// @ts-strict-ignore
import { useEffect, useState } from 'react';
import { Box, Button, Layer } from 'grommet';

import useAPI from '../../external/useAPI';
import { useErrorState } from '../../hooks/useErrorState';
import ErrorPanel from '../errors/ErrorPanel';
import LoadingPanel from '../panels/LoadingPanel';

interface DosespotUserIFrameModalProps {
  onClose: () => void;
}

export const DosespotUserIFrameModal: React.FC<DosespotUserIFrameModalProps> = (
  props: DosespotUserIFrameModalProps,
) => {
  const { dosespotAPI } = useAPI();
  const [iframeURL, setIFrameURL] = useState<string>('');
  const [loading, setLoading] = useState<boolean>(false);
  const { errors, addError, clearErrors } = useErrorState();

  const fetchIFrameURL = async () => {
    clearErrors();
    setLoading(true);
    try {
      const ssoURL = await dosespotAPI.fetchUserSSOURL();
      setIFrameURL(ssoURL);
    } catch (err) {
      addError(err);
    } finally {
      setLoading(false);
    }
  };

  const syncDosespot = async () => {
    clearErrors();
    setLoading(true);
    try {
      await dosespotAPI.postUserSync();
      props.onClose();
    } catch (err) {
      addError(err);
    } finally {
      setLoading(false);
    }
  };

  const onDone = () => {
    setIFrameURL('');
    syncDosespot();
  };

  useEffect(() => {
    fetchIFrameURL();
  }, []);

  return (
    <Layer modal>
      <Box width="xxlarge" height="xxlarge" pad="small" gap="small">
        <Box direction="row" justify="end">
          {errors.length > 0 ? (
            <Button size="large" secondary label="CLOSE" onClick={props.onClose} />
          ) : (
            <Button size="large" secondary label="DONE" onClick={onDone} />
          )}
        </Box>
        <ErrorPanel errors={errors} />
        <Box fill>
          <LoadingPanel showLoader={loading} />
          {iframeURL !== '' && (
            <iframe
              key="dosespot"
              src={iframeURL}
              title="dosespot"
              frameBorder="0"
              style={{ height: '100%', width: '100%' }}
            />
          )}
        </Box>
      </Box>
    </Layer>
  );
};

export default DosespotUserIFrameModal;
