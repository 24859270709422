// Generated by ts-to-zod
import { z } from 'zod';

import { patientPatientNameSchema } from './patient.zod';
import { tasksTaskSchema } from './tasks.zod';
import { usersActorSchema, usersRoleSchema, usersUserSchema } from './users.zod';
import { requiredDateSchema } from './util.zod';

export const formSignatureRequirementTypeSchema = z.union([
  z.literal('client_only'),
  z.literal('client_or_guardian'),
  z.literal('staff'),
]);

export const formsSignatureRequirementSchema = z.lazy(() =>
  z.object({
    id: z.string(),
    roles: z.array(usersRoleSchema),
    type: formSignatureRequirementTypeSchema,
    createdAt: z.date(),
  }),
);

export const formsSectionDefinitionBaseSchema = z.object({
  id: z.string(),
  label: z.string(),
  loadPreviousData: z.boolean(),
  title: z.string().optional(),
  description: z.string().optional(),
  archivedAt: z.date().optional(),
});

export const formsSectionDefinitionSchema = z.lazy(() =>
  formsSectionDefinitionBaseSchema.extend({
    fieldDefinitions: z.array(formsFieldDefinitionSchema),
  }),
);

export const formsFieldTypeSchema = z.union([
  z.literal('text'),
  z.literal('text_paragraph'),
  z.literal('text_display_only'),
  z.literal('number_float'),
  z.literal('date'),
  z.literal('single_choice_radio'),
  z.literal('single_choice_dropdown'),
  z.literal('single_choice_scale'),
  z.literal('multi_choice_checkbox'),
  z.literal('multi_choice_dropdown'),
  z.literal('grid_matrix_text'),
  z.literal('grid_matrix_choice'),
  z.literal('grid_matrix_multi_choice'),
]);

export const formsFieldMetaTextSchema = z.object({
  promptText: z.string(),
});

export const formsFieldMetaParagraphSchema = z.object({
  promptText: z.string(),
});

export const formsFieldMetaTextDisplayOnlySchema = z.object({
  displayTextValue: z.string(),
  displayTextStyle: z.union([z.literal('heading'), z.literal('body')]),
});

export const formsFieldMetaNumberSchema = z.object({
  promptText: z.string(),
});

export const formsFieldMetaDateSchema = z.object({
  promptText: z.string(),
});

export const formsFieldMetaSCRadioSchema = z.object({
  promptText: z.string(),
  choices: z.array(z.string()),
  choiceLabels: z.array(z.string()).optional(),
});

export const formsFieldMetaSCScaleSchema = z.object({
  promptText: z.string(),
  choices: z.array(z.string()),
});

export const formsFieldMetaSCDropdownSchema = z.object({
  promptText: z.string(),
  choices: z.array(z.string()),
});

export const formsFieldMetaMCDropdownSchema = z.object({
  promptText: z.string(),
  choices: z.array(z.string()),
});

export const formsFieldMetaMCCheckboxSchema = z.object({
  promptText: z.string(),
  choices: z.array(z.string()),
  checked: z.array(z.boolean()).optional(),
});

export const formsFieldMetaGridMatrixTextSchema = z.object({
  promptText: z.string(),
  rows: z.array(z.string()),
  columns: z.array(z.string()),
  requiredRows: z.array(z.boolean()),
});

export const formsFieldMetaGridMatrixChoiceSchema = z.object({
  promptText: z.string(),
  rows: z.array(z.string()),
  columns: z.array(z.string()),
  requiredRows: z.array(z.boolean()),
});

export const formsFieldMetaGridMatrixMultiChoiceSchema = z.object({
  promptText: z.string(),
  rows: z.array(z.string()),
  columns: z.array(z.string()),
  requiredRows: z.array(z.boolean()),
});

export const formsFieldMetaSchema = z.union([
  z.object({}),
  formsFieldMetaTextSchema,
  formsFieldMetaParagraphSchema,
  formsFieldMetaNumberSchema,
  formsFieldMetaDateSchema,
  formsFieldMetaSCRadioSchema,
  formsFieldMetaSCScaleSchema,
  formsFieldMetaTextDisplayOnlySchema,
  formsFieldMetaSCDropdownSchema,
  formsFieldMetaMCDropdownSchema,
  formsFieldMetaMCCheckboxSchema,
  formsFieldMetaGridMatrixTextSchema,
  formsFieldMetaGridMatrixChoiceSchema,
  formsFieldMetaGridMatrixMultiChoiceSchema,
]);

export const formsFormDefinitionPostBodySchema = z.object({
  id: z.string(),
  title: z.string().optional(),
  description: z.string().optional(),
  sectionDefinitions: z.array(formsSectionDefinitionBaseSchema.pick({ id: true })),
  isPatientEligible: z.boolean().optional(),
  newFormNeededInXDays: z.number().optional(),
  archiveDueFormsOnDischarge: z.boolean().optional(),
});

export const formsFormSignatureStatusSchema = z.union([
  z.literal('Incomplete'),
  z.literal('Complete'),
]);

export const formsFormSignatureRequirementSchema = z.lazy(() =>
  formsSignatureRequirementSchema.schema.extend({
    formInstanceId: z.string(),
    patient: patientPatientNameSchema,
  }),
);

export const formsFormSignatureFulfillmentSchema = z.object({
  id: z.string(),
  formInstanceId: z.string(),
  formSignatureId: z.string(),
  formSignatureRequirementId: z.string(),
  createdAt: z.date(),
});

export const formsFormSignatureSchema = z.lazy(() =>
  z.object({
    id: z.string(),
    formInstanceId: z.string(),
    user: usersUserSchema,
    patient: patientPatientNameSchema,
    clientContactRelationshipType: z.string(),
    isPatientSignature: z.boolean(),
    signatureImageFileId: z.string(),
    signatureDisplayText: z.string(),
    createdAt: z.date(),
  }),
);

export const formsFormSubmissionSchema = z.object({
  id: z.string(),
  formInstanceId: z.string(),
  formInstancePatientId: z.string(),
  userId: z.string(),
  userPatientId: z.string(),
  isFormClientSubmission: z.boolean(),
  createdAt: z.date(),
  deletedAt: z.date(),
});

export const formsAccessRoleSchema = z.union([
  z.literal('view_only'),
  z.literal('edit_only'),
  z.literal('sign_only'),
  z.literal('edit_and_sign'),
]);

export const formsFormPortalAccessRoleSchema = z.object({
  id: z.string(),
  accessRole: formsAccessRoleSchema,
  createdAt: requiredDateSchema,
  deletedAt: z.date().nullable(),
  user: z.object({
    id: z.string(),
    patientId: z.string(),
    first: z.string(),
    middle: z.string(),
    last: z.string(),
  }),
});

export const formsPortalAccessRoleBulkPostBodySchema = z.object({
  patientId: z.string(),
  accessRole: formsAccessRoleSchema,
});

export const formsPortalAccessRolePostBodySchema = z.object({
  id: z.string().optional(),
  patientId: z.string().optional(),
  accessRole: z.union([formsAccessRoleSchema, z.literal('revoke')]),
});

export const formsFormInstancesQuerySchema = z.object({
  patientIds: z.array(z.string()).optional(),
  signatureStatuses: z.array(formsFormSignatureStatusSchema).optional(),
  formDefinitionIds: z.array(z.string()).optional(),
  creatorUserIds: z.array(z.string()).optional(),
  encounterTemplateIds: z.array(z.string()).optional(),
  startTime: z.date().optional().nullable(),
  endTime: z.date().optional().nullable(),
  searchQuery: z.string().optional(),
  limit: z.number(),
  offset: z.number(),
});

export const formsFormDefinitionSignatureRequirementSchema = z.lazy(() =>
  formsSignatureRequirementSchema.schema.extend({
    formDefinitionId: z.string(),
  }),
);

export const formsPortalAccessRolesBulkPostBodySchema = z.object({
  formIds: z.array(z.string()),
  portalAccessRoles: z.array(formsPortalAccessRoleBulkPostBodySchema),
});

export const formsFormDefinitionBaseSchema = z.object({
  id: z.string(),
  label: z.string(),
  title: z.string().optional(),
  description: z.string().optional(),
  isPatientEligible: z.boolean(),
  isTxPlanForm: z.boolean(),
  newFormNeededInXDays: z.number().optional(),
  archiveDueFormsOnDischarge: z.boolean(),
  signatureRequirements: z.array(formsFormDefinitionSignatureRequirementSchema).optional(),
  isRequired: z.boolean().optional(),
});

export const formsFormDefinitionSchema = formsFormDefinitionBaseSchema.extend({
  sectionDefinitions: z.array(formsSectionDefinitionSchema),
});

export const formsFormInstanceBaseSchema = z.object({
  id: z.string(),
  definition: formsFormDefinitionBaseSchema,
  signatureStatus: formsFormSignatureStatusSchema,
  isPortalEligible: z.boolean(),
  isPortalForm: z.boolean(),
  isTxPlanForm: z.boolean(),
  patientId: z.string(),
});

export const formsFieldDefinitionBaseSchema = z.object({
  id: z.string().optional(),
  label: z.string().optional(),
  defaultValue: z.any().optional(),
  type: formsFieldTypeSchema,
  meta: formsFieldMetaSchema,
});

export const formsFieldDefinitionSchema = z
  .object({
    id: z.string(),
    label: z.string(),
    isRequired: z.boolean().optional(),
  })
  .extend(formsFieldDefinitionBaseSchema.shape);

export const formsFieldInstanceSchema = z.lazy(() =>
  z.object({
    id: z.string(),
    value: z.any(),
    updatedBy: usersActorSchema.optional(),
    isRequired: z.boolean().optional(),
    definition: formsFieldDefinitionSchema,
    tasks: z.array(tasksTaskSchema).optional(),
  }),
);

export const formsSectionInstanceSchema = z.object({
  id: z.string(),
  loadPreviousData: z.boolean(),
  fromGroupForm: z.boolean(),
  definition: formsSectionDefinitionBaseSchema,
  fields: z.array(formsFieldInstanceSchema),
});

export const formsFormInstanceSchema = z.lazy(() =>
  z
    .object({
      sections: z.array(formsSectionInstanceSchema),
      signatureRequiremements: z.array(formsFormSignatureRequirementSchema),
      signatureFulfillments: z.array(formsFormSignatureFulfillmentSchema),
      signatures: z.array(formsFormSignatureSchema),
      submissions: z.array(formsFormSubmissionSchema),
      portalAccessRoles: z.array(formsFormPortalAccessRoleSchema),
      createdAt: z.string(),
      createdBy: usersActorSchema,
      signedAt: z.date().optional(),
      isLocked: z.boolean(),
      userCanUnlock: z.boolean(),
      canOpen: z.boolean(),
      encounterId: z.string(),
      hasAIScribeForm: z.boolean(),
    })
    .extend(formsFormInstanceBaseSchema.shape),
);
