import { Box } from 'grommet';

import InteriorBox from '../boxes/InteriorBox';
import CheckboxComponent from '../form-inputs/CheckboxComponent';
import SimpleLinkToCard, { SimpleLinkToCardProps } from './SimpleLinkToCard';

interface LinkCardWithCheckboxProps extends SimpleLinkToCardProps {
  checked: boolean;
  onSelectCheckbox: () => void;
  disabled?: boolean;
}

export const LinkCardWithCheckbox: React.FC<LinkCardWithCheckboxProps> = (
  props: LinkCardWithCheckboxProps,
) => {
  const { checked, disabled = false, onSelectCheckbox, ...rest } = props;
  return (
    <InteriorBox direction="row" align="center" gap="10px">
      <Box pad={{ left: '12px' }}>
        <CheckboxComponent
          label=""
          data-testid="link-card-with-checkbox-checkbox-component"
          checkBoxProps={{
            onClick: onSelectCheckbox,
            iconSize: '16px',
            checked,
            disabled,
          }}
        />
      </Box>
      <SimpleLinkToCard {...rest} />
    </InteriorBox>
  );
};

export default LinkCardWithCheckbox;
