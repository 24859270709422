import { from } from 'rxjs';

import { reportError } from 'third-party/sentry';
import { parseISODateStrings } from 'utils';
import RittenClient from './RittenClient';

class CRMClient extends RittenClient {
  postOrganization = async (postBody: CRM.PostOrganizationBody) => {
    const res = await this.post<CRM.Organization>(`/api/organizations`, { ...postBody });
    return res.data;
  };

  getOrganization = async (organizationId: string) => {
    const res = await this.get<CRM.Organization>(`/api/organizations/${organizationId}`);
    return res.data;
  };

  archiveOrganization = async (id: string) => {
    await this.delete(`/api/organizations/${id}`);
  };

  getOrganizationContacts = async (
    organizationId: string,
    query?: CRM.ListOrganizationPersonQuery,
  ) => {
    const res = await this.get<CRM.OrganizationContact[]>(
      `/api/organizations/${organizationId}/contacts`,
      { params: { ...query } },
    );
    return parseISODateStrings(res.data);
  };

  getOrganizationROIs = async (organizationId: string, query?: CRM.ListOrganizationROIsQuery) => {
    const res = await this.get<CRM.OrganizationROI[]>(
      `/api/organizations/${organizationId}/releases`,
      { params: { ...query } },
    );
    return parseISODateStrings(res.data);
  };

  postOrganizationContact = async (
    organizationId: string,
    postBody: CRM.PostOrganizationContact[],
  ) => {
    const res = await this.post<{ id: string }[]>(
      `/api/organizations/${organizationId}/contacts`,
      postBody,
    );
    return res.data;
  };

  uploadDocumentToOrganization = async (organizationId: string, documentId: string) => {
    const res = await this.post<{ id: string }>(
      `/api/organizations/${organizationId}/documents/${documentId}`,
    );
    return res.data;
  };

  getOrganizationDocuments = async (organizationId: string) => {
    const res = await this.get<Documents.Document[]>(
      `/api/organizations/${organizationId}/documents`,
    );
    return parseISODateStrings(res.data);
  };

  deleteOrganizationDocument = async (organizationId: string, documentId: string) => {
    await this.delete(`/api/organizations/${organizationId}/documents/${documentId}`);
  };

  postPerson = async (postBody: CRM.PostPerson) => {
    const res = await this.post<CRM.Person>(`/api/persons`, { ...postBody });
    return res.data;
  };

  getPerson = async (personId: string) => {
    const res = await this.get<CRM.Person>(`/api/persons/${personId}`);
    return res.data;
  };

  mergePeople = async (primaryPersonId: string, postBody: CRM.PostMergePersons) => {
    const res = await this.post<CRM.Person>(`/api/persons/${primaryPersonId}/merge`, postBody);
    return res;
  };

  archivePerson = async (personId: string) => {
    await this.delete(`/api/persons/${personId}`);
  };

  listOrganizations = async (query?: CRM.ListOrganizationsQuery) => {
    const res = await this.get<CRM.Organization[]>(`/api/organizations`, {
      params: { ...query },
    });
    return res.data;
  };

  listOrganizations$ = (query?: CRM.ListOrganizationsQuery) =>
    from(this.listOrganizations({ ...query }));

  listPeople = async (query?: CRM.ListPeopleQuery) => {
    const res = await this.get<CRM.Person[]>(`/api/persons`, {
      params: { ...query },
    });
    return res.data;
  };

  listPeople$ = (query?: CRM.ListPeopleQuery) => from(this.listPeople({ ...query }));

  getPersonRelationships = async (personId: string) => {
    const res = await this.get<CRM.PersonRelationships>(`/api/persons/${personId}/relationships`);
    return parseISODateStrings(res.data);
  };

  /**
   *
   * @param sourcePersonId id of the source person in the relationship
   * @param postBody contains the id of the destination person in the relationship
   * @returns
   */
  postPersonRelationships = async (
    sourcePersonId: string,
    postBody: CRM.PostPersonRelationship,
  ) => {
    const res = await this.post(`/api/persons/${sourcePersonId}/relationships`, {
      ...postBody,
    });
    return res.data;
  };

  getPersonOrganizations = async (personId: string) => {
    const res = await this.get<CRM.OrganizationContact[]>(`/api/persons/${personId}/organizations`);
    return res.data;
  };

  listCases = async (query?: CRM.ListCasesQuery) => {
    const res = await this.get<CRM.Case[]>(`/api/crm/deals`, {
      params: { ...query },
    });
    return parseISODateStrings(res.data);
  };

  getCase = async (dealId: string) => {
    const res = await this.get<CRM.Case>(`/api/crm/deals/${dealId}`);
    return parseISODateStrings(res.data);
  };

  postCase = async (postBody: CRM.PostCase) => {
    const res = await this.post<CRM.Case>(`/api/crm/deals`, { ...postBody });
    return res.data;
  };

  archiveCase = async (id: string) => {
    await this.delete(`/api/crm/deals/${id}`);
  };

  updateCaseStatus = async (caseId: string, patchBody: CRM.PatchCaseStatus) => {
    await this.patch(`/api/crm/deals/${caseId}/status`, {
      ...patchBody,
    });
  };

  postNote = async (postBody: CRM.PostNote) => {
    // @ts-ignore: Incompatible types error
    if (postBody.takenAt === '') {
      reportError(`Note has empty string value in takenAt property`);
      postBody.takenAt = null;
    }
    const res = await this.post<CRM.Note>(`/api/crm/notes`, { ...postBody });
    return parseISODateStrings(res.data);
  };

  getNote = async (noteId: string) => {
    const res = await this.get<CRM.Note>(`/api/crm/notes/${noteId}`);
    return parseISODateStrings(res.data);
  };

  listNotes = async (query?: CRM.ListNotesQuery) => {
    const res = await this.get<CRM.FullNote>(`/api/crm/notes`, {
      params: { ...query },
    });
    return parseISODateStrings(res.data);
  };

  deleteDealNote = async (dealId: string, noteId: string) => {
    await this.delete(`/api/crm/deals/${dealId}/notes/${noteId}`);
  };

  deleteContactNote = async (contactId: string, noteId: string) => {
    await this.delete(`/api/persons/${contactId}/notes/${noteId}`);
  };

  deleteOrganizationNote = async (orgId: string, noteId: string) => {
    await this.delete(`/api/organizations/${orgId}/notes/${noteId}`);
  };

  postCaseAction = async (dealId: string, postBody: CRM.PostAction) => {
    const res = await this.post<API.IDResponse>(`/api/crm/deals/${dealId}/actions`, {
      ...postBody,
    });
    return parseISODateStrings(res.data);
  };

  deleteCaseAction = async (dealId: string, actionId: string) => {
    await this.delete(`/api/crm/deals/${dealId}/actions/${actionId}`);
  };

  postCaseDisqualification = async (dealId: string, postBody: CRM.PostCaseDisqualification) => {
    const res = await this.post<API.IDResponse>(
      `/api/crm/deals/${dealId}/disqualification-reasons`,
      {
        ...postBody,
      },
    );
    return parseISODateStrings(res.data);
  };

  deleteCaseDisqualification = async (dealId: string, reasonId: string) => {
    await this.delete(`/api/crm/deals/${dealId}/disqualification-reasons/${reasonId}`);
  };

  getOutboundReferral = async (dealId: string, outboundReferralId: string) => {
    const res = await this.get<CRM.OutboundReferral>(
      `/api/crm/deals/${dealId}/outbound-referrals/${outboundReferralId}`,
    );
    return parseISODateStrings(res.data);
  };

  postOutboundReferral = async (dealId: string, postBody: CRM.PostOutboundReferral) => {
    const res = await this.post<CRM.OutboundReferral>(
      `/api/crm/deals/${dealId}/outbound-referrals`,
      {
        ...postBody,
      },
    );
    return parseISODateStrings(res.data);
  };

  archiveOutboundReferral = async (dealId: string, outboundReferralId: string) => {
    await this.delete(`/api/crm/deals/${dealId}/outbound-referrals/${outboundReferralId}`);
  };

  postOutboundReferralContact = async (
    dealId: string,
    postBody: CRM.PostOutboundReferralContact,
  ) => {
    const res = await this.post<API.IDResponse>(
      `/api/crm/deals/${dealId}/outbound-referrals/${postBody.caseOutboundReferralId}/contacts`,
      {
        ...postBody,
      },
    );
    return parseISODateStrings(res.data);
  };

  deleteOutboundReferralContact = async (
    dealId: string,
    outboundReferralId: string,
    contactId: string,
  ) => {
    await this.delete(
      `/api/crm/deals/${dealId}/outbound-referrals/${outboundReferralId}/contacts/${contactId}`,
    );
  };

  getCaseSources = async (): Promise<CRM.CaseSource[]> => {
    const res = await this.get<CRM.CaseSource[]>(`/api/crm/case-sources`);
    return res.data;
  };

  postCaseSources = async (postBody: CRM.CaseSourcePostBody[]): Promise<CRM.CaseSource[]> => {
    const res = await this.post<CRM.CaseSource[]>(`/api/crm/case-sources`, postBody);
    return res.data;
  };

  deleteCaseSource = async (caseSourceId: string): Promise<void> => {
    await this.delete(`/api/crm/case-sources/${caseSourceId}`);
  };

  getLeadAppDefinition = async (): Promise<CRM.LeadAppDefinition> => {
    const { data = [] } = await this.get<CRM.LeadAppDefinition[]>(`/api/crm/lead-app/definitions`);
    return data[0];
  };

  updateLeadAppDefinition = async (
    id: string,
    leadAppSections: CRM.LeadAppSection[],
    leadAppMeta: CRM.LeadAppDefinitionMeta,
  ): Promise<CRM.LeadAppDefinition> => {
    const body: CRM.LeadAppDefinitionUpdateRequest = {
      sections: leadAppSections,
      meta: leadAppMeta,
    };
    const res = await this.put<CRM.LeadAppDefinition>(`/api/crm/lead-app/definitions/${id}`, body);
    return res.data;
  };

  /**
   * @returns a list of possible sections that a lead application can be compromised of
   */
  getLeadAppDefinitionSections = async (): Promise<CRM.LeadAppSection[]> => {
    const res = await this.get<CRM.LeadAppSection[]>(`/api/crm/lead-app/definitions/sections`);
    return res.data;
  };

  getCaseSharedLeadAppUrl = async (caseId: string): Promise<{ url: string }> => {
    const res = await this.post<{ url: string }>(`/api/crm/deals/${caseId}/lead-app/share`);
    return res.data;
  };

  postLeadAppInstanceSync = async (leadAppInstanceId: string): Promise<void> => {
    await this.post(`/api/crm/lead-app/instances/${leadAppInstanceId}/sync`);
  };

  getLeadAppInstance = async (leadAppInstanceId: string): Promise<CRM.LeadAppInstance> => {
    const res = await this.get<CRM.LeadAppInstance>(
      `/api/crm/lead-app/instances/${leadAppInstanceId}`,
    );
    return res.data;
  };

  getLeadAppPersonMatches = async (leadAppInstanceId: string): Promise<CRM.Person[]> => {
    const res = await this.get<CRM.Person[]>(
      `/api/crm/lead-app/instances/${leadAppInstanceId}/persons`,
    );
    return res.data;
  };

  patchLeadAppInstanceStatus = async (leadAppInstanceId: string, status: CRM.LeadAppStatus) => {
    await this.patch(`/api/crm/lead-app/instances/${leadAppInstanceId}/status`, { status });
  };
}

export default CRMClient;
