import { sortRoundConfigurations } from 'features/clients/rounds/utils';
import { parseISODateStrings } from 'utils';
import RittenClient from './RittenClient';

export default class RoundsClient extends RittenClient {
  /**
   * list rounds configuration - sorted by FE util by building/program if necessary
   * @returns list of RoundGroupConfigurations to be listed on Round Times config tab
   */
  listRoundGroupConfigurations = async (): Promise<Rounds.RoundGroupConfiguration[]> => {
    const { data } = await this.get<Rounds.RoundGroupConfiguration[]>('api/rounds/config/rounds');
    return sortRoundConfigurations(data);
  };

  /**
   * get list of round instances for round page/client chart tab
   * @param params RoundsQueryParams
   * @returns list of Rounds.RoundInstance
   */
  listRoundInstances = async (
    params: Rounds.RoundsQueryParams,
  ): Promise<Rounds.RoundInstance[]> => {
    const { data } = await this.get<Rounds.RoundInstance[]>('api/rounds/instances', { params });
    return parseISODateStrings(data);
  };

  /**
   * Get client round instances
   * @param patientId patientId
   * @param params Rounds Filters params - from RoundsFilters - same as listRoundInstances Object
   * @returns list of PatientRoundListItem
   */
  listPatientRoundInstances = async (
    patientId: string,
    params: Rounds.RoundsQueryParams,
  ): Promise<Rounds.PatientRoundListItem[]> => {
    const { data } = await this.get<Rounds.PatientRoundListItem[]>(
      `api/rounds/patient/${patientId}/instances`,
      { params },
    );
    return data;
  };

  /**
   * Get list of users who have logged a round
   * @returns A list of Users' names and Ids for basic filtering
   */
  listRoundLoggingStaffMembers = async (): Promise<Users.AbbreviatedUser[]> => {
    const { data } = await this.get<Users.AbbreviatedUser[]>('api/rounds/staff');
    return data;
  };

  getStartRoundOptions = async (): Promise<Rounds.StartRoundResponse> => {
    const { data } = await this.get<Rounds.StartRoundResponse>('api/rounds/start');
    return data;
  };

  postStartRound = async (roundToStart: Rounds.RoundOption): Promise<{ id: string }> => {
    const { data } = await this.post<{ id: string }>('api/rounds/instances', roundToStart);
    return data;
  };

  getClientRoundGroups = async (
    patientId: string,
  ): Promise<Rounds.PatientRoundGroupConfigurationResponse> => {
    const { data } = await this.get<Rounds.PatientRoundGroupConfigurationResponse>(
      `api/rounds/config/patient/${patientId}`,
    );
    return parseISODateStrings(data);
  };

  putClientRoundGroups = async (
    patientId: string,
    configuration: Rounds.PatientRoundGroupConfiguration[],
  ) => {
    await this.put<void>(`api/rounds/config/patient/${patientId}`, configuration);
  };

  getRoundInstance = async (roundInstanceId: string): Promise<Rounds.RoundInstance> => {
    const { data } = await this.get<Rounds.RoundInstance>(
      `api/rounds/instances/${roundInstanceId}`,
    );
    return parseISODateStrings(data);
  };

  /**
   * Updates round time ranges and enabled/disabled from Round Times config tab
   * @param Rounds.PostRoundGroupConfiguration
   *
   */
  updateRoundConfiguration = async (
    config: Rounds.PostRoundGroupConfiguration,
  ): Promise<Rounds.RoundGroupConfiguration> => {
    const { data } = await this.post<Rounds.RoundGroupConfiguration>(
      'api/rounds/config/rounds',
      config,
    );
    return data;
  };

  /**
   * removes round time from round configuration
   * @param roundId Rounds.RoundGroupConfiguration.id
   * @param timeRangeId Rounds.RoundGroupTimeRange.id
   */
  deleteTimeRange = async (roundId: string, timeRangeId: string): Promise<void> => {
    await this.delete(`api/rounds/config/rounds/${roundId}/timerange/${timeRangeId}`);
  };

  /**
   * get all round clinic activities
   * @returns list of Rounds.LocationOrActivity
   */
  listActivities = async (): Promise<Rounds.LocationOrActivity[]> => {
    const { data } = await this.get<Rounds.LocationOrActivity[]>('api/rounds/config/activities');
    return data;
  };

  /**
   * update activity list - send full list in order
   * @param activities list of Rounds.LocationOrActivity
   * @returns nothing
   */
  putActivityList = async (activities: Rounds.LocationOrActivity[]): Promise<void> => {
    await this.put<void>('api/rounds/config/activities', activities);
  };

  /**
   * get all round clinic locations
   * @returns list of Rounds.LocationOrActivity
   */
  listLocations = async (): Promise<Rounds.LocationOrActivity[]> => {
    const { data } = await this.get<Rounds.LocationOrActivity[]>('api/rounds/config/locations');
    return data;
  };

  /**
   * update locations list - send full list in order
   * @param activities list of Rounds.LocationOrActivity
   * @returns nothing
   */
  putLocationsList = async (locations: Rounds.LocationOrActivity[]): Promise<void> => {
    await this.put<void>('api/rounds/config/locations', locations);
  };

  /**
   * get base rounds configuration - whether dividing by program/building
   * @returns Rounds.RoundsBuildingProgramConfig
   */
  getRoundsBuildingProgramConfiguration = async (): Promise<Rounds.RoundsBuildingProgramConfig> => {
    const { data } = await this.get<Rounds.RoundsBuildingProgramConfig>('api/rounds/config');
    return data;
  };

  /**
   * post round config setup for dividing rounds by building/program/none
   * @param configuration Rounds.RoundsBuildingProgramConfig
   */
  postRoundsBuildingProgramConfiguration = async (
    configuration: Rounds.RoundsBuildingProgramConfig,
  ): Promise<void> => {
    await this.post<void>('api/rounds/config', configuration);
  };

  /**
   * Update individual round log - used by autosave fn
   * @param individualLogId - PatientRoundLog.id (NOT patientID)
   * @param logDetails Rounds.UpdatePatientRoundLog - activity, location, notes
   */
  patchRoundIndividualLog = async (logDetails: Rounds.UpdatePatientRoundLog): Promise<void> => {
    await this.patch<void>(
      `api/rounds/instances/${logDetails.roundId}/individuals/${logDetails.patientLogId}`,
      { activity: logDetails.activity, location: logDetails.location, notes: logDetails.notes },
    );
  };

  /**
   * Create PatientRoundLog for client within roundInstance
   * @param roundInstanceId
   * @param patientIds
   * @returns PatientRoundLog data - to add patient to current round instance individuals and update log
   */
  addClientsToRoundInstance = async (
    roundInstanceId: string,
    patientIds: string[],
  ): Promise<Rounds.RoundInstance> => {
    const { data } = await this.post<Rounds.RoundInstance>(
      `api/rounds/instances/${roundInstanceId}/individuals`,
      {
        patientIds,
      },
    );
    return parseISODateStrings(data);
  };

  getMatchingRoundInstancesByRoundOption = async (
    roundOption: Rounds.RoundOption,
  ): Promise<Rounds.RoundInstance[]> => {
    const { data } = await this.get<Rounds.RoundInstance[]>(
      `api/rounds/instances/match?date="${roundOption.date}"&tod="${roundOption.time}"${
        roundOption.building ? `&buildingId=${roundOption.building.id}` : ''
      }${
        roundOption.program
          ? `&programId=${roundOption.program.id}&programLoc=${roundOption.program.levelOfCare}`
          : ''
      }`,
    );
    return data;
  };

  /**
   * Delete Patient from round instance
   * @param roundInstanceId round to delete patient from
   * @param individualLogId patient to remove from round instance
   */
  removeClientFromRoundInstance = async (
    roundInstanceId: string,
    individualLogId: string,
  ): Promise<Rounds.RoundInstance> => {
    const { data } = await this.delete<Rounds.RoundInstance>(
      `api/rounds/instances/${roundInstanceId}/individuals/${individualLogId}`,
    );
    return parseISODateStrings(data);
  };

  /**
   * Mark individual's round log as done
   * @param individualId PatientRoundLog.id
   * @param roundInstanceId RoundInstance.id
   */
  markIndividualDone = async (roundInstanceId: string, individualId: string): Promise<void> => {
    await this.post<void>(
      `api/rounds/instances/${roundInstanceId}/individuals/${individualId}/mark`,
    );
  };

  /**
   * Mark a group of round individual logs done
   * @param roundInstanceId
   * @param roundIndividualIds list of Rounds.PatientRoundLog.id to be marked done
   * @returns nothing
   */
  bulkMarkDone = async (roundInstanceId: string, roundIndividualIds: string[]): Promise<void> => {
    await this.post<void>(`api/rounds/instances/${roundInstanceId}/mark`, {
      roundIndividualIds,
    });
  };
}
