// Generated by ts-to-zod
import { z } from 'zod';

import { ordersOrderSchema, ordersOrderTypeSchema } from './orders.zod';
import { patientPatientNameSchema, patientPatientSchema } from './patient.zod';
import { signaturesSignatureSchema } from './signatures.zod';
import { usersUserSchema } from './users.zod';

export const marOrderStatusSchema = z.union([
  z.literal('pending'),
  z.literal('active'),
  z.literal('inactive'),
  z.literal('none'),
  z.literal('complete'),
]);

export const marScheduleTypeSchema = z.union([
  z.literal('PRN'),
  z.literal('Scheduled'),
  z.literal('Action'),
  z.literal('Unscheduled Action'),
]);

export const marFirstDoseResponseSchema = z.object({
  id: z.string(),
  doseId: z.string(),
  doseScheduledAt: z.date(),
  doseTakenAt: z.date(),
  userId: z.string(),
  createdAt: z.date(),
  efficacy: z.string(),
  adverseReaction: z.string(),
  justification: z.string(),
  medication: z.string(),
  scheduleId: z.string(),
  scheduleType: marScheduleTypeSchema,
});

export const marOrderTypeSchema = z.union([
  z.literal('Electronic'),
  z.literal('Doctor Prescribed'),
  z.literal('Phone'),
  z.literal('Email'),
  z.literal('Fax'),
  z.literal('Paper'),
  z.literal('Verbal'),
  z.literal('Other'),
]);

export const marScheduleStartBehaviorSchema = z.union([
  z.literal('after current time'),
  z.literal('start of day'),
  z.literal('given time'),
]);

export const marListAllSchedulesSortSchema = z.union([
  z.literal('endDate_asc'),
  z.literal('endDate_desc'),
]);

export const marListAllSchedulesQuerySchema = z.object({
  startDate: z.date().optional(),
  endDate: z.date().optional(),
  lowPillCount: z.boolean().optional(),
  endsInXDays: z.number().optional(),
  patientIds: z.array(z.string()).optional(),
  scheduleTypes: z.array(z.string()).optional(),
  programIds: z.array(z.string()).optional(),
  primaryClinicianIds: z.array(z.string()).optional(),
  careTeamMemberIds: z.array(z.string()).optional(),
  orderingProviderIds: z.array(z.string()).optional(),
  limit: z.string().optional(),
  offset: z.string().optional(),
  sortBy: marListAllSchedulesSortSchema.optional(),
});

export const marScheduleBlockFrequencySchema = z.union([
  z.literal('Q30M'),
  z.literal('QH'),
  z.literal('Q2H'),
  z.literal('Q3H'),
  z.literal('Q4H'),
  z.literal('Q6H'),
  z.literal('Q8H'),
  z.literal('Q12H'),
  z.literal('QD'),
  z.literal('HS'),
  z.literal('BID'),
  z.literal('TID'),
  z.literal('QID'),
  z.literal('QW'),
  z.literal('Q4W'),
  z.literal('Now'),
  z.literal('Stat'),
  z.literal('QM'),
  z.literal('Select Week Days'),
]);

export const marBlockDurationTypeSchema = z.union([
  z.literal('Days'),
  z.literal('Weeks'),
  z.literal('Months'),
  z.literal('Doses'),
]);

export const marDoseStatusSchema = z.union([
  z.literal('pending'),
  z.literal('refused'),
  z.literal('wasted'),
  z.literal('observed'),
  z.literal('administered'),
  z.literal('complete'),
  z.literal('incomplete'),
  z.literal('not_provided'),
  z.literal('unavailable'),
  z.literal('before schedule'),
]);

export const marDaysOfWeekSchema = z.union([
  z.literal('Sunday'),
  z.literal('Monday'),
  z.literal('Tuesday'),
  z.literal('Wednesday'),
  z.literal('Thursday'),
  z.literal('Friday'),
  z.literal('Saturday'),
]);

export const marScheduleBlockSchema = z.object({
  id: z.string(),
  scheduleId: z.string().optional(),
  createdAt: z.date().optional(),
  startsAt: z.date().optional(),
  frequency: marScheduleBlockFrequencySchema.optional(),
  count: z.number().optional(),
  hours: z.array(z.number()),
  strengths: z.array(z.string()),
  quantities: z.array(z.number()),
  doseForms: z.array(z.string()),
  routes: z.array(z.string()),
  isIntervalDose: z.boolean(),
  daysOfWeek: z.array(marDaysOfWeekSchema).optional(),
});

export const marSignableOrderSchema = z.object({
  orderId: z.string(),
  userSignatureId: z.string(),
  action: z.union([z.literal('create'), z.literal('delete')]),
});

export const marScheduleTemplateListQueryParamsSchema = z.object({
  limit: z.number(),
  offset: z.number().optional(),
  names: z.array(z.string()).optional(),
});

export const marListDoseQuerySchema = z.object({
  startDate: z.date(),
  endDate: z.date(),
  scheduleTypes: z.array(z.string()).optional(),
  patientIds: z.array(z.string()).optional(),
  programIds: z.array(z.string()).optional(),
  levelsOfCare: z.array(z.string()).optional(),
  withFormer: z.boolean().optional(),
  withArchived: z.boolean().optional(),
  excludeStatuses: z.array(marDoseStatusSchema).optional(),
});

export const marListPatientDoseQuerySchema = marListDoseQuerySchema.pick({
  startDate: true,
  endDate: true,
  scheduleTypes: true,
});

const marScheduleSchema = z.lazy(() =>
  z.object({
    id: z.string(),
    patientId: z.string(),
    userId: z.string(),
    createdAt: z.date(),
    deletedAt: z.date().nullable(),
    startsAt: z.date(),
    medication: z.string(),
    justification: z.string(),
    warning: z.string(),
    instructions: z.string(),
    pillCount: z.number(),
    firstDoseId: z.string().nullable(),
    firstDoseResponseId: z.string().nullable(),
    firstDoseResponseStatus: z.union([
      z.literal('pending'),
      z.literal('due'),
      z.literal('complete'),
    ]),
    lastDoseId: z.string().nullable(),
    marOrderId: z.string(),
    orderId: z.string(),
    encounterId: z.string(),
    endsAt: z.date().nullable(),
    timeZone: z.string(),
    patientName: patientPatientNameSchema.optional(),
    patientProgramNames: z.array(z.string()).optional(),
    orderingProviderId: z.string().optional(),
    discontinueOrderingProviderId: z.string().optional(),
    discontinuedBy: usersUserSchema.optional(),
    scheduledBy: usersUserSchema,
    prescriptionNumber: z.string().optional(),
    type: marScheduleTypeSchema,
    blocks: z.array(marScheduleBlockSchema),
    firstDoseResponse: marFirstDoseResponseSchema.nullable(),
    marOrderStatus: marOrderStatusSchema,
    marOrderType: marOrderTypeSchema,
    // marOrder?: Order;
    startBehavior: marScheduleStartBehaviorSchema,
    recalcOn: z.boolean(),
  }),
);

export const marOrderSchema = z.lazy(() =>
  z.object({
    id: z.string(),
    order: ordersOrderSchema,
    orderId: z.string(),
    createdAt: z.date(),
    status: marOrderStatusSchema,
    type: ordersOrderTypeSchema,
    schedule: marScheduleSchema.optional(),
    completedAt: z.date().nullable(),
  }),
);

export const marDoseSchema = z.lazy(() =>
  z.object({
    id: z.string(),
    userId: z.string().nullable(),
    patientId: z.string(),
    createdAt: z.date(),
    scheduledAt: z.date(),
    takenAt: z.date().nullable(),
    canceledAt: z.date().nullable(),
    comments: z.string(),
    medication: z.string(),
    strength: z.string(),
    quantity: z.number(),
    quantityTaken: z.number(),
    quantityWasted: z.number(),
    doseForm: z.string(),
    route: z.string(),
    justification: z.string(),
    warning: z.string(),
    instructions: z.string(),
    userSignature: signaturesSignatureSchema.nullable(),
    userSignatureId: z.string().nullable(),
    patientSignature: signaturesSignatureSchema.nullable(),
    patientSignatureId: z.string().nullable(),
    scheduleId: z.string().optional(),
    prevTakenAt: z.date().nullable(),
    nextTakenAt: z.date().nullable(),
    patient: patientPatientSchema.nullable(),
    loggedBy: usersUserSchema,
    status: marDoseStatusSchema,
    scheduleType: marScheduleTypeSchema,
    schedule: marScheduleSchema.optional(),
    frequency: marScheduleBlockFrequencySchema,
    isRecalcDose: z.boolean(),
    isDisabled: z.boolean(),
  }),
);

export const marUpdatableMARDoseSchema = z.lazy(() =>
  marDoseSchema.schema.pick({
    status: true,
    comments: true,
    quantityTaken: true,
    quantityWasted: true,
    canceledAt: true,
    takenAt: true,
    userId: true,
  }),
);

export const marCreatableMARFDRSchema = marFirstDoseResponseSchema.pick({
  doseId: true,
  adverseReaction: true,
  efficacy: true,
  userId: true,
});

export const marDosesResponseSchema = z.object({
  meta: z.object({
    totalCount: z.number(),
  }),
  doses: z.array(marDoseSchema),
});

export const marCreatableScheduleBlockSchema = z.lazy(() =>
  z.object({
    id: z.string(),
    patientId: z.string(),
    userId: z.string(),
    createdAt: z.date(),
    deletedAt: z.date().nullable(),
    startsAt: z.date(),
    medication: z.string(),
    justification: z.string(),
    warning: z.string(),
    instructions: z.string(),
    pillCount: z.number(),
    firstDoseId: z.string().nullable(),
    firstDoseResponseId: z.string().nullable(),
    firstDoseResponseStatus: z.union([
      z.literal('pending'),
      z.literal('due'),
      z.literal('complete'),
    ]),
    lastDoseId: z.string().nullable(),
    marOrderId: z.string(),
    orderId: z.string(),
    encounterId: z.string(),
    endsAt: z.date().nullable(),
    timeZone: z.string(),
    patientName: patientPatientNameSchema.optional(),
    patientProgramNames: z.array(z.string()).optional(),
    orderingProviderId: z.string().optional(),
    prescriptionNumber: z.string().optional(),
    scheduledBy: usersUserSchema,
    type: marScheduleTypeSchema,
    blocks: z.array(marScheduleBlockSchema),
    firstDoseResponse: marFirstDoseResponseSchema.nullable(),
    marOrderStatus: marOrderStatusSchema,
    marOrderType: marOrderTypeSchema,
    // marOrder?: Order;
    startBehavior: marScheduleStartBehaviorSchema,
    durationType: marBlockDurationTypeSchema,
  }),
);

export const marCreatableScheduleSchema = z.object({
  type: marScheduleTypeSchema,
  blocks: z.array(marCreatableScheduleBlockSchema),
  medication: z.string(),
  justification: z.string(),
  warning: z.string(),
  instructions: z.string(),
  pillCount: z.number(),
  marOrderType: marOrderTypeSchema,
  id: z.string().optional(),
  startsAt: z.date().optional(),
  userId: z.string().optional(),
  encounterId: z.string().optional(),
  orderId: z.string().optional(),
  timeZone: z.string().optional(),
  orderingProviderId: z.string().optional(),
  startBehavior: marScheduleStartBehaviorSchema,
  startTime: z.string().optional(),
  recalcOn: z.boolean(),
});

export const marScheduleTemplateSchema = z.object({
  id: z.string(),
  createdAt: z.date(),
  name: z.string(),
  schedule: marCreatableScheduleSchema,
  marOrderType: marOrderTypeSchema,
});

export const marOrderTemplateSetSchema = z.object({
  id: z.string(),
  name: z.string(),
  scheduleTemplates: z.array(marScheduleTemplateSchema),
});

export const marBulkScheduleCreationSchema = z.object({
  wantsNonErx: z.boolean(),
  wantsAction: z.boolean(),
  templates: z.array(marScheduleTemplateSchema),
  templateSets: z.array(marOrderTemplateSetSchema),
});
