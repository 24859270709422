import { z } from 'zod';

import { catalogClinicProgramSchema, catalogPlaceOfServiceCodeSchema } from './catalog.zod';
import { claimsClaimTypeSchema } from './claims.zod';
import { clinicPayerGroupSchema, clinicProviderSchema } from './clinic.zod';
import {
  encountersEncounterAttendanceSchema,
  encountersEncounterSchema,
  encountersEncounterTemplateSchema,
} from './encounters.zod';
import {
  patientAuthorizationEntrySchema,
  patientAuthorizationSchema,
  patientInsurancePayerSchema,
  patientPatientNameSchema,
  patientPatientSchema,
} from './patient.zod';

// Could not parse:

// type StringifyKeys<T, K extends keyof T> = {
//     [P in keyof T]: P extends K ? string : T[P];
//   };

//   type DBSResponse = DailyBillableService & {
//     associatedEncounters: StringifyKeys<Encounters.Encounter, 'startTime' | 'endTime'>[];
//   };

export const billingDailyBillableServiceSchema = z.lazy(() =>
  z.object({
    id: z.string(),
    patient: patientPatientSchema,
    encounterTemplates: z.array(encountersEncounterTemplateSchema),
    program: catalogClinicProgramSchema,
    levelOfCare: z.string(),
    payerGroup: clinicPayerGroupSchema,
    procedureCode: z.string(),
    addOnCodes: z.array(billingAddOnCodeSchema),
    revenueCode: z.string(),
    billedRate: z.number(),
    expectedRate: z.number(),
    useFeeSchedule: z.boolean(),
    isBundled: z.boolean(),
    associatedEncounters: z.array(encountersEncounterSchema),
    serviceDate: z.string(),
    ruleName: z.string(),
    minimumBundledMinutes: z.number(),
    claimId: z.string(),
    claimType: claimsClaimTypeSchema,
    authorization: patientAuthorizationSchema.optional(),
    procedureCodeModifiers: z.array(z.string()),
    unitsTotal: z.number(),
    useEncounterCreatorProvider: z.boolean(),
    provider: clinicProviderSchema.nullable(),
    isDraft: z.boolean(),
    placeOfServiceCode: catalogPlaceOfServiceCodeSchema.nullable(),
  }),
);

export const billingAddOnCodeSchema = z.object({
  code: z.string(),
  billedAmount: z.number(),
  allowedAmount: z.number(),
});

export const billingAddOnCodeResponseSchema = billingAddOnCodeSchema.extend({
  id: z.string(),
  createdAt: z.date(),
});

export const billingBillingRuleResultSchema = z.union([
  z.literal('Billable'),
  z.literal('No Encounter Rule Inputs'),
  z.literal('No Rule Match'),
  z.literal('Multiple Rules Matched'),
  z.literal('No Active Programs'),
  z.literal('No Payer Groups'),
  z.literal('Deleted'),
]);

export const billingListDailyBillableServiceSortTypeSchema = z.union([
  z.literal('createdAtAsc'),
  z.literal('createdAtDesc'),
  z.literal('patientNameAsc'),
  z.literal('patientNameDesc'),
]);

export const billingDailyBillableServiceStatusSchema = z.union([
  z.literal('Draft'),
  z.literal('Claim'),
]);

export const billingListDailyBillableServicesQuerySchema = z.object({
  startTime: z.string(),
  endTime: z.string(),
  patientIds: z.array(z.string()),
  limit: z.number().optional(),
  offset: z.number().optional(),
  status: billingDailyBillableServiceStatusSchema.optional(),
  ruleId: z.string().optional(),
  noRule: z.boolean().optional(),
  procedureCodes: z.array(z.string()),
  hasAuthorization: z.boolean().optional(),
  payerGroupIds: z.array(z.string()).optional(),
  sortBy: billingListDailyBillableServiceSortTypeSchema.optional(),
  encounterStatuses: z.array(z.string()).optional(),
});

export const billingBillingProgramSchema = z.object({
  id: z.string(),
  programName: z.string(),
  programLevelsOfCare: z.array(z.string()),
  selectedLevelsOfCare: z.array(z.string()),
});

export const billingBillingProgramAndLOCSchema = z.object({
  programAndLOCName: z.string(),
  uniqueId: z.string(),
  programId: z.string(),
  loc: z.string(),
});

export const billingDailyBillableServicePutBodySchema = z.object({
  id: z.string(),
  patientId: z.string(),
  encounterTemplateIds: z.array(z.string()),
  programId: z.string(),
  levelOfCare: z.string(),
  payerGroupId: z.string(),
  procedureCode: z.string(),
  revenueCode: z.string(),
  billedRate: z.number(),
  expectedRate: z.number(),
  useFeeSchedule: z.boolean(),
  isBundled: z.boolean(),
  associatedEncounterIds: z.array(z.string()),
  serviceDate: z.string(),
  authorizationEntryId: z.string().optional(),
  minimumBundledMinutes: z.number().optional(),
  procedureCodeModifiers: z.array(z.string()).optional(),
  placeOfServiceCode: z.string().optional(),
  claimType: claimsClaimTypeSchema.optional(),
});

export const billingBillingAuthorizationsQueryExpirationTypeSchema = z.union([
  z.literal('until_due_3'),
  z.literal('until_due_7'),
  z.literal('past_due_7'),
  z.literal('past_due_30'),
]);

export const billingBillingAuthorizationsSortOptionSchema = z
  .union([
    z.literal('createdAtAsc'),
    z.literal('createdAtDesc'),
    z.literal('patientNameAsc'),
    z.literal('patientNameDesc'),
  ])
  .nullable();

export const billingBillingAuthorizationsQuerySchema = z.object({
  startDate: z.string().optional(),
  endDate: z.string().optional(),
  expiration: billingBillingAuthorizationsQueryExpirationTypeSchema,
  patientIds: z.array(z.string()).optional(),
  payerIds: z.array(z.string()).optional(),
  careTeamUserIds: z.array(z.string()).optional(),
  procedureCodes: z.array(z.string()).optional(),
  hasUnbilled: z.boolean().optional(),
  limit: z.number().optional(),
  offset: z.number().optional(),
  sortBy: billingBillingAuthorizationsSortOptionSchema.optional(),
});

export const billingUtilizationReviewSchema = z.object({
  patient: patientPatientSchema,
  payer: patientInsurancePayerSchema,
  authorization: patientAuthorizationSchema,
  authorizedDays: z.number(),
  services: z.array(billingDailyBillableServiceSchema),
  totalClaims: z.number(),
});

export const billingBillingProgramPostBodySchema = z.object({
  programId: z.string(),
  selectedLOCs: z.array(z.string()),
});

export const billingBillingRuleAdditiveTypeSchema = z.union([
  z.literal('add_on_code'),
  z.literal('cpt_modifier'),
]);

export const billingBillingRuleAdditiveResponseSchema = z.object({
  modifierId: z.string(),
  modifierName: z.string(),
  type: billingBillingRuleAdditiveTypeSchema,
  value: z.string(),
});

export const billingBillingRuleOverrideTypeSchema = z.literal('pos_code');

export const billingRuleOverrideSchema = z.object({
  modifierId: z.string().nullable(),
  modifierName: z.string(),
  payerGroupId: z.string().nullable(),
  payerGroupName: z.string(),
  programId: z.string().nullable(),
  programLOC: z.string(),
  posCode: z.string(),
  revenueCode: z.string(),
  cptCode: z.string(),
  claimType: z.string(),
  renderingProviderId: z.string(),
  addOnCode: z.string(),
  cptModifiers: z.array(z.string()),
});

export const billingModifierSchema = z.object({
  id: z.string(),
  name: z.string(),
  createdAt: z.date(),
});

export const billingBillingRuleAdditiveSchema = z.object({
  modifierId: z.string(),
  type: billingBillingRuleAdditiveTypeSchema,
  value: z.string(),
});

export const billingBillingRuleOverrideSchema = z.object({
  modifierId: z.string(), // TODO: make optional when supporting payer groups
  type: billingBillingRuleOverrideTypeSchema,
  value: z.string(),
});

export const billingProcedureCodeSetSchema = z.object({
  id: z.number(),
  name: z.string(),
  primaryProcedureCode: z.string(),
  secondaryProcedureCodes: z.array(z.string()),
  createdAt: z.date(),
});

export const billingListFreeEncountersQuerySchema = z.lazy(() =>
  z.object({
    startDate: z.date(),
    endDate: z.date(),
    patientIds: z.array(z.string()),
    offset: z.number().optional(),
    limit: z.number().optional(),
    ruleResults: z.array(billingBillingRuleResultSchema).optional(),
    attendanceTypes: z.array(encountersEncounterAttendanceSchema).optional(),
    templateIds: z.array(z.string()).optional(),
    sortBy: z.string().optional(),
  }),
);

export const billingBillingRuleSchema = z.lazy(() =>
  z.object({
    id: z.string(),
    procedureCode: z.string(),
    addOnCodes: z.array(z.string()),
    revenueCode: z.string(),
    encounterTemplates: z.array(encountersEncounterTemplateSchema),
    expectedRate: z.number().optional(),
    billedRate: z.number().optional(),
    isBundled: z.boolean(),
    payerGroups: z.array(clinicPayerGroupSchema),
    noActivePrograms: z.boolean(),
    billingPrograms: z.array(billingBillingProgramSchema),
    ruleName: z.string(),
    minimumBundledMinutes: z.number().optional(),
    hasDuration: z.boolean(),
    minimumDurationMinutes: z.number().optional(),
    maximumDurationMinutes: z.number().optional(),
    procedureCodeModifiers: z.array(z.string()),
    unitIntervalMinutes: z.number(),
    provider: clinicProviderSchema.nullable(),
    placeOfServiceCode: catalogPlaceOfServiceCodeSchema.nullable(),
    useEncounterCreatorProvider: z.boolean(),
    useFeeSchedule: z.boolean(),
    isDraft: z.boolean(),
    additiveAttributes: z.array(billingBillingRuleAdditiveResponseSchema),
    overrides: z.array(billingRuleOverrideSchema),
    position: z.number(),
    claimType: claimsClaimTypeSchema.optional(),
    admissionFencepostType: billingFencepostTypeSchema,
    admissionCutoffHours: z.number(),
    admissionCutoffMinutes: z.number(),
    dischargeFencepostType: billingFencepostTypeSchema,
    dischargeCutoffHours: z.number(),
    dischargeCutoffMinutes: z.number(),
    shouldAutogenerateServices: z.boolean(),
  }),
);

export const billingFencepostTypeSchema = z.lazy(() =>
  z.union([
    z.literal('bill on fencepost day'),
    z.literal('no bill on fencepost day'),
    z.literal('bill using event time'),
    z.literal('bill using cutoff time'),
  ]),
);

export const billingBillingRuleFormRuleSchema = z.lazy(() =>
  billingBillingRuleSchema.schema.extend({
    billingProgramsAndLOCs: z.array(billingBillingProgramAndLOCSchema),
  }),
);

export const billingUtilizationReviewSetSchema = z.object({
  reviews: z.array(billingUtilizationReviewSchema),
  totalCount: z.number(),
});

export const billingBillingRulePostBodySchema = z.lazy(() =>
  z.object({
    noActivePrograms: z.boolean(),
    billingPrograms: z.array(billingBillingProgramPostBodySchema),
    payerGroupIds: z.array(z.string()),
    encounterTemplates: z.array(
      encountersEncounterTemplateSchema.pick({ id: true, modifierId: true }),
    ),
    procedureCode: z.string(),
    addOnCodes: z.array(z.string()).optional(),
    revenueCode: z.string().optional(),
    billedRate: z.number().optional(),
    expectedRate: z.number().optional(),
    isBundled: z.boolean(),
    ruleName: z.string(),
    minimumBundledMinutes: z.number().optional(),
    hasDuration: z.boolean(),
    minimumDurationMinutes: z.number().optional(),
    maximumDurationMinutes: z.number().optional(),
    procedureCodeModifiers: z.array(z.string()).optional(),
    unitIntervalMinutes: z.number(),
    useEncounterCreatorProvider: z.boolean().optional(),
    useFeeSchedule: z.boolean().optional(),
    providerId: z.string().optional(),
    placeOfServiceCode: z.string().optional(),
    additiveAttributes: z.array(billingBillingRuleAdditiveSchema),
    overrides: z.array(billingBillingRuleOverrideSchema),
    claimType: claimsClaimTypeSchema.optional(),
    admissionFencepostType: billingFencepostTypeSchema,
    admissionCutoffHours: z.number(),
    admissionCutoffMinutes: z.number(),
    dischargeFencepostType: billingFencepostTypeSchema,
    dischargeCutoffHours: z.number(),
    dischargeCutoffMinutes: z.number(),
    shouldAutogenerateServices: z.boolean(),
  }),
);

export const billingRulePatchBodySchema = z.lazy(() =>
  z
    .object({
      id: z.string(),
      position: z.number(),
    })
    .extend(billingBillingRulePostBodySchema.schema.shape),
);

export const censusServiceSchema = z.object({
  id: z.string(),
  programId: z.string(),
  serviceDate: z.date(),
  procedureCode: z.string(),
  authEntryId: z.string(),
  dbsClaimType: z.string(),
  claimId: z.string().nullable(),
  claimStatus: z.string(),
  claimType: z.string(),
});

export const censusProgramDetailsSchema = z.object({
  id: z.string(),
  levelOfCare: z.string(),
  programName: z.string(),
  programType: z.string(),
});

export const censusProgramDataSchema = z.object({
  admitDate: z.date(),
  dischargeDate: z.date(),
  program: censusProgramDetailsSchema,
  services: z.array(censusServiceSchema),
});

export const censusDataSchema = z.object({
  date: z.string(),
  encountersCount: z.number(),
  programData: z.array(censusProgramDataSchema),
});

export const censusAuthorizationSchema = z.object({
  entry: patientAuthorizationEntrySchema,
  trackingNumber: z.string(),
});

export const censusPatientSchema = z.object({
  clientId: z.string(),
  clientName: patientPatientNameSchema,
  programs: z.array(censusProgramDataSchema),
  authorizations: z.array(censusAuthorizationSchema),
  payers: z.array(z.string()),
  data: z.array(censusDataSchema),
});

export const censusPatientQuerySchema = z.object({
  startDate: z.string(),
  endDate: z.string(),
  searchText: z.string(),
  programIds: z.array(z.string()),
  levelsOfCare: z.array(z.string()),
  payerIds: z.array(z.string()),
  limit: z.number(),
  offset: z.number().optional(),
});
