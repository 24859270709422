import { AxiosResponseHeaders } from 'axios';
import { from } from 'rxjs';

import { getFileNameFromHeaders } from 'utils';
import { parseArrayDates, parseISODateStrings, StringifyKeys } from 'utils/dateParsers';
import RittenClient from './RittenClient';

export default class EncounterClient extends RittenClient {
  listEncounterTemplates = async (
    query: Encounters.EncounterTemplateQuery = {},
  ): Promise<Encounters.EncounterTemplate[]> => {
    if (query.showArchived == null) {
      query.showArchived = false;
    }
    const res = await this.get<Encounters.EncounterTemplate[]>('/api/encounters/templates', {
      params: query,
    });
    return res.data;
  };

  listIndividualEncounterTemplates = async (
    query: Encounters.EncounterTemplateQuery = {},
  ): Promise<Encounters.EncounterTemplate[]> => {
    if (query.showArchived == null) {
      query.showArchived = false;
    }

    // this function only lists individual encounter templates, so set isGroup=false
    query.isGroup = false;

    const res = await this.get<Encounters.EncounterTemplate[]>('/api/encounters/templates', {
      params: query,
    });
    return res.data;
  };

  listGroupEncounterTemplates = async (
    query: Encounters.EncounterTemplateQuery = {},
  ): Promise<Encounters.EncounterTemplate[]> => {
    if (query.showArchived == null) {
      query.showArchived = false;
    }

    // this function only lists group encounter templates, so set isGroup=true
    query.isGroup = true;

    const res = await this.get<Encounters.EncounterTemplate[]>('/api/encounters/templates', {
      params: query,
    });
    return res.data;
  };

  listEncounterTemplates$ = (query: Encounters.EncounterTemplateQuery = {}) =>
    from(this.listEncounterTemplates(query));

  getEncounterTemplateByID = async (id: string): Promise<Encounters.EncounterTemplate> => {
    const res = await this.get<Encounters.EncounterTemplate>(`/api/encounters/templates/${id}`);
    return res.data;
  };

  postEncounterTemplate = async (
    postBody: Encounters.EncounterTemplatePostBody,
  ): Promise<API.IDResponse> => {
    const res = await this.post<API.IDResponse>('/api/encounters/templates', postBody);
    return res.data;
  };

  postEncounterTemplate$ = (postBody: Encounters.EncounterTemplatePostBody) =>
    from(this.postEncounterTemplate(postBody));

  patchEncounterTemplate = async (
    patchBody: Encounters.EncounterTemplatePatchBody[],
  ): Promise<void> => {
    await this.patch<void>('/api/encounters/templates', patchBody);
  };

  getEncounterConfigurationCsv = async () => {
    const params: any = {
      csv: true,
    };
    const res = await this.get<Blob>(`api/encounters/templates/configuration`, {
      params,
      responseType: 'blob',
    });
    const headers = res.headers as AxiosResponseHeaders;
    const fileName = getFileNameFromHeaders(headers['content-disposition']);
    const result = { fileName, file: res.data };
    return result;
  };

  postGroupEncounter = async (
    postBody: Encounters.GroupEncounterPostBody,
  ): Promise<API.IDResponse> => {
    const res = await this.post<API.IDResponse>('/api/encounters/group/instances', postBody);
    return res.data;
  };

  getGroupEncounter = async (id: string): Promise<Encounters.GroupEncounterFull> => {
    const res = await this.get<Encounters.GroupEncounterFull>(
      `/api/encounters/group/instances/${id}`,
    );
    return parseISODateStrings(res.data);
  };

  deleteGroupEncounter = async (id: string): Promise<void> => {
    await this.delete<void>(`/api/encounters/group/instances/${id}`);
  };

  exportGroupEncounterAsync = async (id: string): Promise<void> => {
    await this.post<void>(`/api/exports/async/group-encounter/${id}`);
  };

  patchGroupEncounter = async (
    id: string,
    patchBody: Pick<Encounters.GroupEncounter, 'startTime'> &
      Partial<Pick<Encounters.GroupEncounter, 'endTime'>> &
      Partial<Pick<Encounters.GroupEncounter, 'title'>>,
  ): Promise<void> => {
    await this.patch<void>(`/api/encounters/group/instances/${id}`, patchBody);
  };

  postGroupEncounterIndividual = async (
    groupEncounterId: string,
    postBody: Encounters.GroupEncounterIndividualPostBody,
  ): Promise<void> => {
    await this.post<void>(
      `/api/encounters/group/instances/${groupEncounterId}/individuals`,
      postBody,
    );
  };

  patchGroupEncounterIndividual = async (
    groupEncounterId: string,
    groupEncounterIndividualId: string,
    patchBody: Partial<
      Pick<Encounters.GroupEncounterIndividual, 'attendanceType' | 'startTime' | 'endTime'>
    >,
  ): Promise<void> => {
    await this.patch<void>(
      `/api/encounters/group/instances/${groupEncounterId}/individuals/${groupEncounterIndividualId}`,
      patchBody,
    );
  };

  deleteGroupEncounterIndividualById = async (
    groupEncounterId: string,
    groupEncounterIndividualId: string,
  ): Promise<void> => {
    await this.delete<void>(
      `/api/encounters/group/instances/${groupEncounterId}/individuals/${groupEncounterIndividualId}`,
    );
  };

  listGroupEncounters = async (
    query: Encounters.GroupEncounterQuery = {},
  ): Promise<Encounters.GroupEncounter[]> => {
    const res = await this.get<
      StringifyKeys<Encounters.GroupEncounter, 'createdAt' | 'startTime' | 'endTime'>[]
    >('/api/encounters/group/instances', {
      params: query,
    });
    const convertedRes = parseArrayDates(res.data, ['createdAt', 'startTime', 'endTime']);
    return convertedRes;
  };

  postGroupEncounterSubmit = async (groupEnounterId: string): Promise<void> => {
    await this.post<void>(`/api/encounters/group/instances/${groupEnounterId}/submit`);
  };

  postGroupEncounterEntity = async (
    groupEnounterId: string,
    body: Encounters.GroupEncounterEntity,
  ): Promise<void> => {
    await this.post<void>(`/api/encounters/group/instances/${groupEnounterId}/entity`, { ...body });
  };

  // listEncounters returns a list of encounters.
  // Note: sending an empty patientIds array will return encounters for ALL patients
  // sending a non-empty patientIds array will filter encounters for just those patients
  listEncounters = async (
    query: Encounters.EncountersQuery = {},
  ): Promise<Encounters.Encounter[]> => {
    const res = await this.get<
      StringifyKeys<Encounters.Encounter, 'createdAt' | 'startTime' | 'endTime'>[]
    >('/api/encounters/instances', {
      params: {
        ...query,
      },
    });
    const convertedRes = parseArrayDates(res.data, ['createdAt', 'startTime', 'endTime']);
    return convertedRes;
  };

  getEncountersV2 = async (
    query: Encounters.EncountersQueryV2 = {},
  ): Promise<Encounters.Encounter[]> => {
    const res = await this.get<
      StringifyKeys<Encounters.Encounter, 'createdAt' | 'startTime' | 'endTime'>[]
    >('/api/encounters/instances/v2', {
      params: {
        ...query,
      },
    });
    const convertedRes = parseArrayDates(res.data, ['createdAt', 'startTime', 'endTime']);
    return convertedRes;
  };

  getEncounterById = async (id: string): Promise<Encounters.Encounter> => {
    const res = await this.get<Encounters.Encounter>(`/api/encounters/instances/${id}`);
    return parseISODateStrings(res.data);
  };

  exportSingleEncounterAsync = async (
    id: string,
    encounterExportObject: Encounters.EncounterExportPostBody,
  ): Promise<void> => {
    await this.post<void>(`/api/exports/async/patient/encounters/${id}`, encounterExportObject);
  };

  archiveEncounterTemplate = async (encounterTemplateId: string): Promise<void> => {
    await this.delete<API.IDResponse>(`/api/encounters/templates/${encounterTemplateId}`);
  };

  postEncounterTemplateDocument = async (
    encounterTemplateId: string,
    encounterTemplateDocument: Encounters.EncounterTemplateDocumentPostBody,
  ): Promise<API.IDResponse> => {
    const res = await this.post<API.IDResponse>(
      `/api/encounters/templates/${encounterTemplateId}/documents`,
      encounterTemplateDocument,
    );
    return res.data;
  };

  exportSingleEncounter = async (
    id: string,
    encounterExportObject: Encounters.EncounterExportPostBody,
  ) => {
    const res = await this.post<Blob>(
      `/api/exports/patient/encounters/${id}`,
      encounterExportObject,
      {
        responseType: 'blob',
      },
    );
    return res;
  };

  deleteEncounter = async (id: string): Promise<Encounters.Encounter> => {
    const res = await this.delete<Encounters.Encounter>(`/api/encounters/instances/${id}`);
    return res.data;
  };

  postEncounter = async (
    encounterCreationObject: Encounters.EncounterPostBody,
  ): Promise<Encounters.PostEncounterResponse> => {
    const res = await this.post<Encounters.PostEncounterResponse>(
      '/api/encounters/instances',
      encounterCreationObject,
    );
    return parseISODateStrings(res.data);
  };

  patchEncounter = async (
    id: string,
    attendanceType: Encounters.EncounterAttendance,
    encounterTimeObject: { startTime: Date; endTime?: Date | null },
    encounterType?: string,
  ): Promise<void> => {
    await this.patch<void>(`/api/encounters/instances/${id}`, {
      ...encounterTimeObject,
      attendanceType,
      encounterType,
    });
  };

  startEncounterForm = async (
    encounterID: string,
    formDefinitionID: string,
  ): Promise<Encounters.FormInstanceIDResponse> => {
    const res = await this.post<Encounters.FormInstanceIDResponse>(
      `/api/encounters/instances/${encounterID}/start-form/${formDefinitionID}`,
    );
    return res.data;
  };

  putEncounterModifiers = async (encounterId: string, modifierIds: string[]): Promise<void> => {
    await this.put(`/api/encounters/instances/${encounterId}/modifiers`, {
      modifierIds,
    });
  };

  putGroupEncounterModifiers = async (
    groupEncounterId: string,
    modifierIds: string[],
  ): Promise<void> => {
    await this.put(`/api/encounters/group/instances/${groupEncounterId}/modifiers`, {
      modifierIds,
    });
  };

  postGroupEncounterDocument = async (groupEncounterId: string, documentId: string) => {
    await this.post(`/api/encounters/group/instances/${groupEncounterId}/documents/${documentId}`);
  };

  patchSupervisingProvider = async (encounterId: string, providerId: string) => {
    await this.patch(`/api/encounters/instances/${encounterId}/supervising-provider`, {
      supervisingProviderId: providerId,
    });
  };

  patchSupervisingProviderGroupEncounter = async (groupEncounterId: string, providerId: string) => {
    await this.patch(`/api/encounters/group/instances/${groupEncounterId}/supervising-provider`, {
      supervisingProviderId: providerId,
    });
  };
}
