// @ts-strict-ignore
/**
 *
 * We have a layer in our API Clients that converts any API-provided ISO strings to JS Dates.
 * This file contains util functions responsible for operating on JS Dates.
 *
 * Note: functions are in alphabetical order!
 *
 * date-fns: https://date-fns.org/
 *
 */

import {
  addDays,
  compareDesc,
  differenceInCalendarDays,
  differenceInMinutes,
  format,
  formatDistanceStrict,
  formatDuration,
  formatISO,
  getDate,
  getHours,
  getMinutes,
  getMonth,
  getYear,
  intervalToDuration,
  isAfter,
  isBefore,
  isEqual,
  isSameDay,
  isToday,
  isValid,
  isYesterday,
  lastDayOfMonth,
} from 'date-fns';
import { formatInTimeZone, zonedTimeToUtc as fromZonedTime } from 'date-fns-tz';
import { times } from 'lodash';

import { LabelValueDropdownChoice } from '@ritten/ui-library/dropdowns/Dropdown';

import { containsIgnoreCase, isEmpty } from 'utils';
import { getDayForISOString, getHoursMinutesObject, getMonthForISOString } from './timeUtils';

/**
 *
 * @param date
 * @param numDaysToAdd to the date
 * @returns a new date that is the date passed in + the numDaysToAdd
 */
export const addDaysToDate = (date: Date, numDaysToAdd: number) => {
  if (!isValidDate(date)) {
    return null;
  }

  // if numDaysToAdd is null just return the date
  if (!numDaysToAdd) {
    return date;
  }

  const newDate = addDays(date, numDaysToAdd);

  return newDate;
};

/**
 *
 * @param date
 * @returns iso string representing date with local timezone
 */
export const convertDateToISOWithLocalTime = (date?: Date): string => {
  if (!date) {
    return null;
  }
  return formatISO(date, { representation: 'complete' });
};

/**
 *
 * @param date
 * @returns 'Today' if the date argument is today, 'Yesterday' if yesterday, otherwise the formatted date
 */
export const displayDateLabelOrFormattedDate = (date: Date) => {
  if (isToday(date)) {
    return 'Today';
  }
  if (isYesterday(date)) {
    return 'Yesterday';
  }
  return formatDateMMDDYYYYSlashes(date);
};

export const getRelativeOrSpecificWeekdayLabel = (date: Date) => {
  if (isToday(date)) {
    return 'Today';
  }
  if (isYesterday(date)) {
    return 'Yesterday';
  }
  return format(date, 'EEEE'); // Monday, Tuesday, ..., Sunday
};

/**
 *
 * @param date JS Date
 * @returns the JS Date passed in as a string in the format mm/dd/yyyy
 * If the Date passed in is invalid, returns an empty string
 */
export const formatDateMMDDYYYYSlashes = (date?: Date | null): string => {
  if (date && isValidDate(date)) {
    const result = format(date, 'MM/dd/yyyy');
    return result;
  }
  return '';
};

/**
 *
 * @param date JS Date
 * @returns the JS Date passed in as a string in the format mm/dd/yy
 * If the Date passed in is invalid, returns an empty string
 */
export const formatDateMMDDYYSlashes = (date?: Date | null): string => {
  if (date && isValidDate(date)) {
    const result = format(date, 'MM/dd/yy');
    return result;
  }
  return '';
};

export const getDateOrDateRange = (date1?: Date | null, date2?: Date | null) => {
  if (isValidDate(date1) && isValidDate(date2)) {
    if (isSameDay(date1, date2)) {
      return formatDateMMDDYYYYSlashes(date1);
    }
    return `${formatDateMMDDYYYYSlashes(date1)} - ${formatDateMMDDYYYYSlashes(date2)}`;
  }

  // not valid date
  return null;
};

export const formatDateYearMonthDayDashes = (date: Date | null) => {
  if (!date || !isValidDate(date)) {
    return '';
  }

  return format(date, 'yyyy-MM-dd');
};

export const formatDateMMDDYYYYSlashesWithTime = (date: Date | null): string => {
  if (date && isValidDate(date)) {
    return format(date, 'Pp');
  }

  return '';
};

export const formatDateMMDDYYYYSlashesWithTimeAndTimezone = (date: Date | null): string => {
  if (date && isValidDate(date)) {
    return `${format(date, 'Pp')} ${getTimeZoneAbbreviation()}`;
  }

  return '';
};

/**
 * Example: Tuesday, May 3, 2022
 */
export const formatDateFullWeekdayMonthDay = (date: Date): string => {
  return format(date, 'EEEE, LLLL do, yyyy');
};

export const getAllDatesInBetween = (startDate: Date, endDate: Date): Date[] => {
  if (!startDate || !endDate) {
    return [];
  }
  if (!isDateBefore(startDate, endDate)) {
    return [];
  }

  const datesInBetween = [];
  let nextDay = startDate;
  while (!isSameDay(nextDay, endDate)) {
    datesInBetween.push(nextDay);
    nextDay = addDays(nextDay, 1);
  }
  datesInBetween.push(endDate);
  return datesInBetween;
};

export const getAllDatesInBetweenInclusive = (startDate: Date, endDate: Date): Date[] => {
  if (!startDate || !endDate) {
    return [];
  }
  if (!isDateBeforeOrSame(startDate, endDate)) {
    return [];
  }
  if (isSameDay(startDate, endDate)) {
    return [startDate];
  }

  const datesInBetween = [];
  let nextDay = startDate;
  while (isDateBefore(nextDay, endDate)) {
    datesInBetween.push(nextDay);
    nextDay = addDays(nextDay, 1);
  }
  datesInBetween.push(endDate);
  return datesInBetween;
};

export const getCurrentYear = () => {
  const d = new Date();
  return d.getFullYear();
};

export const getDateFilterLabel = (startDate?: Date, endDate?: Date) => {
  if (!startDate && !endDate) {
    return 'Select Date Range';
  }

  if (startDate && !endDate) {
    return `${formatDateMMDDYYYYSlashes(startDate)} - `;
  }

  if (endDate && !startDate) {
    return ` - ${formatDateMMDDYYYYSlashes(endDate)}`;
  }

  return `${formatDateMMDDYYYYSlashes(startDate)} - ${formatDateMMDDYYYYSlashes(endDate)}`;
};

/**
 *
 * @param date
 * @param time
 * @returns a date that combines the date and time passed in into one date object
 */
export const getDateFromDateAndTime = (date: Date | undefined, time: string) => {
  // return null if the date passed in is invalid
  if (!isValidDate(date)) {
    return null;
  }

  // get the hours minutes object, if its null then time is invalid, return null
  const hoursMinutesObjectFromFormattedTime = getHoursMinutesObject(time);
  if (!hoursMinutesObjectFromFormattedTime) {
    return null;
  }

  const month = getMonth(date);
  const day = getDate(date);
  const year = getYear(date);

  const hours = hoursMinutesObjectFromFormattedTime.hour;
  const minutes = hoursMinutesObjectFromFormattedTime.minute;

  const fullDate = new Date(year, month, day, hours, minutes);

  return fullDate;
};

export const getDifferenceInMinutes = (startDate: Date, endDate: Date) => {
  if (isValidDate(startDate) && isValidDate(endDate)) {
    const difference = differenceInMinutes(endDate, startDate);
    return difference;
  }
  return null;
};

export const getDifferenceInCalendarDays = (startDate: Date, endDate: Date) => {
  if (isValidDate(startDate) && isValidDate(endDate)) {
    const difference = differenceInCalendarDays(endDate, startDate);
    return difference;
  }
  return null;
};

export const getDurationText = (start: Date, end: Date | null) => {
  if (!start || !end) {
    return '';
  }
  if (!isDateBefore(start, end)) {
    return '';
  }
  const duration = intervalToDuration({ start, end });
  return formatDuration(duration);
};

export const getDurationTextAbbreviated = (start: Date, end: Date) => {
  const text = getDurationText(start, end);
  // Leading space is included because that's how date-fns formats 2 hours
  return text.replace(' hours', 'hr').replace(' hour', 'hr').replace(' minutes', 'm');
};

/**
 * Returns { startDate, endDate } where startDate is the first day of the month
 * and endDate is the lastDay of the month for the given month and current year.
 * @param {number} month
 * @returns {{startDate, endDate}}
 */
export const getFirstAndLastDayForMonth = (month: number) => {
  const currentYear = getYear(new Date());
  return getFirstAndLastDayForMonthInYear(currentYear, month);
};

/**
 * Returns { startDate, endDate } where startDate is the first day of the month
 * and endDate is the lastDay of the month for the given year and month.
 * @param {number} month
 * @returns {{startDate, endDate}}
 */
export const getFirstAndLastDayForMonthInYear = (year: number, month: number) => {
  if (month > 11) {
    throw new Error(`Month is out of range, should be 11 or less. You sent in ${month}`);
  }
  const startDate = new Date(year, month, 1, 0, 0, 0, 0);
  const endDate = lastDayOfMonth(startDate);

  return { startDate, endDate };
};

/**
 * Returns { startDate, endDate } where startDate is the first day of the month
 * and endDate is the lastDay of the month for the previous month.
 * @param {number} month
 * @returns {{startDate, endDate}}
 */
export const getFirstAndLastDayForPreviousMonth = () => {
  const today = new Date();
  return getFirstAndLastDayForMonth(getMonth(today) - 1);
};

/**
 *
 * @param date to extract time from
 * @returns a string of the time in the date passed in, in the format "hh:mm pm"
 */
export const getFormattedTimeFromDate = (date: Date | null | undefined) => {
  if (isValidDate(date)) {
    let hour = getHours(date);
    let minutes = getMinutes(date).toString();

    const amOrPm = hour >= 12 ? 'pm' : 'am';

    if (hour > 12) {
      hour -= 12;
    } else if (hour === 0) {
      hour += 12;
    }

    if (parseInt(minutes) < 10) {
      minutes = `0${minutes}`;
    }

    return `${hour}:${minutes} ${amOrPm}`;
  }

  return '';
};

export const getEndOfDay = (date: Date | null): Date | null => {
  if (date && isValidDate(date)) {
    const year = date.getFullYear();

    const monthIndex = date.getMonth();
    const ISOMonth = getMonthForISOString(monthIndex);

    const day = date.getDate();
    const ISODay = getDayForISOString(day);

    const endOfDayStr = `${year}-${ISOMonth}-${ISODay}T23:59:59.999Z`;

    const endOfDay = new Date(endOfDayStr);

    return endOfDay;
  }
  return null;
};

export const getStartOfDay = (date: Date | null): Date | null => {
  if (date && isValidDate(date)) {
    const year = date.getFullYear();

    const monthIndex = date.getMonth();
    const ISOMonth = getMonthForISOString(monthIndex);

    const day = date.getDate();
    const ISODay = getDayForISOString(day);

    const startOfDayStr = `${year}-${ISOMonth}-${ISODay}T00:00:00.000Z`;

    const startOfDay = new Date(startOfDayStr);

    return startOfDay;
  }
  return null;
};

export const getMonthYearFromDate = (date: Date) => {
  if (isValidDate(date)) {
    return `${date.getMonth() + 1}/${date.getFullYear()}`;
  }
  return '';
};

export const getNowDateAndTime = () => {
  const now = new Date();
  return {
    nowDate: now.toISOString().replace('+00:00', 'Z'),
    nowTime: getFormattedTimeFromDate(now),
  };
};

/**
 *
 * @param date1 first date as JS Date
 * @param date2 second date as JS Date
 * @returns true if the first date is before the second date, false if the second date is after
 * the first date of if they are the same date
 */
export const isDateBefore = (date1: Date, date2: Date): boolean => {
  const compareDates = compareDesc(date1, date2);

  // compareDesc will return 1 if the start date is before the end date
  if (compareDates === 1) {
    return true;
  }

  // if it returns -1 or 0, return false
  return false;
};

export const isDateBeforeOrSame = (date1: Date | null, date2: Date | null): boolean => {
  const compareDates = compareDesc(date1, date2);

  // compareDesc will return 1 if the start date is before the end date
  if (compareDates === 1 || compareDates === 0) {
    return true;
  }

  // if it returns -1, return false
  return false;
};

export const isDateAfterOrSame = (date: Date, dateToCompare: Date): boolean => {
  return isAfter(date, dateToCompare) || isEqual(date, dateToCompare);
};

export const isDateBetween = (
  date: Date,
  { startDate, endDate }: { startDate: Date; endDate: Date },
): boolean => {
  if (!date || !startDate || !endDate) {
    return false;
  }
  return isBefore(date, endDate) && isAfter(date, startDate);
};

export const isDateBetweenInclusive = (
  date: Date,
  { startDate, endDate }: { startDate: Date; endDate: Date },
): boolean => {
  if (!date || !startDate || !endDate) {
    return false;
  }
  return isDateBeforeOrSame(date, endDate) && isDateAfterOrSame(date, startDate);
};

/**
 *
 * @param date to determine if valid
 * @returns true if date passed in is a valid date, else false
 */
export const isValidDate = (date?: Date | null): boolean => {
  if (isValid(date)) {
    return true;
  }

  return false;
};

/**
 *
 * @param date1 JS Date
 * @param date2 JS Date
 * @returns the number of days between date1 and date2 as a number
 * will return -1 if either dates passed in are invalid
 */
export const numberOfDaysBetweenDates = (date1: Date, date2: Date): number => {
  if (isValidDate(date1) && isValidDate(date2)) {
    const daysBetween = formatDistanceStrict(date1, date2, {
      unit: 'day',
    });

    return parseInt(daysBetween);
  }

  return -1;
};

/**
 * If local storage has saved the date as a string, we need to convert it back to a date
 * @param stringOrDate
 * @returns
 */
export const maybeConvertToDate = (stringOrDate: Date | string | null | undefined) => {
  if (!stringOrDate) {
    return null;
  }

  if (stringOrDate instanceof Date) {
    return stringOrDate;
  }
  const stringAsDate = new Date(stringOrDate);

  if (isValidDate(stringAsDate)) {
    return stringAsDate;
  }

  // otherwise return null
  return null;
};

/**
 * Returns a comprehensive list of IANA Time Zones.
 * e.g. ["America/New_York", "America/Los_Angeles", ...]
 */
export const getAllTimeZones = (defaultTimeZone?: string): string[] => {
  if (window.Intl?.supportedValuesOf) {
    const timeZones = window.Intl.supportedValuesOf('timeZone');
    return timeZones.map((tz) => tz);
  }
  return [defaultTimeZone];
};

/**
 * Returns a list of time zone options to be used with the DropdownComponent.
 */
export const getTimeZoneOptions = (defaultTimeZone?: string): LabelValueDropdownChoice[] =>
  getAllTimeZones(defaultTimeZone).map((tz) => ({
    label: tz,
    value: tz,
  }));

export const searchTimeZoneOptions = (searchValue: string, options: LabelValueDropdownChoice[]) => {
  if (isEmpty(searchValue)) {
    return options;
  }
  const filtered = options
    .filter((tz) => containsIgnoreCase(tz.label as string, searchValue))
    .filter((tz) => containsIgnoreCase(tz.value, searchValue));
  return filtered;
};

/**
 * Returns the browser session IANA time zone (e.g. America/New_York).
 */
export const getLocalTimeZone = (defaultTimeZone: string = 'Etc/UTC'): string => {
  if (window.Intl?.supportedValuesOf) {
    return window.Intl.DateTimeFormat().resolvedOptions().timeZone;
  }
  return defaultTimeZone;
};

export const getTimeZoneAbbreviation = (timeZoneName?: string): string => {
  return new Date()
    .toLocaleTimeString('en-us', { timeZoneName: 'short', timeZone: timeZoneName })
    .split(' ')[2];
};

/**
 * Takes an array of dates and returns a unique array of years.  Ex: [2019, 2029, 2021]
 * @param {Date[]|null} dates
 * @returns {number[]}
 */
export const getYearRange = (dates: Date[] | null) => {
  if (!dates || dates?.length === 0) {
    return [];
  }

  let minYear: number;
  let maxYear: number;

  dates.forEach((d) => {
    const year = d.getFullYear();

    if (!minYear) {
      minYear = year;
    }
    if (!maxYear) {
      maxYear = year;
    }

    if (year < minYear) {
      minYear = year;
    } else if (year > maxYear) {
      maxYear = year;
    }
  });

  const yearDiff = maxYear - minYear;
  const yearRange = yearDiff === 0 ? [minYear] : times(yearDiff + 1, (n) => n + minYear);

  return yearRange;
};

/**
 * Converts a local Date object to its UTC equivalent with the time set to midnight.
 * The resulting Date object will have the same year, month, and day values in UTC.
 *
 * @param {Date} localDate - The local date to be converted.
 * @returns {Date} The UTC date with time set to midnight.
 */
export const convertLocalDateToUTCDateOnly = (localDate: Date): Date | null => {
  if (!localDate) {
    return null;
  }
  const year = getYear(localDate);
  const month = getMonth(localDate);
  const day = getDate(localDate);

  // Create a UTC date with time set to midnight
  return new Date(Date.UTC(year, month, day));
};

/**
 * Converts a UTC date string to a local Date object with the same year, month, and day.
 * The resulting Date object will display the same Y/M/D in the local timezone, with the time set to midnight.
 *
 * @param {Date|string} utcDate - The UTC date to be converted.
 * @returns {Date} The local date object with matching Y/M/D.
 */
export const convertUTCDateToLocalDateOnly = (utcDate: string | Date): Date => {
  // Ensure the utc date is a Date object
  utcDate = new Date(utcDate);

  // Extract year, month, and day from the UTC date
  const year = utcDate.getUTCFullYear();
  const month = utcDate.getUTCMonth();
  const day = utcDate.getUTCDate();

  // Create a local date object with the same Y/M/D at midnight
  return new Date(year, month, day);
};

export const isValidTime = (time: string): boolean => {
  if (time.length < 7 || time.length > 8) {
    return false;
  }
  if (!time.includes(':')) {
    return false;
  }
  if (time.split(' ').length < 2) {
    return false;
  }
  if (!time.substring(time.length - 2).match('am|pm|AM|PM')) {
    return false;
  }

  return true;
};

export const getFullDateAndTimeInTimeZone = (tz: string, date?: Date) => {
  return formatInTimeZone(date ?? new Date(), tz, 'h:mm a zz, LLLL do yyyy');
};

// Uses a base date and time string (HH:MM:SS format) to create a new date in a specified timezone.
export const createDateAndTimeInSpecifiedTimezone = (
  date: Date,
  time: string,
  timeZone: string,
): Date | null => {
  if (/\d{1,2}:\d{2}:\d{2}/.test(time)) {
    // Format the date components separately
    const year = date.getFullYear();
    const month = (date.getMonth() + 1).toString().padStart(2, '0'); // Month is zero-based
    const day = date.getDate().toString().padStart(2, '0');
    const hours = time.substring(0, 2);
    const minutes = time.substring(3, 5);
    const seconds = time.substring(6, 8);

    // Construct the ISO 8601 formatted date string
    const formattedDate = `${year}-${month}-${day}T${hours}:${minutes}:${seconds}`;

    return fromZonedTime(formattedDate, timeZone);
  }
  return null;
};

// Returns the date range that is the intersection of two date ranges.
export const subtractDateRanges = (a: [Date, Date], b: [Date, Date]): [Date, Date][] => {
  // A = [startA, endA]
  // B = [startB, endB]
  const startA = a[0];
  const endA = a[1];
  const startB = b[0];
  const endB = b[1];

  const result: [Date, Date][] = [];

  // Check if B starts before A
  if (startB < startA) {
    result.push([startB, startA]);
  }

  // Check if B ends after A
  if (endB > endA) {
    result.push([endA, endB]);
  }

  return result;
};

export const getDayOfWeekOfDateInSpecificTimeZone = (date: Date, timeZone: string): string => {
  return date.toLocaleString('en-US', {
    timeZone,
    weekday: 'long',
  });
};
