import { parseISODateStrings } from 'utils';
import RittenClient from './RittenClient';

class TasksClient extends RittenClient {
  postTask = async (task: Tasks.PostTask): Promise<Tasks.Task> => {
    const { data } = await this.post<Tasks.Task>('/api/tasks', task);
    return parseISODateStrings(data);
  };

  getTask = async (taskId: string): Promise<Tasks.Task> => {
    const { data } = await this.get<Tasks.Task>(`/api/tasks/${taskId}`);
    return data;
  };

  listTasks = async (params: Tasks.TasksQueryParams): Promise<Tasks.Task[]> => {
    const { data } = await this.get<Tasks.Task[]>('/api/tasks', {
      params,
    });
    return parseISODateStrings(data);
  };

  deleteTask = async (id: string): Promise<void> => {
    await this.delete(`/api/tasks/${id}`);
  };

  updateTaskStatus = async (taskId: string, status: Tasks.TaskStatus): Promise<void> => {
    await this.patch(`/api/tasks/${taskId}/status`, {
      status,
    });
  };

  updateTaskPriority = async (taskId: string, priority: Tasks.TaskPriority): Promise<void> => {
    await this.patch(`/api/tasks/${taskId}/priority`, {
      priority,
    });
  };

  getTaskComments = async (taskId: string): Promise<Tasks.Comment[]> => {
    const { data } = await this.get<Tasks.Comment[]>(`/api/tasks/${taskId}/comment`);
    return parseISODateStrings(data);
  };

  postTaskComment = async (taskId: string, comment: string): Promise<Tasks.Comment> => {
    const { data } = await this.post<Tasks.Comment>(`/api/tasks/${taskId}/comment`, {
      comment,
    });
    return parseISODateStrings(data);
  };

  updateTaskComment = async (
    taskId: string,
    commentId: string,
    comment: string,
  ): Promise<Tasks.Comment> => {
    const { data } = await this.patch<Tasks.Comment>(`/api/tasks/${taskId}/comment/${commentId}`, {
      comment,
    });
    return parseISODateStrings(data);
  };

  deleteTaskComment = async (taskId: string, commentId: string): Promise<void> => {
    await this.delete(`/api/tasks/${taskId}/comment/${commentId}`);
  };

  addWatcher = async (taskId: string, watcherId: string): Promise<Users.User> => {
    const { data } = await this.post<Users.User>(`/api/tasks/${taskId}/watchers/${watcherId}`);
    return data;
  };

  deleteWatcher = async (taskId: string, watcherId: string): Promise<void> => {
    await this.delete(`/api/tasks/${taskId}/watchers/${watcherId}`);
  };
}

export default TasksClient;
