import { PropsWithChildren, useState } from 'react';
import { NavLink } from 'react-router-dom';
import { Box } from 'grommet';

import { COLORS } from 'styles/colors';
import ProtectedNavLink from './ProtectedNavLink';

const commonSubNavStyle: React.CSSProperties = {
  height: '48px',
  display: 'flex',
  alignItems: 'center',
  fontSize: '16px',
};

const activeSubNavStyle: React.CSSProperties = {
  ...commonSubNavStyle,
  color: COLORS.darkGray600,
  borderBottom: `2px solid ${COLORS.rittenBlue400}`,
};

const inactiveSubNavStyle: React.CSSProperties = {
  ...commonSubNavStyle,
  color: COLORS.darkGray400,
  textDecoration: 'none',
  borderBottom: '2px solid transparent',
};

const hoverSubNavStyle: React.CSSProperties = {
  ...commonSubNavStyle,
  color: COLORS.darkGray600,
  textDecoration: 'none',
  borderBottom: `2px solid ${COLORS.rittenBlue400}`,
};

export const SubNavLink = (
  props: PropsWithChildren<{
    to: string;
    url: string;
    isProtected?: boolean;
    'data-testid'?: string;
  }>,
) => {
  const { to, url, isProtected = true, children } = props;
  const [hover, setHover] = useState<boolean>(false);
  return isProtected ? (
    <ProtectedNavLink
      to={`${url}${to}`}
      style={hover ? hoverSubNavStyle : inactiveSubNavStyle}
      activeStyle={activeSubNavStyle}
    >
      <Box
        fill
        justify="center"
        onMouseEnter={() => setHover(true)}
        onMouseLeave={() => setHover(false)}
        margin="0 20px"
        data-testid={props['data-testid']}
      >
        {children}
      </Box>
    </ProtectedNavLink>
  ) : (
    <NavLink
      to={`${url}${to}`}
      style={hover ? hoverSubNavStyle : inactiveSubNavStyle}
      activeStyle={activeSubNavStyle}
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
      data-testid={props['data-testid']}
    >
      <Box margin="0 20px" data-testid={props['data-testid']}>
        {children}
      </Box>
    </NavLink>
  );
};

export default SubNavLink;
