import { parseISODateStrings } from '../utils/dateParsers';
import RittenClient from './RittenClient';

class BillingClient extends RittenClient {
  listBillingRules = async () => {
    const res = await this.get<Billing.BillingRule[]>(`/api/billing/rules`);
    return res.data;
  };

  getBillingRule = async (id: string) => {
    const res = await this.get<Billing.BillingRule>(`/api/billing/rules/${id}`);
    return res.data;
  };

  postBillingRule = async (body: Billing.BillingRulePostBody) => {
    const res = await this.post<API.IDResponse>('/api/billing/rules', body);
    return res.data;
  };

  patchBillingRules = async (body: { id: string; position: number }[]) => {
    const res = await this.patch<void>('/api/billing/rules', body);
    return res.data;
  };

  patchBillingRule = async (id: string, patchBody: Billing.BillingRulePatchBody) => {
    const res = await this.patch<API.IDResponse>(`/api/billing/rules/${id}`, { ...patchBody });
    return res.data;
  };

  deleteBillingRule = async (id: string) => {
    const res = await this.delete<API.IDResponse>(`/api/billing/rules/${id}`);
    return res.data;
  };

  getDailyBillableService = async (id: string) => {
    const res = await this.get<Billing.DailyBillableService>(`/api/billing/services/${id}`);
    return parseISODateStrings(res.data);
  };

  listDailyBillableServices = async (params: Billing.ListDailyBillableServicesQuery) => {
    const res = await this.get<Billing.ListDBSResponse>(`/api/billing/services`, {
      params,
    });

    const { dbss, totalCount } = res.data;
    return { dbss: parseISODateStrings(dbss), totalCount };
  };

  putDailyBillableService = async (id: string, putBody: Billing.DailyBillableServicePutBody) => {
    const res = await this.put<API.IDResponse>(`/api/billing/services/${id}`, { ...putBody });
    return res.data;
  };

  postDailyBillableService = async (id: string, postBody: Billing.DailyBillableServicePutBody) => {
    const res = await this.post<API.IDResponse>(`/api/billing/services/${id}`, { ...postBody });
    return res.data;
  };

  unbundleDailyBillableService = async (
    id: string,
    putBody: Billing.DailyBillableServicePutBody,
  ) => {
    const res = await this.put<API.IDResponse>(`/api/billing/services/${id}/unbundle`, {
      ...putBody,
    });
    return res.data;
  };

  deleteDailyBillableService = async (idsToDelete: string[]) => {
    const res = await this.delete<API.IDResponse>('/api/billing/services', {
      params: {
        serviceIds: idsToDelete,
      },
    });
    return res.data;
  };

  listFreeEncounters = async (
    query: Billing.ListFreeEncountersQuery,
  ): Promise<{ totalCount: number; encounters: Encounters.FreeEncounter[] }> => {
    const res = await this.get<{ totalCount: number; encounters: Encounters.FreeEncounter[] }>(
      '/api/billing/encounters/free',
      {
        params: {
          startDate: query.startDate,
          endDate: query.endDate,
          patientIds: query.patientIds,
          limit: query.limit,
          offset: query.offset,
          ruleResults: query.ruleResults,
          attendanceTypes: query.attendanceTypes,
          templateIds: query.templateIds,
          sortBy: query.sortBy,
        },
      },
    );

    const { encounters, totalCount } = res.data;

    return { encounters: parseISODateStrings(encounters), totalCount };
  };

  markFreeEncounterNotBillable = async (encounterId: string) => {
    await this.post(`/api/billing/encounters/free/${encounterId}/mark-not-billable`);
  };

  runRulesOnFreeEncounter = async (encounterId: string) => {
    await this.post(`/api/billing/encounters/run-rules`, {
      encounterIds: [encounterId], // run-rules accepts a list of encounters, in this case we are only sending in one encounter ID to run the billing rules engine with.
    });
  };

  /**
   * rerunAllFreeEncounters reruns all free encounters from the past six weeks
   */
  rerunAllFreeEncounters = async () => {
    await this.post(`/api/billing/encounters/free`);
  };

  listAuthorizations = async (query?: Billing.BillingAuthorizationsQuery) => {
    const res = await this.get<Billing.UtilizationReviewSet>('/api/billing/review/authorizations', {
      params: { ...query },
    });
    return parseISODateStrings(res.data);
  };

  getAuthorizationForReview = async (id: string) => {
    const res = await this.get<Billing.UtilizationReview>(
      `/api/billing/review/authorizations/${id}`,
    );
    return parseISODateStrings(res.data);
  };

  getIntegrationsConfig = async () => {
    const res = await this.get<Claims.BillingIntegrationsConfig>(
      '/api/billing/integrations/config',
    );
    return res.data;
  };

  postIntegrationsConfig = async (integrationConfig: Claims.BillingIntegrationsConfig) => {
    const res = await this.post('/api/billing/integrations/config', integrationConfig);
    return res.data;
  };

  getCollabMdConfig = async () => {
    const res = await this.get<Claims.CollabMdSettings>(
      '/api/billing/integrations/collabmd/config',
    );
    return res.data;
  };

  postCollabMdSettings = async (collabMdSettings: Claims.CollabMdSettings) => {
    await this.post('/api/billing/integrations/collabmd/config', collabMdSettings);
  };

  deleteCollabMdProviderMap = async (providerMapId: string) => {
    await this.delete(`/api/billing/integrations/collabmd/config/providers/${providerMapId}`);
  };

  sendPatientToCollabMD = async (patientId: string) => {
    await this.post(`/api/billing/integrations/collabmd/patients/${patientId}`);
  };

  listProcedureCodes = async (): Promise<string[]> => {
    const { data = [] } = await this.get<string[]>('/api/billing/procedure-codes');
    return data;
  };

  /**
   * Lists all users assigned to a billing provider (clinic provider) ID.
   */
  listBillingProviderUsers = async (clinicProviderId: string): Promise<Users.User[]> => {
    const { data = [] } = await this.get<Users.User[]>(
      `/api/billing/providers/${clinicProviderId}/users`,
    );
    return parseISODateStrings(data ?? []);
  };

  /**
   * Lists all known billing modifiers.
   */
  listModifiers = async (): Promise<Billing.Modifier[]> => {
    const { data = [] } = await this.get<Billing.Modifier[]>('/api/billing/modifiers');
    return parseISODateStrings(data ?? []);
  };

  /**
   * Saves a new billing modifier.
   */
  postModifier = async (modifier: Billing.Modifier): Promise<Billing.Modifier> => {
    await this.post<void>('/api/billing/modifiers', { ...modifier });
    return modifier;
  };

  /**
   * Deletes a billing modifier.
   */
  deleteModifier = async (id: string): Promise<void> => {
    await this.delete<void>(`/api/billing/modifiers/${id}`);
  };

  /**
   * Saves a billing procedure code set.
   */
  postProcedureCodeSet = async (
    set: Pick<
      Billing.ProcedureCodeSet,
      'name' | 'primaryProcedureCode' | 'secondaryProcedureCodes'
    >,
  ): Promise<Billing.ProcedureCodeSet> => {
    const { data } = await this.post<Billing.ProcedureCodeSet>(
      '/api/billing/procedure-code-sets',
      set,
    );
    return parseISODateStrings(data);
  };

  /**
   * Fetches all procedure code sets.
   */
  listProcedureCodeSets = async (): Promise<Billing.ProcedureCodeSet[]> => {
    const { data = [] } = await this.get<Billing.ProcedureCodeSet[]>(
      '/api/billing/procedure-code-sets',
    );
    return parseISODateStrings(data ?? []);
  };

  /**
   * Deletes a procedure code set by ID.
   */
  deleteProcedureCodeSet = async (id: number): Promise<void> => {
    await this.delete<void>(`/api/billing/procedure-code-sets/${id}`);
  };

  /**
   * Fetches all census patients with the given query parameters.
   */
  listCensusPatients = async (query: Billing.CensusPatientQuery) => {
    const res = await this.get<Billing.CensusPatient[]>('/api/billing/census', {
      params: { ...query },
    });
    return parseISODateStrings(res.data);
  };
}

export default BillingClient;
