// @ts-strict-ignore
import { isValid, parseISO, parseJSON } from 'date-fns';
import * as _ from 'lodash';

/**
 *
 * dateParsers will contain all functions responsible for converting ISO strings to/from JS Dates
 *
 */

/**
 * Parse the given string in ISO 8601 format and return an instance of Date.
 *
 * String can be one of the two formats:
 * - '2021-07-93'
 * - '2009-03-28T00:00:00-05:00'
 *
 * @param value - the string date value.
 * @returns a JS Date from the given ISO string.
 */
export const convertISOStringToDate = (value?: string): Date => {
  if (!value) {
    return null;
  }
  return parseISO(value);
};

/**
 *
 * @param date to convert
 *
 * @returns the date converted to an ISO string
 */
export const convertDateToISOString = (date: Date): string => {
  if (isValid(date)) {
    return date.toISOString();
  }
  return '';
};

/**
 * StringifyKeys modifies a type T and sets the keys K as strings instead of their original type.
 * This allows us to use `Date` types in interfaces, and modify them for API calls.
 */
export type StringifyKeys<T, K extends keyof T> = {
  [P in keyof T]: P extends K ? string : T[P];
};

/**
 * Converts a subset of keys in an object from string to Date.
 *
 * NOTE: This version takes an Object T, and an array whose keys are keys of T
 *
 *
 * @param obj - Object to parse.
 * @param keysToParse - List of keys whose values to convert to Date.
 * @returns
 */
export function parseObjectDates<T = Record<string, any>>(
  obj: T | StringifyKeys<T, any>,
  keysToParse: Array<keyof T>,
): T {
  // Build a copy of the object from scratch.
  const o = {};
  // Iterate over the original object's key/values to copy them over one-by-one.
  for (const [key, value] of Object.entries<any>(obj)) {
    // Copy all values to the new object.
    o[key] = value;
    // Iterate through the date keys array to figure out if the current key
    // is one of the known date keys.
    for (const k of keysToParse) {
      // If the key matches a known date key, convert the value to a Date.
      // Only parse string values to date, and allow any other values to remain.
      if (k === key && typeof value === 'string') {
        // Parse the string as Date and update the object with new value.
        o[key] = convertISOStringToDate(value);
      }
    }
  }
  return o as T;
}

/**
 *
 * @param arrayToParse the array of objects,
 * ie: [{treatmentHistoryObject1}, {treatmentHistoryObject2}]
 * @param keysToParse an array of the keys (each key should point to an ISOString) to pass through the parser,
 * ie: [admitDate, dischargeDate]
 * @returns the arrayToParse after mapping over the arrayToParse and converts
 * each ISOString to a JS Date (specified by the array of keysToParse)
 */
export const parseArrayDates = <T = Record<string, any>>(
  arrayToParse: StringifyKeys<T, any>[],
  keysToParse: Array<keyof T>,
) => {
  if (!arrayToParse) {
    return [];
  }

  return arrayToParse?.map((el) => {
    return parseObjectDates(el, keysToParse);
  });
};

/**
 * Parses date strings in arrays/objects and converts the values to instances of Date.
 *
 * @param data response data from API to parse which could be null
 * @returns data in same format it was received but with each ISO string property
 * converted to a date, at all depths of the data
 */
export const parseISODateStrings = <T = any>(data: T | null): T => {
  if (!data) {
    return data;
  }

  const parseDateStringsRecursive = (data: any) => {
    if (!data) {
      return data;
    }

    // Iterate over any arrays and parse its contents individually.
    if (Array.isArray(data)) {
      data.forEach((datum, idx) => {
        data[idx] = parseDateStringsRecursive(datum);
      });
      return data;
    }

    // Iterate over the object keys and parse its keys individually.
    if (typeof data === 'object') {
      for (const [key, val] of Object.entries(data)) {
        data[key] = parseDateStringsRecursive(val);
      }
      return data;
    }

    // Attempt to parse any string type as a date string.
    // Return either the parsed Date or original string if not a valid date string.
    if (typeof data === 'string') {
      // if the value includes a whitespace, don't parse as a Date
      if (_.includes(data, ' ')) {
        return data;
      }
      const date = parseJSON(data);
      return isValid(date) ? date : data;
    }

    // Return original data if it's not an array, object, or string.
    return data;
  };

  // Copy the data so we don't mutate the original
  const copy = _.cloneDeep(data);

  // Parse dates and return as the expected type.
  const converted = parseDateStringsRecursive(copy);
  return converted as T;
};
