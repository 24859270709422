import { createSlice } from '@reduxjs/toolkit';

import { RootState } from '../../../../redux/store';

export interface OrderState {
  orderUpdate: number;
}

const initialState: OrderState = {
  orderUpdate: 0,
};

export const orderSlice = createSlice({
  name: 'order',
  initialState,
  reducers: {
    onOrderUpdate: (state) => {
      state.orderUpdate += 1;
    },
  },
});

export const { onOrderUpdate } = orderSlice.actions;

export const selectEncounterOrderUpdate = (state: RootState) => state.order.orderUpdate;

export default orderSlice.reducer;
