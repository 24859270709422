// Generated by ts-to-zod
import { z } from 'zod';

export const apiIDResponseSchema = z.object({
  id: z.string(),
});

export const apiOAuthSchema = z.object({
  url: z.string(),
});

export const apiTimeOfDayRangeSchema = z.lazy(() =>
  z.object({
    start: z.string(),
    end: z.string(),
  }),
);
