import { Box } from 'grommet';

import { TextAreaComponent } from '@ritten/ui-library/form-inputs/TextAreaComponent';

import PromptTextBuilder from '../PromptTextBuilder';

const ParagraphFieldBuilder = (props: FieldBuilderProps<Forms.FieldMetaParagraph>) => {
  const promptText = (props.meta as Forms.FieldMetaParagraph).promptText;

  const updateMeta = (newValue: string) => {
    if (props.onNewMeta !== undefined) {
      props.onNewMeta({ promptText: newValue });
    }
  };

  const updateDefaultValue = (newValue: string) => {
    props.onNewDefaultValue?.(newValue);
  };

  const allowDefaultValue = props.onNewDefaultValue !== undefined;

  return (
    <Box direction="column" pad="medium" gap="small">
      <PromptTextBuilder
        {...props}
        type="TYPE: FREE TEXT, PARAGRAPH"
        promptText={promptText}
        onChange={updateMeta}
        removeFieldFromFieldsList={props.removeFieldFromFieldsList}
        moveField={props.moveField}
        fieldIdx={props.fieldIdx}
      />
      <TextAreaComponent
        initialHeight="111px"
        textAreaProps={{
          value: props.value,
          placeholder: allowDefaultValue ? 'Enter default text' : '',
          resize: 'vertical',
          size: 'medium',
          onChange: (e) => {
            updateDefaultValue(e.target.value);
          },
          disabled: !allowDefaultValue,
        }}
      />
    </Box>
  );
};

export default ParagraphFieldBuilder;
