// @ts-strict-ignore
import { animated, useSpring } from 'react-spring';
import { Box, Text } from 'grommet';
import styled, { css } from 'styled-components';

import { COLORS } from '../../styles/colors';

interface ToggleComponentProps {
  onChange: (toggleChange: boolean) => void;
  checked: boolean | undefined;
  label?: string;
  labelSize?: string;
  disabled?: boolean;
  iconActive?: React.ReactNode;
  iconInactive?: React.ReactNode;
  'data-testid'?: string;
}

const ToggleButton = styled(animated.div)<{ checked: boolean }>`
  position: absolute;
  width: 12px;
  height: 12px;
  border-radius: 45px;
  background: #fff;
  display: flex;
  justify-content: center;
  align-items: center;

  ${(props) =>
    props.checked &&
    css`
      background-color: ${COLORS.rittenBlue400};
      width: 23px;
      height: 23px;
    `};
`;

const ToggleContainer = styled(animated.label)<{ disabled: boolean }>`
  cursor: pointer;
  display: flex;
  align-items: center;
  user-select: none;
  ${(props) =>
    props.disabled &&
    css`
      opacity: 0.6;
      cursor: default;
    `};
`;

const ToggleBackground = styled(animated.div)<{ checked: boolean }>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 40px;
  min-width: 40px;
  height: 20px;
  background: ${COLORS.darkGray400};
  border-radius: 15px;
  position: relative;
  transition: background-color 0.2s;

  ${(props) =>
    props.checked &&
    css`
      background-color: ${COLORS.rittenDarkBlue};
    `};
`;

export const ToggleComponent = (props: ToggleComponentProps) => {
  const { label, labelSize, checked, onChange, iconActive, iconInactive, disabled } = props;

  const toggleButtonAnimation = useSpring({
    left: checked ? 19 : 5,
    config: { tension: 400, friction: 18 },
  });

  return (
    <Box direction="row" align="center" flex={false}>
      <ToggleContainer disabled={disabled}>
        <ToggleBackground checked={checked}>
          <input
            checked={checked}
            onChange={(event) => onChange(event.target.checked)}
            type="checkbox"
            style={{
              height: 0,
              width: 0,
              position: 'absolute',
            }}
            disabled={disabled}
            data-testid={props['data-testid']}
          />
          <ToggleButton style={toggleButtonAnimation} checked={checked}>
            <Box
              align="center"
              style={{
                visibility: checked ? 'visible' : 'hidden',
              }}
            >
              {iconActive}
            </Box>
          </ToggleButton>

          <Box
            align="center"
            style={{
              position: 'absolute',
              left: '23px',
              visibility: checked ? 'hidden' : 'visible',
            }}
          >
            {iconInactive}
          </Box>
        </ToggleBackground>
        <Text size={labelSize} style={{ paddingLeft: '10px' }}>
          {label}
        </Text>
      </ToggleContainer>
    </Box>
  );
};

export default ToggleComponent;
