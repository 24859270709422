// Generated by ts-to-zod
import { z } from 'zod';

import { clinicFacilitySchema } from './clinic.zod';

export const catalogCodedMedicationResponseSchema = z.object({
  name: z.string(),
  strength: z.string(),
  NDC: z.string(),
});

export const catalogDoseSpotCodedMedicationDetailsSchema = z.object({
  DispenseUnitId: z.number(),
  DispenseUnitName: z.string(),
  DoseForm: z.string(),
  Route: z.string(),
  Strength: z.string(),
  GenericProductName: z.string(),
  LexiGenProductId: z.number(),
  LexiDrugSynId: z.number(),
  LexiSynonymTypeId: z.number(),
  LexiGenDrugId: z.string(),
  RxCUI: z.string(),
  OTC: z.boolean(),
  NDC: z.string(),
  DisplayName: z.string(),
});

export const catalogCodedMedicationDetailsWithOptionsSchema =
  catalogDoseSpotCodedMedicationDetailsSchema.extend({
    strengthOptions: z.array(z.string()),
    doseFormOptions: z.array(z.string()),
    routeOptions: z.array(z.string()),
  });

export const catalogDoseSpotDispenseUnitTypeSchema = z.object({
  IsActive: z.boolean(),
  Name: z.string(),
  StandardDispenseUnitId: z.number(),
});

export const catalogDoseSpotAllergySchema = z.object({
  name: z.string(),
  code: z.string(),
  codeType: z.string(),
  rxCUI: z.string(),
});

export const catalogFoodSubstanceAllergySchema = z.object({
  name: z.string(),
});

export const catalogAllergiesResultSchema = z.object({
  foodAllergies: z.array(catalogFoodSubstanceAllergySchema),
  medicalAllergies: z.array(catalogDoseSpotAllergySchema),
  substanceAllergies: z.array(catalogFoodSubstanceAllergySchema),
});

export const catalogAllergiesQueryParamsSchema = z.object({
  name: z.string(),
});

export const catalogICDDiagnosisSchema = z.object({
  type: z.string(),
  code: z.string(),
  description: z.string(),
});

export const catalogICD10DiagnosisSchema = z.object({
  code: z.string(),
  shortDescription: z.string(),
  longDescription: z.string(),
});

export const catalogSubstanceSchema = z.object({
  substance: z.string(),
  category: z.string(),
});

export const catalogRacesSchema = z.object({
  race: z.string(),
  code: z.string(),
});

export const catalogLevelOfCareSchema = z.object({
  name: z.string(),
  isClinical: z.boolean(),
  isSelected: z.boolean().optional(),
});

export const catalogEthnicitiesSchema = z.object({
  ethnicity: z.string(),
  code: z.string(),
});

export const catalogLanguagesSchema = z.object({
  language: z.string(),
  code: z.string(),
});

export const catalogRevenueCodeSchema = z.object({
  code: z.string(),
  description: z.string(),
});

export const catalogCPTCodeSchema = z.object({
  code: z.string(),
  description: z.string(),
});

export const catalogHCPCSCodeSchema = z.object({
  code: z.string(),
  description: z.string(),
});

export const catalogBillingCodesResultSchema = z.object({
  revenueCodes: z.array(catalogRevenueCodeSchema),
  cptCodes: z.array(catalogCPTCodeSchema),
  hcpcsCodes: z.array(catalogHCPCSCodeSchema),
});

export const catalogBillingCodesQueryParamsSchema = z.object({
  code: z.string(),
});

export const catalogParsedBillingCodeSchema = z.object({
  type: z.union([z.literal('cpt'), z.literal('hcpcs'), z.literal('revenue')]),
  code: z.string(),
  description: z.string(),
});

export const catalogPlaceOfServiceCodeSchema = z.object({
  code: z.string(),
  name: z.string(),
  description: z.string(),
});

export const catalogTxPlanProblemsQueryParamsSchema = z.object({
  problemName: z.string(),
  problemId: z.string(),
  groupName: z.string(),
  groupId: z.string(),
  withWiley: z.boolean(),
});

export const catalogClinicProgramTypeSchema = z.union([
  z.literal('Clinical'),
  z.literal('Non-Clinical'),
]);

export const catalogClinicProgramSchema = z.lazy(() =>
  z.object({
    id: z.string(),
    programName: z.string(),
    programType: catalogClinicProgramTypeSchema,
    levelOfCare: z.array(z.string()),
    patientIds: z.array(z.string()),
    createdAt: z.date(),
    users: z.array(z.string()).optional(),
    facility: clinicFacilitySchema.optional().nullable(),
  }),
);

export const catalogClinicProgramPostBodySchema = z.object({
  id: z.string(),
  programName: z.string(),
  programType: catalogClinicProgramTypeSchema,
  levelOfCare: z.array(z.string()),
  patientIds: z.array(z.string()),
  users: z.array(z.string()).optional(),
  facilityId: z.string().optional(),
});

export const catalogTxPlanBaseResourceSchema = z.object({
  id: z.string(),
  name: z.string(),
  createdAt: z.date().optional(),
  deletedAt: z.date().optional(),
  isWiley: z.boolean().optional(),
});

export const catalogTxPlanProblemPostBodySchema = z.object({
  name: z.string(),
  groupId: z.string().optional(),
  definitions: z.array(
    z.object({
      name: z.string(),
    }),
  ),
  goals: z.array(
    z.object({
      name: z.string(),
    }),
  ),
  objectives: z.array(
    z.object({
      name: z.string(),
      isEbt: z.boolean().optional(),
      interventions: z.array(
        z.object({
          name: z.string(),
          isEbt: z.boolean().optional(),
        }),
      ),
    }),
  ),
});

export const catalogTxPlanProblemPutBodySchema = z.object({
  name: z.string(),
  id: z.string(),
  groupId: z.string().nullable().optional(),
  definitions: z
    .array(
      z.object({
        name: z.string(),
      }),
    )
    .optional(),
  goals: z
    .array(
      z.object({
        name: z.string(),
      }),
    )
    .optional(),
  objectives: z
    .array(
      z.object({
        name: z.string(),
        isEbt: z.boolean().optional(),
        interventions: z.array(
          z.object({
            name: z.string(),
            isEbt: z.boolean().optional(),
          }),
        ),
      }),
    )
    .optional(),
});

export const catalogTxPlanDefinitionSchema = catalogTxPlanBaseResourceSchema;

export const catalogTxPlanGoalSchema = catalogTxPlanBaseResourceSchema;

export const catalogTxPlanBaseEbtResourceSchema = catalogTxPlanBaseResourceSchema.extend({
  isEbt: z.boolean(),
});

export const catalogTxPlanInterventionSchema = catalogTxPlanBaseEbtResourceSchema;

export const catalogTxPlanObjectiveSchema = catalogTxPlanBaseEbtResourceSchema.extend({
  interventions: z.array(catalogTxPlanInterventionSchema),
});

export const catalogTxPlanProblemSchema = catalogTxPlanBaseResourceSchema.extend({
  groupId: z.string().optional().nullable(),
  groupName: z.string(),
  definitions: z.array(catalogTxPlanDefinitionSchema).optional(),
  goals: z.array(catalogTxPlanGoalSchema).optional(),
  objectives: z.array(catalogTxPlanObjectiveSchema).optional(),
});
