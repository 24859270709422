import { Box, Button, ButtonType, grommet, Spinner, Text, ThemeContext } from 'grommet';
import { deepMerge } from 'grommet/utils';

import { COLORS } from '../../styles/colors';
import { getDropProps, TipContentWithCaret } from '../tooltips/CustomTooltip';
import { ButtonColor } from './constants';
import { getColor, getHoverStateColor, getTextColor } from './useButtonColor';

export type StandardButtonProps = ButtonType & {
  label: React.ReactNode;
  color?: ButtonColor;
  isLoading?: boolean;
  containerRef?: React.LegacyRef<HTMLDivElement>;
  fontSize?: string;
  lineHeight?: string;
  pad?: {
    vertical?: string;
    horizontal?: string;
  };
  styleVariant?: 'default' | 'outline';
  sizeVariant?: 'default' | 'small';
  alignSelf?: string;
  tooltipContent?: string | React.ReactNode;
  tooltipAlign?: 'bottom' | 'left' | 'right' | 'top';
  'data-testid'?: string;
  fullWidth?: boolean;
  borderRadius?: string;
};

const spinnerContainer: React.CSSProperties = {
  position: 'absolute',
  inset: 0,
  margin: 'auto',
  width: 'fit-content',
  height: 'fit-content',
};

const BORDER_RADIUS = {
  default: '6px',
  small: '4px',
};

const PADDING = {
  default: {
    vertical: '8px',
    horizontal: '12px',
  },
  small: {
    vertical: '6px',
    horizontal: '10px',
  },
};

const FONT = {
  default: {
    fontSize: '14px',
    lineHeight: '22px',
  },
  small: {
    fontSize: '12px',
    lineHeight: '12px',
  },
};

export const StandardButton: React.FC<StandardButtonProps> = (props: StandardButtonProps) => {
  const {
    isLoading,
    color = 'default',
    containerRef,
    styleVariant = 'default',
    sizeVariant = 'default',
    tooltipContent,
    tooltipAlign,
    fullWidth = false,
    borderRadius,
  } = props;

  let bgColor: string;
  let textColor: string;
  let borderColor: string;
  if (styleVariant === 'outline') {
    bgColor = COLORS.white;
    if (props.disabled) {
      textColor = COLORS.darkGray400;
      borderColor = COLORS.darkGray400;
    } else {
      textColor = getColor(color);
      borderColor = getColor(color);
    }
  } else {
    textColor = getTextColor(color);
    if (props.disabled) {
      borderColor = COLORS.darkGray400;
      bgColor = COLORS.darkGray400;
    } else {
      borderColor = getColor(color);
      bgColor = getColor(color);
    }
  }

  const hoverColor = getHoverStateColor(color, styleVariant === 'default');

  const whiteButtonOverlay: React.CSSProperties = {
    background: COLORS.white,
    position: 'absolute',
    width: '100%',
    height: '100%',
    border: `1px solid ${bgColor}`,
    borderRadius: borderRadius ?? BORDER_RADIUS[sizeVariant],
  };

  const theme = deepMerge(grommet, {
    box: {
      extend: {
        color: textColor,
      },
    },
    button: {
      disabled: {
        opacity: '1',
      },
      extend: `
        font-size: ${props.fontSize || FONT[sizeVariant].fontSize};
        line-height: ${props.lineHeight || FONT[sizeVariant].lineHeight};
        font-family: Circular;
        font-style: normal;
        font-weight: normal;
        background: ${bgColor};
        color: ${textColor};
        border: 1px solid ${borderColor};
        ${
          !props.disabled
            ? `&:hover {
          border: 1px solid ${styleVariant === 'outline' ? borderColor : hoverColor};
          background: ${hoverColor};
        }`
            : ''
        }
      `,
      default: {
        padding: {
          vertical: props.pad?.vertical || PADDING[sizeVariant].vertical,
          horizontal: props.pad?.horizontal || PADDING[sizeVariant].horizontal,
        },
        border: {
          radius: borderRadius ?? BORDER_RADIUS[sizeVariant],
        },
      },
    },
  });

  return (
    <ThemeContext.Extend value={theme}>
      <Box style={{ position: 'relative' }} ref={containerRef} width={fullWidth ? '100%' : 'auto'}>
        {/* Hide the button text with this white overlay, so we can see the spinner */}
        <Box style={isLoading ? whiteButtonOverlay : undefined} />

        {isLoading && (
          <Box style={spinnerContainer}>
            <Spinner size="xsmall" pad="0" />
          </Box>
        )}

        <Box height="min-content">
          <Button
            alignSelf={props.alignSelf || 'center'}
            {...props}
            // overwrite disabled prop because it prevents tooltip
            // disabled styling done manually above
            disabled={false}
            // use for tests because we're not using the actual disabled prop
            aria-disabled={props.disabled}
            onClick={props.disabled ? undefined : props.onClick}
            style={{
              width: fullWidth ? '100%' : 'auto',
              cursor: props.disabled ? 'default' : 'pointer',
              ...props.style,
            }}
            {...(tooltipContent && {
              tip: {
                content: (
                  <TipContentWithCaret
                    backgroundColor={COLORS.text}
                    tooltipAlign={tooltipAlign ?? 'top'}
                    tooltipContent={
                      typeof tooltipContent === 'string' ? (
                        <Box background={COLORS.text} round>
                          <Text size="11px" color={COLORS.white}>
                            {tooltipContent}
                          </Text>
                        </Box>
                      ) : (
                        tooltipContent
                      )
                    }
                  />
                ),
                dropProps: getDropProps(tooltipAlign ?? 'top'),
                plain: true,
              },
            })}
          />
        </Box>
      </Box>
    </ThemeContext.Extend>
  );
};

export default StandardButton;
