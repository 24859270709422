// Generated by ts-to-zod
import { z } from 'zod';

import { encountersEncounterSchema } from './encounters.zod';
import {
  patientPatientSchema,
  patientReleaseOfInformationSchema,
  patientReleaseOfInformationV2PostBodySchema,
} from './patient.zod';
import { usersAbbreviatedUserSchema, usersFullUserSchema } from './users.zod';
import { requiredDateSchema } from './util.zod';

export const documentsFileStatusTypeSchema = z.union([
  z.literal('export not started'),
  z.literal('export in progress'),
  z.literal('export could not be completed'),
  z.literal('export completed successfully'),
  z.literal('scan not started'),
  z.literal('scan in progress'),
  z.literal('error scanning file'),
  z.literal('dirty'),
  z.literal('clean'),
  z.literal(''),
]);

export const documentsDocumentTypeSchema = z.union([
  z.literal('Pre-Admission'),
  z.literal('Lab/Toxicology Results'),
  z.literal('Nursing Shift Notes'),
  z.literal('Release of Information'),
  z.literal('Consent'),
  z.literal('Waiver'),
  z.literal('Policy and Procedure'),
  z.literal('Homework'),
  z.literal('Medical Record'),
  z.literal('Insurance Card'),
  z.literal('Client Photo'),
  z.literal('Other'),
  z.literal('Prescriptions'),
  z.literal('Consults'),
  z.literal('Psych Testing'),
  z.literal('Correspondence'),
  z.literal('Intake'),
  z.literal('Clinic Logo'),
  z.literal('Encounter Export'),
  z.literal('Full Patient Export'),
  z.literal('Patient Weekly Agenda Export'),
  z.literal('Insights Chart Export'),
  z.literal('Patient Active MAR Export'),
  z.literal('Brief Patient Export'),
  z.literal('Group Encounter Export'),
  z.literal('Portal Upload'),
  z.literal('Patients Forms Export'),
  z.literal('Patient Documents Export'),
  z.literal('Patient Biometrics Export'),
  z.literal(''),
]);

export const documentsDocumentExportSchema = z.lazy(() =>
  z.object({
    patient: patientPatientSchema.optional(),
    requester: usersFullUserSchema.optional(),
    encounter: encountersEncounterSchema.schema.omit({ documents: true }).optional(),
    exportType: z.string().optional(),
    createdAt: z.date().optional(),
    title: z.string().optional(),
  }),
);

export const documentsDocumentPortalAccessRoleSchema = z.object({
  id: z.string(),
  createdAt: requiredDateSchema,
  deletedAt: z.date().nullable(),
  user: z.object({
    id: z.string(),
    patientId: z.string(),
    first: z.string(),
    middle: z.string(),
    last: z.string(),
  }),
});

export const documentsDocumentCountResponseSchema = z.object({
  docCount: z.number(),
  allFinished: z.boolean(),
});

export const documentsDocumentPostBodySchema = z.object({
  title: z.string(),
  type: documentsDocumentTypeSchema,
  primaryFileId: z.string(),
  expiresAt: z.string().optional(),
  releaseOfInformation: patientReleaseOfInformationV2PostBodySchema.optional(),
  isClinicDocument: z.boolean().optional(),
  patientId: z.string().optional(),
});

export const documentsFileSchema = z.object({
  id: z.string(),
  filename: z.string(),
  size: z.string(),
  extension: z.string(),
  fileStatus: documentsFileStatusTypeSchema,
});

export const documentsFileDataURLResponseSchema = z.object({
  dataURL: z.string(),
});

export const documentsDocumentPortalAccessRolePostBodySchema = z.object({
  patientId: z.string(),
});

export const documentsDocumentQuerySchema = z.object({
  types: z.array(documentsDocumentTypeSchema),
  limit: z.number().optional(),
  offset: z.number().optional(),
  patientId: z.string().optional(),
  requesterId: z.string().optional(),
  clinicDocuments: z.boolean().optional(),
  showArchived: z.boolean().optional(),
  filterByRequester: z.boolean().optional(),
});

export const documentsDocumentSchema = z.object({
  id: z.string(),
  type: z.string(),
  title: z.string(),
  primaryFileId: z.string(),
  expiresAt: z.string(),
  releaseOfInformation: patientReleaseOfInformationSchema.optional(),
  createdAt: requiredDateSchema,
  portalAccessRoles: z.array(documentsDocumentPortalAccessRoleSchema),
  file: documentsFileSchema,
  isClinicDocument: z.boolean(),
  uploadedBy: z.string(),
  uploadedByUser: usersAbbreviatedUserSchema.optional().nullable(),
  documentExport: documentsDocumentExportSchema.nullable(),
});
