import { ReactNode } from 'react';
import { Box, Text } from 'grommet';
import { LinkNext } from 'grommet-icons';

import { COLORS } from 'styles/colors';
import InteriorBox from '../boxes/InteriorBox';

export interface SimpleLinkToCardProps {
  label: string;
  linkNode?: ReactNode;
  onClick?: () => void;
  renderSubtext?: () => ReactNode;
  labelSize?: string;
  backgroundColor?: string;
  'data-testid'?: string;
}

export const SimpleLinkToCard: React.FC<SimpleLinkToCardProps> = (props: SimpleLinkToCardProps) => {
  return (
    <InteriorBox
      direction="row"
      justify="between"
      pad="small"
      background={props.backgroundColor}
      onClick={props.onClick}
      gap="6px"
      aria-label={props.label}
      width="100%"
      data-testid={props['data-testid']}
    >
      <Box justify="center" gap="2px" basis="90%">
        {props.linkNode ? (
          props.linkNode
        ) : (
          <Text size={props.labelSize || '14px'} color={COLORS.darkGray600}>
            {props.label}
          </Text>
        )}
        {props.renderSubtext?.()}
      </Box>
      <Box justify="center">
        <LinkNext size="17px" color={COLORS.darkGray600} />
      </Box>
    </InteriorBox>
  );
};

export default SimpleLinkToCard;
