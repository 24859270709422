import { MutableRefObject, useEffect, useState } from 'react';
import { NavLink, useHistory, useLocation } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';
import { Box, DropButton, grommet, Header, ThemeContext } from 'grommet';
import { deepMerge } from 'grommet/utils';
import { FormClose, Logout, Menu } from 'grommet-icons';

import { LinkButton } from '@ritten/ui-library/buttons';
import IconButton from '@ritten/ui-library/buttons/IconButton';
import IconLabel from '@ritten/ui-library/labels/IconLabel';
import Flyout from '@ritten/ui-library/modals/Flyout';

import { FeatureFlagLabel } from 'common/feature-flags/featureFlags';
import useFeatureFlags from 'context/featureFlags/useFeatureFlags';
import useMobileDevice from 'context/mobileDevice/useMobileDevice';
import useTenant from 'context/tenant/useTenant';
import useUser from 'context/user/useUser';
import UserProfileNavItem from 'features/nav/UserProfileNavItem';
import { useResponsiveDeviceSize } from 'hooks';
import { COLORS } from 'styles/colors';
import RittenBanner from '../../../assets/logos/RittenBanner';
import { getPortalPageRootUrl, getSelectedClientId } from '../utils';
import PortalNavClientSelector from './PortalNavClientSelector';

export const PORTAL_HEADER_ID = 'portal-header';

export const navDropButton = deepMerge(grommet, {
  global: {
    drop: {
      // unique case
      border: {
        radius: '0px',
      },
      shadowSize: 'none',
    },
  },
  button: {
    extend: `
      background-color: transparent;
      padding: 0;
    `,
  },
});

interface PortalNavProps {
  menuAlignmentRef: MutableRefObject<any>;
  accessibleContacts: Users.PortalAuthorization[];
}

const PortalNav = (props: PortalNavProps) => {
  const { accessibleContacts } = props;

  const { logout } = useAuth0();
  const { user } = useUser();
  const {
    deviceSize: { set },
  } = useResponsiveDeviceSize();
  const { isMobile } = useMobileDevice();
  const history = useHistory();
  const { isFlagOn } = useFeatureFlags();
  const { pathname } = useLocation();
  const { tenant } = useTenant();

  const [isMenuOpen, setIsMenuOpen] = useState<boolean>(false);

  const selectedClientId = getSelectedClientId(pathname);
  const showClientSelector = accessibleContacts.length > 1 && selectedClientId;
  const selectedClientIsUser = selectedClientId === user.patientId;

  const isPortalAgendaFlagOn = isFlagOn(FeatureFlagLabel.CLIENT_PORTAL_AGENDA);
  const isStripeEnabled = tenant.stripeIntegrationEnabled;

  useEffect(() => {
    if (!isMobile && isMenuOpen) {
      setIsMenuOpen(false);
    }
  }, [isMobile]);

  return (
    <>
      <Header
        background={COLORS.darkGray600}
        pad={set({ L: { right: '9px' }, XS: { right: '9px', vertical: '11px' } })}
        style={{ position: 'fixed', top: '0', zIndex: '2' }}
        width="100%"
        id={PORTAL_HEADER_ID}
      >
        <Box
          direction="row"
          align="center"
          pad={{ horizontal: '24px' }}
          gap="48px"
          height={isMobile ? 'auto' : '48px'}
        >
          <NavLink to="/" style={{ display: 'flex' }}>
            <RittenBanner height="25px" />
          </NavLink>
          {!isMobile && showClientSelector && (
            <PortalNavClientSelector accessibleContacts={accessibleContacts} />
          )}
        </Box>
        {isMobile ? (
          <Box pad={{ right: '15px' }}>
            <IconButton
              icon={
                isMenuOpen ? (
                  <FormClose color={COLORS.white} size="26px" />
                ) : (
                  <Menu color={COLORS.white} size="20px" />
                )
              }
              color="plain"
              onClick={() => setIsMenuOpen(!isMenuOpen)}
            />
          </Box>
        ) : (
          <ThemeContext.Extend value={navDropButton}>
            <DropButton
              label={<UserProfileNavItem />}
              dropContent={
                <Box background={COLORS.darkGray600}>
                  <LinkButton
                    color="white"
                    size="small"
                    padding={{ left: '24px', right: '72px', vertical: '10px' }}
                    icon={<IconLabel icon={<Logout size="16px" />} containerSize="24px" />}
                    onClick={() => logout({ returnTo: window.location.origin })}
                  >
                    Log Out
                  </LinkButton>
                </Box>
              }
              dropProps={{ align: { top: 'bottom', left: 'right' } }}
            />
          </ThemeContext.Extend>
        )}
      </Header>
      {isMenuOpen && (
        <Flyout
          full
          responsive
          position="right"
          target={props.menuAlignmentRef.current}
          layerTheme={{ inset: '48px 0px 0px !important' }} // this ensures the layer is positioned below the header but over top of the footer
        >
          <Box
            background={COLORS.darkGray600}
            fill
            pad={{ horizontal: '24px', vertical: '16px' }}
            gap="26px"
          >
            {showClientSelector && (
              <PortalNavClientSelector
                accessibleContacts={accessibleContacts}
                onSelectClient={() => setIsMenuOpen(false)}
              />
            )}
            {selectedClientId && (
              <LinkButton
                color="white"
                size="small"
                padding="none"
                onClick={() => {
                  history.push(`${getPortalPageRootUrl('forms', selectedClientId)}/forms`);
                  setIsMenuOpen(false);
                }}
              >
                Forms
              </LinkButton>
            )}
            {isPortalAgendaFlagOn && selectedClientIsUser && (
              <LinkButton
                color="white"
                size="small"
                padding="none"
                onClick={() => {
                  history.push(`${getPortalPageRootUrl('agenda', selectedClientId)}/agenda`);
                  setIsMenuOpen(false);
                }}
              >
                Agenda
              </LinkButton>
            )}
            {selectedClientId && (
              <LinkButton
                color="white"
                size="small"
                padding="none"
                onClick={() => {
                  history.push(
                    `${getPortalPageRootUrl('attachments', selectedClientId)}/attachments`,
                  );
                  setIsMenuOpen(false);
                }}
              >
                Attachments
              </LinkButton>
            )}
            {selectedClientId && isStripeEnabled && (
              <LinkButton
                color="white"
                size="small"
                padding="none"
                onClick={() => {
                  history.push(
                    `${getPortalPageRootUrl('payment-methods', selectedClientId)}/payment-methods`,
                  );
                  setIsMenuOpen(false);
                }}
              >
                Payment Methods
              </LinkButton>
            )}
            <LinkButton
              color="white"
              size="small"
              icon={<IconLabel icon={<Logout size="16px" />} containerSize="24px" />}
              onClick={() => logout({ returnTo: window.location.origin })}
            >
              Log Out
            </LinkButton>
          </Box>
        </Flyout>
      )}
    </>
  );
};

export default PortalNav;
