import { Box, Text } from 'grommet';

import { getUserInitials } from 'features/nav/util';

interface UserInitialsProps {
  backgroundColor: string;
  height: string;
  width: string;
  textColor: string;
  textSize: string;
  user: Users.FullUser | Patient.PatientName;
}

const UserInitials = (props: UserInitialsProps) => {
  const { backgroundColor, height, width, textColor, textSize, user } = props;
  return (
    <Box
      background={backgroundColor}
      round="full"
      height={height}
      width={{ width, min: width }}
      justify="center"
      align="center"
    >
      <Text color={textColor} size={textSize} data-testid="user-initials">
        {getUserInitials(user)}
      </Text>
    </Box>
  );
};

export default UserInitials;
