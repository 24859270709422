import { useContext } from 'react';

import Auth0UserContext from './Auth0UserContext';

const useAuth0User = (): Auth0UserContext => {
  const context = useContext(Auth0UserContext);
  if (context === null) {
    throw new Error('useAuth0User must be used inside a Auth0UserContext.Provider');
  }
  return context;
};

export default useAuth0User;
