import RittenClient from './RittenClient';

export default class DosespotClient extends RittenClient {
  /**
   * Will perform a sync with Dosespot via our API in the context of an encounter.
   * @param encounterID
   * @returns a list of created orders.
   */
  postEncounterSync = async (encounterID: string): Promise<Orders.Order[]> => {
    const { data = [] } = await this.post<Orders.Order[]>('/api/dosespot/sync', null, {
      params: {
        encounterID,
      },
    });
    return data;
  };

  /**
   * Will perform a sync with Dosespot via our API in the context of just the user.
   * @param encounterID
   */
  postUserSync = async (): Promise<void> => {
    await this.post('/api/dosespot/sync', null);
  };

  /**
   * Fetches the dosespot sso url in the context of an encounter.
   * @param encounterID
   * @returns
   */
  fetchEncounterSSOURL = async (encounterID: string): Promise<string> => {
    const res = await this.get<Dosespot.SSOURL>('/api/dosespot/ssourl', {
      params: {
        encounterID,
      },
    });
    return res.data.ssoURL;
  };

  /**
   * Fetches the dosespot sso url in the context of just a user.
   * @param encounterID
   * @returns
   */
  fetchUserSSOURL = async (): Promise<string> => {
    const res = await this.get<Dosespot.SSOURL>('/api/dosespot/ssourl');
    return res.data.ssoURL;
  };
}
