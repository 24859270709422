import * as promiseUtils from 'blend-promise-utils';
import debounce from 'lodash/debounce';

// Normal debounced function will not return a Promise (see https://github.com/lodash/lodash/issues/4815#issuecomment-815866904)
export const asyncDebounce = <T>(func: (...args: any[]) => Promise<T>, wait) => {
  const debounced = debounce((resolve, reject, args) => {
    func(...args)
      .then(resolve)
      .catch(reject);
  }, wait);
  return (...args: any[]) =>
    new Promise<T>((resolve, reject) => {
      debounced(resolve, reject, args);
    });
};

export { promiseUtils };
