import { Box, Text } from 'grommet';

import { LinkButton, StandardButton } from '@ritten/ui-library/buttons';
import { BaseModal } from '@ritten/ui-library/modals';

import useMobileDevice from 'context/mobileDevice/useMobileDevice';
import { COLORS } from 'styles/colors';

const RefreshDesktopViewModal = () => {
  const { dismissRefreshPageFlyout } = useMobileDevice();

  const refreshPage = () => window.location.reload();

  return (
    <BaseModal
      layerProps={{ full: 'horizontal', position: 'bottom', responsive: false }}
      header={
        <Box pad="4px">
          <Text size="18px" color={COLORS.darkGray600} weight={700}>
            Refresh page for desktop view
          </Text>
        </Box>
      }
      isOpen
      onClose={dismissRefreshPageFlyout}
    >
      <Box pad="4px 4px 16px" gap="10px">
        <Text size="14px" color={COLORS.darkGray600}>
          This page has not been optimized for mobile.
        </Text>
        <Text size="14px" color={COLORS.darkGray600}>
          We recommend clicking &ldquo;Refresh Page&rdquo; to switch back to the desktop view. You
          may dismiss this message and continue to use the non-optimized version of this page but we
          cannot guarantee everything will work as expected.
        </Text>
        <Box margin={{ top: '10px', bottom: '16px' }}>
          <StandardButton onClick={refreshPage} label="Refresh Page" fullWidth />
        </Box>
        <LinkButton
          onClick={dismissRefreshPageFlyout}
          label="Dismiss"
          textSize="14px"
          color="text"
          justify="center"
        />
      </Box>
    </BaseModal>
  );
};

export default RefreshDesktopViewModal;
