import { parseISODateStrings } from 'utils';
import RittenClient from './RittenClient';

export default class UserNotificationClient extends RittenClient {
  /**
   * This call will provide the total number of notifications for a user,
   * including DoseSpot. This will be polled by the web client so that it
   * can display a notification icon accordingly.
   * @param {string} userId
   * @returns {number}
   *
   * */
  getUserNotificationCounts = async (userId: string) => {
    const res = await this.get<number>(`/api/notifications/user/${userId}/count`);
    return res.data;
  };

  /**
   * Provides notifications for a user, including DoseSpot counts.
   * @param {string} userId
   * @param {Notifications.QueryUserNotifications} params
   * @returns {Notifications.GetUserNotificationResult}
   */
  getUserNotifications = async (params: Notifications.QueryUserNotifications) => {
    const res = await this.get<Notifications.GetUserNotificationResult>(`/api/notifications`, {
      params,
    });
    return parseISODateStrings(res.data);
  };

  /**
   * Updates a user notification, right now this is just the status (Read/Unread).
   * @param {string} notificationId
   * @param {Notifications.UpdateUserNotificationParams} params
   */
  updateUserNotification = async (params: Notifications.UpdateUserNotificationParams[]) => {
    await this.patch<void>(`/api/notifications`, params);
  };

  /**
   * Archives notifications by ID.
   * @param {string} notificationId
   */
  clearNotificationById = async (notificationId: string) => {
    await this.delete<void>(`/api/notifications/${notificationId}`);
  };

  /**
   * Archives all read notifications, even if they are not in view.
   */
  clearReadNotifications = async () => {
    await this.delete<void>(`/api/notifications`);
  };

  /**
   * Gets clinic notification settings
   * @returns {Notifications.UserNotificationSetting[]}
   */
  getClinicNotificationSetting = async () => {
    const res = await this.get<Notifications.UserNotificationSetting[]>(
      `/api/clinic/notifications`,
    );
    return res.data;
  };

  /**
   * Updates the status for a notification type and eventually the time increment.
   * @param {Notifications.UserNotificationSetting} notificationSettingId
   * @returns {void}
   */
  updateClinicNotificationSetting = async (
    notificationSetting: Notifications.UserNotificationSetting,
  ) => {
    const id = notificationSetting.id;
    const params: Notifications.UpdateClinicNotificationSettingParams = {
      roleIds: notificationSetting.roles?.map((r) => r.id),
      status: notificationSetting.status,
      notifyAfterMinutes: notificationSetting.notifyAfterMinutes,
      includePrimaryClinician: notificationSetting.includePrimaryClinician,
      includeCreator: notificationSetting.includeCreator,
    };

    const res = await this.patch<void>(`/api/clinic/notifications/${id}`, params);
    return res.data;
  };
}
