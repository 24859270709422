// @ts-strict-ignore
import { useEffect, useState } from 'react';
import { Box, Text, TextInput } from 'grommet';
import { Close } from 'grommet-icons';

import { LinkButton } from '@ritten/ui-library/buttons';
import { ToggleComponent } from '@ritten/ui-library/form-inputs';

import { COLORS } from '../../../../styles/colors';
import PromptTextBuilder from '../PromptTextBuilder';

const optionTextInputStyles = {
  color: COLORS.darkGray600,
  fontWeight: 400,
  backgroundColor: COLORS.white,
  border: `1px solid ${COLORS.darkGray400}`,
  borderRadius: '4px',
  fontSize: '14px',
};

const MCCheckboxFieldBuilder = (
  props: FieldBuilderProps<Forms.FieldMetaMCCheckbox> & { hideDefaultAsCheckedOptions?: boolean },
) => {
  const promptText = (props.meta as Forms.FieldMetaMCCheckbox).promptText;
  const [choices, setChoices] = useState<string[]>(['', '']);
  const [unfilledChoices, setUnfilledChoices] = useState<boolean>(false);
  const [cannotRemoveChoice, setCannotRemoveChoice] = useState<boolean>(false);

  const [prechecked, setPrechecked] = useState<boolean[]>([false, false]);

  useEffect(() => {
    updateChoices();
  }, [props.meta]);

  function updateChoices() {
    const meta = props.meta as Forms.FieldMetaMCCheckbox;

    const checkedList = meta.checked ?? [];
    while (checkedList.length < 2) {
      checkedList.push(false);
    }
    setPrechecked(checkedList);

    const list = meta.choices;
    while (list.length < 2) {
      list.push('');
    }
    setChoices(list);

    // need to set meta in case it is an old section and does not have checked array in meta
    if (!meta.checked) {
      updateMeta({ choices: meta.choices, checked: meta.choices.map(() => false) }); // checked should be initialized to an array of false values for each choice
    }
  }

  const updateMeta = (newValue: Partial<Forms.FieldMetaMCCheckbox>) => {
    if (props.onNewMeta !== undefined) {
      if (newValue.promptText !== undefined) {
        props.onNewMeta({
          promptText: newValue.promptText,
          choices,
          checked: prechecked,
        });
      } else {
        props.onNewMeta({
          promptText,
          choices: newValue.choices,
          checked: newValue.checked,
        });
      }
    }
  };

  const updateChoice = (newValue: string, idx: number) => {
    // Resets validation state when an edit is made
    setCannotRemoveChoice(false);
    setUnfilledChoices(false);

    const newChoices = [...choices];
    newChoices[idx] = newValue;
    setChoices(newChoices);
    updateMeta({
      choices: newChoices,
      checked: prechecked, // updating choice list, so checked stays the same
    });
  };

  const updatePrechecked = (idx: number) => {
    const newPrecheckedList = [...prechecked];
    newPrecheckedList[idx] = !newPrecheckedList[idx];
    setPrechecked(newPrecheckedList);
    updateMeta({
      checked: newPrecheckedList,
      choices, // updating prechecked list, so choices stays the same
    });
  };

  const allChoicesFilled = () => {
    const emptyChoices = choices.filter((choice) => choice === '');
    return emptyChoices.length === 0;
  };

  const addAdditionalOption = () => {
    if (allChoicesFilled()) {
      const newChoices = [...choices];
      newChoices.push('');
      setChoices(newChoices);
      setUnfilledChoices(false);

      const newPrecheckedList = [...prechecked];
      newPrecheckedList.push(false);
      setPrechecked(newPrecheckedList);
    } else {
      setUnfilledChoices(true);
    }
  };

  const removeOption = (idxToRemove: number) => {
    setUnfilledChoices(false); // clear out this error if it exists on removal of an option

    if (choices.length > 2) {
      const filteredChoices = choices.filter((choice, idx) => idx !== idxToRemove);
      setChoices(filteredChoices);

      const filteredPrecheckedList = prechecked.filter((pc, idx) => idx !== idxToRemove);
      setPrechecked(filteredPrecheckedList);

      updateMeta({ choices: filteredChoices, checked: filteredPrecheckedList });
    } else {
      setCannotRemoveChoice(true);
    }
  };

  const checkboxOptionBuilder = (choice: string, idx: number) => {
    return (
      <Box key={idx} direction="row" align="center" gap="medium" pad={{ vertical: 'xsmall' }} fill>
        <TextInput
          plain={true}
          placeholder="Option"
          value={choice}
          onChange={(e) => updateChoice(e.target.value, idx)}
          focusIndicator={false}
          style={optionTextInputStyles}
        />
        {!props.hideDefaultAsCheckedOptions && (
          <ToggleComponent
            label="Default as Checked"
            labelSize="14px"
            onChange={() => updatePrechecked(idx)}
            checked={prechecked[idx]}
          />
        )}
        <Close
          color={COLORS.darkGray600}
          onClick={() => removeOption(idx)}
          cursor="pointer"
          size="14px"
        />
      </Box>
    );
  };

  return (
    <Box direction="column" pad="medium" gap="small">
      <PromptTextBuilder
        {...props}
        type="TYPE: MULTI SELECT, CHECKBOX"
        promptText={promptText}
        onChange={(newValue: string) => updateMeta({ promptText: newValue })}
        removeFieldFromFieldsList={props.removeFieldFromFieldsList}
        moveField={props.moveField}
        fieldIdx={props.fieldIdx}
      />

      <Box direction="row" align="center" wrap={true}>
        {choices.map((choice, idx) => {
          return checkboxOptionBuilder(choice, idx);
        })}
      </Box>

      <LinkButton label="Add a new row" onClick={addAdditionalOption} padding="none" />

      {unfilledChoices && (
        <Text size="12px" color="status-critical">
          Please fill in existing choices before adding new ones
        </Text>
      )}
      {cannotRemoveChoice && (
        <Text size="12px" color="status-critical">
          Field must have at least two choices
        </Text>
      )}
    </Box>
  );
};

export default MCCheckboxFieldBuilder;
