import RittenClient from './RittenClient';

class RemittanceClient extends RittenClient {
  listRemittances = async (
    params: Remittances.QueryParams,
  ): Promise<Remittances.GetRemttancesResponse> => {
    const { data } = await this.get<Remittances.GetRemttancesResponse>('api/remittances', {
      params,
    });
    return data;
  };

  postManualRemittance = async (
    remittance: Remittances.ManualRemittancePostBody,
  ): Promise<API.IDResponse> => {
    const { data } = await this.post<API.IDResponse>('api/remittances', remittance);
    return data;
  };

  getRemittance = async (remittanceId: string): Promise<Remittances.Remittance> => {
    const { data } = await this.get<Remittances.Remittance>(`api/remittances/${remittanceId}`);
    return data;
  };

  applySingleService = async (
    updatedService: Remittances.PutServiceBody,
    remittanceId: string,
  ): Promise<Remittances.Remittance> => {
    const { data } = await this.put<Remittances.Remittance>(
      `api/remittances/${remittanceId}/claims/services/${updatedService.id}/apply`,
      updatedService,
    );
    return data;
  };

  unapplySingleService = async (
    serviceId: string,
    remittanceId: string,
  ): Promise<Remittances.Remittance> => {
    const { data } = await this.patch<Remittances.Remittance>(
      `api/remittances/${remittanceId}/claims/services/${serviceId}/unapply`,
    );
    return data;
  };

  applyAllServices = async (
    remittanceId: string,
    services: Remittances.PutServiceBody[],
  ): Promise<Remittances.Remittance> => {
    const { data } = await this.put<Remittances.Remittance>(
      `api/remittances/${remittanceId}/apply`,
      { services },
    );
    return data;
  };

  unapplyAllServices = async (remittanceId: string): Promise<Remittances.Remittance> => {
    const { data } = await this.patch<Remittances.Remittance>(
      `api/remittances/${remittanceId}/unapply`,
    );
    return data;
  };

  putRemittanceChecks = async (
    remittanceId: string,
    payments: Remittances.PaymentPostBody[],
  ): Promise<Remittances.Payment[]> => {
    const { data } = await this.put<Remittances.Payment[]>(
      `api/remittances/${remittanceId}/payments`,
      { payments },
    );
    return data;
  };

  patchRemittanceDate = async (remittanceId: string, remittanceDate: string) => {
    await this.patch<void>(`api/remittances/${remittanceId}`, {
      remittanceDate,
    });
  };

  addClaimToRemittance = async (
    remittanceId: string,
    claimId: string,
  ): Promise<Remittances.Remittance> => {
    const { data } = await this.post<Remittances.Remittance>(
      `api/remittances/${remittanceId}/claim/${claimId}`,
    );
    return data;
  };

  revertRemittance = async (remittanceId: string) => {
    const { data } = await this.put<Remittances.Remittance>(
      `api/remittances/${remittanceId}/revert`,
    );
    return data;
  };

  // note this is the claim instance id - not the remittance claim id - so Remittances.Claim.instanceId
  removeClaimFromRemittance = async (
    remittanceId: string,
    claimId: string,
  ): Promise<Remittances.Remittance> => {
    const { data } = await this.delete<Remittances.Remittance>(
      `api/remittances/${remittanceId}/claim/${claimId}`,
    );
    return data;
  };

  deleteRemittance = async (remittanceId: string) => {
    await this.delete(`api/remittances/${remittanceId}`);
  };

  patchClaimPayment = async (remittanceId: string, claimId: string, paymentId: string) => {
    const { data } = await this.patch<Remittances.Remittance>(
      `api/remittances/${remittanceId}/claims/${claimId}`,
      {
        paymentId,
      },
    );
    return data;
  };
}

export default RemittanceClient;
