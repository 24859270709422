import { ReactNode } from 'react';
import { Box, BoxProps, DropProps, grommet, Text, ThemeContext, Tip } from 'grommet';
import { deepMerge } from 'grommet/utils';

import { isEmpty } from 'utils';
import { COLORS } from '../../styles/colors';

type SimpleTooltipProps = React.PropsWithChildren<
  Partial<BoxProps> & {
    text: string | ReactNode;
    color?: string;
    dropProps?: DropProps;
    tooltipBackgroundColor?: string;
  }
>;

const SimpleTooltip: React.FC<SimpleTooltipProps> = (props: SimpleTooltipProps) => {
  const themeTooltip = deepMerge(grommet, {
    tip: {
      content: {
        background: props.tooltipBackgroundColor ?? COLORS.white,
      },
    },
  });

  const getTooltipContent = () => {
    return (
      <Box>
        <Text color={props.color ?? COLORS.darkGray600} size="14px">
          {props.text}
        </Text>
      </Box>
    );
  };

  if (typeof props.text === 'string' && isEmpty(props.text)) {
    return <Box margin={props.margin}>{props.children}</Box>;
  }

  return (
    <ThemeContext.Extend value={themeTooltip}>
      <Tip content={getTooltipContent()} dropProps={props.dropProps}>
        <Box margin={props.margin}>{props.children}</Box>
      </Tip>
    </ThemeContext.Extend>
  );
};

export default SimpleTooltip;
