import { z } from 'zod';

import { usersActorSchema, usersUserSchema } from './users.zod';

export const tasksTaskStatusSchema = z.union([
  z.literal('New'),
  z.literal('Working'),
  z.literal("Won't Do"),
  z.literal('Complete'),
]);

export const tasksTaskPrioritySchema = z.union([
  z.literal('Low'),
  z.literal('Medium'),
  z.literal('High'),
]);

export const tasksTaskObjectSchema = z.union([
  z.literal('Form Field'),
  z.literal('Weblink'),
  z.literal('No Object'),
]);

export const tasksTaskCategorySchema = z.union([
  z.literal('Assigned To You'),
  z.literal('Created By You'),
  z.literal('Watching'),
]);

export const tasksCommentSchema = z.lazy(() =>
  z.object({
    id: z.string(),
    taskId: z.string(),
    comment: z.string(),
    createdBy: usersActorSchema,
    createdAt: z.date(),
    updated: z.boolean(),
    updatedAt: z.date(),
  }),
);

export const tasksTaskSchema = z.lazy(() =>
  z.object({
    id: z.string(),
    title: z.string(),
    description: z.string(),
    createdBy: usersActorSchema,
    createdAt: z.date(),
    assignee: usersActorSchema,
    watchers: z.array(usersUserSchema),
    dueDate: z.string(),
    status: tasksTaskStatusSchema,
    priority: tasksTaskPrioritySchema,
    objectType: tasksTaskObjectSchema,
    weblink: z.string().optional(),
    weblinkAlias: z.string().optional(),
    formField: z.string().optional(),
  }),
);

export const tasksTaskObjectSubtype = z.union([
  z.literal('Treatment Plan Problem'),
  z.literal('Treatment Plan Goal'),
  z.literal('Treatment Plan Objective'),
  z.literal('Treatment Plan Intervention'),
  z.literal('Treatment Plan Non-Plan Field'),
]);

export const tasksFormFieldResponseSchema = z.object({
  patientId: z.string(),
  encounterId: z.string(),
  formInstanceId: z.string(),
  formFieldId: z.string(),
  objectSubtype: tasksTaskObjectSubtype.optional(),
  objectSubtypeId: z.string().optional(),
});

export const tasksPostTaskSchema = z.object({
  id: z.string().optional(),
  title: z.string(),
  description: z.string(),
  assigneeId: z.string(),
  watcherIds: z.array(z.string()),
  priority: tasksTaskPrioritySchema,
  dueDate: z.string(),
  status: tasksTaskStatusSchema,
  weblink: z.string(),
  weblinkAlias: z.string(),
  objectType: tasksTaskObjectSchema,
  objectId: z.string(),
  objectSubtype: tasksTaskObjectSubtype.optional(),
  objectSubtypeId: z.string().optional(),
});

export const tasksTasksQuerySchema = z.object({
  creatorIds: z.array(z.string()),
  assigneeIds: z.array(z.string()),
  watcherIds: z.array(z.string()),
  statuses: z.array(tasksTaskStatusSchema),
  createdStartTime: z.string(),
  createdEndTime: z.string(),
  dueStartTime: z.date().optional(),
  dueEndTime: z.date().optional(),
  priorities: z.array(tasksTaskPrioritySchema),
  sort: z.string(),
  searchText: z.string(),
  offset: z.number(),
  limit: z.number(),
  objectTypes: z.array(tasksTaskObjectSchema).optional(),
  objectIds: z.array(z.string()),
});
