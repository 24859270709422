import { useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import { NavLink, useHistory } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';
import { addMinutes, isAfter } from 'date-fns';
import { Box, Grid, Header, Sidebar, Text } from 'grommet';
import { Add, FormClose, Menu, Share } from 'grommet-icons';
import styled from 'styled-components';

import IconButton from '@ritten/ui-library/buttons/IconButton';
import AnchorLink from '@ritten/ui-library/links/AnchorLink';
import Flyout from '@ritten/ui-library/modals/Flyout';
import SuccessToast from '@ritten/ui-library/toasts/SuccessToast';

import RittenBanner from 'assets/logos/RittenBanner';
import RittenLogo from 'assets/logos/RittenLogo';
import { FeatureFlagLabel } from 'common/feature-flags/featureFlags';
import useAuth0User from 'context/auth0/useAuth0User';
import useFeatureFlags from 'context/featureFlags/useFeatureFlags';
import { usePatientEventContext } from 'context/patients/PatientEventContext';
import useUser from 'context/user/useUser';
import { LOCAL_STORAGE_ON_TOKEN_GET_KEY } from 'external/RittenClient';
import useAPI from 'external/useAPI';
import CreateEditTask from 'features/home/tasks/CreateEditTask';
import UserProfileNavItem from 'features/nav/UserProfileNavItem';
import { useResponsiveDeviceSize } from 'hooks';
import { useWindowFocusBlur } from 'hooks/useWindowFocusBlur';
import { COLORS } from 'styles/colors';
import { getFullName } from 'utils';
import CollapsibleMenuNavLinks from './CollapsibleMenuNavLinks';
import SidebarNavLinks from './SidebarNavLinks';
import { getNotificationCount, renderDownloadsCount } from './utils';

export const SIDE_NAV_WIDTH_CLOSED = '45px';
export const SIDE_NAV_Z_INDEX = 12; // This ensures the nav item name tooltip renders above the scheduler day view time gutter and any toasts

const SideNavContainer = styled.div`
  position: fixed;
  height: 100vh;
  top: 0;
  left: 0;
  background: ${COLORS.darkGray600};
  width: ${SIDE_NAV_WIDTH_CLOSED};
  z-index: ${SIDE_NAV_Z_INDEX};
`;

/**
 * POLLING EXPLAINER
 *
 * The NavBar component is responsible for polling the API for notifications and downloads.
 * The term "polling" is used here in two different ways.
 *
 * 1. Notification Polling:
 *
 * The NavBar component polls the API for notifications at most every 5 minutes.
 * First, it checks for a token update in local storage every 1 minute. The token is updated every time an API call is made elsewhere in the application.
 * If the token has been updated, it checks if it has been at least 5 minutes since the last notification count update.
 * If it has been at least 5 minutes, it checks whether the user is focused on the tab/window that the application is running in.
 * If it is, it makes an API call to get the notification count.
 *
 * Previously, we had simply polled the notifications API every 5 minutes.
 * After discovering that this was keeping the user's session alive, we switched to the current method: the token update polling.
 * The token update indicates that the user is still actively using the application.
 *
 * More details on why we check the focus of the user's tab/window can be found here: https://ritten.atlassian.net/browse/RE-9189
 *
 * 2. Download Polling:
 *
 * This only occurs when triggered by a "downloadEvent".
 * Once triggered, the NavBar component polls the API for download counts every 5 seconds UNTIL all downloads are finished.
 * At this point, the polling stops until triggered by another "downloadEvent".
 */

const NavBar = ({ openDownloadsModal }) => {
  const { logout } = useAuth0();
  const { userAPI, documentAPI } = useAPI();
  const { user, notificationUpdateEvent } = useUser();
  const { tenants } = useAuth0User();
  const { downloadEvent } = usePatientEventContext();
  const featureFlags = useFeatureFlags();
  const {
    deviceSize: { isMedium, isLarge },
  } = useResponsiveDeviceSize();
  const history = useHistory();
  const { isFocused } = useWindowFocusBlur();

  const [openMenuType, setOpenMenuType] = useState<'top' | 'bottom' | null>(null);
  const [isNotificationPolling, setIsNotificationPolling] = useState<boolean>(false);
  const [userNotificationsCount, setUserNotificationsCount] = useState<number>(0);
  const [notificationUpdateExpiry, setNotificationUpdateExpiry] = useState<Date>(
    addMinutes(new Date(), -1), // initial expiry one minute ago, so that getAndSetUserNotificationCount will run on render
  );
  const [tokenUpdate, setTokenUpdate] = useState<string | null>('');
  const [downloadsCount, setDownloadsCount] = useState<number>(0);
  const [isCreateTaskModalOpen, setIsCreateTaskModalOpen] = useState<boolean>(false);

  const isCrmFeatureFlagOn = featureFlags.isFlagOn(FeatureFlagLabel.CRM);
  const isNotificationsFlagOn = featureFlags.isFlagOn(FeatureFlagLabel.NOTIFICATIONS);

  let downloadIntervalId = 0;
  let notificationIntervalId = 0;

  useEffect(() => {
    if (!isNotificationPolling && isNotificationsFlagOn) {
      startNotificationPolling();
    }

    const unlisten = history.listen(() => {
      // If the user navigates anywhere while the menu is open, close the menu
      setOpenMenuType(null);
    });

    return () => {
      unlisten();
      if (notificationIntervalId) {
        clearInterval(notificationIntervalId);
      }
      if (downloadIntervalId) {
        clearInterval(downloadIntervalId);
      }
    };
  }, []);

  useEffect(() => {
    if (isNotificationsFlagOn) {
      getAndSetUserNotificationCount();
    }
  }, [notificationUpdateEvent]); // on an actual notification update event, run the function

  useEffect(() => {
    // on a token update, only call getAndSetUserNotificationCount if the current time is after notificationUpdateExpiry
    if (isNotificationsFlagOn && isAfter(new Date(), notificationUpdateExpiry)) {
      getAndSetUserNotificationCount();
    }
  }, [tokenUpdate]);

  useEffect(() => {
    startDownloadPolling();
    getAndSetDownloadCount();
  }, [downloadEvent]);

  /**
   * startNotificationPolling starts the poll
   * to monitor local storage every minute
   */
  const startNotificationPolling = async () => {
    setIsNotificationPolling(true);
    const intervalMs = 60000;
    const intervalId = window.setInterval(checkForTokenUpdate, intervalMs);
    notificationIntervalId = intervalId;
  };

  /**
   * checkForTokenUpdate gets the item from the 'LOCAL_STORAGE_ON_TOKEN_GET_KEY'
   * in local storage and updates the state of tokenUpdate
   */
  const checkForTokenUpdate = () => {
    const tokenUpdate = window.localStorage.getItem(LOCAL_STORAGE_ON_TOKEN_GET_KEY);
    setTokenUpdate(tokenUpdate);
  };

  const startDownloadPolling = () => {
    const intervalMs = 5000; // 5 seconds
    const intervalId = window.setInterval(getAndSetDownloadCount, intervalMs);
    downloadIntervalId = intervalId;
  };

  const getAndSetUserNotificationCount = async () => {
    if (isFocused) {
      try {
        const notificationCount = await userAPI.getNotificationCounts();
        setUserNotificationsCount(notificationCount);
        setNotificationUpdateExpiry(addMinutes(new Date(), 5)); // every time we make this API request, reset the expiry to 5 minutes from now.
      } catch (err) {
        // eat the error for now because we don't have a way to show this to a user.
        // We also don't want to be bugging the user every 5 minutes assuming
        // the API continues to fail.
      }
    }
  };

  const getAndSetDownloadCount = async () => {
    try {
      const documentCountResponse = await documentAPI.queryDocumentCount(user.id);
      setDownloadsCount(documentCountResponse?.docCount);
      if (documentCountResponse?.allFinished) {
        window.clearInterval(downloadIntervalId);
      }
    } catch (err) {
      // Fail gracefully
    }
  };

  const getLogoNavTo = (): string => {
    const homeLink = '/';
    const navigationLink = '/navigation';
    if (tenants.length < 2) {
      return homeLink;
    }
    return history.location.pathname === navigationLink ? homeLink : navigationLink;
  };

  const onCreateTask = () => {
    setIsCreateTaskModalOpen(false);
    toast(
      <SuccessToast
        message={
          <Box direction="row" align="center" gap="10px" flex={false} pad="0 16px 0 0">
            <Text size="16px" weight={700}>
              A task was created
            </Text>
            <AnchorLink
              label="View Tasks"
              icon={<Share size="14px" color={COLORS.rittenBlue400} />}
              iconPosition="after"
              openInNewTab
              linkToUrl="/home/tasks?initialCategoryFilter=Created By You"
              padding="none"
              textWeight={700}
            />
          </Box>
        }
      />,
    );
  };

  const onOpenDownloadsModal = () => {
    setOpenMenuType(null);
    openDownloadsModal();
  };

  const sidebarLinksProps = {
    userNotificationsCount,
    downloadsCount,
    isCrmFeatureFlagOn,
    logout,
    onOpenDownloadsModal,
    user,
    dosespotNotificationsCount: !featureFlags.isFlagOn(FeatureFlagLabel.NOTIFICATIONS)
      ? getNotificationCount(user)
      : null,
    setIsCreateTaskModalOpen,
  };

  if (isMedium || isLarge) {
    return (
      <>
        <SideNavContainer id="SideBar">
          <div data-testid="click-outside-harness" style={{ minHeight: '1px' }} />
          <Grid fill rows={['auto', '1fr']}>
            <NavLink to={getLogoNavTo()} data-testid="logonav">
              <Box justify="center" pad={{ vertical: '10px' }} width={SIDE_NAV_WIDTH_CLOSED}>
                <RittenLogo height="25px" />
              </Box>
            </NavLink>
            <SidebarNavLinks {...sidebarLinksProps} />
          </Grid>
        </SideNavContainer>
        {isCreateTaskModalOpen && (
          <CreateEditTask
            onClose={() => setIsCreateTaskModalOpen(false)}
            onSuccess={onCreateTask}
            isNavMode
          />
        )}
      </>
    );
  }

  return (
    <>
      <Header background={COLORS.darkGray600} pad={{ horizontal: '24px', vertical: '12px' }}>
        <NavLink to={getLogoNavTo()} style={{ display: 'flex' }} data-testid="logonav">
          <RittenBanner height="25px" />
        </NavLink>
        <Box direction="row" align="center">
          <Box pad="0 10px" onClick={() => setIsCreateTaskModalOpen(true)}>
            <Box background={COLORS.rittenBlue400} pad="5px" round="4px">
              <Add size="14px" color={COLORS.white} />
            </Box>
          </Box>
          <Box pad="0 10px" onClick={() => setOpenMenuType('bottom')}>
            <div style={{ position: 'relative' }}>
              <UserProfileNavItem hideName containerHeight="24px" initialsDimensions="24px" />
              {renderDownloadsCount(downloadsCount, 'badge')}
            </div>
          </Box>
          <IconButton
            icon={<Menu color={COLORS.white} size="20px" />}
            color="plain"
            onClick={() => setOpenMenuType(openMenuType ? null : 'top')}
          />
        </Box>
      </Header>
      {openMenuType && (
        <Flyout full={false} position="right" width="234px">
          <Sidebar
            flex={false}
            pad="none"
            background={COLORS.darkGray600}
            height="100%"
            overflow="hidden"
          >
            <Box fill overflow="auto">
              <Box
                direction="row"
                align="center"
                justify={openMenuType === 'top' ? 'end' : 'between'}
                pad={{ horizontal: '15px', vertical: '10px' }}
              >
                {openMenuType === 'bottom' && (
                  <Text size="18px" weight={700} color={COLORS.white} wordBreak="break-all">
                    {getFullName(user)}
                  </Text>
                )}
                <IconButton
                  icon={<FormClose color={COLORS.white} size="31px" />}
                  color="plain"
                  onClick={() => setOpenMenuType(null)}
                />
              </Box>
              <CollapsibleMenuNavLinks {...sidebarLinksProps} openMenuType={openMenuType} />
            </Box>
          </Sidebar>
        </Flyout>
      )}
      {isCreateTaskModalOpen && (
        <CreateEditTask
          onClose={() => setIsCreateTaskModalOpen(false)}
          onSuccess={onCreateTask}
          isNavMode
        />
      )}
    </>
  );
};

export default NavBar;
