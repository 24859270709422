export type LeadAppCategory =
  | 'Person Seeking Treatment Information'
  | 'Insurance'
  | 'Custom Question'
  | 'Look and Feel';

export type LeadAppMode = 'builder' | 'application' | 'case';

export const LABEL_TO_CATEGORY_MAP: Record<CRM.LeadAppSectionLabel, LeadAppCategory> = {
  lead_app_name: 'Person Seeking Treatment Information',
  lead_app_dob: 'Person Seeking Treatment Information',
  lead_app_email: 'Person Seeking Treatment Information',
  lead_app_phone: 'Person Seeking Treatment Information',
  lead_app_address: 'Person Seeking Treatment Information',
  lead_app_insurance_card: 'Insurance',
  lead_app_payer_info: 'Insurance',
  lead_app_custom: 'Custom Question',
};

export const LEAD_APP_CATEGORY_TO_PUBLIC_DISPLAY_TEXT = {
  'Person Seeking Treatment Information': 'Client Information',
  Insurance: 'Payer Information',
  'Custom Question': 'Additional Information',
};

// Conditional fields shown only if lead_app_insured_different_than_client is checked
export const LEAD_APP_INSURED_DIFFERENT_SUBSCRIBER_FIELDS = [
  'lead_app_insured_first_name',
  'lead_app_insured_last_name',
  'lead_app_insured_address_1',
  'lead_app_insured_city',
  'lead_app_insured_state',
  'lead_app_insured_zip',
  'lead_app_insured_dob',
  'lead_app_insured_relationship',
];

export const SKIP_REQUIRED_VALIDATION_FIELDS = [
  'lead_app_address2',
  'lead_app_insured_different_than_client',
  ...LEAD_APP_INSURED_DIFFERENT_SUBSCRIBER_FIELDS,
];

export const SKIP_REQUIREMENT_MARKER_FIELDS = [
  'lead_app_address2',
  'lead_app_insured_different_than_client',
];

export const ORDERED_CATEGORIES: LeadAppCategory[] = [
  'Person Seeking Treatment Information',
  'Insurance',
  'Custom Question',
];
