import { Box, Text } from 'grommet';

import { isNotEmpty } from '../../utils/stringUtils';

type ErrorTextProps = {
  show: boolean;
  children: string | null;
  size?: 'xsmall' | 'small' | 'medium';
};

export const ErrorText: React.FC<ErrorTextProps> = (props: ErrorTextProps) => {
  if (props.show && isNotEmpty(props.children)) {
    return (
      <Box>
        <Text color="status-critical" size={props.size || 'medium'}>
          {props.children}
        </Text>
      </Box>
    );
  }

  return null;
};

export default ErrorText;
