// @ts-strict-ignore
import { useState } from 'react';
import { Box, CheckBox, CheckBoxProps, Text, ThemeContext } from 'grommet';
import { grommet } from 'grommet/themes';
import { deepMerge } from 'grommet/utils';

import { COLORS } from '../../styles/colors';

export interface CheckboxComponentProps {
  label: string;
  inlineLabel?: boolean;
  checkBoxProps: CheckBoxProps & {
    disabled?: boolean;
    iconSize?: string;
    onClick?: () => void;
  };
  labelProps?: {
    size: string;
    color?: string;
  };
  'data-cy'?: string;
  'data-testid'?: string;
}

const CheckboxComponent = (props: CheckboxComponentProps) => {
  const themeCheckbox = deepMerge(grommet, {
    checkBox: {
      size: props.checkBoxProps.iconSize || '24px',
      icon: {
        size: props.checkBoxProps.iconSize || '24px',
        extend: {
          background: COLORS.rittenBlue400,
          borderRadius: '2px',
          overflow: 'visible',
        },
      },
      border: {
        color: COLORS.darkGray400,
        width: '1px',
      },
      color: COLORS.white,
      check: {
        radius: '2px',
        extend: {
          background: COLORS.white,
        },
      },
      hover: {
        border: {
          color: COLORS.darkGray600,
        },
      },
    },
  });

  const [checked, setChecked] = useState<boolean>(props.checkBoxProps.checked);
  const checkboxIsClickable = props.checkBoxProps.onClick && !props.checkBoxProps.disabled;
  const onClick = () => {
    if (checkboxIsClickable) {
      setChecked(!checked);
      props.checkBoxProps.onClick?.();
    }
  };

  const labelProps = {
    ...props.labelProps,
    onClick,
    style: { cursor: checkboxIsClickable ? 'pointer' : 'auto' },
  };

  return (
    <>
      {props.inlineLabel ? (
        <Box direction="row" align="center" gap="10px">
          <ThemeContext.Extend value={themeCheckbox}>
            <CheckBox
              {...props.checkBoxProps}
              data-cy={props['data-cy']}
              data-testid={props['data-testid']}
              aria-label={props.label || ''}
            />
          </ThemeContext.Extend>

          <Text size="14px" {...labelProps}>
            {props.label}
          </Text>
        </Box>
      ) : (
        <Box>
          {props.label.length > 0 && (
            <Box pad={{ bottom: '10px' }}>
              <Text size="14px" {...labelProps}>
                {props.label}
              </Text>
            </Box>
          )}
          <ThemeContext.Extend value={themeCheckbox}>
            <CheckBox
              {...props.checkBoxProps}
              data-cy={props['data-cy']}
              data-testid={props['data-testid']}
              aria-label={props.label || ''}
            />
          </ThemeContext.Extend>
        </Box>
      )}
    </>
  );
};

export default CheckboxComponent;
