import { Box, TextInput, TextInputProps, ThemeContext } from 'grommet';
import { grommet } from 'grommet/themes';
import { deepMerge } from 'grommet/utils';

import ErrorLabel from '../labels/ErrorLabel';
import InputLabel from './InputLabel';
import { useInputTheme } from './useInputTheme';

export interface TextInputComponentProps {
  label?: string;
  requirementMarker?: string;
  tooltipText?: string;
  error?: string;
  width?: string;
  flex?: 'grow' | 'shrink' | boolean | { grow?: number; shrink?: number };
  textInputProps: TextInputProps & {
    required?: boolean;
    disabled?: boolean;
    onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
    style?: React.CSSProperties;
  };
  'data-cy'?: string;
  'data-testid'?: string;
  autoFocus?: boolean;
  inputLabel?: React.ReactNode;
  hideErrorMessage?: boolean;
}

export const TextInputComponent = (props: TextInputComponentProps) => {
  const { extendInputContainerProperties, focusStyle, onFocus, getErrorStyle } = useInputTheme();
  const errorStyle = getErrorStyle(props.error);

  const themeTextInput = deepMerge(grommet, {
    textInput: {
      extend: extendInputContainerProperties(props.width, props.textInputProps.disabled),
      disabled: {
        opacity: 1,
      },
    },
  });

  return (
    <ThemeContext.Extend value={themeTextInput}>
      <Box flex={props.flex}>
        {props.inputLabel ? (
          props.inputLabel
        ) : (
          <InputLabel
            tooltipText={props.tooltipText}
            label={props.label}
            requirementMarker={props.requirementMarker}
          />
        )}
        <TextInput
          data-cy={props['data-cy']}
          data-testid={props['data-testid']}
          {...props.textInputProps}
          aria-label={props.label || ''}
          onFocus={(e) => {
            if (props.textInputProps.onFocus) {
              props.textInputProps.onFocus(e);
            }
            onFocus(true);
          }}
          onBlur={(e) => {
            if (props.textInputProps.onBlur) {
              props.textInputProps.onBlur(e);
            }
            onFocus(false);
          }}
          autoFocus={props.autoFocus}
          style={{ ...(props.textInputProps.style ?? {}), ...focusStyle, ...errorStyle }}
        />
        {props.error && !props.hideErrorMessage && (
          <Box pad={{ top: 'xxsmall' }}>
            <ErrorLabel>{props.error}</ErrorLabel>
          </Box>
        )}
      </Box>
    </ThemeContext.Extend>
  );
};

export default TextInputComponent;
