import { Button, ButtonExtendedProps, Spinner } from 'grommet';

export interface AsyncButtonProps extends ButtonExtendedProps {
  children?: React.ReactNode;
  isLoading?: boolean;
  onClick?: () => void;
}

/**
 * @deprecated Not for direct use in UI
 */
const AsyncButton: React.FC<AsyncButtonProps> = (props: AsyncButtonProps) => {
  const { isLoading = false } = props;

  if (isLoading) {
    return (
      <div style={{ position: 'relative' }}>
        <div
          style={{
            position: 'absolute',
            inset: 0,
            margin: 'auto',
            width: 'fit-content',
            height: 'fit-content',
          }}
        >
          <Spinner size="xsmall" color={props.color} />
        </div>
        <Button {...props} disabled />
      </div>
    );
  }

  return <Button {...props} />;
};

export default AsyncButton;
