// @ts-strict-ignore
import { Box, Text } from 'grommet';
import { Checkbox, CheckboxSelected } from 'grommet-icons';
import { isEqual } from 'lodash';

import { COLORS } from 'styles/colors';
import { DropdownChoice, getChoiceLabel, getChoiceValue, SELECT_ALL_CHOICE } from '../Dropdown';

interface ChoiceLabelProps {
  variant: 'single-choice' | 'multiple-choice';
  choice: DropdownChoice;
  onSelectChoice: (choice: DropdownChoice) => void;
  choices: DropdownChoice[];
  multipleChoiceSelections?: DropdownChoice[];
  renderChoice?: (choice: DropdownChoice) => JSX.Element;
  choiceLabelKey?: string;
  choiceValueKey?: string;
  style?: React.CSSProperties;
}

const ChoiceLabel = (props: ChoiceLabelProps) => {
  const {
    variant,
    choice,
    onSelectChoice,
    choices,
    renderChoice,
    multipleChoiceSelections,
    choiceLabelKey,
    choiceValueKey,
    style,
  } = props;

  const choiceText = getChoiceLabel(choice, choiceLabelKey);

  let choiceComponent = null;

  const renderDropdownChoice = ({ choice, choiceText, choiceValue }) => {
    if (renderChoice && choiceValue !== SELECT_ALL_CHOICE) {
      return renderChoice(choice);
    }
    return <Text>{choiceText}</Text>;
  };

  switch (variant) {
    case 'single-choice': {
      choiceComponent = renderChoice ? renderChoice(choice) : <Text size="16px">{choiceText}</Text>;
      break;
    }
    case 'multiple-choice': {
      const checkboxProps = { size: '16px', color: COLORS.darkGray600 };
      let isSelected;
      const choiceValue = getChoiceValue(choice, choiceValueKey);
      if (choiceValue === SELECT_ALL_CHOICE && choices.length === multipleChoiceSelections.length) {
        isSelected = true;
      } else {
        isSelected = multipleChoiceSelections.find((selected) => {
          const selectionValue = getChoiceValue(selected, choiceValueKey);
          return isEqual(choiceValue, selectionValue);
        });
      }

      choiceComponent = (
        <Box
          direction="row"
          align="center"
          pad={{ horizontal: '8px' }}
          width="100%"
          flex={false}
          gap="8px"
        >
          {isSelected ? <CheckboxSelected {...checkboxProps} /> : <Checkbox {...checkboxProps} />}
          {renderDropdownChoice({ choice, choiceText, choiceValue })}
        </Box>
      );
      break;
    }
    default: {
      break;
    }
  }

  return (
    <Box
      flex={false}
      pad="8px"
      justify="center"
      onClick={() => onSelectChoice(choice)}
      hoverIndicator={COLORS.darkGray200}
      style={style}
    >
      {choiceComponent}
    </Box>
  );
};

export default ChoiceLabel;
