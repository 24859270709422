/* eslint-disable no-extend-native */
import arrayAt from 'core-js/stable/array/at';
import stringAt from 'core-js/stable/string/at';

if (!String.prototype.at) {
  String.prototype.at = stringAt;
}

if (!String.prototype.at) {
  String.prototype.at = arrayAt;
}
