import { parseISODateStrings } from 'utils';
import RittenClient from './RittenClient';

class PaymentsClient extends RittenClient {
  postClientPayment = async (
    clientId: string,
    payment: Payments.PostPayment,
  ): Promise<Payments.PaymentResponse> => {
    const { data } = await this.post<Payments.PaymentResponse>(
      `/api/patients/${clientId}/billing/payments`,
      payment,
    );
    return parseISODateStrings(data);
  };

  postInvoice = async (
    clientId: string,
    invoice: Payments.PostInvoice,
  ): Promise<Payments.InvoiceResponse> => {
    const { data } = await this.post<Payments.InvoiceResponse>(
      `/api/patients/${clientId}/billing/invoices`,
      invoice,
    );
    return parseISODateStrings(data);
  };

  postInvoicePayment = async (
    clientId: string,
    invoiceId: string,
    payment: Payments.PostPayment,
  ): Promise<Payments.InvoiceResponse> => {
    const { data } = await this.post<Payments.InvoiceResponse>(
      `/api/patients/${clientId}/billing/invoices/${invoiceId}/payments`,
      payment,
    );
    return parseISODateStrings(data);
  };

  voidInvoice = async (clientId: string, invoiceId: string): Promise<void> => {
    await this.post(`/api/patients/${clientId}/billing/invoices/${invoiceId}/void`);
  };

  postDebit = async (clientId: string, debit: Payments.PostDebit): Promise<void> => {
    await this.post<Payments.DebitResponse>(`/api/patients/${clientId}/billing/debits`, debit);
  };

  updateDebit = async (clientId: string, debitId: string, debit: Payments.PostDebit) => {
    await this.put<void>(`/api/patients/${clientId}/billing/debits/${debitId}`, debit);
  };

  postDebitWriteOff = async (
    clientId: string,
    debitId: string,
    writeOff: Payments.PostWriteOff,
  ): Promise<Payments.DebitResponse> => {
    const { data } = await this.post<Payments.DebitResponse>(
      `/api/patients/${clientId}/billing/debits/${debitId}/write-offs`,
      writeOff,
    );
    return parseISODateStrings(data);
  };

  undoWriteOff = async (
    clientId: string,
    debitId: string,
    writeOffs: Payments.RemoveWriteOff,
  ): Promise<void> => {
    await this.delete(
      `/api/patients/${clientId}/billing/debits/${debitId}/write-offs`,
      undefined,
      writeOffs,
    );
  };

  applyCredits = async (clientId: string, body: Payments.ApplyCreditsBody): Promise<void> => {
    await this.post<void>(`/apit/patients/${clientId}/billing/credits`, body);
  };

  applyCreditToDebit = async (
    clientId: string,
    debitId: string,
  ): Promise<Payments.DebitResponse> => {
    const { data } = await this.post<Payments.DebitResponse>(
      `/api/patients/${clientId}/billing/debits/${debitId}/credits`,
    );
    return parseISODateStrings(data);
  };

  postDebitPayment = async (
    clientId: string,
    debitId: string,
    payment: Payments.PostPayment,
  ): Promise<Payments.DebitResponse> => {
    const { data } = await this.post<Payments.DebitResponse>(
      `/api/patients/${clientId}/billing/debits/${debitId}/payments`,
      payment,
    );
    return parseISODateStrings(data);
  };

  unapplyDebitPayment = async (
    clientId: string,
    debitId: string,
    body: Payments.UnapplyCreditsBody,
  ): Promise<Payments.DebitResponse> => {
    const { data } = await this.delete<Payments.DebitResponse>(
      `/api/patients/${clientId}/billing/debits/${debitId}/credits`,
      undefined,
      body,
    );
    return parseISODateStrings(data);
  };

  refundPayment = async (clientId: string, paymentId: string) => {
    await this.post<void>(`/api/patients/${clientId}/billing/payments/${paymentId}/refund`);
  };

  listPayments = async (
    clientId: string,
    params: Payments.ListPaymentsParams,
  ): Promise<Payments.ListPaymentsResponse> => {
    const { data } = await this.get<Payments.ListPaymentsResponse>(
      `/api/patients/${clientId}/billing/payments`,
      { params },
    );
    return parseISODateStrings(data);
  };

  listInvoices = async (
    clientId: string,
    params: Payments.ListInvoicesParams,
  ): Promise<Payments.ListInvoicesResponse> => {
    const { data } = await this.get<Payments.ListInvoicesResponse>(
      `/api/patients/${clientId}/billing/invoices`,
      { params },
    );
    return parseISODateStrings(data);
  };

  listDebits = async (
    clientId: string,
    params: Payments.ListDebitsParams,
  ): Promise<Payments.ListDebitsResponse> => {
    const { data } = await this.get<Payments.ListDebitsResponse>(
      `/api/patients/${clientId}/billing/debits`,
      {
        params,
      },
    );
    return parseISODateStrings(data);
  };

  getClientBalances = async (clientId: string) => {
    const { data } = await this.get<Payments.ClientBalanceResponse>(
      `/api/patients/${clientId}/billing/balance`,
    );
    return data;
  };

  getBillingRecipients = async (clientId: string): Promise<Payments.BillToResponse[]> => {
    const { data } = await this.get<Payments.BillToResponse[]>(
      `/api/patients/${clientId}/billing/recipients`,
    );
    return data;
  };

  deleteDebit = async (debitId: string, clientId: string): Promise<void> => {
    await this.delete<void>(`/api/patients/${clientId}/billing/debits/${debitId}`);
  };
}

export default PaymentsClient;
