import { Box, Text } from 'grommet';
import { FormClose } from 'grommet-icons';

import { COLORS } from 'styles/colors';
import { DropdownChoice, getChoiceLabel, getChoiceValue } from '../Dropdown';

interface MultiChoiceExternalTagsProps {
  selections: DropdownChoice[];
  disabled: boolean;
  onRemoveSelection: (choice: DropdownChoice) => void;
  showTagsBelow?: boolean;
  choiceTextDataCy?: string;
  choiceLabelKey?: string;
  choiceValueKey?: string;
}

const MultiChoiceExternalTags = (props: MultiChoiceExternalTagsProps) => {
  const {
    selections,
    disabled,
    onRemoveSelection,
    showTagsBelow,
    choiceTextDataCy,
    choiceLabelKey,
    choiceValueKey,
  } = props;

  return (
    <Box direction="row" fill wrap>
      {selections?.length > 0 &&
        selections.map((choice, idx) => {
          const label = getChoiceLabel(choice, choiceLabelKey);
          const value = getChoiceValue(choice, choiceValueKey);

          return (
            <Box
              key={idx}
              pad={
                showTagsBelow
                  ? {
                      right: '20px',
                      bottom: '0px',
                      top: '16px',
                    }
                  : {
                      left: '20px',
                      bottom: '0px',
                      top: '25px',
                    }
              }
            >
              <Box
                key={value}
                onClick={
                  !disabled
                    ? (event: React.MouseEvent<HTMLElement>) => {
                        event.preventDefault();
                        event.stopPropagation();
                        onRemoveSelection(choice);
                      }
                    : undefined
                }
                onFocus={(event) => event.stopPropagation()}
                border={{ size: '1px', color: COLORS.rittenBlue400, style: 'dashed' }}
                direction="row"
                height={{ min: '42px' }}
                align="center"
                background={disabled ? COLORS.darkGray200 : COLORS.white}
                style={{
                  opacity: 1,
                  cursor: disabled ? 'default' : 'pointer',
                  borderRadius: '4px',
                }}
                pad={{ horizontal: '10px' }}
              >
                <Box direction="row" align="center" pad={{ right: '10px' }}>
                  <Text data-cy={choiceTextDataCy} color={COLORS.darkGray600} size="16px">
                    {label}
                  </Text>
                </Box>
                <Box data-cy="form-close-button">
                  <FormClose size="16px" color={COLORS.red400} />
                </Box>
              </Box>
            </Box>
          );
        })}
    </Box>
  );
};

export default MultiChoiceExternalTags;
