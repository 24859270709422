import { useContext } from 'react';

import UserContext from './UserContext';

// useUser provides access to the UserContext
const useUser = (): UserContext => {
  const context = useContext(UserContext);
  if (context === null) {
    throw new Error('useUser must be used inside a UserContext.Provider');
  }
  return context;
};

export default useUser;
