// Generated by ts-to-zod
import { z } from 'zod';

import { catalogClinicProgramTypeSchema, catalogPlaceOfServiceCodeSchema } from './catalog.zod';
import { claimsProviderEntityTypeSchema } from './claims.zod';
import {
  patientAddressSchema,
  patientAddressUseSchema,
  patientContactPointSchema,
  patientInsurancePayerSchema,
} from './patient.zod';
import { usersUserSchema } from './users.zod';

export const clinicGetClinicProgramsQuerySchema = z.lazy(() =>
  z.object({
    showArchived: z.boolean(),
    programType: catalogClinicProgramTypeSchema.optional(),
    loadPatientIds: z.boolean().optional(),
  }),
);

export const clinicClinicProgramPostBodySchema = z.object({
  id: z.string(),
  programName: z.string(),
  levelOfCare: z.array(z.string()),
});

export const clinicDischargeTypeSchema = z.object({
  id: z.string(),
  name: z.string(),
  dischargeType: z.string(),
  createdAt: z.date(),
});

export const clinicDischargeTypePostBodySchema = z.object({
  id: z.string(),
  name: z.string(),
  dischargeType: z.string(),
});

export const clinicReferralTypeSchema = z.object({
  id: z.string(),
  type: z.string(),
  createdAt: z.date(),
  deletedAt: z.date(),
});

export const clinicReferralTypePostBodySchema = z.object({
  id: z.string(),
  type: z.string(),
});

export const clinicPayerGroupSchema = z.lazy(() =>
  z.object({
    id: z.string(),
    name: z.string(),
    claimsAcceptAssignment: z.boolean().optional(),
    claimsBillingProvider: z.any().optional(),
    claimsRenderingProvider: z.any().optional(),
    claimsPayerType: z.string().optional(),
    claimsPlaceOfService: catalogPlaceOfServiceCodeSchema.optional(),
    claimsSendAdmittingDiagnosis: z.boolean().optional(),
    createdAt: z.date(),
  }),
);

export const clinicPayerGroupPostBodySchema = z.object({
  name: z.string(),
  claimsAcceptAssignment: z.boolean().optional().nullable(),
  claimsBillingProviderId: z.string().optional(),
  claimsRenderingProviderId: z.string().optional(),
  claimsPayerType: z.string().optional(),
  claimsPlaceOfService: z.string().optional(),
  claimsSendAdmittingDiagnosis: z.boolean().optional(),
});

export const clinicPayerGroupPatchBodySchema = z.object({
  id: z.string(),
  name: z.string(),
  claimsAcceptAssignment: z.boolean().optional().nullable(),
  claimsBillingProviderId: z.string().optional(),
  claimsRenderingProviderId: z.string().optional(),
  claimsPayerType: z.string().optional(),
  claimsPlaceOfService: z.string().optional(),
  claimsSendAdmittingDiagnosis: z.boolean().optional(),
});

export const clinicCaseStatusSchema = z.object({
  id: z.string(),
  name: z.string(),
  description: z.string(),
  position: z.number(),
  isEditable: z.boolean(),
  isProtected: z.boolean(),
  createdAt: z.string(),
  deletedAt: z.string(),
});

export const clinicCaseStatusPostBodySchema = z.object({
  id: z.string().optional(),
  name: z.string(),
  description: z.string().optional(),
});

export const clinicCaseStatusPatchBodySchema = z.object({
  id: z.string(),
  position: z.number(),
});

export const clinicDisqualificationReasonSchema = z.object({
  id: z.string(),
  name: z.string(),
  createdAt: z.date(),
});

export const clinicDisqualificationReasonPostBodySchema = z.object({
  id: z.string().optional(),
  name: z.string(),
});

export const clinicFacilitySchema = z.lazy(() =>
  z.object({
    id: z.string(),
    isDefault: z.boolean(),
    name: z.string(),
    nationalProviderId: z.string().optional(),
    taxonomyCode: z.string(),
    taxId: z.string(),
    collaborateMDId: z.string(),
    primaryAddress: patientAddressSchema,
    payToAddress: patientAddressSchema,
    contactPoints: z.array(patientContactPointSchema),
    hasPayTo: z.boolean(),
    createdAt: z.date(),
  }),
);

export const clinicFacilityPostBodySchema = z.lazy(() =>
  z.object({
    isDefault: z.boolean(),
    name: z.string(),
    nationalProviderId: z.string().optional(),
    taxonomyCode: z.string().optional(),
    collaborateMDId: z.string().optional(),
    taxId: z.string().optional(),
    primaryAddress: facilityAddressPostBody,
    payToAddress: facilityAddressPostBody.optional(),
    contactPoints: z.array(patientContactPointSchema),
    hasPayTo: z.boolean(),
  }),
);

export const clinicFacilityPutBodySchema = z.lazy(() =>
  z.object({
    id: z.string(),
    isDefault: z.boolean(),
    name: z.string(),
    nationalProviderId: z.string().optional(),
    taxonomyCode: z.string().optional(),
    collaborateMDId: z.string().optional(),
    taxId: z.string().optional(),
    primaryAddress: facilityAddressPostBody,
    payToAddress: facilityAddressPostBody.optional(),
    contactPoints: z.array(patientContactPointSchema),
    hasPayTo: z.boolean(),
  }),
);

export const facilityAddressPostBody = z.lazy(() =>
  z.object({
    id: z.string().optional(),
    use: patientAddressUseSchema,
    country: z.string(),
    line: z.string(),
    line2: z.string().optional(),
    city: z.string(),
    region: z.string(),
    postalCode: z.string(),
  }),
);

export const clinicProviderSchema = z.lazy(() =>
  z.object({
    id: z.string(),
    createdAt: z.date().optional(),
    entityType: claimsProviderEntityTypeSchema,
    facility: clinicFacilitySchema,
    user: usersUserSchema,
    assignedUsers: z.array(usersUserSchema).optional(),
  }),
);

export const clinicProviderPostBodySchema = z.lazy(() =>
  z.object({
    id: z.string(),
    entityType: claimsProviderEntityTypeSchema,
    facilityId: z.string(),
    userId: z.string(),
  }),
);

export const clinicAuthorizationTypeSchema = z.union([
  z.literal('Facility Contract'),
  z.literal('Patient Contract'),
]);

export const clinicAdmittingDiagnosisTypeSchema = z.union([
  z.literal('Mental Health'),
  z.literal('Substance Abuse'),
]);

export const clinicTemplatedTextQuerySchema = z.object({
  searchParam: z.string().optional(),
  noFieldTemplates: z.boolean().optional(),
  noUserTemplates: z.boolean().optional(),
  fieldDefinitionId: z.string().optional(),
  userId: z.string().optional(),
  limit: z.number().optional(),
  offset: z.number().optional(),
});

export const clinicTemplatedTextItemSchema = z.object({
  id: z.string(),
  label: z.string(),
  fieldDefinitionId: z.string(),
  userId: z.string(),
  value: z.string(),
});

export const clinicAuthorizationConfigSchema = z.object({
  id: z.string(),
  authorizationTemplateId: z.string(),
  authorizationType: clinicAuthorizationTypeSchema,
  procedureCode: z.string(),
  admittingDiagnosis: clinicAdmittingDiagnosisTypeSchema,
  totalDaysAuthorized: z.number(),
  calendarDays: z.number(),
  daysPerWeek: z.number(),
  notes: z.string(),
  createdAt: z.date(),
});

export const clinicAuthorizationConfigPostBodySchema = z.object({
  id: z.string().optional(),
  authorizationTemplateId: z.string().optional(),
  authorizationType: clinicAuthorizationTypeSchema.optional(),
  procedureCode: z.string(),
  admittingDiagnosis: clinicAdmittingDiagnosisTypeSchema.optional(),
  totalDaysAuthorized: z.number().optional(),
  calendarDays: z.number().optional(),
  daysPerWeek: z.number().optional(),
  notes: z.string().optional(),
});

export const clinicAuthorizationTemplateSchema = z.lazy(() =>
  z.object({
    id: z.string(),
    name: z.string(),
    payer: patientInsurancePayerSchema,
    authorizations: z.array(clinicAuthorizationConfigSchema),
    count: z.number(),
    createdAt: z.date(),
  }),
);

export const clinicAuthorizationTemplatePostBodySchema = z.object({
  id: z.string().optional(),
  name: z.string(),
  payerId: z.string().optional(),
  authorizations: z.array(clinicAuthorizationConfigPostBodySchema).optional(),
});

export const rateSchema = z.object({
  id: z.string(),
  code: z.string(),
  procedureCodeModifiers: z.array(z.string()),
  billedRateCents: z.number().optional(),
  allowedRateCents: z.number().optional(),
  description: z.string(),
});

export const feeScheduleSchema = z.object({
  id: z.string(),
  isDefault: z.boolean(),
  name: z.string(),
  payerGroups: z.array(clinicPayerGroupSchema),
  rates: z.array(rateSchema),
  createdAt: z.date().optional(),
});

export const ratePostBodySchema = z.object({
  description: z.string(),
  billedRateCents: z.number(),
  allowedRateCents: z.number(),
  code: z.string(),
  procedureCodeModifiers: z.array(z.string()),
});

export const feeSchedulePostBodySchema = z.object({
  name: z.string(),
  payerGroupIds: z.array(z.string()),
  rates: z.array(ratePostBodySchema),
});

export const feeSchedulePutBodySchema = z.object({
  id: z.string(),
  name: z.string(),
  payerGroupIds: z.array(z.string()),
  rates: z.array(ratePostBodySchema),
});

export const billingGlobalVariablesSchema = z.lazy(() =>
  z.object({
    cms1500Employment: z.boolean().optional(),
    cms1500AutoAccident: z.boolean().optional(),
    cms1500AutoAccidentState: z.string().optional(),
    cms1500OtherAccident: z.boolean().optional(),
    cms1500OutsideLab: z.boolean().optional(),
    cms1500OutsideLabChargeCents: z.number(),
    cms1500AcceptAssignment: z.boolean().optional(),
    cms1500BillingProvider: clinicProviderSchema.optional(),
    cms1500RenderingProvider: clinicProviderSchema.optional(),
    ub04BillTypeCode2: z.number(),
    ub04BillTypeCode3: z.number(),
    ub04BillTypeCode4: z.number(),
    ub04BillingProvider: clinicProviderSchema.optional(),
    ub04AttendingProvider: clinicProviderSchema.optional(),
  }),
);

export const billingGlobalVariablesPutBodySchema = z.lazy(() =>
  z.object({
    cms1500Employment: z.boolean().optional(),
    cms1500AutoAccident: z.boolean().optional(),
    cms1500AutoAccidentState: z.string().optional(),
    cms1500OtherAccident: z.boolean().optional(),
    cms1500OutsideLab: z.boolean().optional(),
    cms1500OutsideLabChargeCents: z.number(),
    cms1500AcceptAssignment: z.boolean().optional(),
    cms1500RenderingProviderId: z.string().optional(),
    cms1500BillingProviderId: z.string().optional(),
    ub04BillTypeCode2: z.number(),
    ub04BillTypeCode3: z.number(),
    ub04BillTypeCode4: z.number(),
    ub04BillingProviderId: z.string().optional(),
    ub04AttendingProviderId: z.string().optional(),
  }),
);
