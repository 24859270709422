import RittenClient from './RittenClient';

export default class FileDownloadsClient extends RittenClient {
  downloadFileById = async (fileId: string) => {
    const res = await this.get<Blob>(`/api/public/files/${fileId}/download`, {
      responseType: 'blob',
    });
    return res.data;
  };
}
