import { forwardRef } from 'react';
import { Box, TextInput, ThemeContext } from 'grommet';
import { grommet } from 'grommet/themes';
import { deepMerge } from 'grommet/utils';

const themeComponent = deepMerge(grommet, {
  global: {
    colors: {
      focus: undefined,
    },
    input: {
      extend: {
        height: '44px',
      },
    },
  },
  textInput: {
    extend: {
      fontSize: '16px',
      fontWeight: '400',
      padding: 0,
    },
    disabled: {
      opacity: 1,
    },
  },
});

interface PhoneTextInputProps extends React.ComponentPropsWithoutRef<'input'> {
  value?: string;
  onBlur?: () => void;
  onFocus?: () => void;
  rest;
}

export const PhoneTextInput = forwardRef((props: PhoneTextInputProps, ref) => {
  const { value, disabled, onBlur, onFocus, rest, onChange } = props;

  return (
    <ThemeContext.Extend value={themeComponent}>
      <Box width="100%">
        <TextInput
          plain={true}
          value={value}
          ref={ref}
          disabled={disabled}
          onChange={onChange}
          onBlur={onBlur}
          onFocus={onFocus}
          {...rest}
        />
      </Box>
    </ThemeContext.Extend>
  );
});

export default PhoneTextInput;
