import { parseISODateStrings } from 'utils';
import RittenClient from './RittenClient';

export default class ComplianceClient extends RittenClient {
  getProgramRules = async (): Promise<Compliance.ProgramRuleBlock[]> => {
    const res = await this.get<Compliance.ProgramRuleBlock[]>('/api/compliance/clinic-form-rules');
    return parseISODateStrings(res.data);
  };

  postProgramFormRules = async (ruleBody: Compliance.PostFormRule[]): Promise<void> => {
    await this.post('/api/compliance/clinic-form-rules', ruleBody);
  };

  deleteProgramFormRules = async (ruleIds: string[]): Promise<void> => {
    await this.delete('/api/compliance/clinic-form-rules', { data: { ruleIds } });
  };

  listRequiredFormsv2 = async ({
    searchQuery,
    formStatuses,
    programIds,
    levelsOfCare,
    primaryClinicianIds,
    careTeamMemberIds,
    sortBy,
    offset,
  }: {
    searchQuery?: string;
    formStatuses?: Compliance.RequiredFormsStatus[];
    programIds?: string[];
    levelsOfCare?: string[];
    primaryClinicianIds?: string[];
    careTeamMemberIds?: string[];
    sortBy?: Compliance.RequiredFormsSortType;
    offset?: number;
  }): Promise<Compliance.RequiredFormResponse[]> => {
    const res = await this.get<Compliance.RequiredFormResponse[]>(
      '/api/compliance/v2/required-forms',
      {
        params: {
          searchQuery,
          formStatuses,
          programIds,
          levelsOfCare,
          primaryClinicianIds,
          careTeamMemberIds,
          sortBy,
          offset,
        },
      },
    );
    return parseISODateStrings(res.data);
  };

  getRequiredFormsByClientv2 = async (
    clientId: string,
  ): Promise<Compliance.RequiredFormResponse[]> => {
    const res = await this.get<Compliance.RequiredFormResponse[]>(
      `/api/compliance/v2/required-forms/${clientId}`,
    );
    return parseISODateStrings(res.data);
  };

  /**
   *
   * @param formInstanceId form instance id for which we want the associated due form
   * @returns the due form associated with the given form instance id
   */
  getDueFormByFormInstanceId = async (formInstanceId: string): Promise<Compliance.DueForm> => {
    const res = await this.get<Compliance.DueForm>(`/api/compliance/due-forms/${formInstanceId}`);
    return parseISODateStrings(res.data);
  };

  /**
   *
   * @param dueFormId the due form to be dismissed
   * @returns the dismissed due form (with new_instance_needed = false)
   */
  dismissDueForm = async (dueFormId: string): Promise<Compliance.DueForm> => {
    const res = await this.patch<Compliance.DueForm>(
      `/api/compliance/due-forms/${dueFormId}/dismiss`,
    );
    return parseISODateStrings(res.data);
  };

  /**
   *
   * @param dueFormId the due form to be updated
   * @param newInstanceNeededByDate the new "new_instance_needed_by" Date
   * @returns the updated due form
   */
  patchDueFormInstanceNeededByDate = async (
    dueFormId: string,
    newInstanceNeededByDate: Date,
  ): Promise<Compliance.DueForm> => {
    const res = await this.patch<Compliance.DueForm>(`/api/compliance/due-forms/${dueFormId}`, {
      newInstanceNeededByDate,
    });
    return parseISODateStrings(res.data);
  };

  listDueForms = async ({
    isNotPastDue,
    daysUntilDue,
    patientIds,
    formDefinitionIds,
    programIds,
    levelsOfCare,
    primaryClinicianIds,
    careTeamMemberIds,
    sortBy,
    limit,
    offset,
  }: {
    isNotPastDue?: boolean;
    daysUntilDue?: number;
    patientIds?: string[];
    formDefinitionIds?: string[];
    programIds?: string[];
    levelsOfCare?: string[];
    primaryClinicianIds?: string[];
    careTeamMemberIds?: string[];
    sortBy?: Compliance.DueFormsSortType;
    limit: number;
    offset: number;
  }): Promise<Compliance.ListDueForm[]> => {
    const res = await this.get<Compliance.ListDueForm[]>('/api/compliance/due-forms', {
      params: {
        isNotPastDue,
        daysUntilDue,
        patientIds,
        formDefinitionIds,
        programIds,
        levelsOfCare,
        primaryClinicianIds,
        careTeamMemberIds,
        sortBy,
        limit,
        offset,
      },
    });
    return parseISODateStrings(res.data);
  };

  getMyComplianceTasks = async (): Promise<Compliance.MyTasksCount> => {
    const res = await this.get<Compliance.MyTasksCount>(`/api/compliance/todo/me`);
    return res.data;
  };

  postPatientFormDefinitionComplete = async (
    patientId: string,
    formDefinitionId: string,
  ): Promise<API.IDResponse> => {
    const res = await this.post<API.IDResponse>(
      `/api/compliance/v2/required-forms/${patientId}/${formDefinitionId}/complete`,
    );
    return res.data;
  };

  deletePatientFormDefinitionComplete = async (
    patientId: string,
    formDefinitionId: string,
  ): Promise<API.IDResponse> => {
    const res = await this.delete<API.IDResponse>(
      `/api/compliance/v2/required-forms/${patientId}/${formDefinitionId}/incomplete`,
    );
    return res.data;
  };

  getTodoConfig = async (userId: string): Promise<Compliance.TodoConfig> => {
    const res = await this.get<Compliance.TodoConfig>(`/api/compliance/todo/config/${userId}`);
    return res.data;
  };

  postTodoDisplayConfig = async (
    userId: string,
    config: Compliance.PostTodoDisplayConfig,
  ): Promise<API.IDResponse> => {
    const res = await this.post<API.IDResponse>(
      `/api/compliance/todo/config/display/${userId}`,
      config,
    );
    return res.data;
  };

  postTodoColleaguesConfig = async (
    userId: string,
    colleagueIds: string[],
  ): Promise<API.IDResponse> => {
    const res = await this.post<API.IDResponse>(`/api/compliance/todo/config/users/${userId}`, {
      colleagueIds,
    });
    return res.data;
  };

  postTodoFormsConfig = async (
    userId: string,
    formDefinitionIds: string[],
  ): Promise<API.IDResponse> => {
    const res = await this.post<API.IDResponse>(`/api/compliance/todo/config/forms/${userId}`, {
      formDefinitionIds,
    });
    return res.data;
  };

  listFormDefinitionsWithDueDates = async (): Promise<Forms.FormDefinitionBase[]> => {
    const res = await this.get<Forms.FormDefinitionBase[]>('/api/compliance/todo/form-definitions');
    return res.data;
  };
}
