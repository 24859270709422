import { z } from 'zod';

import { claimsClaimTypeSchema } from './claims.zod';
import {
  patientAddressSchema,
  patientPatientInsuranceSchema,
  patientPatientNameSchema,
} from './patient.zod';

export const remittancesServiceStatusSchema = z.union([
  z.literal('Rejected at Clearinghouse'),
  z.literal('Rejected at Payer'),
  z.literal('Denied at Payer'),
  z.literal('Voided'),
  z.literal('Deleted'),
  z.literal('Payer Balance Due'),
  z.literal('Client Balance Due'),
  z.literal('Appealed'),
  z.literal('On Hold'),
  z.literal('At Collections'),
  z.literal('Paid'),
  z.literal('Reversal'),
  z.literal('Draft'),
]);

export const remittancesQueryParamsSchema = z.object({
  remittanceStartDate: z.string().optional(),
  remittanceEndDate: z.string().optional(),
  payerIds: z.array(z.string()),
  searchValue: z.string(),
  sortBy: z.string(),
  offset: z.number(),
  limit: z.number(),
});

export const remittancesPayerSchema = z.object({
  payerId: z.string(),
  payerIdentifier: z.string(),
  payerName: z.string(),
  phone: z.string(),
  address: patientAddressSchema,
});

export const remittancesRemittancesResponseMeta = z.object({
  totalRemittanceCount: z.number(),
});

export const remittancesHeaderSchema = z.object({
  id: z.string(),
  isManual: z.boolean(),
  transactionControlNumber: z.string(),
  totalProviderPaidCents: z.number(),
  totalServices: z.number(),
  appliedServices: z.number(),
  remittanceDate: z.string(),
  checkDate: z.string(),
  payer: remittancesPayerSchema,
  //   payee: remittancesPayeeSchema, // TODO: add payee when we know what it looks like
});

export const remittancesGetRemittanceResponse = z.object({
  meta: remittancesRemittancesResponseMeta,
  remittances: z.array(remittancesHeaderSchema),
});

export const remittancesPaymentSchema = z.object({
  id: z.string(),
  transactionNumber: z.string(),
  paymentDate: z.string(),
  paymentMethod: z.string(),
  amountCents: z.number(),
  isReconciled: z.boolean(),
  createdAt: z.string(),
});

export const remittancesPaymentPostBodySchema = remittancesPaymentSchema.pick({
  transactionNumber: true,
  paymentDate: true,
  paymentMethod: true,
  amountCents: true,
  id: true,
});

export const remittancesManualRemittancePostBodySchema = z.object({
  eraNumber: z.string(),
  date: z.string(),
  payerId: z.string(),
  payments: z.array(remittancesPaymentPostBodySchema),
});

export const remittancesAdjustmentSchema = z.object({
  id: z.string(),
  groupCode: z.string(),
  groupCodeValue: z.string(),
  reasonCode: z.string(),
  amountCents: z.number().nullable(),
});

export const remittancesServiceSchema = z.object({
  id: z.string(),
  isLocked: z.boolean(),
  status: remittancesServiceStatusSchema,
  serviceDate: z.string(),
  procedureCode: z.string(),
  procedureCodeModifiers: z.array(z.string()),
  remarks: z.string(),
  billedAmountCents: z.number(),
  allowedAmountCents: z.number(),
  providerPaidAmountCents: z.number(),
  adjustments: z.array(remittancesAdjustmentSchema),
});

export const remittancesClaimSchema = z.lazy(() =>
  z.object({
    id: z.string(),
    acceptAssignment: z.boolean(),
    accountNumber: z.string(),
    allowedCents: z.number(),
    appliedAdjustmentsCents: z.number(),
    appliedPaymentsCents: z.number(),
    billedCents: z.number(),
    clearinghouseControlNumber: z.string(),
    coinsuranceCents: z.number(),
    deductibleCents: z.number(),
    instanceID: z.string(),
    insuranceBalanceDueCents: z.number(),
    moas: z.array(z.string()),
    otherClientInsurance: patientPatientInsuranceSchema,
    outstandingBalanceCents: z.number(),
    patient: patientPatientNameSchema,
    patientBalanceDueCents: z.number(),
    payerControlNumber: z.string(),
    payment: remittancesPaymentSchema,
    paymentAmountCents: z.number(),
    policyNumber: z.string(),
    previousPaymentsCents: z.number(),
    providerPaidCents: z.number(),
    rcCents: z.number(),
    services: z.array(remittancesServiceSchema),
    type: claimsClaimTypeSchema,
  }),
);

export const remittancesPostAdjustmentBodySchema = remittancesAdjustmentSchema.pick({
  groupCode: true,
  reasonCode: true,
  amountCents: true,
});

export const remittancesPutServiceBodySchema = z.object({
  id: z.string(),
  status: remittancesServiceStatusSchema,
  providerPaidAmountCents: z.number().optional(),
  adjustments: z.array(remittancesPostAdjustmentBodySchema),
});

export const remittancesRemittanceSchema = z.object({
  id: z.string(),
  transactionControlNumber: z.string(),
  remittanceDate: z.string(),
  checkDate: z.string(),
  status: z.string(),
  isManual: z.boolean(),
  unappliedPaymentCents: z.number(),
  unappliedAdjustmentCents: z.number(),
  billedTotalCents: z.number(),
  allowedTotalCents: z.number(),
  deductibleTotalCents: z.number(),
  coinsuranceTotalCents: z.number(),
  rcTotalCents: z.number(),
  providerPaidTotalCents: z.number(),
  payments: z.array(remittancesPaymentSchema),
  payer: remittancesPayerSchema,
  //   payee: remittancesPayeeSchema, // TODO: add payee when we know what it looks like
  claims: z.array(remittancesClaimSchema),
  totalServices: z.number(),
  appliedServices: z.number(),
});

export const remittancesAdjustmentFormSchema = remittancesAdjustmentSchema
  .omit({
    amountCents: true,
  })
  .extend({
    amountDollars: z.number().nullable(),
  });

export const remittancesServiceFormSchema = remittancesServiceSchema
  .omit({
    adjustments: true,
    providerPaidAmountCents: true,
  })
  .extend({
    adjustments: z.array(remittancesAdjustmentFormSchema),
    providerPaidAmountDollars: z.number().nullable(),
  });

export const remittancesClaimFormSchema = z.lazy(() =>
  remittancesClaimSchema.schema
    .omit({ services: true })
    .extend({ services: z.array(remittancesServiceFormSchema) }),
);

export const remittancesRemittanceFormSchema = remittancesRemittanceSchema
  .omit({ claims: true })
  .extend({ claims: z.array(remittancesClaimFormSchema) });
