import { AxiosRequestConfig } from 'axios';
import { sortBy } from 'lodash';
import { from } from 'rxjs';

import { parseArrayDates, parseISODateStrings, StringifyKeys } from 'utils';
import { Prop } from '../utils/typesUtils';
import RittenClient from './RittenClient';

class ClinicClient extends RittenClient {
  getClinicPrograms = async (query: Clinic.GetClinicProgramsQuery) => {
    const res = await this.get<Catalog.ClinicProgram[]>(`api/clinic/programs`, {
      params: query,
    });

    return parseISODateStrings(res.data);
  };

  getClinicPrograms$ = (query: Clinic.GetClinicProgramsQuery) =>
    from(this.getClinicPrograms(query));

  upsertClinicProgram = async (program: Clinic.ClinicProgramPostBody) => {
    const res = await this.post<Catalog.ClinicProgram>(`api/clinic/programs`, program);
    return res.data;
  };

  archiveClinicProgram = async (programId: string) => {
    await this.delete(`api/clinic/programs/${programId}`);
  };

  archiveClinicProgram$ = (programId: string) => from(this.archiveClinicProgram(programId));

  getClinicDischargeTypes = async (showArchived: boolean) => {
    const params: AxiosRequestConfig = {
      params: {
        showArchived,
      },
    };
    const res = await this.get<StringifyKeys<Clinic.DischargeType, 'createdAt'>[]>(
      `api/clinic/discharge-types`,
      params,
    );
    return parseArrayDates(res.data, ['createdAt']);
  };

  getClinicDischargeTypes$ = (showArchived: boolean) =>
    from(this.getClinicDischargeTypes(showArchived));

  upsertClinicDischargeType = async (dischargeType: Clinic.DischargeTypePostBody) => {
    const res = await this.post<Clinic.DischargeType>(`api/clinic/discharge-types`, dischargeType);
    return res.data;
  };

  upsertClinicDischargeType$ = (dischargeType: Clinic.DischargeTypePostBody) =>
    from(this.upsertClinicDischargeType(dischargeType));

  archiveClinicDischargeType = async (dischargeTypeId: string) => {
    await this.delete(`api/clinic/discharge-types/${dischargeTypeId}`);
  };

  archiveClinicDischargeType$ = (dischargeTypeId: string) =>
    from(this.archiveClinicDischargeType(dischargeTypeId));

  getReferralTypes = async () => {
    const res = await this.get<StringifyKeys<Clinic.ReferralType, 'createdAt' | 'deletedAt'>[]>(
      `api/clinic/referral-types`,
    );
    const convertedRes = parseArrayDates(res.data, ['createdAt', 'deletedAt']);
    return sortBy(convertedRes, [Prop<Clinic.ReferralType>('type')]);
  };

  upsertReferralTypes = async (referralType: Clinic.ReferralTypePostBody) => {
    const res = await this.post<Clinic.ReferralType[]>(`api/clinic/referral-types`, [referralType]);
    return sortBy(res.data, [Prop<Clinic.ReferralType>('type')]);
  };

  /// //////////////////////////////////
  // Clinic Payers
  /// //////////////////////////////////

  getPayers = async (): Promise<Patient.InsurancePayer[]> => {
    const res = await this.get<Patient.InsurancePayer[]>(`/api/insurance/payers`);
    return res.data;
  };

  upsertPayers = async (
    newPayersArray: Patient.InsurancePayer[],
  ): Promise<Patient.InsurancePayer[]> => {
    const res = await this.post<Patient.InsurancePayer[]>(`/api/insurance/payers`, newPayersArray);
    return res.data;
  };

  archivePayer = async (payerId: string): Promise<void> => {
    await this.delete<void>(`/api/insurance/payers/${payerId}`);
  };

  /// //////////////////////////////////
  // Clinic Payer Group APIs
  /// //////////////////////////////////

  listPayerGroups = async (): Promise<Clinic.PayerGroup[]> => {
    const res = await this.get<Clinic.PayerGroup[]>(`/api/clinic/payer-groups`);
    return res.data;
  };

  getPayerGroup = async (id: string): Promise<Clinic.PayerGroup> => {
    const res = await this.get<Clinic.PayerGroup>(`/api/clinic/payer-groups/${id}`);
    return res.data;
  };

  postPayerGroup = async (body: Clinic.PayerGroupPostBody): Promise<API.IDResponse> => {
    const res = await this.post<API.IDResponse>(`/api/clinic/payer-groups`, body);
    return res.data;
  };

  patchPayerGroup = async (
    id: string,
    patchBody: Clinic.PayerGroupPatchBody,
  ): Promise<API.IDResponse> => {
    const res = await this.patch<API.IDResponse>(`api/clinic/payer-groups/${id}`, { ...patchBody });
    return res.data;
  };

  archivePayerGroup = async (id: string) => {
    const res = await this.delete(`api/clinic/payer-groups/${id}`);
    return res.data;
  };

  /// ///////////////////////////////////
  // Clinic Authorizations Template APIs
  /// ///////////////////////////////////

  listAuthorizationTemplates = async (
    patientId?: string,
  ): Promise<Clinic.AuthorizationTemplate[]> => {
    const res = await this.get<Clinic.AuthorizationTemplate[]>(
      `/api/clinic/templates/authorizations`,
      { params: { patientId } },
    );
    return res.data;
  };

  getAuthorizationTemplate = async (id: string): Promise<Clinic.AuthorizationTemplate> => {
    const res = await this.get<Clinic.AuthorizationTemplate>(
      `/api/clinic/templates/authorizations/${id}`,
    );
    return res.data;
  };

  postAuthorizationTemplate = async (
    body: Clinic.AuthorizationTemplatePostBody,
  ): Promise<API.IDResponse> => {
    const res = await this.post<API.IDResponse>(`/api/clinic/templates/authorizations`, body);
    return res.data;
  };

  archiveAuthorizationTemplate = async (id: string) => {
    const res = await this.delete(`api/clinic/templates/authorizations/${id}`);
    return res.data;
  };
  /// //////////////////////////////////
  // Clinic Case Statuses APIs
  /// //////////////////////////////////

  listCaseStatuses = async (): Promise<Clinic.CaseStatus[]> => {
    const res = await this.get<Clinic.CaseStatus[]>(`/api/clinic/crm/deal-statuses`);
    return res.data;
  };

  postCaseStatus = async (body: Clinic.CaseStatusPostBody): Promise<Clinic.CaseStatus> => {
    const res = await this.post<Clinic.CaseStatus>(`/api/clinic/crm/deal-statuses`, body);
    return res.data;
  };

  patchCaseStatus = async (id: string, position: number): Promise<Clinic.CaseStatus> => {
    const res = await this.patch<Clinic.CaseStatus>(`api/clinic/crm/deal-statuses/${id}/position`, {
      position,
    });
    return res.data;
  };

  archiveCaseStatus = async (id: string) => {
    const res = await this.delete(`api/clinic/crm/deal-statuses/${id}`);
    return res.data;
  };

  /// //////////////////////////////////
  // Clinic Disqulification Reason APIs
  /// //////////////////////////////////

  listDisqualificationReasons = async (): Promise<Clinic.DisqualificationReason[]> => {
    const res = await this.get<Clinic.DisqualificationReason[]>(
      `/api/clinic/crm/disqualification-reasons`,
    );
    return res.data;
  };

  postDisqualificationReason = async (
    body: Clinic.DisqualificationReasonPostBody,
  ): Promise<Clinic.DisqualificationReason> => {
    const res = await this.post<Clinic.DisqualificationReason>(
      `/api/clinic/crm/disqualification-reasons`,
      body,
    );
    return res.data;
  };

  archiveDisqualificationReason = async (id: string) => {
    const res = await this.delete(`api/clinic/crm/disqualification-reasons/${id}`);
    return res.data;
  };

  getProvider = async (id: string) => {
    const res = await this.get<Clinic.Provider>(`/api/clinic/providers/${id}`);
    return res.data;
  };

  getAllProviders = async () => {
    const res = await this.get<Clinic.Provider[]>(`/api/clinic/providers`);
    return parseISODateStrings(res.data);
  };

  postProvider = async (clinicProvider: Clinic.ProviderPostBody) => {
    const res = await this.post<Clinic.Provider>(`/api/clinic/providers`, clinicProvider);
    return res.data;
  };

  deleteProvider = async (id: string) => {
    await this.delete(`/api/clinic/providers/${id}`);
  };

  /// //////////////////////////////////
  // Clinic Templated Text APIs
  /// //////////////////////////////////

  queryTemplatedTexts = async (
    q?: Clinic.TemplatedTextQuery,
  ): Promise<Clinic.TemplatedTextItem[]> => {
    const res = await this.get<Clinic.TemplatedTextItem[]>(`/api/clinic/templated-text`, {
      params: q,
    });
    return res.data;
  };

  postTemplatedText = async (t: Clinic.TemplatedTextItem) => {
    await this.post<void>(`/api/clinic/templated-text`, t);
  };

  deleteTemplatedText = async (id: string) => {
    await this.delete<void>(`/api/clinic/templated-text/${id}`);
  };

  postGenerateTemplatedTextAnswer = async (
    id: string,
    data: { patientId: string },
  ): Promise<{ result: string }> => {
    const res = await this.post<{ result: string }>(
      `/api/clinic/templated-text/${id}/generate`,
      data,
    );
    return res.data;
  };

  /// //////////////////////////////////
  // Chart Intelligence APIs
  /// //////////////////////////////////

  getChartIntelFormFieldConfigs = async (params: {
    formDefinitionId?: string;
    fieldDefinitionId?: string;
  }): Promise<Clinic.ChartIntelligenceFormFieldConfigWithField[]> => {
    const res = await this.get<Clinic.ChartIntelligenceFormFieldConfigWithField[]>(
      '/api/chart-intelligence/form-field-configs',
      {
        params,
      },
    );
    return res.data;
  };

  putChartIntelFormFieldConfigs = async (
    fieldDefinitionId: string,
    data: Clinic.ChartIntelligenceFormFieldConfig[],
  ): Promise<void> => {
    await this.put<void>(`/api/chart-intelligence/form-field-configs/${fieldDefinitionId}`, data);
  };

  postChartIntelligenceGenerate = async (body: {
    type: Clinic.ChartIntelligenceType;
    formId?: string;
    inputText?: string;
  }): Promise<ReadableStreamDefaultReader<Uint8Array>> => {
    return this.fetchStream('POST', `/api/chart-intelligence/generate`, body);
  };

  postScribeJob = async (body: {
    filename: string;
    encounterId: string;
  }): Promise<{ signedUrl: string }> => {
    const res = await this.post<{ signedUrl: string }>('/api/chart-intelligence/scribe/jobs', body);
    return res.data;
  };

  postScribeJobRuns = async (
    jobId: string,
    body: {
      formInstanceIds: string[];
    },
  ): Promise<void> => {
    await this.post<void>(`/api/chart-intelligence/scribe/jobs/${jobId}/runs`, body);
  };

  getAIForm = async (formInstanceId: string): Promise<{ aiScribeForm: Forms.FormInstance }> => {
    const res = await this.get<{ aiScribeForm: Forms.FormInstance }>(
      `/api/chart-intelligence/scribe/forms/${formInstanceId}`,
    );
    return res.data;
  };

  /// //////////////////////////////////
  // Fee Schedules
  /// //////////////////////////////////

  listFeeSchedules = async () => {
    const res = await this.get<Clinic.FeeSchedule[]>(`/api/clinic/bill/fee-schedules`);
    return res.data;
  };

  postFeeSchedule = async (fs: Clinic.FeeSchedulePostBody) => {
    const res = await this.post<Clinic.FeeSchedule>(`/api/clinic/bill/fee-schedules`, fs);
    return res.data;
  };

  putFeeSchedule = async (fs: Clinic.FeeSchedulePutBody) => {
    const res = await this.put<Clinic.FeeSchedule>(`/api/clinic/bill/fee-schedules/${fs.id}`, fs);
    return res.data;
  };

  archiveFeeSchedule = async (id: string) => {
    const res = await this.delete<API.IDResponse>(`/api/clinic/bill/fee-schedules/${id}`);
    return res.data;
  };

  putFeeScheduleOrder = async (scheduleIds: string[]) => {
    await this.put<void>(`/api/clinic/bill/fee-schedules/order`, { scheduleIds });
  };

  /// //////////////////////////////////
  // Billing Global Variables
  /// //////////////////////////////////

  getGlobalVariables = async (): Promise<Clinic.BillingGlobalVariables> => {
    const res = await this.get<Clinic.BillingGlobalVariables>('/api/clinic/bill/global-vars');
    return res.data;
  };

  putGlobalVariables = async (body: Clinic.BillingGlobalVariablesPutBody): Promise<void> => {
    await this.put<void>('/api/clinic/bill/global-vars', body);
  };

  /// //////////////////////////////////
  // Facilities
  /// /////////////////////////////////

  listFacilities = async () => {
    const res = await this.get<Clinic.Facility[]>(`/api/clinic/facilities`);
    return res.data;
  };

  postFacility = async (f: Clinic.FacilityPostBody) => {
    const res = await this.post<Clinic.Facility>(`/api/clinic/facilities`, f);
    return res.data;
  };

  archiveFacility = async (id: string) => {
    const res = await this.delete<API.IDResponse>(`/api/clinic/facilities/${id}`);
    return res.data;
  };

  putFacilitiesOrder = async (facilityIds: string[]) => {
    await this.put<void>(`/api/clinic/facilities/order`, { facilityIds });
  };

  getSupervisingProviderConfig = async () => {
    const { data } = await this.get<{ supervisingProvidersEnabled: boolean }>(
      'api/billing/clinic/config',
    );
    return data;
  };

  putClinicConfig = async (supervisingProvidersEnabled: boolean) => {
    await this.put('api/billing/clinic/config', { supervisingProvidersEnabled });
  };
}

export default ClinicClient;
