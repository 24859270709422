// Generated by ts-to-zod
import { z } from 'zod';

import { usersUserSchema } from './users.zod';

export const securityPolicySchema = z.object({
  id: z.string(),
  name: z.string(),
  description: z.string(),
  type: z.union([z.literal('Ritten'), z.literal('Custom')]),
  resource: z.string(),
  operation: z.string(),
  filter: z.string(),
  filterGlob: z.string(),
  filterRegex: z.string(),
  createdAt: z.date(),
  isDeny: z.boolean(),
});

export const securitySecurityGroupUsersPostBodySchema = z.object({
  userIds: z.array(z.string()),
});

export const securityPolicyPostBodySchema = securityPolicySchema.pick({
  name: true,
  description: true,
  type: true,
  resource: true,
  operation: true,
  filter: true,
  filterGlob: true,
  filterRegex: true,
});

export const securitySecurityGroupSchema = z.lazy(() =>
  z.object({
    id: z.string(),
    name: z.string(),
    users: z.array(usersUserSchema),
    policies: z.array(securityPolicySchema),
    createdAt: z.date(),
  }),
);
