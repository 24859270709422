import { COLORS } from '../../styles/colors';

interface LineBreakProps {
  color?: string;
  verticalMargin?: string;
  height?: string;
  width?: string;
}

const LineBreak = (props: LineBreakProps) => {
  const {
    color = COLORS.darkGray300,
    verticalMargin = '2px',
    height = '2px',
    width = '100%',
  } = props;

  return (
    <div
      style={{
        height,
        width,
        background: color,
        marginTop: verticalMargin,
        marginBottom: verticalMargin,
      }}
    />
  );
};

export default LineBreak;
