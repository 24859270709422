export function base64DataURLToBlob(base64DataURL: string): Blob {
  const parts = base64DataURL.split(';base64,');

  // Hold the content type
  const imageType = parts[0].split(':')[1];

  // Decode Base64 string
  const decodedData = window.atob(parts[1]);

  // Create UNIT8ARRAY of size same as row data length
  const uInt8Array = new Uint8Array(decodedData.length);

  // Insert all character code into uInt8Array
  for (let i = 0; i < decodedData.length; ++i) {
    uInt8Array[i] = decodedData.charCodeAt(i);
  }

  // Return BLOB image after conversion
  return new Blob([uInt8Array], { type: imageType });
}

export async function blobToBase64DataURL(b: Blob): Promise<string> {
  const resultP = new Promise<string>((resolve) => {
    const reader = new FileReader();
    reader.readAsDataURL(b);
    reader.onloadend = () => {
      const base64DataURL = reader.result as string;
      resolve(base64DataURL);
    };
  });
  return resultP;
}

export async function downloadBlobAsFile(blob: Blob, fileName: string) {
  const base64URL = await blobToBase64DataURL(blob);
  const a = document.createElement('a');
  a.href = base64URL;
  a.download = fileName;
  a.click();
}

export async function downloadObjectAsJSONFile(obj: any, filename: string) {
  const blob = new Blob([JSON.stringify(obj, null, 2)], {
    type: 'application/json',
  });
  const url = URL.createObjectURL(blob);
  const a = document.createElement('a');
  a.href = url;
  a.download = filename;
  a.click();
  URL.revokeObjectURL(url);
}

export function getFileNameFromHeaders(contentDispositionValue?: string) {
  const contentDisposition = contentDispositionValue?.replace(/"/g, '');
  // I think this should find a match and exclude the 'filename=' part.
  // Right now the actual match includes it - but the capture group
  // does not contain it.  Which is accessed at match[1]
  const match = contentDisposition?.match(/filename\s*=\s*((?:(?!;).)*)/i);

  if (!match || match?.length === 0) {
    throw new Error('File name was not found.');
  }

  return match[1];
}
