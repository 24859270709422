import { isEmpty } from 'lodash';

import { COLORS } from 'styles/colors';
import { pluralizeText } from 'utils';
import { isValidURL } from 'utils/urlUtils';
import { TASK_SORT_OPTIONS, taskCategories, taskPriorities, taskStatuses } from './constants';

export const validateFilters = (
  initialFilters: Tasks.TasksQueryParams,
  staffUsers: Users.User[],
  selectedCategory: Tasks.TaskCategory,
) => {
  const staffIds = staffUsers.map((user) => user.id);
  const areAssigneesValid = initialFilters.assigneeIds.reduce((isValid, id) => {
    return staffIds.includes(id) && isValid;
  }, true);

  const areCreatorsValid = initialFilters.creatorIds.reduce((isValid, id) => {
    return staffIds.includes(id) && isValid;
  }, true);

  const areWatchersValid = initialFilters.watcherIds.reduce((isValid, id) => {
    return staffIds.includes(id) && isValid;
  }, true);

  const areStatusesValid = initialFilters.statuses.reduce((isValid, status) => {
    return taskStatuses.includes(status) && isValid;
  }, true);

  const arePrioritiesValid = initialFilters.priorities.reduce((isValid, priority) => {
    return taskPriorities.includes(priority) && isValid;
  }, true);

  const areCategoriesValid: boolean = taskCategories.includes(selectedCategory);

  const sortIsValid = !!TASK_SORT_OPTIONS.find((option) => initialFilters.sort === option.value);

  return (
    sortIsValid &&
    arePrioritiesValid &&
    areStatusesValid &&
    areWatchersValid &&
    areCreatorsValid &&
    areAssigneesValid &&
    areCategoriesValid
  );
};

export const maybeGetTaskStatusColor = (status: Tasks.TaskStatus) => {
  switch (status) {
    case 'Complete':
      return COLORS.green400;
    case 'Working':
      return COLORS.yellow400;
    case "Won't Do":
      return COLORS.red400;
    case 'New':
    default:
      return COLORS.rittenBlue400;
  }
};

export const taskObjectToPostTask = (task: Tasks.Task | undefined): Tasks.PostTask | undefined => {
  if (!task) {
    return undefined;
  }
  const postTask: Tasks.PostTask = {
    id: task.id,
    title: task.title,
    description: task.description,
    assigneeId: task.assignee.userId,
    priority: task.priority,
    dueDate: task.dueDate,
    status: task.status,
    weblink: task.weblink ?? '',
    weblinkAlias: task.weblinkAlias ?? '',
    objectType: task.objectType,
    objectId: task.objectType === 'Form Field' && task.formField ? task.formField.formFieldId : '',
    watcherIds: task.watchers.map((w) => w.id),
  };
  return postTask;
};

export const validatePostTask = (postTaskObj: Tasks.PostTask): string[] => {
  const errs: string[] = [];
  if (isEmpty(postTaskObj.title)) {
    errs.push('Title is required');
  }
  if (isEmpty(postTaskObj.assigneeId ?? '')) {
    errs.push('Task must have an assignee');
  }
  if (!postTaskObj.dueDate) {
    errs.push('Task must have a due date');
  }
  if (postTaskObj.objectType === 'Weblink' && !isValidURL(postTaskObj.weblink)) {
    errs.push('Invalid Web Link');
  }
  return errs;
};

export const getCommentTimeAgo = (date: Date): string => {
  const currentDate = new Date();
  const timeDifference = currentDate.getTime() - date.getTime();
  const seconds = Math.ceil(timeDifference / 1000);
  const minutes = Math.floor(seconds / 60);
  const hours = Math.floor(minutes / 60);
  const days = Math.floor(hours / 24);
  const weeks = Math.floor(days / 7);

  if (weeks >= 1) {
    return `${weeks} ${pluralizeText('week', weeks)} ago`;
  }
  if (days >= 1) {
    return `${days} ${pluralizeText('day', days)} ago`;
  }
  if (hours >= 1) {
    return `${hours} ${pluralizeText('hour', hours)} ago`;
  }
  return `${minutes} ${pluralizeText('minute', minutes)} ago`;
};

export const getNavigateToFormParams = (
  task: Tasks.Task,
): { pathname: string; state: unknown } | undefined => {
  if (task.formField) {
    if (task.formField.objectSubtype && task.formField.objectSubtype.includes('Treatment Plan')) {
      return {
        pathname: `/records/clients/${task.formField.patientId}/treatment-plan/form/${task.formField.formInstanceId}`,
        state: {
          initialSubtypeId:
            task.formField.objectSubtype === 'Treatment Plan Non-Plan Field'
              ? task.formField.formFieldId
              : task.formField.objectSubtypeId,
        },
      };
    }
    return {
      pathname: `/records/clients/${task.formField.patientId}/chart/encounters/${task.formField.encounterId}/forms/${task.formField.formInstanceId}`,
      state: { initialFieldInstanceId: task.formField.formFieldId },
    };
  }
  return undefined;
};

export const TASKS_QUERY_LIMIT: number = 20;

export const getEmptyFiltersObject = (): Tasks.TasksQueryParams => ({
  statuses: ['New', 'Working'],
  priorities: [],
  dueStartTime: undefined,
  dueEndTime: undefined,
  createdEndTime: '',
  createdStartTime: '',
  assigneeIds: [],
  watcherIds: [],
  creatorIds: [],
  sort: '',
  searchText: '',
  offset: 0,
  limit: TASKS_QUERY_LIMIT,
});
