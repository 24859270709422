import { useContext } from 'react';
import { ResponsiveContext } from 'grommet';

import useMobileDevice from 'context/mobileDevice/useMobileDevice';
import {
  responsiveDeviceValueSet,
  ResponsiveSetInput,
  ResponsiveType,
} from '../utils/responsiveDeviceSizeUtils';

/**
 * Wraps grommets ResponsiveContext with Type support
 * and provides extra support for identifying mobile devices
 * when the app dynamically loads the viewport meta tag.
 * @returns {ResponsiveType} 'xsmall' | 'small' | 'medium' | 'large'
 */
export const useResponsiveDeviceSize = () => {
  // we can remove useMobileDevice once the entire app is mobile-friendly (i.e. the meta viewport tag is no longer added in via react-helmet)
  const { isMobile } = useMobileDevice();
  const grommetSize = useContext(ResponsiveContext) as ResponsiveType;
  const size = grommetSize;
  const isLarge = size === 'large';
  const isMedium = size === 'medium';
  const isSmall = size === 'small';
  const isXSmall = size === 'xsmall' || isMobile;

  /**
   * Returns corresponding value for device size.  Returns nearest value to screen size, and will return larger
   * screen size value if the exact screen size is not specified.
   *
   * - If screen size isSmall but only large specified then large value will be returned.
   * - If screen size isSmall but only large and medium specified then medium value will be returned.
   * - If screen size isMedium but only large and small are specified then large value will be returned.
   *
   * @example
   * // Screensize isSmall -> will return '3px'
   * deviceSize.set({L: '1px', M: '2px', S: '3px', XS: '4px'})
   *
   * // Screensize isMedium -> will return '1px'
   * deviceSize.set({L: '1px', S: '3px'})
   *
   * @param {ResponsiveSetInput<T>} responsiveValues
   * @returns {any}
   */
  const set = <T>(values: ResponsiveSetInput<T>) =>
    responsiveDeviceValueSet(values, isXSmall, isSmall, isMedium);

  return {
    deviceSize: {
      size,
      isLarge,
      isMedium,
      isSmall,
      isXSmall,
      isSmallish: isSmall || isXSmall,
      set,
    },
  };
};
