import { Box, Text, TextInput } from 'grommet';

import { LinkButton } from '@ritten/ui-library/buttons';

import { COLORS } from '../../../styles/colors';
import UpDownButtons from '../../../ui-library/buttons/UpDownButtons';

interface PromptTextBuilderProps {
  type: string;
  promptText: string;
  onChange?: (newValue: string) => void;
  removeFieldFromFieldsList?: (idx: number) => void;
  moveField?: (idx: number, direction: 'up' | 'down') => void;
  fieldIdx?: number;
  sectionFieldsList?: Forms.FieldDefinitionBase[];
  duplicateField?: (fieldIdx: number) => void;
}

export const promptTextBuilderInputStyles = {
  color: COLORS.darkGray600,
  fontWeight: 400,
  backgroundColor: COLORS.darkGray200,
  borderBottom: `1px solid ${COLORS.rittenBlue400}`,
  borderLeft: 'none',
  borderRight: 'none',
  borderTop: 'none',
  borderRadius: '0px',
  fontSize: '14px',
};

const PromptTextBuilder = (props: PromptTextBuilderProps) => {
  // if the optional props are undefined return null
  if (
    props.removeFieldFromFieldsList &&
    props.moveField &&
    props.fieldIdx !== undefined &&
    props.sectionFieldsList &&
    props.duplicateField
  ) {
    return (
      <Box direction="column" gap="small">
        <Box direction="row" align="center" justify="between">
          <Box direction="row" align="center" gap="small">
            <Text size="12px" weight="bold">
              {props.type}
            </Text>
            <LinkButton
              label="Duplicate Field"
              textSize="12px"
              padding="none"
              onClick={() => props.duplicateField?.(props.fieldIdx!)}
            />
          </Box>

          <Box direction="row" align="center" gap="18px">
            <Box onClick={() => props.removeFieldFromFieldsList?.(props.fieldIdx!)}>
              <Text size="12px" color={COLORS.red400}>
                Remove Field
              </Text>
            </Box>
            <UpDownButtons
              moveField={props.moveField}
              fieldIdx={props.fieldIdx}
              numFields={props.sectionFieldsList.length}
            />
          </Box>
        </Box>
        <TextInput
          value={props.promptText}
          onChange={(e) => props.onChange?.(e.target.value)}
          placeholder="Enter prompt"
          focusIndicator={false}
          style={promptTextBuilderInputStyles}
        />
      </Box>
    );
  }

  // needed for LeadApplicationCustomFieldConfiguration
  return null;
};

export default PromptTextBuilder;
