import FileDownloadsClient from './FileDownloadsClient';
import LeadApplicationClient from './LeadApplicationClient';
import UserTicketsClient from './UserTicketsClient';

const usePublicAPI = () => {
  return {
    userTicketsAPI: new UserTicketsClient(null, () => ''),
    leadApplicationAPI: new LeadApplicationClient(null, () => ''),
    fileDownloadsAPI: new FileDownloadsClient(null, () => ''),
  };
};

export default usePublicAPI;
