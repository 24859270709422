// @ts-strict-ignore
import { Route, RouteProps } from 'react-router-dom';
import { useAuth0, withAuthenticationRequired } from '@auth0/auth0-react';
import { Box } from 'grommet';

import useUser from '../../context/user/useUser';
import ErrorText from '../../ui-library/errors/ErrorText';
import { canUserAccessPath } from './util';

type ProtectedRouteProps = React.PropsWithChildren<RouteProps> & {
  skipPolicyCheck?: boolean;
  errorText?: string;
};

const ProtectedRoute: React.FC<ProtectedRouteProps> = (props: ProtectedRouteProps) => {
  const {
    component,
    skipPolicyCheck,
    errorText = 'Not authorized. Contact your administrator for access to this page.',
    ...args
  } = props;
  const { isAuthenticated } = useAuth0();
  const { user } = useUser();
  if (!isAuthenticated) {
    return (
      <Route
        render={(props) => {
          const Component = withAuthenticationRequired(component, {
            // If using a Hash Router, you need to pass the hash fragment as `returnTo`
            returnTo: () => window.location.hash.substr(1),
          });
          return <Component {...props} />;
        }}
        {...args}
      />
    );
  }
  const pagePath = Array.isArray(args.path) ? '' : (args.path as string) || '';
  if (skipPolicyCheck || canUserAccessPath(user, pagePath)) {
    return <Route component={component} {...args} />;
  }
  return (
    <Box pad="small" fill>
      <ErrorText show>{errorText}</ErrorText>
    </Box>
  );
};

export default ProtectedRoute;
