import { useContext } from 'react';

import TenantContext from './TenantContext';

const useTenant = (): TenantContext => {
  const context = useContext(TenantContext);
  if (context === null) {
    throw new Error('useTenantConfig must be used inside a TenantContext.Provider');
  }
  return context;
};

export default useTenant;
