// @ts-strict-ignore
import { useCallback, useEffect, useRef, useState } from 'react';
import { Box, Drop } from 'grommet';
import { DirectionType, PadType } from 'grommet/utils';
import { isEqual } from 'lodash';

import InputLabel from '@ritten/ui-library/form-inputs/InputLabel';

import { useLoadingState } from 'hooks';
import { COLORS } from 'styles/colors';
import { asyncDebounce, parseISODateStrings } from 'utils';
import ErrorLabel from '../labels/ErrorLabel';
import DropContent from './base-components/DropContent';
import MultiChoiceAnchor from './base-components/MultiChoiceAnchor';
import MultiChoiceExternalTags from './base-components/MultiChoiceExternalTags';
import SingleChoiceAnchor from './base-components/SingleChoiceAnchor';

export const SELECT_ALL_CHOICE = 'SELECT_ALL';
const selectAllChoice: DropdownChoice = { value: SELECT_ALL_CHOICE, label: 'Select All' };

export const getChoiceLabel = (
  choice: DropdownChoice,
  choiceLabelKey: string | undefined,
): string => {
  if (!choice) {
    return '';
  }
  if (typeof choice === 'string') {
    return choice;
  }
  if (typeof choice === 'object' && choiceLabelKey && choice[choiceLabelKey]) {
    return choice[choiceLabelKey];
  }
  if (typeof choice === 'object' && choice.label) {
    return choice.label;
  }
  return '';
};

export const getChoiceValue = (choice: DropdownChoice, choiceValueKey: string | undefined): any => {
  if (!choice) {
    return null;
  }
  if (typeof choice === 'string') {
    return choice;
  }
  if (typeof choice === 'object' && choiceValueKey && choice[choiceValueKey]) {
    return parseISODateStrings(choice[choiceValueKey]);
  }
  if (typeof choice === 'object' && choice.value) {
    return parseISODateStrings(choice.value);
  }
  return null;
};

const getDefaultChoices = (choices: DropdownChoice[], variant: Variant, hideSelectAll: boolean) => {
  if (variant !== 'multiple-choice') {
    return choices;
  }

  return hideSelectAll || choices.length === 0 ? choices : [selectAllChoice, ...choices];
};

export type LabelValueDropdownChoice<T = any> = { label: string; value: T };
export type DropdownChoice = string | LabelValueDropdownChoice | Record<string, any>;
export type Variant = 'single-choice' | 'multiple-choice';

export interface DropdownProps {
  variant: Variant;
  choices: DropdownChoice[];
  onSelect: (choice: DropdownChoice | DropdownChoice[]) => void;
  singleChoiceSelection?: DropdownChoice;
  multipleChoiceSelections?: DropdownChoice[];
  dropdownLabel?: string;
  placeholderText?: string;
  disabled?: boolean;
  onNoOptionsClick?: (searchQuery: string) => void;
  noOptionsMessage?: string;
  preserveStateOnNoOptionsClick?: boolean;
  customizationProps?: {
    renderChoice?: (choice: DropdownChoice) => JSX.Element;
    renderSelection?: (choice: DropdownChoice) => JSX.Element;
    anchorWidth?: string | { min?: string; max?: string };
    anchorGap?: string;
    anchorPad?: PadType;
    anchorBorderColor?: string;
    dropWidth?: string | { min?: string; max?: string };
    requirementMarker?: string;
    tooltipText?: string;
    inlineChipColors?: { borderColor: string; backgroundColor: string; iconColor: string };
    iconSize?: string;
    iconColor?: string;
    staticDropContent?: JSX.Element; // static content to show at the bottom of the drop (ie, a button that should be present regardless of scrolling/search results)
  };
  searchProps?: {
    onSyncSearch?: (searchQuery: string) => void; // used if this component's query state is needed in parent component
    onAsyncSearch?: (searchQuery: string) => Promise<void>;
    searchPlaceholder?: string;
    onClearSearchQuery?: (searchQuery?: string) => void; // called when the x in the search input is clicked
    hideSearch?: boolean;
    addError?: (err: string | Error) => void;
    forceNoOptionsMessageOnSearch?: boolean;
    hideNoOptionsMessageUntilSearch?: boolean;
    focusOnOpen?: boolean; // autofocus the search input on drop open
    minimumSearchCharactersForNoOptionsMessage?: number;
  };
  singleChoiceProps?: {
    showClearSelection?: boolean;
    forceDropClosedOnSelectionUpdate?: boolean;
    truncateSelectionText?: boolean;
  };
  multipleChoiceProps?: {
    hideSelectAll?: boolean;
    hideSelectionTags?: boolean;
    showSelectionsInline?: boolean;
    showSelectionTagsBelow?: boolean;
  };
  'data-testid'?: string;
  choiceDataTextCy?: string;
  choiceLabelKey?: string;
  choiceValueKey?: string;
  errorMessage?: string;
  clearInput?: boolean;
  onClear?: () => void;
  boxWidth?: string | { max?: string; min?: string };
  hideErrorMessage?: boolean;
  infiniteScrollProps?: {
    onMore: () => void;
    step: number;
    loadingMore?: boolean;
  };
}

const Dropdown = (props: DropdownProps) => {
  const {
    variant,
    dropdownLabel,
    choices,
    onSelect,
    singleChoiceSelection,
    multipleChoiceSelections,
    disabled = false,
    onNoOptionsClick,
    preserveStateOnNoOptionsClick = false,
    customizationProps = {},
    searchProps = {},
    singleChoiceProps = {},
    multipleChoiceProps = {},
    choiceLabelKey,
    choiceValueKey,
    errorMessage,
    placeholderText,
    boxWidth,
    hideErrorMessage = false,
  } = props;
  const {
    anchorWidth,
    anchorGap,
    requirementMarker,
    tooltipText,
    renderSelection,
    inlineChipColors,
    anchorPad,
    iconSize,
    iconColor,
    anchorBorderColor,
    staticDropContent,
  } = customizationProps;
  const { onClearSearchQuery, onAsyncSearch, onSyncSearch, addError } = searchProps;
  const { showSelectionsInline, showSelectionTagsBelow, hideSelectionTags, hideSelectAll } =
    multipleChoiceProps;
  const { showClearSelection, forceDropClosedOnSelectionUpdate, truncateSelectionText } =
    singleChoiceProps;

  const { loading, needsLoadingState } = useLoadingState(false);
  const [filterQuery, setFilterQuery] = useState<string>('');
  const [isDropOpen, setIsDropOpen] = useState<boolean>(false);
  const [forceDropClosed, setForceDropClosed] = useState<boolean>(false);
  const dropAnchorRef = useRef();
  const containerRef = useRef();

  const defaultChoices = getDefaultChoices(choices, variant, hideSelectAll);
  const filteredChoices = onAsyncSearch
    ? defaultChoices
    : defaultChoices.filter((choice) => {
        const choiceText = getChoiceLabel(choice, choiceLabelKey);
        return choiceText.toLowerCase().includes(filterQuery.toLowerCase());
      });

  useEffect(() => {
    // If the singleChoiceSelection is updated by the parent component
    // (typically after creating a new choice when no options were found)
    // and forceDropClosedOnSelectionUpdate is true, close the drop
    if (forceDropClosedOnSelectionUpdate) {
      setIsDropOpen(false);
    }
  }, [singleChoiceSelection]);

  const debouncedSearch = useCallback(asyncDebounce(needsLoadingState(onAsyncSearch), 500), [
    onAsyncSearch,
  ]);

  const onSelectChoice = (choice: DropdownChoice) => {
    switch (variant) {
      case 'single-choice': {
        onSelect(choice);
        setIsDropOpen(false);
        setFilterQuery('');
        break;
      }
      case 'multiple-choice': {
        const choiceValue = getChoiceValue(choice, choiceValueKey);
        let updatedSelections = [];

        if (choiceValue === SELECT_ALL_CHOICE) {
          const allAreSelected = multipleChoiceSelections.length === choices.length;
          if (!allAreSelected) {
            updatedSelections = choices;
          }
        } else {
          const isSelected = multipleChoiceSelections.find((selection) => {
            return isEqual(getChoiceValue(selection, choiceValueKey), choiceValue);
          });

          if (isSelected) {
            updatedSelections = multipleChoiceSelections.filter((selection) => {
              return !isEqual(getChoiceValue(selection, choiceValueKey), choiceValue);
            });
          } else {
            updatedSelections = [...multipleChoiceSelections, choice];
          }
        }
        onSelect(updatedSelections);
        break;
      }
      default: {
        break;
      }
    }
  };

  const onRemoveChoice = onSelectChoice;

  const onDropClick = () => {
    setIsDropOpen(!isDropOpen);
    setForceDropClosed(false);
  };

  const onNoOptionsFoundClick = onNoOptionsClick
    ? needsLoadingState(async () => {
        try {
          await onNoOptionsClick(filterQuery);
        } catch (e) {
          addError(e);
        }
        if (!preserveStateOnNoOptionsClick) {
          setFilterQuery('');
          setIsDropOpen(false);
        }
      })
    : null;

  const onClearSearchQueryClick = () => {
    onClearSearchQuery?.(filterQuery);
    setFilterQuery('');
  };

  const onChangeSearchQuery = (query: string) => {
    setFilterQuery(query);

    if (onAsyncSearch) {
      try {
        debouncedSearch(query);
      } catch (e) {
        addError(e);
      }
    } else {
      onSyncSearch?.(query);
    }
  };

  const anchorProps = {
    disabled,
    onDropClick,
    anchorRef: dropAnchorRef,
    width: anchorWidth,
    gap: anchorGap,
    'data-testid': props['data-testid'],
    variant,
    placeholderText,
    renderSelection,
    hasError: errorMessage && errorMessage.length > 0,
    pad: anchorPad,
    iconSize,
    iconColor,
    borderColor: anchorBorderColor,
  };
  const boxContainerProps =
    variant === 'multiple-choice'
      ? {
          pad: {
            bottom:
              showSelectionTagsBelow && !hideSelectionTags && multipleChoiceSelections?.length > 0
                ? '16px'
                : '0px',
          },
          direction: showSelectionTagsBelow
            ? ('column' as DirectionType)
            : ('row' as DirectionType),
          width: boxWidth,
        }
      : {};

  return (
    <Box {...boxContainerProps}>
      <Box width={anchorWidth} flex="grow">
        {dropdownLabel && (
          <InputLabel
            label={dropdownLabel}
            requirementMarker={requirementMarker}
            tooltipText={tooltipText}
          />
        )}
        {variant === 'single-choice' && (
          <SingleChoiceAnchor
            {...anchorProps}
            showClearSelection={showClearSelection}
            selection={singleChoiceSelection}
            choiceLabelKey={choiceLabelKey}
            onSelectChoice={onSelectChoice}
            truncateSelectionText={truncateSelectionText}
          />
        )}
        {variant === 'multiple-choice' && (
          <MultiChoiceAnchor
            {...anchorProps}
            selections={multipleChoiceSelections}
            onRemoveSelection={onRemoveChoice}
            showSelectionsInline={showSelectionsInline}
            choiceLabelKey={choiceLabelKey}
            inlineChipColors={inlineChipColors}
          />
        )}
        {isDropOpen && !forceDropClosed && (
          <Drop
            ref={containerRef}
            target={dropAnchorRef.current}
            align={{ top: 'bottom', left: 'left' }}
            onClickOutside={() => setForceDropClosed(true)}
            // Force the background color to white to avoid Grommet dark/light color detection changing the color when dropdown parent has dark bg
            background={COLORS.white}
          >
            <DropContent
              {...props}
              filterQuery={filterQuery}
              onChangeSearchQuery={onChangeSearchQuery}
              onClearSearchQueryClick={onClearSearchQueryClick}
              loading={loading}
              filteredChoices={filteredChoices}
              onSelectChoice={onSelectChoice}
              onNoOptionsFoundClick={onNoOptionsFoundClick}
              choiceLabelKey={choiceLabelKey}
              choiceValueKey={choiceValueKey}
              staticDropContent={staticDropContent}
            />
          </Drop>
        )}
        {errorMessage && !hideErrorMessage && (
          <Box pad={{ top: 'xxsmall' }}>
            <ErrorLabel>{errorMessage}</ErrorLabel>
          </Box>
        )}
      </Box>
      {variant === 'multiple-choice' && !hideSelectionTags && !showSelectionsInline && (
        <MultiChoiceExternalTags
          selections={multipleChoiceSelections}
          disabled={disabled}
          onRemoveSelection={onRemoveChoice}
          choiceTextDataCy={props.choiceDataTextCy}
          showTagsBelow={showSelectionTagsBelow}
          choiceLabelKey={choiceLabelKey}
          choiceValueKey={choiceValueKey}
        />
      )}
    </Box>
  );
};

export default Dropdown;
