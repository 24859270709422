import { Box, Button, grommet, ThemeContext } from 'grommet';
import { deepMerge } from 'grommet/utils';

import { COLORS } from '../../styles/colors';
import { ButtonColor } from './constants';
import { useButtonColor } from './useButtonColor';

interface IconButtonProps {
  icon: JSX.Element;
  color: ButtonColor;
  onClick: (e?: any) => void;
  className?: string;
  'data-testid'?: string;
  disabled?: boolean;
}

const IconButton = (props: IconButtonProps) => {
  const { getColor } = useButtonColor();

  const theme = deepMerge(grommet, {
    button: {
      extend: `
            line-height: 22px;
            font-family: Circular;
            font-style: normal;
            font-weight: normal;
            height: 100%;
            color: ${getColor(props.color)};
          `,
      default: {
        background: {
          color: COLORS.white,
        },
        padding: {
          vertical: '4px',
          horizontal: '4px',
        },
        border: {
          radius: '0',
          width: '0',
        },
      },
    },
    icon: {
      size: {
        small: '12px',
        medium: '15px',
        large: '18px',
        xlarge: '20px',
      },
      extend: undefined,
    },
  });

  return (
    <ThemeContext.Extend value={theme}>
      <Button
        disabled={props.disabled}
        onClick={props.onClick}
        className={props.className}
        data-testid={props['data-testid']}
      >
        <Box direction="row" align="center">
          {props.icon}
        </Box>
      </Button>
    </ThemeContext.Extend>
  );
};

export default IconButton;
