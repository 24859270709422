import RittenClient from './RittenClient';

export default class PortalClient extends RittenClient {
  /**
   * Grants a client contact access to a patient. Ensure they have a portal account before hitting this endpoint.
   * @param patientId The patient ID to whom we grant access.
   * @param personId The contact we are granting access to.
   */
  grantPortalAccess = async (patientId: string, contactPersonId: string) => {
    await this.post<void>(`/api/portal/patients/${patientId}/grant`, {
      personId: contactPersonId,
    });
  };

  revokePortalAccess = async (patientId: string, userId: string) => {
    await this.post<void>(`/api/portal/patients/${patientId}/revoke`, { userId });
  };

  patchDisabled = async (patientId: string, disabled: boolean) => {
    await this.patch<void>(`/api/portal/patients/${patientId}/disabled`, { disabled });
  };

  patchPatientEmail = async (patientId: string, email: string) => {
    await this.patch<void>(`/api/portal/patients/${patientId}/email`, { email });
  };

  patchContactEmail = async (personId: string, email: string) => {
    await this.patch<void>(`/api/portal/persons/${personId}/email`, { email });
  };

  /**
   * Creates a person's portal account with the desired email.
   */
  createPortalUser = async (personId: string, email: string) => {
    await this.post<void>(`/api/portal/persons/${personId}/user`, { email });
  };

  /**
   * Deletes a person's portal account.
   */
  deletePortalUser = async (personId: string) => {
    await this.delete<void>(`/api/portal/persons/${personId}/user`);
  };
}
