import { useEffect, useRef, useState } from 'react';
import { BsFillPersonFill } from 'react-icons/bs';
import { Box, InfiniteScroll, Text } from 'grommet';
import { Alert, Calendar, Clock, Refresh, Trash } from 'grommet-icons';
import { union, uniqBy } from 'lodash';

import { LinkButton } from '@ritten/ui-library/buttons';
import { ErrorPanel } from '@ritten/ui-library/errors';
import { BaseModal } from '@ritten/ui-library/modals';
import LoadingPanel from '@ritten/ui-library/panels/LoadingPanel';

import { usePatientEventContext } from 'context/patients/PatientEventContext';
import useUser from 'context/user/useUser';
import useAPI from 'external/useAPI';
import { useErrorState, useLoadingState, useResponsiveDeviceSize } from 'hooks';
import { COLORS } from 'styles/colors';
import {
  formatDateMMDDYYYYSlashes,
  getFormattedTimeFromDate,
  getFullName,
  getTimeZoneAbbreviation,
} from 'utils';

const DOCUMENTS_LIMIT = 10;
export const DOCUMENT_EXPORT_TYPES: Documents.DocumentType[] = [
  'Encounter Export',
  'Full Patient Export',
  'Patient Weekly Agenda Export',
  'Insights Chart Export',
  'Patient Active MAR Export',
  'Brief Patient Export',
  'Group Encounter Export',
  'Patients Forms Export',
  'Patient Documents Export',
  'Patient Biometrics Export',
];

const getTypeLabel = (document: Documents.Document): string => {
  const { type, documentExport } = document;
  switch (type) {
    case 'Encounter Export': {
      return documentExport?.encounter?.type || 'Encounter';
    }
    case 'Full Patient Export': {
      return 'Full Patient Record';
    }
    case 'Insights Chart Export': {
      return 'Insights Report';
    }
    case 'Patient Weekly Agenda Export': {
      return 'Weekly Agenda Export';
    }
    case 'Patient Active MAR Export': {
      return 'Active Schedules';
    }
    case 'Brief Patient Export': {
      return 'Brief Patient Record';
    }
    case 'Group Encounter Export': {
      return 'Group Encounter Export';
    }
    case 'Patients Forms Export': {
      return document.documentExport?.title ? document.documentExport?.title : 'Bulk Forms Export';
    }
    case 'Patient Documents Export': {
      return 'Attachments';
    }
    case 'Patient Biometrics Export': {
      return 'Patient Biometrics';
    }
    default: {
      return '';
    }
  }
};

interface DownloadsModalProps {
  onClose: () => void;
}

const DownloadsModal = (props: DownloadsModalProps) => {
  const { onClose } = props;

  const fetchRef = useRef<number>(0);
  const { documentAPI } = useAPI();
  const { user } = useUser();
  const { deviceSize } = useResponsiveDeviceSize();
  const { triggerDownloadEvent } = usePatientEventContext();
  const { loading, needsLoadingState } = useLoadingState(true);
  const { loading: isLoadingMore, setLoading: setIsLoadingMore } = useLoadingState(false);
  const { loading: isLoadingAction, needsLoadingState: needsActionLoadingState } =
    useLoadingState(false);
  const { errors, addError, clearErrors } = useErrorState();

  const [documents, setDocuments] = useState<Documents.Document[]>([]);

  useEffect(() => {
    fetchInitialData();
  }, []);

  const fetchInitialData = needsLoadingState(async () => {
    await fetchDocuments({});
    triggerDownloadEvent();
  });

  const fetchDocuments = async ({ offset }: { offset?: number }) => {
    const currentRef = ++fetchRef.current;

    try {
      let documentsRes = await documentAPI.queryDocuments({
        types: DOCUMENT_EXPORT_TYPES,
        limit: DOCUMENTS_LIMIT,
        offset: offset || 0,
        requesterId: user.id,
        filterByRequester: true,
      });

      if (offset && offset > 0) {
        documentsRes = uniqBy(union(documents, documentsRes), 'id');
      }

      if (fetchRef.current === currentRef) {
        setDocuments(documentsRes ?? []);
      }
    } catch (err) {
      addError(err);
    }
  };

  const downloadDocument = needsActionLoadingState(async (document: Documents.Document) => {
    clearErrors();
    try {
      await documentAPI.downloadDocument(document.id);
    } catch (e) {
      addError(e);
    }
  });

  const deleteDocument = needsActionLoadingState(async (documentId: string) => {
    clearErrors();
    try {
      await documentAPI.deleteDocument(documentId);
      triggerDownloadEvent();
      await fetchDocuments({ offset: 0 });
    } catch (e) {
      addError(e);
    }
  });

  const onInfiniteScroll = async () => {
    try {
      const shouldTryToLoadMore = documents?.length >= DOCUMENTS_LIMIT;
      if (shouldTryToLoadMore) {
        setIsLoadingMore(true);
        const newOffset = documents.length;
        await fetchDocuments({ offset: newOffset });
      }
    } catch (err) {
      addError(err);
    } finally {
      setIsLoadingMore(false);
    }
  };

  const renderDocument = (d: Documents.Document) => {
    const { documentExport } = d;
    const backgroundColor =
      d.file.fileStatus === 'export completed successfully' ? COLORS.darkGray200 : COLORS.white;
    let border;
    let statusDependentSection;
    switch (d.file.fileStatus) {
      case 'export completed successfully': {
        statusDependentSection = (
          <LinkButton
            disabled={isLoadingAction}
            textSize="14px"
            padding="0px"
            label="Download"
            onClick={() => downloadDocument(d)}
          />
        );
        break;
      }
      case 'export could not be completed': {
        border = { color: COLORS.red400, style: 'dashed', size: '1px' };
        statusDependentSection = (
          <Box direction="row" align="center" gap="6px">
            <Alert size="14px" color={COLORS.red400} />
            <Text size="14px">Failed</Text>
          </Box>
        );
        break;
      }
      case 'export not started': {
        border = { color: COLORS.darkGray600, style: 'dashed', size: '1px' };
        statusDependentSection = <Text size="14px">Queued</Text>;
        break;
      }
      case 'export in progress': {
        border = { color: COLORS.darkGray600, style: 'dashed', size: '1px' };
        statusDependentSection = <Text size="14px">Requesting...</Text>;
        break;
      }
      default: {
        break;
      }
    }
    return (
      <Box
        key={d.id}
        direction="row"
        align="center"
        justify="between"
        pad="10px"
        margin={{ bottom: '10px' }}
        border={border}
        background={backgroundColor}
      >
        <Box>
          <Text size="14px" weight="bold">
            {getTypeLabel(d)}
          </Text>
          <Box direction="row" align="center" gap="8px">
            {documentExport?.patient?.id !== '' && (
              <Box direction="row" align="center" gap="4px">
                <BsFillPersonFill size="12px" style={{ marginTop: '2px' }} />
                <Text size="12px">{getFullName(documentExport?.patient?.name)}</Text>
              </Box>
            )}
            <Box direction="row" align="center" gap="4px">
              <Calendar size="12px" color={COLORS.darkGray600} />
              <Text size="12px">{formatDateMMDDYYYYSlashes(documentExport?.createdAt)}</Text>
            </Box>
            <Box direction="row" align="center" gap="4px">
              <Clock size="12px" color={COLORS.darkGray600} />
              <Text size="12px">
                {getFormattedTimeFromDate(documentExport?.createdAt)} {getTimeZoneAbbreviation()}
              </Text>
            </Box>
          </Box>
        </Box>
        <Box direction="row" align="center" gap="12px">
          {statusDependentSection}
          {!['export not started', 'export in progress'].includes(d.file.fileStatus) && (
            <Trash
              size="12px"
              color={isLoadingAction ? COLORS.darkGray200 : COLORS.darkGray600}
              {...(isLoadingAction ? {} : { onClick: () => deleteDocument(d.id) })}
              style={isLoadingAction ? {} : { cursor: 'pointer' }}
            />
          )}
        </Box>
      </Box>
    );
  };

  const loadedDocuments: boolean = !loading && documents.length > 0;

  return (
    <BaseModal
      onClose={onClose}
      isOpen
      header={
        <Box width="100%" direction="row" align="center" justify="between">
          <Text weight="bold" color={COLORS.darkGray600}>
            Downloads
          </Text>
          {!loading && (
            <div style={{ marginTop: '-1px' }}>
              <Refresh
                size="16px"
                color={COLORS.darkGray600}
                cursor="pointer"
                onClick={fetchInitialData}
              />
            </div>
          )}
        </Box>
      }
      height={{ min: '250px', max: '450px' }}
      width={deviceSize.set({ L: '700px', S: 'auto' })}
    >
      <ErrorPanel errors={errors} />
      <Box>
        <Box pad="18px 6px" fill overflow="auto">
          {loading && <LoadingPanel showLoader={loading} />}
          {loadedDocuments && (
            <InfiniteScroll items={documents} step={DOCUMENTS_LIMIT} onMore={onInfiniteScroll}>
              {renderDocument}
            </InfiniteScroll>
          )}
        </Box>
        {isLoadingMore && (
          <Box flex={false} direction="row" alignSelf="center">
            <LoadingPanel showLoader={isLoadingMore} />
          </Box>
        )}
        {documents.length === 0 && !loading && (
          <Box align="center">
            <Text size="14px" color={COLORS.darkGray400}>
              No downloads available
            </Text>
          </Box>
        )}
      </Box>
    </BaseModal>
  );
};

export default DownloadsModal;
