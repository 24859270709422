// @ts-strict-ignore
import { FieldMetaGridMatrix } from './custom-fields/table/constants';
import { ROOBI_SECTION_ALLOW_LIST } from './roobis/constants';

export const shouldShowSignatureCallToAction = (
  formInstance: Forms.FormInstance,
  user: Users.User,
) => {
  if (formInstance.signatureStatus === 'Complete') {
    return false;
  }
  const clientSigReqs = formInstance.signatureRequiremements.filter((r) => r.type !== 'staff');
  const clientsHaveSigned = clientSigReqs.every((sigReq) =>
    formInstance.signatures.some((s) => s.patient.patientId === sigReq.patient.patientId),
  );
  const userHasSigned = formInstance.signatures.find(
    // The user object gets attached to client signatures and client contact signatures
    // so here we check if isPatientSignature and if the patient object is populated (which means a contact - client or not - signed)
    (s) => s.user.id === user.id && !s.isPatientSignature && !s.patient.patientId,
  );
  if (userHasSigned && clientSigReqs.length > 0 && clientsHaveSigned) {
    return false;
  }
  if (userHasSigned && clientSigReqs.length === 0) {
    return false;
  }
  return true;
};

export const isFieldValueEmpty = (value: any) => {
  const isFieldValueAnArray = Array.isArray(value);
  const isEmptyValue =
    (isFieldValueAnArray && value.length === 0) ||
    (value !== 0 && !value) ||
    (typeof value === 'string' && !value.trim());
  return isEmptyValue;
};

export const getMissingRequiredFields = (
  formInstance: Forms.FormInstance,
): Forms.FieldInstance[] => {
  const missingFields = [];
  formInstance?.sections.forEach((section: Forms.SectionInstance) => {
    // Only custom sections matter
    if (!ROOBI_SECTION_ALLOW_LIST.includes(section.definition.label)) {
      section.fields.forEach((field: Forms.FieldInstance) => {
        if (!field.isRequired) {
          return;
        }
        if (field.definition.type.includes('grid')) {
          if (!field.value) {
            missingFields.push(field);
          } else {
            // Even if field.value exists, the grid may still be "empty"
            switch (field.definition.type) {
              case 'grid_matrix_text': {
                // Make sure no rows are missing
                const fieldDef = field.definition.meta as FieldMetaGridMatrix;
                if (Object.keys(field.value).length !== fieldDef.rows.length) {
                  missingFields.push(field);
                } else {
                  // All rows "exist" on field.value but we need to make sure the columns that exist on the row are not all empty strings
                  // aka at least one column needs to have a real value
                  Object.values(field.value).forEach((row: any) => {
                    let isEmptyRow = true;
                    Object.values(row).forEach((colCell: any) => {
                      if (colCell.trim()) {
                        isEmptyRow = false;
                      }
                    });
                    if (isEmptyRow) {
                      missingFields.push(field);
                    }
                  });
                }
                break;
              }
              case 'grid_matrix_choice': {
                const fieldDef = field.definition.meta as FieldMetaGridMatrix;
                // Make sure no rows are missing. If a row exists in this type, it *has* to have a value (unlike other grid types)
                // because that's how radio works (you can't deselect a radio choice)
                if (Object.keys(field.value).length !== fieldDef.rows.length) {
                  missingFields.push(field);
                }
                break;
              }
              case 'grid_matrix_multi_choice': {
                const fieldDef = field.definition.meta as FieldMetaGridMatrix;
                // Make sure no rows are missing
                if (Object.keys(field.value).length !== fieldDef.rows.length) {
                  missingFields.push(field);
                } else {
                  // All the rows "exist" but we need to make sure each row isn't an empty array (aka it had options "checked" before but then unchecked)
                  Object.values(field.value).forEach((row: any) => {
                    if (row.length === 0) {
                      missingFields.push(field);
                    }
                  });
                }
                break;
              }
              default: {
                break;
              }
            }
          }
        } else {
          const isEmptyValue = isFieldValueEmpty(field.value);
          if (isEmptyValue && field.definition.type !== 'text_display_only') {
            missingFields.push(field);
          }
        }
      });
    }
  });

  return missingFields;
};

export const hasAtleastOneSignature = (formInstance: Forms.FormInstance) => {
  return formInstance.signatures?.length > 0;
};

export const isViewOnly = (formInstance: Forms.FormInstance | null): boolean => {
  return (
    formInstance?.isLocked ||
    (formInstance?.isPortalForm && formInstance?.signatureStatus !== 'Complete')
  );
};

export const isViewOnlyInPortal = (
  formInstance: Forms.FormInstance,
  accessRole?: Forms.AccessRole,
): boolean => {
  return formInstance?.isLocked || accessRole === 'view_only';
};

export const getUserSubmissionDate = (
  submissions: Forms.FormSubmission[],
  userPatientId: string,
) => {
  if (submissions.length > 0) {
    const userSubmission = submissions.find(
      (submission) => submission.userPatientId === userPatientId,
    );
    return userSubmission?.createdAt ?? null;
  }
  return null;
};

export const formHasRequiredFields = (form: Forms.FormInstance) => {
  return form.sections.some((s) => s.fields.some((f) => f.isRequired));
};
