export const APP_ROUTE_URLS = [
  '/set-password',
  '/crm/cases/:caseId',
  '/crm/cases/:caseId/overview',
  '/crm/cases/:caseId/lead-application',
  '/crm/cases',
  '/crm/people/:peopleId/notes',
  '/crm/people/:peopleId/attachments',
  '/crm/people/:peopleId',
  '/crm/people',
  '/crm/organizations/:organizationId/notes',
  '/crm/organizations/:organizationId/attachments',
  '/crm/organizations/:organizationId/clients',
  '/crm/organizations/:organizationId/cases',
  '/crm/organizations/:organizationId',
  '/crm/organizations',
  '/crm',
  '/records/occupancy',
  '/records/rounds/:roundInstanceId',
  '/records/rounds',
  '/records/medications/med-pass',
  '/records/medications/all-schedules',
  '/records/medications',
  '/records/lab-results',
  '/records/group-encounters/:groupEncounterId',
  '/records/group-encounters',
  '/records/clients/:clientId/chart/encounters/:encounterId/forms/:formId',
  '/records/clients/:clientId/chart/encounters/:encounterId/orders/:orderId',
  '/records/clients/:clientId/chart/encounters/:encounterId/modifiers',
  '/records/clients/:clientId/chart/encounters/:encounterId',
  '/records/clients/:clientId/chart/encounters',
  '/records/clients/:clientId/chart/forms/:formId',
  '/records/clients/:clientId/chart/forms',
  '/records/clients/:clientId/chart/orders/:orderId',
  '/records/clients/:clientId/chart/orders',
  '/records/clients/:clientId/chart',
  '/records/clients/:clientId/client-information',
  '/records/clients/:clientId/treatment-plan/form/:formId',
  '/records/clients/:clientId/treatment-plan',
  '/records/clients/:clientId/trends',
  '/records/clients/:clientId/documents',
  '/records/clients/:clientId/medication-record/log',
  '/records/clients/:clientId/medication-record/medications',
  '/records/clients/:clientId/medication-record',
  '/records/clients/:clientId/agenda',
  '/records/clients/:clientId/biometrics',
  '/records/clients/:clientId/rounds',
  '/records/clients/:clientId/payments',
  '/records/clients/:clientId/lab-results',
  '/records/clients/:clientId',
  '/records/clients',
  '/records',
  '/compliance/actions',
  '/compliance/due-forms',
  '/compliance/forms/:clientId',
  '/compliance/required-forms',
  '/compliance/notifications',
  '/compliance',
  '/calendar/event/:eventId/recurrence/:recurrenceDate',
  '/calendar/event/:eventId',
  '/calendar/date/:date/event/:eventId/recurrence/:recurrenceDate',
  '/calendar/date/:date/event/:eventId',
  '/calendar/date/:date',
  '/calendar',
  '/insights/operations-dashboard',
  '/insights/census',
  '/insights/admissions-dashboard',
  '/insights/custom-reports',
  '/insights/reports',
  '/insights',
  '/admin/forms/form-builder/:existingFormId?',
  '/admin/forms/types',
  '/admin/forms/problems',
  '/admin/forms/program-requirements',
  '/admin/forms/answer-library',
  '/admin/forms',
  '/admin/encounters',
  '/admin/orders/types/:orderType?/edit',
  '/admin/orders/types',
  '/admin/orders/templates',
  '/admin/orders/config',
  '/admin/orders/sets',
  '/admin/orders',
  '/admin/billing/rules/service-rules/:ruleId',
  '/admin/billing/rules/service-rules',
  '/admin/billing/rules/fee-schedules',
  '/admin/billing/rules/global-variables',
  '/admin/billing/rules',
  '/admin/billing/providers-encounters/facilities',
  '/admin/billing/providers-encounters/encounter-attributes',
  '/admin/billing/providers-encounters/providers',
  '/admin/billing/providers-encounters/integrations',
  '/admin/billing/providers-encounters',
  '/admin/billing/payers/manage-payers',
  '/admin/billing/payers/payer-groups',
  '/admin/billing/payers',
  '/admin/billing/authorizations-templates',
  '/admin/billing',
  '/admin/crm/disqualified',
  '/admin/crm/case-statuses',
  '/admin/crm/case-source',
  '/admin/crm/lead-application',
  '/admin/crm',
  '/admin/clinic-setup/programs',
  '/admin/clinic-setup/discharge-types',
  '/admin/clinic-setup/referrals',
  '/admin/clinic-setup/calendar',
  '/admin/clinic-setup/bedboard',
  '/admin/clinic-setup/rounds/round-times',
  '/admin/clinic-setup/rounds/activities',
  '/admin/clinic-setup/rounds/locations',
  '/admin/clinic-setup/rounds/round-configurations',
  '/admin/clinic-setup/rounds',
  '/admin/clinic-setup/other',
  '/admin/clinic-setup',
  '/admin/user-settings/roles',
  '/admin/user-settings/permissions',
  '/admin/user-settings/users',
  '/admin/user-settings/teams',
  '/admin/user-settings/notifications',
  '/admin/user-settings',
  '/admin/config/feature-flags',
  '/admin/config',
  '/admin',
  '/billing/services/:serviceId',
  '/billing/services',
  '/billing/claims/:claimId/professional',
  '/billing/claims/:claimId/institutional',
  '/billing/claims',
  '/billing/remittances',
  '/billing/remittances/:remittanceId',
  '/billing/utilization-review/:entryId',
  '/billing/utilization-review',
  '/billing/census',
  '/billing',
  '/user/profile',
  '/user/todo',
  '/user/notifications',
  '/user',
  '/home/dashboard',
  '/home/tasks',
  '/home',
  '/navigation',
  '/callback/lab-order',
  '/callback',
  '/lead-application',
  '/lead-application/:leadAppInstanceId',
  '/r',
] as const;

export const CLINIC_APP_ROUTE_URL_MAPPING = new Map<typeof APP_ROUTE_URLS[number], string>([
  ['/set-password', 'Set Password'],
  ['/crm/cases/:caseId/overview', 'Case'],
  ['/crm/cases/:caseId/lead-application', 'Case'],
  ['/crm/cases/:caseId', 'Case'],
  ['/crm/cases', 'Case'],
  ['/crm/people/:peopleId/notes', 'Contact'],
  ['/crm/people/:peopleId/attachments', 'Contact'],
  ['/crm/people/:peopleId', 'Contact'],
  ['/crm/people', 'Contact'],
  ['/crm/organizations/:organizationId/notes', 'Organization'],
  ['/crm/organizations/:organizationId/attachments', 'Organization'],
  ['/crm/organizations/:organizationId/clients', 'Organization'],
  ['/crm/organizations/:organizationId/cases', 'Organization'],
  ['/crm/organizations/:organizationId', 'Organization'],
  ['/crm/organizations', 'Organization'],
  ['/crm', 'Admissions'],
  ['/records/occupancy', 'Bedboard'],
  ['/records/rounds/:roundInstanceId', 'Round'],
  ['/records/rounds', 'All Rounds'],
  ['/records/medications/med-pass', 'Med Pass'],
  ['/records/medications/all-schedules', 'All Active Schedules'],
  ['/records/medications', 'Medications'],
  ['/records/lab-results', 'All Lab Results'],
  ['/records/group-encounters/:groupEncounterId', 'Group Encounter'],
  ['/records/group-encounters', 'Group Encounters'],
  ['/records/clients/:clientId/chart/encounters/:encounterId/forms/:formId', 'Client Form'],
  ['/records/clients/:clientId/chart/encounters/:encounterId/orders/:orderId', 'Client Order'],
  [
    '/records/clients/:clientId/chart/encounters/:encounterId/modifiers',
    'Client Chart - Encounter',
  ],
  ['/records/clients/:clientId/chart/encounters/:encounterId', 'Client Encounter'],
  ['/records/clients/:clientId/chart/encounters', 'Client Chart - Encounters'],
  ['/records/clients/:clientId/chart/forms/:formId', 'Client Form'],
  ['/records/clients/:clientId/chart/forms', 'Client Chart - Forms'],
  ['/records/clients/:clientId/chart/orders/:orderId', 'Client Order'],
  ['/records/clients/:clientId/chart/orders', 'Client Chart - Orders'],
  ['/records/clients/:clientId/chart', 'Client Chart'],
  ['/records/clients/:clientId/client-information', 'Client Information'],
  ['/records/clients/:clientId/treatment-plan/form/:formId', 'Treatment Plan Form'],
  ['/records/clients/:clientId/treatment-plan', 'Treatment Plan'],
  ['/records/clients/:clientId/trends', 'Client Trends'],
  ['/records/clients/:clientId/documents', 'Client Attachments'],
  ['/records/clients/:clientId/medication-record/log', 'Client MAR'],
  ['/records/clients/:clientId/medication-record/medications', 'Client MAR Schedules'],
  ['/records/clients/:clientId/medication-record', 'Client MAR'],
  ['/records/clients/:clientId/agenda', 'Client Weekly Agenda'],
  ['/records/clients/:clientId/biometrics', 'Client Biometrics'],
  ['/records/clients/:clientId/rounds', 'Client Rounds'],
  ['/records/clients/:clientId/payments', 'Client Payments'],
  ['/records/clients/:clientId/lab-results', 'Client Lab Results'],
  ['/records/clients/:clientId', 'Client'],
  ['/records/clients', 'Client Roster'],
  ['/records', 'Records'],
  ['/compliance/actions', 'Compliance - Open Signatures'],
  ['/compliance/due-forms', 'Compliance - Due Forms'],
  ['/compliance/forms/:clientId', 'Client Required Forms'],
  ['/compliance/required-forms', 'Compliance - Required Forms'],
  ['/compliance/notifications', 'Compliance - Notifications'],
  ['/compliance', 'Compliance'],
  ['/calendar/event/:eventId/recurrence/:recurrenceDate', 'Clinic Calendar'],
  ['/calendar/event/:eventId', 'Clinic Calendar'],
  ['/calendar/date/:date/event/:eventId/recurrence/:recurrenceDate', 'Clinic Calendar'],
  ['/calendar/date/:date/event/:eventId', 'Clinic Calendar'],
  ['/calendar/date/:date', 'Clinic Calendar'],
  ['/calendar', 'Clinic Calendar'],
  ['/insights/operations-dashboard', 'Operations Dashboard'],
  ['/insights/census', 'Clinical Historical Census'],
  ['/insights/admissions-dashboard', 'Admissions Dashboard'],
  ['/insights/custom-reports', 'Custom Reports'],
  ['/insights/reports', 'All Reports'],
  ['/insights', 'Insights'],
  ['/admin/forms/form-builder/:existingFormId?', 'Configurations - Forms Form Builder'],
  ['/admin/forms/types', 'Configurations - Forms - Types'],
  ['/admin/forms/problems', 'Configurations - Forms - Problems'],
  ['/admin/forms/program-requirements', 'Configurations - Forms - Program Requirements'],
  ['/admin/forms/answer-library', 'Configurations - Forms - Answer Library'],
  ['/admin/forms', 'Configurations - Forms'],
  ['/admin/encounters', 'Configurations - Encounters'],
  ['/admin/orders/types/:orderType?/edit', 'Configurations - Orders - Types'],
  ['/admin/orders/types', 'Configurations - Orders - Types'],
  ['/admin/orders/templates', 'Configurations - Orders - Templates'],
  ['/admin/orders/config', 'Configurations - Orders - Config'],
  ['/admin/orders/sets', 'Configurations - Orders - Sets'],
  ['/admin/orders', 'Configurations - Orders'],
  ['/admin/billing/rules/service-rules/:ruleId', 'Configurations - Billing Rule'],
  ['/admin/billing/rules/service-rules', 'Configurations - Billing Rules'],
  ['/admin/billing/rules/fee-schedules', 'Configurations - Billing Rules - Fee Schedules'],
  ['/admin/billing/rules/global-variables', 'Configurations - Billing Rules - Global Variables'],
  ['/admin/billing/rules', 'Configurations - Billing Rules'],
  [
    '/admin/billing/providers-encounters/facilities',
    'Configurations - Billing - Providers and Encounters - Facilities',
  ],
  [
    '/admin/billing/providers-encounters/encounter-attributes',
    'Configurations - Billing Providers and Encounters Encounter Attributes',
  ],
  [
    '/admin/billing/providers-encounters/providers',
    'Configurations - Billing - Providers and Encounters - Providers',
  ],
  [
    '/admin/billing/providers-encounters/integrations',
    'Configurations - Billing - Providers and Encounters - Integrations',
  ],
  ['/admin/billing/providers-encounters', 'Configurations - Billing - Providers and Encounters'],
  ['/admin/billing/payers/manage-payers', 'Configurations - Billing - Payers - Manage Payers'],
  ['/admin/billing/payers/payer-groups', 'Configurations - Billing - Payers - Payer Groups'],
  ['/admin/billing/payers', 'Configurations - Billing Payers'],
  [
    '/admin/billing/authorizations-templates',
    'Configurations - Billing - Authorizations Templates',
  ],
  ['/admin/billing', 'Configurations - Billing'],
  ['/admin/crm/disqualified', 'Configurations - CRM - Disqualified Reasons'],
  ['/admin/crm/case-statuses', 'Configurations - CRM - Case Statuses'],
  ['/admin/crm/case-source', 'Configurations - CRM - Case Source'],
  ['/admin/crm/lead-application', 'Configurations - CRM - Lead Application'],
  ['/admin/crm', 'Configurations - CRM'],
  ['/admin/clinic-setup/programs', 'Configurations - Clinic Setup - Programs'],
  ['/admin/clinic-setup/discharge-types', 'Configurations - Clinic Setup - Discharge Types'],
  ['/admin/clinic-setup/referrals', 'Configurations - Clinic Setup - Referrals'],
  ['/admin/clinic-setup/calendar', 'Configurations - Clinic Setup - Calendar'],
  ['/admin/clinic-setup/bedboard', 'Configurations - Clinic Setup - Bedboard'],
  [
    '/admin/clinic-setup/rounds/round-times',
    'Configurations - Clinic Setup - Rounds - Round Times',
  ],
  ['/admin/clinic-setup/rounds/activities', 'Configurations - Clinic Setup - Rounds - Activities'],
  ['/admin/clinic-setup/rounds/locations', 'Configurations - Clinic Setup - Rounds - Locations'],
  [
    '/admin/clinic-setup/rounds/round-configurations',
    'Configurations - Clinic Setup - Rounds - Round Configurations',
  ],
  ['/admin/clinic-setup/rounds', 'Configurations - Clinic Setup - Rounds'],
  ['/admin/clinic-setup/other', 'Configurations - Clinic Logo'],
  ['/admin/clinic-setup', 'Configurations - Clinic Setup'],
  ['/admin/user-settings/roles', 'Configurations - User Settings - Roles'],
  ['/admin/user-settings/permissions', 'Configurations - User Settings - Permissions'],
  ['/admin/user-settings/users', 'Configurations - User Settings - Users'],
  ['/admin/user-settings/teams', 'Configurations - User Settings - Teams'],
  ['/admin/user-settings/notifications', 'Configurations - User Settings - Notifications'],
  ['/admin/user-settings', 'Configurations - User Settings'],
  ['/admin/config/feature-flags', 'Configurations - Feature Flags'],
  ['/admin/config', 'Configurations'],
  ['/admin', 'Configurations'],
  ['/billing/services/:serviceId', 'Billing Services'],
  ['/billing/services', 'Service'],
  ['/billing/claims/:claimId/professional', 'Claim'],
  ['/billing/claims/:claimId/institutional', 'Claim'],
  ['/billing/claims', 'Billing Claims'],
  ['/billing/remittances', 'Remittance Table'],
  ['/billing/remittances/:remittanceId', 'Remittance'],
  ['/billing/utilization-review/:entryId', 'Client Utilization Review'],
  ['/billing/utilization-review', 'Billing Utilization Review'],
  ['/billing/census', 'Billing Census'],
  ['/billing', 'Billing'],
  ['/user/profile', 'User Profile'],
  ['/user/todo', 'Customize Your To-Do List'],
  ['/user/notifications', 'Notifications'],
  ['/user', 'User'],
  ['/home/dashboard', 'Home'],
  ['/home/tasks', 'Tasks'],
  ['/home', 'Home'],
  ['/navigation', 'Switch Clinic'],
  ['/callback/lab-order', 'Callback Lab Order'],
  ['/callback', 'Callback'],
  ['/r', 'Redirect'],
]);
