import { createSlice } from '@reduxjs/toolkit';

import { RootState } from '../../../../../redux/store';

export interface DocumentState {
  documentUpdate: number;
}

const initialState: DocumentState = {
  documentUpdate: 0,
};

export const documentSlice = createSlice({
  name: 'document',
  initialState,
  reducers: {
    onDocumentUpdate: (state) => {
      state.documentUpdate += 1;
    },
  },
});

export const { onDocumentUpdate } = documentSlice.actions;

export const selectEncounterDocumentUpdate = (state: RootState) => state.document.documentUpdate;

export default documentSlice.reducer;
