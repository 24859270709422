import useAuth0User from 'context/auth0/useAuth0User';
import useLogRocket from 'context/logrocket/useLogRocket';
import BedBoardClient from './BedBoardClient';
import BillingClient from './BillingClient';
import CalendarClient from './CalendarClient';
import CatalogClient from './CatalogClient';
import ClaimsClient from './ClaimsClient';
import ClinicClient from './ClinicClient';
import ComplianceClient from './ComplianceClient';
import CRMClient from './CRMClient';
import DocumentClient from './DocumentClient';
import DosespotClient from './DosespotClient';
import EncounterClient from './EncounterClient';
import EntityClient from './EntityClient';
import FeatureFlagClient from './FeatureFlagClient';
import FilesClient from './FilesClient';
import FiltersClient from './FiltersClient';
import FormClient from './FormClient';
import InsightsClient from './InsightsClient';
import LabClient from './LabClient';
import MARClient from './MARClient';
import OrderClient from './OrderClient';
import PatientClient from './PatientClient';
import PaymentsClient from './PaymentsClient';
import PortalClient from './PortalClient';
import RemittanceClient from './RemittanceClient';
import ReportingClient from './ReportingClient';
import RolodexClient from './RolodexClient';
import RoundsClient from './RoundsClient';
import SecurityClient from './SecurityClient';
import SignatureClient from './SignatureClient';
import TasksClient from './TasksClient';
import UserClient from './UserClient';
import UserNotificationClient from './UserNotificationClient';

const useAPI = () => {
  const { getTokenAndSetExpirationDate } = useAuth0User();
  const { getSessionUrl } = useLogRocket();
  return {
    catalogAPI: new CatalogClient(getTokenAndSetExpirationDate, getSessionUrl),
    signatureAPI: new SignatureClient(getTokenAndSetExpirationDate, getSessionUrl),
    securityAPI: new SecurityClient(getTokenAndSetExpirationDate, getSessionUrl),
    userAPI: new UserClient(getTokenAndSetExpirationDate, getSessionUrl),
    orderAPI: new OrderClient(getTokenAndSetExpirationDate, getSessionUrl),
    patientAPI: new PatientClient(getTokenAndSetExpirationDate, getSessionUrl),
    clinicAPI: new ClinicClient(getTokenAndSetExpirationDate, getSessionUrl),
    dosespotAPI: new DosespotClient(getTokenAndSetExpirationDate, getSessionUrl),
    featureFlagsAPI: new FeatureFlagClient(getTokenAndSetExpirationDate, getSessionUrl),
    documentAPI: new DocumentClient(getTokenAndSetExpirationDate, getSessionUrl),
    encounterAPI: new EncounterClient(getTokenAndSetExpirationDate, getSessionUrl),
    calendarAPI: new CalendarClient(getTokenAndSetExpirationDate, getSessionUrl),
    formAPI: new FormClient(getTokenAndSetExpirationDate, getSessionUrl),
    filesAPI: new FilesClient(getTokenAndSetExpirationDate, getSessionUrl),
    insightsAPI: new InsightsClient(getTokenAndSetExpirationDate, getSessionUrl),
    rolodexAPI: new RolodexClient(getTokenAndSetExpirationDate, getSessionUrl),
    billingAPI: new BillingClient(getTokenAndSetExpirationDate, getSessionUrl),
    marAPI: new MARClient(getTokenAndSetExpirationDate, getSessionUrl),
    entityAPI: new EntityClient(getTokenAndSetExpirationDate, getSessionUrl),
    userNotificationAPI: new UserNotificationClient(getTokenAndSetExpirationDate, getSessionUrl),
    claimsAPI: new ClaimsClient(getTokenAndSetExpirationDate, getSessionUrl),
    reportingAPI: new ReportingClient(getTokenAndSetExpirationDate, getSessionUrl),
    crmAPI: new CRMClient(getTokenAndSetExpirationDate, getSessionUrl),
    complianceAPI: new ComplianceClient(getTokenAndSetExpirationDate, getSessionUrl),
    labAPI: new LabClient(getTokenAndSetExpirationDate, getSessionUrl),
    bedBoardAPI: new BedBoardClient(getTokenAndSetExpirationDate, getSessionUrl),
    filtersAPI: new FiltersClient(getTokenAndSetExpirationDate, getSessionUrl),
    tasksAPI: new TasksClient(getTokenAndSetExpirationDate, getSessionUrl),
    portalAPI: new PortalClient(getTokenAndSetExpirationDate, getSessionUrl),
    roundsAPI: new RoundsClient(getTokenAndSetExpirationDate, getSessionUrl),
    remittanceAPI: new RemittanceClient(getTokenAndSetExpirationDate, getSessionUrl),
    paymentsAPI: new PaymentsClient(getTokenAndSetExpirationDate, getSessionUrl),
    // add more API clients here
  };
};

export default useAPI;
