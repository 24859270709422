import { useEffect, useState } from 'react';
import { Box, Text } from 'grommet';
import { FormClose } from 'grommet-icons';

import InteriorBox from '@ritten/ui-library/boxes/InteriorBox';
import { ErrorPanel } from '@ritten/ui-library/errors';
import { TextInputComponent } from '@ritten/ui-library/form-inputs';
import Flyout from '@ritten/ui-library/modals/Flyout';
import LoadingPanel from '@ritten/ui-library/panels/LoadingPanel';

import useAPI from 'external/useAPI';
import { useErrorState, useLoadingState } from 'hooks';
import { COLORS } from 'styles/colors';

export interface AnswerLibraryContext {
  patientId?: string;
  fieldDefinitionId?: string;
  formId?: string;
}

interface AnswerLibraryFlyoutProps {
  context: AnswerLibraryContext;
  onSelect: (answer: string) => void;
  onClose: () => void;
}

const AnswerLibraryFlyout = (props: AnswerLibraryFlyoutProps) => {
  const { context, onSelect, onClose } = props;
  const { loading, needsLoadingState } = useLoadingState(false);
  const { errors, addError, clearErrors } = useErrorState();
  const { clinicAPI } = useAPI();
  const [fieldItems, setFieldItems] = useState<Clinic.TemplatedTextItem[]>([]);
  const [globalItems, setGlobalItems] = useState<Clinic.TemplatedTextItem[]>([]);
  const [searchInput, setSearchInput] = useState<string>('');

  useEffect(() => {
    fetchTemplatedTextItems();
  }, []);

  useEffect(() => {
    fetchTemplatedTextItems();
  }, [searchInput]);

  const fetchTemplatedTextItems = needsLoadingState(async () => {
    clearErrors();
    try {
      const { fieldDefinitionId } = context;
      if (fieldDefinitionId) {
        const items = await clinicAPI.queryTemplatedTexts({
          ...(searchInput.length > 0 && { searchParam: searchInput }),
          fieldDefinitionId,
          noUserTemplates: true,
          limit: 100,
          offset: 0,
        });
        setFieldItems(items);
      }
      const items = await clinicAPI.queryTemplatedTexts({
        ...(searchInput.length > 0 && { searchParam: searchInput }),
        noFieldTemplates: true,
        noUserTemplates: true,
        limit: 100,
        offset: 0,
      });
      setGlobalItems(items);
    } catch (err) {
      addError(err);
    }
  });

  const generateAnswer = needsLoadingState(async (item: Clinic.TemplatedTextItem) => {
    clearErrors();
    try {
      let result = item.value;
      if (context.patientId) {
        const generated = await clinicAPI.postGenerateTemplatedTextAnswer(item.id, {
          patientId: context.patientId,
        });
        result = generated.result;
      }
      onSelect(result);
    } catch (err) {
      addError(err);
    }
  });

  const flyoutClickOutside = () => {
    onClose();
  };

  return (
    <Flyout onClickOutside={flyoutClickOutside}>
      <Box
        flex="grow"
        pad={{
          bottom: '24px',
        }}
      >
        <Box background={COLORS.rittenBlue400} pad="12px" justify="between" direction="row">
          <Text color={COLORS.white}>Answer Library</Text>
          <FormClose
            style={{
              cursor: 'pointer',
            }}
            onClick={() => onClose()}
          />
        </Box>
        <Box pad="12px" gap="20px">
          <Box gap="12px">
            <Text weight="bold" size="16px">
              Templated Text
            </Text>
            <TextInputComponent
              flex="grow"
              textInputProps={{
                placeholder: 'Search',
                value: searchInput,
                onChange: (e) => {
                  setSearchInput(e.target.value);
                },
              }}
            />
            <LoadingPanel showLoader={loading}>
              <ErrorPanel errors={errors} />
              <Box gap="12px">
                {fieldItems.length < 1 && globalItems.length < 1 && !loading && (
                  <Text size="12px">Answer library is currently empty.</Text>
                )}
                {fieldItems.length > 0 && (
                  <Box gap="12px">
                    <Text size="12px" weight="bold">
                      Suggested
                    </Text>
                    {fieldItems.map((item) => {
                      return (
                        <InteriorBox pad="12px" key={item.id} onClick={() => generateAnswer(item)}>
                          <Text size="14px" weight="bold">
                            {item.label}
                          </Text>
                          <Text size="12px">{item.value}</Text>
                        </InteriorBox>
                      );
                    })}
                  </Box>
                )}
                {globalItems.length > 0 && (
                  <Box gap="12px">
                    <Text size="12px" weight="bold">
                      All Notes
                    </Text>
                    {globalItems.map((item) => {
                      return (
                        <InteriorBox pad="12px" key={item.id} onClick={() => generateAnswer(item)}>
                          <Text size="14px" weight="bold">
                            {item.label}
                          </Text>
                          <Text size="12px">{item.value}</Text>
                        </InteriorBox>
                      );
                    })}
                  </Box>
                )}
              </Box>
            </LoadingPanel>
          </Box>
        </Box>
      </Box>
    </Flyout>
  );
};

export default AnswerLibraryFlyout;
