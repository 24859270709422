import { parseISODateStrings } from 'utils';
import RittenClient from './RittenClient';

class BedBoardClient extends RittenClient {
  listBedBoard = async (
    params?: Partial<BedBoard.ListBedBoardQuery>,
  ): Promise<BedBoard.BuildingResponse[]> => {
    const { data = [] } = await this.get<BedBoard.BuildingResponse[]>(`/api/bedboard`, {
      params,
    });
    return parseISODateStrings(data);
  };

  putBuildings = async (params: BedBoard.PutBuildingsRequest[]) => {
    const res = await this.put<BedBoard.BuildingResponse[]>('/api/bedboard/buildings', params);
    return res.data;
  };

  putBuilding = async (params: BedBoard.PutBuildingsRequest) => {
    const res = await this.put<BedBoard.BuildingResponse>(
      `/api/bedboard/buildings/${params.id}`,
      params,
    );
    return res.data;
  };

  deleteBuilding = async (buildingId: string) => {
    await this.delete(`/api/bedboard/buildings/${buildingId}`);
  };

  putRoom = async (params: BedBoard.PutRoomsRequest) => {
    const res = await this.put<BedBoard.RoomResponse>(`/api/bedboard/rooms/${params.id}`, {
      params,
    });
    return res.data;
  };

  deleteRoom = async (roomId: string) => {
    await this.delete(`/api/bedboard/rooms/${roomId}`);
  };

  putBed = async (params: BedBoard.PutBedsRequest) => {
    const res = await this.put<BedBoard.BedResponse>(`/api/bedboard/beds/${params.id}`, params);
    return res.data;
  };

  deleteBed = async (bedId: string) => {
    await this.delete(`/api/bedboard/beds/${bedId}`);
  };

  postBedPatients = async (bedId: string, patientId: string) => {
    const res = await this.post<BedBoard.BedResponse>(`/api/bedboard/beds/${bedId}/patients`, {
      patientId,
    });
    return res.data;
  };

  deleteBedPatient = async (patientId: string) => {
    await this.delete(`/api/bedboard/beds/patients/${patientId}`);
  };

  getPatientBedRoomBuilding = async (
    patientId: string,
  ): Promise<BedBoard.BedRoomBuildingResponse> => {
    const res = await this.get<BedBoard.BedRoomBuildingResponse>(
      `/api/bedboard/beds/patients/${patientId}`,
    );
    return res.data;
  };
}

export default BedBoardClient;
