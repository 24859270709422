import RittenClient from './RittenClient';

export default class UserTicketsClient extends RittenClient {
  validatePasswordTicket = async (
    ticketId: string,
  ): Promise<UserTickets.ValidatePasswordTicketResponse> => {
    const res = await this.post<UserTickets.ValidatePasswordTicketResponse>(
      '/api/user-password-ticket/validate',
      {
        ticketId,
      },
    );
    return res.data;
  };

  spendPasswordTicket = async (ticketId: string, userPassword: string): Promise<void> => {
    await this.post<void>('/api/user-password-ticket/spend', {
      ticketId,
      password: userPassword,
    });
  };

  resendInvitationEmail = async (ticketId: string): Promise<void> => {
    await this.post<void>('/api/user-password-ticket/resend-invite', {
      ticketId,
    });
  };
}
