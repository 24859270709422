/**
 * Used to mark the parent nav as active on all sub nav paths.
 * 
 * @example
 * <NavLink 
 *    to={`${match.path}/clinic-setup/programs`} // default path but can change to /clinic-setup/*
 *    isActive={(match, location) =>
        navLinkUtils.isParentActive('/clinic-setup', location?.pathname)
      }
 * />
 * @param {string} parentPath path representing the base path which will be present no matter what child links are selected.
 * @param {string} location current location passed down from the navLink.isActive function
 * @returns {boolean}
 */
export function isParentActive(parentPath: string, location: string) {
  return location.includes(parentPath);
}
