// @ts-strict-ignore
import { grommet } from 'grommet';
import { deepMerge } from 'grommet/utils';

import { COLORS } from '../../../styles/colors';

export const SINGLE_DOSE_FREQUENCIES: MAR.ScheduleBlockFrequency[] = ['Now', 'Stat'];
export const MONTHLY_DOSE_FREQUENCIES: MAR.ScheduleBlockFrequency[] = ['Q4W', 'QM'];

export type FrequencyConfigLabel = 'Starts at:' | 'Dose ';

// NOTE: this is in a specific order for display on the configurations screen
export const CONFIGURABLE_FREQUENCY_OPTIONS: {
  frequencyOption: MAR.ScheduleBlockFrequency;
  label: FrequencyConfigLabel;
}[] = [
  { frequencyOption: 'Q30M', label: 'Starts at:' },
  { frequencyOption: 'QH', label: 'Starts at:' },
  { frequencyOption: 'Q2H', label: 'Starts at:' },
  { frequencyOption: 'Q3H', label: 'Starts at:' },
  { frequencyOption: 'Q4H', label: 'Starts at:' },
  { frequencyOption: 'Q6H', label: 'Starts at:' },
  { frequencyOption: 'Q8H', label: 'Starts at:' },
  { frequencyOption: 'QW', label: 'Starts at:' },
  { frequencyOption: 'Q4W', label: 'Starts at:' },
  { frequencyOption: 'QD', label: 'Dose ' },
  { frequencyOption: 'HS', label: 'Dose ' },
  { frequencyOption: 'BID', label: 'Dose ' },
  { frequencyOption: 'TID', label: 'Dose ' },
  { frequencyOption: 'QID', label: 'Dose ' },
];

export const FREQUENCY_OPTION_LABELS = [
  'Now',
  'Stat',
  'Every 30 minutes (q30min)',
  'Every hour (q1h)',
  'Every 2 hours (q2h)',
  'Every 3 hours (q3h)',
  'Every 4 hours (q4h)',
  'Every 6 hours (q6h)',
  'Every 8 hours (q8h)',
  'Every 12 hours (q12h)',
  'Once per day (Daily)',
  'Bedtime (HS)',
  'Twice per day (BID)',
  'Three times per day (TID)',
  'Four times per day (QID)',
  'Every other day',
  'Every week (qweek)',
  'Every 4 weeks (q4week)',
  'Every month (QM)',
  'Days of the week',
];

export const FREQUENCY_OPTIONS: MAR.ScheduleBlockFrequency[] = [
  'Now',
  'Stat',
  'Q30M',
  'QH',
  'Q2H',
  'Q3H',
  'Q4H',
  'Q6H',
  'Q8H',
  'Q12H',
  'QD',
  'HS',
  'BID',
  'TID',
  'QID',
  'Q2D',
  'QW',
  'Q4W',
  'QM',
  'Select Week Days',
];

export const SCHEDULES_ROUTE = 'medications';
export const DOSES_ROUTE = 'log';

export const TAKEN_DOSE_STATUSES: MAR.DoseStatus[] = ['observed', 'administered'];
export const ORDER_TYPES: MAR.OrderType[] = [
  'Electronic',
  'Doctor Prescribed',
  'Phone',
  'Email',
  'Fax',
  'Paper',
  'Verbal',
  'Other',
];

export const ORDER_TYPE_LABELS: Record<MAR.OrderType, string> = {
  Electronic: 'Prescriber creating electronic order',
  'Doctor Prescribed': 'Previously prescribed by doctor',
  Phone: 'Phone',
  Email: 'Email',
  Fax: 'Fax',
  Paper: 'Paper',
  Verbal: 'Verbal - Read back and verified',
  Other: 'Other',
};

export const modalTheme = deepMerge(grommet, {
  box: {
    extend: {
      fontSize: '14px',
    },
  },
  text: {
    extend: {
      fontSize: '14px',
    },
  },
});

export const getChipColors = (type?: MAR.ScheduleType) => {
  return {
    PRN: {
      color: COLORS.yellow200,
      textColor: COLORS.darkGray600,
    },
    Scheduled: {
      color: COLORS.green100,
      textColor: COLORS.green400,
    },
    Action: {
      color: COLORS.rittenBlue200,
      textColor: COLORS.rittenTeal,
    },
    'Unscheduled Action': {
      color: COLORS.rittenBlue200,
      textColor: COLORS.rittenTeal,
    },
  }[type];
};

export const UPDATABLE_DOSE_STATUSES: MAR.DoseStatus[] = [
  'observed',
  'administered',
  'refused',
  'wasted',
  'complete',
  'incomplete',
  'not_provided',
  'unavailable',
];

export const NEGATIVE_DOSE_STATUSES: MAR.DoseStatus[] = [
  'incomplete',
  'refused',
  'wasted',
  'not_provided',
  'unavailable',
];

export const DOSE_STATUS_LABELS: Record<MAR.DoseStatus, string> = {
  incomplete: 'Action Not Completed',
  complete: 'Action Completed',
  administered: 'Administered',
  observed: 'Observed',
  pending: 'Pending',
  refused: 'Refused',
  wasted: 'Wasted',
  not_provided: 'Not Provided',
  unavailable: 'Unavailable',
  'before schedule': 'Before Schedule',
};

export const ACTION_DOSE_STATUSES: MAR.DoseStatus[] = ['complete', 'incomplete'];

export const ACTIVE_ORDER_STATUSES: MAR.OrderStatus[] = ['active', 'pending'];

export const LOW_PILL_COUNT = 5;

export const INJECTABLE_ROUTES = ['Intramuscular', 'Injectable', 'Subcutaneous', 'Intraveneous'];
