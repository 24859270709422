// Generated by ts-to-zod
import { z } from 'zod';

import { labOrderSchema } from './lab.zod';
import {
  marOrderStatusSchema,
  marScheduleBlockFrequencySchema,
  marScheduleTypeSchema,
} from './mar.zod';
import { patientPatientSchema, patientProgramStatusSchema } from './patient.zod';
import { signaturesSignatureSchema, signaturesSignatureStatusSchema } from './signatures.zod';
import { usersUserSchema } from './users.zod';

export const ordersOrderTypeSchema = z.union([
  z.literal('ERX_DOSESPOT'),
  z.literal('NON_ERX'),
  z.literal('ACTION'),
  z.literal('LAB'),
]);

export const ordersOrderStatusSchema = z.union([
  z.literal('Approval Required'),
  z.literal('Complete'),
  z.literal('Sending'),
  z.literal('Sent'),
  z.literal('Error'),
  z.literal(''),
]);

export const ordersOrderSchema = z.lazy(() =>
  z.object({
    id: z.string(),
    encounterId: z.string(),
    type: ordersOrderTypeSchema,
    status: ordersOrderStatusSchema,
    createdAt: z.date(),
    deletedAt: z.date().nullable(),
    marOrderId: z.string().nullable(),
    marOrderStatus: marOrderStatusSchema,
    marScheduleId: z.string().nullable(),
    marOrderCreateSignatureId: z.string().nullable(),
    marOrderCreateSignature: signaturesSignatureSchema.optional(),
    marOrderDeleteSignatureId: z.string().nullable(),
    marOrderDeleteSignature: signaturesSignatureSchema.optional(),
    marScheduleType: marScheduleTypeSchema,
    displayName: z.string(),
    medication: z.string(),
    orderingProvider: usersUserSchema.optional(),
    discontinueOrderingProvider: usersUserSchema.optional(),
    orderedBy: usersUserSchema,
    labOrder: labOrderSchema.nullable(),
    signatureStatus: signaturesSignatureStatusSchema,
    signedByMe: z.boolean(),
    prescribedBy: usersUserSchema.optional(),
    dosespotPrescriptionId: z.string(),
    dosespotMedicationId: z.string(),
    scheduleExempt: z.boolean(),
    patient: patientPatientSchema,
  }),
);

export const ordersOrderDetailsDosespotSchema = z.object({
  id: z.string(),
  orderId: z.string(),
  dosespotPrescriptionId: z.string(),
  dosespotMedicationId: z.string(),
  createdAt: z.date(),
  displayName: z.string(),
  doseForm: z.string(),
  route: z.string(),
  strength: z.string(),
  genericProductName: z.string(),
  genericDrugName: z.string(),
  directions: z.string(),
  quantity: z.string(),
  refills: z.string(),
  daysSupply: z.string(),
  prescriber: z.string(),
  prescriberUserId: z.string(),
  pharmacy: z.object({
    storeName: z.string(),
    address1: z.string(),
    address2: z.string(),
    city: z.string(),
    state: z.string(),
    zipCode: z.string(),
    primaryPhone: z.string(),
    primaryFax: z.string(),
  }),
  pharmacyNotes: z.string(),
  effectiveDate: z.date().nullable(),
});

export const ordersOrdersQuerySchema = z.lazy(() =>
  z.object({
    signatureStatuses: z.array(signaturesSignatureStatusSchema).optional(),
    orderTypes: z.array(ordersOrderTypeSchema).optional(),
    scheduleTypes: z.array(marScheduleTypeSchema).optional(),
    patientIds: z.array(z.string()).optional(),
    patientProgramStatuses: z.array(patientProgramStatusSchema).optional(),
    creatorUserIds: z.array(z.string()).optional(),
    orderingProviderUserIds: z.array(z.string()).optional(),
    startTime: z.date().optional().nullable(),
    endTime: z.date().optional().nullable(),
    searchQuery: z.string().optional(),
    limit: z.number(),
    offset: z.number(),
    careTeamMemberIds: z.array(z.string()).optional(),
    unscheduled: z.boolean().optional(),
    programIds: z.array(z.string()).optional(),
  }),
);

export const ordersOrderFrequencySchema = z.object({
  frequency: marScheduleBlockFrequencySchema,
  hours: z.array(z.number()),
});
