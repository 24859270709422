// @ts-strict-ignore
import { ComponentType, lazy } from 'react';

import { setLocalStorageItem } from './browserUtils';

// Note: taken from https://raphael-leger.medium.com/react-webpack-chunkloaderror-loading-chunk-x-failed-ac385bd110e0
// If window.location.reload ACTUALLY solves our issue (with our index.html no longer being cached), in theory
// it should never get to to the `throw error` line (because .reload() will always resolve the error when caught)

const FORCE_REFRESH_LOCAL_STORAGE_KEY = 'ritten-force-page-refresh';

type ComponentImportType = { default: ComponentType<any> };

export const lazyWithRetry = (componentImport: () => Promise<ComponentImportType>) =>
  lazy(async () => {
    const pageHasAlreadyBeenForceRefreshed = JSON.parse(
      window.localStorage.getItem(FORCE_REFRESH_LOCAL_STORAGE_KEY) || 'false',
    );

    try {
      const component: ComponentImportType = await componentImport();

      setLocalStorageItem(FORCE_REFRESH_LOCAL_STORAGE_KEY, 'false');

      return component;
    } catch (error) {
      if (!pageHasAlreadyBeenForceRefreshed) {
        // Assuming that the user is not on the latest version of the application.
        // Let's refresh the page immediately.
        setLocalStorageItem(FORCE_REFRESH_LOCAL_STORAGE_KEY, 'true');
        window.location.reload();
        return null;
      }

      // The page has already been reloaded
      // Assuming that user is already using the latest version of the application.
      // Let's let the application crash and raise the error.
      throw error;
    }
  });
