import { createSlice } from '@reduxjs/toolkit';

import { RootState } from '../../redux/store';

export interface SignatureState {
  signatureUpdate: number;
}

const initialState: SignatureState = {
  signatureUpdate: 0,
};

export const signatureSlice = createSlice({
  name: 'signature',
  initialState,
  reducers: {
    // NOTE: onSignatureUpdate is our first redux reducer.  It's rudimentary but it's a start.
    // I don't think we want to proliferate this pattern throughout the app so if you see this message
    // in the future than pause and think before you get inspiration from here.
    onSignatureUpdate: (state) => {
      state.signatureUpdate += 1;
    },
  },
});

export const { onSignatureUpdate } = signatureSlice.actions;

export const selectSignatureUpdate = (state: RootState) => state.signature.signatureUpdate;

export default signatureSlice.reducer;
