// @ts-strict-ignore
import { PropsWithChildren } from 'react';
import { Box, Text } from 'grommet';

import useMobileDevice from 'context/mobileDevice/useMobileDevice';
import { useErrorState, useLoadingState } from 'hooks';
import { StandardButton } from '../buttons';
import LinkButton from '../buttons/LinkButton';
import { ErrorPanel } from '../errors';
import BaseModal from './base-modal/BaseModal';

type ConfirmDeleteModalProps = PropsWithChildren<{
  isOpen?: boolean;
  onSubmit: () => void;
  onSuccess?: () => void;
  onClose?: () => void;
  isLoading?: boolean;
  title?: string;
  /**
   * Use the children prop to render more complex ReactNodes.
   * Use an empty string ('') to render no text at all.
   */
  text?: string;
  deleteButtonText?: string;
  cancelButtonText?: string;
  /**
   * If true, will keep modal open on error and display the error to user.
   */
  catchErrors?: boolean;
}>;

export const ConfirmDeleteModal: React.FC<ConfirmDeleteModalProps> = (
  props: ConfirmDeleteModalProps,
) => {
  const {
    isOpen = false,
    title = 'Confirm Delete',
    isLoading,
    deleteButtonText = 'Delete',
    cancelButtonText = 'Cancel',
    catchErrors = false,
  } = props;

  const { isMobile } = useMobileDevice();
  const { loading, needsLoadingState } = useLoadingState(false);
  const { errors, addError, clearErrors } = useErrorState();

  const onSubmit = needsLoadingState(async () => {
    clearErrors();
    if (!catchErrors) {
      props.onSubmit();
      return;
    }

    try {
      await props.onSubmit();
      props.onSuccess?.();
      onClose();
    } catch (e) {
      addError(e);
    }
  });

  const onClose = () => {
    clearErrors();
    props.onClose?.();
  };

  if (!isOpen) {
    return null;
  }

  const text = props.text ?? 'Are you sure you want to delete?';

  return (
    <BaseModal
      isOpen={isOpen}
      header={title}
      onClose={onClose}
      width={isMobile ? '100%' : '450px'}
      footer={
        <Box justify="end" direction="row" gap="xsmall" width="100%">
          <LinkButton
            color="plain"
            className="ConfirmDeleteModal__close-button"
            onClick={onClose}
            label={cancelButtonText}
            disabled={loading}
          />
          <StandardButton
            data-testid="confirm-delete-modal-delete-btn"
            onClick={onSubmit}
            label={deleteButtonText}
            isLoading={isLoading}
            color="alert"
            disabled={loading}
          />
        </Box>
      }
    >
      <ErrorPanel errors={errors} />
      {props.text !== '' && (
        <Box pad={{ vertical: 'small' }}>
          <Text>{text}</Text>
        </Box>
      )}
      {props.children}
    </BaseModal>
  );
};

export default ConfirmDeleteModal;
