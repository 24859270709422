// @ts-strict-ignore
import { useEffect, useState } from 'react';
import { countries } from 'country-flag-icons';
import getCountryFlag from 'country-flag-icons/unicode';
import { Box, Select, Text, ThemeContext } from 'grommet';
import { grommet } from 'grommet/themes';
import { deepMerge } from 'grommet/utils';
import { CountryCode, getCountryCallingCode } from 'libphonenumber-js';

import { containsIgnoreCase, isNotEmpty } from 'utils';
import { COLORS } from '../../styles/colors';
import InputLabel from './InputLabel';

interface CountrySelectWithIconsProps {
  name?: string;
  value?: string;
  onChange: (value: string) => void;
  options?: { value?: CountryCode; label: string }[];
  disabled?: boolean;
  isIndependentFormEntry?: boolean;
  label?: string;
  width?: string;
  showSelectedCountryName?: boolean;
  showCallingCode?: boolean;
}

const disabledKey = 'ZZ';

const getCountryCallingCodeSafe = (countryCode: CountryCode | null) => {
  if (isNotEmpty(countryCode)) {
    try {
      return getCountryCallingCode(countryCode);
    } catch (error) {
      // getCountryCallingCode() will throw an error if you request a country it doesn't have in its list.
      // for now we want to squash the error.
      // console.warn(error);
    }
  }

  return '';
};

export const CountrySelectWithIcons = (props: CountrySelectWithIconsProps) => {
  const { showCallingCode } = props;

  const regionNames = new Intl.DisplayNames(['en'], { type: 'region' });
  const defaultCountriesList = countries.map((country: CountryCode) => {
    return { value: country, label: regionNames.of(country) };
  });
  const [altCountryList, setAltCountryList] = useState<{ value?: CountryCode; label: string }[]>(
    [],
  );
  const [searchText, setSearchText] = useState<string>('');

  const getOptions = () => {
    let curList = altCountryList;
    if (props.options?.length > 0) {
      curList = props.options;
    }
    return curList
      .filter(
        ({ label, value }) =>
          containsIgnoreCase(label, searchText) ||
          containsIgnoreCase(value, searchText) ||
          containsIgnoreCase(getCountryCallingCodeSafe(value), searchText),
      )
      .map(({ value, label }) => (
        <Box
          width="400px"
          key={value || disabledKey}
          justify="between"
          pad={{ horizontal: '5px' }}
          direction="row"
        >
          <Box direction="row" gap="15px" width="100%">
            {value && getCountryFlag(value)}
            {label ? (
              <Text>{label}</Text>
            ) : (
              <div
                style={{
                  width: '100%',
                  borderTop: `2px dashed ${COLORS.darkGray600}`,
                }}
              />
            )}
          </Box>
          {value && showCallingCode && <Text>+{getCountryCallingCodeSafe(value)}</Text>}
        </Box>
      ));
  };

  const themeCountrySelect = deepMerge(grommet, {
    global: {
      colors: {
        focus: undefined,
      },
    },
    select: {
      container: {
        extend: {
          backgroundColor: COLORS.white,
        },
      },
      icons: {
        color: COLORS.darkGray600,
        margin: 'none',
      },
      background: props.disabled ? COLORS.darkGray200 : COLORS.white,
    },
    button: {
      disabled: {
        extend: {
          fontSize: '10px',
          fontWeight: 400,
        },
        opacity: 0.5,
      },
    },
  });

  const themeCountrySelectComponent = deepMerge(grommet, {
    global: {
      colors: {
        focus: undefined,
      },
    },
    textInput: {
      extend: {
        backgroundColor: props.disabled ? COLORS.darkGray200 : COLORS.white,
        fontSize: '16px',
        fontWeight: 400,
        height: '40px',
      },
      disabled: {
        opacity: 1,
      },
    },
    select: {
      container: {
        extend: {
          backgroundColor: props.disabled ? COLORS.darkGray200 : COLORS.white,
        },
      },
      icons: {
        color: COLORS.darkGray600,
      },
      background: props.disabled ? COLORS.darkGray200 : COLORS.white,
    },
    button: {
      extend: {
        width: props.width ?? 'auto',
        height: '40px',
        background: props.disabled ? COLORS.darkGray200 : COLORS.white,
      },
      disabled: {
        extend: {
          fontSize: '10px',
          fontWeight: 400,
        },
        opacity: 1,
      },
    },
  });

  useEffect(() => {
    // Adds preferred countries to the top with a separator
    // Defaulting to US for now
    const newCountryList: { value: CountryCode; label: string }[] = [];
    newCountryList.push({ value: 'US', label: regionNames.of('US') });
    newCountryList.push({ value: null, label: '' });
    newCountryList.push(
      ...defaultCountriesList.filter(({ value }) => {
        return value !== 'US';
      }),
    );
    setAltCountryList(newCountryList);
  }, []);

  return (
    <ThemeContext.Extend
      value={props.isIndependentFormEntry ? themeCountrySelectComponent : themeCountrySelect}
    >
      <Box aria-label={props.name} direction="column" flex={false}>
        {props.isIndependentFormEntry && <InputLabel label={props.label} />}
        <Select
          a11yTitle="Country Selector"
          alignSelf="start"
          dropHeight="medium"
          plain={!props.isIndependentFormEntry}
          onChange={(country) => {
            props.onChange(country.option.key);
            setSearchText('');
          }}
          value={props.value}
          options={getOptions()}
          disabledKey={(value) => {
            return value.key === disabledKey;
          }}
          searchPlaceholder={`Country Name ${showCallingCode ? 'OR Country Calling Code' : ''}`}
          onSearch={setSearchText}
          disabled={props.disabled}
          valueLabel={
            <Box direction="row" align="center">
              <Text margin={{ horizontal: '8px' }} size="large">
                {props.value ? (
                  getCountryFlag(props.value)
                ) : (
                  <Box
                    width="22px"
                    height="2px"
                    border={{
                      color: COLORS.darkGray600,
                      side: 'top',
                      style: 'dashed',
                      size: '3px',
                    }}
                  />
                )}
              </Text>
              {props.showSelectedCountryName && (
                <Text truncate size="16px">
                  {regionNames.of(props.value)}
                </Text>
              )}
            </Box>
          }
        />
      </Box>
    </ThemeContext.Extend>
  );
};

export default CountrySelectWithIcons;
