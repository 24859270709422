import { parseObjectDates } from 'utils/dateParsers';
import RittenClient from './RittenClient';

export default class FormClient extends RittenClient {
  listFormDefinitions = async (): Promise<Forms.FormDefinitionBase[]> => {
    const res = await this.get<Forms.FormDefinitionBase[]>('/api/forms/definitions/forms');
    return res.data;
  };

  getFormDefinitionByID = async (id: string): Promise<Forms.FormDefinition> => {
    const res = await this.get<Forms.FormDefinition>(`/api/forms/definitions/forms/${id}`);
    return res.data;
  };

  archiveFormDefinition = async (formDefinitionId: string): Promise<Forms.SectionDefinition> => {
    const res = await this.delete<Forms.SectionDefinition>(
      `/api/forms/definitions/forms/${formDefinitionId}`,
    );
    return res.data;
  };

  listSectionDefinitions = async (): Promise<Forms.SectionDefinitionBase[]> => {
    const res = await this.get<Forms.SectionDefinitionBase[]>('/api/forms/definitions/sections');
    return res.data;
  };

  listFieldTypes = async (): Promise<Forms.FieldType[]> => {
    const { data = [] } = await this.get<Forms.FieldType[]>('/api/forms/definitions/fields/types');
    return data;
  };

  getDefaultFieldDefinitionMeta = async (fieldType: string): Promise<Forms.FieldMeta> => {
    const res = await this.get<Forms.FieldMeta>(`/api/forms/definitions/fields/types/${fieldType}`);
    return res.data;
  };

  postSectionDefinition = async (
    definition: Forms.SectionDefinitionPostBody,
  ): Promise<Forms.SectionDefinition> => {
    const res = await this.post<Forms.SectionDefinition>(
      '/api/forms/definitions/sections',
      definition,
    );
    return res.data;
  };

  getFullSectionDefinition = async (
    sectionDefinitionID: string,
  ): Promise<Forms.SectionDefinition> => {
    const res = await this.get<Forms.SectionDefinition>(
      `/api/forms/definitions/sections/${sectionDefinitionID}`,
    );
    return res.data;
  };

  postFormDefinition = async (
    definition: Forms.FormDefinitionPostBody,
  ): Promise<{ id: string }> => {
    const res = await this.post<{ id: string }>('/api/forms/definitions/forms', definition);
    return res.data;
  };

  getFormInstance = async (formInstanceID: string): Promise<Forms.FormInstance> => {
    const res = await this.get<Forms.FormInstance>(`/api/forms/instances/forms/${formInstanceID}`);
    const convertedData = parseObjectDates(res.data, ['signedAt']);
    const { portalAccessRoles, submissions } = convertedData;
    return {
      ...convertedData,
      submissions: submissions.map((s) => parseObjectDates(s, ['createdAt'])),
      portalAccessRoles: portalAccessRoles.map((par) =>
        parseObjectDates(par, ['createdAt', 'deletedAt']),
      ),
    };
  };

  getPortalFormInstance = async (
    patientId: string,
    formInstanceId: string,
  ): Promise<Forms.FormInstance> => {
    const res = await this.get<Forms.FormInstance>(
      `/api/patients/${patientId}/portal-forms/${formInstanceId}`,
    );
    const convertedData = parseObjectDates(res.data, ['signedAt']);
    return convertedData;
  };

  sendFormInstanceToPatient = async (formInstanceID: string): Promise<void> => {
    await this.post<void>(`/api/forms/instances/forms/${formInstanceID}/send`);
  };

  submitFormInstance = async (formInstanceID: string): Promise<void> => {
    await this.post<void>(`/api/forms/instances/forms/${formInstanceID}/submissions`);
  };

  deleteFormInstance = async (formInstanceID: string): Promise<Forms.FormInstance> => {
    const res = await this.delete<Forms.FormInstance>(
      `/api/forms/instances/forms/${formInstanceID}`,
    );
    return res.data;
  };

  postFieldValue = async (fieldID: string, value: any): Promise<void> => {
    await this.post(`/api/forms/instances/fields/${fieldID}/value`, { value });
  };

  getChartableFieldDefinitions = async (patientId: string): Promise<Forms.TrendableField[]> => {
    const res = await this.get<Forms.TrendableField[]>(`/api/forms/definitions/fields-trends`, {
      params: {
        patientId,
      },
    });
    return res.data;
  };

  postFormPortalAccessRolesBulk = async (
    body: Forms.PortalAccessRolesBulkPostBody,
  ): Promise<void> => {
    await this.post<void>('/api/forms/instances/forms-portal-access-roles', body);
  };

  postFormPortalAccessRoles = async (
    formInstanceID: string,
    body: Forms.PortalAccessRolePostBody[],
  ): Promise<void> => {
    await this.post<void>(`/api/forms/instances/forms/${formInstanceID}/portal-access-roles`, body);
  };

  exportFormsAsync = async (exportFormsBody: {
    formIds: string[];
    patientIds?: string[];
    startTime: Date;
    endTime: Date;
    combinePDFs: boolean;
  }): Promise<void> => {
    await this.post<void>(`/api/exports/async/forms`, exportFormsBody);
  };

  getFormInstances = async (
    params: Forms.FormInstancesQuery,
  ): Promise<Forms.FormInstanceFeed[]> => {
    const res = await this.get<Forms.FormInstanceFeed[]>(`/api/forms/instances`, { params });
    const convertedData = res.data.map((instance) => {
      const { form, encounter } = instance;
      const convertedEncounter = parseObjectDates(encounter, ['createdAt', 'startTime', 'endTime']);
      return { form, encounter: convertedEncounter };
    });
    return convertedData;
  };
}
