import { from } from 'rxjs';

import { API_CACHE } from 'third-party/cache';
import { addToApiCache, getFromApiCache } from 'utils';
import RittenClient from './RittenClient';

class FilesClient extends RittenClient {
  downloadFileByID = async (fileId: string, options?: { useCache?: boolean }) => {
    const { useCache } = options || {};
    const url = `/api/files/${fileId}/download`;

    if (useCache) {
      const result = await getFromApiCache(API_CACHE.RITTEN_FILE_API_CACHE, url);

      // result will be null if API is not previously cached
      if (result) {
        const blob = await result.blob();
        return blob;
      }
    }

    const res = await this.get<Blob>(url, {
      responseType: 'blob',
    });

    // Only cache if there is something to cache
    if (useCache && res.data?.size > 0) {
      await addToApiCache(API_CACHE.RITTEN_FILE_API_CACHE, url, res.data);
    }

    return res.data;
  };

  downloadFileByID$ = (fileId: string, options?: { useCache?: boolean }) =>
    from(this.downloadFileByID(fileId, options));
}

export default FilesClient;
