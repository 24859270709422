import { Box, Text } from 'grommet';

import useUser from 'context/user/useUser';
import { COLORS } from 'styles/colors';
import { isEmpty } from 'utils';

const WelcomeHeader = (props: React.PropsWithChildren<{}>) => {
  const { user } = useUser();

  return (
    <Box
      background={COLORS.white}
      pad={{ horizontal: '24px', vertical: '28px' }}
      direction="column"
      align="center"
      fill="horizontal"
      height={{ min: 'auto' }}
    >
      <Text size="18px" weight={700}>
        Welcome, {!isEmpty(user.chosen) ? user.chosen : user.first}!
      </Text>
      {props.children}
    </Box>
  );
};

export default WelcomeHeader;
