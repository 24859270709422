import { useEffect, useState } from 'react';
import { GetTokenSilentlyOptions, useAuth0 } from '@auth0/auth0-react';
import axios from 'axios';
import { addMinutes } from 'date-fns';
import jwt_decode from 'jwt-decode';

import UnauthorizedPage from 'common/UnauthorizedPage';
import { useLoadingState } from 'hooks/useLoadingState';
import { isDateBefore, setLocalStorageItem } from 'utils';
import { getSubdomain } from 'utils/urlUtils';
import { useErrorState } from '../../hooks/useErrorState';
import LoadingPanel from '../../ui-library/panels/LoadingPanel';
import Auth0UserContext from './Auth0UserContext';

export type Auth0TokenGetter = (options: GetTokenSilentlyOptions) => Promise<string>;

interface TenantHost {
  host: string;
}

interface Auth0Claim {
  iss?: string;
  sub?: string;
  aud?: string[] | string;
  exp?: number;
  nbf?: number;
  iat?: number;
  jti?: string;
  ['https://ritten.io/isPatient']?: boolean;
  ['https://ritten.io/rittenTenants']?: string[];
}

const parseTenantId = (token: string) => {
  // parse token to get claims with authorized tenants list
  const claim = jwt_decode(token) as Auth0Claim;
  const authorizedTenants = claim['https://ritten.io/rittenTenants'];
  if (authorizedTenants) {
    return getTenantIdFromAuthorizedTenants(authorizedTenants);
  }
  throw new Error('authorized tenants not found on claim');
};

const getTenantIdFromAuthorizedTenants = (authorizedTenants: string[]) => {
  if (authorizedTenants.length > 0) {
    // This func requires modification if testing in QA
    // Use the below function with the qa tenant id (Ex. pr4801) instead of the above filter
    // return authorizedTenants.filter((t) => t === 'pr4856')[0];
    if (authorizedTenants.filter((t) => t === '*').length > 0) {
      return 'all';
    } // includes '*' wildcard so access to all
    return authorizedTenants[0]; // return the first tenant
  }
  throw new Error('tenant list from token claims can not be empty!');
};

const Auth0UserProvider: React.FC<{}> = (props: React.PropsWithChildren<{}>): JSX.Element => {
  const { getAccessTokenSilently } = useAuth0();
  const { loading, needsLoadingState } = useLoadingState(true);
  const { errors, addError } = useErrorState();

  const [userMeValue, setUserMeValue] = useState<Pick<Auth0UserContext, 'isPatient' | 'tenants'>>({
    isPatient: false,
    tenants: [],
  });
  const [userInactive, setUserInactive] = useState<boolean>(false);
  const [isRedirecting, setIsRedirecting] = useState<boolean>(false);

  let userInactivityExpirationId = 0;

  useEffect(() => {
    fetchAuth0User();
    startPolling();
    return () => {
      if (userInactivityExpirationId) {
        clearInterval(userInactivityExpirationId);
      }
    };
  }, []);

  const startPolling = () => {
    const intervalMs = 60000;
    const intervalId = window.setInterval(checkIfUserInactive, intervalMs);
    userInactivityExpirationId = intervalId;
  };

  const checkIfUserInactive = () => {
    const expirationDateString = window.localStorage.getItem('userInactivityExpirationDate');
    if (expirationDateString) {
      const expirationDate = new Date(expirationDateString);
      if (isDateBefore(expirationDate, new Date())) {
        window.localStorage.removeItem('userInactivityExpirationDate');
        setUserInactive(true);
      }
    }
  };

  const getTokenAndSetExpirationDate = async () => {
    const token = await getAccessTokenSilently({
      redirect_uri: window.location.href,
    });
    const expirationDate = addMinutes(new Date(), 60);
    setLocalStorageItem('userInactivityExpirationDate', expirationDate.toISOString());
    setUserInactive(false);
    return token;
  };

  const fetchAuth0User = needsLoadingState(async () => {
    const subdomain = getSubdomain(window.location.hostname);

    const token = await getTokenAndSetExpirationDate();
    if (subdomain === 'secure') {
      // the user was redirected after logging in from ritten.io
      // for these cases, we don't know the user's tenant, so we have to get it from the token
      try {
        const tenantId = parseTenantId(token);
        const res = await axios.get<TenantHost>(`/events/tenant/${tenantId}/host`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

        // redirect to tenant + navigation page
        window.location.href = res.data.host;
        setIsRedirecting(true);
      } catch (err) {
        addError(err);
      }
    } else {
      try {
        // TODO: can this be put into a RittenClient? I think it can now that the multi-tenant stuff is removed
        const res = await axios.get<Auth0UserContext>('/api/user/me/auth0', {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        setUserMeValue(res.data);
      } catch (err) {
        addError(err);
      }
    }
  });

  const appendJwtToUrl = async (urlString: string): Promise<string> => {
    const token = await getTokenAndSetExpirationDate();

    // Use the current page's scheme and host for the base URL
    const baseUrl = `${window.location.protocol}//${window.location.host}`;

    // Check if urlString is a relative URL
    let url;
    if (urlString.startsWith('http://') || urlString.startsWith('https://')) {
      // If urlString is an absolute URL, use it as is
      url = new URL(urlString);
    } else {
      // If urlString is a relative URL, construct the full URL using the dynamic base
      url = new URL(urlString, baseUrl);
    }

    // Append the JWT token as a query parameter
    url.searchParams.append('jwt', token);

    // Return the updated URL as a string
    return url.toString();
  };

  if (loading || isRedirecting) {
    return <LoadingPanel showLoader />;
  }

  if (errors.length > 0) {
    return <UnauthorizedPage />;
  }

  return (
    <Auth0UserContext.Provider
      value={{ ...userMeValue, userInactive, getTokenAndSetExpirationDate, appendJwtToUrl }}
    >
      {props.children}
    </Auth0UserContext.Provider>
  );
};

export default Auth0UserProvider;
