// @ts-strict-ignore
import { useEffect, useState } from 'react';
import { Box, BoxProps, grommet, Select, SelectProps, Text, ThemeContext } from 'grommet';
import { deepMerge } from 'grommet/utils';
import { Observable } from 'rxjs';

import { useErrorState } from '../../hooks/useErrorState';
import { COLORS } from '../../styles/colors';
import { isNotEmpty } from '../../utils/stringUtils';
import LoadingPanel from '../panels/LoadingPanel';
import { useAsyncSearch } from './useAsyncSearch';

type SearchDropdownComponentProps = {
  label?: string;
  selectProps: SelectProps;
  minimumSearchCharacters?: number;
  onSearch: (s: string) => Observable<any[]>;
  getOptionLabel?: (option: any) => JSX.Element;
  noOptionFoundLabel?: string;
  onNoOptionFoundClick?: () => void;
  optionsContainerWidth?: string;
  startSearchMessage?: string;
  emptySearchMessage?: string;
  width?: string;
  ariaLabel?: string;
  initialOptions?: any[];
} & Pick<BoxProps, 'margin'>;

/**
 * @deprecated in favor of Dropdown
 */
export const SearchDropdownComponent: React.FC<SearchDropdownComponentProps> = (
  props: SearchDropdownComponentProps,
) => {
  const themeDropdown = deepMerge(grommet, {
    global: {
      colors: {
        focus: undefined,
      },
    },
    textInput: {
      extend: {
        backgroundColor: COLORS.white,
        fontSize: '16px',
        fontWeight: 400,
        height: '38px',
      },
      disabled: {
        opacity: 1,
      },
    },
    select: {
      options: {
        container: {
          width: props.optionsContainerWidth || 'auto',
        },
      },
      control: {
        extend: {
          background: props.selectProps.disabled ? COLORS.darkGray200 : COLORS.white,
        },
      },
      icons: {
        color: COLORS.darkGray600,
      },
    },
    button: {
      extend: {
        width: props.width || 'auto',
      },
      disabled: {
        extend: {
          fontSize: '10px',
          fontWeight: 400,
        },
        opacity: 1,
      },
    },
  });

  const [searchString, setSearchString] = useState<string>('');
  const [options, setOptions] = useState<string[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const { errors, clearErrors, addError } = useErrorState();

  const [selectOpenState, setSelectOpenState] = useState(false);

  const { NO_OPTION_FOUND_KEY, setupSearchObservable, triggerSearch } = useAsyncSearch({
    setOptions,
    setLoading,
    addError,
    clearErrors,
    showNoOptionFound: isNotEmpty(props.noOptionFoundLabel),
    onAsyncSearch: props.onSearch,
    minimumSearchCharacters: props.minimumSearchCharacters,
  });

  useEffect(() => {
    const sub = setupSearchObservable();

    return () => sub.unsubscribe();
  }, []);

  useEffect(() => {
    if (props.initialOptions) {
      setOptions(props.initialOptions);
    }
  }, [props.initialOptions]);

  const onChange = (e: any) => {
    if (e.value === NO_OPTION_FOUND_KEY) {
      props.onNoOptionFoundClick?.();
    } else {
      props.selectProps.onChange({ option: e.value });
    }
  };

  const startSearch = (searchString: string) => {
    setSearchString(searchString);
    triggerSearch(searchString);
  };

  return (
    <ThemeContext.Extend value={themeDropdown}>
      <Box margin={props.margin}>
        {props.label.length > 0 && (
          <Box pad={{ bottom: '4px' }}>
            <Text size="14px">{props.label}</Text>
          </Box>
        )}
        <Select
          {...props.selectProps}
          emptySearchMessage={
            searchString.length === 0
              ? props.startSearchMessage || 'Search...'
              : props.emptySearchMessage || 'No matches found'
          }
          options={options}
          searchPlaceholder="Search..."
          onSearch={(searchString) => startSearch(searchString)}
          onChange={onChange}
          open={selectOpenState}
          onOpen={() => setSelectOpenState(true)}
          // eslint-disable-next-line react/no-children-prop
          children={(option) => {
            let component: JSX.Element;

            if (props.getOptionLabel) {
              component = <Box margin={{ horizontal: '8px' }}>{props.getOptionLabel(option)}</Box>;
            } else if (
              props.selectProps.labelKey &&
              typeof props.selectProps.labelKey !== 'function'
            ) {
              component = (
                <Text margin={{ horizontal: '8px' }}>{option[props.selectProps.labelKey]}</Text>
              );
            } else {
              component = <Text margin={{ horizontal: '8px' }}>{option}</Text>;
            }

            if (loading) {
              component = <LoadingPanel showLoader={true} />;
            }
            if (errors?.length > 0) {
              component = <Text>{errors[0]}</Text>;
            }
            if (option === NO_OPTION_FOUND_KEY) {
              component = <Text margin={{ horizontal: '8px' }}>{props.noOptionFoundLabel}</Text>;
            }

            return <Box>{component}</Box>;
          }}
          aria-label={props.ariaLabel || ''}
        />
      </Box>
    </ThemeContext.Extend>
  );
};

export default SearchDropdownComponent;
