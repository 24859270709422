import { useContext } from 'react';

import MobileDeviceContext from './MobileDeviceContext';

const useMobileDevice = (): MobileDeviceContext => {
  const context = useContext(MobileDeviceContext);
  if (context === null) {
    throw new Error('useMobileDevice must be used inside a MobileDeviceContext.Provider');
  }
  return context;
};

export default useMobileDevice;
