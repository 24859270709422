// Generated by ts-to-zod
import { z } from 'zod';

import { catalogClinicProgramSchema } from './catalog.zod';
import {
  patientCareTeamSchema,
  patientClinicTeamSchema,
  patientPatientSchema,
} from './patient.zod';
import { usersUserSchema } from './users.zod';

export const entitySuggestedUserSchema = z.object({
  id: z.string(),
  email: z.string(),
  first: z.string(),
  middle: z.string(),
  last: z.string(),
  dosepotClinicianId: z.string(),
});

export const entitySuggestedProgramSchema = z.object({
  id: z.string(),
  programName: z.string(),
  levelOfCare: z.array(z.string()),
  createdAt: z.string(),
});

export const entitySuggestedPatientNameSchema = z.object({
  patientId: z.string(),
  first: z.string(),
  middle: z.string(),
  last: z.string(),
});

export const entitySuggestedPatientProgramSchema = z.object({
  id: z.string(),
});

export const entityGetEntitiesResponseSchema = z.lazy(() =>
  z.object({
    patients: z.array(patientPatientSchema),
    users: z.array(usersUserSchema),
    clinicProgram: z.array(catalogClinicProgramSchema),
    careTeams: z.array(patientCareTeamSchema),
    clinicTeams: z.array(patientClinicTeamSchema),
  }),
);

export const entitySuggestedCareTeamSchema = z.object({
  id: z.string(),
  patient: entitySuggestedPatientNameSchema,
  primaryClinician: entitySuggestedUserSchema,
  team: z.array(entitySuggestedUserSchema),
  createdAt: z.string(),
});

export const entitySuggestedClinicTeamSchema = z.object({
  id: z.string(),
  name: z.string(),
  patients: z.array(entitySuggestedPatientNameSchema),
  team: z.array(entitySuggestedUserSchema),
  createdAt: z.string(),
});

export const entityEntityResponseSchema = z.lazy(() =>
  z.object({
    patients: z.array(patientPatientSchema),
    users: z.array(entitySuggestedUserSchema),
    clinicProgram: z.array(entitySuggestedProgramSchema),
    careTeams: z.array(entitySuggestedCareTeamSchema),
    clinicTeams: z.array(entitySuggestedClinicTeamSchema),
  }),
);
