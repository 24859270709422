export const FIELD_TYPES: Record<string, Forms.FieldType> = {
  // Update form-builder.cy.ts custom section test accordingly with new/updated types
  TEXT: 'text',
  PARAGRAPH: 'text_paragraph',
  TEXT_DISPLAY_ONLY: 'text_display_only',
  NUMBER: 'number_float',
  DATE: 'date',
  SINGLE_CHOICE_RADIO: 'single_choice_radio',
  SINGLE_CHOICE_DROPDOWN: 'single_choice_dropdown',
  SINGLE_CHOICE_SCALE: 'single_choice_scale',
  MULTI_CHOICE_CHECKBOX: 'multi_choice_checkbox',
  MULTI_CHOICE_DROPDOWN: 'multi_choice_dropdown',
  GRID_MATRIX_TEXT: 'grid_matrix_text',
  GRID_MATRIX_CHOICE: 'grid_matrix_choice',
  GRID_MATRIX_MULTI_CHOICE: 'grid_matrix_multi_choice',
};

// This is the list of field types that will show up in the
// section builder's list of available fields to add to a section.
// Add a type to this list if there is a 'builder' component that
// maps to it.
export const FIELD_TYPE_ALLOWLIST: Forms.FieldType[] = [
  FIELD_TYPES.TEXT,
  FIELD_TYPES.TEXT_DISPLAY_ONLY,
  FIELD_TYPES.PARAGRAPH,
  FIELD_TYPES.NUMBER,
  FIELD_TYPES.DATE,
  FIELD_TYPES.SINGLE_CHOICE_SCALE,
  FIELD_TYPES.SINGLE_CHOICE_RADIO,
  FIELD_TYPES.MULTI_CHOICE_CHECKBOX,
  FIELD_TYPES.SINGLE_CHOICE_DROPDOWN,
  FIELD_TYPES.MULTI_CHOICE_DROPDOWN,
  FIELD_TYPES.GRID_MATRIX_TEXT,
  FIELD_TYPES.GRID_MATRIX_CHOICE,
  FIELD_TYPES.GRID_MATRIX_MULTI_CHOICE,
];
