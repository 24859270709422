import { PropsWithChildren } from 'react';
import { Box, BoxExtendedProps } from 'grommet';

import { COLORS } from 'styles/colors';

const InteriorBox = (props: PropsWithChildren<{} & BoxExtendedProps>) => {
  const { children, background, ...rest } = props;
  return (
    <Box round="4px" background={background ?? COLORS.darkGray200} {...rest}>
      {children}
    </Box>
  );
};

export default InteriorBox;
