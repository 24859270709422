import { useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { FaEnvelopeOpenText, FaRegTimesCircle } from 'react-icons/fa';
import { IoMdLock } from 'react-icons/io';
import { useHistory, useLocation } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';
import { yupResolver } from '@hookform/resolvers/yup';
import { Box, Grid, Heading, Image, Text } from 'grommet';
import { CircleQuestion } from 'grommet-icons';
import { object, ref, SchemaOf, string } from 'yup';

import { StandardButton } from '@ritten/ui-library/buttons';
import { EmptyStateInstructionCard } from '@ritten/ui-library/cards';
import { ErrorPanel } from '@ritten/ui-library/errors';
import RHFTextInput from '@ritten/ui-library/form-inputs/react-hook-form/RHFTextInput';
import LoadingPanel from '@ritten/ui-library/panels/LoadingPanel';

import FormSubmitWrapper from 'common/FormSubmitWrapper';
import ViewportMetaTagHandler from 'common/ViewportMetaTagHandler';
import usePublicAPI from 'external/usePublicAPI';
import { useErrorState, useLoadingState, useResponsiveDeviceSize } from 'hooks';
import { COLORS } from 'styles/colors';
import { isEmpty } from 'utils';
import { ReactComponent as RittenLogo } from '../../assets/ritten_full_color_logo.svg';
import Talk from '../../assets/talk.png';
import Zen from '../../assets/zen.png';

export const redirectToHomePage = () => {
  // This ensures that the user will be prompted to login
  // and then taken to the respective app's home page upon success
  // which avoids the user seeing an initial flash of the page
  window.location.href = window.location.origin;
};

type Flow = 'initial' | 'reset';

const passwordValidation: SchemaOf<{ password: string; confirmedPassword: string }> = object({
  password: string()
    .required('Password is required')
    .min(8, 'Password must be at least 8 characters long')
    .matches(/\d+/, 'Password must contain at least one number')
    .matches(/[A-Z]+/, 'Password must contain at least one uppercase letter')
    .matches(/[a-z]+/, 'Password must contain at least one lowercase letter')
    .matches(
      /[~=\+%\^\*\/\(\)\[\]\{\}\/!@#\$\?|]+/, //eslint-disable-line
      'Password must contain at least one special character',
    ),
  confirmedPassword: string()
    .required()
    .oneOf([ref('password'), null], 'Passwords must match'),
});

const SetPasswordPage = () => {
  const { isAuthenticated } = useAuth0();
  const { search } = useLocation();
  const { userTicketsAPI } = usePublicAPI();
  const { needsLoadingState, loading, setLoading } = useLoadingState(true);
  const { needsLoadingState: needsResendLoadingState, loading: resendLoading } =
    useLoadingState(false);
  const {
    errors: validateErrors,
    addError: addValidateError,
    clearErrors: clearValidateErrors,
  } = useErrorState();
  const {
    errors: submitErrors,
    addError: addSubmitError,
    clearErrors: clearSubmitErrors,
  } = useErrorState();
  const {
    errors: resendErrors,
    addError: addResendError,
    clearErrors: clearResendErrors,
  } = useErrorState();
  const history = useHistory();
  const { deviceSize } = useResponsiveDeviceSize();
  const { isSmallish } = deviceSize;
  const [flow, setFlow] = useState<Flow>();
  const isInitialFlow = flow === 'initial';
  const [linkIsExpired, setLinkIsExpired] = useState<boolean>(false);
  const [invitationResent, setInvitationResent] = useState<boolean>(false);
  const [ticketId, setTicketId] = useState<string>();
  const methods = useForm({
    defaultValues: {
      email: '',
      password: '',
      confirmedPassword: '',
    },
    resolver: yupResolver(passwordValidation),
  });
  const {
    formState: { isSubmitting },
    setValue,
  } = methods;

  useEffect(() => {
    // In case someone navigates here by mistake while already logged in,
    // redirect them to the home page
    if (isAuthenticated) {
      redirectToHomePage();
    }
  }, [isAuthenticated]);

  useEffect(() => {
    const query = new URLSearchParams(search);
    const flowQueryParam = query.get('flow') as Flow;
    setFlow(flowQueryParam ?? 'initial');
    const ticketQueryParam = query.get('ticket');
    if (isEmpty(ticketQueryParam)) {
      redirectToHomePage();
    } else {
      validateTicket(ticketQueryParam!);
    }
  }, [search]);

  const validateTicket = needsLoadingState(async (ticket: string) => {
    clearValidateErrors();
    try {
      const validation = await userTicketsAPI.validatePasswordTicket(ticket);
      const { email, isValid, reason } = validation;
      if (!isValid) {
        if (reason === 'SPENT') {
          // if the ticket was previously spent, just send the user to the login page
          redirectToHomePage();
        }
        // for any other reason we will show the user the expired page
        setLinkIsExpired(true);
        setTicketId(ticket);
      } else {
        setTicketId(ticket);
        setValue('email', email);
      }
    } catch (err) {
      // show error and suggest page reload
      addValidateError(err);
    }
  });

  const submitPassword = async (form) => {
    setLoading(true);
    clearSubmitErrors();
    if (ticketId) {
      try {
        await userTicketsAPI.spendPasswordTicket(ticketId, form.password);
        redirectToHomePage();
      } catch (err) {
        addSubmitError(err);
        setLoading(false);
      }
    }
  };

  const resendInvitationEmail = needsResendLoadingState(async () => {
    clearResendErrors();
    if (ticketId) {
      try {
        await userTicketsAPI.resendInvitationEmail(ticketId);
        setLinkIsExpired(false);
        setInvitationResent(true);
      } catch (err) {
        addResendError(err);
      }
    }
  });

  if (loading) {
    return <LoadingPanel showLoader />;
  }

  const renderPageContent = () => {
    if (validateErrors.length > 0) {
      return (
        <Box background={COLORS.darkGray200} fill align="center" justify="center" pad="large">
          <Box
            background={COLORS.white}
            align="center"
            justify="center"
            pad="medium"
            height="329px"
            width={{ max: '480px', width: '100%' }}
          >
            <CircleQuestion size="40px" color={COLORS.darkGray400} />
            <Heading size="24px">Something went wrong.</Heading>
            <Box gap="18px" align="center">
              <Text size="14px" color={COLORS.darkGray600}>
                Try reloading the page
              </Text>
              <StandardButton label="Reload" onClick={() => history.go(0)} />
              <Box background={COLORS.darkGray200} pad="8px">
                <Text size="11px" weight="bold" color={COLORS.darkGray600}>
                  Error: {validateErrors[0]}
                </Text>
              </Box>
            </Box>
          </Box>
        </Box>
      );
    }

    if (linkIsExpired) {
      return (
        <Box
          direction="column"
          justify="center"
          align="center"
          gap="medium"
          background={COLORS.darkGray200}
          fill
          pad="medium"
        >
          <EmptyStateInstructionCard
            title="Link Expired"
            icon={<FaRegTimesCircle color={COLORS.red400} size="40px" />}
            subTitle="Your invitation to Ritten has expired, to resend please click below."
          >
            <StandardButton
              label="Resend Invitation Email"
              onClick={resendInvitationEmail}
              disabled={resendLoading}
              isLoading={resendLoading}
            />
            {resendErrors.length > 0 && (
              <Text size="11px" weight="bold" color={COLORS.darkGray600}>
                Error: {resendErrors[0]}
              </Text>
            )}
          </EmptyStateInstructionCard>
        </Box>
      );
    }

    if (invitationResent) {
      return (
        <Box
          direction="column"
          justify="center"
          align="center"
          gap="medium"
          background={COLORS.darkGray200}
          fill
          pad="medium"
        >
          <EmptyStateInstructionCard
            title="Invitation Sent"
            icon={<FaEnvelopeOpenText color={COLORS.rittenBlue400} size="40px" />}
            subTitle="A new invitation to Ritten has been sent to your inbox. Please check your email for the new link."
          ></EmptyStateInstructionCard>
        </Box>
      );
    }

    return (
      <Grid
        rows={['100%']}
        columns={isSmallish ? ['100%', '0px', '0px'] : ['520px', '1fr', '1fr']}
        fill
      >
        <Box
          width={{ max: '375px', width: '100%' }}
          margin="0 auto"
          justify="center"
          pad={{ horizontal: '30px' }}
        >
          <Box align="center">
            <RittenLogo height="40px" width="40px" />
          </Box>
          <Text
            margin={{ top: 'medium' }}
            color={COLORS.darkGray600}
            textAlign="center"
            weight={700}
            size="24px"
          >
            {isInitialFlow ? 'Welcome to Ritten!' : 'Welcome back to Ritten!'}
          </Text>
          <Text
            color={COLORS.darkGray400}
            size="14px"
            margin={{ vertical: '16px' }}
            textAlign="center"
          >
            {isInitialFlow
              ? `You've been invited by your clinic to join and access your confidential health information through our secure platform.`
              : 'As requested, you can reset your password to access your confidential health information through our secure platform.'}
          </Text>
          <ErrorPanel errors={submitErrors} onDismiss={clearSubmitErrors} flex={false} />
          <FormProvider {...methods}>
            <FormSubmitWrapper submit={() => methods.handleSubmit(submitPassword)()}>
              <Box gap="16px" margin={{ bottom: 'medium' }}>
                <RHFTextInput
                  label="Email (Contact clinic to update)"
                  textInputProps={{
                    name: 'email',
                    disabled: true,
                    icon: <IoMdLock size="18px" color={COLORS.darkGray600} />,
                    reverse: true,
                    style: {
                      opacity: 1,
                      backgroundColor: COLORS.darkGray200,
                      color: COLORS.darkGray400,
                    },
                  }}
                />
                <RHFTextInput
                  label="Password"
                  textInputProps={{
                    name: 'password',
                    placeholder: 'Enter a password',
                    type: 'password',
                  }}
                />
                <RHFTextInput
                  label="Confirm Password"
                  textInputProps={{
                    name: 'confirmedPassword',
                    placeholder: 'Confirm your password from above',
                    type: 'password',
                  }}
                />
              </Box>
            </FormSubmitWrapper>
            <StandardButton
              disabled={isSubmitting}
              onClick={methods.handleSubmit(submitPassword)}
              label={isInitialFlow ? 'Sign Up' : 'Reset Password'}
            />
          </FormProvider>
        </Box>
        <Box>
          <Image fill src={Zen} alt="" />
        </Box>
        <Box>
          <Image fill src={Talk} alt="" />
        </Box>
      </Grid>
    );
  };

  return (
    <>
      <ViewportMetaTagHandler />
      {renderPageContent()}
    </>
  );
};

export default SetPasswordPage;
