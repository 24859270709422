// @ts-strict-ignore
import { useState } from 'react';
import { Box, Text } from 'grommet';
import styled, { css } from 'styled-components';

import ProtectedNavLink from 'features/nav/ProtectedNavLink';
import { COLORS, DROP_SHADOW } from 'styles/colors';
import { SIDE_NAV_WIDTH_CLOSED } from './NavBar';

export const styleLink: React.CSSProperties = {
  color: COLORS.white,
  textDecoration: 'none',
  height: '100%',
  width: '100%',
};

export const styleLinkActive: React.CSSProperties = {
  color: COLORS.white,
  backgroundColor: COLORS.darkGray500,
  height: '100%',
  width: '100%',
  display: 'block',
};

export const NavLinkContainer = styled.div<{ isSidebar: boolean }>`
  height: 48px;
  display: flex;
  align-items: center;
  ${({ isSidebar }) =>
    isSidebar
      ? css`
          justify-content: center;
        `
      : css`
          &:hover {
            background-color: ${COLORS.darkGray500};
          }
        `}
  cursor: pointer;
`;

export const NavLinkIconContainer = styled.div`
  width: 45px;
  &:hover {
    background-color: ${COLORS.darkGray600};
  }
`;

export const NavTip = ({ label, link }: { label: string; link: React.ReactNode }) => {
  const [showTip, setShowTip] = useState<boolean>(false);

  return (
    <div style={{ position: 'relative' }}>
      <NavLinkIconContainer
        onMouseEnter={() => setShowTip(true)}
        onMouseLeave={() => setShowTip(false)}
        onBlur={() => setShowTip(false)}
      >
        {link}
      </NavLinkIconContainer>
      {showTip && (
        <div style={{ position: 'absolute', top: 10, left: 50 }}>
          <Box
            align="center"
            pad="4px 12px"
            margin={{ left: '4px' }}
            round="12px"
            width={showTip ? { min: 'max-content' } : 'inherit'}
            background={COLORS.darkGray600}
            style={{ boxShadow: DROP_SHADOW }}
          >
            <Text size="15px" color={COLORS.white}>
              {label}
            </Text>
          </Box>
        </div>
      )}
    </div>
  );
};

export const StyledNavLink = (props: {
  to: string;
  label: string;
  userNotificationsCount?: number;
  icon: JSX.Element;
}) => {
  const { label } = props;

  return <NavTip label={label} link={<StyledMenuNavLink {...props} hideLabel />} />;
};

export const StyledMenuNavLink = (props: {
  to: string;
  label: string;
  userNotificationsCount?: number;
  icon: JSX.Element;
  hideLabel?: boolean;
}) => {
  const { to, label, userNotificationsCount, icon, hideLabel = false } = props;

  const isNotificationLink = to === 'compliance';

  return (
    <ProtectedNavLink
      to={`/${to}`}
      style={styleLink}
      activeStyle={styleLinkActive}
      data-testid={label}
    >
      <NavLinkContainer isSidebar={false}>
        <Box fill direction="row" align="center">
          <Box direction="row" flex={{ grow: 0, shrink: 0 }}>
            <Box
              width={SIDE_NAV_WIDTH_CLOSED}
              height="100%"
              align="center"
              justify="center"
              style={{ position: 'relative' }}
            >
              {icon}
              {/* Red dot next to notifications link */}
              {isNotificationLink && userNotificationsCount > 0 && (
                <div
                  data-testid="side-bar-notification-indicator"
                  style={{
                    width: '5px',
                    height: '5px',
                    backgroundColor: COLORS.red400,
                    borderRadius: '5px',
                    position: 'absolute',
                    top: 0,
                    right: 8,
                  }}
                />
              )}
            </Box>
            {!hideLabel && (
              <Text size="small" weight="normal">
                {label}
              </Text>
            )}
          </Box>
        </Box>
      </NavLinkContainer>
    </ProtectedNavLink>
  );
};
