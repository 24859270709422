import { COLORS } from 'styles/colors';

interface RittenBannerProps {
  height?: string;
}

const RittenBanner = (props: RittenBannerProps) => {
  return (
    <svg
      height={props.height ? props.height : '20px'}
      viewBox="0 0 534 151"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M239.85 119.8L220.12 84.95H211.66V119.8H193.72V30.36H227.29C234.21 30.36 240.1 31.58 244.97 34.01C249.84 36.44 253.49 39.73 255.93 43.87C258.36 48.01 259.58 52.65 259.58 57.77C259.58 63.66 257.87 68.98 254.46 73.72C251.04 78.46 245.96 81.73 239.21 83.52L260.61 119.78H239.85V119.8ZM211.66 71.49H226.65C231.52 71.49 235.15 70.32 237.54 67.97C239.93 65.62 241.13 62.35 241.13 58.17C241.13 54.07 239.93 50.89 237.54 48.62C235.15 46.36 231.52 45.23 226.65 45.23H211.66V71.49Z"
        fill="white"
      />
      <path
        d="M270.66 37.34C268.57 35.33 267.52 32.84 267.52 29.84C267.52 26.85 268.56 24.35 270.66 22.34C272.75 20.33 275.38 19.33 278.54 19.33C281.7 19.33 284.33 20.33 286.42 22.34C288.51 24.35 289.56 26.85 289.56 29.84C289.56 32.83 288.51 35.33 286.42 37.34C284.33 39.35 281.7 40.35 278.54 40.35C275.38 40.36 272.75 39.35 270.66 37.34ZM287.38 48.81V119.8H269.44V48.81H287.38Z"
        fill="white"
      />
      <path
        d="M322.11 63.55V97.89C322.11 100.28 322.69 102.01 323.84 103.08C324.99 104.15 326.94 104.68 329.67 104.68H338V119.8H326.72C311.6 119.8 304.04 112.45 304.04 97.76V63.55H295.58V48.81H304.04V31.26H322.11V48.81H338V63.55H322.11Z"
        fill="white"
      />
      <path
        d="M367.97 63.55V97.89C367.97 100.28 368.55 102.01 369.7 103.08C370.85 104.15 372.8 104.68 375.53 104.68H383.86V119.8H372.58C357.46 119.8 349.9 112.45 349.9 97.76V63.55H341.44V48.81H349.9V31.26H367.97V48.81H383.86V63.55H367.97Z"
        fill="white"
      />
      <path
        d="M458.43 89.69H406.54C406.97 94.82 408.76 98.83 411.92 101.73C415.08 104.64 418.97 106.09 423.58 106.09C430.24 106.09 434.98 103.23 437.8 97.5H457.15C455.1 104.34 451.17 109.95 445.36 114.35C439.55 118.75 432.42 120.95 423.96 120.95C417.12 120.95 411 119.43 405.57 116.4C400.14 113.37 395.91 109.08 392.89 103.52C389.86 97.97 388.34 91.56 388.34 84.3C388.34 76.95 389.83 70.51 392.83 64.95C395.82 59.4 400 55.13 405.39 52.14C410.77 49.15 416.96 47.66 423.97 47.66C430.72 47.66 436.76 49.11 442.1 52.02C447.44 54.93 451.58 59.05 454.53 64.39C457.48 69.73 458.95 75.86 458.95 82.78C458.95 85.33 458.77 87.64 458.43 89.69ZM440.37 77.64C440.28 73.03 438.62 69.33 435.37 66.56C432.12 63.79 428.15 62.4 423.45 62.4C419.01 62.4 415.27 63.75 412.24 66.44C409.21 69.13 407.35 72.87 406.67 77.65H440.37V77.64Z"
        fill="white"
      />
      <path
        d="M525.45 55.8C530.66 61.14 533.27 68.59 533.27 78.16V119.8H515.33V80.59C515.33 74.95 513.92 70.62 511.1 67.59C508.28 64.56 504.44 63.04 499.57 63.04C494.61 63.04 490.71 64.56 487.85 67.59C484.99 70.62 483.56 74.96 483.56 80.59V119.8H465.62V48.81H483.56V57.65C485.95 54.57 489.01 52.16 492.72 50.41C496.43 48.66 500.51 47.78 504.96 47.78C513.4 47.79 520.23 50.46 525.45 55.8Z"
        fill="white"
      />
      <path
        d="M91.22 59.64H120.93V29.93H91.21V0.229996H67.53C63.48 0.229996 60.2 3.51 60.2 7.56V29.92H91.21V59.64H91.22Z"
        fill={COLORS.rittenDarkBlue}
      />
      <path d="M120.94 29.92L91.22 0.219986V29.92H120.94Z" fill={COLORS.rittenTeal} />
      <path
        d="M60.21 59.64V29.93H30.5V59.65H0.799988V83.33C0.799988 87.38 4.07998 90.66 8.12998 90.66H30.49V59.65H60.21V59.64Z"
        fill={COLORS.rittenDarkBlue}
      />
      <path d="M30.5 29.92L0.799988 59.64H30.5V29.92Z" fill={COLORS.rittenTeal} />
      <path
        d="M60.21 90.64H30.5V120.35H60.22V150.05H83.9C87.95 150.05 91.23 146.77 91.23 142.72V120.36H60.22V90.64H60.21Z"
        fill={COLORS.rittenDarkBlue}
      />
      <path d="M30.5 120.36L60.21 150.06V120.36H30.5Z" fill={COLORS.rittenTeal} />
      <path
        d="M91.22 90.64V120.35H120.93V90.63H150.63V66.95C150.63 62.9 147.35 59.62 143.3 59.62H120.94V90.63H91.22V90.64Z"
        fill={COLORS.rittenDarkBlue}
      />
      <path d="M120.94 120.36L150.64 90.64H120.94V120.36Z" fill={COLORS.rittenTeal} />
      <path d="M91.22 29.92H60.22V120.37H91.22V29.92Z" fill="white" />
      <path d="M120.94 59.47H30.51V90.64H120.94V59.47Z" fill="white" />
    </svg>
  );
};

export default RittenBanner;
