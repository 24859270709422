import { AxiosResponseHeaders } from 'axios';

import { getFileNameFromHeaders } from 'utils';
import RittenClient from './RittenClient';

export default class UserClient extends RittenClient {
  getAllStaffUsers = async (): Promise<Users.FullUser[]> => {
    const res = await this.get<Users.FullUser[]>(`/api/users`, {
      params: { staffUsersOnly: true },
    });
    return res.data;
  };

  getUserById = async (id: string): Promise<Users.FullUser> => {
    const res = await this.get<Users.FullUser>(`/api/users/${id}`);
    return res.data;
  };

  getClinicTeamsByUserId = async (id: string): Promise<Patient.ClinicTeam[]> => {
    const res = await this.get<Patient.ClinicTeam[]>(`/api/users/${id}/clinic-teams`);
    return res.data;
  };

  getCurrentUser = async (): Promise<Users.FullUser> => {
    const res = await this.get<Users.FullUser>('/api/user/me');
    return res.data;
  };

  getNotificationCounts = async () => {
    const result = await this.get<{ totalNotificationCount: number }>(
      `/api/user/me/notification-counts`,
    );
    return result.data?.totalNotificationCount;
  };

  getPortalAuthorizations = async (): Promise<Users.PortalAuthorization[]> => {
    const res = await this.get<Users.PortalAuthorization[]>('/api/user/me/portal-authorizations');
    return res.data;
  };

  updateCurrentUser = async (body: Users.UpdateUserPostBody): Promise<Users.User> => {
    const res = await this.patch<Users.User>('/api/user/me', body);
    return res.data;
  };

  postUser = async (body: Users.CreateUserPostBody): Promise<Users.PostUserResponse> => {
    const res = await this.post<Users.PostUserResponse>('/api/users', body);
    return res.data;
  };

  patchUser = async (
    id: string,
    body: Users.PatchUserPostBody,
  ): Promise<Users.PostUserResponse> => {
    const res = await this.patch<Users.PostUserResponse>(`/api/users/${id}`, body);
    return res.data;
  };

  deleteUser = async (id: string): Promise<void> => {
    await this.delete<void>(`/api/users/${id}`);
  };

  listRoles = async (): Promise<Users.Role[]> => {
    const res = await this.get<Users.Role[]>('/api/roles');
    return res.data;
  };

  postRole = async (body: Users.RolePostBody): Promise<API.IDResponse> => {
    const res = await this.post<API.IDResponse>('/api/roles', body);
    return res.data;
  };

  deleteRole = async (id: string): Promise<void> => {
    await this.delete<void>(`/api/roles/${id}`);
  };

  getUserRoles = async (userID: string): Promise<Users.Role[]> => {
    const res = await this.get<Users.Role[]>(`/api/users/${userID}/roles`);
    return res.data;
  };

  assignUserToRole = async (userID: string, roleID: string): Promise<void> => {
    await this.post(`/api/users/${userID}/roles`, { userId: userID, roleId: roleID });
  };

  getAllUsersWithRole = async (roleId: string): Promise<Users.User[]> => {
    const res = await this.get<Users.User[]>(`/api/roles/${roleId}/users`);
    return res.data;
  };

  removeRoleFromUser = async (userId: string, roleId: string): Promise<void> => {
    await this.delete(`/api/users/${userId}/roles/${roleId}`);
  };

  getAllClinicTeams = async (query?: Users.GetClinicTeamsQuery): Promise<Patient.ClinicTeam[]> => {
    const res = await this.get<Patient.ClinicTeam[]>(`/api/clinic/teams`, {
      params: query,
    });
    return res.data;
  };

  createClinicTeam = async ({
    id,
    name,
  }: {
    id?: string;
    name: string;
  }): Promise<API.IDResponse> => {
    const res = await this.post<API.IDResponse>(`/api/clinic/teams`, { id, name });
    return res.data;
  };

  getClinicTeam = async (teamId: string): Promise<Patient.ClinicTeam> => {
    const res = await this.get<Patient.ClinicTeam>(`/api/clinic/teams/${teamId}`);
    return res.data;
  };

  deleteClinicTeam = async (teamId: string): Promise<void> => {
    await this.delete(`/api/clinic/teams/${teamId}`);
  };

  assignUserToTeam = async (teamId: string, userId: string): Promise<void> => {
    await this.post(`/api/clinic/teams/${teamId}/users/${userId}`);
  };

  removeUserFromTeam = async (teamId: string, userId: string): Promise<void> => {
    await this.delete(`/api/clinic/teams/${teamId}/users/${userId}`);
  };

  addUserToPermissionGroup = async (groupId: string, userId: string): Promise<void> => {
    await this.post(`/api/users/${userId}/groups/${groupId}`);
  };

  getUserPermissionGroups = async (userId: string): Promise<Security.SecurityGroup[]> => {
    const res = await this.get<Security.SecurityGroup[]>(`/api/users/${userId}/groups`);
    return res.data;
  };

  removeUserFromPermissionGroup = async (groupId: string, userId: string): Promise<void> => {
    await this.delete(`/api/users/${userId}/groups/${groupId}`);
  };

  getUserPermissionsCsv = async () => {
    const params: any = {
      csv: true,
    };
    const res = await this.get<Blob>(`api/users/permissions`, {
      params,
      responseType: 'blob',
    });
    const headers = res.headers as AxiosResponseHeaders;
    const fileName = getFileNameFromHeaders(headers['content-disposition']);
    const result = { fileName, file: res.data };
    return result;
  };

  getUserBillingProvider = async (userId: string): Promise<Clinic.Provider> => {
    const res = await this.get<Clinic.Provider>(`/api/users/${userId}/billing-provider`);
    return res.data;
  };

  /**
   * Either sets or updates the billing provider (clinic provider) for a user ID.
   */
  patchUserBillingProvider = async (userId: string, clinicProviderId: string): Promise<void> => {
    await this.patch(`/api/users/${userId}/billing-provider`, {
      id: clinicProviderId,
    });
  };

  /**
   * Deletes the billing provider (clinic provider) for a user ID.
   */
  deleteUserBillingProvider = async (userId: string): Promise<void> => {
    await this.delete(`/api/users/${userId}/billing-provider`);
  };

  isUserAuthorizedWithZoom = async (userId: string): Promise<boolean> => {
    const res = await this.get<boolean>(`/api/users/${userId}/zoom/authorized`);
    return res.data;
  };

  authenticateWithZoom = async (userId: string) => {
    const res = await this.get<API.OAuth>(`/api/users/${userId}/zoom/connect`);
    return res.data;
  };

  disconnectZoomUser = async (userId: string): Promise<void> => {
    await this.delete(`/api/users/${userId}/zoom/disconnect`);
  };

  getAllZoomHosts = async (): Promise<Users.User[]> => {
    const res = await this.get<Users.User[]>('/api/users/zoom');
    return res.data;
  };

  resendInvite = async (id: string): Promise<void> => {
    await this.post(`/api/users/${id}/resend-invite`);
  };

  getOrderingProviders = async (): Promise<Users.FullUser[]> => {
    const { data } = await this.get<Users.FullUser[]>('api/users/ordering-providers');
    return data;
  };

  getUserWorkingHours = async (id: string): Promise<Users.WorkingHours> => {
    const res = await this.get<Users.WorkingHours>(`/api/users/${id}/working-hours`);
    return res.data;
  };

  postUserWorkingHours = async (id: string, body: Users.PostWorkingHoursRequest): Promise<void> => {
    await this.post<void>(`/api/users/${id}/working-hours`, body);
  };

  getUserNotificationSubscriptions = async (
    userId: string,
    params?: { subscriptionId?: string; eventType?: string },
  ): Promise<Users.NotificationSubscription[]> => {
    const res = await this.get<Users.NotificationSubscription[]>(
      `/api/users/${userId}/notifications/subscriptions`,
      { params },
    );
    return res.data;
  };

  putUserNotificationSubscription = async (
    userId: string,
    subscriptionId: string,
    body: {
      eventType: Users.NotificationEventType;
      channels: Users.NotificationChannel[];
      inactive?: boolean;
    },
  ): Promise<void> => {
    await this.put<void>(
      `/api/users/${userId}/notifications/subscriptions/${subscriptionId}`,
      body,
    );
  };

  listCredentials = async (): Promise<Users.Credential[]> => {
    const res = await this.get<Users.Credential[]>('/api/credentials');
    return res.data;
  };

  putCredentials = async (body: Users.CredentialPutBody[]): Promise<API.IDResponse> => {
    const res = await this.put<API.IDResponse>('/api/credentials', body);
    return res.data;
  };
}
