import { Link } from 'react-router-dom';
import { Box, Text } from 'grommet';
import { AlignType, BorderType, JustifyContentType, PadType } from 'grommet/utils';

import { COLORS } from '../../styles/colors';

interface AnchorLinkProps {
  linkToUrl: string;
  openInNewTab?: boolean;
  label?: string;
  textSize?: string;
  children?: string;
  icon?: React.ReactNode;
  color?: string;
  noGap?: boolean;
  padding?: PadType;
  justify?: JustifyContentType;
  align?: AlignType;
  border?: BorderType;
  ref?: React.LegacyRef<HTMLButtonElement>;
  showHyperLinkStyle?: boolean;
  iconPosition?: 'before' | 'after';
  disabled?: boolean;
  textWeight?: 'bold' | 'normal' | number;
  'data-testid'?: string;
}

export const AnchorLink: React.FC<AnchorLinkProps> = (props: AnchorLinkProps) => {
  const {
    linkToUrl,
    openInNewTab = false,
    color = COLORS.rittenBlue400,
    padding,
    align = 'center',
    border,
    icon,
    label,
    children,
    textSize = '16px',
    justify = 'start',
    showHyperLinkStyle = false,
    iconPosition = 'before',
    noGap,
    textWeight = 'normal',
  } = props;

  const showIconBeforeText = icon && iconPosition === 'before';
  const showIconAfterText = icon && iconPosition === 'after';

  return (
    <Link
      to={linkToUrl}
      style={{ textDecoration: 'none' }}
      target={openInNewTab ? '_blank' : '_self'}
    >
      <Box
        pad={padding || 'small'}
        direction="row"
        align={align}
        justify={justify}
        gap={noGap ? '' : '10px'}
        border={border || undefined}
        data-testid={props['data-testid']}
        height="100%"
      >
        {showIconBeforeText && <Box color={color}>{icon}</Box>}
        {(label || children) && (
          <Text
            weight={textWeight}
            size={textSize}
            color={color}
            style={{
              borderBottom: showHyperLinkStyle ? `1px solid ${color}` : 'none',
            }}
          >
            {label} {children}
          </Text>
        )}
        {showIconAfterText && <Box color={color}>{icon}</Box>}
      </Box>
    </Link>
  );
};

export default AnchorLink;
