// @ts-strict-ignore
import { Box, InfiniteScroll, Text } from 'grommet';
import { FormClose, Search } from 'grommet-icons';

import { StandardButton } from '@ritten/ui-library/buttons';
import { TextInputComponent } from '@ritten/ui-library/form-inputs';
import LoadingPanel from '@ritten/ui-library/panels/LoadingPanel';

import { COLORS } from 'styles/colors';
import { isEmpty } from 'utils';
import { DropdownChoice, DropdownProps, getChoiceLabel } from '../Dropdown';
import ChoiceLabel from './ChoiceLabel';

interface DropContentProps extends DropdownProps {
  filterQuery: string;
  onChangeSearchQuery: (searchQuery: string) => void;
  onClearSearchQueryClick: () => void;
  loading: boolean;
  filteredChoices: DropdownChoice[];
  onSelectChoice: (choice: DropdownChoice) => void;
  onNoOptionsFoundClick: () => void;
  noOptionsMessage?: string;
  choiceLabelKey?: string;
  choiceValueKey?: string;
  clearInput?: boolean;
  onClear?: () => void;
  staticDropContent?: JSX.Element;
}

const DropContent = (props: DropContentProps) => {
  const {
    variant = 'single-choice',
    choices,
    multipleChoiceSelections,
    disabled = false,
    customizationProps = {},
    searchProps = {},
    filterQuery,
    onChangeSearchQuery,
    onClearSearchQueryClick,
    loading,
    filteredChoices,
    onSelectChoice,
    onNoOptionsFoundClick,
    noOptionsMessage,
    choiceLabelKey,
    choiceValueKey,
    clearInput,
    onClear,
    infiniteScrollProps,
    staticDropContent,
  } = props;
  const { renderChoice, dropWidth } = customizationProps;
  const {
    searchPlaceholder,
    hideSearch,
    forceNoOptionsMessageOnSearch = false,
    hideNoOptionsMessageUntilSearch = false,
    focusOnOpen = true,
    minimumSearchCharactersForNoOptionsMessage,
  } = searchProps;

  const shouldShowNoOptionsMessage = (): boolean => {
    if (
      isEmpty(filterQuery) ||
      (minimumSearchCharactersForNoOptionsMessage !== undefined &&
        filterQuery.length < minimumSearchCharactersForNoOptionsMessage)
    ) {
      // if force Search, return false because we want to make them search first
      // if minimumSearchCharactersForNoOptionsMessage is set, then they must have typed that num of chars
      // in order to "start the search"
      if (hideNoOptionsMessageUntilSearch) {
        return false;
      }
      // if forceSearch is false, only show the button if there are no options available
      return filteredChoices.length === 0;
    }

    // if they have searched, forceNoOptionsMessageOnSearch should always show the button if true
    if (forceNoOptionsMessageOnSearch) {
      return true;
    }
    // if not forcing message on search, we only want to show the button if their query yields no results
    return filteredChoices.length === 0;
  };

  return (
    <Box data-testid="dropdown-drop-content">
      {!hideSearch && (
        <Box
          pad="8px"
          width="100%"
          height={{ min: 'fit-content' }}
          style={{ position: 'relative' }}
        >
          <TextInputComponent
            data-testid="dropdown-search-input"
            textInputProps={{
              value: filterQuery,
              onChange: ({ target }) => {
                onChangeSearchQuery(target.value);
              },
              placeholder: searchPlaceholder || 'Search...',
              icon: <Search color={COLORS.darkGray400} size="16px" />,
              disabled,
              style: { paddingRight: '28px' }, // need for FormClose below
            }}
            autoFocus={focusOnOpen}
          />
          {filterQuery?.length > 0 && (
            <div style={{ position: 'absolute', right: 12, top: 16 }}>
              <FormClose
                color={COLORS.darkGray600}
                onClick={onClearSearchQueryClick}
                style={{ cursor: 'pointer' }}
              />
            </div>
          )}
        </Box>
      )}
      <Box
        height={{ max: '280px' }}
        width={dropWidth || { min: '200px' }}
        overflow="auto"
        pad={{ bottom: '8px' }}
      >
        {loading ? (
          <LoadingPanel showLoader />
        ) : (
          <>
            {clearInput && (
              <ChoiceLabel
                key={'clear'}
                renderChoice={renderChoice}
                variant={variant}
                choice={'Clear'}
                choices={null}
                onSelectChoice={onClear}
                choiceLabelKey={choiceLabelKey}
                choiceValueKey={choiceValueKey}
                style={{ paddingRight: '5px', color: COLORS.darkGray400 }}
              />
            )}
            {infiniteScrollProps ? (
              <>
                <InfiniteScroll
                  items={filteredChoices}
                  step={infiniteScrollProps?.step}
                  onMore={infiniteScrollProps?.onMore}
                >
                  {(choice, index) => {
                    const choiceLabel = getChoiceLabel(choice, choiceLabelKey);
                    return (
                      <ChoiceLabel
                        key={`${choiceLabel}-${index}`}
                        renderChoice={renderChoice}
                        variant={variant}
                        choice={choice}
                        choices={choices}
                        multipleChoiceSelections={multipleChoiceSelections}
                        onSelectChoice={onSelectChoice}
                        choiceLabelKey={choiceLabelKey}
                        choiceValueKey={choiceValueKey}
                      />
                    );
                  }}
                </InfiniteScroll>
                {infiniteScrollProps?.loadingMore && <LoadingPanel showLoader />}
              </>
            ) : (
              filteredChoices.map((choice, index) => {
                const choiceLabel = getChoiceLabel(choice, choiceLabelKey);
                return (
                  <ChoiceLabel
                    key={`${choiceLabel}-${index}`}
                    renderChoice={renderChoice}
                    variant={variant}
                    choice={choice}
                    choices={choices}
                    multipleChoiceSelections={multipleChoiceSelections}
                    onSelectChoice={onSelectChoice}
                    choiceLabelKey={choiceLabelKey}
                    choiceValueKey={choiceValueKey}
                  />
                );
              })
            )}
            {shouldShowNoOptionsMessage() &&
              (noOptionsMessage ? (
                <Box align="center" justify="center" pad="4px" flex={false}>
                  <StandardButton
                    fullWidth
                    label={noOptionsMessage}
                    onClick={onNoOptionsFoundClick}
                  />
                </Box>
              ) : (
                <Box pad="8px" onClick={onNoOptionsFoundClick} flex={false}>
                  <Text size="16px">No options found</Text>
                </Box>
              ))}
          </>
        )}
      </Box>
      {staticDropContent && staticDropContent}
    </Box>
  );
};

export default DropContent;
