import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { Box, Heading, Text } from 'grommet';
import { CircleQuestion } from 'grommet-icons';

import { StandardButton } from '@ritten/ui-library/buttons';

import { useResponsiveDeviceSize } from 'hooks';
import { COLORS } from 'styles/colors';

const ErrorPage = (props) => {
  const { error, resetError } = props;
  const history = useHistory();
  const { deviceSize } = useResponsiveDeviceSize();

  // Sentry's ErrorBoundary requires you to resetError to go back to original app/client state.
  // This ensures we can go back/forth to other routes without getting stuck on the ErrorPage rendering.
  useEffect(() => {
    window.onhashchange = () => {
      resetError();
    };

    return () => {
      window.onhashchange = () => {};
    };
  }, []);

  const onNavigateBack = () => {
    history.goBack();
  };

  return (
    <Box background={COLORS.darkGray200} fill align="center" pad={{ vertical: 'large' }}>
      <Box
        background={COLORS.white}
        align="center"
        justify="center"
        pad="medium"
        height="329px"
        width={deviceSize.set({ L: '480px', S: '80%' })}
      >
        <CircleQuestion size="40px" color={COLORS.darkGray400} />
        <Heading size="24px">Something went wrong.</Heading>
        <Box gap="18px" align="center">
          <Text size="14px" color={COLORS.darkGray400}>
            Try navigating back to where you were
          </Text>
          <StandardButton label="Go back" onClick={onNavigateBack} />
          {error?.message && (
            <Box background={COLORS.darkGray200} pad="8px">
              <Text size="11px" weight="bold" color={COLORS.darkGray600}>
                Error: {error.message}
              </Text>
            </Box>
          )}
        </Box>
      </Box>
    </Box>
  );
};

export default ErrorPage;
