import { useRef, useState } from 'react';
import { BsGear, BsPersonFill } from 'react-icons/bs';
import { MdInsights } from 'react-icons/md';
import { RiContactsBookLine, RiCustomerServiceFill } from 'react-icons/ri';
import { useHistory } from 'react-router-dom';
import { Box, BoxProps, Drop, Nav, Text } from 'grommet';
import {
  Add,
  Calendar,
  CircleQuestion,
  Compliance,
  DownloadOption,
  Group,
  HomeRounded,
  Logout,
  Money,
} from 'grommet-icons';

import AlertCount from '@ritten/ui-library/labels/AlertCount';
import LineBreak from '@ritten/ui-library/line-break/LineBreak';

import FeatureFlag from 'common/feature-flags/FeatureFlag';
import { FeatureFlagLabel } from 'common/feature-flags/featureFlags';
import UserProfileNavItem from 'features/nav/UserProfileNavItem';
import { COLORS } from 'styles/colors';
import { getFullName } from 'utils';
import { NavLinkContainer, NavTip, StyledNavLink } from './NavLink';
import { renderDownloadsCount } from './utils';

export interface SidebarNavLinksProps {
  isCrmFeatureFlagOn: boolean;
  userNotificationsCount: number;
  downloadsCount: number;
  logout: ({ returnTo }) => void;
  onOpenDownloadsModal: () => void;
  user: Users.FullUser;
  dosespotNotificationsCount: number | null;
  setIsCreateTaskModalOpen: (isOpen: boolean) => void;
}

const SidebarNavLinks = (props: SidebarNavLinksProps) => {
  const {
    isCrmFeatureFlagOn,
    userNotificationsCount,
    downloadsCount,
    logout,
    onOpenDownloadsModal,
    user,
    dosespotNotificationsCount,
    setIsCreateTaskModalOpen,
  } = props;

  const history = useHistory();
  const dropAnchorRef = useRef(null);

  const [showDrop, setShowDrop] = useState(false);
  const [isHover, setIsHover] = useState<boolean>(false);

  const dropItemContainerProps: BoxProps = {
    direction: 'row',
    align: 'center',
    gap: '8px',
    hoverIndicator: COLORS.darkGray500,
    pad: '4px 12px',
  };

  return (
    <Box style={{ width: `${isHover ? '100vw' : '45px'}` }}>
      <Box fill overflow={{ vertical: 'scroll' }} className="no-scrollbar">
        <Box
          width="45px"
          height="100%"
          justify="between"
          onMouseOver={() => setIsHover(true)}
          onMouseOut={() => setIsHover(false)}
        >
          <Nav gap="none">
            <StyledNavLink
              to="home"
              label="Home"
              icon={<HomeRounded size="18px" color={COLORS.white} />}
            />
            <StyledNavLink
              to="crm"
              label={isCrmFeatureFlagOn ? 'Admissions' : 'Contacts'}
              icon={<RiContactsBookLine size="20px" color={COLORS.white} />}
            />
            <StyledNavLink
              to="records"
              label="Records"
              icon={<Group size="18px" color={COLORS.white} />}
            />
            <StyledNavLink
              to="calendar"
              label="Calendar"
              icon={<Calendar size="18px" color={COLORS.white} />}
            />
            <StyledNavLink
              to="insights"
              label="Insights"
              icon={<MdInsights size="18px" color={COLORS.white} />}
            />
            <FeatureFlag label={FeatureFlagLabel.BILLING}>
              <StyledNavLink
                to="billing"
                label="Billing"
                icon={<Money size="18px" color={COLORS.white} />}
              />
            </FeatureFlag>
            <FeatureFlag label={FeatureFlagLabel.SIGNATURE_ACTIONS}>
              <StyledNavLink
                to="compliance"
                label="Compliance"
                userNotificationsCount={userNotificationsCount}
                icon={<Compliance size="18px" color={COLORS.white} />}
              />
            </FeatureFlag>
            <StyledNavLink
              to="admin"
              label="Configurations"
              icon={<BsGear size="18px" color={COLORS.white} />}
            />
          </Nav>

          <Nav gap="none">
            <NavTip
              label="Create Task"
              link={
                <NavLinkContainer isSidebar={false} onClick={() => setIsCreateTaskModalOpen(true)}>
                  <Box pad="0 10px">
                    <Box background={COLORS.rittenBlue400} pad="5px" round="4px">
                      <Add size="14px" color={COLORS.white} />
                    </Box>
                  </Box>
                </NavLinkContainer>
              }
            />
            <NavTip
              label={getFullName(user)}
              link={
                <NavLinkContainer isSidebar={false}>
                  {showDrop && (
                    <Drop
                      target={dropAnchorRef.current ?? undefined}
                      align={{ left: 'right', bottom: 'bottom' }}
                      onEsc={() => setShowDrop(false)}
                      onClickOutside={() => setShowDrop(false)}
                      margin="0 8px"
                    >
                      <Box pad="4px 0" background={COLORS.darkGray600}>
                        <Box>
                          <Box
                            onClick={() => {
                              onOpenDownloadsModal();
                              setShowDrop(false);
                              setIsHover(false);
                            }}
                            {...dropItemContainerProps}
                          >
                            <DownloadOption size="16px" />
                            <Text color={COLORS.white} size="14px" weight={700}>
                              Downloads
                            </Text>
                            {renderDownloadsCount(downloadsCount, 'inline')}
                          </Box>
                          <Box
                            onClick={() => {
                              setShowDrop(false);
                              setIsHover(false);
                              history.push('/user');
                            }}
                            {...dropItemContainerProps}
                            data-testid="user-profile"
                          >
                            <Box
                              round="full"
                              border={{ size: '1px', style: 'solid', color: COLORS.white }}
                              pad="2px"
                            >
                              <BsPersonFill size="10px" color={COLORS.white} />
                            </Box>
                            <Text color={COLORS.white} size="14px" weight={700}>
                              User
                            </Text>
                            {dosespotNotificationsCount !== null && (
                              <AlertCount
                                count={dosespotNotificationsCount}
                                hideIfZero
                                data-testid="user-notification-count"
                                size="19px"
                                fontSize="11px"
                              />
                            )}
                          </Box>
                          <Box
                            onClick={() => {
                              setShowDrop(false);
                              window.open('https://ritten.zendesk.com/hc/en-us');
                            }}
                            {...dropItemContainerProps}
                            data-testid="support-center"
                          >
                            <CircleQuestion size="16px" />
                            <Text color={COLORS.white} size="14px" weight={700}>
                              Support Center
                            </Text>
                          </Box>
                          <Box
                            onClick={() => {
                              setShowDrop(false);
                              window.open('https://ritten.zendesk.com/hc/en-us/requests/new');
                            }}
                            {...dropItemContainerProps}
                          >
                            <Box
                              round="full"
                              border={{ size: '1px', style: 'solid', color: COLORS.white }}
                              pad="2px"
                            >
                              <RiCustomerServiceFill color={COLORS.white} size="10px" />
                            </Box>
                            <Text color={COLORS.white} size="14px" weight={700}>
                              Submit a Ticket
                            </Text>
                          </Box>
                          <LineBreak color={COLORS.darkGray400} height="1px" />
                          <Box
                            onClick={() => {
                              setShowDrop(false);
                              logout({ returnTo: window.location.href });
                            }}
                            {...dropItemContainerProps}
                            data-testid="log-out"
                          >
                            <Logout size="16px" color={COLORS.red400} />
                            <Text color={COLORS.red400} size="14px" weight={700}>
                              Log Out
                            </Text>
                          </Box>
                        </Box>
                      </Box>
                    </Drop>
                  )}
                  <Box onClick={() => setShowDrop(!showDrop)} ref={dropAnchorRef}>
                    <div style={{ position: 'relative' }}>
                      <UserProfileNavItem hideName containerHeight="26px" />
                      {renderDownloadsCount(downloadsCount, 'badge')}
                    </div>
                  </Box>
                </NavLinkContainer>
              }
            />
          </Nav>
        </Box>
      </Box>
    </Box>
  );
};

export default SidebarNavLinks;
