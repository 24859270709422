import { useContext } from 'react';

import UIConfigContext from './UIConfigContext';

const useUIConfig = (): UIConfigContext => {
  const context = useContext(UIConfigContext);
  if (context === null) {
    throw new Error('useUIConfig must be used inside a UIConfigContext.Provider');
  }
  return context;
};

export default useUIConfig;
