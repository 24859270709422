import { NavLink } from 'react-router-dom';

import useUser from '../../context/user/useUser';
import { canUserAccessPath } from './util';

interface ProtectedNavLinkProps {
  to: string;
  style: React.CSSProperties;
  activeStyle: React.CSSProperties;
  'data-testid'?: string;
}

const ProtectedNavLink: React.FC<ProtectedNavLinkProps> = (
  props: React.PropsWithChildren<ProtectedNavLinkProps>,
) => {
  const { to, style, activeStyle, children } = props;
  const { user } = useUser();
  const canNavTo = canUserAccessPath(user, to);
  if (canNavTo) {
    return (
      <NavLink to={to} style={style} activeStyle={activeStyle} data-testid={props['data-testid']}>
        {children}
      </NavLink>
    );
  }
  return <></>;
};

export default ProtectedNavLink;
