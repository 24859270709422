// @ts-strict-ignore
import { MutableRefObject, ReactNode } from 'react';
import { Box, Grommet, grommet, Layer, ThemeValue } from 'grommet';
import { deepMerge } from 'grommet/utils';

import { useResponsiveDeviceSize } from 'hooks';
import { isAutocompleteSelection } from 'utils/autocompleteUtils';
import { ResponsiveType } from 'utils/responsiveDeviceSizeUtils';
import { COLORS } from '../../styles/colors';

type FlyoutProps = React.PropsWithChildren<{
  full?: 'vertical' | 'horizontal' | boolean;
  position?: 'right' | 'left' | 'top-right';
  responsive?: boolean;
  target?: MutableRefObject<any>;
  width?: string;
  layerTheme?: ThemeValue;
  footer?: ReactNode;
  onClickOutside?: (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
}>;

const themeFlyout = (screenSize: ResponsiveType, width: string, layerTheme: ThemeValue) => {
  // The width of a full vertical flyout is set based on screen size
  let flyoutWidth = width;
  if (!flyoutWidth) {
    flyoutWidth = '23%';
    switch (screenSize) {
      case 'medium':
        flyoutWidth = '30%';
        break;
      case 'small':
        flyoutWidth = '40%';
        break;
      case 'xsmall':
        flyoutWidth = '100%';
        break;
      default:
        break;
    }
  }

  // TODO Configure height setting for full horizontal flyout as necessary

  return deepMerge(grommet, {
    layer: {
      container: {
        extend: {
          width: flyoutWidth,
          height: '100%',
        },
      },
      border: {
        radius: '0',
      },
      extend: { ...layerTheme },
    },
    global: {
      colors: {
        text: COLORS.darkGray600,
        focus: undefined,
      },
      drop: {
        border: {
          radius: '8px',
        },
      },
    },
  });
};

export const Flyout: React.FC<FlyoutProps> = (props: FlyoutProps) => {
  const {
    children,
    full = 'vertical',
    position = 'right',
    responsive = false,
    target,
    width,
    layerTheme = {},
    footer,
    onClickOutside = () => {},
  } = props;

  const { deviceSize } = useResponsiveDeviceSize();

  const handleClickOutside = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    const target = e.target as HTMLDivElement | HTMLSpanElement;
    if (isAutocompleteSelection(target)) {
      e.stopPropagation();
    } else {
      onClickOutside(e);
    }
  };

  return (
    <Grommet theme={themeFlyout(deviceSize.size, width, layerTheme)}>
      <Layer
        modal
        position={position}
        animation="slide"
        full={full}
        responsive={responsive}
        target={target}
        style={{ overflow: 'auto' }}
        onClickOutside={handleClickOutside}
      >
        <Box height="100%" justify="between">
          {children}

          {footer && footer}
        </Box>
      </Layer>
    </Grommet>
  );
};

export default Flyout;
