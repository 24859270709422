import { AxiosError, AxiosRequestConfig, AxiosResponse, default as axios, Method } from 'axios';
import axiosRetry from 'axios-retry';
import * as _ from 'lodash';
import * as uuid from 'uuid';

import { Auth0TokenGetter } from 'context/auth0/Auth0UserProvider';
import { reportError } from 'third-party/sentry';
import { setLocalStorageItem } from 'utils';
import { parseArrayDates, StringifyKeys } from '../utils/dateParsers';

export const LOCAL_STORAGE_ON_TOKEN_GET_KEY = 'LOCAL_STORAGE_ON_TOKEN_GET_KEY';

const onTokenGet = () => {
  setLocalStorageItem(LOCAL_STORAGE_ON_TOKEN_GET_KEY, uuid.v4());
};

export default class RittenClient {
  private tokenGetter: Nullable<Auth0TokenGetter>;

  private logrocketSessionGetter: () => string | null;

  /**
   * Optional response interceptors to be added to the request client.
   */
  protected responseInterceptors: ResponseInterceptor[] = [];

  constructor(t: Nullable<Auth0TokenGetter>, logrocket: () => string | null) {
    this.tokenGetter = t;
    this.logrocketSessionGetter = logrocket;
  }

  private makeRequest = async <T>(
    method: Method,
    url: string,
    config?: AxiosRequestConfig,
  ): Promise<AxiosResponse<T>> => {
    const authHeaders = await this.authHeaders();

    const lrUrl = this.logrocketSessionGetter();
    if (lrUrl !== null && lrUrl !== '') {
      // set logrocket session as header
      axios.defaults.headers.common['X-Ritten-Logrocket-Url'] = lrUrl;
    }

    const defaults: AxiosRequestConfig = {
      headers: authHeaders,
      method,
      url,
      'axios-retry': {
        retries: 3,
        retryDelay: axiosRetry.exponentialDelay,
      },
    };

    // Create an axios client instance with config.
    const requestConfig = _.merge(defaults, config || {});
    const client = axios.create(requestConfig);
    axiosRetry(client);

    // Attach any optional response interceptors to the client.
    this.responseInterceptors?.forEach((interceptor) =>
      client.interceptors.response.use(interceptor),
    );

    return client.request<T>(requestConfig).catch((err: AxiosError<RittenServerError>) => {
      let errMsg = err.message;
      if (err.response) {
        // The request was made and the server responded with a status code
        // that falls out of the range of 2xx
        reportError(err, 'warning');
        errMsg = `${err.response.data.error || err.response.statusText} (response code: ${
          err.response.status
        })`;
      }
      throw new Error(errMsg);
    });
  };

  private authHeaders = async (): Promise<{ Authorization?: string }> => {
    let authHeaders: { Authorization?: string } = {};
    if (this.tokenGetter !== null) {
      onTokenGet();
      const token = await this.tokenGetter({
        redirect_uri: window.location.href,
      });
      authHeaders = {
        Authorization: `Bearer ${token}`,
      };
    }
    return authHeaders;
  };

  protected get = async <T>(
    url: string,
    config?: AxiosRequestConfig,
  ): Promise<AxiosResponse<T>> => {
    return this.makeRequest('GET', url, config);
  };

  protected getWithDatesArray = async <T>(
    url: string,
    keysToParse: (keyof T)[],
    config?: AxiosRequestConfig,
  ): Promise<T[]> => {
    type OrKeys = typeof keysToParse[number];
    const res = await this.get<StringifyKeys<T, OrKeys>[]>(url, config);
    // pass in the response array, along with an array of keys to be converted from an ISO string to a JS Date
    return parseArrayDates(res.data ?? [], keysToParse);
  };

  protected post = async <T>(
    url: string,
    data?: any,
    config?: AxiosRequestConfig,
  ): Promise<AxiosResponse<T>> => {
    return this.makeRequest('POST', url, _.merge({ data }, config));
  };

  protected fetchStream = async (
    method: string,
    url: string,
    body?: any,
  ): Promise<ReadableStreamDefaultReader<Uint8Array>> => {
    const authHeaders = await this.authHeaders();
    const response = await fetch(url, {
      headers: {
        ...authHeaders,
        'Content-Type': 'application/json',
      },
      method,
      ...(body && { body: JSON.stringify(body) }),
    });
    if (!response.ok) {
      const errorFromBody = (await response.json())?.error;
      const errMsg = `${response.statusText} (response code: ${response.status}) ${errorFromBody}`;
      const err = new Error(errMsg);
      reportError(err, 'warning');
      throw err;
    }
    if (response.body === null) {
      const err = new Error('response body is null but stream was expected');
      reportError(err, 'warning');
      throw err;
    }
    return response.body.getReader();
  };

  protected patch = async <T>(
    url: string,
    data?: any,
    config?: AxiosRequestConfig,
  ): Promise<AxiosResponse<T>> => {
    return this.makeRequest('PATCH', url, _.merge({ data }, config));
  };

  protected put = async <T>(
    url: string,
    data?: any,
    config?: AxiosRequestConfig,
  ): Promise<AxiosResponse<T>> => {
    return this.makeRequest('PUT', url, _.merge({ data }, config));
  };

  protected delete = async <T>(
    url: string,
    config?: AxiosRequestConfig,
    data?: any,
  ): Promise<AxiosResponse<T>> => {
    return this.makeRequest('DELETE', url, _.merge({ data }, config));
  };
}
