import { useEffect, useState } from 'react';

import { FeatureFlagLabel } from 'common/feature-flags/featureFlags';
import UnauthorizedPage from 'common/UnauthorizedPage';
import useFeatureFlags from 'context/featureFlags/useFeatureFlags';
import useLogRocket from 'context/logrocket/useLogRocket';
import useTenant from 'context/tenant/useTenant';
import { useChameleon } from 'third-party/chameleon';
import { isEmpty } from 'utils';
import useAPI from '../../external/useAPI';
import { useErrorState } from '../../hooks/useErrorState';
import LoadingPanel from '../../ui-library/panels/LoadingPanel';
import UserContext from './UserContext';

const UserProvider: React.FC<{}> = (props: React.PropsWithChildren<{}>): JSX.Element => {
  const { children } = props;
  const { userAPI } = useAPI();
  const { initializeLogRocket } = useLogRocket();
  const { tenant } = useTenant();
  const { identify: identifyChameleon } = useChameleon();
  const { isFlagOn } = useFeatureFlags();

  const [loading, setLoading] = useState<boolean>(false);
  const { errors, clearErrors, addError } = useErrorState();
  const [user, setUser] = useState<Users.FullUser | null>(null);
  const [notificationUpdateEvent, setNotificationUpdateEvent] = useState<boolean>(false);

  useEffect(() => {
    fetchCurrentUser();
  }, []);

  useEffect(() => {
    if (user !== null) {
      const { email, first, last, id, roles, patientId } = user;
      const name = `${first} ${last}`;
      const tenantId = tenant.id;
      const allRoles = roles?.map(({ name }) => name).join(', ');
      if (isFlagOn(FeatureFlagLabel.LOG_ROCKET) && !email.includes('@ritten.io')) {
        initializeLogRocket(id, {
          name,
          email,
          tenantId,
          roles: allRoles,
        });
      }

      // Don't initialize chameleon for patient users
      if (isEmpty(patientId)) {
        identifyChameleon(id, { name, email, role: allRoles });
      }
    }
  }, [user]);

  const fetchCurrentUser = async () => {
    clearErrors();
    setLoading(true);
    try {
      const currentUser = await userAPI.getCurrentUser();
      setUser(currentUser);
    } catch (err) {
      addError(err);
    } finally {
      setLoading(false);
    }
  };

  const triggerNotificationUpdate = () => {
    setNotificationUpdateEvent(!notificationUpdateEvent);
  };

  if (errors.length > 0) {
    return <UnauthorizedPage />;
  }

  return (
    <>
      <LoadingPanel showLoader={loading} />
      {user && (
        <UserContext.Provider value={{ user, triggerNotificationUpdate, notificationUpdateEvent }}>
          {children}
        </UserContext.Provider>
      )}
    </>
  );
};

export default UserProvider;
