import { Spinner } from 'grommet';

import { COLORS } from '../../styles/colors';

type LoadingPanelProps = React.PropsWithChildren<{
  showLoader: boolean;
  fill?: boolean;
  'data-testid'?: string;
  overflow?: string;
}>;

const LoadingPanel: React.FC<LoadingPanelProps> = (props: LoadingPanelProps) => {
  const { showLoader, fill, children, overflow } = props;

  const MIN_HEIGHT = 65;

  const loadingPanelStyle: React.CSSProperties = {
    position: 'relative',
    overflow: overflow || 'auto',
    ...(fill && {
      // We have to subtract the -2px because of the -2px margin below.  Without it
      // the modal will be slightly larger than the container it lives in and
      // will show scroll bars.
      width: 'calc(100% - 2px)',
      height: 'calc(100% - 2px)',
    }),
    ...(showLoader && {
      minHeight: MIN_HEIGHT,
      minWidth: MIN_HEIGHT,
      padding: 2, // the negative margin with pad makes the blur 2px larger than the container
      margin: -2, // with children.  Inset was not working.
    }),
  };

  const spinnerBoxStyle: React.CSSProperties = {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backdropFilter: 'blur(2px)',
    position: 'absolute',
    inset: 0,
    minHeight: MIN_HEIGHT,
    zIndex: 99,
  };

  return (
    <div style={loadingPanelStyle} data-testid={props['data-testid'] || 'loading-panel-wrapper'}>
      {children && children}
      {showLoader && (
        <div style={spinnerBoxStyle} data-testid="loading-spinner">
          <Spinner size="medium" color={COLORS.rittenBlue400} />
        </div>
      )}
    </div>
  );
};

export default LoadingPanel;
