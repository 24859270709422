import _ from 'lodash';

import { KeyOf } from '../ui-library/types/keyof.type';

/**
 * Lodash set with Type support. https://lodash.com/docs/4.17.15#set
 * NOTICE: If you are not seeing your prop then ensure you are passing in an object and not an array.
 * @param {T} object
 * @param {keyofT} prop
 * @param {any} value
 */
export const setProp = <Object extends object, Key extends KeyOf<Object>>(
  object: Object,
  prop: Key,
  value: any,
) => {
  return _.set(object, prop, value);
};

/**
 * Lodash set with Type support. https://lodash.com/docs/4.17.15#set
 * This returns a cloned value to ensure react state updates are triggered properly.
 * NOTICE: If you are not seeing your prop then ensure you are passing in an object and not an array.
 * @param {T} object
 * @param {keyofT} prop
 * @param {any} value
 */
export const setStateProp = <Object extends object, Key extends KeyOf<Object>>(
  object: Object,
  prop: Key,
  value: any,
) => {
  return _.cloneDeep(setProp(object, prop as any, value));
};
