// @ts-strict-ignore
import { useEffect } from 'react';
import { Box } from 'grommet';

import { COLORS } from '../../styles/colors';
import { NavItem, useNavState } from './useNavState';

interface VerticalNavProps {
  width?: string;
  navItems: NavItem[];
  onActiveLinkClicked: (label: string) => void;
  activeNavItemOverride?: NavItem;
}

const styleLink: React.CSSProperties = {
  color: COLORS.darkGray400,
  fontSize: '14px',
  textDecoration: 'none',
  paddingLeft: '10px',
};

const styleLinkDanger: React.CSSProperties = {
  color: COLORS.red400,
};

const styleLinkSpecial: React.CSSProperties = {
  color: COLORS.rittenBlue400,
};

const styleLinkActive: React.CSSProperties = {
  color: COLORS.darkGray600,
  fontSize: '14px',
  borderLeft: `2px solid ${COLORS.rittenBlue400}`,
  paddingLeft: '10px',
  fontWeight: 'bold',
  zIndex: 1,
};

interface VerticalNavItemProps {
  label: string;
  active: boolean;
  danger?: boolean;
  special?: boolean;
  onClick: (navItem: NavItem) => void;
  'data-cy'?: string;
}

const VerticalNavItem = (props: VerticalNavItemProps) => {
  let boxStyle = props.active ? styleLinkActive : styleLink;
  if (props.danger) {
    boxStyle = { ...boxStyle, ...styleLinkDanger };
  }
  if (props.special) {
    boxStyle = { ...boxStyle, ...styleLinkSpecial };
  }
  return (
    <Box direction="row" onClick={props.onClick}>
      <Box style={boxStyle} justify="center" data-cy={props['data-cy']}>
        {props.label}
      </Box>
    </Box>
  );
};

const VerticalNav = (props: VerticalNavProps) => {
  const { navItems, setNavItems, onNavItemClick, getActiveLink } = useNavState(props.navItems);

  useEffect(() => {
    // Until we implement a nav context we need to broadcast the active link.
    // This will initialize the consumers state so they don't have to manually set it.
    props.onActiveLinkClicked(getActiveLink()?.label);
  }, []);

  useEffect(() => {
    setNavItems(props.navItems);
  }, [props.navItems]);

  useEffect(() => {
    if (props.activeNavItemOverride?.label) {
      onNavClick(props.activeNavItemOverride);
    }
  }, [props.activeNavItemOverride]);

  const onNavClick = (navItem: NavItem) => {
    onNavItemClick(navItem);
    props.onActiveLinkClicked(navItem.label);
  };

  return (
    <Box width={props.width} overflow="auto">
      <Box direction="column" gap="12px" flex={false}>
        {navItems.map((ni) => (
          <VerticalNavItem
            key={ni.label}
            label={ni.label}
            active={ni.active}
            danger={ni.danger}
            special={ni.special}
            onClick={() => onNavClick(ni)}
            data-cy={`${ni.label.toLowerCase()}-nav-item`}
          />
        ))}
      </Box>
    </Box>
  );
};

export default VerticalNav;
