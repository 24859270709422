// @ts-strict-ignore
import { Box, Text } from 'grommet';

import { StandardButton } from '../buttons';
import LinkButton from '../buttons/LinkButton';
import BaseModal from './base-modal/BaseModal';

type ConfirmSubmitModalProps = {
  isOpen?: boolean;
  onSubmit: () => void;
  onClose?: () => void;
  isLoading?: boolean;
  customMessage?: JSX.Element;
  customHeader?: string;
  submitButtonLabel?: string;
};

export const ConfirmSubmitModal: React.FC<ConfirmSubmitModalProps> = (
  props: ConfirmSubmitModalProps,
) => {
  const {
    isOpen = false,
    onSubmit,
    onClose,
    isLoading,
    customHeader,
    submitButtonLabel = 'Continue',
  } = props;

  if (!isOpen) {
    return null;
  }

  return (
    <BaseModal
      isOpen={isOpen}
      header={customHeader ?? 'Confirm Submit'}
      onClose={onClose}
      footer={
        <Box justify="end" direction="row" gap="xsmall" width="100%">
          <LinkButton color="plain" onClick={onClose} label="Cancel" />
          <StandardButton onClick={onSubmit} label={submitButtonLabel} isLoading={isLoading} />
        </Box>
      }
    >
      <Box>{props.customMessage || <Text>Are you sure you want to continue?</Text>}</Box>
    </BaseModal>
  );
};

export default ConfirmSubmitModal;
