// @ts-strict-ignore
import { useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { Box, Button, DropButton, Text, ThemeContext } from 'grommet';
import { Checkmark, FormDown } from 'grommet-icons';

import LineBreak from '@ritten/ui-library/line-break/LineBreak';

import UserInitials from 'common/UserInitials';
import useMobileDevice from 'context/mobileDevice/useMobileDevice';
import { COLORS } from 'styles/colors';
import { getFullName } from 'utils';
import { getPortalPageRootUrl, getSelectedClientId } from '../utils';
import { navDropButton } from './PortalNav';

interface PortalNavClientSelectorProps {
  accessibleContacts: Users.PortalAuthorization[];
  onSelectClient?: () => void;
}

const PortalNavClientSelector = (props: PortalNavClientSelectorProps) => {
  const { accessibleContacts, onSelectClient } = props;

  const { isMobile } = useMobileDevice();

  const history = useHistory();
  const { pathname } = useLocation();

  const [isDropOpen, setIsDropOpen] = useState<boolean>(false);

  const selectedClientId = getSelectedClientId(pathname);
  const selectedClient = accessibleContacts.find(
    (contact) => contact.patient.patientId === selectedClientId,
  )?.patient;

  return isMobile ? (
    <Box>
      <Text color={COLORS.white} size="11px" margin={{ bottom: '10px' }}>
        Available Clients
      </Text>
      <LineBreak color={COLORS.darkGray400} height="1px" />
      {accessibleContacts.map((contact) => (
        <Button
          onClick={
            selectedClientId === contact.patient.patientId
              ? () => onSelectClient?.()
              : () => {
                  history.push(`${getPortalPageRootUrl('forms', contact.patient.patientId)}/forms`);

                  onSelectClient?.();
                }
          }
          key={contact.patient.patientId}
        >
          <Box direction="row" align="center" pad="10px 6px" gap="10px">
            <UserInitials
              backgroundColor={COLORS.white}
              height="24px"
              width="24px"
              textColor={COLORS.darkGray600}
              textSize="12px"
              user={contact.patient}
            />
            <Text size="14px" color={COLORS.white} truncate>
              {getFullName(contact.patient)}
            </Text>
            {selectedClientId === contact.patient.patientId && (
              <Box color={COLORS.white}>
                <Checkmark color={COLORS.rittenBlue400} size="12px" />
              </Box>
            )}
          </Box>
        </Button>
      ))}
    </Box>
  ) : (
    <ThemeContext.Extend value={navDropButton}>
      <DropButton
        label={
          <Box gap="10px" direction="row" align="center">
            <UserInitials
              backgroundColor={COLORS.white}
              height="26px"
              width="26px"
              textColor={COLORS.darkGray600}
              textSize="12px"
              user={selectedClient}
            />
            <Text color={COLORS.white} size="14px">
              {getFullName(selectedClient)}
            </Text>
            <FormDown size="16px" color={COLORS.white} />
          </Box>
        }
        dropContent={
          <Box background={COLORS.darkGray600} pad="10px" width="200px">
            <Text color={COLORS.white} size="11px" margin={{ bottom: '10px' }}>
              Available Clients
            </Text>
            <LineBreak color={COLORS.darkGray400} height="1px" />
            {accessibleContacts.map((contact) => (
              <Button
                onClick={
                  selectedClientId === contact.patient.patientId
                    ? null
                    : () => {
                        history.push(
                          `${getPortalPageRootUrl('forms', contact.patient.patientId)}/forms`,
                        );
                        setIsDropOpen(false);
                      }
                }
                key={contact.patient.patientId}
              >
                <Box direction="row" align="center" pad="10px 2px" gap="10px">
                  <UserInitials
                    backgroundColor={COLORS.white}
                    height="26px"
                    width="26px"
                    textColor={COLORS.darkGray600}
                    textSize="12px"
                    user={contact.patient}
                  />
                  <Text size="14px" color={COLORS.white} truncate>
                    {getFullName(contact.patient)}
                  </Text>
                  {selectedClientId === contact.patient.patientId && (
                    <Box color={COLORS.white}>
                      <Checkmark color={COLORS.rittenBlue400} size="12px" />
                    </Box>
                  )}
                </Box>
              </Button>
            ))}
          </Box>
        }
        dropProps={{ align: { top: 'bottom', left: 'left' } }}
        style={{ height: '100%' }}
        open={isDropOpen}
        onClose={() => setIsDropOpen(false)}
        onOpen={() => setIsDropOpen(true)}
      />
    </ThemeContext.Extend>
  );
};

export default PortalNavClientSelector;
