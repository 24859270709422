import { Box, TextInput } from 'grommet';

import { FIELD_TYPES } from 'features/forms/custom-fields/fieldConstants';
import MCCheckboxFieldBuilder from 'features/forms/custom-fields/mcCheckbox/MCCheckboxFieldBuilder';
import ParagraphFieldBuilder from 'features/forms/custom-fields/paragraph/ParagraphFieldBuilder';
import { promptTextBuilderInputStyles } from 'features/forms/custom-fields/PromptTextBuilder';
import SCDropdownFieldBuilder from 'features/forms/custom-fields/scDropdown/SCDropdownFieldBuilder';
import SCRadioFieldBuilder from 'features/forms/custom-fields/scRadio/SCRadioFieldBuilder';
import TextFieldBuilder from 'features/forms/custom-fields/text/TextFieldBuilder';

interface LeadApplicationCustomFieldConfigurationProps {
  field: CRM.LeadAppField;
  updateField: (field: CRM.LeadAppField) => void;
}

const LeadApplicationCustomFieldConfiguration = (
  props: LeadApplicationCustomFieldConfigurationProps,
) => {
  const { field, updateField } = props;

  const renderBuilderByType = (type: CRM.LeadAppFieldType) => {
    switch (type) {
      case FIELD_TYPES.TEXT:
        return (
          <TextFieldBuilder
            meta={field.meta}
            onNewMeta={(newMeta) => updateField({ ...field, meta: newMeta })}
          />
        );
      case FIELD_TYPES.PARAGRAPH:
        return (
          <ParagraphFieldBuilder
            meta={field.meta}
            onNewMeta={(newMeta) => updateField({ ...field, meta: newMeta })}
          />
        );
      case FIELD_TYPES.SINGLE_CHOICE_DROPDOWN: {
        return (
          <SCDropdownFieldBuilder
            meta={field.meta as Forms.FieldMetaSCDropdown}
            onNewMeta={(newMeta) => updateField({ ...field, meta: newMeta })}
          />
        );
      }
      case FIELD_TYPES.SINGLE_CHOICE_RADIO:
        return (
          <SCRadioFieldBuilder
            meta={field.meta as Forms.FieldMetaSCRadio}
            onNewMeta={(newMeta) => updateField({ ...field, meta: newMeta })}
          />
        );
      case FIELD_TYPES.MULTI_CHOICE_CHECKBOX:
        return (
          <MCCheckboxFieldBuilder
            meta={field.meta as Forms.FieldMetaMCCheckbox}
            onNewMeta={(newMeta) => updateField({ ...field, meta: newMeta })}
            hideDefaultAsCheckedOptions
          />
        );
      default:
        return <></>;
    }
  };

  return (
    <Box width="100%" gap="8px">
      <TextInput
        value={field.meta.promptText}
        onChange={(e) =>
          updateField({ ...field, meta: { ...field.meta, promptText: e.target.value } })
        }
        placeholder="Enter prompt"
        focusIndicator={false}
        style={promptTextBuilderInputStyles}
        width="100%"
      />
      {renderBuilderByType(field.type)}
    </Box>
  );
};

export default LeadApplicationCustomFieldConfiguration;
