export const FeatureFlagLabel = {
  PORTAL: 'patient_portal_12_2021',
  BILLING: 'billing_01_2022',
  MAR: 'mar_01_2022',
  MAR_NO_CLIENT_SIGS: 'mar_no_client_sigs_03_2023',
  CLIENT_TRENDS: 'client_trends_03_2022',
  NOTIFICATIONS: 'user_notifications_04_2022',
  CLINIC_TEAMS: 'clinic_team_05_2022',
  CALENDAR_NOTIFICATIONS: 'calendar_notifications_06_2022',
  BIOMETRICS: 'biometrics_08_2022',
  CRM: 'crm_08_2022',
  SIGNATURE_ACTIONS: 'signature_actions_11_2022',
  WILEY: 'wiley_11_2022',
  REQUIRED_FORMS: 'required_forms_12_2022',
  HIDE_CLIENT_ROSTER_COUNT: 'hide_client_roster_count_01_2023',
  CLIENT_PORTAL_AGENDA: 'portal_agenda_01_2023',
  DUE_FORMS: 'due_forms_01_2023',
  CALENDAR_REMINDERS: 'calendar_reminders_02_2023',
  MED_PASS: 'med_pass_03_2023',
  FORSE: 'forse_03_2023',
  CALENDAR_INTEGRATIONS: 'calendar_integrations_03_2023',
  REQUIRED_FORMS_V2: 'required_forms_v2_05_2023',
  EXTERNAL_API: 'external_api_06_2023',
  CALENDAR_V2_API: 'calendar_recurrence_v2_05_2023',
  ZOOM: 'zoom_07_2023',
  METABASE: 'metabase_08_2023',
  GOOGLE_ADDRESS_AUTOCOMPLETE: 'google_autocomplete_09_2023',
  LABS: 'labs_09_2023',
  TEMPLATED_TEXT: 'templated_text_10_2023',
  BEDBOARD: 'bedboard_10_2023',
  CHART_INTELLIGENCE: 'chart_intelligence_01_2024',
  LOG_ROCKET: 'log_rocket_02_2024',
  IMPROVE_TEXT: 'chart_intelligence_improve_text_03_2024',
  ROUNDS: 'rounds_03_2024',
  RCM_V1: 'rcm_v1_02_2024',
  MORIAH_REPORTS: 'moriah_reports_08_2024',
  AI_SCRIBE: 'ai_scribe_08_2024',
  CUSTOM_METABASE_DASHBOARD: 'custom_metabase_dashboard_09_2024',
};
