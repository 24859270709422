import { useState } from 'react';

/**
 * useLoadingState returns a loading state variable and a higher-order function handles setLoading.
 *
 * @example
 * Instead of manually calling setLoading(true) and setLoading(false) in any async
 * handler that needs it
 *
 * const onSomeAsyncChange = needsLoadingState(async () => {
 *
 * })
 *
 * <SomeRoobi
 *    onSomeAsyncChange={onSomeAsyncChange}
 *  />
 * }
 * @returns {any}
 */

export const useLoadingState = (initialLoadingState: boolean) => {
  const [loading, setLoading] = useState<boolean>(initialLoadingState);

  /**
   * Wraps an async function (e.g. API call) and manages the loading state.
   *
   * Generic function that accepts the type `T` of the returned resource.
   *
   * @example
   * const getFoo = needsLoadingState<API.Foo>(apiClient.getFoo);
   */
  function needsLoadingState<T, Args extends any[]>(
    componentHandler: (...args: Args) => Promise<T>,
  ) {
    return async (...args: Args): Promise<T> => {
      setLoading(true);
      const ret = await componentHandler(...args);
      setLoading(false);
      return ret;
    };
  }

  return {
    loading,
    setLoading, // for more granular setLoading control if needed
    needsLoadingState,
  };
};
