import {
  caseInsensitiveHHMMAATimeStringRegex,
  getDateFromDateAndTime,
  getTimeZoneAbbreviation,
  maybeConvertHHMMSSToHHMMAA,
} from 'utils';

export const ROUNDS_FILTERS_LOCAL_STORAGE_KEYS = {
  roundIntervals: 'roundsPageRoundIntervalsFilter',
  dateRangeStart: 'roundsPageDateRangeStartDateFilter',
  dateRangeEnd: 'roundsPageDateRangeEndDateFilter',
  timeRangeStart: 'roundsPageTimeRangeStartFilter',
  timeRangeEnd: 'roundsPageTimeRangeEndFilter',
  staffMembers: 'roundsPageStaffMembersFilter',
};

export const ROUNDS_QUERY_LIMIT = 20;

export const validateFilters = (
  initialFilters: Rounds.RoundsQueryParams,
  staffUsers: Users.AbbreviatedUser[],
): boolean => {
  const allStaffIds = staffUsers.map((user) => user.id);
  const areStaffUserIdsValid = initialFilters.staffMemberIds.reduce((isValid, id) => {
    return allStaffIds.includes(id) && isValid;
  }, true);

  return areStaffUserIdsValid;
};

export const getRoundTitleText = (
  roundOption: Rounds.RoundOption | Rounds.RoundInstance,
  tenantTimeZone: string,
): string => {
  if (roundOption.building) {
    return roundOption.building.label;
  }
  if (roundOption.program) {
    return roundOption.program.label;
  }
  return `${maybeConvertHHMMSSToHHMMAA(roundOption.time)} ${getTimeZoneAbbreviation(
    tenantTimeZone,
  )}`;
};

const titleSortObject: Record<Rounds.RoundIntervalTitle, number> = {
  '15 Minute Rounds': 0,
  '30 Minute Rounds': 1,
  '1 Hour Rounds': 2,
  Manual: 3,
  // will not be used
  None: 99,
};

export const sortRoundConfigurations = (
  configs: Rounds.RoundGroupConfiguration[],
): Rounds.RoundGroupConfiguration[] => {
  let sortType: 'program' | 'building' | undefined;
  if (configs[0].program) {
    sortType = 'program';
  } else if (configs[0].building) {
    sortType = 'building';
  } // Else it is none and will remain undefined
  if (!sortType) {
    return configs.sort((configA, configB) => {
      return titleSortObject[configA.name] - titleSortObject[configB.name];
    });
  }
  return configs.sort((configA, configB) => {
    return (
      (sortType === 'building'
        ? // based on the sort type sort by building or program name
          configA.building?.label?.localeCompare(configB.building?.label ?? '')
        : configA.program?.label?.localeCompare(configB.program?.label ?? '')) ||
      // when the program/building are the same - defer to name
      titleSortObject[configA.name] - titleSortObject[configB.name]
    );
  });
};

/**
 * compare times (formatted HH:mm:ss) to determine order of rounds
 * @param timeA
 * @param timeB
 * @returns number value to sort on
 */
export const sortTimesHHMMSS = (timeA: string, timeB: string, asc: boolean): number => {
  // NOTE - This sorts based solely off time, day is ignored
  // so 23:45, 00:00, 00:15 will be sorted -> 00:00, 00:15, 23:45
  const dateA = new Date(`1970-01-01T${timeA}`);
  const dateB = new Date(`1970-01-01T${timeB}`);

  if (asc) {
    return dateA.getTime() - dateB.getTime();
  }
  return dateB.getTime() - dateA.getTime();
};

/**
 * Shorten tag for mobile/smaller devices
 * @param tag Rounds.RoundIntervalTitle
 * @returns tag with "Rounds" removed
 */
export const getAbbreviatedTagLabel = (tag: Rounds.RoundIntervalTitle) =>
  tag.replace(/\srounds/i, '');

/**
 * adds timezone to kitchen time format
 * @param time formatted hh:mm aa
 * @param string formatted America/New_York - using config.tenantTimeZone
 * @returns hh:mm aa zzz
 */
export const addTimeZoneAbbreviation = (time: string, tenantTimeZone: string) =>
  `${time} ${getTimeZoneAbbreviation(tenantTimeZone)}`;

/**
 * determines whether a string (input from TimeInputComponent) is a valid kitchen time
 * @param timeVal string
 * @returns boolean
 */
export const isValidKitchenTime = (timeVal: string) => /\b\d{1,2}:\d{2} (AM|PM)\b/.test(timeVal);

/**
 * determines whether the round is start-able based on the date and time
 * @param startDate date returned from Rounds.RoundInstance
 * @param startTime time formatted HH:MM:SS from Rounds.RoundInstance
 * @returns true/false indicating whether the current time is behind the start time of the round
 */
export const isPastRoundStartTime = (startDate: string, startTime: string): boolean => {
  // Split date and time strings
  const [year, month, day] = startDate.split('-');
  const [hours, minutes, seconds] = startTime.split(':');

  // Create a Date object with provided date and time
  const inputDateTime = new Date(`${year}-${month}-${day}T${hours}:${minutes}:${seconds}`);

  // Get current date and time
  const currentDateTime = new Date();

  // Compare input date with current date
  return inputDateTime.getTime() < currentDateTime.getTime();
};

/**
 * validates manually create round input
 * @param date date value chosen from modal - indicates date day value, not time
 * @param time time input - formatted HH:MM AA ZZZ
 * @returns true/false whether inputs combine to create a date object that is 3 hours before the current time
 */
export const isValidManuallyCreatedRound = (date: Date, time: string) => {
  const formattedTime = time.match(caseInsensitiveHHMMAATimeStringRegex)?.[0];
  const inputDateObj = getDateFromDateAndTime(date, formattedTime ?? '');
  if (!inputDateObj) {
    return false;
  }
  const curDate = new Date();
  // compare just minute values
  curDate.setSeconds(0);
  curDate.setMilliseconds(0);

  return curDate.getTime() - inputDateObj.getTime() > 3 * 60 * 60 * 1000;
};
