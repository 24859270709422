// @ts-strict-ignore
import { useEffect } from 'react';
import { matchPath } from 'react-router-dom';
import * as Sentry from '@sentry/react';
import { some } from 'lodash';

import useLogRocket from 'context/logrocket/useLogRocket';
import useUIConfig from '../context/config/useUIConfig';
import { containsIgnoreCase } from '../utils/stringUtils';
import { history } from './history';

// Array of Route Config Objects
// Make sure the order of the routes is correct. The longest url under
// the same parent should be placed first and in decreasing order.
const routes = [
  { path: '/crm' },
  { path: '/records' },
  { path: '/calendar/date/:date/event/:eventId' },
  { path: '/calendar/event/:eventId' },
  { path: '/calendar/date/:date' },
  { path: '/calendar' },
  { path: '/insights' },
  { path: '/admin' },
  { path: '/billing' },
];

export type Severity = 'error' | 'warning' | 'info';

export const reportError = (exception: any, severity: Severity = 'error') => {
  Sentry.captureException(exception, { level: severity });
};

export const reportMessage = (message: string) => {
  Sentry.captureMessage(message);
};

export const useSentry = () => {
  const { config } = useUIConfig();
  const { getSessionUrl } = useLogRocket();

  useEffect(() => {
    Sentry.init({
      dsn: config.sentryDsn,
      integrations:
        config.env === 'prod' // only enable browser tracing in prod
          ? [
              Sentry.reactRouterV5BrowserTracingIntegration({
                history,
                routes,
                matchPath,
              }),
            ]
          : [],
      enabled: config.env !== 'dev',
      // release: 'valinor@{{valinor-version-num}}',
      environment: config.env,

      // This controls how often performance metrics are sent to Sentry.  Value is
      // between 0 and 1 - 1 meaning send ALL events, 0.2 meaning send 20% of events,
      // and 0 meaning send none.
      tracesSampleRate: 0.025,

      beforeSend: (event) => {
        // We block JS/CSS chunk loading errors, but we do send an info message to Sentry.
        // We need to do this because otherwise the chunk loading errors clutter up the issues
        // page in sentry and sends alerts.
        const isChunkLoadError = some(
          event.exception?.values,
          (exception) =>
            exception.type === 'ChunkLoadError' ||
            containsIgnoreCase(exception.value, 'Loading CSS chunk'),
        );

        if (isChunkLoadError) {
          Sentry.captureMessage('Chunk Load Error');

          // TO-DO: as of Jan 2023 we're experimenting with a new chunk error solution that should
          // limit chunk error sentry spam so we're commenting the report-blocking line out for now and
          // should revisit permanently deleting if there's no spam

          // This stops Sentry from reporting _as_ an error
          // return null;
        }

        const logRocketSessionUrl = getSessionUrl();
        if (logRocketSessionUrl !== null) {
          event.extra.LogRocket = logRocketSessionUrl;
          return event;
        }
        return event;
      },
    });
  }, []);
};
