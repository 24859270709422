// @ts-strict-ignore
import { useState } from 'react';
import { Observable, Subject } from 'rxjs';
import { debounceTime, filter, map, switchMap, tap } from 'rxjs/operators';

interface UseAsyncSearchProps {
  setLoading: (loading: boolean) => void;
  setOptions: (options: string[]) => void;
  addError: (error: string) => void;
  clearErrors: () => void;
  showNoOptionFound?: boolean;
  onAsyncSearch?: (searchText: string) => Observable<(string | object)[]>;
  minimumSearchCharacters?: number;
}

export const useAsyncSearch = (props: UseAsyncSearchProps) => {
  const {
    setLoading,
    setOptions,
    addError,
    clearErrors,
    onAsyncSearch,
    minimumSearchCharacters = 3,
    showNoOptionFound,
  } = props;

  const NO_OPTION_FOUND_KEY = 'NO_OPTION_FOUND';

  const [searchSubject] = useState(new Subject<string>());

  const triggerSearch = (searchString: string) => {
    searchSubject.next(searchString);
  };

  const setupSearchObservable = () => {
    return searchSubject
      .pipe(
        debounceTime(250),
        filter(
          (searchString: string) =>
            !minimumSearchCharacters || searchString?.length >= minimumSearchCharacters,
        ),
        tap(() => {
          setLoading(true);
          clearErrors();
          setOptions(['searching']);
        }),
        switchMap((searchString: string) => onAsyncSearch(searchString)),
        map((options: any[]) => {
          // If no options AND noOptionFoundLabel is specified
          if ((!options || options.length === 0) && showNoOptionFound) {
            return [NO_OPTION_FOUND_KEY];
          }
          return options;
        }),
      )
      .subscribe({
        next: (result) => {
          setLoading(false);
          setOptions(result);
        },
        error: () => {
          addError('Sorry, something went wrong with the search');
        },
      });
  };

  return {
    NO_OPTION_FOUND_KEY,
    setupSearchObservable,
    triggerSearch,
  };
};
