// @ts-strict-ignore
import { useEffect, useState } from 'react';
import PhoneInput, { Country } from 'react-phone-number-input';
import flags from 'country-flag-icons/react/3x2';
import { Box, Grid, Text } from 'grommet';
import { WidthType } from 'grommet/utils';
import { FormClose } from 'grommet-icons';

import { COLORS } from 'styles/colors';
import IconButton from '../buttons/IconButton';
import ErrorLabel from '../labels/ErrorLabel';
import CountrySelectWithIcons from './CountrySelectWithIcons';
import PhoneTextInput from './PhoneTextInput';
import { useInputTheme } from './useInputTheme';

import 'react-phone-number-input/style.css';

export interface PhoneNumberInputComponentProps {
  label: string;
  value: string;
  error?: string;
  preferredCountries: Country[];
  disabled?: boolean;
  onChange: (value) => void;
  onCountryChange?: (country: Country) => void;
  requirementMarker?: string;
  width?: WidthType;
}

const PhoneNumberInputComponent = (props: PhoneNumberInputComponentProps) => {
  const {
    label,
    value,
    preferredCountries,
    disabled = false,
    onChange,
    error,
    onCountryChange,
    requirementMarker,
    width,
  } = props;

  const { focusStyle, onFocus } = useInputTheme();

  const DEFAULT_COUNTRY = preferredCountries?.length > 0 ? preferredCountries[0] : 'US';
  const [selectedCountry, setSelectedCountry] = useState<Country>(DEFAULT_COUNTRY);

  useEffect(() => {
    onCountryChange?.(selectedCountry);
  }, [selectedCountry]);

  /*
   * PLEASE READ: If you make a visual change to this component then please also ensure you make the same changes to RHFPhoneNumberInput
   */
  return (
    <Box aria-label={label} width={width}>
      {label.length > 0 && (
        <Box pad={{ bottom: '4px' }} direction="row" justify="between">
          <Text size="14px">{label}</Text>
          {requirementMarker && (
            <Text
              size="13px"
              color={requirementMarker === 'Required' ? COLORS.red400 : COLORS.darkGray400}
            >
              {requirementMarker}
            </Text>
          )}
        </Box>
      )}
      <Box
        border={{ side: 'all', color: error ? COLORS.red400 : 'rgba(0, 0, 0, .33)' }}
        background={disabled ? COLORS.darkGray200 : COLORS.white}
        round="4px"
        height="40px"
        alignContent="center"
        direction="row"
        style={focusStyle}
      >
        {/* 
          Wrapping in a grid here ensures the phone input consumes the available space.  Otherwise there can 
          be an empty gap between the phone input and the clear button
        */}
        <Grid columns={['1fr', 'auto']} alignContent="center" height="40px" width="100%">
          <PhoneInput
            international
            value={value}
            disabled={disabled}
            onChange={onChange}
            addInternationalOption={false}
            limitMaxLength
            countryOptionsOrder={[...preferredCountries, '|', '...']}
            countrySelectComponent={CountrySelectWithIcons}
            countrySelectProps={{ showCallingCode: true }}
            country={selectedCountry}
            defaultCountry={selectedCountry}
            onCountryChange={(country) => {
              setSelectedCountry(country ?? DEFAULT_COUNTRY);
            }}
            flags={flags}
            inputComponent={PhoneTextInput}
            onFocus={() => onFocus(true)}
            onBlur={() => onFocus(false)}
            style={{ paddingLeft: '1px' }} // fixes cuttoff issue with focus border
          />
          <Box pad={{ right: '10px' }}>
            <IconButton
              icon={<FormClose size="24px" color={COLORS.darkGray600} />}
              disabled={disabled}
              onClick={() => {
                onChange('');
                setSelectedCountry(null);
              }}
              color="text"
            />
          </Box>
        </Grid>
      </Box>

      {error && (
        <Box pad={{ top: 'xxsmall' }}>
          <ErrorLabel>{error}</ErrorLabel>
        </Box>
      )}
    </Box>
  );
};

export default PhoneNumberInputComponent;
