// @ts-strict-ignore
import { useEffect, useState } from 'react';

import UnauthorizedPage from 'common/UnauthorizedPage';
import useAPI from '../../external/useAPI';
import { useErrorState } from '../../hooks/useErrorState';
import LoadingPanel from '../../ui-library/panels/LoadingPanel';
import TenantContext from './TenantContext';

const TenantProvider: React.FC<{}> = (props: React.PropsWithChildren<{}>): JSX.Element => {
  const { rolodexAPI } = useAPI();
  const [tenant, setTenant] = useState<Tenant | null>(null);
  const [loading, setLoading] = useState<boolean>(true);
  const { errors, clearErrors, addError } = useErrorState();

  useEffect(() => {
    fetchTenantConfig();
  }, []);

  const fetchTenantConfig = async () => {
    clearErrors();
    try {
      const currentTenant = await rolodexAPI.getCurrentTenant();
      setTenant(currentTenant);
    } catch (err) {
      addError(err);
    } finally {
      setLoading(false);
    }
  };

  if (loading) {
    return <LoadingPanel showLoader />;
  }

  if (errors.length > 0) {
    return <UnauthorizedPage />;
  }

  return <TenantContext.Provider value={{ tenant }}>{props.children}</TenantContext.Provider>;
};

export default TenantProvider;
