// @ts-strict-ignore
import { useEffect, useState } from 'react';
import { Box, Text, TextInput } from 'grommet';
import { Close } from 'grommet-icons';

import { LinkButton } from '@ritten/ui-library/buttons';

import { COLORS } from '../../../../styles/colors';
import PromptTextBuilder from '../PromptTextBuilder';

const optionTextInputStyles = {
  color: COLORS.darkGray600,
  fontWeight: 400,
  backgroundColor: COLORS.white,
  border: `1px solid ${COLORS.darkGray400}`,
  borderRadius: '4px',
  fontSize: '14px',
};

const SCDropdownFieldBuilder = (props: FieldBuilderProps<Forms.FieldMetaSCDropdown>) => {
  const promptText = (props.meta as Forms.FieldMetaSCDropdown).promptText;
  const [choices, setChoices] = useState<string[]>(['', '']);
  const [unfilledChoices, setUnfilledChoices] = useState<boolean>(false);
  const [cannotRemoveChoice, setCannotRemoveChoice] = useState<boolean>(false);

  useEffect(() => {
    updateChoices();
  }, [props.meta]);

  function updateChoices() {
    const meta = props.meta as Forms.FieldMetaSCDropdown;
    const list = meta.choices;
    while (list.length < 2) {
      list.push('');
    }
    setChoices(list);
  }

  const updateMeta = (newValue: Partial<Forms.FieldMetaSCDropdown>) => {
    if (props.onNewMeta !== undefined) {
      if (newValue.promptText !== undefined) {
        props.onNewMeta({ promptText: newValue.promptText, choices });
      } else {
        props.onNewMeta({ promptText, choices: newValue.choices });
      }
    }
  };

  const updateChoice = (newValue: string, idx: number) => {
    // Resets validation state if there is an attempt to remove < 2 choices, and then an edit is made
    setCannotRemoveChoice(false);

    const newChoices = [...choices];
    newChoices[idx] = newValue;
    setChoices(newChoices);
    updateMeta({ choices: newChoices });
  };

  const removeOption = (idxToRemove: number) => {
    if (choices.length > 2) {
      const filteredChoices = choices.filter((choice, idx) => idx !== idxToRemove);
      setChoices(filteredChoices);
      updateMeta({ choices: filteredChoices });
    } else {
      setCannotRemoveChoice(true);
    }
  };

  const dropdownOptionBuilder = (choice: string, idx: number) => {
    return (
      <Box key={idx} direction="row" align="center" gap="small" pad={{ vertical: 'xsmall' }} fill>
        <TextInput
          plain={true}
          placeholder="Option"
          value={choice}
          onChange={(e) => updateChoice(e.target.value, idx)}
          focusIndicator={false}
          style={optionTextInputStyles}
        />
        <Close
          onClick={() => removeOption(idx)}
          cursor="pointer"
          size="14px"
          color={COLORS.darkGray600}
        />
      </Box>
    );
  };

  const allChoicesFilled = () => {
    const emptyChoices = choices.filter((choice) => choice === '');
    return emptyChoices.length === 0;
  };

  const addAdditionalOption = () => {
    if (allChoicesFilled()) {
      const newChoices = [...choices];
      newChoices.push('');
      setChoices(newChoices);
      setUnfilledChoices(false);
    } else {
      setUnfilledChoices(true);
    }
  };

  return (
    <Box direction="column" pad="medium" gap="small">
      <PromptTextBuilder
        {...props}
        type="TYPE: SINGLE SELECT, DROPDOWN"
        promptText={promptText}
        onChange={(newValue: string) => updateMeta({ promptText: newValue })}
        removeFieldFromFieldsList={props.removeFieldFromFieldsList}
        moveField={props.moveField}
        fieldIdx={props.fieldIdx}
      />
      <Box direction="column" align="start" wrap={true}>
        {choices.map((choice, idx) => {
          return dropdownOptionBuilder(choice, idx);
        })}
      </Box>
      <LinkButton label="Add a new row" onClick={addAdditionalOption} padding="none" />
      {unfilledChoices && (
        <Text color="status-critical">Please fill in existing choices before adding new ones</Text>
      )}
      {cannotRemoveChoice && (
        <Text color="status-critical">Field must have at least two choices</Text>
      )}
    </Box>
  );
};

export default SCDropdownFieldBuilder;
