import { AxiosError, AxiosResponse } from 'axios';

import { parseISODateStrings } from 'utils';

interface RittenServerError {
  error: string;
}

export const errorHandler = (err: AxiosError<RittenServerError>) => {
  let errMsg = err.message;
  if (err.response) {
    // The request was made and the server responded with a status code
    // that falls out of the range of 2xx
    errMsg = `${err.response.data.error || err.response.statusText} (response code: ${
      err.response.status
    })`;
  }
  throw new Error(errMsg);
};

/**
 * Axios response interceptor that parses date strings in the response body,
 * and converts the values to instances of Date.
 */
export const dateParseResponseInterceptor = (response: AxiosResponse<any, any>) => {
  const isJSON =
    response.headers['content-type']?.toLowerCase().startsWith('application/json') ?? false;
  if (isJSON && response.status < 300) {
    response.data = parseISODateStrings(response.data);
    return response;
  }
  return response;
};
