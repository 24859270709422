// @ts-strict-ignore
import { FeatureFlagLabel } from 'common/feature-flags/featureFlags';

export type InsightsReportTypeName =
  | 'Admissions'
  | 'Discharges'
  | 'Length of Stay'
  | 'Clinical Program Census'
  | 'Clinical Readmissions'
  | 'Clinical Compliance'
  | 'Outcomes'
  | 'Payer Types'
  | 'Census'
  | 'Client Alumni'
  | 'Encounter History'
  | 'Services'
  | 'Chart Audit'
  | 'Client Attendance'
  | 'Converted Cases Report'
  | 'Disqualified Cases Report'
  | 'Referred Out Cases Report'
  | 'Diagnosis Report'
  | 'Current Pipeline Report'
  | 'Authorizations Report'
  | 'Case Notes Report'
  | 'Organization Notes Report'
  | 'Logged PRN and All Scheduled Doses Report'
  | 'Medications Schedules'
  | 'Calendar Event Audit'
  | 'Teams Report'
  | 'Clinic Contacts Report'
  | 'Organizations Report'
  | 'Created Cases Report'
  | 'Contact Notes Report'
  | 'Care Teams Report'
  | 'Rounds'
  | 'Current Occupancy'
  | 'Scheduled Staff Event History'
  | 'Facility Census';

export type InsightsReportCategory = 'Operational' | 'Clinical' | 'Billing' | 'CRM';

export type ReportType = {
  displayName: InsightsReportTypeName;
  urlName: Insights.InsightsReportTypeURL;
  category: InsightsReportCategory;
  description: string;
  featureFlagNeeded?: string;
  hasClientFilter?: boolean;
  hasStaffFilter?: boolean;
  hasTimeRange: boolean;
};

export const insightsReportTypes: ReportType[] = [
  {
    displayName: 'Census',
    urlName: 'census-report',
    category: 'Operational',
    description:
      'For a given time period, a brief or detailed summary of all clients active during that time period',
    hasTimeRange: true,
  },
  {
    displayName: 'Client Alumni',
    urlName: 'alumni-patients',
    category: 'Operational',
    hasTimeRange: true,
    description:
      'For a given time period, a list of clients discharged during that period, with their length of stay, programs, and contact information',
  },
  {
    displayName: 'Client Attendance',
    urlName: 'client-attendance',
    category: 'Clinical',
    hasTimeRange: true,
    description:
      'For a given time period, the total number of encounters attended and absent as well as the total attended hours for each client and encounter type in that time period',
  },
  {
    displayName: 'Admissions',
    urlName: 'admissions',
    category: 'Operational',
    hasTimeRange: true,
    description:
      'For a given time period, {select either Clinical or Non-Clinical Services}, all admits and transfers into clinical programs (or non-clinical start and end service dates)',
  },
  {
    displayName: 'Clinical Compliance',
    urlName: 'clinical-compliance-form',
    category: 'Clinical',
    hasTimeRange: true,
    description:
      'For a selected form and time period, the time between each clinical admit/transfer and when the form was created, signed, and completed',
  },
  {
    displayName: 'Discharges',
    urlName: 'discharges',
    category: 'Operational',
    hasTimeRange: true,
    description:
      'For a given time period, {select either Clinical or Non-Clinical Services}, all discharges from clinical programs (or non-clinical end service dates) ',
  },
  {
    displayName: 'Length of Stay',
    urlName: 'alos',
    category: 'Operational',
    hasTimeRange: true,
    description:
      'For a given time period, the length of stay of each client in each level of care who was active in that time period',
  },
  {
    displayName: 'Clinical Program Census',
    urlName: 'program-census',
    category: 'Operational',
    hasTimeRange: true,
    description:
      'For a given time period, the beginning, ending, minimum, and maximum census for each program during that time period',
  },
  {
    displayName: 'Clinical Readmissions',
    urlName: 'readmissions',
    category: 'Clinical',
    hasTimeRange: true,
    description:
      'For a given time period, all clients admitted to a clinical program who had previously been enrolled in a clinical program. Transfers are include, but are designated as such.',
  },
  {
    displayName: 'Services',
    urlName: 'dbs',
    category: 'Billing',
    hasTimeRange: true,
    description: 'For a given time period, all services for each client',
  },
  {
    displayName: 'Encounter History',
    urlName: 'encounter-history',
    category: 'Billing',
    hasTimeRange: true,
    description: 'For a given time period, all client encounters',
    hasClientFilter: true,
  },
  {
    displayName: 'Outcomes',
    urlName: 'form-outcomes',
    category: 'Clinical',
    hasTimeRange: true,
    description:
      'For a selected encounter time period, all responses of a selected form for selected clients',
    hasClientFilter: true,
  },
  {
    displayName: 'Payer Types',
    urlName: 'payer-types',
    category: 'Billing',
    hasTimeRange: true,
    description:
      'For a given time period, a list of clients and payer types for each episode of care',
  },
  {
    displayName: 'Chart Audit',
    urlName: 'chart-audit',
    category: 'Operational',
    hasTimeRange: true,
    description:
      'For a given time period, the total number of attended encounters, group and individual, as well as the last attend encounter type and date.',
  },
  {
    displayName: 'Converted Cases Report',
    urlName: 'crm/converted-cases',
    category: 'CRM',
    hasTimeRange: true,
    description: 'For a given time period, all cases that were admitted.',
    featureFlagNeeded: FeatureFlagLabel.CRM,
  },
  {
    displayName: 'Disqualified Cases Report',
    urlName: 'crm/disqualified-cases',
    category: 'CRM',
    hasTimeRange: true,
    description: 'For a given time period, all cases that were disqualified.',
    featureFlagNeeded: FeatureFlagLabel.CRM,
  },
  {
    displayName: 'Referred Out Cases Report',
    urlName: 'crm/referred-out-cases',
    category: 'CRM',
    hasTimeRange: true,
    description: 'For a given time period, all cases that were referred out.',
    featureFlagNeeded: FeatureFlagLabel.CRM,
  },
  {
    displayName: 'Current Pipeline Report',
    urlName: 'crm/current-pipeline',
    category: 'CRM',
    hasTimeRange: false,
    description: 'All cases currently in the pipeline.',
    featureFlagNeeded: FeatureFlagLabel.CRM,
  },
  {
    displayName: 'Created Cases Report',
    urlName: 'crm/created-cases',
    category: 'CRM',
    hasTimeRange: true,
    description: 'For a given time period, all created cases and their current statuses.',
    featureFlagNeeded: FeatureFlagLabel.CRM,
  },
  {
    displayName: 'Diagnosis Report',
    urlName: 'diagnosis',
    category: 'Clinical',
    hasTimeRange: true,
    description: 'For a given time period, all active client and all their diagnoses',
  },
  {
    displayName: 'Authorizations Report',
    urlName: 'authorizations',
    category: 'Billing',
    hasTimeRange: true,
    description: 'For a given time period, selected clients and all of their authorizations',
    hasClientFilter: true,
  },
  {
    displayName: 'Case Notes Report',
    urlName: 'crm/notes/cases',
    category: 'CRM',
    hasTimeRange: true,
    description: 'For a given time period, all case notes and actions.',
    featureFlagNeeded: FeatureFlagLabel.CRM,
  },
  {
    displayName: 'Organization Notes Report',
    urlName: 'crm/notes/organizations',
    category: 'CRM',
    hasTimeRange: true,
    description: 'For a given time period, all notes on all organizations',
    featureFlagNeeded: FeatureFlagLabel.CRM,
  },
  {
    displayName: 'Contact Notes Report',
    urlName: 'crm/notes/contacts',
    category: 'CRM',
    hasTimeRange: true,
    description: 'For a given time period, all notes for all contacts.',
    featureFlagNeeded: FeatureFlagLabel.CRM,
  },
  {
    displayName: 'Logged PRN and All Scheduled Doses Report',
    urlName: 'doses',
    category: 'Clinical',
    hasTimeRange: true,
    description: 'For a given time period, logged PRN and all scheduled doses for clients.',
    hasClientFilter: true,
  },
  {
    displayName: 'Medications Schedules',
    urlName: 'medications',
    category: 'Operational',
    hasTimeRange: true,
    description:
      'For a given time period, all medications for specified clients. Default to all clients, can select specific clients.',
  },
  {
    displayName: 'Teams Report',
    urlName: 'teams',
    category: 'Operational',
    description: 'As of today, all clients and staff members by clinic team.',
    hasClientFilter: false,
    hasTimeRange: false,
  },
  {
    displayName: 'Calendar Event Audit',
    urlName: 'event-audit',
    category: 'Operational',
    description: 'For a given time period, all calendar events filter by staff member.',
    hasStaffFilter: true,
    hasTimeRange: true,
  },
  {
    displayName: 'Clinic Contacts Report',
    urlName: 'crm/clinic-contacts',
    category: 'CRM',
    description: 'All current contacts in your CRM as of today.',
    hasStaffFilter: false,
    hasTimeRange: false,
  },
  {
    displayName: 'Organizations Report',
    urlName: 'crm/clinic-organizations',
    category: 'CRM',
    description: 'All organizations in your CRM as of today.',
    hasStaffFilter: false,
    hasTimeRange: false,
  },
  {
    displayName: 'Care Teams Report',
    urlName: 'care-teams',
    category: 'Operational',
    description: 'As of today, care teams for active clients in clinical programs.',
    hasClientFilter: false,
    hasTimeRange: false,
  },
  {
    displayName: 'Rounds',
    urlName: 'rounds',
    category: 'Clinical',
    hasTimeRange: true,
    description: 'For a given time period, all documented rounds.',
    hasClientFilter: false,
    featureFlagNeeded: FeatureFlagLabel.ROUNDS,
  },
  {
    displayName: 'Current Occupancy',
    urlName: 'occupancy',
    category: 'Operational',
    hasTimeRange: false,
    description: 'As of today, the current status and occupancy of all beds.',
    hasClientFilter: false,
    featureFlagNeeded: FeatureFlagLabel.BEDBOARD,
  },
  {
    displayName: 'Scheduled Staff Event History',
    urlName: 'staff-event-history',
    category: 'Operational',
    hasTimeRange: true,
    description: 'For a date range, all scheduled staff events.',
    hasClientFilter: false,
  },
  {
    displayName: 'Facility Census',
    urlName: 'facility-census',
    category: 'Billing',
    hasTimeRange: true,
    description:
      'For a date range, the number of days each client was active in a program and facility.',
    hasClientFilter: false,
  },
];

export const getInsightsReportTypeURL = (reportName: InsightsReportTypeName) => {
  const reportType = insightsReportTypes.find((r) => r.displayName === reportName);
  return reportType.urlName;
};

export const hasClientFilter = (reportName: InsightsReportTypeName) => {
  const reportType = insightsReportTypes.find((r) => r.displayName === reportName);
  return reportType.hasClientFilter;
};

export const hasStaffFilter = (reportName: InsightsReportTypeName) => {
  const reportType = insightsReportTypes.find((r) => r.displayName === reportName);
  return reportType.hasStaffFilter;
};

export const hasTimeRange = (reportName: InsightsReportTypeName) => {
  const reportType = insightsReportTypes.find((r) => r.displayName === reportName);
  return reportType.hasTimeRange;
};

export const getAvailableInsightsReportTypes = (isFlagOn: (label: string) => boolean) => {
  return insightsReportTypes.filter((rt) =>
    rt.featureFlagNeeded ? isFlagOn(rt.featureFlagNeeded) : true,
  );
};
