// @ts-strict-ignore
import { matchPath } from 'react-router-dom';

export type PortalPage = 'forms' | 'agenda' | 'clients' | 'attachments' | 'payment-methods';

export const getPortalPageRootUrl = (page: PortalPage, clientId?: string) => {
  switch (page) {
    case 'forms':
    case 'agenda':
    case 'attachments':
    case 'payment-methods':
      return `/portal/${clientId}`;
    case 'clients':
      return `/portal`;
    default:
      return `/portal`;
  }
};

export const getSelectedClientId = (path: string): string | undefined => {
  let clientId: string;
  PORTAL_PATHS.some((pattern) => {
    const match = matchPath(path, { path: pattern, exact: true });
    if (match !== null) {
      ({ clientId } = match.params as { clientId: string });
      return true;
    }
    return false;
  });
  return clientId;
};

const PORTAL_PATHS = [
  '/portal/:clientId/forms',
  '/portal/:clientId/agenda',
  '/portal/:clientId/forms/:formId',
  '/portal/:clientId/attachments',
  '/portal/:clientId/payment-methods',
];

export const getUserFormAccessRole = (
  form: Forms.FormInstance,
  userPatientId: string,
  isActive: boolean,
) => {
  let formPortalAccessRoles = form.portalAccessRoles;
  if (isActive) {
    formPortalAccessRoles = formPortalAccessRoles.filter((role) => role.deletedAt === null);
  }
  return formPortalAccessRoles.find((role) => role.user.patientId === userPatientId);
};

export const getIncompleteFormStatusText = (role: Forms.AccessRole) => {
  if (role === 'edit_and_sign') {
    return 'Information & Signature Needed';
  }
  if (role === 'edit_only') {
    return 'Information Needed';
  }
  if (role === 'sign_only') {
    return 'Signature Needed';
  }
  return 'Information Needed';
};

export const hasUserCompletedAssignedFormRole = (
  form: Forms.FormInstance,
  userPatientId: string,
) => {
  const par = getUserFormAccessRole(form, userPatientId, true);
  if (par) {
    const { accessRole } = par;
    const formSubmittedByUser = form.submissions.some(
      (submission) => submission.userPatientId === userPatientId,
    );
    const formSignedByUser = form.signatures?.some(
      (sig) => sig.patient.patientId === userPatientId,
    );
    if (accessRole === 'edit_only' && formSubmittedByUser) {
      return true;
    }
    if (accessRole === 'edit_and_sign' && formSignedByUser) {
      // while we expect formSubmittedByUser to be true as well,
      // we don't check it here because there are edge cases from the original portal form sharing implementation
      // and with the way we currently call the sign and submit api's sequentially which could result in formSubmittedByUser being false
      // despite that, we still want to show the form as completed
      return true;
    }
    if (accessRole === 'sign_only' && formSignedByUser) {
      return true;
    }
    if (accessRole === 'view_only') {
      return false;
    }
  }
  return false;
};

export const getDateUserCompletedAssignedFormRole = (
  form: Forms.FormInstance,
  userPatientId: string,
): Date | null => {
  const par = getUserFormAccessRole(form, userPatientId, true);
  if (par) {
    const { accessRole } = par;
    switch (accessRole) {
      case 'edit_only':
        return form.submissions.find((submission) => submission.userPatientId === userPatientId)
          ?.createdAt;
      case 'edit_and_sign':
      case 'sign_only':
        return form.signatures?.find((sig) => sig.patient.patientId === userPatientId)?.createdAt;
      default:
        return null;
    }
  }
  return null;
};
