import { PropsWithChildren } from 'react';

import useFeatureFlags from '../../context/featureFlags/useFeatureFlags';

interface FeatureFlagProps {
  /**
   * Corresponds to the feature flag label, e.g. FeatureFlag.MAR.
   *
   * With multiple labels, the default matching behavior is to match ALL,
   * however this behavior can be changed by utilizing the `matchAny` prop.
   */
  label: string | string[];
  /**
   * Changes the label matching behavior to match ANY of the labels supplied.
   */
  matchAny?: boolean;
}

/**
 * FeatureFlag is a component that conditionally renders children,
 * based on whether a feature flag label is on or not.
 *
 * If the feature flag is off, it will return `null`.
 *
 * Use this over the `useFeatureFlag` hook when you want a more
 * React-way to declare feature flag logic within a render func.
 */
function FeatureFlag({ label, matchAny = false, children }: PropsWithChildren<FeatureFlagProps>) {
  const { isFlagOn } = useFeatureFlags();
  const labels = Array.isArray(label) ? label : [label];
  const matchFn = matchAny ? 'some' : 'every';
  const flagIsOn = labels[matchFn]((label) => isFlagOn(label));
  return flagIsOn ? <>{children}</> : null;
}

export default FeatureFlag;
