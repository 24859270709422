import { Helmet } from 'react-helmet';

import useMobileDevice from 'context/mobileDevice/useMobileDevice';

const ViewportMetaTagHandler = () => {
  const { checkIfViewportMatchesMobileDevice } = useMobileDevice();

  return (
    <Helmet onChangeClientState={() => checkIfViewportMatchesMobileDevice()}>
      <meta name="viewport" content="width=device-width, initial-scale=1" />
    </Helmet>
  );
};

export default ViewportMetaTagHandler;
