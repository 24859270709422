import { parseISODateStrings } from 'utils';
import RittenClient from './RittenClient';

class ReportingClient extends RittenClient {
  getPatientChartData = async (
    patientId: string,
    fieldDefinitionId: string,
    order?: 'ASC' | 'DESC',
    csv?: boolean,
  ) => {
    const res = await this.post<Reporting.ChartDataPoint[]>('/api/reporting/charts', {
      chartType: 'PatientTrends',
      chartParams: {
        patientId,
        fieldDefinitionId,
        order,
      },
      csv,
    } as Reporting.GetPatientChartDataPostBody);

    return parseISODateStrings(res.data);
  };
}

export default ReportingClient;
