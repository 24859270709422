import { LegacyRef, useEffect, useRef, useState } from 'react';
import { Box, Text } from 'grommet';

import { StandardButton } from '@ritten/ui-library/buttons';
import { ErrorPanel } from '@ritten/ui-library/errors';
import { TextAreaComponent } from '@ritten/ui-library/form-inputs/TextAreaComponent';
import { BaseModal } from '@ritten/ui-library/modals';
import LoadingPanel from '@ritten/ui-library/panels/LoadingPanel';

import useMobileDevice from 'context/mobileDevice/useMobileDevice';
import useAPI from 'external/useAPI';
import { useErrorState, useLoadingState } from 'hooks';
import { removeNullBytes } from 'utils/stringUtils';

interface AIGenerationModalProps {
  chartIntelligenceType: Clinic.ChartIntelligenceType;
  formId: string;
  existingValue: string;
  onSubmit: (value: string) => void;
  onCancel: () => void;
}

const AIGenerationModal = (props: AIGenerationModalProps): JSX.Element => {
  const { formId, onSubmit, onCancel, chartIntelligenceType, existingValue } = props;

  const { clinicAPI } = useAPI();
  const { loading, needsLoadingState } = useLoadingState(false);
  const { errors, addError, clearErrors } = useErrorState();
  const { isMobile } = useMobileDevice();

  const [generatedText, setGeneratedText] = useState<string>('');
  const [generating, setGenerating] = useState<boolean>(false);
  const [generationSuccessful, setGenerationSuccessful] = useState<boolean>(false);
  const [editableText, setEditableText] = useState<string>('');
  const generatedTextRef = useRef<string>('');
  const modalBottomRef = useRef<HTMLDivElement>();
  const cancelStreamReading = useRef<boolean>(false);

  // kick off the generation process
  useEffect(() => {
    generate();
  }, []);

  // keep scrolling to the bottom of the generated text
  useEffect(() => {
    scrollToBottom();
  }, [generatedText]);

  const scrollToBottom = () => {
    if (modalBottomRef.current) {
      try {
        modalBottomRef.current.scrollIntoView({ behavior: 'instant' as ScrollBehavior });
      } catch (error) {
        modalBottomRef.current.scrollIntoView(false);
      }
    }
  };

  const generate = needsLoadingState(async () => {
    clearErrors();
    setGenerating(true);
    try {
      const streamReader = await clinicAPI.postChartIntelligenceGenerate({
        type: chartIntelligenceType,
        formId,
        inputText: existingValue,
      });
      const decoder = new TextDecoder();
      let streaming = true;
      while (streaming) {
        // streaming ...
        // TODO: consider setting some kind of timeout on this loop just in case
        if (cancelStreamReading.current) {
          // streaming cancelled.
          streaming = false;
          break;
        }
        const { done, value } = await streamReader.read();
        if (done) {
          // streaming finished.
          streaming = false;
          break;
        }
        // data received.
        const textValue = decoder.decode(value);
        generatedTextRef.current += removeNullBytes(textValue);
        setGeneratedText(generatedTextRef.current);
        setEditableText(generatedTextRef.current);
      }
      // streaming finished or cancelled.
      setGenerating(false);
      if (cancelStreamReading.current) {
        // if it was cancelled, cancel the stream reader
        await streamReader.cancel();
      } else {
        // if the summarization was not cancelled generation was successful
        setGenerationSuccessful(true);
      }
    } catch (err) {
      addError(err);
      setGenerating(false);
    }
  });

  const cancelGeneration = () => {
    cancelStreamReading.current = true;
  };

  const headerText = (): JSX.Element => {
    let text = '';
    switch (chartIntelligenceType) {
      case 'BPSSummarization':
        text = 'BPS Summarization';
        break;
      case 'ImproveTextGeneric':
        text = 'Improving Text';
        break;
      default:
        text = 'Chart Intelligence';
        break;
    }
    return (
      <Text weight="bold" size="18px">
        {text}
      </Text>
    );
  };

  return (
    <BaseModal
      isOpen
      onClose={onCancel}
      width={isMobile ? '100%' : '840px'}
      height={isMobile ? '100%' : '800px'}
      shouldPreventClickOutside
      header={
        <Box gap="12px" pad={{ top: '12px' }}>
          <Box direction="row" fill>
            {headerText()}
          </Box>
          <Text wordBreak="normal" size="14px">
            Please stay on this page in order for the generation process to complete. It may take a
            few minutes.
          </Text>
        </Box>
      }
      footer={
        <Box direction="row" fill justify="between" align="center">
          <Box direction="row">
            <StandardButton
              label="Cancel"
              color="alert"
              styleVariant="outline"
              onClick={() => {
                if (generating) {
                  cancelGeneration();
                }
                onCancel();
              }}
            />
          </Box>
          <Box direction="row" gap="12px">
            <StandardButton
              label="Rerun"
              styleVariant="outline"
              disabled={loading}
              onClick={() => {
                setGenerationSuccessful(false);
                generatedTextRef.current = '';
                setGeneratedText('');
                setEditableText('');
                generate();
              }}
            />
            <StandardButton
              label="Accept"
              disabled={!generationSuccessful}
              onClick={() => {
                onSubmit(editableText);
              }}
            />
          </Box>
        </Box>
      }
    >
      <Box pad={{ top: '12px' }} gap="12px">
        {existingValue !== '' && (
          <TextAreaComponent
            label="Existing Text:"
            textAreaProps={{
              style: { overflow: 'hidden' },
              value: existingValue,
              disabled: true,
            }}
          />
        )}
        <ErrorPanel errors={errors} />
        <LoadingPanel showLoader={loading} overflow="hidden">
          <TextAreaComponent
            label="Generated Text:"
            textAreaProps={{
              style: { overflow: 'hidden' },
              disabled: loading,
              value: editableText,
              onChange: (e) => {
                setEditableText(e.target.value);
              },
            }}
          />
        </LoadingPanel>
        <div ref={modalBottomRef as LegacyRef<HTMLDivElement>} />
      </Box>
    </BaseModal>
  );
};

export default AIGenerationModal;
