import { Box, BoxProps } from 'grommet';

import { COLORS } from '../../styles/colors';

export type DashedBoxSkeletonProps = BoxProps & {
  height?: string;
  width?: string;
  borderColor?: string;
  borderSize?: string;
  background?: string;
};

export const DashedBoxSkeleton = (props: React.PropsWithChildren<DashedBoxSkeletonProps>) => {
  const {
    height,
    width,
    children,
    borderColor = COLORS.darkGray400,
    borderSize = '1px',
    background = 'rgba(255, 255, 255, 0.2)',
    ...rest
  } = props;
  return (
    <Box
      height={height ?? '70px'}
      width={width}
      style={{
        minHeight: height ?? '70px',
      }}
      border={{
        color: borderColor,
        size: borderSize,
        style: 'dashed',
      }}
      background={background}
      {...rest}
    >
      {children}
    </Box>
  );
};

export default DashedBoxSkeleton;
