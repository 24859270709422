// @ts-strict-ignore
import { Box, Select, SelectProps, ThemeContext } from 'grommet';
import { grommet } from 'grommet/themes';
import { deepMerge } from 'grommet/utils';

import { COLORS } from '../../styles/colors';
import ErrorLabel from '../labels/ErrorLabel';
import InputLabel from './InputLabel';

export interface DropdownComponentProps {
  label?: string;
  dropdownProps: SelectProps & { ref?: React.MutableRefObject<any>; style?: any };
  width?: string;
  textColor?: string;
  placeholderColor?: string;
  borderColor?: string;
  iconColor?: string;
  iconMargin?: string;
  'data-cy'?: string;
  'data-testid'?: string;
  ariaLabel?: string;
  requirementMarker?: string;
  tooltipText?: string;
  error?: string;
  height?: string;
  inputLabel?: React.ReactNode;
}

/**
 * @deprecated in favor of Dropdown
 */
export const DropdownComponent = (props: DropdownComponentProps) => {
  const themeSCDropdown = deepMerge(grommet, {
    global: {
      colors: {
        focus: undefined,
        placeholder: props.placeholderColor ?? 'rgba(0, 0, 0, .33)',
        border: props.borderColor ?? 'rgba(0, 0, 0, .33)',
        selected: COLORS.rittenBlue400,
      },
    },
    textInput: {
      extend: {
        color: props.textColor ?? COLORS.darkGray600,
        backgroundColor: props.dropdownProps.disabled ? COLORS.darkGray200 : COLORS.white,
        fontSize: '16px',
        fontWeight: 400,
        height: props.height ?? '38px',
      },
      disabled: {
        opacity: 1,
      },
    },
    select: {
      disabled: {
        opacity: 1,
      },
      container: {
        extend: {
          backgroundColor: props.dropdownProps.disabled ? COLORS.darkGray200 : COLORS.white,
          fontFamily: 'Circular',
        },
      },
      icons: {
        color: props.iconColor ?? COLORS.darkGray600,
        margin: props.iconMargin ?? '0 12px',
      },
      background: props.dropdownProps.disabled ? COLORS.darkGray200 : COLORS.white,
    },
    button: {
      extend: {
        width: props.width ? props.width : 'auto',
        backgroundColor: props.dropdownProps.disabled ? COLORS.darkGray200 : COLORS.white,
      },
      disabled: {
        extend: {
          fontSize: '10px',
          fontWeight: 400,
        },
        opacity: 1,
      },
    },
    box: {
      extend: {
        borderRadius: '4px',
      },
    },
  });

  return (
    <ThemeContext.Extend value={themeSCDropdown}>
      <Box data-cy={props['data-cy']} data-testid={props['data-testid']} flex="grow">
        {props.inputLabel ? (
          props.inputLabel
        ) : (
          <InputLabel
            label={props.label || ''}
            requirementMarker={props.requirementMarker}
            tooltipText={props.tooltipText}
          />
        )}
        <Select {...props.dropdownProps} aria-label={props.ariaLabel || props.label || ''} />
        {props.error && (
          <Box pad={{ top: 'xxsmall' }}>
            <ErrorLabel>{props.error}</ErrorLabel>
          </Box>
        )}
      </Box>
    </ThemeContext.Extend>
  );
};

export default DropdownComponent;
