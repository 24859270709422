import { PropsWithChildren } from 'react';
import { Box, BoxExtendedProps } from 'grommet';

import { COLORS, DROP_SHADOW } from 'styles/colors';

type ContentBoxProps = PropsWithChildren<{
  noBoxShadow?: boolean;
}> &
  BoxExtendedProps;

const ContentBox = (props: ContentBoxProps) => {
  const { children, noBoxShadow = false, style = {}, ...rest } = props;
  return (
    <Box
      round="8px"
      background={COLORS.white}
      style={{ boxShadow: noBoxShadow ? undefined : DROP_SHADOW, ...style }}
      {...rest}
    >
      {children}
    </Box>
  );
};

export default ContentBox;
