import { Box, grommet, Text, ThemeContext, Tip } from 'grommet';
import { deepMerge } from 'grommet/utils';
import { CircleQuestion } from 'grommet-icons';

import { COLORS } from '../../styles/colors';

export interface InputLabelProps {
  label?: string;
  requirementMarker?: string;
  tooltipText?: string;
  textSize?: string;
}

export const labelTooltipTheme = deepMerge(grommet, {
  tip: {
    content: {
      background: COLORS.white,
      pad: '4px',
      round: 'xsmall',
    },
  },
});

const InputLabel = (props: InputLabelProps) => {
  if (props.label && props.label.length > 0) {
    return (
      <Box pad={{ bottom: '4px' }} direction="row" justify="between">
        <Box direction="row" align="center" gap="6px">
          <Text size={props.textSize ?? '14px'}>{props.label}</Text>
          {props.tooltipText && (
            <Box margin={{ top: '2px' }}>
              <ThemeContext.Extend value={labelTooltipTheme}>
                <Tip
                  content={
                    <Box>
                      {props.tooltipText.split('\n').map((line, index) => (
                        <Text size="13px" key={index}>
                          {line}
                        </Text>
                      ))}
                    </Box>
                  }
                >
                  <CircleQuestion size={props.textSize ?? '14px'} color={COLORS.darkGray400} />
                </Tip>
              </ThemeContext.Extend>
            </Box>
          )}
        </Box>
        {props.requirementMarker && (
          <Text
            size={props.textSize ?? '13px'}
            color={props.requirementMarker === 'Required' ? COLORS.red400 : COLORS.darkGray400}
          >
            {props.requirementMarker}
          </Text>
        )}
      </Box>
    );
  }
  return null;
};

export default InputLabel;
