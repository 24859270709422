import { ReactNode } from 'react';
import { Provider } from 'react-redux';
import { Router } from 'react-router-dom';
import { Auth0Provider } from '@auth0/auth0-react';

import { store } from '../../redux/store';
import { history } from '../../third-party/history';
import useUIConfig from '../config/useUIConfig';

export const Auth0App: React.FC = (props: { children?: ReactNode }) => {
  const { children } = props;
  const { config } = useUIConfig();

  const onRedirectCallback = () => {
    window.history.replaceState(
      {},
      document.title,
      `${window.location.origin}${window.location.hash}`,
    );
  };

  return (
    <Auth0Provider
      domain={config.auth0Domain}
      clientId={config.auth0ClientId}
      redirectUri={window.location.href}
      onRedirectCallback={onRedirectCallback}
      audience={config.auth0Audience}
      cacheLocation="localstorage"
      useRefreshTokens={true}
      scope="offline_access"
    >
      <Router history={history}>
        <Provider store={store}>{children}</Provider>
      </Router>
    </Auth0Provider>
  );
};
