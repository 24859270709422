// @ts-strict-ignore
import { AxiosRequestConfig } from 'axios';

import { convertDateToISOWithLocalTime, parseISODateStrings } from 'utils';
import RittenClient from './RittenClient';

class CalendarClient extends RittenClient {
  getEvents = async (body: Calendar.EventQueryBody) => {
    const res = await this.post<Calendar.CalendarEvent[]>(`/api/calendar/events/list`, {
      ...body,
      startTime: convertDateToISOWithLocalTime(body.startTime),
      endTime: convertDateToISOWithLocalTime(body.endTime),
    });
    return parseISODateStrings(res.data);
  };

  postEvent = async (body: Calendar.EventPostBody) => {
    const res = await this.post<API.IDResponse>('/api/calendar/events', {
      ...body,
      startTime: convertDateToISOWithLocalTime(body.startTime),
      endTime: convertDateToISOWithLocalTime(body.endTime),
      until: convertDateToISOWithLocalTime(body.until),
    });
    return res.data;
  };

  putEvent = async (
    id: string,
    dateTime: Date,
    type: 'single' | 'series',
    body: Calendar.EventPostBody,
  ) => {
    const dateUrlString = convertDateToISOWithLocalTime(dateTime);
    const typeUrl = type === 'single' ? 'single' : 'future';
    const res = await this.put<API.IDResponse>(
      `/api/calendar/events/${id}/date/${dateUrlString}/${typeUrl}`,
      {
        ...body,
        startTime: convertDateToISOWithLocalTime(body.startTime),
        endTime: convertDateToISOWithLocalTime(body.endTime),
        until: convertDateToISOWithLocalTime(body.until),
      },
    );
    return res.data;
  };

  getEvent = async (id: string, dateTime: Date) => {
    const dateUrlString = convertDateToISOWithLocalTime(dateTime);
    const res = await this.get<Calendar.CalendarEvent>(
      `/api/calendar/events/${id}/date/${dateUrlString}`,
    );
    return parseISODateStrings(res.data);
  };

  deleteEvent = async (
    id: string,
    dateTime: Date,
    type: Calendar.DeletionType,
    notify: boolean,
  ) => {
    const dateUrlString = convertDateToISOWithLocalTime(dateTime);
    const res = await this.delete<API.IDResponse>(
      `/api/calendar/events/${id}/date/${dateUrlString}`,
      {
        params: {
          type,
          notify,
        },
      },
    );
    return res.data;
  };

  postEventEncounter = async (id: string, dateTime: Date) => {
    const dateUrlString = convertDateToISOWithLocalTime(dateTime);
    const res = await this.post<Calendar.EventStart>(
      `/api/calendar/events/${id}/date/${dateUrlString}/start`,
    );
    return res.data;
  };

  deletePatientEvents = async (patientId: string, deleteFrom: Date): Promise<void> => {
    await this.delete<void>(`/api/calendar/patients/${patientId}/events`, {
      params: {
        startTime: deleteFrom,
      },
    });
  };

  getSchedulerAccount = async () => {
    const res = await this.get<Calendar.NylasAccount>(`/api/integrations/nylas/admin/accounts`);
    return res.data;
  };

  deleteSchedulerAccount = async (accountId: string): Promise<void> => {
    await this.delete<void>(`/api/integrations/nylas/admin/accounts/${accountId}`);
  };

  authenticateWithNylas = async (tenantId: string, userId: string, isScheduler: boolean) => {
    const params: AxiosRequestConfig = {
      params: {
        tenantId,
        userId,
        isScheduler,
      },
    };
    const res = await this.get<API.OAuth>(`/api/oauth/nylas`, params);
    return res.data;
  };

  processExternalWebhooks = async () => {
    const res = await this.post(`/api/events/nylas/pull`);
    return res.data;
  };

  createNylasAccount = async (code: string, isScheduler: boolean) => {
    const res = await this.post<Calendar.NylasAccount>('/api/integrations/nylas/accounts', {
      code,
      isScheduler,
    });
    return res.data;
  };

  listNylasAccounts = async () => {
    const res = await this.get<Calendar.NylasAccount[]>(`/api/integrations/nylas/accounts`);
    return res.data;
  };

  deleteNylasAccount = async (accountId: string): Promise<void> => {
    await this.delete<void>(`/api/integrations/nylas/accounts/${accountId}`);
  };

  getConflictsForProposedEvent = async ({
    startTime,
    endTime,
    entityIds,
  }: {
    startTime: Date;
    endTime: Date;
    entityIds: string[];
  }): Promise<Calendar.EventConflict[]> => {
    const { data = [] } = await this.post<Calendar.EventConflict[]>(
      `/api/calendar/events/conflicts`,
      {
        startTime,
        endTime,
        entityIds,
      },
    );
    return parseISODateStrings(data);
  };
}

export default CalendarClient;
