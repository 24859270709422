import { ReactNode, useState } from 'react';
import _ from 'lodash';

export interface NavItem {
  label: string;
  active: boolean;
  danger?: boolean;
  special?: boolean;
  disabled?: boolean;
  labelBadge?: ReactNode;
}

export const useNavState = (nav?: NavItem[]) => {
  const [navItems, setNavItemsRaw] = useState<NavItem[]>(nav?.filter((n) => !n.disabled) ?? []);

  const onNavItemClick = (navItem: NavItem) => {
    setNavItemsRaw((prevValue) => {
      return prevValue.map((prev) => {
        if (prev.label === navItem.label) {
          prev.active = true;
        } else {
          prev.active = false;
        }
        return prev;
      });
    });
  };

  const setNavItems = (newNavItems: NavItem[]) => {
    setNavItemsRaw((prevValue) => {
      // Only change value if a new label is added, otherwise leave existing nav item list in tact.
      const difference = _.differenceBy(prevValue, newNavItems, 'label');
      if (difference?.length > 0) {
        return newNavItems;
      }
      return prevValue;
    });
  };

  const getActiveLink = () => {
    return _.find(navItems, { active: true });
  };

  return {
    navItems,
    setNavItems,
    onNavItemClick,
    getActiveLink,
  };
};
