import { useEffect, useState } from 'react';
import { Toaster } from 'react-hot-toast';
import { useRouteMatch } from 'react-router-dom';
import { GoogleOAuthProvider } from '@react-oauth/google';
import { Box, Grid } from 'grommet';

import RefreshDesktopViewModal from 'common/modals/RefreshDesktopViewModal';
import useUIConfig from 'context/config/useUIConfig';
import useMobileDevice from 'context/mobileDevice/useMobileDevice';
import { PatientEventProvider, usePatientEventContext } from 'context/patients/PatientEventContext';
import DownloadsModal from 'features/downloads/DownloadsModal';
import { useResponsiveDeviceSize } from 'hooks';
import ErrorBoundary from '../../common/ErrorBoundary';
import FeatureFlagProvider from '../../context/featureFlags/FeatureFlagProvider';
import TenantProvider from '../../context/tenant/TenantProvider';
import UserProvider from '../../context/user/UserProvider';
import NavBar, { SIDE_NAV_WIDTH_CLOSED } from './nav/NavBar';
import Routes from './routes';

const smallAppRows = ['auto', 'flex'];
const smallAppColumns = ['100%'];
const smallAppAreas = [['side-nav'], ['main-content']];

const normalAppRows = ['100%'];
const normalAppColumns = ['45px', '1fr'];
const normalAppAreas = [['side-nav', 'main-content']];

// Certain paths (e.g. callback URLs) are rendered without the application chrome.
// The chrome is the fixed aspects of a design or GUI (e.g. navbars, toolbars, etc.).
const CHROMELESS_ROUTE_PATHS = ['/callback', '/r'];

const AppContent = () => {
  const { deviceSize } = useResponsiveDeviceSize();
  const { isSmallish } = deviceSize;
  const { exportEvent } = usePatientEventContext();
  const { showRefreshPageFlyout, isMobile } = useMobileDevice();

  const isChromeless = CHROMELESS_ROUTE_PATHS.map((path) => useRouteMatch(path)).some(
    (match) => match !== null,
  );

  const [currentExportEventRef, setCurrentExportEventRef] = useState<number>(0);
  const [isDownloadsModalOpen, setIsDownloadsModalOpen] = useState<boolean>(false);

  const getRows = () => {
    if (isChromeless) {
      return ['100%'];
    }
    return isSmallish ? smallAppRows : normalAppRows;
  };

  const getColumns = () => {
    if (isChromeless) {
      return ['100%'];
    }
    return isSmallish ? smallAppColumns : normalAppColumns;
  };

  const getAreas = () => {
    if (isChromeless) {
      return [['main-content']];
    }
    return isSmallish ? smallAppAreas : normalAppAreas;
  };

  useEffect(() => {
    setCurrentExportEventRef(exportEvent);
    if (exportEvent !== currentExportEventRef) {
      setIsDownloadsModalOpen(true);
    }
  }, [exportEvent]);

  return (
    <Grid
      rows={getRows()}
      columns={getColumns()}
      areas={getAreas()}
      height={isMobile ? '100%' : '100vh'}
      width="100vw"
    >
      {!isChromeless && (
        <Box gridArea="side-nav">
          <NavBar openDownloadsModal={() => setIsDownloadsModalOpen(true)} />
        </Box>
      )}
      <Box gridArea="main-content">
        <ErrorBoundary>
          <Routes />
          {isDownloadsModalOpen && (
            <DownloadsModal onClose={() => setIsDownloadsModalOpen(false)} />
          )}
        </ErrorBoundary>
      </Box>
      {showRefreshPageFlyout && <RefreshDesktopViewModal />}
      <Toaster
        position="bottom-left"
        toastOptions={{
          className: 'ritten-hot-toast',
        }}
        containerStyle={{ left: isMobile ? 16 : SIDE_NAV_WIDTH_CLOSED, zIndex: 11 }} // align next to side bar nav on desktop / with page pading on mobile
      />
    </Grid>
  );
};

const ClinicApp = () => {
  const { config } = useUIConfig();
  return (
    <TenantProvider>
      <FeatureFlagProvider>
        <UserProvider>
          <PatientEventProvider>
            <GoogleOAuthProvider clientId={config.nylasGoogleClientID}>
              <AppContent />
            </GoogleOAuthProvider>
          </PatientEventProvider>
        </UserProvider>
      </FeatureFlagProvider>
    </TenantProvider>
  );
};

export default ClinicApp;
