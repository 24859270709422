import { BsGear, BsPersonFill } from 'react-icons/bs';
import { MdInsights } from 'react-icons/md';
import { RiContactsBookLine, RiCustomerServiceFill } from 'react-icons/ri';
import { Box, Nav, Text } from 'grommet';
import {
  Calendar,
  CircleQuestion,
  Compliance,
  DownloadOption,
  Group,
  HomeRounded,
  Logout,
  Money,
} from 'grommet-icons';

import AlertCount from '@ritten/ui-library/labels/AlertCount';
import LineBreak from '@ritten/ui-library/line-break/LineBreak';

import FeatureFlag from 'common/feature-flags/FeatureFlag';
import { FeatureFlagLabel } from 'common/feature-flags/featureFlags';
import ProtectedNavLink from 'features/nav/ProtectedNavLink';
import { COLORS } from 'styles/colors';
import { NavLinkContainer, StyledMenuNavLink } from './NavLink';
import { SidebarNavLinksProps } from './SidebarNavLinks';
import { renderDownloadsCount } from './utils';

const CollapsibleMenuNavLinks = ({
  isCrmFeatureFlagOn,
  userNotificationsCount,
  downloadsCount,
  logout,
  onOpenDownloadsModal,
  openMenuType,
  dosespotNotificationsCount,
}: SidebarNavLinksProps & { openMenuType: 'top' | 'bottom' | null }) =>
  openMenuType === 'top' ? (
    <Box fill>
      <Nav gap="none" background={COLORS.darkGray600}>
        <StyledMenuNavLink
          to="home"
          label="Home"
          icon={<HomeRounded size="18px" color={COLORS.white} />}
        />
        <StyledMenuNavLink
          to="crm"
          label={isCrmFeatureFlagOn ? 'Admissions' : 'Contacts'}
          icon={<RiContactsBookLine size="20px" color={COLORS.white} />}
        />
        <StyledMenuNavLink
          to="records"
          label="Records"
          icon={<Group size="18px" color={COLORS.white} />}
        />
        <StyledMenuNavLink
          to="calendar"
          label="Calendar"
          icon={<Calendar size="18px" color={COLORS.white} />}
        />
        <StyledMenuNavLink
          to="insights"
          label="Insights"
          icon={<MdInsights size="18px" color={COLORS.white} />}
        />
        <FeatureFlag label={FeatureFlagLabel.BILLING}>
          <StyledMenuNavLink
            to="billing"
            label="Billing"
            icon={<Money size="18px" color={COLORS.white} />}
          />
        </FeatureFlag>
        <FeatureFlag label={FeatureFlagLabel.SIGNATURE_ACTIONS}>
          <StyledMenuNavLink
            to="compliance"
            label="Compliance"
            userNotificationsCount={userNotificationsCount}
            icon={<Compliance size="18px" color={COLORS.white} />}
          />
        </FeatureFlag>
        <StyledMenuNavLink
          to="admin"
          label="Configurations"
          icon={<BsGear size="18px" color={COLORS.white} />}
        />
      </Nav>
    </Box>
  ) : (
    <Box fill justify="between">
      <Nav gap="none">
        <NavLinkContainer onClick={onOpenDownloadsModal} isSidebar={false}>
          <Box direction="row" align="center" gap="8px" pad="0 16px">
            <DownloadOption size="16px" />
            <Text color={COLORS.white} size="14px" weight={700}>
              Downloads
            </Text>
            {renderDownloadsCount(downloadsCount, 'inline')}
          </Box>
        </NavLinkContainer>
        <ProtectedNavLink
          to="/user"
          style={{ width: '100%', textDecoration: 'none' }}
          activeStyle={{ width: '100%', textDecoration: 'none' }}
        >
          <NavLinkContainer isSidebar={false}>
            <Box direction="row" align="center" gap="8px" pad="0 16px">
              <Box
                round="full"
                border={{ size: '1px', style: 'solid', color: COLORS.white }}
                pad="2px"
              >
                <BsPersonFill size="10px" color={COLORS.white} />
              </Box>
              <Text color={COLORS.white} size="14px" weight={700}>
                User
              </Text>
              {dosespotNotificationsCount !== null && (
                <AlertCount
                  count={dosespotNotificationsCount}
                  hideIfZero
                  data-testid="user-notification-count"
                  size="19px"
                  fontSize="11px"
                />
              )}
            </Box>
          </NavLinkContainer>
        </ProtectedNavLink>
        <NavLinkContainer
          isSidebar={false}
          onClick={() => window.open('https://ritten.zendesk.com/hc/en-us')}
        >
          <Box direction="row" align="center" gap="8px" pad="0 16px">
            <CircleQuestion size="16px" />
            <Text color={COLORS.white} size="14px" weight={700}>
              Support Center
            </Text>
          </Box>
        </NavLinkContainer>
        <NavLinkContainer
          isSidebar={false}
          onClick={() => window.open('https://ritten.zendesk.com/hc/en-us/requests/new')}
        >
          <Box direction="row" align="center" gap="8px" pad="0 16px">
            <Box
              round="full"
              border={{ size: '1px', style: 'solid', color: COLORS.white }}
              pad="2px"
            >
              <RiCustomerServiceFill color={COLORS.white} size="10px" />
            </Box>
            <Text color={COLORS.white} size="14px" weight={700}>
              Submit a Ticket
            </Text>
          </Box>
        </NavLinkContainer>
      </Nav>
      <Nav gap="none">
        <LineBreak color={COLORS.darkGray400} height="1px" />
        <NavLinkContainer
          isSidebar={false}
          onClick={() => logout({ returnTo: window.location.href })}
        >
          <Box direction="row" align="center" gap="8px" pad="0 16px">
            <Logout size="16px" color={COLORS.red400} />
            <Text color={COLORS.red400} size="14px" weight={700}>
              Log Out
            </Text>
          </Box>
        </NavLinkContainer>
      </Nav>
    </Box>
  );

export default CollapsibleMenuNavLinks;
