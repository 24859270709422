import { useEffect } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { Box, Grid, Text } from 'grommet';
import { LinkNext } from 'grommet-icons';
import { partition } from 'lodash';

import ContentBox from '@ritten/ui-library/boxes/ContentBox';
import { DashedBoxSkeleton } from '@ritten/ui-library/skeletons';

import UserInitials from 'common/UserInitials';
import useUser from 'context/user/useUser';
import { COLORS } from 'styles/colors';
import { getFullName } from 'utils';
import { getPortalPageRootUrl } from './utils';
import WelcomeHeader from './WelcomeHeader';

const ClientPortalDashboardLink = (props: { client: Patient.PatientName }) => {
  const { client } = props;
  return (
    <Link
      to={`${getPortalPageRootUrl('forms', client.patientId)}/forms`}
      style={{ textDecoration: 'none' }}
      key={client.patientId}
    >
      <ContentBox
        pad={{ horizontal: '24px', vertical: '12px' }}
        margin={{ vertical: '10px' }}
        justify="between"
        align="center"
        direction="row"
      >
        <Box direction="row" align="center" fill="horizontal">
          <UserInitials
            backgroundColor={COLORS.rittenBlue200}
            height="48px"
            width="48px"
            textColor={COLORS.black}
            textSize="20px"
            user={client}
          />
          <Text size="16px" margin={{ horizontal: '8px' }} wordBreak="break-word">
            {getFullName(client)}
          </Text>
        </Box>
        <Box margin="8px" flex={false}>
          <LinkNext color={COLORS.darkGray600} size="24px" />
        </Box>
      </ContentBox>
    </Link>
  );
};

interface PortalClientPageProps {
  accessibleContacts: Users.PortalAuthorization[];
}

const PortalClientsPage = (props: PortalClientPageProps) => {
  const { accessibleContacts } = props;

  const { user } = useUser();
  const history = useHistory();

  const [userContact, otherContacts] = partition(
    accessibleContacts,
    (contact) => contact.patient.patientId === user.patientId,
  );

  useEffect(() => {
    if (accessibleContacts.length === 1) {
      // redirect to sole accessible contacts' forms page
      history.replace(
        `${getPortalPageRootUrl('forms', accessibleContacts[0].patient.patientId)}/forms`,
      );
    }
  }, [accessibleContacts]);

  return (
    <Box
      fill="horizontal"
      height={{ min: '100%' }}
      background={COLORS.darkGray200}
      align="center"
      gap="24px"
      pad={{ bottom: 'large' }}
    >
      {accessibleContacts.length > 1 ? (
        <WelcomeHeader>
          <Text size="14px" weight={700}>
            Select the client chart you would like to view.
          </Text>
        </WelcomeHeader>
      ) : (
        <WelcomeHeader>
          <Text size="14px" weight={700}>
            You do not have access to any clients at this time.
          </Text>
        </WelcomeHeader>
      )}
      <Grid
        pad={{ horizontal: '24px' }}
        width={{ max: '1000px', width: '100%' }}
        height={{ min: 'auto' }}
        areas={[['formsList']]}
        rows={['1fr']}
        columns={['1fr']}
      >
        <Box gridArea="formsList" gap="12px">
          {accessibleContacts.length > 1 ? (
            <>
              {userContact[0] && (
                <Box gap="12px">
                  <Text size="16px" color={COLORS.black}>
                    My Chart
                  </Text>
                  <ClientPortalDashboardLink client={userContact[0].patient} />
                </Box>
              )}
              <Box gap="12px">
                <Text size="16px" color={COLORS.black}>
                  Your Contacts
                </Text>
                {otherContacts.map((contact) => (
                  <ClientPortalDashboardLink
                    client={contact.patient}
                    key={contact.patient.patientId}
                  />
                ))}
              </Box>
            </>
          ) : (
            <Box gap="12px">
              <Text size="16px" color={COLORS.black}>
                Your Contacts
              </Text>
              <DashedBoxSkeleton />
            </Box>
          )}
        </Box>
      </Grid>
    </Box>
  );
};

export default PortalClientsPage;
