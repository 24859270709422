import { useEffect, useRef, useState } from 'react';
import { Footer } from 'grommet';

import { LinkButton } from '@ritten/ui-library/buttons';
import LoadingPanel from '@ritten/ui-library/panels/LoadingPanel';

import ViewportMetaTagHandler from 'common/ViewportMetaTagHandler';
import FeatureFlagProvider from 'context/featureFlags/FeatureFlagProvider';
import { PatientEventProvider } from 'context/patients/PatientEventContext';
import TenantProvider from 'context/tenant/TenantProvider';
import UserProvider from 'context/user/UserProvider';
import useAPI from 'external/useAPI';
import PortalNav from 'features/portal-dashboard/nav/PortalNav';
import { useLoadingState } from 'hooks';
import { COLORS } from 'styles/colors';
import { AppRoutes } from './routes';

const AppContent = (props: { accessibleContacts: Users.PortalAuthorization[] }) => {
  const { accessibleContacts } = props;

  const menuAlignmentRef = useRef(null);

  return (
    <>
      <ViewportMetaTagHandler />
      <PortalNav menuAlignmentRef={menuAlignmentRef} accessibleContacts={accessibleContacts} />
      {/* The height of the div is adjusted to account for the height of the nav bar and the footer */}
      <div
        style={{ position: 'relative', top: '48px', height: 'calc(100% - 48px - 33px)' }}
        ref={menuAlignmentRef}
      >
        <AppRoutes accessibleContacts={accessibleContacts} />
        <Footer background={COLORS.darkGray200} align="center" justify="center">
          <LinkButton
            onClick={() =>
              window.open(
                'https://ritten.atlassian.net/servicedesk/customer/portal/5/group/15/create/52',
              )
            }
            color="text"
            showHyperLinkStyle
            label="Need technical help? Click here"
            padding={{ top: '0', bottom: '12px' }}
          />
        </Footer>
      </div>
    </>
  );
};

const PortalApp = () => {
  const { loading, needsLoadingState } = useLoadingState(true);
  const { userAPI } = useAPI();

  const [accessibleContacts, setAccessibleContacts] = useState<Users.PortalAuthorization[]>([]);

  useEffect(() => {
    fetchAccessibleContacts();
  }, []);

  const fetchAccessibleContacts = needsLoadingState(async () => {
    const portalAuthorizations = await userAPI.getPortalAuthorizations();
    setAccessibleContacts(portalAuthorizations);
  });

  return loading ? (
    <LoadingPanel showLoader />
  ) : (
    <TenantProvider>
      <FeatureFlagProvider>
        <UserProvider>
          <PatientEventProvider>
            <AppContent accessibleContacts={accessibleContacts} />
          </PatientEventProvider>
        </UserProvider>
      </FeatureFlagProvider>
    </TenantProvider>
  );
};

export default PortalApp;
