import RittenClient from './RittenClient';

export default class FeatureFlagClient extends RittenClient {
  getAllFlags = async (): Promise<FeatureFlags.FeatureFlag[]> => {
    const res = await this.get<FeatureFlags.FeatureFlag[]>('/api/feature-flags');
    return res.data;
  };

  patchFlag = async (id: string, isOn: boolean): Promise<void> => {
    await this.patch<void>(`/api/feature-flags/${id}`, { isOn });
  };
}
