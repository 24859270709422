import { Text } from 'grommet';

import { COLORS } from '../../styles/colors';

interface ErrorLabelProps {
  children: string;
}
const ErrorLabel: React.FC<ErrorLabelProps> = (props: ErrorLabelProps) => {
  return (
    <Text size="12px" color={COLORS.red400}>
      {props.children}
    </Text>
  );
};

export default ErrorLabel;
