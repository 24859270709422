import { findIndex, orderBy, xor } from 'lodash';

/**
 * Returns true if the index is last index in the array.
 * @param {number} arrayLength
 * @param {number} idx
 * @returns {boolean}
 */
export const isLastIndex = (arrayLength: number, idx: number) => {
  return arrayLength - 1 === idx;
};

export const isInArray = <T>(array: T[], item: T, valueKey?: keyof T) => {
  return (
    findIndex(array, (currentItem) => {
      if (valueKey) {
        return currentItem[valueKey] === item[valueKey];
      }
      return currentItem === item;
    }) >= 0
  );
};

export const isArrayEmpty = (array: any[]) => {
  return !array || array?.length === 0;
};

export const moveElementAtIndexDown = <T>(array: T[], currentIndex: number): T[] => {
  const targetIndex = currentIndex + 1;

  if (array.length > targetIndex) {
    const sectionToMove = array[currentIndex];
    const fieldAtTargetIdx = array[targetIndex];
    array[targetIndex] = sectionToMove;
    array[currentIndex] = fieldAtTargetIdx;
    return array;
  }

  return array;
};

export const moveElementAtIndexUp = <T>(array: T[], currentIndex: number): T[] => {
  const targetIndex = currentIndex - 1;

  if (targetIndex >= 0) {
    const sectionToMove = array[currentIndex];
    const fieldAtTargetIdx = array[targetIndex];
    array[targetIndex] = sectionToMove;
    array[currentIndex] = fieldAtTargetIdx;
    return array;
  }

  return array;
};

export const moveElementToStart = <T>(array: T[], currentIndex: number): T[] => {
  if (array?.length < 2) {
    return array;
  }
  array.unshift(array.splice(currentIndex, 1)[0]);
  return array;
};

export const moveElementToEnd = <T>(array: T[], currentIndex: number): T[] => {
  if (array?.length < 2) {
    return array;
  }
  array.push(array.splice(currentIndex, 1)[0]);
  return array;
};

/**
 * It returns true if the two arrays have the same elements, regardless of order
 * NOTE: Right now this only supports primitives but can be updated to support
 * objects if you use xorBy from lodash.
 * @param {T[]} array1 - The first array to compare.
 * @param {T[]} array2 - The array to compare against
 * @returns {boolean}
 */
export const arraysHaveSameElements = <T = string | number>(array1: T[], array2: T[]): boolean => {
  const result = xor(array1, array2);
  return result.length === 0;
};

/**
 * Convenient simplified wrapper for Lodash orderBy function.
 * @param {T[]} array
 * @param {keyofT} prop
 * @returns {any}
 */
export const orderByProp = <T>(array: T[], prop: keyof T, order: 'asc' | 'desc' = 'asc') => {
  return orderBy(array, prop, order);
};

/**
 * Fills an array with numbers starting from zero up to the specified max value.
 * The resulting array will include numbers with increments determined by the step parameter.
 *
 * @param {number} max - The maximum value to include in the array.
 * @param {number} step - The increment step between consecutive numbers in the array.
 * @returns {number[]} An array filled with numbers from 0 to max, with a step increment.
 *
 * @example
 * const max = 3;
 * const step = 0.5;
 * const result = fillArrayWithNumbers(max, step);
 * console.log(result); // Output: [0, 0.5, 1, 1.5, 2, 2.5, 3]
 */
export const fillArrayWithNumbers = (max: number, step: number = 1): number[] => {
  const result: number[] = [];
  for (let i = 0; i < max; i += step) {
    result.push(i);
  }
  return result;
};
