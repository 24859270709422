// Generated by ts-to-zod
import { z } from 'zod';

import { calendarCalendarEventSchema } from './calendar.zod';
import { catalogClinicProgramSchema } from './catalog.zod';
import { clinicPayerGroupSchema, clinicReferralTypeSchema } from './clinic.zod';
import { crmOrganizationSchema, crmPersonSchema } from './crm.zod';
import { documentsFileStatusTypeSchema } from './documents.zod';
import { encountersEncounterSchema } from './encounters.zod';
import {
  formsFormDefinitionSchema,
  formsFormInstanceSchema,
  formsFormSignatureStatusSchema,
} from './forms.zod';
import { usersActorSchema, usersFullUserSchema, usersUserSchema } from './users.zod';
import { optionalDateSchema } from './util.zod';

export const patientProgramStatusSchema = z.union([
  z.literal('prospective'),
  z.literal('active'),
  z.literal('former'),
]);

export const patientPatientProgramSchema = z.lazy(() =>
  z.object({
    id: z.string(),
    patientID: z.string(),
    levelOfCare: z.string(),
    admitDate: z.date(),
    admittedByUserID: z.string(),
    admitNotes: z.string(),
    estimatedDischargeDate: z.date(),
    dischargeType: z.string(),
    dischargeDate: z.date(),
    dischargedByUserID: z.string(),
    dischargeNotes: z.string(),
    createdAt: z.date(),
    program: catalogClinicProgramSchema,
  }),
);

export const patientCareTeamSchema = z.object({
  id: z.string(),
  patientId: z.string(),
  primaryClinicianId: z.string(),
  primaryClinician: usersUserSchema.optional(),
  teamUserIds: z.array(z.string()),
});

export const patientPronounTypeSchema = z.union([
  z.literal('he/him'),
  z.literal('she/her'),
  z.literal('they/them'),
]);

export const patientPatientSSNSchema = z.object({
  ssn: z.string(),
});

export const patientPatientExportPostBodySchema = z.object({
  startDate: z.date().optional(),
  endDate: z.date().optional(),
  toc: z.boolean(),
  aci: z.boolean(),
  cci: z.boolean(),
  episodesOfCare: z.boolean(),
  forms: z.boolean(),
  orders: z.boolean(),
  encounterTypes: z.array(z.string()),
  documentTypes: z.array(z.string()),
});

export const patientPatientWeeklyAgendaExportPostBodySchema = z.object({
  startTime: z.date(),
  endTime: z.date(),
  events: z.array(calendarCalendarEventSchema),
});

export const patientContactPointSystemSchema = z.union([z.literal('EMAIL'), z.literal('PHONE')]);

export const patientContactPointUseSchema = z.union([
  z.literal('HOME'),
  z.literal('WORK'),
  z.literal('MOBILE'),
  z.literal('PERSONAL'),
]);

export const patientContactPointSchema = z.object({
  id: z.string(),
  system: patientContactPointSystemSchema,
  use: patientContactPointUseSchema,
  value: z.string(),
  isPrimary: z.boolean(),
  notes: z.string(),
  notify: z.boolean(),
  isAssociatedWithAuth0User: z.boolean(),
  hasAssociatedAuthoUserLoggedIn: z.boolean(),
});

export const patientAddressUseSchema = z.union([
  z.literal('WORK'),
  z.literal('HOME'),
  z.literal('OTHER'),
]);

export const patientAddressSchema = z.object({
  id: z.string(),
  use: patientAddressUseSchema,
  country: z.string(),
  line: z.string(),
  line2: z.string(),
  city: z.string(),
  region: z.string(),
  postalCode: z.string(),
  isInactive: z.boolean(),
  isBilling: z.boolean(),
  notes: z.string(),
});

export const patientDemographicsSchema = z.object({
  id: z.string().optional(),
  race: z.array(z.string()),
  raceOther: z.array(z.string()).nullable(),
  ethnicity: z.array(z.string()).nullable(),
  ethnicityOther: z.array(z.string()).nullable(),
  preferredLanguage: z.string(),
  sex: z.string(),
  gender: z.array(z.string()).nullable(),
  genderDetail: z.string(),
  sexualOrientation: z.string(),
  sexualOrientationDetail: z.string(),
  maritalStatus: z.string(),
});

export const patientProgramAdmissionPostBodySchema = z.object({
  id: z.string(),
  fieldInstanceId: z.string(),
  levelOfCare: z.string(),
  admitDate: z.date(),
  estimatedDischargeDate: z.date(),
  notes: z.string(),
});

export const patientProgramTransferPostBodySchema = z.object({
  id: z.string(),
  fieldInstanceId: z.string(),
  levelOfCare: z.string(),
  transferDate: z.date(),
  estimatedDischargeDate: z.date(),
  notes: z.string(),
});

export const patientProgramDischargePostBodySchema = z.object({
  id: z.string(),
  fieldInstanceId: z.string(),
  dischargeTypeId: z.string(),
  dischargeDate: z.date(),
  notes: z.string(),
});

export const patientSubstanceUseHistorySchema = z.object({
  id: z.string(),
  substance: z.string(),
  category: z.string(),
  ageFirstUsed: z.any(),
  lastUseDate: z.string(),
  amount: z.any(),
  amountType: z.string(),
  amountFrequency: z.string(),
  route: z.string(),
  notes: z.string(),
  methodOfAcquisition: z.string(),
  patternOfUse: z.string(),
});

export const patientAllergySchema = z.object({
  id: z.string(),
  dosespotAllergyId: z.string(),
  name: z.string(),
  code: z.string(),
  codeType: z.string(),
  reactionType: z.string(),
  status: z.string(),
  onsetDate: z.string(),
  reaction: z.string(),
  createdAt: z.date().optional(),
});

export const patientAllergiesPostBodySchema = z.object({
  fieldInstanceId: z.string().optional(),
  allergies: z.array(patientAllergySchema),
});

export const patientInsurancePayerSchema = z.object({
  id: z.string(),
  payerIdentifier: z.string(),
  cmdPayerId: z.string(),
  payerName: z.string(),
  address: z.string(),
  city: z.string(),
  state: z.string(),
  postalCode: z.string(),
  notes: z.string(),
  payerGroupId: z.string(),
});

export const patientDiagnosisSchema = z.object({
  id: z.string(),
  diagnosis: z.string(),
  clinicalStatus: z.string(),
  verificationStatus: z.string(),
  isAuthorized: z.boolean(),
  isPrimary: z.boolean(),
});

export const patientDiagnosesPostBodySchema = z.object({
  fieldInstanceId: z.string().optional(),
  diagnosis: z.array(patientDiagnosisSchema),
});

export const patientTreatmentHistorySchema = z.object({
  id: z.string(),
  facilityOrganization: z.string(),
  levelOfCare: z.string(),
  treatedFor: z.string(),
  admitDate: z.string(),
  dischargeDate: z.string(),
  dischargeType: z.string(),
  outcome: z.string(),
});

export const patientMedicationStatusSchema = z.union([
  z.literal('Active'),
  z.literal('Inactive'),
  z.literal('Discontinued'),
  z.literal('Deleted'),
  z.literal('Completed'),
  z.literal('Sending'),
  z.literal('Cancel Requested'),
  z.literal('Cancel Pending'),
  z.literal('Cancelled'),
  z.literal('Cancel Denied'),
  z.literal('Changed'),
  z.literal('Full Fill'),
  z.literal('Partial Fill'),
  z.literal('No Fill'),
  z.literal('Stopped Taking'),
  z.literal('Pending'),
]);

export const patientClientContactSchema = z.object({
  clientContactPersonId: z.string().optional(),
  id: z.string(),
  name: z.string(),
  relationship: z.string(),
  deletedAt: z.date().optional(),
  isRelationship: z.boolean().optional(),
  age: z.number().optional(),
  gender: z.string().optional(),
  characteristics: z.array(z.string()).optional(),
  comments: z.string().optional(),
  isClientContact: z.boolean(),
  emergencyContact: z.boolean(),
  guarantor: z.boolean(),
  guardian: z.boolean(),
  phone: z.string(),
  email: z.string(),
  notes: z.string(),
  line: z.string(),
  city: z.string(),
  state: z.string(),
  postalCode: z.string(),
  isPortalDisabled: z.boolean().optional(),
  hasPortalUser: z.boolean().optional(),
  hasPortalAuthorization: z.boolean().optional(),
  userId: z.string().optional(),
  hasLoggedIn: z.boolean().optional(),
  mrn: z.string().optional(),
});

export const patientClientContactPostBodySchema = z.object({
  id: z.string().optional(),
  personId: z.string(),
  type: z.string(),
  emergencyContact: z.boolean(),
  guarantor: z.boolean(),
  guardian: z.boolean(),
});

export const patientClientRelationshipSchema = z.object({
  id: z.string(),
  name: z.string(),
  relationship: z.string(),
  age: z.number(),
  gender: z.string(),
  characteristics: z.array(z.string()),
  comments: z.string(),
  isClientContact: z.boolean().optional(),
  emergencyContact: z.boolean().optional(),
  guardian: z.boolean().optional(),
  phone: z.string().optional(),
  email: z.string().optional(),
  notes: z.string().optional(),
});

export const patientOrganizationSchema = z.lazy(() =>
  z.object({
    id: z.string(),
    referralType: clinicReferralTypeSchema,
    name: z.string(),
    createdAt: z.date(),
    deletedAt: z.date(),
  }),
);

export const patientClinicOrganizationContactSchema = z.object({
  id: z.string(),
  organization: patientOrganizationSchema,
  firstName: z.string(),
  lastName: z.string(),
  jobTitle: z.string(),
  phone: z.string(),
  email: z.string(),
  notes: z.string(),
  createdAt: z.date(),
  deletedAt: z.date(),
});

export const patientPatientReferralPostBodySchema = z.object({
  id: z.string(),
  clinicContactID: z.string(),
  referralTypeID: z.string(),
  organizationID: z.string(),
});

export const patientAuthorizationEntrySchema = z.object({
  id: z.string(),
  sectionId: z.string(),
  startDate: z.string(),
  endDate: z.string(),
  daysPerWeek: z.number(),
  nextReviewDate: z.string().optional(),
  totalDays: z.number(),
  billedDays: z.number(),
  usedDays: z.number(),
  remainingDays: z.number(),
});

export const patientProblemStatusTypeSchema = z.union([
  z.literal('Active'),
  z.literal('Resolved'),
  z.literal('Deferred'),
  z.literal('Referred'),
]);

export const patientInterventionSchema = z.object({
  id: z.string(),
  description: z.string().optional(),
  frequency: z.string().optional(),
  staffMemberUsers: z.array(usersUserSchema).optional(),
  notSaved: z.boolean().optional(),
  catalogId: z.string().optional(),
});

export const patientInterventionPostBodySchema = z.object({
  id: z.string(),
  description: z.string(),
  frequency: z.string().optional(),
  staffMemberUserIds: z.array(z.string()),
});

export const patientTreatmentPlanReferencePostBodySchema = z.object({
  id: z.string(),
  problemId: z.string(),
  goalId: z.string().optional(),
  objectiveId: z.string().optional(),
  interventionId: z.string().optional(),
  treatmentPlanReferenceId: z.string().optional(),
});

export const patientReleaseOfInformationSchema = z.object({
  id: z.string(),
  formId: z.string().optional(),
  encounterId: z.string().optional(),
  relationshipId: z.string().optional(),
  documentId: z.string().optional(),
  releaseType: z.string(),
  isCompleteRecord: z.boolean(),
  expiresAt: optionalDateSchema,
  revokedAt: optionalDateSchema,
  notSaved: z.boolean().optional(),
});

export const patientReleaseOfInformationV2Schema = z.object({
  id: z.string(),
  createdAt: z.string(),
  documentId: z.string(),
  encounterId: z.string(),
  expiresAt: z.string(),
  revokedAt: z.date(),
  formId: z.string(),
  isCompleteRecord: z.boolean(),
  releaseType: z.string(),
  releaseToOrganization: crmOrganizationSchema,
  releaseToPerson: crmPersonSchema,
  signatureStatus: formsFormSignatureStatusSchema,
});

export const patientReleaseOfInformationV2PostBodySchema = z.object({
  id: z.string(),
  patientId: z.string().optional(),
  formId: z.string().optional(),
  expiresAt: z.string().optional(),
  releaseToPatientId: z.string().optional(),
  releaseToOrganizationId: z.string().optional(),
  releaseType: z.string(),
  isCompleteRecord: z.boolean(),
});

export const patientClinicTeamSchema = z.object({
  id: z.string(),
  name: z.string(),
  userIds: z.array(z.string()),
  patientIds: z.array(z.string()),
  createdAt: z.date(),
});

export const patientFundingSourceTypeSchema = z.union([
  z.literal('Private Pay'),
  z.literal('Private Pay + Insurance'),
  z.literal('Private Pay + Lab Insurance'),
  z.literal('Insurance Only'),
  z.literal('Medicaid/Medicare'),
  z.literal('Medicaid'),
  z.literal('Medicare'),
  z.literal('Scholarship'),
  z.literal('Other'),
]);

export const patientFundingSourceSchema = z.object({
  id: z.string(),
  type: patientFundingSourceTypeSchema,
  medicaidNumber: z.string().optional(),
});

export const patientObservationTypeSchema = z.union([
  z.literal('temperature'),
  z.literal('heart rate'),
  z.literal('respiration rate'),
  z.literal('oxygen saturation'),
  z.literal('blood pressure'),
  z.literal('glucose'),
  z.literal('blood alcohol concentration'),
  z.literal('height and weight'),
  z.literal('bmi'),
]);

export const patientAssessmentTypeSchema = z.union([
  z.literal('ciwa'),
  z.literal('cows'),
  z.literal('pain scale'),
]);

const patientExportObservationTypeSchema = z.union([
  patientObservationTypeSchema,
  patientAssessmentTypeSchema,
]);

export const patientMeasurementTypeSchema = z.union([
  z.literal('standard'),
  z.literal('systolic'),
  z.literal('diastolic'),
  z.literal('height'),
  z.literal('weight'),
]);

export const patientMeasurementPostBodySchema = z.object({
  id: z.string().optional(),
  type: z.string(),
  unit: z.string(),
  value: z.number().nullable(),
});

export const patientWarningStatusSchema = z.union([z.literal('Active'), z.literal('Inactive')]);

export const patientWarningSchema = z.object({
  id: z.string(),
  type: z.string(),
  status: patientWarningStatusSchema,
  onsetDate: z.string(),
});

export const patientDietaryRestrictionStatusSchema = z.union([
  z.literal('Active'),
  z.literal('Inactive'),
]);

export const patientDietaryRestrictionSchema = z.object({
  id: z.string(),
  type: z.string(),
  status: patientDietaryRestrictionStatusSchema,
  startDate: z.string(),
});

export const patientExportBiometricsQuery = z.object({
  patientId: z.string(),
  types: z.array(patientExportObservationTypeSchema).optional(),
  startDate: z.date().optional(),
  endDate: z.date().optional(),
});

export const patientBiometricsQuerySchema = z.object({
  types: z.array(z.string()),
  patientId: z.string(),
  startDate: z.date().optional(),
  endDate: z.date().optional(),
  measurementType: z.string().optional(),
  bmiOnly: z.string().optional(),
  limit: z.number().optional(),
  offset: z.number().optional(),
});

export const patientBiometricTypeSchema = z.union([
  z.literal('BAC'),
  z.literal('Glucose'),
  z.literal('Height'),
  z.literal('Weight'),
  z.literal('Blood Pressure'),
  z.literal('O2 Saturation'),
  z.literal('Pulse'),
  z.literal('Respiration Rate'),
  z.literal('Temperature'),
]);

export const patientCiwaSchema = z.object({
  id: z.string(),
  patientId: z.string(),
  q1Nausea: z.number().nullable(),
  q2Tremors: z.number().nullable(),
  q3Anxiety: z.number().nullable(),
  q4Agitation: z.number().nullable(),
  q5Sweats: z.number().nullable(),
  q6Orientation: z.number().nullable(),
  q7Tactile: z.number().nullable(),
  q8Auditory: z.number().nullable(),
  q9Visual: z.number().nullable(),
  q10Headache: z.number().nullable(),
  notes: z.string(),
  loggedBy: usersActorSchema,
  loggedAt: z.date(),
  createdAt: z.date(),
});

export const patientCIWAPostBodySchema = z.object({
  id: z.string().optional(),
  q1Nausea: z.number().nullable(),
  q2Tremors: z.number().nullable(),
  q3Anxiety: z.number().nullable(),
  q4Agitation: z.number().nullable(),
  q5Sweats: z.number().nullable(),
  q6Orientation: z.number().nullable(),
  q7Tactile: z.number().nullable(),
  q8Auditory: z.number().nullable(),
  q9Visual: z.number().nullable(),
  q10Headache: z.number().nullable(),
  loggedAt: z.date(),
  notes: z.string(),
});

export const patientCowsSchema = z.object({
  id: z.string(),
  patientId: z.string(),
  q1Pulse: z.number().nullable(),
  q2Sweating: z.number().nullable(),
  q3Restlessness: z.number().nullable(),
  q4Pupils: z.number().nullable(),
  q5Aches: z.number().nullable(),
  q6Nose: z.number().nullable(),
  q7Tremor: z.number().nullable(),
  q8Yawning: z.number().nullable(),
  q9Anxiety: z.number().nullable(),
  q10Gooseflesh: z.number().nullable(),
  q11Giupset: z.number().nullable(),
  notes: z.string(),
  loggedBy: usersActorSchema,
  loggedAt: z.date(),
  createdAt: z.date(),
});

export const patientCOWSPostBodySchema = z.object({
  id: z.string().optional(),
  q1Pulse: z.number().nullable(),
  q2Sweating: z.number().nullable(),
  q3Restlessness: z.number().nullable(),
  q4Pupils: z.number().nullable(),
  q5Aches: z.number().nullable(),
  q6Nose: z.number().nullable(),
  q7Tremor: z.number().nullable(),
  q8Yawning: z.number().nullable(),
  q9Anxiety: z.number().nullable(),
  q10Gooseflesh: z.number().nullable(),
  q11Giupset: z.number().nullable(),
  loggedAt: z.date(),
  notes: z.string(),
});

export const patientPainScaleSchema = z.object({
  id: z.string(),
  patientId: z.string(),
  painLevel: z.number(),
  notes: z.string(),
  loggedBy: usersActorSchema,
  loggedAt: z.date(),
  createdAt: z.date(),
});

export const patientPainScalePostBodySchema = z.object({
  id: z.string().optional(),
  painLevel: z.number(),
  loggedAt: z.date(),
  notes: z.string(),
});

export const patientVOBRateTypeSchema = z.union([
  z.literal('Usual & Customary'),
  z.literal('Allowable'),
  z.literal('Contract Rate'),
  z.literal('Billable'),
  z.literal('50/50 Chance'),
  z.literal('Maximum Non-Network Reimbursement Plan'),
]);

export const patientVOBPrecertRequirementSchema = z.union([
  z.literal('Detox'),
  z.literal('Residential'),
  z.literal('PHP'),
  z.literal('IOP 5'),
  z.literal('IOP 3'),
  z.literal('IOP 2'),
  z.literal('OP'),
]);

export const patientVOBBenefitTypeSchema = z.union([
  z.literal('Mental and Substance Benefit'),
  z.literal('Mental Benefit Only'),
  z.literal('Substance Benefit Only'),
]);

export const patientPatientNameSchema = z.object({
  patientId: z.string(),
  first: z.string(),
  middle: z.string(),
  last: z.string(),
  pronouns: patientPronounTypeSchema.optional(),
  chosenName: z.string().optional(),
});

export const patientPatientInsuranceSchema = z.lazy(() =>
  z.object({
    id: z.string(),
    payerID: z.string(),
    verification: z.string(),
    orderNumber: z.number(),
    groupNumber: z.string(),
    memberIdentifier: z.string(),
    rxBIN: z.string(),
    startDate: z.string(),
    expirationDate: z.string(),
    notSubscriber: z.boolean(),
    firstName: z.string(),
    lastName: z.string(),
    address: z.string(),
    city: z.string(),
    state: z.string(),
    postalCode: z.string(),
    birthDate: z.string(),
    sex: z.string(),
    relationship: z.string(),
    comments: z.string(),
    payerGroup: clinicPayerGroupSchema.optional(),
    providerPhoneNumber: z.string().optional(),
    subscriberPhoneNumber: z.string().optional(),
    payer: patientInsurancePayerSchema.optional(),
    rxPCN: z.string().optional(),
    rxGroup: z.string().optional(),
  }),
);

export const patientPatientFlagPriorityTypeSchema = z.union([z.literal('LOW'), z.literal('HIGH')]);

export const patientPatientChartFlagSchema = z.object({
  priority: patientPatientFlagPriorityTypeSchema,
  description: z.string(),
  lastUpdatedBy: usersUserSchema.optional(),
  updatedAt: z.date().optional(),
});

export const patientProfilePhotoSchema = z.lazy(() =>
  z.object({
    fileId: z.string(),
    status: documentsFileStatusTypeSchema,
  }),
);

export const patientPatientSchema = z.lazy(() =>
  z.object({
    id: z.string(),
    dob: z.string(),
    name: patientPatientNameSchema,
    isGuardianRequired: z.boolean(),
    programStatus: patientProgramStatusSchema,
    profilePhoto: patientProfilePhotoSchema,
    mrn: z.string(),
    ssn: z.string(),
    programs: z.array(patientPatientProgramSchema),
    careTeam: patientCareTeamSchema,
    insurance: z.array(patientPatientInsuranceSchema).optional(),
    latestClinicalProgram: patientPatientProgramSchema.optional(),
    deletedAt: z.string().optional(),
    isObfuscated: z.boolean().optional(),
    userId: z.string().optional(),
    hasLoggedIn: z.boolean(),
    isPortalDisabled: z.boolean(),
    flag: patientPatientChartFlagSchema.optional(),
  }),
);

export const patientListPatientsResponseSchema = z.object({
  patients: z.array(patientPatientSchema),
  meta: z.object({
    count: z.number(),
    totalCount: z.number(),
  }),
});

export const patientCareTeamPostBodySchema = z.object({
  primaryClinicianId: z.string(),
  teamUserIds: z.array(z.string()),
});

export const patientStripePaymentMethodSessionSchema = z.object({
  sessionURL: z.string(),
});

export const patientStripePaymentMethodPostBodySchema = z.object({
  returnURL: z.string(),
});

export const patientRequestPaymentMethodSchema = z.object({
  patientIds: z.array(z.string()),
});

export const patientPaymentMethodCreditCardSchema = z.object({
  id: z.string(),
  brand: z.string(),
  last4: z.string(),
  expMonth: z.number(),
  expYear: z.number(),
  nameOnCard: z.string(),
});

export const patientChargeSchema = z.object({
  id: z.string(),
  created: z.date(),
  status: z.string(),
  amount: z.number(),
  refunded: z.boolean(),
  description: z.string(),
  type: z.string(),
  dateOfService: z.string(),
  paymentMethod: patientPaymentMethodCreditCardSchema,
  failureCode: z.string(),
  failureMessage: z.string(),
});

export const patientStripeChargePostBodySchema = z.object({
  paymentMethodId: z.string(),
  amount: z.number(),
  description: z.string().optional(),
  dateOfService: z.date().optional(),
  type: z.union([z.literal('Payment'), z.literal('Copay')]),
});

export const patientStripeRefundPostBodySchema = z.object({
  chargeId: z.string(),
  amount: z.number(),
});

export const patientStripeGetChargesParamsSchema = z.object({
  limit: z.number(),
  startingAfter: z.string().optional(),
});

export const patientCMDBalanceSchema = z.object({
  patientId: z.string(),
  totalBalanceCents: z.number(),
  createdAt: z.date(),
});

export const patientExclusiveGrantPostBodySchema = z.object({
  userId: z.string(),
});

export const patientMedicationSchema = z.object({
  id: z.string(),
  name: z.string(),
  dosespotMedicationId: z.string().optional(),
  strength: z.string(),
  type: z.string(),
  dispensedUnitId: z.number(),
  dispensedUnitName: z.string(),
  dispensedQuantity: z.string(),
  daysSupply: z.number(),
  refills: z.number(),
  directions: z.string(),
  status: patientMedicationStatusSchema,
  dateOrdered: z.date(),
  dateInactivated: z.date(),
  prescriber: z.string(),
  comment: z.string(),
  LexiGenProductId: z.number().optional(),
  LexiDrugSynId: z.number().optional(),
  LexiSynonymTypeId: z.number().optional(),
  LexiGenDrugId: z.string().optional(),
});

export const patientPatientReferralSchema = z.lazy(() =>
  z.object({
    id: z.string(),
    referralType: clinicReferralTypeSchema,
    organization: patientOrganizationSchema,
    clinicContact: patientClinicOrganizationContactSchema,
    createdAt: z.date(),
    deletedAt: z.date(),
  }),
);

export const patientAuthorizationSchema = z.object({
  id: z.string(),
  payerID: z.string(),
  status: z.string(),
  type: z.string(),
  trackingNumber: z.string(),
  procedureCodes: z.array(z.string()),
  diagnosis: z.string(),
  notes: z.string(),
  entries: z.array(patientAuthorizationEntrySchema),
});

export const patientObjectiveSchema = z.object({
  id: z.string(),
  description: z.string(),
  targetDate: z.string().optional(),
  interventions: z.array(patientInterventionSchema).optional(),
  status: z.string(),
  comments: z.string(),
  notSaved: z.boolean().optional(),
  catalogId: z.string().optional(),
});

export const patientObjectivePostBodySchema = z.object({
  id: z.string(),
  description: z.string().optional(),
  targetDate: z.date().nullable(),
  interventions: z.array(patientInterventionPostBodySchema).optional(),
  status: z.string().optional(),
  comments: z.string().optional(),
});

export const patientMeasurementSchema = z.object({
  id: z.string(),
  type: patientMeasurementTypeSchema,
  unit: z.string(),
  value: z.number(),
  createdAt: z.date(),
});

export const patientObservationPostBodySchema = z.object({
  id: z.string().optional(),
  fieldInstanceId: z.string().optional(),
  patientId: z.string(),
  type: patientObservationTypeSchema,
  measurements: z.array(patientMeasurementPostBodySchema),
  comment: z.string().optional(),
  loggedAt: z.date().nullable(),
  loggedBy: z.string(),
});

export const patientVOBBenefitSchema = z.object({
  id: z.string().optional(),
  benefitType: patientVOBBenefitTypeSchema,
  levelOfCare: z.string(),
  benefitAmount: z.number(),
  benefitPercentage: z.number(),
  daysPerYear: z.number(),
  daysUsed: z.number(),
  lifetimeLimits: z.string(),
  lifetimeLimitsUsed: z.string(),
});

export const patientPatientPostBodySchema = z.object({
  fieldInstanceId: z.string().optional(),
  dob: z.string(),
  name: z.object({
    first: z.string(),
    middle: z.string(),
    last: z.string(),
    pronouns: patientPronounTypeSchema.optional(),
    chosenName: z.string().optional(),
  }),
  isGuardianRequired: z.boolean(),
  ssn: z.string(),
  patientCareTeam: patientCareTeamPostBodySchema.optional(),
  clinicTeamIds: z.array(z.string()).optional(),
});

export const patientGuardianStatus = z.object({
  isGuardianRequired: z.boolean(),
});

export const patientExclusiveGrantSchema = z.object({
  id: z.string(),
  patient: patientPatientNameSchema,
  user: usersFullUserSchema,
});

export const patientTreatmentPlanFormReferenceSchema = z.lazy(() =>
  z.object({
    formInstance: formsFormInstanceSchema,
    formDefinition: formsFormDefinitionSchema,
    encounter: encountersEncounterSchema,
  }),
);

export const patientGoalSchema = z.object({
  id: z.string(),
  description: z.string(),
  objectives: z.array(patientObjectiveSchema).optional(),
  notSaved: z.boolean().optional(),
  catalogId: z.string().optional(),
  formReferences: z.array(patientTreatmentPlanFormReferenceSchema),
});

export const patientGoalPostBodySchema = z.object({
  id: z.string(),
  description: z.string().optional(),
  objectives: z.array(patientObjectivePostBodySchema).optional(),
});

export const patientObservationSchema = z.object({
  id: z.string(),
  patientId: z.string(),
  type: patientObservationTypeSchema,
  loggedAt: z.date(),
  loggedBy: usersUserSchema,
  comment: z.string(),
  measurements: z.array(patientMeasurementSchema),
  createdAt: z.date(),
});

export const patientVobSchema = z.object({
  id: z.string(),
  patientId: z.string(),
  dob: z.string(),
  insurance: patientPatientInsuranceSchema,
  patientInsuranceId: z.string().optional(),
  initialCoordinator: z.string(),
  callReferenceNumber: z.string(),
  verificationDate: z.string(),
  rateType: patientVOBRateTypeSchema,
  planYear: z.number(),
  effectiveDate: z.string(),
  isActive: z.boolean(),
  isCobra: z.boolean(),
  isInNetwork: z.boolean(),
  isPreexisting: z.boolean(),
  preexistingTerms: z.string(),
  hasPrecertPenaltyTerms: z.boolean(),
  precertPenaltyTerms: z.string(),
  copayCents: z.number(),
  copayNotes: z.string(),
  deductibleCents: z.number(),
  deductibleMetCents: z.number(),
  outOfPocketCents: z.number(),
  outOfPocketMetCents: z.number(),
  precertCompanyName: z.string(),
  precertPhone: z.string(),
  precertBillToName: z.string(),
  precertPayerIdentifier: z.string(),
  precertAddress: patientAddressSchema,
  licenseRequirements: z.string(),
  isFreeStandingFacillity: z.boolean(),
  hasPharmacyBenefits: z.boolean(),
  rx: z.string(),
  pharmacyPhone: z.string(),
  comments: z.string(),
  hasSameBenefits: z.boolean(),
  substanceBenefits: z.array(patientVOBBenefitSchema),
  mentalBenefits: z.array(patientVOBBenefitSchema),
  substancePrecertRequirements: z.array(patientVOBPrecertRequirementSchema),
  mentalPrecertRequirements: z.array(patientVOBPrecertRequirementSchema),
  createdBy: usersUserSchema,
  createdAt: z.date(),
});

export const patientProblemSchema = z.object({
  id: z.string(),
  name: z.string(),
  status: patientProblemStatusTypeSchema,
  evidencePoints: z.string(),
  diagnosis: z.array(patientDiagnosisSchema).optional(),
  goals: z.array(patientGoalSchema).optional(),
  createdAt: z.date().optional(),
  createdBy: usersActorSchema.optional(),
  notSaved: z.boolean().optional(),
  catalogId: z.string().optional(),
  startDate: z.string().optional(),
  endDate: z.string().optional(),
});

export const patientProblemPostBodySchema = z.object({
  id: z.string(),
  name: z.string(),
  status: patientProblemStatusTypeSchema,
  evidencePoints: z.string().optional(),
  diagnosisIds: z.array(z.string()).optional(),
  goals: z.array(patientGoalPostBodySchema).optional(),
  createdByUserId: z.string().optional(),
  startDate: z.string().optional(),
  endDate: z.string().optional(),
});

export const patientTreatmentPlanReferenceSchema = z.object({
  id: z.string(),
  problem: patientProblemSchema,
});

export const patientObservationResponseSchema = z.object({
  temperature: z.array(patientObservationSchema),
  heartRate: z.array(patientObservationSchema),
  respirationRate: z.array(patientObservationSchema),
  oxygenSaturation: z.array(patientObservationSchema),
  bloodPressure: z.array(patientObservationSchema),
  glucose: z.array(patientObservationSchema),
  bloodAlcoholConcentration: z.array(patientObservationSchema),
  heightWeight: z.array(patientObservationSchema),
});
