import { ButtonSize } from './constants';

export const useButtonSize = () => {
  const getTextSize = (buttonSize: ButtonSize) => {
    switch (buttonSize) {
      case 'large':
        return '20px';
      case 'small':
        return '14px';
      case 'xsmall':
        return '12px';
      default:
        return '16px';
    }
  };
  return { getTextSize };
};
