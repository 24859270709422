// @ts-strict-ignore
import { Box, Text } from 'grommet';
import { Alert, CaretRightFill } from 'grommet-icons';

import { COLORS } from '../../styles/colors';

type ErrorPanelProps = {
  errors: Set<string> | string[];
  flex?: boolean;
  onDismiss?: () => void;
};

export const ErrorPanel: React.FC<ErrorPanelProps> = (props: ErrorPanelProps) => {
  // Use a set to avoid potential duplicate errors.
  const errors = props.errors instanceof Set ? props.errors : new Set(props.errors ?? []);
  if (!errors.size) {
    return null;
  }

  return (
    <Box
      background={COLORS.red400}
      flex={props.flex ?? 'grow'}
      data-testid="app-error-panel"
      margin={{ bottom: 'xsmall' }}
    >
      <Box
        pad={{ top: '20px', horizontal: '20px' }}
        direction="row"
        align="center"
        justify="between"
      >
        <Text color={COLORS.white}>Errors</Text>
        <Alert color={COLORS.white} />
      </Box>
      <Box pad="xsmall">
        {Array.from(errors).map((err, index) => (
          <Box pad={{ left: '40px', bottom: '10px' }} key={index} direction="row" align="center">
            <CaretRightFill color={COLORS.white} />
            <Box pad={{ left: '5px' }}>
              <Text color={COLORS.white}>{err}</Text>
            </Box>
          </Box>
        ))}
        {props.onDismiss && (
          <Box direction="row" justify="end" onClick={() => props.onDismiss()}>
            <Text color={COLORS.white} size="12px">
              Dismiss
            </Text>
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default ErrorPanel;
