import { COLORS } from 'styles/colors';

interface RittenLogoProps {
  height?: string;
}

const RittenLogo = (props: RittenLogoProps) => {
  return (
    <svg
      viewBox="0 0 151 151"
      height={props.height ? props.height : '20px'}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="m91.22,59.64l29.71,0l0,-29.71l-29.72,0l0,-29.7l-23.68,0c-4.05,0 -7.33,3.28 -7.33,7.33l0,22.36l31.01,0l0,29.72l0.01,0z"
        fill="#0071C0"
        id="svg_7"
      />
      <path d="m120.94,29.92l-29.72,-29.7l0,29.7l29.72,0z" fill={COLORS.rittenTeal} id="svg_8" />
      <path
        d="m60.21,59.64l0,-29.71l-29.71,0l0,29.72l-29.7,0l0,23.68c0,4.05 3.28,7.33 7.33,7.33l22.36,0l0,-31.01l29.72,0l0,-0.01z"
        fill="#0071C0"
        id="svg_9"
      />
      <path d="m30.5,29.92l-29.7,29.72l29.7,0l0,-29.72z" fill={COLORS.rittenTeal} id="svg_10" />
      <path
        d="m60.21,90.64l-29.71,0l0,29.71l29.72,0l0,29.7l23.68,0c4.05,0 7.33,-3.28 7.33,-7.33l0,-22.36l-31.01,0l0,-29.72l-0.01,0z"
        fill="#0071C0"
        id="svg_11"
      />
      <path d="m30.5,120.36l29.71,29.7l0,-29.7l-29.71,0z" fill={COLORS.rittenTeal} id="svg_12" />
      <path
        d="m91.22,90.64l0,29.71l29.71,0l0,-29.72l29.7,0l0,-23.68c0,-4.05 -3.28,-7.33 -7.33,-7.33l-22.36,0l0,31.01l-29.72,0l0,0.01z"
        fill="#0071C0"
        id="svg_13"
      />
      <path d="m120.94,120.36l29.7,-29.72l-29.7,0l0,29.72z" fill={COLORS.rittenTeal} id="svg_14" />
      <path
        d="m92.22,29.92l-31,0l0,90.45l31,0l0,-90.45z"
        fill={COLORS.white}
        id="svg_15"
        transform="matrix(1 0 0 1 0 0)"
      />
      <path d="m120.94,60.47l-90.43,0l0,31.17l90.43,0l0,-31.17z" fill={COLORS.white} id="svg_16" />
    </svg>
  );
};

export default RittenLogo;
