import { parseISODateStrings, parseObjectDates, StringifyKeys } from '../utils/dateParsers';
import RittenClient from './RittenClient';

export default class OrderClient extends RittenClient {
  getAllOrderTypes = async (): Promise<Orders.OrderTypeResponse[]> => {
    const { data = [] } = await this.get<Orders.OrderTypeResponse[]>('/api/order/types');
    return parseISODateStrings(data);
  };

  getOrder = async (orderId: string): Promise<Orders.Order> => {
    const { data } = await this.get<Orders.Order>(`/api/orders/${orderId}`);
    return parseISODateStrings(data);
  };

  getOrders = async (params: Orders.OrdersQuery): Promise<Orders.Order[]> => {
    const res = await this.get<Orders.Order[]>(`/api/orders`, { params });
    return parseISODateStrings(res.data);
  };

  getOrderDetailsDosespot = async (orderID: string): Promise<Orders.OrderDetailsDosespot> => {
    const res = await this.get<Orders.OrderDetailsDosespot>(
      `/api/orders/${orderID}/dosespot/details`,
    );
    return parseObjectDates(res.data, ['createdAt', 'effectiveDate']);
  };

  getFrequencyConfgurations = async (): Promise<Orders.OrderFrequency[]> => {
    const { data } = await this.get<Orders.OrderFrequency[]>(`/api/clinic/mar/frequencies`);
    return data;
  };

  getFrequencyConfgurationsFull = async (): Promise<Orders.OrderFrequency[]> => {
    const { data } = await this.get<Orders.OrderFrequency[]>(
      `/api/clinic/mar/frequencies/full-times`,
    );
    return data;
  };

  updateFrequencyConfigurations = async (config: Orders.OrderFrequency): Promise<void> => {
    await this.post<void>('api/clinic/mar/frequencies', config);
  };

  /**
   * Update signature requirements for an order type.
   */
  updateOrderTypeSignatureRequirements = async (
    type: Orders.OrderType,
    roles: Users.Role[] = [],
  ): Promise<Forms.SignatureRequirement> => {
    const { data } = await this.patch<StringifyKeys<Forms.SignatureRequirement, 'createdAt'>>(
      `/api/orders/signature-requirements`,
      {
        type,
        roleIds: roles.map((r) => r.id),
      },
    );
    return parseObjectDates(data, ['createdAt']);
  };

  patchOrderScheduleExempt = async (orderId: string, scheduleExempt: boolean) => {
    await this.patch(`api/orders/${orderId}/schedule-exempt`, {
      scheduleExempt,
    });
  };
}
