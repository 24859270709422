import { createSlice } from '@reduxjs/toolkit';

import { RootState } from 'redux/store';

export interface EncounterPatchState {
  EncounterPatch: number;
}

const initialState: EncounterPatchState = {
  EncounterPatch: 0,
};

export const EncounterPatchSlice = createSlice({
  name: 'encounterPatch',
  initialState,
  reducers: {
    onEncounterPatch: (state) => {
      state.EncounterPatch += 1;
    },
  },
});

export const { onEncounterPatch } = EncounterPatchSlice.actions;

export const selectEncounterPatch = (state: RootState) => state.encounterPatch.EncounterPatch;

export default EncounterPatchSlice.reducer;
