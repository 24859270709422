import { AxiosRequestConfig, AxiosResponseHeaders } from 'axios';
import { from } from 'rxjs';

import { getFileNameFromHeaders, parseISODateStrings } from 'utils';
import RittenClient from './RittenClient';
import { errorHandler } from './util';

class DocumentClient extends RittenClient {
  queryDocuments = async (query: Documents.DocumentQuery) => {
    const params: AxiosRequestConfig = {
      params: {
        ...query,
      },
    };
    const res = await this.get<Documents.Document[]>(`api/documents`, params);
    return parseISODateStrings(res.data);
  };

  queryDocuments$ = (query: Documents.DocumentQuery) => from(this.queryDocuments(query));

  queryDocumentCount = async (userId: string) => {
    const res = await this.get<Documents.DocumentCountResponse>(`api/downloads/count/${userId}`);
    return res.data;
  };

  postDocument = async (document: Documents.DocumentPostBody) => {
    const res = await this.post<Documents.Document>(`api/documents`, document);
    return res.data;
  };

  postDocument$ = (document: Documents.DocumentPostBody) => from(this.postDocument(document));

  patchDocument = async (document: Documents.Document) => {
    const res = await this.patch<Documents.Document>(`api/documents/${document.id}`, document);
    return res.data;
  };

  patchDocument$ = (document: Documents.Document) => from(this.patchDocument(document));

  postFile = async (fileBlob: Blob) => {
    const formData = new FormData();
    formData.append('file', fileBlob);

    const res = await this.post<API.IDResponse>(`api/files`, formData);
    return res.data;
  };

  postFile$ = (fileBlob: Blob) => from(this.postFile(fileBlob));

  downloadDocument = async (documentId: string) => {
    const res = await this.get<Blob>(`api/documents/${documentId}/download`, {
      responseType: 'blob',
    });
    const headers = res.headers as AxiosResponseHeaders;
    const fileName = getFileNameFromHeaders(headers['content-disposition']);
    const url = window.URL.createObjectURL(res.data);
    const link = document.createElement('a');
    link.href = url;
    link.download = fileName;
    document.body.appendChild(link);
    link.click();
  };

  viewDocument = async (documentId: string) => {
    const res = await this.get<Blob>(`api/documents/${documentId}/view`, {
      responseType: 'blob',
    });
    return res.data;
  };

  downloadDocument$ = (documentId: string) => from(this.downloadDocument(documentId));

  getFileDataURL = async (fileId: string) => {
    const res = await this.get<Documents.FileDataURLResponse>(`/api/files/${fileId}/dataurl`);
    return res.data;
  };

  getFileDataURL$ = (fileId: string) => from(this.getFileDataURL(fileId));

  getFileByID = async (fileID: string): Promise<Documents.File> => {
    const res = await this.get<Documents.File>(`/api/files/${fileID}`).catch(errorHandler);
    return res.data;
  };

  getFileByID$ = (fileId: string) => from(this.getFileByID(fileId));

  deleteDocument = async (docID: string): Promise<void> => {
    const res = await this.delete<void>(`/api/documents/${docID}`);
    return res.data;
  };

  getDocument = async (documentId: string): Promise<Documents.Document> => {
    const res = await this.get<Documents.Document>(`api/documents/${documentId}`);
    return res.data;
  };

  getFileById = async (fileId: string): Promise<Documents.File> => {
    const res = await this.get<Documents.File>(`/api/files/${fileId}`);
    return res.data;
  };

  uploadDocumentOnEncounter = async (
    encounterId: string,
    documentId: string,
  ): Promise<API.IDResponse> => {
    const res = await this.post<API.IDResponse>(
      `api/encounters/instances/${encounterId}/documents/${documentId}`,
    );
    return res.data;
  };

  getAllDocumentsOnEncounter = async (encounterId: string): Promise<Documents.Document[]> => {
    const res = await this.get<Documents.Document[]>(
      `api/encounters/instances/${encounterId}/documents`,
    );
    return res.data;
  };

  getEncounterByDocumentId = async (documentId: string): Promise<API.IDResponse> => {
    const res = await this.get<API.IDResponse>(`api/documents/${documentId}/encounter`);
    return res.data;
  };

  getDocumentDataURL = async (docId: string): Promise<Documents.FileDataURLResponse> => {
    const res = await this.get<Documents.FileDataURLResponse>(`/api/documents/${docId}/dataurl`);
    return res.data;
  };

  postDocumentPortalAccessRoles = async (
    documentId: string,
    body: Documents.DocumentPortalAccessRolePostBody[],
  ): Promise<void> => {
    await this.post<void>(`/api/documents/${documentId}/portal-access-roles`, body);
  };

  postDocumentPortalAccessRolesBulk = async (
    body: Documents.DocumentPortalAccessRolesBulkPostBody,
  ): Promise<void> => {
    const { documentIds, portalAccessRoles } = body;
    await this.post<void>(`/api/documents/portal-access-roles`, {
      documentIds,
      patientIds: portalAccessRoles.map(({ patientId }) => patientId),
    });
  };
}

export default DocumentClient;
