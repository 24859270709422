import { Checkmark } from 'grommet-icons';

import { COLORS } from '../../styles/colors';
import SimpleToast from './SimpleToast';

interface SuccessToastProps {
  message: string | JSX.Element;
  subText?: string;
}

const SuccessToast = (props: SuccessToastProps) => {
  const { message, subText } = props;

  return (
    <SimpleToast message={message} icon={<Checkmark color={COLORS.green400} />} subText={subText} />
  );
};

export default SuccessToast;
