import toast from 'react-hot-toast';
import { Box, Text } from 'grommet';
import { FormClose } from 'grommet-icons';

import { COLORS } from 'styles/colors';

interface SimpleToastProps {
  message: string | JSX.Element;
  icon: JSX.Element;
  subText?: string;
}

const SimpleToast = (props: SimpleToastProps) => {
  const { message, icon, subText } = props;

  return (
    <Box background={COLORS.white} pad="8px 10px" round="8px">
      <Box direction="row">
        <Box direction="row" align="center" gap="20px">
          {icon}
          {typeof message === 'string' ? <Text size="16px">{message}</Text> : message}
        </Box>
        <Box align="end" onClick={() => toast.dismiss()}>
          <FormClose color={COLORS.darkGray600} size="24px" data-testid="toast-close" />
        </Box>
      </Box>

      {subText && (
        <Box pad={{ left: '44px', top: '8px' }}>
          <Text size="14px">{subText}</Text>
        </Box>
      )}
    </Box>
  );
};

export default SimpleToast;
