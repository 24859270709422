// @ts-strict-ignore
import { formatISO, isEqual } from 'date-fns';
import { throttle } from 'lodash';

import { getDummyDate } from 'features/clients/mar/utils';
import RittenClient from './RittenClient';
import { dateParseResponseInterceptor } from './util';

const requestCache = new Map<string, (...any) => any>();

const createRequestCacheKey = (method: string, route: string, query: {}): string => {
  const serializedQuery = JSON.stringify(query);
  return `${method}:${route}:${serializedQuery}`;
};

export default class MARClient extends RittenClient {
  constructor(...args: ConstructorParameters<typeof RittenClient>) {
    super(...args);
    this.responseInterceptors = [dateParseResponseInterceptor]; // parse dates automagically
  }

  /**
   * Creates a new schedule for a patient.
   */
  createSchedule = async (
    patientId: string,
    schedule: MAR.CreatableSchedule,
  ): Promise<MAR.Schedule> => {
    let { startsAt } = schedule;
    // Should keep the exact time if they input it for a Doses Interval
    if (schedule.startBehavior !== 'given time') {
      // Truncate the date to YYYY-MM-DD in UTC. This acts as a floating date.
      // e.g. 2020-05-12T09:33:51-0400 => 2020-05-12T00:00:00Z
      startsAt = new Date(
        Date.UTC(startsAt.getFullYear(), startsAt.getMonth(), startsAt.getDate()),
      );
    } else {
      startsAt = new Date(
        Date.UTC(
          startsAt.getFullYear(),
          startsAt.getMonth(),
          startsAt.getDate(),
          startsAt.getHours(),
          startsAt.getMinutes(),
        ),
      );
    }

    const { data } = await this.post<MAR.Schedule>(`api/mar/patients/${patientId}/schedules`, {
      ...schedule,
      // We send the truncated date to the backend, which combines it with the timeZone
      // property to create the actual start date.
      startsAt,
      encounterId: schedule.encounterId ?? undefined,
      orderId: schedule.orderId ?? undefined,
    });
    return data;
  };

  /**
   * Creates multiple new schedules for a patient. All fail if one fails.
   */
  createSchedules = async (
    patientId: string,
    encounterId: string,
    userId: string,
    schedules: MAR.CreatableSchedule[],
  ): Promise<MAR.Schedule[]> => {
    const body: MAR.CreatableSchedule[] = schedules.map((s) => {
      let { startsAt } = s;
      // Should keep the exact time if they input it for a Doses Interval
      if (s.startBehavior !== 'given time') {
        // Truncate the date to YYYY-MM-DD in UTC. This acts as a floating date.
        // e.g. 2020-05-12T09:33:51-0400 => 2020-05-12T00:00:00Z
        startsAt = new Date(
          Date.UTC(startsAt.getFullYear(), startsAt.getMonth(), startsAt.getDate()),
        );
      } else {
        startsAt = new Date(
          Date.UTC(
            startsAt.getFullYear(),
            startsAt.getMonth(),
            startsAt.getDate(),
            startsAt.getHours(),
            startsAt.getMinutes(),
          ),
        );
      }
      return {
        ...s,
        encounterId,
        userId,
        orderId: s.orderId ?? undefined,
        startsAt,
      };
    });
    const { data = [] } = await this.post<MAR.Schedule[]>(
      `api/mar/patients/${patientId}/schedules/multi`,
      body,
    );
    return data;
  };

  /**
   * Lists all schedules for a patient ID.
   */
  listSchedules = async (patientId: string): Promise<MAR.Schedule[]> => {
    const { data } = await this.get<MAR.Schedule[]>(`api/mar/patients/${patientId}/schedules`);
    return data;
  };

  /**
   * Lists all schedules given the query parameters.
   */
  listAllSchedules = async (queryParams: MAR.ListAllSchedulesQuery): Promise<MAR.Schedule[]> => {
    const { data } = await this.get<MAR.Schedule[]>(`api/mar/all-schedules`, {
      params: {
        ...queryParams,
      },
    });
    return data;
  };

  /**
   * Gets a schedule by ID.
   */
  getSchedule = async (id: string): Promise<MAR.Schedule> => {
    const { data } = await this.get<MAR.Schedule>(`api/mar/schedules/${id}`);
    return data;
  };

  /**
   * Updates a schedule by ID.
   */
  updateSchedule = async (id: string, schedule: MAR.CreatableSchedule): Promise<MAR.Schedule> => {
    const { data } = await this.put<MAR.Schedule>(`api/mar/schedules/${id}`, schedule);
    return data;
  };

  /**
   * Archives a schedule by ID.
   */
  archiveSchedule = async (id: string): Promise<void> => {
    await this.delete<void>(`api/mar/schedules/${id}`);
  };

  /**
   * Generic function list MAR doses for all schedule types for a given patient ID.
   */
  #listPatientDoses = async (
    path: string,
    patientId: string,
    query: MAR.ListPatientDoseQuery,
  ): Promise<MAR.Dose[]> => {
    const { data } = await this.get<MAR.Dose[]>(`api/mar/patients/${patientId}${path}`, {
      params: {
        ...query,
        startDate: formatISO(query.startDate, { representation: 'complete' }),
        endDate: formatISO(query.endDate, { representation: 'complete' }),
      },
    });
    return sortDoses(data);
  };

  /**
   * Lists all doses for a patient across schedules excluding PRN.
   */
  listPatientDoses = async (
    patientId: string,
    query: MAR.ListPatientDoseQuery,
  ): Promise<MAR.Dose[]> => this.#listPatientDoses('/doses', patientId, query);

  /**
   * Lists all doses for a patient for PRN schedules.
   */
  listPatientPRNDoses = async (
    patientId: string,
    query: MAR.ListPatientDoseQuery,
  ): Promise<MAR.Dose[]> => this.#listPatientDoses('/doses/prn', patientId, query);

  /**
   * Lists MAR doses for a given time window for ALL patients (Med Pass).
   *
   * This is a computationally intensive endpoint and therefore throttled
   * to avoid bricking the API. A request is throttled based on its query
   * params to allow API requests with differing query params to proceed
   * without being unnecessarily throttled.
   */
  listDoses = async (query: MAR.ListDoseQuery): Promise<MAR.DosesResponse> => {
    const key = createRequestCacheKey('GET', 'api/mar/doses', query);

    if (!requestCache.has(key)) {
      // Calls the function at most once every second
      const listDosesThrottled = throttle(this.#listDoses, 1000);
      requestCache.set(key, listDosesThrottled);
    }

    return requestCache.get(key)(query);
  };

  #listDoses = async (query: MAR.ListDoseQuery): Promise<MAR.DosesResponse> => {
    const { data } = await this.get<MAR.DosesResponse>('api/mar/doses', {
      params: {
        ...query,
        startDate: formatISO(query.startDate ?? new Date(), { representation: 'complete' }),
        endDate: formatISO(query.endDate ?? new Date(), { representation: 'complete' }),
      },
    });
    data.doses = sortDoses(data.doses ?? []);
    return data;
  };

  /**
   * Gets a dose by ID.
   */
  getDose = async (id: string): Promise<MAR.Dose> => {
    const { data } = await this.get<MAR.Dose>(`api/mar/doses/${id}`);
    return data;
  };

  /**
   * Updates a dose by ID.
   */
  updateDose = async (id: string, attributes: MAR.UpdatableMARDose): Promise<MAR.Dose> => {
    const { data } = await this.put<MAR.Dose>(`api/mar/doses/${id}`, attributes);
    return data;
  };

  /**
   * Resets a dose to unlogged state by ID.
   */
  resetDose = async (id: string): Promise<void> => {
    await this.delete<MAR.Dose>(`api/mar/doses/${id}/log`);
  };

  /**
   * Gets a first dose response (FDR) by ID.
   */
  getFirstDoseResponse = async (id: string): Promise<MAR.FirstDoseResponse> => {
    const { data } = await this.get<MAR.FirstDoseResponse>(`api/mar/first-dose-responses/${id}`);
    return data;
  };

  /**
   * Creates a new first dose response (FDR) for a dose ID.
   */
  createFirstDoseResponse = async (data: MAR.CreatableMARFDR): Promise<void> => {
    await this.post(`/api/mar/first-dose-responses`, data);
  };

  /**
   * Signs a dose for staff and/or patient.
   */
  signDose = async (
    doseId: string,
    userSignatureId?: string,
    patientSignatureId?: string,
  ): Promise<void> => {
    await this.post(`api/mar/doses/${doseId}/signature`, {
      userSignatureId,
      patientSignatureId,
    });
  };

  /**
   * Signs multiple MAR orders with user (staff) signatures.
   */
  signOrders = async (signableOrders: MAR.SignableOrder[]): Promise<Signatures.Signature[]> => {
    const { data = [] } = await this.post<Signatures.Signature[]>(
      `api/mar/orders/signatures`,
      signableOrders,
    );
    return data;
  };

  /**
   * Discontinues a MAR order and corresponding schedule, and deletes all pending doses.
   */
  deleteOrder = async (
    orderId: string,
    signatureId: string,
    reason: string,
    discontinueOrderingProviderId?: string,
  ): Promise<void> => {
    await this.post<void>(`api/mar/orders/${orderId}/delete`, {
      signatureId,
      comments: reason,
      discontinueOrderingProviderId,
    });
  };

  /**
   * Gets a MAR order by ID.
   */
  getOrder = async (orderId: string): Promise<MAR.Order> => {
    const { data } = await this.get<MAR.Order>(`api/mar/orders/${orderId}`);
    return data;
  };

  /**
   * Updates a MAR order status.
   */
  updateOrderStatus = async (
    orderId: string,
    status: MAR.UpdatableMAROrderStatus,
  ): Promise<MAR.Order> => {
    const { data } = await this.patch<MAR.Order>(`api/mar/orders/${orderId}/status`, {
      status,
    });
    return data;
  };

  /**
   * Creates a new MAR schedule template.
   * If this is a doses interval without a given time to start
   * use a dummy date to indicate that the start time should be blank when they open the template
   */
  createScheduleTemplate = async (
    template: Pick<MAR.ScheduleTemplate, 'name' | 'schedule'>,
  ): Promise<MAR.ScheduleTemplate> => {
    if (
      template.schedule.startBehavior === 'given time' &&
      (!template.schedule.startTime || template.schedule.startTime === '')
    ) {
      template.schedule.startsAt = getDummyDate();
    }
    const { data } = await this.post<MAR.ScheduleTemplate>(`api/mar/schedule-templates`, template);
    return data;
  };

  /**
   * Lists MAR schedule templates (searchable, paginated).
   */
  listScheduleTemplates = async (
    params: Partial<MAR.ScheduleTemplateListQueryParams> = {},
  ): Promise<MAR.ScheduleTemplate[]> => {
    const { data = [] } = await this.get<MAR.ScheduleTemplate[]>(`api/mar/schedule-templates`, {
      params: {
        ...params,
        limit: params.limit ?? 100,
        offset: params.offset ?? 0,
      },
    });
    return data;
  };

  /**
   * Gets a single MAR schedule templates by ID.
   */
  getScheduleTemplateById = async (id: string): Promise<MAR.ScheduleTemplate> => {
    const { data } = await this.get<MAR.ScheduleTemplate>(`api/mar/schedule-templates/${id}`);
    return data;
  };

  /**
   * Archives a new MAR schedule template by ID.
   */
  archiveScheduleTemplate = async (id: string): Promise<void> => {
    await this.delete<void>(`api/mar/schedule-templates/${id}`);
  };

  /**
   * update pill count for an order
   */
  updatePillCount = async (scheduleId: string, updatedPillCount: number): Promise<MAR.Schedule> => {
    const { data } = await this.patch<MAR.Schedule>(`api/mar/schedules/${scheduleId}/pill-count`, {
      pillCount: updatedPillCount,
    });
    return data;
  };

  /**
   *
   * @param doseId
   * remove signature from an individual dose - permission controlled through user's accessPolicies
   */
  removeDoseSignature = async (doseId: string) => {
    await this.delete<void>(`api/mar/doses/${doseId}/signatures`);
  };

  /**
   * gets full list of template sets
   */
  listOrderTemplateSets = async () => {
    const { data } = await this.get<MAR.OrderTemplateSet[]>('api/mar/schedule-template-sets');
    return data;
  };

  /**
   * create and update template set
   * @param name
   * @param id
   * @param templateIds
   * @returns OrderTemplateSet
   */
  postTemplateSet = async (name: string, id: string | undefined, templateIds: string[]) => {
    const { data } = await this.post<MAR.OrderTemplateSet>('api/mar/schedule-template-sets', {
      id,
      name,
      templateIds,
    });
    return data;
  };

  deleteTemplateSet = async (templateId: string) => {
    await this.delete<void>(`api/mar/schedule-template-sets/${templateId}`);
  };
}

/**
 * Sorts the doses by medication name as well as scheduled time (by default).
 * This avoids UI jankiness when two doses share the exact same scheduled time.
 */
const sortDoses = (doses: MAR.Dose[]) =>
  doses.sort((i, j) => {
    const dateEqual = isEqual(i.scheduledAt, j.scheduledAt);
    if (dateEqual) {
      return i.medication < j.medication ? -1 : 1;
    }
    return 0; // keep original order
  });
