import { useState } from 'react';
import { Box, RadioButtonGroup, RadioButtonGroupProps, ThemeContext } from 'grommet';
import { grommet } from 'grommet/themes';
import { deepMerge, DirectionType } from 'grommet/utils';
import { v4 } from 'uuid';

import { COLORS } from '../../styles/colors';
import { isNotEmpty } from '../../utils/stringUtils';
import ErrorLabel from '../labels/ErrorLabel';
import InputLabel from './InputLabel';

export interface RadioButtonGroupComponentProps {
  label: string;
  radioButtonGroupProps: RadioButtonGroupProps & {
    id: string;
  };
  width?: string;
  direction?: DirectionType;
  labelTextSize?: string;
  optionTextSize?: string;
  optionGap?: string;
  'data-testid'?: string;
  justify?: 'around' | 'between' | 'center' | 'end' | 'evenly' | 'start' | 'stretch';
  error?: string;
  padding?: string;
  requirementMarker?: string;
  inputLabel?: React.ReactNode;
  hideErrorMessage?: boolean;
}

export interface ChoiceObject<T = string | number | boolean> {
  disabled?: boolean;
  id?: string;
  label?: string | React.ReactNode;
  value: T;
}

export const RADIO_OPTIONS_Y_N: { label: string; value: boolean }[] = [
  { label: 'Yes', value: true },
  { label: 'No', value: false },
];

/**
 * Provides Yes/No/Empty options for a RadioButtonGroupComponent
 */
export const RADIO_OPTIONS_Y_N_EMPTY: { label: string; value: boolean }[] = [
  { label: 'Yes', value: true },
  { label: 'No', value: false },
  { label: 'Empty', value: null as unknown as boolean }, // incredibly annoying cast because of bad grommet typing
];

/**
 *
 * @param e the ChangeEvent from the RadioButtonGroupComponent
 * @returns true, false or null based on the ChangeEvent provided
 */
export const radioOnChange = (e: React.ChangeEvent<HTMLInputElement>): boolean | null => {
  let val: boolean | null = null;
  if (e.target.value) {
    val = e.target.value === 'true';
  }
  return val;
};

const RadioButtonGroupComponent = (props: RadioButtonGroupComponentProps) => {
  const {
    label,
    radioButtonGroupProps,
    width,
    direction,
    labelTextSize,
    optionGap,
    justify,
    padding,
    requirementMarker,
    hideErrorMessage = false,
  } = props;

  const radioButtonGroupTheme = deepMerge(grommet, {
    radioButton: {
      size: '16px',
      color: COLORS.rittenBlue400,
      hover: {
        border: {
          color: COLORS.rittenBlue400,
        },
      },
      border: {
        color: COLORS.darkGray400,
        opacity: 1,
        width: '1px',
      },
      container: {
        extend: {
          padding: padding ?? '6px 40px 6px 0',
          fontSize: props.optionTextSize || '16px',
          opacity: radioButtonGroupProps.disabled ? 0.5 : 1,
        },
      },
      check: {
        color: COLORS.rittenBlue400,
        border: {
          color: COLORS.rittenBlue400,
        },
      },
    },
  });

  const [radioKey] = useState<string>(v4());

  // In rare cases, radio Ids can collide.  When this happens one of the radio buttons
  // will not work. This ensures when the option Ids are passed in - a unique identifier is
  // concatenated onto it.
  const options = radioButtonGroupProps.options?.map((o) => {
    if (typeof o === 'object' && isNotEmpty(o?.id)) {
      o.id = `${o.id}_${radioKey}`;
    }
    return o;
  });

  return (
    <ThemeContext.Extend value={radioButtonGroupTheme}>
      <Box width={width} data-testid={props['data-testid']}>
        {props.inputLabel ? (
          props.inputLabel
        ) : (
          <InputLabel
            label={label}
            textSize={labelTextSize}
            requirementMarker={requirementMarker}
          />
        )}
        <RadioButtonGroup
          {...radioButtonGroupProps}
          aria-label={label || ''}
          options={options}
          name={radioButtonGroupProps.name}
          // We concatenate a unique key to ensure there are no collisions.
          id={radioButtonGroupProps.id + radioKey}
          direction={direction || 'row'}
          wrap
          margin="0px"
          gap={direction === 'row' ? optionGap ?? '40px' : optionGap ?? '6px'}
          justify={justify ?? 'start'}
        />
        {props.error && !hideErrorMessage && (
          <Box pad={{ top: 'xxsmall' }}>
            <ErrorLabel>{props.error}</ErrorLabel>
          </Box>
        )}
      </Box>
    </ThemeContext.Extend>
  );
};

export default RadioButtonGroupComponent;
