// @ts-strict-ignore
import { PropsWithChildren } from 'react';
import { Box } from 'grommet';
import { PadType } from 'grommet/utils';
import { FormClose, FormDown } from 'grommet-icons';
import { isEmpty } from 'lodash';

import { COLORS } from 'styles/colors';
import { DropdownChoice, Variant } from '../Dropdown';

interface AnchorContainerProps {
  disabled: boolean;
  onDropClick: () => void;
  anchorRef: any;
  width?: string | { min?: string; max?: string };
  gap?: string;
  variant: Variant;
  onSelectChoice?: (choice: DropdownChoice) => void;
  selection?: DropdownChoice;
  showClearSelection?: boolean;
  'data-testid'?: string;
  hasError?: boolean;
  pad?: PadType;
  iconSize?: string;
  iconColor?: string;
  borderColor?: string;
}

const AnchorContainer = (props: PropsWithChildren<AnchorContainerProps>) => {
  const {
    disabled,
    anchorRef,
    onDropClick,
    width,
    gap = '10px',
    children,
    variant,
    onSelectChoice,
    selection,
    showClearSelection,
    hasError = false,
    pad,
    iconSize,
    iconColor = COLORS.darkGray600,
    borderColor = COLORS.darkGray400,
  } = props;
  return (
    <Box
      pad={pad ?? { vertical: '7px', horizontal: '10px' }}
      gap={gap}
      direction="row"
      align="center"
      justify="between"
      onClick={!disabled ? onDropClick : null}
      ref={anchorRef}
      height="auto"
      border={{ color: hasError ? COLORS.red400 : borderColor }}
      width={width || 'auto'}
      round="4px"
      background={disabled ? COLORS.darkGray200 : COLORS.white}
      data-testid={props['data-testid']}
    >
      {children}
      <Box direction="row" align="center" flex={false}>
        {variant === 'single-choice' && !isEmpty(selection) && showClearSelection && !disabled && (
          <FormClose
            color={iconColor}
            onClick={(e) => {
              e.stopPropagation();
              onSelectChoice?.(undefined);
            }}
            size={iconSize ?? '22px'}
          />
        )}
        <FormDown color={iconColor} data-testid="dropdown-formdown" size={iconSize} />
      </Box>
    </Box>
  );
};

export default AnchorContainer;
