// @ts-strict-ignore
import { Ref, useEffect, useState } from 'react';
import { format, parseISO } from 'date-fns';
import {
  Box,
  DateInput,
  DateInputProps,
  HeaderProps,
  MaskedInputExtendedProps,
  ThemeContext,
} from 'grommet';
import { grommet } from 'grommet/themes';
import { deepMerge } from 'grommet/utils';

import useMobileDevice from 'context/mobileDevice/useMobileDevice';
import { COLORS } from 'styles/colors';
import { isEmpty, isNotEmpty } from 'utils/stringUtils';
import { convertDateToISOString, convertISOStringToDate } from '../../utils/dateParsers';
import CalendarDay from '../calendar/CalendarDay';
import CalendarHeader from '../calendar/CalendarHeader';
import ErrorLabel from '../labels/ErrorLabel';
import InputLabel from './InputLabel';
import { useInputTheme } from './useInputTheme';

interface DateInputComponentProps {
  label: string;
  requirementMarker?: string;
  width?: string;
  error?: string;
  dropPlacement?: 'Above' | 'Below';
  // custom onChange to send back a js date instead of an iso string
  onDateChange?: (newDate: Date) => void;
  dateValue?: Date | null;
  dateInputProps: DateInputProps & {
    disabled?: boolean;
    inputProps?: MaskedInputExtendedProps;
  };
  'data-cy'?: string;
  'data-testid'?: string;
  dateInputRef?: Ref<HTMLInputElement>;
  tooltip?: string;
  labelWidth?: string;
  inputLabel?: React.ReactNode;
  hideIcon?: boolean;
  hideErrorMessage?: boolean;
}

const DateInputComponent = (props: DateInputComponentProps) => {
  const {
    label,
    width,
    dropPlacement = 'Below',
    onDateChange,
    dateValue,
    dateInputProps,
    requirementMarker,
    dateInputRef,
    tooltip,
    inputLabel,
    labelWidth,
    hideIcon = false,
    hideErrorMessage = false,
  } = props;

  const { isMobile } = useMobileDevice();
  const { onFocus, focusStyle, extendInputContainerProperties, getErrorStyle } = useInputTheme();
  const errorStyle = getErrorStyle(props.error);
  const [value, setValue] = useState<string | string[]>('');

  // This date represents the month shown on the Calendar when the DateInput Drop is open and showing the calendar view
  const [currentDateOnCalendarHeader, setCurrentDateOnCalendarHeader] = useState<Date>();

  const styleDateInput: React.CSSProperties = {
    background: props.dateInputProps.disabled ? COLORS.darkGray200 : COLORS.white,
    fontSize: '16px',
    fontWeight: 400,
    opacity: 1,
    height: '40px',
    borderRadius: '4px',
    ...focusStyle,
    ...errorStyle,
  };

  const themeDateInput = deepMerge(grommet, {
    textInput: {
      extend: {
        backgroundColor: props.dateInputProps.disabled ? COLORS.darkGray200 : COLORS.white,
        fontSize: '16px',
        fontWeight: 400,
      },
      disabled: {
        opacity: 1,
      },
    },
    maskedInput: {
      extend: extendInputContainerProperties('100%', props.dateInputProps.disabled),
    },
    calendar: {
      extend: `
      overflow: scroll;
    `,
    },
  });

  useEffect(() => {
    // Right now the Grommet Date Input is bugged and depending on your version
    // you can either clear or update but you can't do both
    // Reproduce here: https://codesandbox.io/s/grommet-date-bug-e8bch?file=/src/App.tsx

    // eventually we only want to accept js dates as the value
    // for now, we need to be able to handle both
    // this tests if a JS Date was passed down as the prop value
    if (dateValue) {
      // convert it to an ISO string and then pass it through the same logic to set the value for the date input
      const dateAsISOString = convertDateToISOString(dateValue);
      updateValueState(dateAsISOString);
    } else {
      // keeping the old logic if it is NOT a date (an ISO String is being passed down as the value)
      updateValueState(dateInputProps.value);
    }
  }, [dateInputProps.value, dateValue]);

  const updateValueState = (value) => {
    if (
      (typeof value === 'string' && isEmpty(dateInputProps?.value as string)) ||
      (typeof value === 'object' && value.length === 0) ||
      !value
    ) {
      setValue(value);
      // Commenting out this re-render for now.  This is causing issues when typing
      // in a date.  The DateInput re-renders and loses focus.  Very annoying and in
      // some cases makes it impossible to enter a date.
      // setRerender(uuid.v4());
    } else {
      setValue(localizeValue(value));
    }
  };

  const localizeValue = (value: string | string[]) => {
    let convertedValue = value;
    if (typeof value === 'string') {
      convertedValue = localizeAndFormatDate(value);
    }
    if (typeof value === 'object' && value.length > 0) {
      convertedValue = value.map((v) => localizeAndFormatDate(v));
    }
    return convertedValue;
  };

  const localizeAndFormatDate = (date: string) => {
    if (isNotEmpty(date)) {
      return format(parseISO(date), 'M/d/y');
    }
    return '';
  };

  const renderHeader = (calendarRenderHeaderProps: HeaderProps) => {
    const { date } = calendarRenderHeaderProps;
    setCurrentDateOnCalendarHeader(date);

    return (
      <CalendarHeader
        calendarRenderHeaderProps={calendarRenderHeaderProps}
        headingTextStyle={{ weight: 'bold' }}
        daysOfWeekTextStyle={{ weight: 'bold', size: '15px' }}
      />
    );
  };

  const defaultWidth = isMobile ? '100%' : '175px';

  return (
    <ThemeContext.Extend value={themeDateInput}>
      <Box width={labelWidth || width || defaultWidth}>
        {inputLabel || (
          <InputLabel label={label} requirementMarker={requirementMarker} tooltipText={tooltip} />
        )}
        <Box width={width ?? defaultWidth}>
          <DateInput
            ref={dateInputRef}
            data-cy={props['data-cy']}
            data-testid={props['data-testid']}
            /* THIS key is a HACK - in Grommet 2.17.4 we cannot clear value, so we need to remove then re-add to dom */
            /* I spent a bunch of time trying different things and couldn't get anything else to work. */
            // key={rerender}
            {...dateInputProps}
            inputProps={{
              style: styleDateInput,
              ...(hideIcon && { icon: undefined }),
            }}
            onFocus={() => onFocus(true)}
            onBlur={() => onFocus(false)}
            onChange={(e) => {
              // If using JS date, the onChange should run the custom onChange
              // function, sending up a JS date
              if (onDateChange) {
                // the selected date will  be an iso string
                const selectedISOString = e.value as string;

                // convert the iso string to a js date
                const selectedDate = convertISOStringToDate(selectedISOString);

                // check if selected date is a valid value (this will be falsey if NaN/Invalid Date)
                const isValidSelection = selectedDate?.valueOf();
                if (isValidSelection) {
                  // pass the js date to the custom onDateChange function
                  onDateChange(selectedDate);
                } else {
                  // otherwise clear the input
                  onDateChange(undefined);
                }
              } else {
                // if it doesn't useJSDate, just call onChange with e as we normally do
                dateInputProps.onChange(e);
              }
            }}
            // We need to overrride the value locally because the Grommet
            // DateInput component will not localize the date, and will display the wrong date
            // when the time is on the edges of the days.  So 4/2/2019 11pm EST will display as 4/3/2019.
            // By localizing the date first we fix this problem.
            value={value}
            aria-label={label || ''}
            calendarProps={{
              header: renderHeader,
              children: (props) => (
                <CalendarDay
                  onSelect={() => {}} // noop: nothing necessary here because Grommet already triggers onChange behind the scenes when the rendered CalendarDay is clicked
                  calendarRenderDayProps={props}
                  currentDateOnCalendarHeader={currentDateOnCalendarHeader}
                  calendarDayWidth="36px"
                />
              ),
              daysOfWeek: false,
              fill: true,
              animate: false, // right now the animation is a little janky - so disabling
            }}
            dropProps={{
              style: {
                width: '200px',
                padding: '20px',
              },
              align:
                dropPlacement === 'Above'
                  ? { bottom: 'top', left: 'left' } // above
                  : { top: 'bottom', left: 'left' }, // below
              ...dateInputProps.dropProps,
            }}
          />
        </Box>
        {props.error && !hideErrorMessage && (
          <Box pad={{ top: 'xxsmall' }}>
            <ErrorLabel>{props.error}</ErrorLabel>
          </Box>
        )}
      </Box>
    </ThemeContext.Extend>
  );
};

export default DateInputComponent;
