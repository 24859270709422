import { Box, Text } from 'grommet';

import { StandardButton } from '@ritten/ui-library/buttons';
import { BaseModal } from '@ritten/ui-library/modals';

interface UserInactiveModalProps {
  onContinue: () => Promise<string>;
}

const UserInactiveModal = (props: UserInactiveModalProps) => {
  const { onContinue } = props;

  return (
    <BaseModal
      isOpen
      header="Session Expired"
      footer={
        <Box direction="row" justify="center" align="center" width="100%">
          <StandardButton label="Log In" onClick={onContinue} />
        </Box>
      }
    >
      <Text>Your session has expired. Log back in to continue.</Text>
    </BaseModal>
  );
};

export default UserInactiveModal;
