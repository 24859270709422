import { useLocation, useRouteMatch } from 'react-router-dom';
import { Box, Text } from 'grommet';

import { FeatureFlagLabel } from 'common/feature-flags/featureFlags';
import useFeatureFlags from 'context/featureFlags/useFeatureFlags';
import useMobileDevice from 'context/mobileDevice/useMobileDevice';
import useUser from 'context/user/useUser';
import SubNavLink from 'features/nav/SubNavLink';
import { getSelectedClientId } from '../utils';

interface PortalSubNavProps {
  showPaymentMethods: boolean;
}

const PortalSubNav = (props: PortalSubNavProps) => {
  const { showPaymentMethods } = props;

  const match = useRouteMatch();
  const { pathname } = useLocation();
  const { user } = useUser();
  const { isFlagOn } = useFeatureFlags();
  const { isMobile } = useMobileDevice();

  const selectedClientId = getSelectedClientId(pathname);
  const selectedClientIsUser = selectedClientId === user.patientId;
  const isPortalAgendaFlagOn = isFlagOn(FeatureFlagLabel.CLIENT_PORTAL_AGENDA);

  return (
    <Box direction="row" overflow={isMobile ? { horizontal: 'scroll' } : 'auto'}>
      <SubNavLink to="/forms" url={match.url} isProtected={false}>
        Forms
      </SubNavLink>
      {selectedClientIsUser && isPortalAgendaFlagOn && (
        <SubNavLink to="/agenda" url={match.url} isProtected={false}>
          Agenda
        </SubNavLink>
      )}
      <SubNavLink to="/attachments" url={match.url} isProtected={false}>
        Attachments
      </SubNavLink>
      {showPaymentMethods && (
        <SubNavLink to="/payment-methods" url={match.url} isProtected={false}>
          <Text size="16px" style={{ whiteSpace: 'nowrap' }}>
            Payment Methods
          </Text>
        </SubNavLink>
      )}
    </Box>
  );
};

export default PortalSubNav;
