// @ts-strict-ignore
import { Box, Layer, Text } from 'grommet';

import LinkButton from '../buttons/LinkButton';
import StandardButton from '../buttons/StandardButton';
import LoadingPanel from '../panels/LoadingPanel';

type ConfirmationModalProps = React.PropsWithChildren<{
  open: boolean;
  onYes: () => void;
  onCancel?: () => void;
  loading?: boolean;
}>;

export const ConfirmationModal: React.FC<ConfirmationModalProps> = (
  props: ConfirmationModalProps,
) => {
  if (!props.open) {
    return <></>;
  }

  return (
    <Layer onClickOutside={props.onCancel}>
      <Box pad="40px" gap="20px">
        <Box>
          <Text size="20px" weight="bold">
            Confirm
          </Text>
        </Box>
        {props.children && <Box>{props.children}</Box>}
        <Box>
          <Text>Would you like to continue?</Text>
        </Box>
        <Box direction="row" justify="end">
          <LinkButton onClick={props.onCancel} color="plain">
            Cancel
          </LinkButton>
          <LoadingPanel showLoader={props.loading}>
            <Box pad={{ left: '20px' }}>
              <StandardButton
                onClick={props.onYes}
                color="default"
                label="Yes"
                isLoading={props.loading}
              />
            </Box>
          </LoadingPanel>
        </Box>
      </Box>
    </Layer>
  );
};

export default ConfirmationModal;
