import { isFieldValueEmpty } from 'features/forms/formInstanceUtils';
import { convertISOStringToDate, convertUTCDateToLocalDateOnly, isEmail } from 'utils';
import { LABEL_TO_CATEGORY_MAP, LeadAppCategory } from './constants';

export const getErrorKey = (section: CRM.LeadAppSection, field: CRM.LeadAppField) => {
  return `${section.title}-${field.label}`;
};

export const validateLeadAppFieldValues = (
  field: CRM.LeadAppField,
  section: CRM.LeadAppSection,
) => {
  if (field.label === 'lead_app_email' && !isFieldValueEmpty(field.value)) {
    return isEmail(field.value) ? null : 'Invalid email';
  }
  // TODO get async validation working if needed
  // if (field.label === 'lead_app_phone') {
  //   const isValid = await intlPhoneValidation(field.value);
  //   return isValid ? null : 'Invalid phone number';
  // }
  if (
    [
      'lead_app_insured_address_1',
      'lead_app_insured_city',
      'lead_app_insured_state',
      'lead_app_insured_zip',
    ].includes(field.label)
  ) {
    const isInsuredDifferentThanSubscriberChecked =
      section.fields.find((f) => f.label === 'lead_app_insured_different_than_client')
        ?.value?.[0] === 'Yes';
    if (isInsuredDifferentThanSubscriberChecked) {
      // These fields are required if lead_app_insured_different_than_client is checked
      return isFieldValueEmpty(field.value) ? 'Field is required' : null;
    }
  }
  return null;
};

export const parseLeadApp = (leadAppRes: CRM.LeadAppDefinition | CRM.LeadAppInstance) => {
  const newSelectedElements = new Map<LeadAppCategory, CRM.LeadAppSection[]>();
  leadAppRes.sections.forEach((section) => {
    const category = LABEL_TO_CATEGORY_MAP[section.label];
    const categorySelectedElements = newSelectedElements.get(category) || [];
    // Convert UTC date to local date for DOB fields
    // Convert to JS dates for all other date type fields
    section.fields = section.fields.map((field) => {
      if (
        field.value &&
        (field.label === 'lead_app_dob' || field.label === 'lead_app_insured_dob')
      ) {
        return {
          ...field,
          value: convertUTCDateToLocalDateOnly(field.value),
        };
      }
      if (field.value && field.type === 'date') {
        return {
          ...field,
          value: convertISOStringToDate(field.value),
        };
      }
      return field;
    });
    categorySelectedElements.push(section);
    newSelectedElements.set(category, categorySelectedElements);
  });
  return newSelectedElements;
};

export const parseLeadAppPSTData = (leadAppInstance: CRM.LeadAppInstance | null) => {
  if (!leadAppInstance || leadAppInstance.status === 'draft') {
    return null;
  }

  const firstName = leadAppInstance.sections
    .find((section) => section.label === 'lead_app_name')
    ?.fields.find((field) => field.label === 'lead_app_first_name')?.value;

  const lastName = leadAppInstance.sections
    .find((section) => section.label === 'lead_app_name')
    ?.fields.find((field) => field.label === 'lead_app_last_name')?.value;

  const email = leadAppInstance.sections
    .find((section) => section.label === 'lead_app_email')
    ?.fields.find((field) => field.label === 'lead_app_email')?.value;

  const phone = leadAppInstance.sections
    .find((section) => section.label === 'lead_app_phone')
    ?.fields.find((field) => field.label === 'lead_app_phone')?.value;

  const dobRaw = leadAppInstance.sections
    .find((section) => section.label === 'lead_app_dob')
    ?.fields.find((field) => field.label === 'lead_app_dob')?.value;
  const dob = dobRaw ? convertUTCDateToLocalDateOnly(dobRaw) : null;

  if (!firstName && !lastName && !email && !phone && !dob) {
    return null;
  }

  const pstData = {
    firstName,
    lastName,
    email,
    phone,
    dob,
  };

  return pstData;
};

export const parseLeadAppPayerInfoData = (leadAppInstance: CRM.LeadAppInstance) => {
  const payerInformation = leadAppInstance.sections.find((s) => s.label === 'lead_app_payer_info');
  const groupNumber = payerInformation?.fields.find(
    (f) => f.label === 'lead_app_payer_group_number',
  )?.value;
  const memberIdentifier = payerInformation?.fields.find(
    (f) => f.label === 'lead_app_payer_member_id',
  )?.value;
  const rxBIN = payerInformation?.fields.find((f) => f.label === 'lead_app_payer_rx_bin')?.value;
  const startDateRaw = payerInformation?.fields.find(
    (f) => f.label === 'lead_app_payer_start_date',
  );
  const startDate = startDateRaw?.value ? convertUTCDateToLocalDateOnly(startDateRaw.value) : null;
  const expirationDateRaw = payerInformation?.fields.find(
    (f) => f.label === 'lead_app_payer_expiration_date',
  );
  const expirationDate = expirationDateRaw?.value
    ? convertUTCDateToLocalDateOnly(expirationDateRaw.value)
    : null;
  const notSubscriber =
    payerInformation?.fields.find((f) => f.label === 'lead_app_insured_different_than_client')
      ?.value[0] === 'Yes';
  const firstName = payerInformation?.fields.find(
    (f) => f.label === 'lead_app_insured_first_name',
  )?.value;
  const lastName = payerInformation?.fields.find(
    (f) => f.label === 'lead_app_insured_last_name',
  )?.value;
  const address = payerInformation?.fields.find(
    (f) => f.label === 'lead_app_insured_address_1',
  )?.value;
  const city = payerInformation?.fields.find((f) => f.label === 'lead_app_insured_city')?.value;
  const state = payerInformation?.fields.find((f) => f.label === 'lead_app_insured_state')?.value;
  const postalCode = payerInformation?.fields.find(
    (f) => f.label === 'lead_app_insured_zip',
  )?.value;
  const birthDateRaw = payerInformation?.fields.find(
    (f) => f.label === 'lead_app_insured_dob',
  )?.value;
  const birthDate = birthDateRaw?.value ? convertUTCDateToLocalDateOnly(birthDateRaw.value) : null;
  const relationship = payerInformation?.fields.find(
    (f) => f.label === 'lead_app_insured_relationship',
  )?.value;

  return {
    groupNumber,
    memberIdentifier,
    rxBIN,
    startDate,
    expirationDate,
    notSubscriber,
    firstName,
    lastName,
    address,
    city,
    state,
    postalCode,
    birthDate,
    relationship,
  };
};
