// @ts-strict-ignore
import { useCallback, useState } from 'react';

import { AutoSaveState } from '../ui-library/indicators/AutoSaveIndicator';
import { asyncDebounce } from '../utils';

/**
 * Use AutoSave provides the necessary state and functions to setup an AutoSave pipeline.
 * Which consists of managaging save state for the UI indicator, debouncing save requests,
 * and managing errors.  This can be wired into update functions so anytime your state changes 
 * you can fire a save request.  This is particularly useful on text inputs which have many
 * onChange events.
 * 
 * @example 
 * 
 * In your component or hook where the update functions are, you will need to place the following.
 *
   ```ts
   const { autoSaveState, setAutoSaveState, buildAutoSave, save } = useAutoSave<
    Save_Input_Interface,  // This is what will be passed to your Save Function when you call Save()
  >(saveFunction, addError, clearErrors);//
  ```
  The saveFunction will be a function that returns a promise: (props: T) => api.call(props.a, props.b, props.c)

  *
  * To Save -> call Save(saveInput: Save_Input_Interface);
  * 
  ```
 * 
 * @param addError 
 * @param clearErrors 
 * @returns 
 */

export const useAutoSave = <T = any, P = any>(
  saveFunction: (props?: P) => Promise<T>,
  addError: (msg: string) => void,
  clearErrors: () => void,
) => {
  // Initializing to Saved because without it the indicator just shows a checkmark with no text.
  const [autoSaveState, setAutoSaveState] = useState<AutoSaveState>();

  const debouncedSaveFunction = useCallback(asyncDebounce(saveFunction, 500), []);

  /**
   * Will make a save request. All saves are subject to the debounce time.
   */
  const save = async (props?: P) => {
    clearErrors();
    setAutoSaveState('Saving');
    let result: T;
    try {
      result = await debouncedSaveFunction(props);
      setAutoSaveState('Saved');
    } catch (err) {
      addError(err);
      setAutoSaveState('Error');
    }
    return result;
  };

  return {
    autoSaveState,
    setAutoSaveState,
    save,
  };
};
