import { useEffect, useState } from 'react';
import { matchPath, useLocation } from 'react-router-dom';

import { APP_THEME } from 'styles/apptheme';
import MobileDeviceContext from './MobileDeviceContext';

const MOBILE_FRIENDLY_PATH_PATTERNS = [
  '/records/clients/:clientId', // PatientChartLanding
  '/records/clients/:clientId/chart', // PatientChart
  '/records/clients/:clientId/chart/encounters', // PatientChartEncounters
  '/records/clients/:clientId/chart/encounters/:encounterId', // PatientChartEncounterSummary
  '/records/clients/:clientId/chart/encounters/:encounterId/modifiers', // PatientChartEncounterModifiers
  '/records/clients/:clientId/chart/encounters/:encounterId/forms/:formId', // PatientChartEncounterFormDetails
  '/records/clients/:clientId/chart/forms', // PatientChartForms
  '/records/clients/:clientId/chart/forms/:formId', // PatientChartFormDetails
  '/records/clients/:clientId/chart/orders', // PatientChartOrders
  '/records/clients/:clientId/agenda', // PatientChartWeeklyAgenda
  '/records/clients/:clientId/payments', // PaymentMethodsPage
  '/records/clients/:clientId/rounds', // PatientRoundsList
  '/records/clients/:clientId/medication-record',
  '/records/clients/:clientId/medication-record/log',
  '/records/clients/:clientId/medication-record/medications',
  '/records/clients/:clientId/lab-results',
  '/records', // ClientRosterLanding
  '/records/clients', // ClientRoster
  '/records/group-encounters', // GroupEncounter
  '/records/group-encounters/*', // GroupEncounter/:Subpage
  '/records/lab-results', // LabsPage
  '/records/rounds', // RoundsPage
  '/records/rounds/:roundInstanceId', // Log Round Page
  '/calendar', // CalendarPage
  '/calendar/*', // CalendarPage/:date
  '/compliance/(actions|required-forms|due-forms)', // CompliancePage(s), except Notifications
  '/compliance', // ComplianceLanding
  '/home', // HomePageLanding
  '/home/dashboard', // HomePage
  '/home/tasks', // TasksPage
  '/crm/cases/:id/overview', // CaseIDPage Overview
  '/crm/cases/:id/lead-application', // CaseIDPage Lead Application
  '/crm/cases/:id', // CaseIDPage
  '/crm/cases', // CasesPage
  '/crm/people', // PeoplePage
  '/crm/organizations', // OrganizationsPage
  '/crm/people/:peopleId', // PeopleID Landing Page
  '/crm/people/:peopleId/notes', // PeopleNotes Page
  '/crm/people/:peopleId/attachments', // PeopleAttachments Page
  '/crm/organizations/:organizationId', // OrganizationID Landing Page
  '/crm/organizations/:organizationId/notes', // OrganizationNotesPage
  '/crm/organizations/:organizationId/attachments', // Organization Attachments Page
  '/crm/organizations/:organizationId/clients', // Organization Clients Page
  '/crm/organizations/:organizationId/cases', // Organization Cases Page
  '/crm', // Contacts/Admissions Landing Page
  '/records/occupancy', // Bed Board Occupancy Page
  '/user', // User Landing Page
  '/user/profile', // UserProfilePage
  '/set-password', // SetPasswordPage
  '/lead-application', // PublicLeadApplication
];

const MobileDeviceProvider: React.FC<{}> = (props: React.PropsWithChildren<{}>): JSX.Element => {
  const location = useLocation();

  const [isMobile, setIsMobile] = useState<boolean>(false);
  const [path, setPath] = useState<string>(location.pathname);
  const [showRefreshPageFlyout, setShowRefreshPageFlyout] = useState<boolean>(false);
  const [hasDismissedRefreshPageFlyout, setHasDismissedRefreshPageFlyout] =
    useState<boolean>(false);

  useEffect(() => {
    setPath(location.pathname);
  }, [location]);

  useEffect(() => {
    if (isMobile) {
      const isMobileFriendly = MOBILE_FRIENDLY_PATH_PATTERNS.some((pattern) => {
        const match = matchPath(path, {
          path: pattern,
          exact: true,
        });
        return match !== null;
      });

      // if new url isn't mobile friendly and user hasn't already dismissed the flyout
      if (!isMobileFriendly && !hasDismissedRefreshPageFlyout) {
        // show banner to suggest desktop mode by reloading and removing viewport meta tag
        setShowRefreshPageFlyout(true);
      }
    }
  }, [path]);

  useEffect(() => {
    window.addEventListener('resize', checkIfViewportMatchesMobileDevice);
    return () => window.removeEventListener('resize', checkIfViewportMatchesMobileDevice);
  }, []);

  /**
   * Use the `window.matchMedia` function to determine whether the width
   * of the client's device is within the bounds of the xsmall breakpoint
   */
  const checkIfViewportMatchesMobileDevice = () => {
    const matchMedia = window.matchMedia(
      `(max-width: ${APP_THEME.global.breakpoints.xsmall.value}px)`,
    );
    const isMobileDevice = matchMedia.matches;
    setIsMobile(isMobileDevice);
  };

  const dismissRefreshPageFlyout = () => {
    setHasDismissedRefreshPageFlyout(true);
    setShowRefreshPageFlyout(false);
  };

  return (
    <MobileDeviceContext.Provider
      value={{
        isMobile,
        checkIfViewportMatchesMobileDevice,
        showRefreshPageFlyout,
        dismissRefreshPageFlyout,
      }}
    >
      {props.children}
    </MobileDeviceContext.Provider>
  );
};

export default MobileDeviceProvider;
