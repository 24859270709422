import { parseISODateStrings } from 'utils';
import RittenClient from './RittenClient';

export default class FiltersClient extends RittenClient {
  getEncounterFilters = async (
    params: Filters.EncounterFiltersQuery,
  ): Promise<Filters.EncounterFilters[]> => {
    const res = await this.get<Filters.EncounterFilters[]>(`/api/filters/encounters`, { params });
    return parseISODateStrings(res.data);
  };

  postEncounterFilters = async (
    params: Filters.EncounterFilters,
  ): Promise<Filters.EncounterFilters> => {
    const res = await this.post<Filters.EncounterFilters>(`/api/filters/encounters`, params);
    return parseISODateStrings(res.data);
  };

  deleteEncounterFilters = async (id: string) => {
    await this.delete<void>(`/api/filters/encounters/${id}`);
  };

  getFormsFilters = async (params: Filters.FormFiltersQuery): Promise<Filters.FormFilters[]> => {
    const res = await this.get<Filters.FormFilters[]>(`/api/filters/forms`, { params });
    return parseISODateStrings(res.data);
  };

  postFormFilters = async (params: Filters.FormFilters): Promise<Filters.FormFilters> => {
    const res = await this.post<Filters.FormFilters>(`/api/filters/forms`, params);
    return parseISODateStrings(res.data);
  };

  deleteFormFilters = async (id: string) => {
    await this.delete<void>(`/api/filters/forms/${id}`);
  };

  getOrdersFilters = async (params: Filters.OrderFiltersQuery): Promise<Filters.OrderFilters[]> => {
    const res = await this.get<Filters.OrderFilters[]>(`/api/filters/orders`, { params });
    return parseISODateStrings(res.data);
  };

  postOrderFilters = async (params: Filters.OrderFilters): Promise<Filters.OrderFilters> => {
    const res = await this.post<Filters.OrderFilters>(`/api/filters/orders`, params);
    return parseISODateStrings(res.data);
  };

  deleteOrderFilters = async (id: string) => {
    await this.delete<void>(`/api/filters/orders/${id}`);
  };
}
