import { Box } from 'grommet';

import Dropdown from '@ritten/ui-library/dropdowns/Dropdown';
import { TextInputComponent } from '@ritten/ui-library/form-inputs';
import CheckboxGroupComponent from '@ritten/ui-library/form-inputs/CheckboxGroupComponent';
import DateInputComponent from '@ritten/ui-library/form-inputs/DateInputComponent';
import PhoneNumberInputComponent from '@ritten/ui-library/form-inputs/PhoneNumberComponent';
import RadioButtonGroupComponent from '@ritten/ui-library/form-inputs/RadioButtonGroupComponent';
import { TextAreaComponent } from '@ritten/ui-library/form-inputs/TextAreaComponent';

import useMobileDevice from 'context/mobileDevice/useMobileDevice';
import LeadApplicationCustomFieldConfiguration from 'features/admin/crm/lead-application/LeadApplicationCustomFieldConfiguration';
import {
  LEAD_APP_INSURED_DIFFERENT_SUBSCRIBER_FIELDS,
  LeadAppMode,
  SKIP_REQUIREMENT_MARKER_FIELDS,
} from './constants';
import LeadApplicationInsuranceCard from './LeadApplicationInsuranceCard';
import { getErrorKey } from './utils';

const CUSTOM_RENDERED_FIELD_LABELS: CRM.LeadAppFieldLabel[] = [
  'lead_app_phone',
  'lead_app_payer_insurance_card_front',
  'lead_app_payer_insurance_card_back',
];

interface LeadApplicationRendererProps {
  section: CRM.LeadAppSection;
  mode: LeadAppMode;
  updateCustomSectionField?: (section: CRM.LeadAppSection, field: CRM.LeadAppField) => void;
  updateLeadAppFieldValue?: (
    section: CRM.LeadAppSection,
    field: CRM.LeadAppField,
    value?: any,
  ) => void;
  validationErrors?: Record<CRM.LeadAppFieldLabel, string[]>;
  leadAppInstanceId?: string;
  token?: string;
}

const LeadApplicationRenderer = (props: LeadApplicationRendererProps) => {
  const {
    section,
    mode,
    updateCustomSectionField,
    updateLeadAppFieldValue,
    validationErrors,
    leadAppInstanceId,
    token,
  } = props;

  const { isMobile } = useMobileDevice();

  const sectionBasedRequirementMarker = section.isRequired ? 'Required' : undefined;
  const isBuilder = mode === 'builder';
  const isCaseView = mode === 'case';
  const isDisabled = isBuilder || isCaseView;
  const textInputComponentProps = {
    disabled: isDisabled,
    placeholder: isBuilder ? 'placeholder' : '',
    required: section.isRequired,
  };
  const inputWidth = (() => {
    if (isBuilder) {
      return '315px';
    }
    if (isMobile) {
      return '100%';
    }
    return '230px';
  })();

  const renderFieldByType = (field: CRM.LeadAppField) => {
    const hideRequirementMarker = SKIP_REQUIREMENT_MARKER_FIELDS.includes(field.label);
    const fieldRequirementMarker = hideRequirementMarker
      ? undefined
      : sectionBasedRequirementMarker;
    const isCustomSectionField = field.label === 'lead_app_custom';
    const validationErrorKey = getErrorKey(section, field);

    switch (field.type) {
      case 'text':
        return (
          <TextInputComponent
            textInputProps={{
              ...textInputComponentProps,
              required: section.isRequired,
              onChange: (e) => {
                updateLeadAppFieldValue?.(section, field, e.target.value);
              },
              value: field.value,
            }}
            width={isCustomSectionField ? '100%' : inputWidth}
            label={field.meta.promptText}
            requirementMarker={fieldRequirementMarker}
            error={validationErrors?.[validationErrorKey]?.[0]}
          />
        );
      case 'text_paragraph':
        return (
          <TextAreaComponent
            label={field.meta.promptText}
            initialHeight="111px"
            textAreaProps={{
              value: field.value || '',
              resize: true,
              onChange: (e) => {
                updateLeadAppFieldValue?.(section, field, e.target.value);
              },
              ...textInputComponentProps,
            }}
            width="100%" // currently only ever a custom section field so always full width
            requirementMarker={fieldRequirementMarker}
            error={validationErrors?.[validationErrorKey]?.[0]}
          />
        );
      case 'date':
        return (
          <DateInputComponent
            label={field.meta.promptText}
            dateInputProps={{
              disabled: isDisabled,
              format: 'mm/dd/yyyy',
            }}
            requirementMarker={fieldRequirementMarker}
            dateValue={field.value}
            onDateChange={(value) => {
              updateLeadAppFieldValue?.(section, field, value);
            }}
            error={validationErrors?.[validationErrorKey]?.[0]}
          />
        );
      case 'single_choice_dropdown':
        return (
          <Dropdown
            dropdownLabel={field.meta.promptText}
            choices={field.meta.choices ?? []}
            variant="single-choice"
            onSelect={(value) => updateLeadAppFieldValue?.(section, field, value)}
            disabled={isDisabled}
            singleChoiceProps={{ showClearSelection: true }}
            customizationProps={{
              anchorWidth: inputWidth,
              requirementMarker: fieldRequirementMarker,
            }}
            singleChoiceSelection={field.value}
            errorMessage={validationErrors?.[validationErrorKey]?.[0]}
          />
        );
      case 'single_choice_radio':
        return (
          <RadioButtonGroupComponent
            label={field.meta.promptText}
            radioButtonGroupProps={{
              id: section.title,
              name: section.title,
              options: field.meta.choices ?? [],
              value: field.value,
              onChange: (e) => updateLeadAppFieldValue?.(section, field, e.target.value),
              disabled: isDisabled,
            }}
            requirementMarker={fieldRequirementMarker}
            error={validationErrors?.[validationErrorKey]?.[0]}
          />
        );
      case 'multi_choice_checkbox':
        return (
          <CheckboxGroupComponent
            label={field.meta.promptText}
            checkboxGroupProps={{
              options: field.meta.choices ?? [],
              value: field.value,
              onChange: (event) => updateLeadAppFieldValue?.(section, field, event?.value),
              disabled: isDisabled,
            }}
            requirementMarker={fieldRequirementMarker}
            error={validationErrors?.[validationErrorKey]?.[0]}
          />
        );
      default:
        return <></>;
    }
  };

  const renderCustomFieldByLabel = (field: CRM.LeadAppField) => {
    const hideRequirementMarker = SKIP_REQUIREMENT_MARKER_FIELDS.includes(field.label);
    const fieldRequirementMarker = hideRequirementMarker
      ? undefined
      : sectionBasedRequirementMarker;
    const validationErrorKey = getErrorKey(section, field);

    switch (field.label) {
      case 'lead_app_phone':
        return (
          <PhoneNumberInputComponent
            label={field.meta.promptText}
            value={field.value}
            preferredCountries={[]}
            onChange={(value) => updateLeadAppFieldValue?.(section, field, value)}
            disabled={isDisabled}
            requirementMarker={fieldRequirementMarker}
            width={inputWidth}
            error={validationErrors?.[validationErrorKey]?.[0]}
          />
        );
      case 'lead_app_payer_insurance_card_front':
      case 'lead_app_payer_insurance_card_back':
        return (
          <LeadApplicationInsuranceCard
            field={field}
            key={field.meta.promptText}
            mode={mode}
            isRequired={section.isRequired}
            updateField={(value) => updateLeadAppFieldValue?.(section, field, value)}
            error={validationErrors?.[validationErrorKey]?.[0]}
            leadAppInstanceId={leadAppInstanceId}
            token={token}
          />
        );
      case 'lead_app_custom':
        return (
          <LeadApplicationCustomFieldConfiguration
            field={field}
            updateField={(field) => updateCustomSectionField?.(section, field)}
          />
        );
      default:
        return <></>;
    }
  };

  const renderSectionFields = () =>
    section.fields.map((field, index) => {
      if (
        LEAD_APP_INSURED_DIFFERENT_SUBSCRIBER_FIELDS.includes(field.label) &&
        section.fields.find((f) => f.label === 'lead_app_insured_different_than_client')
          ?.value?.[0] !== 'Yes'
      ) {
        // Do not render any of the LEAD_APP_INSURED_DIFFERENT_SUBSCRIBER_FIELDS if the checkbox indicating that the insured is different than the client is not checked
        return null;
      }

      // if field has a custom rendering, use that
      if (
        CUSTOM_RENDERED_FIELD_LABELS.includes(field.label) ||
        (isBuilder && field.label === 'lead_app_custom')
      ) {
        const fullWidth =
          isBuilder ||
          isMobile ||
          field.label === 'lead_app_payer_insurance_card_back' ||
          field.label === 'lead_app_payer_insurance_card_front';

        return (
          <div key={index} {...(fullWidth && { style: { width: '100%' } })}>
            {renderCustomFieldByLabel(field)}
          </div>
        );
      }
      // otherwise use the field type
      return (
        <div key={index} {...(isMobile && { style: { width: '100%' } })}>
          {renderFieldByType(field)}
        </div>
      );
    });

  if (isBuilder) {
    return (
      <Box direction="row" wrap style={{ rowGap: '10px', columnGap: '8px' }} width="100%">
        {renderSectionFields()}
      </Box>
    );
  }

  return <>{renderSectionFields()}</>;
};

export default LeadApplicationRenderer;
