// @ts-strict-ignore
import React, {
  ForwardedRef,
  forwardRef,
  KeyboardEvent,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from 'react';
import { HiOutlineLightningBolt } from 'react-icons/hi';
import { PiMagicWand } from 'react-icons/pi';
import { Box, Text, TextArea, TextAreaExtendedProps, ThemeContext } from 'grommet';
import { grommet } from 'grommet/themes';
import { deepMerge, WidthType } from 'grommet/utils';
import { cloneDeep } from 'lodash';

import { FeatureFlagLabel } from 'common/feature-flags/featureFlags';
import useAuth0User from 'context/auth0/useAuth0User';
import useFeatureFlags from 'context/featureFlags/useFeatureFlags';
import AnswerLibraryFlyout, {
  AnswerLibraryContext,
} from 'features/answer-library/AnswerLibraryFlyout';
import AIGenerationModal from 'features/chart-intelligence/AIGenerationModal';
import { COLORS } from 'styles/colors';
import { insertStringAtPosition } from '../../features/answer-library/utils';
import { StandardButton } from '../buttons';
import ErrorLabel from '../labels/ErrorLabel';
import InputLabel from './InputLabel';
import { useInputTheme } from './useInputTheme';

export interface TextAreaComponentProps {
  label?: string;
  initialHeight?: string;
  requirementMarker?: string;
  'data-cy'?: string;
  textAreaProps: TextAreaExtendedProps & {
    required?: boolean;
    disabled?: boolean;
    onChange?: (
      e: React.ChangeEvent<HTMLTextAreaElement> | React.ChangeEvent<HTMLInputElement>,
    ) => void;
    onBlur?: (
      e: React.ChangeEvent<HTMLTextAreaElement> | React.ChangeEvent<HTMLInputElement>,
    ) => void;
    onKeyDown?: (e: KeyboardEvent<HTMLTextAreaElement>) => void;
    onFocus?: (isFocused: boolean) => void;
    style?: React.CSSProperties;
  };
  error?: string;
  width?: WidthType;
  flex?: 'shrink' | 'grow' | boolean;
  fontSize?: string;
  inputLabel?: React.ReactNode;
  'data-testid'?: string;
  // THESE PROPS SHOULD ONLY BE USED WITH THE TextAreaComponentWrapper:
  onCursorPositionChange?: (pos: number) => void;
  answerLibraryContext?: AnswerLibraryContext;
  chartIntelligenceContext?: { formFieldConfigs: Clinic.ChartIntelligenceFormFieldConfig[] };
  improveTextEnabled?: boolean;
}

/**
 * The TextAreaComponentWrapper should be utilized in place of the TextAreaComponent in situations where
 * the additional integrated features (such as Answer Library and Improve Text) should potentially be enabled
 */
const TextAreaComponentWrapper = forwardRef(
  (props: TextAreaComponentProps, ref: ForwardedRef<HTMLTextAreaElement | null>) => {
    const { improveTextEnabled, chartIntelligenceContext, answerLibraryContext } = props;

    // Load in the hooks and calculate the necessary values needed for the potential rendering of the integrated features
    const { isPatient } = useAuth0User();
    const { isFlagOn } = useFeatureFlags();

    const isChartIntelligenceFlagOn = isFlagOn(FeatureFlagLabel.CHART_INTELLIGENCE);
    const chartIntelligenceContextOverride = isChartIntelligenceFlagOn
      ? chartIntelligenceContext
      : undefined;
    const isTemplatedTextFlagOn = isFlagOn(FeatureFlagLabel.TEMPLATED_TEXT);
    const isAnswerLibraryEnabled = isTemplatedTextFlagOn && !isPatient;
    const answerLibraryContextOverride = isAnswerLibraryEnabled ? answerLibraryContext : undefined;
    const isImproveTextEnabled =
      isFlagOn(FeatureFlagLabel.IMPROVE_TEXT) &&
      isTemplatedTextFlagOn &&
      !isPatient &&
      (improveTextEnabled || answerLibraryContextOverride !== undefined);

    return (
      <TextAreaComponent
        {...props}
        ref={ref}
        // overriding props with calculated values considering the feature flags and user type
        improveTextEnabled={isImproveTextEnabled}
        chartIntelligenceContext={chartIntelligenceContextOverride}
        answerLibraryContext={answerLibraryContextOverride}
      />
    );
  },
);

/**
 * @deprecated Deprecated in favor of TextAreaComponentWrapper
 * Utilize the TextAreaComponentWrapper instead EXCEPT in situations where the integrated features should NEVER be enabled
 */
export const TextAreaComponent = forwardRef(
  (props: TextAreaComponentProps, ref: ForwardedRef<HTMLTextAreaElement | null>) => {
    const { chartIntelligenceContext, initialHeight = '40px', improveTextEnabled } = props;

    const { onFocus: onFocusStyle, focusStyle } = useInputTheme();

    const [height, setHeight] = useState<string>(initialHeight);
    const [text, setText] = useState<string>(props.textAreaProps.value || '');
    const textAreaRef = useRef<HTMLTextAreaElement>();
    useImperativeHandle(ref, () => textAreaRef.current, [ref]);

    // Answer Library
    const answerLibraryEnabled = props.answerLibraryContext !== undefined;
    const [showLibraryIcon, setShowLibraryIcon] = useState<boolean>(false);
    const [libraryOpen, setLibraryOpen] = useState<boolean>(false);
    const [cursorPosition, setCursorPosition] = useState<number | null>(null);

    // Chart Intelligence
    const [chartIntelligenceOpen, setChartIntelligenceOpen] = useState<boolean>(false);
    const [chartIntelligenceType, setChartIntelligenceType] =
      useState<Clinic.ChartIntelligenceType>(
        'ImproveTextGeneric', // just use this as a default but doesn't really matter
      );

    const themeTextArea = deepMerge(grommet, {
      textArea: {
        extend: {
          backgroundColor: props.textAreaProps.disabled ? COLORS.darkGray200 : COLORS.white,
          fontSize: props.fontSize ?? '16px',
          fontWeight: 400,
          height,
          minHeight: initialHeight,
          borderRadius: '4px',
          resize: props.textAreaProps.resize ?? 'both',
          width: props.width,
          padding: '10px',
        },
        disabled: {
          opacity: 1,
        },
      },
    });

    // Handles autoexpanding
    useEffect(() => {
      if (textAreaRef.current) {
        setHeight(`${textAreaRef.current.scrollHeight}px`);
      }
    }, [text, props.textAreaProps.value]);

    const onFocus = (isFocused: boolean): void => {
      onFocusStyle(isFocused);
      props.textAreaProps.onFocus?.(isFocused);
    };

    const onChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
      setHeight('auto');
      setText(e.target.value);
      props.textAreaProps.onChange?.(e);
    };

    // ///////////////////
    // Answer Library
    // ///////////////////
    const libraryButtonMargin = (): string => {
      // if answer library is enabled and improve text ff is on
      if (answerLibraryEnabled && improveTextEnabled) {
        return '-110px';
      }
      // if answer library is enabled and improve text ff is off
      if (answerLibraryEnabled && !improveTextEnabled) {
        return '-35px';
      }
      // if answer library is disabled and improve text is enabled
      if (!answerLibraryEnabled && improveTextEnabled) {
        return '-80px';
      }
      return '0px';
    };

    const onCursorPositionChange = (pos: number) => {
      setCursorPosition(pos);
      props.onCursorPositionChange?.(pos);
    };

    const onLibraryButtonClick = () => {
      setLibraryOpen(true);
    };

    const addValueIntoTextArea = (value: string, replace?: boolean) => {
      const target = cloneDeep(textAreaRef.current);
      if (replace) {
        target.value = value;
      } else {
        target.value = insertStringAtPosition(text, value, cursorPosition);
      }
      onChange({
        target,
      } as React.ChangeEvent<HTMLTextAreaElement>);
    };

    const onLibraryClose = () => {
      setLibraryOpen(false);
      onFocus(false);
      setShowLibraryIcon(false);
    };

    // ///////////////////
    // Chart Intelligence
    // ///////////////////
    const onImproveTextButtonClick = () => {
      setChartIntelligenceType('ImproveTextGeneric');
      setChartIntelligenceOpen(true);
    };
    const onChartIntelligenceClose = () => {
      setChartIntelligenceOpen(false);
      onFocus(false);
      setShowLibraryIcon(false);
    };

    return (
      <>
        <ThemeContext.Extend value={themeTextArea}>
          <Box flex={props.flex ?? 'grow'}>
            {props.inputLabel ? (
              props.inputLabel
            ) : (
              <InputLabel label={props.label} requirementMarker={props.requirementMarker} />
            )}
            <Box direction="row" align="end">
              <TextArea
                ref={textAreaRef}
                rows={1}
                data-cy={props['data-cy']}
                data-testid={props['data-testid']}
                {...props.textAreaProps}
                onChange={onChange}
                aria-label={props.label || ''}
                onFocus={() => {
                  setShowLibraryIcon(true);
                  onFocus(true);
                }}
                onBlur={(e) => {
                  if (e.relatedTarget?.id === 'library-button') {
                    onLibraryButtonClick();
                  } else if (e.relatedTarget?.id === 'improve-text-button') {
                    onImproveTextButtonClick();
                  } else {
                    onFocus(false);
                    setShowLibraryIcon(false);
                  }
                }}
                onKeyUp={(e) => {
                  onCursorPositionChange(
                    // eslint-disable-next-line
                    e.target['selectionStart'] as number,
                  );
                }}
                onClick={(e) => {
                  onCursorPositionChange(
                    // eslint-disable-next-line
                    e.target['selectionStart'] as number,
                  );
                }}
                style={{
                  ...focusStyle,
                  ...(props.textAreaProps.style || {}),
                }}
              />
              {/* IMPORTANT: answer library and improve text will not be enabled if user type 
              is a patient user (portal) or the required feature flags are not turned on */}
              {(answerLibraryEnabled || improveTextEnabled) && showLibraryIcon && (
                <Box
                  direction="row"
                  gap="6px"
                  style={{
                    position: 'relative',
                    bottom: '10px',
                    marginLeft: libraryButtonMargin(),
                  }}
                >
                  {/* the Improve button always shows in conjunction with the answer library as long as the Improve Text FF is on; 
                    it will also show if the Improve functionality alone has been enabled and the FF is on */}
                  {improveTextEnabled && (
                    <Box
                      id="improve-text-button"
                      tabIndex={-1}
                      style={{
                        background: COLORS.darkGray200,
                        borderRadius: '4px',
                        cursor: 'pointer',
                      }}
                      flex="grow"
                      justify="center"
                      pad={{
                        vertical: '2px',
                        horizontal: '6px',
                      }}
                      direction="row"
                      gap="4px"
                      align="center"
                      // DO NOT REMOVE THIS ONCLICK HANDLER
                      onClick={() => {}}
                    >
                      <PiMagicWand color={COLORS.purple400} size="12px" />
                      <Text size="11px" color={COLORS.purple400}>
                        Improve
                      </Text>
                    </Box>
                  )}

                  {answerLibraryEnabled && (
                    <Box
                      id="library-button"
                      tabIndex={-1}
                      style={{
                        background: COLORS.darkGray200,
                        padding: '4px',
                        borderRadius: '4px',
                        cursor: 'pointer',
                      }}
                      flex="grow"
                      // DO NOT REMOVE THIS ONCLICK HANDLER
                      onClick={() => {}}
                    >
                      <HiOutlineLightningBolt
                        style={{
                          color: COLORS.rittenBlue400,
                        }}
                      />
                    </Box>
                  )}
                </Box>
              )}
            </Box>
            {/* IMPORTANT: chartIntelligenceContext will be undefined if Chart Intelligence FF is not turned on */}
            {chartIntelligenceContext &&
              chartIntelligenceContext.formFieldConfigs.length > 0 &&
              !props.textAreaProps.disabled && (
                <Box
                  direction="row"
                  justify="end"
                  pad={{
                    top: '12px',
                  }}
                >
                  <StandardButton
                    icon={<PiMagicWand color={COLORS.purple400} />}
                    label="Generate Answer"
                    styleVariant="outline"
                    color="purple"
                    sizeVariant="small"
                    onClick={() => {
                      setChartIntelligenceType(
                        chartIntelligenceContext.formFieldConfigs[0].chartIntelType,
                      );
                      setChartIntelligenceOpen(true);
                    }}
                  />
                </Box>
              )}
            {props.error && (
              <Box pad={{ top: 'xxsmall' }}>
                <ErrorLabel>{props.error}</ErrorLabel>
              </Box>
            )}
          </Box>
        </ThemeContext.Extend>
        {libraryOpen && (
          <AnswerLibraryFlyout
            onClose={() => onLibraryClose()}
            onSelect={(answer: string) => {
              addValueIntoTextArea(answer);
              onLibraryClose();
            }}
            context={props.answerLibraryContext}
          />
        )}
        {chartIntelligenceOpen && (
          <AIGenerationModal
            onCancel={() => {
              onChartIntelligenceClose();
            }}
            onSubmit={(value: string) => {
              addValueIntoTextArea(value, true);
              onChartIntelligenceClose();
            }}
            existingValue={text || ''}
            formId={props.answerLibraryContext && props.answerLibraryContext.formId}
            chartIntelligenceType={chartIntelligenceType}
          />
        )}
      </>
    );
  },
);

export default TextAreaComponentWrapper;
