import { useFormContext } from 'react-hook-form';
import { TextInputProps } from 'grommet';

import { TextInputComponent, TextInputComponentProps } from '@ritten/ui-library/form-inputs';

type RHFTextInputProps = TextInputComponentProps & {
  textInputProps: TextInputProps & {
    name: string;
  };
  hideErrorMessage?: boolean;
  isUpperCase?: boolean;
};

export const RHFTextInput = (props: RHFTextInputProps) => {
  const { textInputProps, isUpperCase = false, ...rest } = props;
  const { name } = textInputProps;
  const { register, getFieldState, formState } = useFormContext();
  const { error } = getFieldState(name, formState);

  return (
    <TextInputComponent
      textInputProps={{
        ...textInputProps,
        ...register(name),
        ...(isUpperCase ? { style: { textTransform: 'uppercase' } } : {}),
      }}
      error={error?.message}
      {...rest}
    />
  );
};

export default RHFTextInput;
