import { useEffect, useState } from 'react';
import { Route, Switch, useParams, useRouteMatch } from 'react-router-dom';
import { Box } from 'grommet';

import { FeatureFlagLabel } from 'common/feature-flags/featureFlags';
import useFeatureFlags from 'context/featureFlags/useFeatureFlags';
import useTenant from 'context/tenant/useTenant';
import useUser from 'context/user/useUser';
import { COLORS } from 'styles/colors';
import { lazyWithRetry } from 'utils/lazyLoadUtils';
import PortalSubNav from './nav/PortalSubNav';
import WelcomeHeader from './WelcomeHeader';

// lazy loading
const PortalAgendaPage = lazyWithRetry(() => import('./PortalAgendaPage'));
const PortalFormsPage = lazyWithRetry(() => import('./PortalFormsPage'));
const PortalAttachmentsPage = lazyWithRetry(() => import('./PortalAttachmentsPage'));
const PortalPaymentMethodsPage = lazyWithRetry(() => import('./PortalPaymentMethodsPage'));

const WELCOME_HEADER_ID = 'client-id-page-welcome-header';

interface PortalClientIdPageProps {
  accessibleContacts: Users.PortalAuthorization[];
}

const PortalClientIdPage = (props: PortalClientIdPageProps) => {
  const { accessibleContacts } = props;

  const { clientId } = useParams<UIRoutes.PortalClientIdParams>();
  const match = useRouteMatch();
  const { isFlagOn } = useFeatureFlags();
  const { tenant } = useTenant();
  const { user } = useUser();

  const [userIsClientOrGuarantor, setUserIsClientOrGuarantor] = useState<boolean>(false);

  const selectedClientIsUser = clientId === user.patientId;
  const isStripeEnabled = tenant.stripeIntegrationEnabled;
  const showPaymentMethods = isStripeEnabled && userIsClientOrGuarantor;

  useEffect(() => {
    if (selectedClientIsUser) {
      setUserIsClientOrGuarantor(true);
    } else {
      const contactWithAuth = accessibleContacts.find(
        (contact) => contact.patient.patientId === clientId,
      );
      if (contactWithAuth) {
        setUserIsClientOrGuarantor(contactWithAuth.canAddPaymentMethods);
      }
    }
  }, [clientId]);

  return (
    <Box
      fill="horizontal"
      height={{ min: '100%' }}
      background={COLORS.darkGray200}
      align="center"
      gap="24px"
      pad={{ bottom: 'large' }}
    >
      <>
        <WelcomeHeader>
          <div id={WELCOME_HEADER_ID}></div>
        </WelcomeHeader>
        <Box
          border={{
            size: '1px',
            side: 'top',
            style: 'solid',
            color: COLORS.darkGray200,
          }}
          width="100%"
          align="center"
          background={COLORS.white}
        >
          <PortalSubNav showPaymentMethods={showPaymentMethods} />
        </Box>
      </>
      <Switch>
        {isFlagOn(FeatureFlagLabel.CLIENT_PORTAL_AGENDA) && (
          <Route exact path={`${match.path}/agenda`} component={PortalAgendaPage} />
        )}
        <Route exact path={`${match.path}/forms`}>
          <PortalFormsPage welcomeHeaderElementId={WELCOME_HEADER_ID} key={clientId} />
        </Route>
        <Route exact path={`${match.path}/attachments`}>
          <PortalAttachmentsPage welcomeHeaderElementId={WELCOME_HEADER_ID} />
        </Route>
        {showPaymentMethods && (
          <Route exact path={`${match.path}/payment-methods`}>
            <PortalPaymentMethodsPage welcomeHeaderElementId={WELCOME_HEADER_ID} />
          </Route>
        )}
      </Switch>
    </Box>
  );
};

export default PortalClientIdPage;
