import { useAuth0 } from '@auth0/auth0-react';
import { Box, Heading, Text } from 'grommet';

import { StandardButton } from '@ritten/ui-library/buttons';

import { COLORS } from 'styles/colors';
import { ReactComponent as RittenLogo } from '../assets/ritten_full_color_logo.svg';

const UnauthorizedPage = () => {
  const { logout } = useAuth0();

  const goToLogin = () => {
    logout({
      returnTo: window.location.origin,
    });
  };

  return (
    <Box background={COLORS.darkGray200} fill align="center" pad={{ vertical: 'large' }}>
      <Box
        background={COLORS.white}
        align="center"
        justify="center"
        pad="medium"
        width={{
          max: '480px',
          width: '80%',
        }}
      >
        <RittenLogo height="40px" width="40px" />
        <Heading size="24px">Unauthorized</Heading>
        <Box gap="18px" align="center">
          <Text size="14px" color={COLORS.darkGray400}>
            Try logging back in
          </Text>
          <StandardButton label="Log in" onClick={goToLogin} />
        </Box>
      </Box>
    </Box>
  );
};

export default UnauthorizedPage;
