import { Box, Text } from 'grommet';

import UserInitials from 'common/UserInitials';
import { getFullName } from 'utils';
import useUser from '../../context/user/useUser';
import { COLORS } from '../../styles/colors';

interface UserProfileNavItemProps {
  hideName?: boolean;
  containerHeight?: string;
  initialsDimensions?: string;
}

const UserProfileNavItem = (props: UserProfileNavItemProps) => {
  const { hideName, containerHeight = '48px', initialsDimensions = '26px' } = props;
  const { user } = useUser();

  return (
    <Box
      height={containerHeight}
      direction="row"
      gap="10px"
      align="center"
      pad={{ horizontal: '10px' }}
      data-testid="user-profile-nav-button"
    >
      <UserInitials
        backgroundColor={COLORS.white}
        height={initialsDimensions}
        width={initialsDimensions}
        textColor={COLORS.darkGray600}
        textSize="12px"
        user={user}
      />
      {!hideName && (
        <Text size="small" weight="normal" data-testid="user-display-name" color={COLORS.white}>
          {getFullName(user)}
        </Text>
      )}
    </Box>
  );
};

export default UserProfileNavItem;
