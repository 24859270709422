import { createSlice } from '@reduxjs/toolkit';

import { RootState } from 'redux/store';

export interface FormState {
  recalcRequiredFields: number;
  formPatch: number;
  missingRequiredFields: Forms.FieldInstance[];
}

const initialState: FormState = {
  recalcRequiredFields: 0,
  formPatch: 0,
  missingRequiredFields: [],
};

export const formSlice = createSlice({
  name: 'form',
  initialState,
  reducers: {
    recalculateRequiredFields: (state) => {
      state.recalcRequiredFields += 1;
    },
    onFormPatch: (state) => {
      state.formPatch += 1;
    },
    updateMissingRequiredFields: (state, action) => {
      state.missingRequiredFields = action.payload;
    },
  },
});

export const { recalculateRequiredFields, onFormPatch, updateMissingRequiredFields } =
  formSlice.actions;

export const selectRecalcRequiredFields = (state: RootState) => state.form.recalcRequiredFields;
export const selectFormPatch = (state: RootState) => state.form.formPatch;
export const selectMissingRequiredFields = (state: RootState) => state.form.missingRequiredFields;

export default formSlice.reducer;
