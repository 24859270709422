import { COLORS, DROP_SHADOW } from './colors';

export const APP_THEME = {
  global: {
    colors: COLORS, // TODO replace
    font: {
      family: 'Circular',
      size: '18px',
      height: '20px',
    },
    breakpoints: {
      xsmall: {
        value: 767, // max for bootstrap small
      },
      small: {
        value: 991, // max for bootstrap medium
      },
      medium: {
        value: 1199, // max for bootstrap large
      },
      large: {
        value: 1407,
      },
    },
    drop: {
      border: {
        radius: '8px',
      },
      shadowSize: 'medium',
    },
    elevation: {
      light: {
        none: 'none',
        medium: DROP_SHADOW,
      },
      dark: {
        none: 'none',
        medium: DROP_SHADOW,
      },
    },
  },
  layer: {
    border: {
      radius: '8px',
    },
    container: {
      elevation: 'medium',
    },
    extend: `font-family: 'Circular'`,
  },
  button: {
    default: {
      color: COLORS.white,
      background: {
        color: 'brand',
      },
    },
    primary: {
      color: COLORS.white,
      background: {
        color: 'brand',
      },
    },
    secondary: {
      color: COLORS.white,
      background: {
        color: COLORS.rittenBlue400,
      },
      border: {
        color: COLORS.rittenBlue400,
        width: '1px',
        radius: '0px',
      },
    },
  },
  select: {
    control: {
      extend: `
      border-radius: 4px;
      `,
    },
  },
  input: {
    extend: `
    border-radius: 4px;
    `,
  },
};
