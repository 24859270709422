import { AxiosResponseHeaders } from 'axios';

import { getFileNameFromHeaders, parseISODateStrings, parseObjectDates } from 'utils';
import RittenClient from './RittenClient';

type CreateOrderResponse = {
  iframeUrl: string;
  order: Lab.Order;
};

export default class LabClient extends RittenClient {
  listOrders = async (params: Partial<Lab.ListOrdersParams>) => {
    const { data = [] } = await this.get<Lab.Order[]>(`api/labs/orders`, {
      params,
    });
    return parseISODateStrings(data) ?? [];
  };

  getOrderById = async (id: string) => {
    const data = await this.listOrders({ ids: [id], limit: 1 });
    return data[0] ?? null;
  };

  /**
   * Creates a new lab order tied to an encounter ID.
   */
  createOrder = async (encounterId: string): Promise<CreateOrderResponse> => {
    const { data } = await this.post<CreateOrderResponse>(`api/labs/orders`, {
      encounterId,
    });
    return {
      ...data,
      order: parseObjectDates(data.order, ['createdAt', 'deletedAt', 'acknowledgedAt']),
    };
  };

  cancelOrder = async (labOrderId: string): Promise<void> => {
    await this.delete<void>(`api/labs/orders/${labOrderId}`);
  };

  newOrderCallback = async (body: Lab.OrderCallbackPostBody) => {
    await this.post(`api/labs/orders/callback`, body);
  };

  acknowledgeResults = async (
    resultId: string,
    classification: Lab.ResultClassification,
    comment: string,
  ) => {
    await this.post(`api/labs/results/${resultId}/acknowledge`, {
      classification,
      comment,
    });
  };

  downloadRequisitionForm = async (labOrderId: string) => {
    const res = await this.get<Blob>(`api/labs/orders/${labOrderId}/requisition-form`, {
      responseType: 'blob',
    });
    const headers = res.headers as AxiosResponseHeaders;
    const url = window.URL.createObjectURL(res.data);
    const el = document.createElement('a');
    el.href = url;
    el.download = getFileNameFromHeaders(headers['content-disposition']);
    document.body.appendChild(el);
    el.click();
  };
}
