import Dropzone, { Accept, FileRejection } from 'react-dropzone';
import { ACCEPTED_FILE_TYPES_STRING } from 'AppConstants';
import { Box, Text } from 'grommet';
import { CloudUpload } from 'grommet-icons';
import _ from 'lodash';

import { COLORS } from '../../styles/colors';

interface FileDropProps {
  uploadPaneMessage: string;
  acceptedFileTypes: Accept;
  onFileUpload: (files: File[]) => void;
  onError: (errors: string[]) => void;
  maxFiles?: number;
  width?: string;
}

const FileDrop = (props: FileDropProps) => {
  const onDrop = (files: File[], rejections: FileRejection[]) => {
    if (rejections?.length > 0) {
      const errors = getUniqueErrorMessages(rejections);
      props.onError(errors);
      return;
    }

    props.onFileUpload(files);
  };

  const getUniqueErrorMessages = (rejections: FileRejection[]) => {
    const errors: string[] = [];
    rejections.forEach((r) => {
      r.errors.forEach((e) => {
        if (e.message.includes('File type must be')) {
          errors.push(
            `File type must be one of the following file types: ${ACCEPTED_FILE_TYPES_STRING}.`,
          );
        } else {
          errors.push(e.message);
        }
      });
    });
    return _.uniq(errors);
  };

  return (
    <Dropzone accept={props.acceptedFileTypes} onDrop={onDrop} maxFiles={props.maxFiles}>
      {({ getRootProps, getInputProps, isDragAccept }) => (
        <Box
          pad={{ horizontal: 'medium', vertical: 'large' }}
          border={{ side: 'all', color: COLORS.rittenBlue400, style: 'dashed' }}
          direction="column"
          align="center"
          style={isDragAccept ? { opacity: 0.5 } : { opacity: 1 }}
          width={props.width ? props.width : undefined}
          {...getRootProps()}
        >
          <input {...getInputProps()} />

          <Box align="center">
            <CloudUpload size="50px" color={COLORS.rittenBlue400} />
            <Text size="14px">Drag and Drop Here</Text>
            <Text size="14px">or</Text>
          </Box>

          <Box direction="row" align="center">
            <Text size="14px">{props.uploadPaneMessage}</Text>
          </Box>
        </Box>
      )}
    </Dropzone>
  );
};

export default FileDrop;
