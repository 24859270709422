import { PropsWithChildren } from 'react';
import { Box, Text } from 'grommet';

import { COLORS } from 'styles/colors';
import { ReactComponent as RittenLogo } from '../../assets/ritten_full_color_logo.svg';
import ContentBox from '../boxes/ContentBox';

interface EmptyStateInstructionDisplayProps {
  title: string;
  subTitle?: string;
  width?: string;
  'data-testid'?: string;
  icon?: JSX.Element;
  noShadowBox?: boolean;
}

export const EmptyStateInstructionCard: React.FC<
  PropsWithChildren<EmptyStateInstructionDisplayProps>
> = (props) => {
  const { title, subTitle, children, width = '440px', icon, noShadowBox = false } = props;

  const ContainerBox = noShadowBox ? Box : ContentBox;

  return (
    <ContainerBox
      style={{ maxWidth: width }}
      direction="column"
      pad={{ horizontal: '80px', vertical: '50px' }}
      justify="center"
      align="center"
      data-testid={props['data-testid']}
      flex={false}
    >
      {icon ?? <RittenLogo height="40px" width="40px" />}
      <Text
        size="24px"
        color={COLORS.darkGray600}
        weight={700}
        textAlign="center"
        margin={{ vertical: '16px' }}
      >
        {title}
      </Text>
      <Text size="14px" color={COLORS.darkGray400} textAlign="center" margin={{ bottom: '24px' }}>
        {subTitle}
      </Text>
      {children}
    </ContainerBox>
  );
};

export default EmptyStateInstructionCard;
