// Generated by ts-to-zod
import { z } from 'zod';

import { clinicFacilitySchema } from './clinic.zod';
import {
  patientAddressSchema,
  patientContactPointSchema,
  patientPatientNameSchema,
  patientPatientSchema,
} from './patient.zod';
import { remittancesServiceStatusSchema } from './remittances.zod';

export const claimsClaimTypeSchema = z.union([
  z.literal('institutional'),
  z.literal('professional'),
]);

export const claimsDraftClaimSchema = z.object({
  authorizationEntryId: z.string(),
  dailyBillableServiceIds: z.array(z.string()),
  patientId: z.string(),
  procedureCode: z.string(),
  programId: z.string(),
});

export const claimsDraftClaimsSchema = z.object({
  type: claimsClaimTypeSchema,
  claims: z.array(claimsDraftClaimSchema),
});

export const claimsClaimFormSchema = z.any();

export const claimsClaimStatusSchema = z.union([z.literal('Draft'), z.literal('Posted')]);

export const claimsClaimsResponseSchema = z.lazy(() =>
  z.object({
    id: z.string(),
    status: claimsClaimStatusSchema,
    type: claimsClaimTypeSchema,
    subtype: z.string(),
    startDate: z.string(),
    endDate: z.string(),
    totalCents: z.number(),
    patient: patientPatientSchema,
    facility: clinicFacilitySchema,
  }),
);

export const claimsListClaimsQuerySchema = z.object({
  startDate: z.string().nullable(),
  endDate: z.string().nullable(),
  patientIds: z.array(z.string()),
  limit: z.number(),
  offset: z.number(),
  status: z.array(claimsClaimStatusSchema).optional(),
});

export const claimsListClaimsResponseSchema = z.object({
  claims: z.array(claimsClaimsResponseSchema),
  totalCount: z.number(),
});

export const claimsCollabMdProviderMapSchema = z.object({
  id: z.string(),
  providerId: z.string(),
  collabMdIdentifier: z.string(),
});

export const claimsBillingIntegrationsConfigSchema = z.object({
  collabmdEnabled: z.boolean(),
});

export const claimsProviderTypeSchema = z.union([
  z.literal('attending'),
  z.literal('billing'),
  z.literal('referring'),
  z.literal('rendering'),
  z.literal('operating'),
  z.literal('supervising'),
  z.literal('ordering'),
  z.literal('other'),
]);

export const claimsProviderEntityTypeSchema = z.union([
  z.literal('non-person'),
  z.literal('person'),
]);

export const claimsCollabMdSettingsSchema = z.object({
  customerNumber: z.string(),
  providerMappings: z.array(claimsCollabMdProviderMapSchema),
});

export const claimsProviderSchema = z.object({
  id: z.string(),
  address: patientAddressSchema,
  claimInstanceId: z.string(),
  contactPoint: patientContactPointSchema,
  employerId: z.string(),
  firstName: z.string(),
  middleName: z.string(),
  lastName: z.string(),
  nationalProviderId: z.string(),
  organizationName: z.string(),
  taxonomyCode: z.string(),
  type: claimsProviderTypeSchema,
});

export const claimsPlaceOfServiceCodeSchema = z.object({
  code: z.string(),
  name: z.string(),
  description: z.string(),
});

export const claimsItemSchema = z.object({
  id: z.string(),
  sequence: z.number(),
  description: z.string(),
  revenueCode: z.string(),
  procedureCodeType: z.string(),
  procedureCode: z.string(),
  procedureCodeModifiers: z.string(),
  serviceStartDate: z.string(),
  serviceEndDate: z.string(),
  quantity: z.number(),
  unitPriceCents: z.number(),
  factor: z.number(),
  netCents: z.number(),
  nonCoveredChargeAmountCents: z.number(),
  placeOfServiceCode: claimsPlaceOfServiceCodeSchema.nullable(),
});

export const claimsX12Response = z.object({
  x12: z.string(),
});

export const claimsServiceActivitySchema = z.lazy(() =>
  z.object({
    date: z.string(),
    activity: z.string(),
    remittanceId: z.string(),
    billedAmountCents: z.number(),
    insurancePaidCents: z.number(),
    adjustmentsCents: z.number(),
    clientPaidCents: z.number(),
    clientWriteOffCents: z.number(),
  }),
);

export const claimsServiceItemSchema = z.lazy(() =>
  z.object({
    itemId: z.string(),
    startDate: z.string(),
    endDate: z.string(),
    cptCode: z.string(),
    activities: z.array(claimsServiceActivitySchema),
    status: remittancesServiceStatusSchema,
    totalBilledAmountCents: z.number(),
    totalInsurancePaidCents: z.number(),
    totalAdjustmentsCents: z.number(),
    totalClientPaidCents: z.number(),
    totalClientWriteOffCents: z.number(),
    totalPayerBalanceCents: z.number(),
    totalClientBalanceCents: z.number(),
  }),
);

export const claimsClaimPaymentSchema = z.lazy(() =>
  z.object({
    claimId: z.string(),
    patient: patientPatientNameSchema,
    startDate: z.string(),
    endDate: z.string(),
    status: claimsClaimStatusSchema,
    type: claimsClaimTypeSchema,
    totalBilledAmountCents: z.number(),
    totalInsurancePaidCents: z.number(),
    totalAdjustmentsCents: z.number(),
    totalClientPaidCents: z.number(),
    totalClientWriteOffCents: z.number(),
    totalPayerBalanceCents: z.number(),
    totalClientBalanceCents: z.number(),
    serviceItems: z.array(claimsServiceItemSchema),
  }),
);

export const claimsClaimHeaderSchema = z.lazy(() =>
  z.object({
    claimId: z.string(),
    identifier: z.string(),
    patient: patientPatientNameSchema,
    startDate: z.string(),
    endDate: z.string(),
    status: claimsClaimStatusSchema,
    type: claimsClaimTypeSchema,
    providerControlNumbers: z.array(z.string()),
  }),
);
