import { useEffect, useState } from 'react';
import { fromEvent } from 'rxjs';
import { startWith } from 'rxjs/operators';

interface WindowAttributes {
  height: number;
  width: number;
}

// Create one 'resize' event listener for our entire application.
// Multiple components can be subscribed to this event.
const windowResize$ = fromEvent(window, 'resize').pipe(startWith({ target: window }));

export const useWindowResize = () => {
  const [windowAttributes, setWindowAttributes] = useState<WindowAttributes>();

  useEffect(() => {
    const sub = windowResize$.subscribe((w) => {
      const window = w.target as Window;
      setWindowAttributes(() => {
        return {
          height: window.innerHeight,
          width: window.innerWidth,
        };
      });
    });

    return () => sub.unsubscribe();
  }, []);

  return {
    windowAttributes,
  };
};
