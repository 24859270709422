// This is a list of the section and field labels for
// PREMIUM and HYBRID roobis.
// Dull roobis are not included here because the frontend doesn't need their
// label values. (*except for the case where we want to block them in the form builder)
export const ROOBI_SECTIONS = {
  // Premium
  DEMOGRAPHIC_ADDRESS: {
    LABEL: 'roobi_demographic_address',
    FIELD_LABELS: {
      ADDRESSES: 'roobi_demographic_addresses',
    },
  },
  SUBSTANCE_USE_HISTORY: {
    LABEL: 'roobi_substance_use',
    FIELD_LABELS: {
      SUBSTANCE_USE_HISTORY: 'roobi_substance_use',
    },
  },
  CONTACT_POINTS: {
    LABEL: 'roobi_demographic_contact_point',
    FIELD_LABELS: {
      EMAIL: 'roobi_demographic_contact_point_email',
      PHONE: 'roobi_demographic_contact_point_phone',
    },
  },
  PATIENT_INSURANCE: {
    LABEL: 'roobi_patient_insurance',
    FIELD_LABELS: {
      PATIENT_INSURANCE: 'roobi_patient_insurance',
    },
  },
  CARE_TEAM: {
    LABEL: 'roobi_patient_care_team',
    FIELD_LABELS: {
      PATIENT_INSURANCE: 'roobi_patient_care_team',
    },
  },
  DEMOGRAPHICS: {
    LABEL: 'roobi_patient_demographics',
    FIELD_LABELS: {
      DEMOGRAPHICS: 'roobi_patient_demographics',
    },
  },
  NAME_DOB: {
    LABEL: 'roobi_name_dob',
    FIELD_LABELS: {
      NAME: 'roobi_name',
      DOB: 'roobi_dob',
      IS_GUARDIAN_REQUIRED: 'roobi_is_guardian_required',
    },
  },
  SSN: {
    LABEL: 'roobi_ssn',
    FIELD_LABELS: {
      SSN: 'roobi_ssn',
    },
  },
  MEDICATION: {
    LABEL: 'roobi_patient_medication',
    FIELD_LABELS: {
      MEDICATIONS: 'roobi_patient_medication',
    },
  },
  DIAGNOSIS: {
    LABEL: 'roobi_diagnosis',
    FIELD_LABELS: {
      DIAGNOSIS: 'roobi_diagnosis',
    },
  },
  CLIENT_CONTACTS: {
    LABEL: 'roobi_client_contact',
    FIELD_LABELS: {
      CLIENT_CONTACTS: 'roobi_client_contact',
      IS_GUARDIAN_REQUIRED: 'roobi_is_guardian_required',
    },
  },
  FAMILY_RELATIONSHIPS: {
    LABEL: 'roobi_patient_relationships',
    FIELD_LABELS: {
      FAMILY_RELATIONSHIPS: 'roobi_patient_relationships',
    },
  },
  RELEASE_OF_INFORMATION: {
    LABEL: 'roobi_release_of_information',
    FIELD_LABELS: {
      RELEASE_OF_INFORMATION: 'roobi_release_of_information',
    },
  },
  RELEASE_OF_INFORMATION_V2: {
    LABEL: 'roobi_release_of_information_v2',
    FIELD_LABELS: {
      RELEASE_OF_INFORMATION_V2: 'roobi_release_of_information_v2',
    },
  },
  FUNDING_SOURCE: {
    LABEL: 'roobi_funding_source',
    FIELD_LABELS: {
      FUNDING_SOURCE: 'roobi_funding_source',
    },
  },
  ALLERGIES: {
    LABEL: 'roobi_allergies',
    FIELD_LABELS: {
      ALLERGIES: 'roobi_allergies',
    },
  },
  ADMIT_TRANSFER_DISCHARGE: {
    LABEL: 'roobi_admit_transfer_discharge',
    FIELD_LABELS: {
      ADT: 'roobi_admit_transfer_discharge',
    },
  },
  ADMIT_DISCHARGE_NON_CLINICAL: {
    LABEL: 'roobi_nonclinical_services',
    FIELD_LABELS: {
      NON_CLINICAL: 'roobi_nonclinical_services',
    },
  },
  AUTHORIZATIONS: {
    LABEL: 'roobi_patient_authorization',
    FIELD_LABELS: {
      AUTHORIZATIONS: 'roobi_patient_authorization',
    },
  },
  PATIENT_REFERRAL: {
    LABEL: 'roobi_patient_referral',
    FIELD_LABELS: {
      PATIENT_REFERRAL: 'roobi_patient_referral',
    },
  },
  TREATMENT_PLAN: {
    LABEL: 'roobi_treatment_plan',
    FIELD_LABELS: {
      TREATMENT_PLAN: 'roobi_treatment_plan',
    },
  },
  TREATMENT_PLAN_REFERENCE: {
    LABEL: 'roobi_treatment_plan_reference',
    FIELD_LABELS: {
      TREATMENT_PLAN_REFERENCE: 'roobi_treatment_plan_reference',
    },
  },
  CLINIC_TEAM: {
    LABEL: 'roobi_clinic_teams',
    FIELD_LABELS: {
      CLINIC_TEAM: 'roobi_clinic_teams',
    },
  },
  WARNINGS: {
    LABEL: 'roobi_warnings',
    FIELD_LABELS: {
      WARNINGS: 'roobi_warnings',
    },
  },
  DIETARY_RESTRICTIONS: {
    LABEL: 'roobi_dietary_restrictions',
    FIELD_LABELS: {
      DIETARY_RESTRICTIONS: 'roobi_dietary_restrictions',
    },
  },
  DEMOGRAPHICS_BRIEF: {
    LABEL: 'roobi_patient_demographics_brief',
    FIELD_LABELS: {
      DEMOGRAPHICS: 'roobi_patient_demographics_brief',
    },
  },
  CIWA_PREMIUM: {
    LABEL: 'roobi_ciwa_premium',
    FIELD_LABELS: {
      CIWA_PREMIUM: 'roobi_ciwa_premium',
    },
  },
  COWS_PREMIUM: {
    LABEL: 'roobi_cows_premium',
    FIELD_LABELS: {
      COWS_PREMIUM: 'roobi_cows_premium',
    },
  },
  PAIN_SCALE_PREMIUM: {
    LABEL: 'roobi_pain_scale_premium',
    FIELD_LABELS: {
      PAIN_SCALE_PREMIUM: 'roobi_pain_scale_premium',
    },
  },
  // Hybrid
  ANXIETY_CAD7: {
    LABEL: 'roobi_anxiety_cad7',
    FIELD_LABELS: {
      Q1: 'roobi_anxiety_cad7_q1',
      Q2: 'roobi_anxiety_cad7_q2',
      QSCORE: 'roobi_anxiety_cad7_qscore',
    },
  },
  TREATMENT_HISTORY: {
    LABEL: 'roobi_treatment_history',
    FIELD_LABELS: {
      TREATMENT_HISTORY: 'roobi_treatment_history',
    },
  },
  BIOMETRICS_VITALS: {
    LABEL: 'roobi_vitals',
    FIELD_LABELS: {
      HEART_RATE: 'roobi_vitals_heart_rate',
      TEMPERATURE: 'roobi_vitals_temperature',
      OXYGEN_SATURATION: 'roobi_vitals_oxygen_saturation',
      BLOOD_PRESSURE: 'roobi_vitals_blood_pressure',
      RESPIRATION_RATE: 'roobi_vitals_respiration_rate',
    },
  },
  BIOMETRICS_BAC: {
    LABEL: 'roobi_blood_alcohol_concentration',
    FIELD_LABELS: {
      BAC: 'roobi_blood_alcohol_concentration',
    },
  },
  BIOMETRICS_HEIGHT_WEIGHT: {
    LABEL: 'roobi_height_weight',
    FIELD_LABELS: {
      HEIGHT: 'roobi_height',
      WEIGHT: 'roobi_weight',
    },
  },
  ANXIETY_GAD7: {
    LABEL: 'roobi_hybrid_gad7',
    FIELD_LABELS: {
      ANSWER_TYPE: 'scale',
      SUMMATION: 'roobi_hybrid_gad7_summation',
      QUESTIONS: {
        Q1: 'roobi_hybrid_gad7_q1',
        Q2: 'roobi_hybrid_gad7_q2',
        Q3: 'roobi_hybrid_gad7_q3',
        Q4: 'roobi_hybrid_gad7_q4',
        Q5: 'roobi_hybrid_gad7_q5',
        Q6: 'roobi_hybrid_gad7_q6',
        Q7: 'roobi_hybrid_gad7_q7',
      },
      DESCRIPTIVE_FIELDS: {
        HEADING: 'roobi_hybrid_gad7_heading',
        DESCRIPTION: 'roobi_hybrid_gad7_description',
        MEASUREMENT: 'roobi_hybrid_gad7_measurement',
        R_HEADING: 'roobi_hybrid_gad7_range_heading',
        R_DESCRIPTION: 'roobi_hybrid_gad7_range_description',
      },
    },
  },
  DEPRESSION_PHQ9: {
    LABEL: 'roobi_hybrid_phq9',
    FIELD_LABELS: {
      ANSWER_TYPE: 'scale',
      SUMMATION: 'roobi_hybrid_phq9_summation',
      QUESTIONS: {
        Q1: 'roobi_hybrid_phq9_q1',
        Q2: 'roobi_hybrid_phq9_q2',
        Q3: 'roobi_hybrid_phq9_q3',
        Q4: 'roobi_hybrid_phq9_q4',
        Q5: 'roobi_hybrid_phq9_q5',
        Q6: 'roobi_hybrid_phq9_q6',
        Q7: 'roobi_hybrid_phq9_q7',
        Q8: 'roobi_hybrid_phq9_q8',
        Q9: 'roobi_hybrid_phq9_q9',
      },
      DESCRIPTIVE_FIELDS: {
        HEADING: 'roobi_hybrid_phq9_heading',
        DESCRIPTION: 'roobi_hybrid_phq9_description',
        MEASUREMENT: 'roobi_hybrid_phq9_measurement',
        R_HEADING: 'roobi_hybrid_phq9_range_heading',
        R_DESCRIPTION: 'roobi_hybrid_phq9_range_description',
      },
    },
  },
  FAMILY_ASSESSMENT_DEVICE: {
    LABEL: 'roobi_hybrid_family_functioning',
    FIELD_LABELS: {
      ANSWER_TYPE: 'scale',
      SUMMATION: 'roobi_hybrid_family_functioning_summation',
      DESCRIPTIVE_FIELDS: {
        HEADING: 'roobi_hybrid_family_functioning_heading',
        INSTRUCTIONS: 'roobi_hybrid_family_functioning_instructions',
        MEASUREMENT: 'roobi_hybrid_family_functioning_measurement',
      },
      QUESTIONS: {
        Q1: 'roobi_hybrid_family_functioning_q1',
        Q2: 'roobi_hybrid_family_functioning_q2',
        Q3: 'roobi_hybrid_family_functioning_q3',
        Q4: 'roobi_hybrid_family_functioning_q4',
        Q5: 'roobi_hybrid_family_functioning_q5',
        Q6: 'roobi_hybrid_family_functioning_q6',
        Q7: 'roobi_hybrid_family_functioning_q7',
        Q8: 'roobi_hybrid_family_functioning_q8',
        Q9: 'roobi_hybrid_family_functioning_q9',
        Q10: 'roobi_hybrid_family_functioning_q10',
        Q11: 'roobi_hybrid_family_functioning_q11',
        Q12: 'roobi_hybrid_family_functioning_q12',
      },
    },
  },
  PEARLS_SCREENER_CHILD: {
    LABEL: 'roobi_hybrid_pearls_screener_child',
    FIELD_LABELS: {
      ANSWER_TYPE: 'radio',
      DESCRIPTIVE_FIELDS: {
        HEADING: 'roobi_hybrid_pearls_screener_child_heading',
        SUBHEADING_1: 'roobi_hybrid_pearls_screener_child_subheading_1',
        SUBHEADING_2: 'roobi_hybrid_pearls_screener_child_subheading_2',
        INSTRUCTIONS: 'roobi_hybrid_pearls_screener_child_instructions',
      },
      SUMMATION_FIELDS: {
        SUMMATION_PART_1: 'roobi_hybrid_pearls_screener_child_summation_p1',
        SUMMATION_PART_2: 'roobi_hybrid_pearls_screener_child_summation_p2',
      },
      COMPLETED_BY: 'roobi_hybrid_pearls_screener_child_completed_by',
      QUESTIONS: {
        Q1: 'roobi_hybrid_pearls_screener_child_p1_q1',
        Q2: 'roobi_hybrid_pearls_screener_child_p1_q2',
        Q3: 'roobi_hybrid_pearls_screener_child_p1_q3',
        Q4: 'roobi_hybrid_pearls_screener_child_p1_q4',
        Q5: 'roobi_hybrid_pearls_screener_child_p1_q5',
        Q6: 'roobi_hybrid_pearls_screener_child_p1_q6',
        Q7: 'roobi_hybrid_pearls_screener_child_p1_q7',
        Q8: 'roobi_hybrid_pearls_screener_child_p1_q8',
        Q9: 'roobi_hybrid_pearls_screener_child_p1_q9',
        Q10: 'roobi_hybrid_pearls_screener_child_p1_q10',
        // Begin part 2
        Q11: 'roobi_hybrid_pearls_screener_child_p2_q1',
        Q12: 'roobi_hybrid_pearls_screener_child_p2_q2',
        Q13: 'roobi_hybrid_pearls_screener_child_p2_q3',
        Q14: 'roobi_hybrid_pearls_screener_child_p2_q4',
        Q15: 'roobi_hybrid_pearls_screener_child_p2_q5',
        Q16: 'roobi_hybrid_pearls_screener_child_p2_q6',
        Q17: 'roobi_hybrid_pearls_screener_child_p2_q7',
      },
    },
  },
  PEARLS_SCREENER_TEEN: {
    LABEL: 'roobi_hybrid_pearls_screener_teen',
    FIELD_LABELS: {
      ANSWER_TYPE: 'radio',
      DESCRIPTIVE_FIELDS: {
        HEADING: 'roobi_hybrid_pearls_screener_teen_heading',
        SUBHEADING_1: 'roobi_hybrid_pearls_screener_teen_subheading_1',
        SUBHEADING_2: 'roobi_hybrid_pearls_screener_teen_subheading_2',
        INSTRUCTIONS: 'roobi_hybrid_pearls_screener_teen_instructions',
      },
      SUMMATION_FIELDS: {
        SUMMATION_PART_1: 'roobi_hybrid_pearls_screener_teen_summation_p1',
        SUMMATION_PART_2: 'roobi_hybrid_pearls_screener_teen_summation_p2',
      },
      COMPLETED_BY: 'roobi_hybrid_pearls_screener_teen_completed_by',
      QUESTIONS: {
        Q1: 'roobi_hybrid_pearls_screener_teen_p1_q1',
        Q2: 'roobi_hybrid_pearls_screener_teen_p1_q2',
        Q3: 'roobi_hybrid_pearls_screener_teen_p1_q3',
        Q4: 'roobi_hybrid_pearls_screener_teen_p1_q4',
        Q5: 'roobi_hybrid_pearls_screener_teen_p1_q5',
        Q6: 'roobi_hybrid_pearls_screener_teen_p1_q6',
        Q7: 'roobi_hybrid_pearls_screener_teen_p1_q7',
        Q8: 'roobi_hybrid_pearls_screener_teen_p1_q8',
        Q9: 'roobi_hybrid_pearls_screener_teen_p1_q9',
        Q10: 'roobi_hybrid_pearls_screener_teen_p1_q10',
        // Begin part 2
        Q11: 'roobi_hybrid_pearls_screener_teen_p2_q1',
        Q12: 'roobi_hybrid_pearls_screener_teen_p2_q2',
        Q13: 'roobi_hybrid_pearls_screener_teen_p2_q3',
        Q14: 'roobi_hybrid_pearls_screener_teen_p2_q4',
        Q15: 'roobi_hybrid_pearls_screener_teen_p2_q5',
        Q16: 'roobi_hybrid_pearls_screener_teen_p2_q6',
        Q17: 'roobi_hybrid_pearls_screener_teen_p2_q7',
        Q18: 'roobi_hybrid_pearls_screener_teen_p2_q8',
        Q19: 'roobi_hybrid_pearls_screener_teen_p2_q9',
      },
    },
  },
  PEARLS_SCREENER_TEEN_SELF: {
    LABEL: 'roobi_hybrid_pearls_screener_teen_self',
    FIELD_LABELS: {
      ANSWER_TYPE: 'radio',
      DESCRIPTIVE_FIELDS: {
        HEADING: 'roobi_hybrid_pearls_screener_teen_self_heading',
        SUBHEADING_1: 'roobi_hybrid_pearls_screener_teen_self_subheading_1',
        SUBHEADING_2: 'roobi_hybrid_pearls_screener_teen_self_subheading_2',
        INSTRUCTIONS: 'roobi_hybrid_pearls_screener_teen_self_instructions',
        COMPLETED_BY: 'roobi_hybrid_pearls_screener_teen_self_completed_by',
      },
      SUMMATION_FIELDS: {
        SUMMATION_PART_1: 'roobi_hybrid_pearls_screener_teen_self_summation_p1',
        SUMMATION_PART_2: 'roobi_hybrid_pearls_screener_teen_self_summation_p2',
      },
      QUESTIONS: {
        Q1: 'roobi_hybrid_pearls_screener_teen_self_p1_q1',
        Q2: 'roobi_hybrid_pearls_screener_teen_self_p1_q2',
        Q3: 'roobi_hybrid_pearls_screener_teen_self_p1_q3',
        Q4: 'roobi_hybrid_pearls_screener_teen_self_p1_q4',
        Q5: 'roobi_hybrid_pearls_screener_teen_self_p1_q5',
        Q6: 'roobi_hybrid_pearls_screener_teen_self_p1_q6',
        Q7: 'roobi_hybrid_pearls_screener_teen_self_p1_q7',
        Q8: 'roobi_hybrid_pearls_screener_teen_self_p1_q8',
        Q9: 'roobi_hybrid_pearls_screener_teen_self_p1_q9',
        Q10: 'roobi_hybrid_pearls_screener_teen_self_p1_q10',
        // Begin part 2
        Q11: 'roobi_hybrid_pearls_screener_teen_self_p2_q1',
        Q12: 'roobi_hybrid_pearls_screener_teen_self_p2_q2',
        Q13: 'roobi_hybrid_pearls_screener_teen_self_p2_q3',
        Q14: 'roobi_hybrid_pearls_screener_teen_self_p2_q4',
        Q15: 'roobi_hybrid_pearls_screener_teen_self_p2_q5',
        Q16: 'roobi_hybrid_pearls_screener_teen_self_p2_q6',
        Q17: 'roobi_hybrid_pearls_screener_teen_self_p2_q7',
        Q18: 'roobi_hybrid_pearls_screener_teen_self_p2_q8',
        Q19: 'roobi_hybrid_pearls_screener_teen_self_p2_q9',
      },
    },
  },
  ACORN_ASSESSMENT: {
    LABEL: 'roobi_hybrid_acorn',
    FIELD_LABELS: {
      ANSWER_TYPE: 'scale',
      SUMMATION: 'roobi_hybrid_acorn_average',
      QUESTIONS: {
        Q1: 'roobi_hybrid_acorn_q1',
        Q2: 'roobi_hybrid_acorn_q2',
        Q3: 'roobi_hybrid_acorn_q3',
        Q4: 'roobi_hybrid_acorn_q4',
        Q5: 'roobi_hybrid_acorn_q5',
        Q6: 'roobi_hybrid_acorn_q6',
        Q7: 'roobi_hybrid_acorn_q7',
        Q8: 'roobi_hybrid_acorn_q8',
        Q9: 'roobi_hybrid_acorn_q9',
        Q10: 'roobi_hybrid_acorn_q10',
        Q11: 'roobi_hybrid_acorn_q11',
        Q12: 'roobi_hybrid_acorn_q12',
        Q13: 'roobi_hybrid_acorn_q13',
        Q14: 'roobi_hybrid_acorn_q14',
        Q15: 'roobi_hybrid_acorn_q15',
        Q16: 'roobi_hybrid_acorn_q16',
        Q17: 'roobi_hybrid_acorn_q17',
        Q18: 'roobi_hybrid_acorn_q18',
        Q19: 'roobi_hybrid_acorn_q19',
        Q20: 'roobi_hybrid_acorn_q20',
        Q21: 'roobi_hybrid_acorn_q21',
      },
      DESCRIPTIVE_FIELDS: {
        HEADING: 'roobi_hybrid_acorn_heading',
        DESCRIPTION: 'roobi_hybrid_acorn_description',
        MEASUREMENT: 'roobi_hybrid_acorn_measurement',
      },
    },
  },
  BRIEF_ADDICTION_MONITOR: {
    LABEL: 'roobi_hybrid_bam',
    FIELD_LABELS: {
      ANSWER_TYPE: 'radio',
      SUMMATIONS: [
        {
          label: 'roobi_hybrid_bam_summation1',
          questions: ['roobi_hybrid_bam_q4', 'roobi_hybrid_bam_q5', 'roobi_hybrid_bam_q6'],
        },
        {
          label: 'roobi_hybrid_bam_summation2',
          questions: [
            'roobi_hybrid_bam_q1',
            'roobi_hybrid_bam_q2',
            'roobi_hybrid_bam_q3',
            'roobi_hybrid_bam_q8',
            'roobi_hybrid_bam_q11',
            'roobi_hybrid_bam_q15',
          ],
        },
        {
          label: 'roobi_hybrid_bam_summation3',
          questions: [
            'roobi_hybrid_bam_q9',
            'roobi_hybrid_bam_q10',
            'roobi_hybrid_bam_q12',
            'roobi_hybrid_bam_q13',
            'roobi_hybrid_bam_q14',
            'roobi_hybrid_bam_q16',
          ],
        },
      ],
      QUESTIONS: [
        'roobi_hybrid_bam_q1',
        'roobi_hybrid_bam_q2',
        'roobi_hybrid_bam_q3',
        'roobi_hybrid_bam_q4',
        'roobi_hybrid_bam_q5',
        'roobi_hybrid_bam_q6',
        'roobi_hybrid_bam_q7a',
        'roobi_hybrid_bam_q7b',
        'roobi_hybrid_bam_q7c',
        'roobi_hybrid_bam_q7d',
        'roobi_hybrid_bam_q7e',
        'roobi_hybrid_bam_q7f',
        'roobi_hybrid_bam_q7g',
        'roobi_hybrid_bam_q8',
        'roobi_hybrid_bam_q9',
        'roobi_hybrid_bam_q10',
        'roobi_hybrid_bam_q11',
        'roobi_hybrid_bam_q12',
        'roobi_hybrid_bam_q13',
        'roobi_hybrid_bam_q14',
        'roobi_hybrid_bam_q15',
        'roobi_hybrid_bam_q16',
        'roobi_hybrid_bam_q17',
      ],
      DESCRIPTIVE_FIELDS: {
        HEADING: 'roobi_hybrid_bam_heading',
        DESCRIPTION: 'roobi_hybrid_bam_description',
        MEASUREMENT: 'roobi_hybrid_bam_measurement',
        R_HEADING1: 'roobi_hybrid_bam_range_heading1',
        R_DESCRIPTION1: 'roobi_hybrid_bam_range_description1',
        R_HEADING2: 'roobi_hybrid_bam_range_heading2',
        R_DESCRIPTION2: 'roobi_hybrid_bam_range_description2',
        R_HEADING3: 'roobi_hybrid_bam_range_heading3',
        R_DESCRIPTION3: 'roobi_hybrid_bam_range_description3',
      },
    },
  },
  DEPRESSION_ANXIETY_STRESS_SCALES_DASS: {
    LABEL: 'roobi_hybrid_dass',
    FIELD_LABELS: {
      ANSWER_TYPE: 'radio',
      SUMMATIONS: [
        {
          label: 'roobi_hybrid_dass_summation1',
          questions: [
            'roobi_hybrid_dass_q3',
            'roobi_hybrid_dass_q5',
            'roobi_hybrid_dass_q10',
            'roobi_hybrid_dass_q13',
            'roobi_hybrid_dass_q16',
            'roobi_hybrid_dass_q17',
            'roobi_hybrid_dass_q21',
            'roobi_hybrid_dass_q24',
            'roobi_hybrid_dass_q26',
            'roobi_hybrid_dass_q31',
            'roobi_hybrid_dass_q34',
            'roobi_hybrid_dass_q37',
            'roobi_hybrid_dass_q38',
            'roobi_hybrid_dass_q42',
          ],
        },
        {
          label: 'roobi_hybrid_dass_summation2',
          questions: [
            'roobi_hybrid_dass_q2',
            'roobi_hybrid_dass_q4',
            'roobi_hybrid_dass_q7',
            'roobi_hybrid_dass_q9',
            'roobi_hybrid_dass_q15',
            'roobi_hybrid_dass_q19',
            'roobi_hybrid_dass_q20',
            'roobi_hybrid_dass_q23',
            'roobi_hybrid_dass_q25',
            'roobi_hybrid_dass_q28',
            'roobi_hybrid_dass_q30',
            'roobi_hybrid_dass_q36',
            'roobi_hybrid_dass_q40',
            'roobi_hybrid_dass_q41',
          ],
        },
        {
          label: 'roobi_hybrid_dass_summation3',
          questions: [
            'roobi_hybrid_dass_q1',
            'roobi_hybrid_dass_q6',
            'roobi_hybrid_dass_q8',
            'roobi_hybrid_dass_q11',
            'roobi_hybrid_dass_q12',
            'roobi_hybrid_dass_q14',
            'roobi_hybrid_dass_q18',
            'roobi_hybrid_dass_q22',
            'roobi_hybrid_dass_q27',
            'roobi_hybrid_dass_q29',
            'roobi_hybrid_dass_q32',
            'roobi_hybrid_dass_q33',
            'roobi_hybrid_dass_q35',
            'roobi_hybrid_dass_q39',
          ],
        },
      ],
      QUESTIONS: Array.from({ length: 42 }).map((_, i) => `roobi_hybrid_dass_q${i + 1}`),
      DESCRIPTIVE_FIELDS: {
        HEADING: 'roobi_hybrid_dass_heading',
        DESCRIPTION: 'roobi_hybrid_dass_description',
        MEASUREMENT: 'roobi_hybrid_dass_measurement',
      },
    },
  },
  // CSSRS RISK LEVELS: 1 = Low Risk, 2 = Moderate Risk, 3 = High Risk
  // (not using a constant/variable because it should never change and its too tedious tbqh)
  CSSRS_SCREEN: {
    LABEL: 'roobi_hybrid_cssrs_screen',
    FIELD_LABELS: {
      ANSWER_TYPE: 'checkbox',
      SUMMATION: 'roobi_hybrid_cssrs_screen_summation',
      QUESTION_DESCRIPTORS: {
        roobi_hybrid_cssrs_screen_q3: `E.g. “I thought about taking an overdose but I never made a specific plan as to when where or how I would actually do it….and I would never go through with it.`,
        roobi_hybrid_cssrs_screen_q4: `As opposed to “I have the thoughts but I definitely will not do anything about them.”`,
        roobi_hybrid_cssrs_screen_q6: `Examples: Collected pills, obtained a gun, gave away valuables, wrote a will or suicide note, took out pills but didn’t swallow any, held a gun but changed your mind or it was grabbed from your hand, went to the roof but didn’t jump; or actually took pills, tried to shoot yourself, cut yourself, tried to hang yourself, etc.`,
      },
      QUESTION_MEASUREMENTS: {
        // Array representing the two options per question (i.e. [Lifetime, Past Month]) and
        // their risk level *if checked* (everything unchecked option is risk level 1)
        // NOTE: this is actually obsolete for the screen as of Feb 2023 (calculation is done without these
        // option values in ScreenSurveyComponent BUT leaving here for future reference of OG values)
        roobi_hybrid_cssrs_screen_q1: [2, 2, 0],
        roobi_hybrid_cssrs_screen_q2: [2, 2, 0],
        roobi_hybrid_cssrs_screen_q3: [2, 2, 0],
        roobi_hybrid_cssrs_screen_q4: [2, 3, 0],
        roobi_hybrid_cssrs_screen_q5: [2, 3, 0],
        roobi_hybrid_cssrs_screen_q6: [2, 3, 0],
      },
      DESCRIPTIVE_FIELDS: {
        HEADING: 'roobi_hybrid_cssrs_screen_heading',
        MEASUREMENT: 'roobi_hybrid_cssrs_screen_measurement',
      },
    },
  },
  CSSRS_ASSESSMENT: {
    LABEL: 'roobi_hybrid_cssrs_assessment',
    FIELD_LABELS: {
      SUMMATION: 'roobi_hybrid_cssrs_assessment_summation',
      QUESTION_SECTIONS: [
        {
          name: 'Suicidal and Self-Injurious Behavior',
          questions: [
            'roobi_hybrid_cssrs_assessment_q1',
            'roobi_hybrid_cssrs_assessment_q2',
            'roobi_hybrid_cssrs_assessment_q3',
            'roobi_hybrid_cssrs_assessment_q4',
            'roobi_hybrid_cssrs_assessment_q5',
          ],
        },
        {
          name: 'Suicidal Ideation',
          questions: [
            'roobi_hybrid_cssrs_assessment_q6',
            'roobi_hybrid_cssrs_assessment_q7',
            'roobi_hybrid_cssrs_assessment_q8',
            'roobi_hybrid_cssrs_assessment_q9',
            'roobi_hybrid_cssrs_assessment_q10',
            'roobi_hybrid_cssrs_assessment_q56',
            'roobi_hybrid_cssrs_assessment_q57',
            'roobi_hybrid_cssrs_assessment_q58',
            'roobi_hybrid_cssrs_assessment_q59',
            'roobi_hybrid_cssrs_assessment_q60',
            'roobi_hybrid_cssrs_assessment_q61',
            'roobi_hybrid_cssrs_assessment_suicidal_ideation_NONE_OPTION',
            'roobi_hybrid_cssrs_assessment_NONE_OPTION',
          ],
        },
        {
          name: 'Activating Events',
          questions: [
            'roobi_hybrid_cssrs_assessment_q11',
            'roobi_hybrid_cssrs_assessment_q12',
            'roobi_hybrid_cssrs_assessment_q13',
            'roobi_hybrid_cssrs_assessment_q14',
            'roobi_hybrid_cssrs_assessment_activating_events_NONE_OPTION',
            'roobi_hybrid_cssrs_assessment_NONE_OPTION',
          ],
        },
        {
          name: 'Treatment History',
          questions: [
            'roobi_hybrid_cssrs_assessment_q15',
            'roobi_hybrid_cssrs_assessment_q16',
            'roobi_hybrid_cssrs_assessment_q17',
            'roobi_hybrid_cssrs_assessment_q18',
          ],
        },
        {
          name: 'Protective Factors',
          questions: [
            'roobi_hybrid_cssrs_assessment_q20',
            'roobi_hybrid_cssrs_assessment_q21',
            'roobi_hybrid_cssrs_assessment_q22',
            'roobi_hybrid_cssrs_assessment_q23',
            'roobi_hybrid_cssrs_assessment_q24',
            'roobi_hybrid_cssrs_assessment_q25',
            'roobi_hybrid_cssrs_assessment_protective_factors_NONE_OPTION',
            'roobi_hybrid_cssrs_assessment_NONE_OPTION',
          ],
        },
        {
          name: 'Clinical Status',
          questions: [
            'roobi_hybrid_cssrs_assessment_q27',
            'roobi_hybrid_cssrs_assessment_q28',
            'roobi_hybrid_cssrs_assessment_q29',
            'roobi_hybrid_cssrs_assessment_q30',
            'roobi_hybrid_cssrs_assessment_q31',
            'roobi_hybrid_cssrs_assessment_q32',
            'roobi_hybrid_cssrs_assessment_q34',
            'roobi_hybrid_cssrs_assessment_q35',
            'roobi_hybrid_cssrs_assessment_q36',
            'roobi_hybrid_cssrs_assessment_q37',
            'roobi_hybrid_cssrs_assessment_q38',
            'roobi_hybrid_cssrs_assessment_q39',
            'roobi_hybrid_cssrs_assessment_q40',
            'roobi_hybrid_cssrs_assessment_q41',
            'roobi_hybrid_cssrs_assessment_q42',
            'roobi_hybrid_cssrs_assessment_clinical_status_NONE_OPTION',
            'roobi_hybrid_cssrs_assessment_NONE_OPTION',
          ],
        },
        {
          name: '', // sectionless
          questions: [
            'roobi_hybrid_cssrs_assessment_q19',
            'roobi_hybrid_cssrs_assessment_q26',
            'roobi_hybrid_cssrs_assessment_q43',
            'roobi_hybrid_cssrs_assessment_q50',
            'roobi_hybrid_cssrs_assessment_q53',
            'roobi_hybrid_cssrs_assessment_q44',
            'roobi_hybrid_cssrs_assessment_q45',
            'roobi_hybrid_cssrs_assessment_q54',
            'roobi_hybrid_cssrs_assessment_q55',
            'roobi_hybrid_cssrs_assessment_q51',
          ],
        },
        {
          name: '', // sectionless
          questions: [
            'roobi_hybrid_cssrs_assessment_q46',
            'roobi_hybrid_cssrs_assessment_q47',
            'roobi_hybrid_cssrs_assessment_q48',
            'roobi_hybrid_cssrs_assessment_q49',
            'roobi_hybrid_cssrs_assessment_q52',
          ],
        },
      ],
      QUESTION_MEASUREMENTS: {
        // Value corresponds to risk level if singular question option is checked
        // or array values correspond to risk level per option if the question has more than one option.
        roobi_hybrid_cssrs_assessment_q1: [1, 3, 0],
        roobi_hybrid_cssrs_assessment_q2: [1, 3, 0],
        roobi_hybrid_cssrs_assessment_q3: [1, 3, 0],
        roobi_hybrid_cssrs_assessment_q4: [1, 3, 0],
        roobi_hybrid_cssrs_assessment_q5: [1, 2, 0],
        roobi_hybrid_cssrs_assessment_q6: 2,
        roobi_hybrid_cssrs_assessment_q7: 2,
        roobi_hybrid_cssrs_assessment_q8: 2,
        roobi_hybrid_cssrs_assessment_q9: 2,
        roobi_hybrid_cssrs_assessment_q10: 3,
        roobi_hybrid_cssrs_assessment_q30: 2,
        roobi_hybrid_cssrs_assessment_q31: 2,
        roobi_hybrid_cssrs_assessment_q33: 2,
        roobi_hybrid_cssrs_assessment_q39: 3,
        roobi_hybrid_cssrs_assessment_q40: 3,
        roobi_hybrid_cssrs_assessment_q43: [3, 2, 1, 0],
        roobi_hybrid_cssrs_assessment_q44: [1, 1, 2, 0],
        roobi_hybrid_cssrs_assessment_q45: [1, 2, 1, 0],
        roobi_hybrid_cssrs_assessment_q51: [1, 1, 2, 2, 2, 0],
        roobi_hybrid_cssrs_assessment_q52: [1, 1, 2, 2, 2, 0],
        roobi_hybrid_cssrs_assessment_q53: [1, 2, 2, 2, 2, 0],
        roobi_hybrid_cssrs_assessment_q54: [1, 1, 1, 2, 2, 0],
        roobi_hybrid_cssrs_assessment_q55: [1, 2, 2, 2, 2, 0],
        roobi_hybrid_cssrs_assessment_q56: [1, 0],
        roobi_hybrid_cssrs_assessment_q57: [1, 0],
        roobi_hybrid_cssrs_assessment_q58: [2, 0],
        roobi_hybrid_cssrs_assessment_q59: [3, 0],
        roobi_hybrid_cssrs_assessment_q60: [3, 0],
        roobi_hybrid_cssrs_assessment_q61: [2, 3, 0],
        roobi_hybrid_cssrs_assessment_NONE_OPTION: 0,
        roobi_hybrid_cssrs_assessment_clinical_status_NONE_OPTION: 0,
        roobi_hybrid_cssrs_assessment_protective_factors_NONE_OPTION: 0,
        roobi_hybrid_cssrs_assessment_activating_events_NONE_OPTION: 0,
        roobi_hybrid_cssrs_assessment_suicidal_ideation_NONE_OPTION: 0,
        // Any question not included in QUESTION_MEASUREMENTS is defaulted to 1
      },
      DESCRIPTIVE_FIELDS: {
        HEADING: 'roobi_hybrid_cssrs_assessment_heading',
        MEASUREMENT: 'roobi_hybrid_cssrs_assessment_measurement',
      },
    },
  },
  LIFE_SKILLS_INDEX: {
    LABEL: 'roobi_hybrid_lsi',
    FIELD_LABELS: {
      ANSWER_TYPE: 'radio',
      SUMMATION: 'roobi_hybrid_lsi_summation',
      QUESTIONS: [
        'roobi_lsi_q1',
        'roobi_lsi_q2',
        'roobi_lsi_q3',
        'roobi_lsi_q4',
        'roobi_lsi_q5',
        'roobi_lsi_q6',
        'roobi_lsi_q7',
        'roobi_lsi_q8',
        'roobi_lsi_q9',
        'roobi_lsi_q10',
        'roobi_lsi_q11',
        'roobi_lsi_q12',
        'roobi_lsi_q13',
        'roobi_lsi_q14',
        'roobi_lsi_q15',
        'roobi_lsi_q16',
        'roobi_lsi_q17',
      ],
      DESCRIPTIVE_FIELDS: {
        HEADING: 'roobi_hybrid_lsi_heading',
      },
    },
  },
  FALLS_EVALUATION: {
    LABEL: 'roobi_hybrid_falls',
    FIELD_LABELS: {
      ANSWER_TYPE: 'radio',
      SUMMATION: 'roobi_hybrid_falls_summation',
      QUESTIONS: [
        'roobi_falls_q1',
        'roobi_falls_q2',
        'roobi_falls_q3',
        'roobi_falls_q4',
        'roobi_falls_q5',
        'roobi_falls_q6a',
        'roobi_falls_q7',
        'roobi_falls_q8',
        'roobi_falls_q9',
        'roobi_falls_q10',
        'roobi_falls_q11',
        'roobi_falls_q12',
        'roobi_falls_q13',
      ],
      DESCRIPTIVE_FIELDS: {
        HEADING: 'roobi_hybrid_falls_heading',
        PRIMARY_FACTORS_SUBHEADING: 'roobi_hybrid_falls_subheading1',
        OTHER_FACTORS_SUBHEADING: 'roobi_hybrid_falls_subheading2',
        SUMMARY_HEADING: 'roobi_hybrid_falls_heading_summary',
        RANGE_DESCRIPTION: 'roobi_hybrid_falls_range_description',
        RISK_SCORE_ASSESSMENT: 'roobi_hybrid_falls_risk_score_assessment',
      },
    },
  },
  AIMS: {
    LABEL: 'roobi_hybrid_aims',
    FIELD_LABELS: {
      SUMMATION: 'roobi_hybrid_aims_summation',
      DESCRIPTIVE_FIELDS: {
        HEADING: 'roobi_hybrid_aims_heading',
        QUESTIONS_TEXT: 'roobi_hybrid_aims_questions_text',
        EXAM_PROCEDURE_TEXT: 'roobi_hybrid_aims_exam_text',
      },
      SECTION_HEADINGS: [
        'roobi_hybrid_aims_scoring_heading',
        'roobi_hybrid_aims_exam',
        'roobi_hybrid_aims_questions',
        'roobi_hybrid_aims_code',
        'roobi_hybrid_aims_movement_ratings',
        'roobi_hybrid_aims_category1',
        'roobi_hybrid_aims_category2',
        'roobi_hybrid_aims_category3',
        'roobi_hybrid_aims_category4',
        'roobi_hybrid_aims_category5',
        'roobi_hybrid_aims_category6',
      ],
      LIST_ITEMS: [
        'roobi_hybrid_aims_scoring_bullet1',
        'roobi_hybrid_aims_scoring_bullet2',
        'roobi_hybrid_aims_scoring_bullet3',
        'roobi_hybrid_aims_scoring_bullet4',
        'roobi_hybrid_aims_code_bullet1',
        'roobi_hybrid_aims_code_bullet2',
        'roobi_hybrid_aims_code_bullet3',
        'roobi_hybrid_aims_code_bullet4',
        'roobi_hybrid_aims_code_bullet5',
        'roobi_hybrid_aims_movement_ratings_bullet1',
        'roobi_hybrid_aims_movement_ratings_bullet2',
        'roobi_hybrid_aims_movement_ratings_bullet3',
      ],
      QUESTIONS: [
        'roobi_hybrid_aims_category1_q1',
        'roobi_hybrid_aims_category1_q2',
        'roobi_hybrid_aims_category1_q3',
        'roobi_hybrid_aims_category1_q4',
        'roobi_hybrid_aims_category2_q1',
        'roobi_hybrid_aims_category2_q2',
        'roobi_hybrid_aims_category3_q1',
      ],
    },
  },
  CWS: {
    LABEL: 'roobi_hybrid_cws',
    FIELD_LABELS: {
      ANSWER_TYPE: 'radio',
      SUMMATIONS: [
        {
          label: 'roobi_hybrid_cws_summation',
          questions: [
            'roobi_cws_q1',
            'roobi_cws_q2',
            'roobi_cws_q3',
            'roobi_cws_q4',
            'roobi_cws_q5',
            'roobi_cws_q6',
            'roobi_cws_q7',
            'roobi_cws_q8',
            'roobi_cws_q9',
            'roobi_cws_q10',
            'roobi_cws_q11',
          ],
        },
      ],
      QUESTIONS: [
        'roobi_cws_q1',
        'roobi_cws_q2',
        'roobi_cws_q3',
        'roobi_cws_q4',
        'roobi_cws_q5',
        'roobi_cws_q6',
        'roobi_cws_q7',
        'roobi_cws_q8',
        'roobi_cws_q9',
        'roobi_cws_q10',
        'roobi_cws_q11',
      ],
      DESCRIPTIVE_FIELDS: {
        HEADING: 'roobi_hybrid_cws_heading',
        RISK_SCORE_ASSESSMENT: 'roobi_hybrid_cws_score_assessment',
      },
    },
  },
  CIWA: {
    LABEL: 'roobi_hybrid_ciwa',
    FIELD_LABELS: {
      ANSWER_TYPE: 'radio',
      SUMMATIONS: [
        {
          label: 'roobi_hybrid_ciwa_summation',
          questions: [
            'roobi_ciwa_q1',
            'roobi_ciwa_q2',
            'roobi_ciwa_q3',
            'roobi_ciwa_q4',
            'roobi_ciwa_q5',
            'roobi_ciwa_q6',
            'roobi_ciwa_q7',
            'roobi_ciwa_q8',
            'roobi_ciwa_q9',
            'roobi_ciwa_q10',
          ],
        },
      ],
      QUESTIONS: [
        'roobi_ciwa_q1',
        'roobi_ciwa_q2',
        'roobi_ciwa_q3',
        'roobi_ciwa_q4',
        'roobi_ciwa_q5',
        'roobi_ciwa_q6',
        'roobi_ciwa_q7',
        'roobi_ciwa_q8',
        'roobi_ciwa_q9',
        'roobi_ciwa_q10',
      ],
      DESCRIPTIVE_FIELDS: {
        HEADING: 'roobi_hybrid_ciwa_heading',
        RISK_SCORE_ASSESSMENT: 'roobi_hybrid_ciwa_score_assessment',
      },
    },
  },
  PTSD_CHECKLIST: {
    LABEL: 'roobi_hybrid_pcl5',
    FIELD_LABELS: {
      ANSWER_TYPE: 'scale',
      SUMMATION: 'roobi_hybrid_pcl5_summation',
      DESCRIPTIVE_FIELDS: {
        HEADING: 'roobi_hybrid_pcl5_heading',
        MEASUREMENT: 'roobi_hybrid_pcl5_measurement',
        INSTRUCTIONS: 'roobi_hybrid_pcl5_instructions',
      },
      PARAGRAPHS: {
        WORST_EXPERIENCE: 'roobi_hybrid_pcl5_we',
      },
      QUESTIONS: {
        Q1: 'roobi_hybrid_pcl5_1',
        Q2: 'roobi_hybrid_pcl5_2',
        Q3: 'roobi_hybrid_pcl5_3',
        Q4: 'roobi_hybrid_pcl5_4',
        Q5: 'roobi_hybrid_pcl5_5',
        Q6: 'roobi_hybrid_pcl5_6',
        Q7: 'roobi_hybrid_pcl5_7',
        Q8: 'roobi_hybrid_pcl5_8',
        Q9: 'roobi_hybrid_pcl5_9',
        Q10: 'roobi_hybrid_pcl5_10',
        Q11: 'roobi_hybrid_pcl5_11',
        Q12: 'roobi_hybrid_pcl5_12',
        Q13: 'roobi_hybrid_pcl5_13',
        Q14: 'roobi_hybrid_pcl5_14',
        Q15: 'roobi_hybrid_pcl5_15',
        Q16: 'roobi_hybrid_pcl5_16',
        Q17: 'roobi_hybrid_pcl5_17',
        Q18: 'roobi_hybrid_pcl5_18',
        Q19: 'roobi_hybrid_pcl5_19',
        Q20: 'roobi_hybrid_pcl5_20',
      },
    },
  },
  MCLEAN_MSI_BPD: {
    LABEL: 'roobi_hybrid_mclean_bpd',
    FIELD_LABELS: {
      ANSWER_TYPE: 'radio',
      DESCRIPTIVE_FIELDS: {
        HEADING: 'roobi_hybrid_mclean_bpd_heading',
        INSTRUCTIONS: 'roobi_hybrid_mclean_bpd_instructions',
      },
      SUMMATION: 'roobi_hybrid_mclean_bpd_summation',
      QUESTIONS: {
        Q1: 'roobi_hybrid_mclean_bpd_1',
        Q2: 'roobi_hybrid_mclean_bpd_2',
        Q3: 'roobi_hybrid_mclean_bpd_3',
        Q4: 'roobi_hybrid_mclean_bpd_4',
        Q5: 'roobi_hybrid_mclean_bpd_5',
        Q6: 'roobi_hybrid_mclean_bpd_6',
        Q7: 'roobi_hybrid_mclean_bpd_7',
        Q8: 'roobi_hybrid_mclean_bpd_8',
        Q9: 'roobi_hybrid_mclean_bpd_9',
        Q10: 'roobi_hybrid_mclean_bpd_10',
      },
    },
  },
  INTOLERANCE_OF_UNCERTAINTY: {
    LABEL: 'roobi_hybrid_ius',
    FIELD_LABELS: {
      ANSWER_TYPE: 'scale',
      SUMMATION: 'roobi_hybrid_ius_summation',
      DESCRIPTIVE_FIELDS: {
        HEADING: 'roobi_hybrid_ius_heading',
        INSTRUCTIONS: 'roobi_hybrid_ius_instructions',
      },
      QUESTIONS: {
        Q1: 'roobi_hybrid_ius_q1',
        Q2: 'roobi_hybrid_ius_q2',
        Q3: 'roobi_hybrid_ius_q3',
        Q4: 'roobi_hybrid_ius_q4',
        Q5: 'roobi_hybrid_ius_q5',
        Q6: 'roobi_hybrid_ius_q6',
        Q7: 'roobi_hybrid_ius_q7',
        Q8: 'roobi_hybrid_ius_q8',
        Q9: 'roobi_hybrid_ius_q9',
        Q10: 'roobi_hybrid_ius_q10',
        Q11: 'roobi_hybrid_ius_q11',
        Q12: 'roobi_hybrid_ius_q12',
        Q13: 'roobi_hybrid_ius_q13',
        Q14: 'roobi_hybrid_ius_q14',
        Q15: 'roobi_hybrid_ius_q15',
        Q16: 'roobi_hybrid_ius_q16',
        Q17: 'roobi_hybrid_ius_q17',
        Q18: 'roobi_hybrid_ius_q18',
        Q19: 'roobi_hybrid_ius_q19',
        Q20: 'roobi_hybrid_ius_q20',
        Q21: 'roobi_hybrid_ius_q21',
        Q22: 'roobi_hybrid_ius_q22',
        Q23: 'roobi_hybrid_ius_q23',
        Q24: 'roobi_hybrid_ius_q24',
        Q25: 'roobi_hybrid_ius_q25',
        Q26: 'roobi_hybrid_ius_q26',
        Q27: 'roobi_hybrid_ius_q27',
      },
    },
  },
  OUTCOME_QUESTIONNAIRE: {
    LABEL: 'roobi_hybrid_outcome_questionnaire',
    FIELD_LABELS: {
      ANSWER_TYPE: 'radio',
      DESCRIPTIVE_FIELDS: {
        HEADER: 'roobi_hybrid_outcome_questionnaire_header',
        INSTRUCTIONS: 'roobi_hybrid_outcome_questionnaire_instructions',
      },
      SUMMATIONS: {
        SUMMATION_SD: 'roobi_hybrid_outcome_questionnaire_summation_sd',
        SUMMATION_SR: 'roobi_hybrid_outcome_questionnaire_summation_sr',
        SUMMATION_IR: 'roobi_hybrid_outcome_questionnaire_summation_ir',
        SUMMATION_TOTAL: 'roobi_hybrid_outcome_questionnaire_summation_total',
      },
      QUESTIONS: {
        Q1: 'roobi_hybrid_outcome_questionnaire_q1',
        Q2: 'roobi_hybrid_outcome_questionnaire_q2',
        Q3: 'roobi_hybrid_outcome_questionnaire_q3',
        Q4: 'roobi_hybrid_outcome_questionnaire_q4',
        Q5: 'roobi_hybrid_outcome_questionnaire_q5',
        Q6: 'roobi_hybrid_outcome_questionnaire_q6',
        Q7: 'roobi_hybrid_outcome_questionnaire_q7',
        Q8: 'roobi_hybrid_outcome_questionnaire_q8',
        Q9: 'roobi_hybrid_outcome_questionnaire_q9',
        Q10: 'roobi_hybrid_outcome_questionnaire_q10',
        Q11: 'roobi_hybrid_outcome_questionnaire_q11',
        Q12: 'roobi_hybrid_outcome_questionnaire_q12',
        Q13: 'roobi_hybrid_outcome_questionnaire_q13',
        Q14: 'roobi_hybrid_outcome_questionnaire_q14',
        Q15: 'roobi_hybrid_outcome_questionnaire_q15',
        Q16: 'roobi_hybrid_outcome_questionnaire_q16',
        Q17: 'roobi_hybrid_outcome_questionnaire_q17',
        Q18: 'roobi_hybrid_outcome_questionnaire_q18',
        Q19: 'roobi_hybrid_outcome_questionnaire_q19',
        Q20: 'roobi_hybrid_outcome_questionnaire_q20',
        Q21: 'roobi_hybrid_outcome_questionnaire_q21',
        Q22: 'roobi_hybrid_outcome_questionnaire_q22',
        Q23: 'roobi_hybrid_outcome_questionnaire_q23',
        Q24: 'roobi_hybrid_outcome_questionnaire_q24',
        Q25: 'roobi_hybrid_outcome_questionnaire_q25',
        Q26: 'roobi_hybrid_outcome_questionnaire_q26',
        Q27: 'roobi_hybrid_outcome_questionnaire_q27',
        Q28: 'roobi_hybrid_outcome_questionnaire_q28',
        Q29: 'roobi_hybrid_outcome_questionnaire_q29',
        Q30: 'roobi_hybrid_outcome_questionnaire_q30',
        Q31: 'roobi_hybrid_outcome_questionnaire_q31',
        Q32: 'roobi_hybrid_outcome_questionnaire_q32',
        Q33: 'roobi_hybrid_outcome_questionnaire_q33',
        Q34: 'roobi_hybrid_outcome_questionnaire_q34',
        Q35: 'roobi_hybrid_outcome_questionnaire_q35',
        Q36: 'roobi_hybrid_outcome_questionnaire_q36',
        Q37: 'roobi_hybrid_outcome_questionnaire_q37',
        Q38: 'roobi_hybrid_outcome_questionnaire_q38',
        Q39: 'roobi_hybrid_outcome_questionnaire_q39',
        Q40: 'roobi_hybrid_outcome_questionnaire_q40',
        Q41: 'roobi_hybrid_outcome_questionnaire_q41',
        Q42: 'roobi_hybrid_outcome_questionnaire_q42',
        Q43: 'roobi_hybrid_outcome_questionnaire_q43',
        Q44: 'roobi_hybrid_outcome_questionnaire_q44',
        Q45: 'roobi_hybrid_outcome_questionnaire_q45',
      },
      QUESTION_SCORING_TYPE_MAP: {
        roobi_hybrid_outcome_questionnaire_q1: 'ir',
        roobi_hybrid_outcome_questionnaire_q2: 'sd',
        roobi_hybrid_outcome_questionnaire_q3: 'sd',
        roobi_hybrid_outcome_questionnaire_q4: 'sr',
        roobi_hybrid_outcome_questionnaire_q5: 'sd',
        roobi_hybrid_outcome_questionnaire_q6: 'sd',
        roobi_hybrid_outcome_questionnaire_q7: 'ir',
        roobi_hybrid_outcome_questionnaire_q8: 'sd',
        roobi_hybrid_outcome_questionnaire_q9: 'sd',
        roobi_hybrid_outcome_questionnaire_q10: 'sd',
        roobi_hybrid_outcome_questionnaire_q11: 'sd',
        roobi_hybrid_outcome_questionnaire_q12: 'sr',
        roobi_hybrid_outcome_questionnaire_q13: 'sd',
        roobi_hybrid_outcome_questionnaire_q14: 'sr',
        roobi_hybrid_outcome_questionnaire_q15: 'sd',
        roobi_hybrid_outcome_questionnaire_q16: 'ir',
        roobi_hybrid_outcome_questionnaire_q17: 'ir',
        roobi_hybrid_outcome_questionnaire_q18: 'ir',
        roobi_hybrid_outcome_questionnaire_q19: 'ir',
        roobi_hybrid_outcome_questionnaire_q20: 'ir',
        roobi_hybrid_outcome_questionnaire_q21: 'sr',
        roobi_hybrid_outcome_questionnaire_q22: 'sd',
        roobi_hybrid_outcome_questionnaire_q23: 'sd',
        roobi_hybrid_outcome_questionnaire_q24: 'sd',
        roobi_hybrid_outcome_questionnaire_q25: 'sd',
        roobi_hybrid_outcome_questionnaire_q26: 'ir',
        roobi_hybrid_outcome_questionnaire_q27: 'sd',
        roobi_hybrid_outcome_questionnaire_q28: 'sr',
        roobi_hybrid_outcome_questionnaire_q29: 'sd',
        roobi_hybrid_outcome_questionnaire_q30: 'ir',
        roobi_hybrid_outcome_questionnaire_q31: 'sd',
        roobi_hybrid_outcome_questionnaire_q32: 'sr',
        roobi_hybrid_outcome_questionnaire_q33: 'sd',
        roobi_hybrid_outcome_questionnaire_q34: 'sd',
        roobi_hybrid_outcome_questionnaire_q35: 'sd',
        roobi_hybrid_outcome_questionnaire_q36: 'sd',
        roobi_hybrid_outcome_questionnaire_q37: 'ir',
        roobi_hybrid_outcome_questionnaire_q38: 'sr',
        roobi_hybrid_outcome_questionnaire_q39: 'sr',
        roobi_hybrid_outcome_questionnaire_q40: 'sd',
        roobi_hybrid_outcome_questionnaire_q41: 'sd',
        roobi_hybrid_outcome_questionnaire_q42: 'sd',
        roobi_hybrid_outcome_questionnaire_q43: 'ir',
        roobi_hybrid_outcome_questionnaire_q44: 'sr',
        roobi_hybrid_outcome_questionnaire_q45: 'sd',
      },
    },
  },
  BECK_ANXIETY: {
    LABEL: 'roobi_hybrid_beck_anxiety',
    FIELD_LABELS: {
      ANSWER_TYPE: 'table',
      TABLE: 'roobi_hybrid_beck_anxiety_table',
      SUMMATION: 'roobi_hybrid_beck_anxiety_summation',
      DISPLAY_TEXTS: [
        'roobi_hybrid_beck_anxiety_summation_description',
        'roobi_hybrid_beck_anxiety_range_description',
        'roobi_hybrid_beck_anxiety_heading',
      ],
    },
  },
  // dull (for the purpose of blocking in the form builder via the ROOBI_BLOCK_LIST below)
  PAIN_SCALE_DULL: {
    LABEL: 'roobi_dull_pain_scale',
    FIELD_LABELS: {
      PAIN_SCALE_DULL: 'roobi_dull_pain_scale',
    },
  },
};

// These are the roobi sections that will appear in the form builder.
// Add roobis to this list when they have frontend components built for them.
export const ROOBI_SECTION_ALLOW_LIST = [
  ROOBI_SECTIONS.CONTACT_POINTS.LABEL,
  ROOBI_SECTIONS.DEMOGRAPHIC_ADDRESS.LABEL,
  ROOBI_SECTIONS.ANXIETY_GAD7.LABEL,
  ROOBI_SECTIONS.DEPRESSION_PHQ9.LABEL,
  ROOBI_SECTIONS.BRIEF_ADDICTION_MONITOR.LABEL,
  ROOBI_SECTIONS.DEPRESSION_ANXIETY_STRESS_SCALES_DASS.LABEL,
  ROOBI_SECTIONS.CSSRS_SCREEN.LABEL,
  ROOBI_SECTIONS.CSSRS_ASSESSMENT.LABEL,
  ROOBI_SECTIONS.SUBSTANCE_USE_HISTORY.LABEL,
  ROOBI_SECTIONS.DEMOGRAPHICS.LABEL,
  ROOBI_SECTIONS.NAME_DOB.LABEL,
  ROOBI_SECTIONS.SSN.LABEL,
  ROOBI_SECTIONS.TREATMENT_HISTORY.LABEL,
  ROOBI_SECTIONS.PATIENT_INSURANCE.LABEL,
  ROOBI_SECTIONS.CARE_TEAM.LABEL,
  ROOBI_SECTIONS.MEDICATION.LABEL,
  ROOBI_SECTIONS.ALLERGIES.LABEL,
  ROOBI_SECTIONS.DIAGNOSIS.LABEL,
  ROOBI_SECTIONS.ADMIT_TRANSFER_DISCHARGE.LABEL,
  ROOBI_SECTIONS.ADMIT_DISCHARGE_NON_CLINICAL.LABEL,
  ROOBI_SECTIONS.CLIENT_CONTACTS.LABEL,
  ROOBI_SECTIONS.FAMILY_RELATIONSHIPS.LABEL,
  ROOBI_SECTIONS.AUTHORIZATIONS.LABEL,
  ROOBI_SECTIONS.PATIENT_REFERRAL.LABEL,
  ROOBI_SECTIONS.TREATMENT_PLAN.LABEL,
  ROOBI_SECTIONS.TREATMENT_PLAN_REFERENCE.LABEL,
  ROOBI_SECTIONS.RELEASE_OF_INFORMATION_V2.LABEL,
  ROOBI_SECTIONS.ACORN_ASSESSMENT.LABEL,
  ROOBI_SECTIONS.LIFE_SKILLS_INDEX.LABEL,
  ROOBI_SECTIONS.FUNDING_SOURCE.LABEL,
  ROOBI_SECTIONS.FALLS_EVALUATION.LABEL,
  ROOBI_SECTIONS.AIMS.LABEL,
  ROOBI_SECTIONS.BIOMETRICS_VITALS.LABEL,
  ROOBI_SECTIONS.CLINIC_TEAM.LABEL,
  ROOBI_SECTIONS.BIOMETRICS_BAC.LABEL,
  ROOBI_SECTIONS.BIOMETRICS_HEIGHT_WEIGHT.LABEL,
  ROOBI_SECTIONS.WARNINGS.LABEL,
  ROOBI_SECTIONS.DIETARY_RESTRICTIONS.LABEL,
  ROOBI_SECTIONS.DEMOGRAPHICS_BRIEF.LABEL,
  ROOBI_SECTIONS.CIWA_PREMIUM.LABEL,
  ROOBI_SECTIONS.COWS_PREMIUM.LABEL,
  ROOBI_SECTIONS.PAIN_SCALE_PREMIUM.LABEL,
  ROOBI_SECTIONS.PTSD_CHECKLIST.LABEL,
  ROOBI_SECTIONS.MCLEAN_MSI_BPD.LABEL,
  ROOBI_SECTIONS.INTOLERANCE_OF_UNCERTAINTY.LABEL,
  ROOBI_SECTIONS.BECK_ANXIETY.LABEL,
  ROOBI_SECTIONS.OUTCOME_QUESTIONNAIRE.LABEL,
  ROOBI_SECTIONS.PEARLS_SCREENER_CHILD.LABEL,
  ROOBI_SECTIONS.PEARLS_SCREENER_TEEN.LABEL,
  ROOBI_SECTIONS.FAMILY_ASSESSMENT_DEVICE.LABEL,
  ROOBI_SECTIONS.PEARLS_SCREENER_TEEN_SELF.LABEL,
];

// These are roobi sections that will be blocked from appearing in the form builder.
export const ROOBI_BLOCK_LIST = [
  ROOBI_SECTIONS.ANXIETY_CAD7.LABEL,
  'roobi_jelly_beans',
  ROOBI_SECTIONS.RELEASE_OF_INFORMATION.LABEL, // release of information v1
  ROOBI_SECTIONS.CIWA.LABEL,
  ROOBI_SECTIONS.CWS.LABEL,
  ROOBI_SECTIONS.PAIN_SCALE_DULL.LABEL, // block the dull pain scale roobi (in favor of the new premium pain scale)
];

export type SectionMode = 'input' | 'view_only' | 'preview';

export const PATIENT_ELIGIBLE_ROOBI_ALLOW_LIST = [
  ROOBI_SECTIONS.DEMOGRAPHICS.LABEL,
  ROOBI_SECTIONS.DEMOGRAPHICS_BRIEF.LABEL,
  ROOBI_SECTIONS.CONTACT_POINTS.LABEL,
  ROOBI_SECTIONS.DEMOGRAPHIC_ADDRESS.LABEL,
  ROOBI_SECTIONS.CLIENT_CONTACTS.LABEL, // as view only
  // ROOBI_SECTIONS.RELEASE_OF_INFORMATION.LABEL, // Note this roobi is only allowed in previously submitted forms
  ROOBI_SECTIONS.RELEASE_OF_INFORMATION_V2.LABEL, // as view only
  ROOBI_SECTIONS.NAME_DOB.LABEL,
  ROOBI_SECTIONS.SSN.LABEL,
  ROOBI_SECTIONS.SUBSTANCE_USE_HISTORY.LABEL,
  ROOBI_SECTIONS.TREATMENT_HISTORY.LABEL,
  ROOBI_SECTIONS.MEDICATION.LABEL,
  ROOBI_SECTIONS.ALLERGIES.LABEL,
  ROOBI_SECTIONS.FUNDING_SOURCE.LABEL,
  ROOBI_SECTIONS.TREATMENT_PLAN.LABEL, // as view only
  ROOBI_SECTIONS.TREATMENT_PLAN_REFERENCE.LABEL, // as view only
  ROOBI_SECTIONS.DIAGNOSIS.LABEL, // as view only
  ROOBI_SECTIONS.PATIENT_INSURANCE.LABEL, // as view only
  ROOBI_SECTIONS.MCLEAN_MSI_BPD.LABEL,
  ROOBI_SECTIONS.PTSD_CHECKLIST.LABEL,
  ROOBI_SECTIONS.INTOLERANCE_OF_UNCERTAINTY.LABEL,
  ROOBI_SECTIONS.BECK_ANXIETY.LABEL,
  ROOBI_SECTIONS.OUTCOME_QUESTIONNAIRE.LABEL,
  ROOBI_SECTIONS.PEARLS_SCREENER_CHILD.LABEL,
  ROOBI_SECTIONS.PEARLS_SCREENER_TEEN.LABEL,
  ROOBI_SECTIONS.FAMILY_ASSESSMENT_DEVICE.LABEL,
  ROOBI_SECTIONS.PEARLS_SCREENER_TEEN_SELF.LABEL,
];

export type IsDeprecated = {
  isDeprecated: boolean;
  userFacingMessage: string;
};

export const isDeprecatedDullRoobiSection = (
  section: Forms.SectionDefinitionBase,
): IsDeprecated | null => {
  switch (section.label) {
    case ROOBI_SECTIONS.PAIN_SCALE_DULL.LABEL:
      return {
        isDeprecated: true,
        userFacingMessage:
          "This form element has been upgraded. Any changes made to the pain scale below will be reflected in this form, but will NOT be saved to the client's biometrics. To make changes or add new results, please do so from the Biometrics tab on the client's chart, or in a new form within the upgraded pain scale form element.",
      };
    default:
      return null;
  }
};
