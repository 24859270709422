// @ts-strict-ignore
import { useEffect, useState } from 'react';
import { Box, Text } from 'grommet';
import { Checkbox, CheckboxSelected, FormClose } from 'grommet-icons';
import _ from 'lodash';
import { Observable } from 'rxjs';

import useMobileDevice from 'context/mobileDevice/useMobileDevice';
import { useErrorState, useLoadingState } from '../../hooks';
import { COLORS } from '../../styles/colors';
import ErrorText from '../errors/ErrorText';
import DropdownComponent from './DropdownComponent';
import { useAsyncSearch } from './useAsyncSearch';

interface MCDropdownProps {
  width?: string;
  choices?: (string | object)[];
  selections: (string | object)[];
  promptText: string;
  label: string;
  labelKey?: string;
  dropdownLabelRenderer?: (value: string | object) => JSX.Element;
  valueKey?: string;
  onUpdate?: (values: (string | object)[], field?: string) => void;
  fieldName: string;
  onSearch?: (searchText: string) => void;
  asyncSearch?: (searchText: string) => Observable<(string | object)[]>;
  placeholder?: string;
  requirementMarker?: string;
  tooltipText?: string;
  minimumSearchCharacters?: number;
  viewOnly?: boolean;
  disabled?: boolean;
  optionsBelow?: boolean; // default will be to display the selected options in line with the dropdown, if this prop is true, they will display below
  showSelectionsInline?: boolean; // whether to show selected values as tags/chips inside dropdown view (vs outside)
  hideSelectionTags?: boolean;
  hideSelectAll?: boolean;
  dropdownDataCy?: string;
  choiceTextDataCy?: string;
  'data-testid'?: string;
  isParentSearching?: boolean;
  shouldUseParentSearch?: boolean;
  inputLabel?: React.ReactNode;
}

const SELECT_ALL_CHOICE = 'SELECT_ALL';

/**
 * @deprecated in favor of Dropdown
 */
export const MultiChoiceDropdownComponent = (props: MCDropdownProps) => {
  const {
    choices = [],
    selections,
    promptText,
    onUpdate,
    viewOnly,
    disabled,
    label,
    fieldName,
    asyncSearch,
    minimumSearchCharacters = 1,
    dropdownDataCy,
    choiceTextDataCy,
    optionsBelow,
    hideSelectionTags,
    placeholder,
    labelKey = 'label',
    dropdownLabelRenderer,
    valueKey = 'value',
    showSelectionsInline,
    hideSelectAll,
    isParentSearching,
    shouldUseParentSearch,
    inputLabel,
  } = props;

  const [searching, setSearching] = useState<boolean>(false);
  const [searchQuery, setSearchQuery] = useState<string>('');

  const selectAllChoice = { [valueKey]: SELECT_ALL_CHOICE, [labelKey]: 'Select All' };
  const getDefaultChoices = () =>
    choices.length > 0 && !hideSelectAll ? [selectAllChoice, ...choices] : choices;
  const [currentChoices, setCurrentChoices] = useState<(string | object)[]>(getDefaultChoices());
  // The actual selections a user has made, excluding the "select all" option
  const [userSelections, setUserSelections] = useState<(string | object)[]>(selections || []);
  // State that tracks the values rendered for/in the dropdown itself
  const getDefaultValues = () =>
    choices.length === selections.length && choices.length > 0 && !hideSelectAll
      ? [selectAllChoice, ...selections]
      : selections;
  const [dropdownValues, setDropdownValues] = useState<(string | object)[]>(getDefaultValues());

  const { loading, setLoading } = useLoadingState(false);
  const { errors, clearErrors, addError } = useErrorState();
  const { isMobile } = useMobileDevice();

  const { setupSearchObservable, triggerSearch } = useAsyncSearch({
    setOptions: setCurrentChoices,
    setLoading,
    addError,
    clearErrors,
    showNoOptionFound: false,
    onAsyncSearch: asyncSearch,
    minimumSearchCharacters,
  });

  useEffect(() => {
    const sub = setupSearchObservable();

    return () => sub.unsubscribe();
  }, []);

  useEffect(() => {
    if (asyncSearch) {
      triggerSearch(searchQuery);
    } else {
      const filteredChoices = choices.filter((choice: string | object) => {
        const label = getLabel(choice);
        return label.toLowerCase().indexOf(searchQuery.toLowerCase()) >= 0;
      });

      const sortedChoices = filteredChoices.sort((a: string | object, b: string | object) => {
        const aLabel = getLabel(a);
        const bLabel = getLabel(b);
        return aLabel.localeCompare(bLabel);
      });

      setSearching(false);
      setCurrentChoices(
        sortedChoices.length > 0 && !hideSelectAll
          ? [selectAllChoice, ...sortedChoices]
          : sortedChoices,
      );
    }
  }, [searching, searchQuery]);

  useEffect(() => {
    setCurrentChoices(getDefaultChoices());
    setDropdownValues(getDefaultValues());
  }, [props.choices]);

  useEffect(() => {
    setUserSelections(selections);
    setDropdownValues(getDefaultValues());
  }, [props.selections]);

  const getLabel = (choice: string | object): string => {
    if (!choice) {
      return '';
    }
    if (typeof choice === 'string') {
      return choice;
    }
    if (typeof choice === 'object' && labelKey !== null) {
      return choice[labelKey] as string;
    }
    return '';
  };

  const getValue = (choice: string | object): string => {
    if (!choice) {
      return '';
    }
    if (typeof choice === 'string') {
      return choice;
    }
    if (typeof choice === 'object' && valueKey !== null) {
      return choice[valueKey] as string;
    }
    return '';
  };

  const updateSelection = (selected: string | object) => {
    const selectedValue = getValue(selected);

    let updatedSelections = [];

    if (selectedValue === SELECT_ALL_CHOICE) {
      const allAreSelected = userSelections.length === choices.length;
      if (!allAreSelected) {
        updatedSelections = choices;
      }
    } else {
      const isSelected = userSelections.find((value) => {
        return _.isEqual(getValue(value), selectedValue);
      });

      if (isSelected) {
        updatedSelections = userSelections.filter((value) => {
          return !_.isEqual(getValue(value), selectedValue);
        });
      } else {
        updatedSelections = [...userSelections, selected];
      }
    }

    setUserSelections(updatedSelections);
    if (updatedSelections.length === choices.length && !hideSelectAll) {
      setDropdownValues([selectAllChoice, ...updatedSelections]);
    } else {
      setDropdownValues(updatedSelections);
    }
    setSearchQuery('');
    if (props.onSearch) {
      props.onSearch('');
    }
    onUpdate(updatedSelections, fieldName);
  };

  const onRemoveSelection = (selection: string | object) => {
    if (!props.disabled && !props.viewOnly) {
      updateSelection(selection);
    }
  };

  const renderChoice = (choice: string | object) => {
    const label = getLabel(choice);
    const value = getValue(choice);
    return (
      <Box
        key={value}
        onClick={(event: React.MouseEvent<HTMLElement>) => {
          event.preventDefault();
          event.stopPropagation();
          onRemoveSelection(choice);
        }}
        onFocus={(event) => event.stopPropagation()}
        border={{ size: '1px', color: COLORS.rittenBlue400, style: 'dashed' }}
        direction="row"
        align="center"
        background={disabled || viewOnly ? COLORS.darkGray200 : COLORS.white}
        style={{
          opacity: 1,
          cursor: disabled || viewOnly ? 'default' : 'pointer',
          borderRadius: '4px',
        }}
        pad="8px"
      >
        <Box direction="row" align="center" pad={{ right: '10px' }}>
          <Text data-cy={choiceTextDataCy} color={COLORS.darkGray600} size="16px">
            {label}
          </Text>
        </Box>
        <Box data-cy="form-close-button">
          <FormClose size="16px" color={COLORS.red400} />
        </Box>
      </Box>
    );
  };

  const renderValues = () => {
    return (
      <Box direction="row" fill wrap>
        {userSelections?.length > 0 &&
          userSelections.map((choice, idx) => {
            return (
              <Box
                key={idx}
                pad={
                  props.optionsBelow
                    ? {
                        right: '20px',
                        bottom: '0px',
                        top: '25px',
                      }
                    : {
                        left: '20px',
                        bottom: '0px',
                        top: '25px',
                      }
                }
              >
                {renderChoice(choice)}
              </Box>
            );
          })}
      </Box>
    );
  };

  const renderValueLabel = () => {
    const textColor = userSelections?.length === 0 ? COLORS.darkGray400 : COLORS.darkGray600;

    const label = getLabel(userSelections[0]);
    return (
      <Box
        height="38px"
        round="2px"
        flex={{ grow: 0, shrink: 0 }}
        pad="11px"
        direction="row"
        align="center"
      >
        {/* we can truncate this text because we are rendering the selected values elsewhere */}
        <Text size="16px" color={textColor} truncate>
          {userSelections?.length === 0 && (placeholder || 'Select...')}
          {userSelections?.length === 1 && label}
          {userSelections?.length > 1 && `${label} + ${userSelections.length - 1}`}
        </Text>
      </Box>
    );
  };

  const renderSelectionsInline = () => {
    return (
      <Box direction="row" wrap pad="xsmall" gap="xsmall">
        {userSelections?.length > 0 ? (
          userSelections.map((choice) => {
            const label = getLabel(choice);
            const value = getValue(choice);
            return (
              <Box
                key={value}
                onClick={(event: React.MouseEvent<HTMLElement>) => {
                  event.preventDefault();
                  event.stopPropagation();
                  onRemoveSelection(choice);
                }}
                data-testid={`mc-dropdown-inline-selection-${label.replace(/\s/g, '')}`}
                onFocus={(event) => event.stopPropagation()}
                border={{ size: '1px', color: COLORS.rittenBlue400 }}
                direction="row"
                align="center"
                height="27px"
                background={COLORS.rittenBlue200}
                style={{ opacity: disabled || viewOnly ? 0.5 : 1, borderRadius: '4px' }}
                pad="4px"
              >
                <Box direction="row" align="center" pad={{ right: '6px' }}>
                  <Text
                    data-cy={choiceTextDataCy}
                    color={COLORS.darkGray600}
                    weight="bold"
                    size="11px"
                  >
                    {label}
                  </Text>
                </Box>
                <Box data-cy="form-close-button">
                  <FormClose size="16px" color={COLORS.rittenBlue400} />
                </Box>
              </Box>
            );
          })
        ) : (
          <Box pad="xsmall" height="27px">
            <Text color={COLORS.darkGray400} size="14px">
              {placeholder || 'Select...'}
            </Text>
          </Box>
        )}
      </Box>
    );
  };

  const emptySearchMessage = (() => {
    if (shouldUseParentSearch) {
      return !isParentSearching && choices.length === 0 ? 'No Matching Options' : 'Searching...';
    }
    return 'No Matching Options';
  })();

  const defaultWidth = isMobile ? '100%' : '300px';

  return (
    <Box gap="10px" flex={{ grow: 1 }}>
      {errors?.length > 0 && <ErrorText show>{errors[0]}</ErrorText>}

      <Box
        pad={{
          bottom: optionsBelow && !hideSelectionTags && selections.length > 0 ? '10px' : '0px',
        }}
        direction={optionsBelow ? 'column' : 'row'}
        fill
      >
        <DropdownComponent
          label={promptText}
          requirementMarker={props.requirementMarker}
          tooltipText={props.tooltipText}
          inputLabel={inputLabel}
          data-cy={dropdownDataCy}
          width={props.width ?? defaultWidth}
          ariaLabel={props.label ?? ''}
          data-testid={props['data-testid']}
          dropdownProps={{
            name: label,
            closeOnChange: false,
            multiple: true,
            placeholder: placeholder ?? 'Select',
            value: showSelectionsInline ? renderSelectionsInline() : renderValues(),
            valueLabel: !showSelectionsInline && renderValueLabel(),
            labelKey,
            valueKey,
            options: currentChoices,
            searchPlaceholder: 'Search Options',
            emptySearchMessage,
            onSearch: (query) => {
              setSearching(true);
              setSearchQuery(query);
              if (props.onSearch) {
                props.onSearch(query);
              }
            },
            onChange: ({ option }) => {
              if (option != null) {
                updateSelection(option);
              } else {
                // Without this else, grommet just clears the choices under the hood :/
                setSearchQuery('');
              }
            },
            children: (choice) => {
              const label = getLabel(choice);
              const value = getValue(choice);
              if (loading) {
                return <Box>Searching...</Box>;
              }

              const isSelected = dropdownValues.find((selected) => {
                const selectedValue = getValue(selected);
                return _.isEqual(selectedValue, value);
              });

              if (isSelected) {
                return (
                  <Box direction="row" align="center" pad={{ horizontal: '8px' }}>
                    <CheckboxSelected size="16px" />
                    <Box pad={{ horizontal: '8px' }}>
                      {dropdownLabelRenderer ? dropdownLabelRenderer(choice) : <Text>{label}</Text>}
                    </Box>
                  </Box>
                );
              }

              return (
                <Box direction="row" align="center" pad={{ horizontal: '8px' }}>
                  <Checkbox size="16px" />
                  <Box pad={{ horizontal: '8px' }}>
                    {dropdownLabelRenderer ? dropdownLabelRenderer(choice) : <Text>{label}</Text>}
                  </Box>
                </Box>
              );
            },
            disabled: disabled || viewOnly,
          }}
        />
        {!hideSelectionTags && !showSelectionsInline && renderValues()}
      </Box>
    </Box>
  );
};

export default MultiChoiceDropdownComponent;
