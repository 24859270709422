// Generated by ts-to-zod
import { z } from 'zod';

import { apiTimeOfDayRangeSchema } from './api.zod';
import { notificationsDosespotNotificationCountsSchema } from './notifications.zod';
import { patientPatientNameSchema } from './patient.zod';
import { securityPolicySchema } from './security.zod';
import { signaturesUserSignatureSchema } from './signatures.zod';

export const usersInfoSchema = z.object({
  id: z.string(),
  fullName: z.string(),
  first: z.string(),
  middle: z.string(),
  last: z.string(),
});

export const usersUserSchema = z.lazy(() =>
  z.object({
    id: z.string(),
    email: z.string(),
    first: z.string(),
    chosen: z.string().optional(),
    middle: z.string(),
    last: z.string(),
    dosespotClinicianId: z.string(),
    dosespotProxyClinicianId: z.string(),
    zoomUserId: z.string().optional(),
    patientId: z.string(),
    createdAt: z.date().optional(),
    signatureDisplayText: z.string(),
    npi: z.string().optional(),
    stateLicenseNumber: z.string().optional(),
  }),
);

export const usersAbbreviatedUserSchema = z.lazy(() =>
  z.object({
    id: z.string(),
    email: z.string(),
    first: z.string(),
    middle: z.string(),
    last: z.string(),
  }),
);

export const usersActorSchema = z.lazy(() =>
  z.object({
    first: z.string(),
    middle: z.string(),
    last: z.string(),
    userId: z.string(),
  }),
);

export const usersRoleSchema = z.object({
  id: z.string(),
  name: z.string(),
  createdAt: z.date(),
});

export const usersFullUserSchema = z
  .object({
    roles: z.array(usersRoleSchema),
    pagePolicies: z.array(securityPolicySchema),
    accessPolicies: z.array(securityPolicySchema),
    dosespotNotificationCounts: notificationsDosespotNotificationCountsSchema,
    isSuper: z.boolean(),
    signature: signaturesUserSignatureSchema,
    sendDailyCensus: z.boolean(),
    isOrderingProvider: z.boolean(),
  })
  .extend(usersUserSchema.schema.shape);

export const usersRolePostBodySchema = usersRoleSchema.pick({ name: true, id: true });

export const usersPortalAuthorizationSchema = z.lazy(() =>
  z.object({
    patient: patientPatientNameSchema,
    canAddPaymentMethods: z.boolean(),
  }),
);

export const usersUpdateUserPostBodySchema = z.object({
  first: z.string(),
  middle: z.string(),
  last: z.string(),
  dosespotClinicianId: z.string(),
  dosespotProxyClinicianId: z.string(),
});

export const usersPatchUserPostBodySchema = z.object({
  first: z.string(),
  middle: z.string().optional(),
  last: z.string(),
  dosespotClinicianId: z.string().optional(),
  dosespotProxyClinicianId: z.string().optional(),
  signatureDisplayText: z.string().optional(),
  sendDailyCensus: z.boolean().optional(),
  isOrderingProvider: z.boolean().optional(),
  npi: z.string().optional(),
  stateLicenseNumber: z.string().optional(),
});

export const usersCreateUserPostBodySchema = z.object({
  firstName: z.string().optional(),
  lastName: z.string().optional(),
  email: z.string(),
  patientId: z.string().optional(),
});

export const usersGetClinicTeamsQuerySchema = z.object({
  loadUserIds: z.boolean().optional(),
  loadPatientIds: z.boolean().optional(),
});

export const usersPostUserResponseSchema = z.object({
  id: z.string(),
  newUserPassword: z.string(),
});

export const usersConnectZoomUserSchema = z.object({
  email: z.string(),
});

export const usersZoomUserResponseSchema = z.object({
  id: z.string(),
});

export const usersWorkingDaySchema = z.lazy(() =>
  z.object({
    day: z.string(),
    isAvailable: z.boolean(),
    timeSlots: z.array(apiTimeOfDayRangeSchema),
    createdAt: z.string(),
  }),
);

export const usersWorkingHoursSchema = z.lazy(() =>
  z.object({
    timezone: z.string(),
    workingDays: z.array(usersWorkingDaySchema),
  }),
);

export const usersPostWorkingDaySchema = z.lazy(() =>
  usersWorkingDaySchema.schema.pick({
    day: true,
    isAvailable: true,
    timeSlots: true,
  }),
);

export const usersPostWorkingHoursRequestSchema = z.lazy(() =>
  z.object({
    timezone: z.string(),
    workingDays: z.array(usersPostWorkingDaySchema),
  }),
);

export const usersNotificationEventTypeSchema = z.union([
  z.literal('lead_app_submitted'),
  z.literal('lead_app_updated'),
  z.literal('patient_adt_updated'),
]);

export const usersNotificationChannelSchema = z.literal('email');

export const usersNotificationSubscriptionSchema = z.object({
  id: z.string(),
  eventType: usersNotificationEventTypeSchema,
  channels: z.array(usersNotificationChannelSchema),
  inactive: z.boolean().optional(),
});

export const usersCredentialSchema = z.object({
  id: z.string(),
  name: z.string(),
  inUse: z.boolean(),
});

export const usersCredentialPutBodySchema = z.object({
  id: z.string(),
  name: z.string(),
});
