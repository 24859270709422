// Generated by ts-to-zod
import { z } from 'zod';

export const notificationsUserNotificationStatusSchema = z.union([
  z.literal('read'),
  z.literal('unread'),
]);

export const notificationsUserNotificationObjectTypeSchema = z.union([
  z.literal('encounter'),
  z.literal('order'),
  z.literal('form'),
  z.literal('group_encounter'),
]);

export const notificationsUserNotificationConfigurationStatusSchema = z.union([
  z.literal('Active'),
  z.literal('Inactive'),
]);

export const notificationsClinicNotificationTypeSchema = z.union([
  z.literal('Form Signatures Required'),
  z.literal('Encounter Missing Forms'),
  z.literal('Orders Action Required'),
  z.literal('Group Encounter Signatures Required'),
]);

export const notificationsQueryUserNotificationsSchema = z.object({
  userId: z.string(),
  notificationsOnly: z.boolean(),
  searchText: z.string(),
  type: z.array(notificationsClinicNotificationTypeSchema),
  status: z.array(notificationsUserNotificationStatusSchema).optional(),
  startTime: z.date().optional(),
  endTime: z.date().optional(),
  limit: z.number(),
  offset: z.number(),
  includeDosespotCount: z.boolean().optional(),
});

export const notificationsUpdateUserNotificationParamsSchema = z.object({
  id: z.string(),
  status: notificationsUserNotificationStatusSchema,
});

export const notificationsUpdateClinicNotificationSettingParamsSchema = z.object({
  status: notificationsUserNotificationConfigurationStatusSchema,
  roleIds: z.array(z.string()).optional(),
  notifyAfterMinutes: z.number().optional(),
  includePrimaryClinician: z.boolean().optional(),
  includeCreator: z.boolean().optional(),
});

export const notificationsUserNotificationSchema = z.object({
  id: z.string(),
  patientId: z.string(),
  notificationTitle: z.string(),
  notificationType: notificationsClinicNotificationTypeSchema,
  encounterId: z.string(),
  objectType: notificationsUserNotificationObjectTypeSchema,
  objectId: z.string(),
  status: notificationsUserNotificationStatusSchema,
  createdAt: z.date(),
});

export const notificationsDosespotNotificationCountsSchema = z.object({
  pendingPrescriptionCount: z.number(),
  transmissionErrorsCount: z.number(),
  refillRequestCount: z.number(),
  changeRequestCount: z.number(),
});

export const notificationsGetUserNotificationResultSchema = z.object({
  notifications: z.array(notificationsUserNotificationSchema),
  doseSpotCounts: notificationsDosespotNotificationCountsSchema,
  hasDosespotEnabled: z.boolean(),
});
