// @ts-strict-ignore
import { useState } from 'react';
import { isSameMonth, isToday as isTodaysDate, isWeekend } from 'date-fns';
import { Box, RenderProps, Text } from 'grommet';

import { COLORS } from '../../styles/colors';

interface CalendarDayProps {
  onSelect: (date: string) => void;
  calendarRenderDayProps: RenderProps;
  calendarDayTextColor?: string;
  calendarSelectedDayBackgroundColor?: string;
  calendarSelectedDayBackgroundRadius?: string;
  calendarSelectedDayTextColor?: string;
  calendarAdjacentMonthTextColor?: string;
  calendarCurrentDayBackgroundColor?: string;
  calendarWeekendDayTextColor?: string;
  calendarDayHoverBackgroundColor?: string;
  currentDateOnCalendarHeader?: Date; // Note that this is only relevant if the Grommet Calendar using it has `showAdjacentDays={true}`
  calendarDayWidth?: string;
}

const CalendarDay = (props: CalendarDayProps) => {
  const {
    onSelect,
    calendarRenderDayProps,
    currentDateOnCalendarHeader,
    calendarDayTextColor = COLORS.darkGray500,
    calendarSelectedDayBackgroundColor = COLORS.black,
    calendarSelectedDayTextColor = COLORS.white,
    calendarAdjacentMonthTextColor = COLORS.darkGray400,
    calendarCurrentDayBackgroundColor = COLORS.darkGray200,
    calendarSelectedDayBackgroundRadius = '6px',
    calendarWeekendDayTextColor = COLORS.darkGray500,
    calendarDayHoverBackgroundColor = COLORS.darkGray200,
    calendarDayWidth = '100%',
  } = props;
  const { date, day, isSelected } = calendarRenderDayProps;

  const [isHover, setIsHover] = useState<boolean>(false);

  const isWeekendDate = isWeekend(date);
  const isToday = isTodaysDate(date);
  const isDateInAdjacentMonth =
    currentDateOnCalendarHeader && !isSameMonth(date, currentDateOnCalendarHeader);

  let backgroundColor = null;
  let fontColor = calendarDayTextColor;
  let weight = 400;
  const hoverFontColor = COLORS.rittenBlue400;
  if (isSelected) {
    backgroundColor = calendarSelectedDayBackgroundColor;
    fontColor = calendarSelectedDayTextColor;
    weight = 700;
  } else if (isDateInAdjacentMonth) {
    fontColor = calendarAdjacentMonthTextColor;
  } else if (isToday) {
    backgroundColor = calendarCurrentDayBackgroundColor;
  } else if (isWeekendDate) {
    fontColor = calendarWeekendDayTextColor;
  }

  return (
    <Box
      background={backgroundColor}
      width={calendarDayWidth}
      height="36px"
      round={calendarSelectedDayBackgroundRadius}
      direction="row"
      justify="center"
      align="center"
      onClick={() => onSelect(date.toISOString())}
      hoverIndicator={{
        background: calendarDayHoverBackgroundColor,
      }}
      onMouseOver={() => setIsHover(true)}
      onMouseOut={() => setIsHover(false)}
    >
      <Text color={isHover ? hoverFontColor : fontColor} size="15px" weight={weight}>
        {day}
      </Text>
    </Box>
  );
};

export default CalendarDay;
