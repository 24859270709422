import { maybeTransformHEICToJPGFile } from 'features/utils/fileUtils';
import RittenClient from './RittenClient';

const getRittenShareHeaders = (token) => ({
  'X-Ritten-Share-Token': token,
});

export default class LeadApplicationClient extends RittenClient {
  getLeadAppDefinition = async (): Promise<CRM.LeadAppDefinition> => {
    const { data = [] } = await this.get<CRM.LeadAppDefinition[]>(
      `/api/public/crm/lead-app/definitions`,
    );
    return data[0];
  };

  createDraftLeadAppInstance = async (
    definitionId: string,
    version: number,
  ): Promise<API.IDResponse> => {
    const { data } = await this.post<API.IDResponse>(`/api/public/crm/lead-app/instances/new`, {
      definitionId,
      version,
    });
    return data;
  };

  postLeadAppFile = async (
    leadAppInstanceId: string,
    file: File,
    type: string,
    token?: string,
  ): Promise<{ documentId: string }> => {
    const fileBlob = await maybeTransformHEICToJPGFile(file);
    const formData = new FormData();
    formData.append('file', fileBlob);
    formData.append('type', type);

    const { data } = await this.post<{ documentId: string }>(
      `/api/public/crm/lead-app/instances/${leadAppInstanceId}/files`,
      formData,
      token
        ? {
            headers: getRittenShareHeaders(token),
          }
        : undefined,
    );
    return data;
  };

  getLeadAppInstance = async (
    leadAppInstanceId: string,
    token: string,
  ): Promise<CRM.LeadAppInstance> => {
    const res = await this.get<CRM.LeadAppInstance>(
      `/api/public/crm/lead-app/instances/${leadAppInstanceId}`,
      {
        headers: getRittenShareHeaders(token),
      },
    );
    return res.data;
  };

  putLeadAppInstance = async (
    leadAppInstanceId: string,
    leadAppSections: CRM.LeadAppSection[],
    token?: string,
  ): Promise<void> => {
    await this.put(
      `/api/public/crm/lead-app/instances/${leadAppInstanceId}`,
      leadAppSections,
      token
        ? {
            headers: getRittenShareHeaders(token),
          }
        : undefined,
    );
  };
}
