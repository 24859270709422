import { Box, Footer, Grid, Header, Heading, Layer, LayerExtendedProps } from 'grommet';
import { BorderType, HeightType, PadType, WidthType } from 'grommet/utils';
import { Close } from 'grommet-icons/icons/Close';

import { COLORS } from 'styles/colors';
import { isAutocompleteSelection } from 'utils/autocompleteUtils';
import IconButton from '../../buttons/IconButton';

type BaseModalProps = React.PropsWithChildren<{
  isOpen?: boolean;
  onClose?: () => void;
  header: React.ReactNode | string;
  footer?: React.ReactNode;
  height?: HeightType;
  width?: WidthType;
  overflow?: string;
  noContentFormat?: boolean;
  border?: BorderType;
  shouldPreventClickOutside?: boolean;
  layerProps?: LayerExtendedProps;
  footerPadding?: PadType;
  headerPadding?: PadType;
  contentPadding?: PadType;
  'data-cy'?: string;
  'data-testid'?: string; // use this not data-cy
}>;

export const BaseModal: React.FC<BaseModalProps> = (props: BaseModalProps) => {
  const defaultHeight = 'auto';
  const defaultMaxHeight = '90vh';
  const modalHeight: HeightType = { height: defaultHeight, max: defaultMaxHeight };

  const {
    header,
    footer,
    isOpen = false,
    border,
    shouldPreventClickOutside,
    overflow,
    noContentFormat,
    layerProps = {},
    footerPadding = { top: 'small', horizontal: 'medium', bottom: 'medium' },
    headerPadding = { horizontal: '24px', top: '24px', bottom: 'xsmall' },
    contentPadding = { horizontal: '24px' },
  } = props;

  const onClose = () => {
    if (props.onClose) {
      props.onClose();
    }
  };

  // Could just change all uses of shouldPreventClickOutside to do nothing and get rid of that prop
  const handleClickOutside = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    if (shouldPreventClickOutside) {
      return;
    }
    const target = e.target as HTMLDivElement | HTMLSpanElement;
    if (isAutocompleteSelection(target)) {
      e.stopPropagation();
    } else {
      onClose();
    }
  };

  if (!isOpen) {
    return null;
  }

  if (props.height) {
    if (typeof props.height === 'object') {
      if (props.height.max) {
        modalHeight.max = props.height.max;
      }
      if (props.height.height) {
        modalHeight.height = props.height.height;
      }
      if (props.height.min) {
        modalHeight.min = props.height.min;
      }
    } else if (typeof props.height === 'string') {
      modalHeight.height = props.height;
    }
  }

  return (
    <Layer
      modal
      onClickOutside={handleClickOutside}
      onEsc={onClose}
      data-cy={props['data-cy']}
      data-testid={props['data-testid'] ?? 'ritten-modal'}
      {...layerProps}
    >
      <Grid
        border={border}
        // height prop on grid isn't working if height and max are both set
        style={{
          height: modalHeight.height,
          maxHeight: modalHeight.max,
          minHeight: modalHeight.min,
          background: COLORS.white, // grommet's dark/light color detection will otherwise change the background color if the modal is on top of a dark bg
          borderRadius: modalHeight.height === '100%' ? 'none' : '8px',
          boxShadow: 'none',
        }}
        width={props.width ?? 'auto'}
        columns={['flex']}
        rows={['min-content', '1fr', 'min-content']}
        areas={[['header'], ['content'], ['footer']]}
        gap="xsmall"
      >
        <Header
          gridArea="header"
          width="full"
          justify="between"
          direction="row"
          pad={headerPadding}
          gap="24px"
        >
          {typeof header !== 'string' ? (
            header
          ) : (
            <Heading level={4} margin="none">
              {header}
            </Heading>
          )}

          {props.onClose && (
            <Box direction="column" alignSelf="start" pad={{ top: 'xxsmall' }}>
              <IconButton color="plain" icon={<Close color="text" />} onClick={onClose} />
            </Box>
          )}
        </Header>

        {noContentFormat ? (
          <Box gridArea="content" fill pad={contentPadding}>
            {props.children}
          </Box>
        ) : (
          <Box
            gridArea="content"
            height={{ min: 'unset' }}
            overflow={overflow ?? 'auto'}
            background={COLORS.white} // grommet's dark/light color detection will otherwise change the background color if the modal is on top of a dark bg
          >
            <Box flex="grow" pad={contentPadding}>
              {props.children}
            </Box>
          </Box>
        )}

        {footer && (
          <Footer gridArea="footer" background="none" pad={footerPadding}>
            {footer}
          </Footer>
        )}
      </Grid>
    </Layer>
  );
};

export default BaseModal;
