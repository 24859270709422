import { SortOption } from 'common/dropdowns/SortDropdown';

export const TASKS_FILTERS_LOCAL_STORAGE = {
  sort: 'tasksFilterSortOption',
  status: 'tasksFilterStatus',
  categories: 'tasksFilterCategories',
  dueDateStart: 'tasksFilterDueDateStart',
  dueDateEnd: 'tasksFilterDueDateEnd',
  createDateStart: 'tasksFilterCreateDateStart',
  createDateEnd: 'tasksFilterCreateDateEnd',
  priorities: 'tasksFilterPriorities',
  assigneeIds: 'tasksFilterAssigneeIds',
  watcherIds: 'tasksFilterWatcherIds',
  creatorIds: 'tasksFilterCreatorIds',
};

export const taskPriorities: Tasks.TaskPriority[] = ['Low', 'Medium', 'High'];

export const taskStatuses: Tasks.TaskStatus[] = ['New', 'Working', "Won't Do", 'Complete'];

export const taskCategories: Tasks.TaskCategory[] = [
  'Assigned To You',
  'Created By You',
  'Watching',
];

export const TASK_SORT_OPTIONS: SortOption[] = [
  {
    label: 'Created Date (Newest)',
    value: 'created_newest',
  },
  {
    label: 'Created Date (Oldest)',
    value: 'created_oldest',
  },
  {
    label: 'Activity (Newest)',
    value: 'activity_newest',
  },
  {
    label: 'Activity (Oldest)',
    value: 'activity_oldest',
  },
  {
    label: 'Due Date (Soonest)',
    value: 'due_earliest',
  },
  {
    label: 'Due Date (Furthest)',
    value: 'due_latest',
  },
  {
    label: 'Priority (Highest)',
    value: 'priority_highest',
  },
  {
    label: 'Priority (Lowest)',
    value: 'priority_lowest',
  },
];
