export const isInt = (str: string) => {
  return /^\+?\d+$/.test(str);
};

export const isFloat = (str: string) => {
  return str.match(/^[+-]?([0-9]+([.][0-9]*)?|[.][0-9]+)$/);
};

export const isEmail = (str: string) => {
  return /^\S+@\S+\.\S+$/.test(str);
};

// Regex to match valid phone numbers
export const isPhone = /^(\+\d{1,2}\s)?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/;

export const isNumberString = (str: string) => /^\d+$/.test(str);
