import { isEmpty } from './stringUtils';

export const getAge = (dob?: string) => {
  if (!dob || dob === '') {
    return '-';
  }
  const today: Date = new Date();
  const patientdob: Date = new Date(dob);
  let age = today.getFullYear() - patientdob.getFullYear();
  const m = today.getMonth() - patientdob.getMonth();
  if (m < 0 || (m === 0 && today.getDate() < patientdob.getDate())) {
    age -= 1;
  }
  return age;
};

export const getFullName = (
  name?: {
    first?: string;
    middle?: string;
    last?: string;
    chosenName?: string; // patient name
    chosen?: string; // user
    email?: string;
  } | null,
) => {
  const { first, middle, last, chosenName, chosen, email } = name || {};
  const namePieces = [
    first,
    middle,
    !isEmpty(chosenName) ? `"${chosenName}"` : null,
    !isEmpty(chosen) ? `"${chosen}"` : null,
    last,
  ];
  const joinedName = namePieces
    .filter((p) => !isEmpty(p))
    .join(' ')
    .trim();
  if (!isEmpty(joinedName)) {
    return joinedName;
  }
  return email?.split('@')?.[0] ?? '';
};

export const getFirstInitialPlusLastName = (
  name?: {
    first?: string;
    last?: string;
  } | null,
) => {
  const { first, last } = name || {};
  let response = '';
  if (first && !isEmpty(first)) {
    response += `${first.charAt(0)}. `;
  }
  if (!isEmpty(last)) {
    response += last;
  }
  return response;
};

export const convertAPIUserToFullNameAndId = (user: Users.FullUser): Users.Info | null => {
  if (!user) {
    return null;
  }

  return {
    ...user,
    fullName: getFullName({ ...user }),
  };
};

/**
 * If the argument is an empty string, it will return a dash. Otherwise, it will return the argument.
 */
export const returnStringOrDashIfEmpty = (arg: string) => {
  return arg?.length > 0 ? arg : '-';
};
