import { createSlice } from '@reduxjs/toolkit';

import { RootState } from 'redux/store';

export interface GroupFormState {
  missingRequiredFieldsRecord: Record<string, Forms.FieldInstance[]>;
  formFieldsRecord: Record<string, string[]>;
  recalcRequiredFields: Record<string, number>;
}

const initialState: GroupFormState = {
  missingRequiredFieldsRecord: {},
  formFieldsRecord: {},
  recalcRequiredFields: {},
};

export const groupFormSlice = createSlice({
  name: 'groupEncounterForm',
  initialState,
  reducers: {
    setGroupEncounterFormInstance: (state, action) => {
      state.formFieldsRecord[action.payload.id] = action.payload.fieldIds;
      state.recalcRequiredFields[action.payload.id] = 0;
    },
    setIndividualFormInstances: (state, action) => {
      action.payload.forEach((formInstance) => {
        state.formFieldsRecord[formInstance.id] = formInstance.fieldIds;
        state.recalcRequiredFields[formInstance.id] = 0;
      });
    },
    updateGroupMissingRequiredFields: (state, action) => {
      state.missingRequiredFieldsRecord[action.payload.formInstanceId] =
        action.payload.missingRequiredFields;
    },
    recalculateGroupRequiredFields: (state, action) => {
      const formInstanceId = Object.keys(state.formFieldsRecord).find((key) =>
        state.formFieldsRecord[key]?.includes(action.payload),
      );
      if (formInstanceId) {
        state.recalcRequiredFields[formInstanceId] += 1;
      }
    },
  },
});

export const {
  recalculateGroupRequiredFields,
  setGroupEncounterFormInstance,
  setIndividualFormInstances,
  updateGroupMissingRequiredFields,
} = groupFormSlice.actions;

export const selectGroupRecalcRequiredFields: (state: RootState, formInstance: string) => number = (
  state,
  formInstanceId,
) => state.groupEncounterForm.recalcRequiredFields[formInstanceId];

export const selectGroupMissingRequiredFields = (state: RootState) => {
  return (
    Object.values(state.groupEncounterForm.missingRequiredFieldsRecord).reduce(
      (acc, record) => acc.concat(record),
      [] as Forms.FieldInstance[],
    ) ?? []
  );
};

export default groupFormSlice.reducer;
