// @ts-strict-ignore
import { includes, join } from 'lodash';

export const isEmpty = (str?: string | null) => {
  return !str || str.length === 0;
};

export const isNotEmpty = (str?: string | null) => {
  return !isEmpty(str);
};

export const isBlank = (str: string) => {
  return !str || /^\s*$/.test(str);
};

export const contains = (str: string, value: string) => {
  return includes(str, value);
};

export const containsIgnoreCase = (str?: string, value?: string) => {
  return includes(str?.toLowerCase(), value?.toLowerCase());
};

export const capitalizeOnlyFirstLetter = (str?: string) => {
  if (!str) {
    return '';
  }
  // convert to lower case, capitalize the first letter and concatenate the rest of the string
  const lowerCasedString = str.toLowerCase();
  return lowerCasedString.charAt(0).toUpperCase() + lowerCasedString.slice(1);
};

export const toCommaSeparatedList = (stringArray: string[]) => {
  if (!stringArray) {
    return '';
  }

  if (Intl?.ListFormat) {
    const formatter = new Intl.ListFormat('en', { style: 'long', type: 'conjunction' });
    return formatter.format(stringArray);
  }

  // backup for browsers that don't support Intl api
  return join(stringArray, ', ');
};

export const pluralizeText = (text: string, count: number) =>
  `${text}${Math.abs(count) === 1 ? '' : 's'}`;

export const getKebabCase = (s: string) => {
  return s
    .replace(/([a-z])([A-Z])/g, '$1-$2')
    .replace(/[\s_]+/g, '-')
    .toLowerCase();
};

export const getNumberAsStringWithCommas = (n: number): string => {
  return n.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
};

export const formatCurrency = (n: number, maxFractionDigits?: number): string => {
  if (!n || typeof n !== 'number') {
    return '';
  }
  try {
    const formatter = new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD',
      maximumFractionDigits: maxFractionDigits ?? 2,
    });
    return formatter.format(n);
  } catch (e) {
    return `${n}`;
  }
};

export const extractNumberFromString = (str?: string): number => {
  if (!str) {
    return null;
  }

  const firstNumber = str.match(/\d+/)[0];
  if (!firstNumber) {
    return null;
  }

  return parseInt(firstNumber);
};

// Converts a string to lowercase, removes whitespace, and trims.
export const toLowercaseNoWhitespace = (str: string): string => {
  return str.toLowerCase().replace(/\s/g, '').trim();
};

export function removeNullBytes(str: string): string {
  return str
    .split('')
    .filter((char) => char.codePointAt(0))
    .join('');
}
