// @ts-strict-ignore
import { parseISODateStrings, parseObjectDates, StringifyKeys } from '../utils/dateParsers';
import RittenClient from './RittenClient';

export default class SignatureClient extends RittenClient {
  /**
   * Verifies the current user's PIN and returns true if the PIN is correct.
   */
  verifyPin = async (pin: string): Promise<boolean> => {
    try {
      await this.post(`/api/signatures/verify-pin`, {
        pin,
      });
      return true;
    } catch {
      return false;
    }
  };

  // Ritten user signatures
  getUserSignatures = async (userID: string): Promise<Signatures.UserSignature[]> => {
    const res = await this.get<Signatures.UserSignature[]>(`/api/users/${userID}/signatures`);
    return res.data;
  };

  postUserSignature = async (
    userID: string,
    postBody: Signatures.UserSignaturePostBody,
  ): Promise<API.IDResponse> => {
    const res = await this.post<API.IDResponse>(`/api/users/${userID}/signatures`, postBody);
    return res.data;
  };

  // Form signatures
  postFormDefinitionSignatureRequirement = async (
    formDefinitionID: string,
    body: Signatures.FormDefinitionSignatureRequirementPostBody,
  ): Promise<API.IDResponse> => {
    const res = await this.post<API.IDResponse>(
      `/api/forms/definitions/forms/${formDefinitionID}/signature-requirements`,
      body,
    );
    return res.data;
  };

  deleteFormDefinitionSignatureRequirement = async (
    formDefinitionID: string,
    formDefinitionSignatureRequirementID: string,
  ): Promise<void> => {
    await this.delete(
      `/api/forms/definitions/forms/${formDefinitionID}/signature-requirements/${formDefinitionSignatureRequirementID}`,
    );
  };

  deleteAllFormSignatures = async (formInstanceId: string): Promise<void> => {
    await this.delete(`/api/forms/instances/forms/${formInstanceId}/signatures`);
  };

  deleteClientFormSignatureRequirement = async (formInstanceId: string): Promise<void> => {
    await this.delete(`/api/forms/instances/forms/${formInstanceId}/client-signature-requirement`);
  };

  deleteStaffFormSignatureRequirements = async (
    formInstanceId: string,
    body: Signatures.SignatureRequirementsDeleteBody,
  ): Promise<void> => {
    await this.delete(`/api/signatures/requirements/${formInstanceId}`, {}, body);
  };

  /**
   * Gets a generic (non-form) signature by ID.
   */
  getSignature = async (id: string): Promise<Signatures.Signature> => {
    const { data } = await this.get<StringifyKeys<Signatures.Signature, 'createdAt' | 'deletedAt'>>(
      `/api/signatures/${id}`,
    );
    return parseObjectDates(data, ['createdAt', 'deletedAt']);
  };

  /**
   * General (non-form) signatures for user, validated by a PIN.
   */
  createSignaturesForUser = async (
    pin: string,
    count: number = 1,
  ): Promise<Signatures.Signature[]> => {
    const { data = [] } = await this.post<Signatures.Signature[]>(`/api/signatures`, {
      pin,
      count: Math.max(1, count),
    });
    return parseISODateStrings(data);
  };

  /**
   * General (non-form) signatures created on-the-fly for a patient.
   */
  createSignaturesForPatient = async (
    fileId: string,
    patientId: string,
    displayText: string,
    count: number = 1,
  ): Promise<Signatures.Signature[]> => {
    const { data = [] } = await this.post<Signatures.Signature[]>(`/api/signatures`, {
      fileId,
      patientId,
      displayText,
      count: Math.max(1, count),
    });
    return parseISODateStrings(data);
  };

  /**
   * Sign any number of forms, a group encounter, and/or orders as a user
   */
  userSign = async ({
    formIds,
    groupEncounterId,
    orderId,
    signatureActionIds,
    pin,
  }: {
    formIds?: string[];
    groupEncounterId?: string;
    orderId?: string;
    signatureActionIds?: string[];
    pin: string;
  }): Promise<Signatures.PostSignResponse> => {
    const { data } = await this.post<Signatures.PostSignResponse>(`/api/sign`, {
      ...(formIds ? { formIds } : {}),
      ...(groupEncounterId ? { groupEncounterId } : {}),
      ...(orderId ? { orderId } : {}),
      ...(signatureActionIds ? { signatureActionIds } : {}),
      pin,
      isPatientSignature: false,
    });
    return data;
  };

  /**
   * Sign any number of forms, group encounters, and/or orders as a patient
   */
  patientSign = async ({
    formIds,
    groupEncounterId,
    patientId,
    signatureImageFileId,
  }: {
    formIds?: string[];
    groupEncounterId?: string;
    patientId: string;
    signatureImageFileId: string;
  }) => {
    const { data } = await this.post<Signatures.PostSignBody>(`/api/sign`, {
      ...(formIds ? { formIds } : {}),
      ...(groupEncounterId ? { groupEncounterId } : {}),
      signatureImageFileId,
      patientId,
      isPatientSignature: true,
    });
    return data;
  };

  /**
   * Remove signatures applied through the userSign endpoint.
   * Supports orders or forms. Does not support removing patient signatures from forms.
   */
  userUnsign = async (params: { orderId?: string; formInstanceId?: string }): Promise<void> => {
    await this.post(`/api/unsign`, params);
  };

  listSignatureActions = async ({
    requiredRoleIds = [],
    objectCreatedByIds = [],
    contentTypes = [],
    patientIds = [],
    formDefinitionIds = [],
    clientStatuses = [],
    externalSignatureStatuses = [],
    hasSignature = null,
    orderingProviderIds = [],
    limit = 25,
    offset = 0,
    sortBy,
  }: Signatures.ListActionsQueryParams) => {
    const { data } = await this.get<{
      signatureActions: Signatures.SignatureAction[];
      meta: { totalCount: number };
    }>('/api/signature-actions', {
      params: {
        ...(requiredRoleIds ? { requiredRoleIds } : {}),
        ...(objectCreatedByIds ? { objectCreatedByIds } : {}),
        ...(patientIds ? { patientIds } : {}),
        ...(formDefinitionIds ? { formDefinitionIds } : {}),
        ...(clientStatuses ? { clientStatuses } : {}),
        ...(externalSignatureStatuses ? { externalSignatureStatuses } : {}),
        ...(contentTypes ? { contentTypes } : {}),
        ...(sortBy ? { sortBy } : {}),
        ...(hasSignature !== null ? { hasSignature } : {}),
        ...(orderingProviderIds ? { orderingProviderIds } : {}),
        limit,
        offset,
      },
    });
    return parseISODateStrings(data);
  };
}
