// @ts-strict-ignore
export type ResponsiveType = 'small' | 'medium' | 'large' | 'xsmall';
export type ResponsiveSetInput<T> = { L?: T; M?: T; S?: T; XS?: T };

/**
 * Detailed comments in useResponsiveDeviceSize hook.  Should reference tests for more info on functionality.
 *
 * @param {ResponsiveSetInput<T>}
 * @param {boolean} isXSmall
 * @param {boolean} isSmall
 * @param {boolean} isMedium
 * @returns {T}
 */

export const responsiveDeviceValueSet = <T>(
  { L, M, S, XS }: ResponsiveSetInput<T>,
  isXSmall: boolean,
  isSmall: boolean,
  isMedium: boolean,
): T => {
  const isXSmallDevice = isXSmall;
  if (isXSmallDevice && XS) {
    return XS;
  }

  if ((isXSmallDevice || isSmall) && S) {
    return S;
  }

  if ((isXSmallDevice || isSmall || isMedium) && M) {
    return M;
  }

  return L || M || S || XS;
};
