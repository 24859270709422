// Generated by ts-to-zod
import { z } from 'zod';

import { apiIDResponseSchema } from './api.zod';
import { billingBillingRuleResultSchema, billingModifierSchema } from './billing.zod';
import { catalogClinicProgramSchema } from './catalog.zod';
import { documentsDocumentSchema, documentsDocumentTypeSchema } from './documents.zod';
import { formsFormDefinitionBaseSchema, formsFormInstanceSchema } from './forms.zod';
import { ordersOrderSchema } from './orders.zod';
import { patientPatientSchema } from './patient.zod';
import { usersRoleSchema, usersUserSchema } from './users.zod';

export const encountersEncounterTemplateQuerySchema = z.object({
  showArchived: z.boolean().optional(),
  isGroup: z.boolean().optional(),
  programIds: z.array(z.string()).optional(),
  isBillable: z.boolean().optional(),
});

export const encountersDocumentDefinitionBaseSchema = z.lazy(() =>
  z.object({
    id: z.string(),
    encounterTemplateId: z.string(),
    type: documentsDocumentTypeSchema,
    documentName: z.string(),
  }),
);

export const encountersEncounterTemplateFormDefinitionSchema = z
  .object({
    isRequired: z.boolean(),
    isGroupForm: z.boolean(),
  })
  .extend(formsFormDefinitionBaseSchema.shape);

export const encountersEncounterTemplateSchema = z.object({
  id: z.string(),
  type: z.string(),
  description: z.string(),
  createdAt: z.string(),
  formDefinitions: z.array(encountersEncounterTemplateFormDefinitionSchema),
  documentDefinitions: z.array(encountersDocumentDefinitionBaseSchema),
  colorHexCode: z.string(),
  isBilling: z.boolean(),
  isGroup: z.boolean(),
  timeRequired: z.boolean(),
  modifiers: z.array(billingModifierSchema).nullable(),
  position: z.number(),
  modifierId: z.string().optional(),
  roles: z.array(usersRoleSchema).optional(),
  programs: z.array(catalogClinicProgramSchema).optional(),
});

export const encountersEncounterTemplatePatchBodySchema = encountersEncounterTemplateSchema.pick({
  position: true,
  id: true,
});

export const encountersEncounterTemplateFormPostBodySchema = z.object({
  isRequired: z.boolean(),
  isGroupForm: z.boolean(),
  formDefinitionId: z.string(),
});

export const encountersEncounterTemplateDocumentPostBodySchema = z.object({
  type: z.string(),
  documentName: z.string(),
});

export const encountersGroupEncounterPostBodySchema = z.object({
  title: z.string().optional(),
  encounterTemplateId: z.string(),
});

export const encountersGroupEncounterStatusLabelTypeSchema = z.union([
  z.literal('Draft'),
  z.literal('Submitted'),
  z.literal('Completed'),
]);

export const encountersEncounterAttendanceSchema = z.union([
  z.literal('Attended'),
  z.literal('AttendedNotBillable'),
  z.literal('AbsentExcused'),
  z.literal('AbsentNotExcused'),
  z.literal('AbsentUnknown'),
  z.literal('AbsentBillable'),
]);

export const encountersEncounterStatusSchema = z.union([
  z.literal('Complete'),
  z.literal('Incomplete'),
  z.literal('Empty'),
]);

export const encountersEncounterSchema = z.lazy(() =>
  z
    .object({
      id: z.string(),
      encounterTemplateId: z.string(),
      type: z.string(),
      description: z.string(),
      isBilling: z.boolean(),
      patientId: z.string(),
      createdAt: z.date(),
      deletedAt: z.date().optional(),
      formInstances: z.array(formsFormInstanceSchema),
      documents: z.array(documentsDocumentSchema),
      orders: z.array(ordersOrderSchema),
      colorHexCode: z.string(),
      createdBy: usersUserSchema,
      startTime: z.date(),
      endTime: z.date(),
      status: encountersEncounterStatusSchema,
      attendanceType: encountersEncounterAttendanceSchema,
      groupEncounterId: z.string().optional(),
      groupEncounterTitle: z.string().optional(),
      ruleResult: z.union([billingBillingRuleResultSchema, z.literal('')]).optional(),
      modifiers: z.array(billingModifierSchema).nullable(),
      roles: z.array(usersRoleSchema),
      hasRequiredForms: z.boolean(),
      scribeJob: z.object({
        id: z.string(),
        status: z.union([
          z.literal('created'),
          z.literal('ready'),
          z.literal('transcribing'),
          z.literal('error'),
        ]),
        error: z.string().optional(),
        fileStatus: z.string(),
      }),
    })
    .optional(),
);

export const encountersFreeEncounterSchema = z.object({
  id: z.string(),
  patient: patientPatientSchema,
  startTime: z.date(),
  endTime: z.date(),
  createdBy: usersUserSchema,
  createdAt: z.date(),
  ruleResult: z.union([billingBillingRuleResultSchema, z.literal('')]),
});

export const encountersGroupEncounterIndividualSchema = z.object({
  id: z.string(),
  groupEncounterId: z.string(),
  patientId: z.string(),
  attendanceType: encountersEncounterAttendanceSchema,
  individualFormInstanceId: z.string(),
  encounterId: z.string(),
  startTime: z.date(),
  endTime: z.date(),
  createdAt: z.date(),
  conflictingEncounters: z.array(encountersEncounterSchema),
  patient: patientPatientSchema,
});

export const encountersGroupEncounterIndividualPostBodySchema =
  encountersGroupEncounterIndividualSchema.pick({ patientId: true });

export const encountersGroupEncounterQuerySchema = z.object({
  encounterTemplateIds: z.array(z.string()).optional(),
  patientIds: z.array(z.string()).optional(),
  createdByUserIds: z.array(z.string()).optional(),
  searchQuery: z.string().optional(),
  status: encountersGroupEncounterStatusLabelTypeSchema.optional(),
  startTime: z.date().optional(),
  endTime: z.date().optional(),
  orderBy: z.string().optional(),
  sortDirection: z.string().optional(),
  limit: z.string().optional(),
  offset: z.string().optional(),
});

export const encountersEncountersQuerySchema = z.object({
  patientIds: z.array(z.string()).optional(),
  templateIds: z.array(z.string()).optional(),
  types: z.string().optional(),
  status: z.string().optional(),
  searchQuery: z.string().optional(),
  startDate: z.date().optional(),
  endDate: z.date().optional(),
  limit: z.number().optional(),
  offset: z.number().optional(),
});

export const encountersEncountersQueryV2Schema = z.object({
  patientIds: z.array(z.string()).optional(),
  templateIds: z.array(z.string()).optional(),
  roleIds: z.array(z.string()).optional(),
  creatorUserIds: z.array(z.string()).optional(),
  modifierIds: z.array(z.string()).optional(),
  statuses: z.array(z.string()).optional(),
  searchQuery: z.string().optional(),
  startTime: z.date().optional().nullable(),
  endTime: z.date().optional().nullable(),
  limit: z.number().optional(),
  offset: z.number().optional(),
});

export const encountersEncounterExportSchema = z.object({
  forms: z.array(z.string()),
  documents: z.array(z.string()),
  orders: z.array(z.string()),
  tableOfContents: z.boolean(),
});

export const encountersEncounterPostBodySchema = z.lazy(() =>
  encountersEncounterSchema.schema.pick({
    encounterTemplateId: true,
    patientId: true,
    attendanceType: true,
  }),
);

export const encountersFormInstanceIDResponseSchema = z.object({
  formInstanceId: z.string(),
});

export const encountersPostEncounterResponseSchema = apiIDResponseSchema.and(
  z.object({
    modifiers: z.array(billingModifierSchema),
  }),
);

export const encountersGroupEncounterEntityTypeSchema = z.union([
  z.literal('patient'),
  z.literal('program'),
  z.literal('clinic team'),
  z.literal('loc'),
]);

export const encountersGroupEncounterEntitySchema = z.object({
  entityId: z.string(),
  entityType: encountersGroupEncounterEntityTypeSchema,
  entityLOC: z.string().optional(),
});

export const encountersGroupEncounterSchema = z.lazy(() =>
  z.object({
    id: z.string(),
    encounterTemplateId: z.string(),
    groupFormDefinitionId: z.string(),
    individualFormDefinitionId: z.string(),
    groupFormInstanceId: z.string(),
    title: z.string(),
    status: encountersGroupEncounterStatusLabelTypeSchema,
    startTime: z.date(),
    endTime: z.date().nullable(),
    type: z.string(),
    colorHexCode: z.string(),
    createdBy: usersUserSchema,
    createdAt: z.date(),
    groupEncounterIndividuals: z.array(encountersGroupEncounterIndividualSchema),
    isBilling: z.boolean(),
    modifiers: z.array(billingModifierSchema).nullable(),
    documents: z.array(documentsDocumentSchema),
  }),
);
