import { useState } from 'react';
import { Box } from 'grommet';

import { ACCEPTED_FILE_TYPES, ACCEPTED_IMAGE_FILE_TYPES } from '../../../../../AppConstants';
import ErrorPanel from '../../../../../ui-library/errors/ErrorPanel';
import FileDrop from '../../../../../ui-library/file-components/FileDrop';

interface FilePickerProps {
  uploadFile: (files: File[]) => void;
  maxFiles?: number;
  imagesOnly?: boolean;
}

const FilePicker = (props: FilePickerProps) => {
  const { uploadFile, maxFiles, imagesOnly } = props;

  const [errors, setErrors] = useState<string[]>([]);

  const onFileUpload = (files: File[]) => {
    uploadFile(files);
  };

  return (
    <Box>
      {errors?.length > 0 && (
        <Box pad={{ bottom: 'medium' }}>
          <ErrorPanel errors={errors} />
        </Box>
      )}

      <FileDrop
        uploadPaneMessage={`Click to upload ${
          imagesOnly ? 'an image' : 'an attachment'
        } from your computer`}
        acceptedFileTypes={imagesOnly ? ACCEPTED_IMAGE_FILE_TYPES : ACCEPTED_FILE_TYPES}
        onFileUpload={onFileUpload}
        maxFiles={maxFiles}
        onError={setErrors}
      />
    </Box>
  );
};

export default FilePicker;
