import { Box } from 'grommet';

import TextInputComponent from '../../../../ui-library/form-inputs/TextInputComponent';
import PromptTextBuilder from '../PromptTextBuilder';

const TextFieldBuilder = (props: FieldBuilderProps<Forms.FieldMetaText>) => {
  const promptText = (props.meta as Forms.FieldMetaText).promptText;

  const updateMeta = (newValue: string) => {
    if (props.onNewMeta !== undefined) {
      props.onNewMeta({ promptText: newValue });
    }
  };

  const updateDefaultValue = (newValue: string) => {
    props.onNewDefaultValue?.(newValue);
  };

  const allowDefaultValue = props.onNewDefaultValue !== undefined;

  return (
    <Box direction="column" pad="medium" gap="small">
      <PromptTextBuilder
        {...props}
        type="TYPE: FREE TEXT, LINE"
        promptText={promptText}
        onChange={updateMeta}
        removeFieldFromFieldsList={props.removeFieldFromFieldsList}
        moveField={props.moveField}
        fieldIdx={props.fieldIdx}
      />
      <TextInputComponent
        textInputProps={{
          value: props.value,
          placeholder: allowDefaultValue ? 'Enter default text' : '',
          size: 'medium',
          onChange: (e) => {
            updateDefaultValue(e.target.value);
          },
          disabled: !allowDefaultValue,
        }}
      />
    </Box>
  );
};

export default TextFieldBuilder;
