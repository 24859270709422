export const COLORS = {
  // ---- GROMMET ---- \\
  focus: undefined, // NO FOCUS OUTLINE
  // basic colors that can be referenced like `color="text"`
  text: '#333333',
  brand: '#1890FF', // BRAND COLOR
  // ---- GROMMET ---- \\

  // Blue
  rittenBlue400: '#1890FF',
  rittenBlue300: '#8BC7FF',
  rittenBlue200: '#D1E9FF',
  rittenBlue100: '#E8F4FF',
  // Logo Colors
  rittenDarkBlue: '#0071C0',
  rittenTeal: '#009FD9',
  // Navy
  rittenNavy300: '#00245B',
  rittenNavy200: '#4D668C',
  rittenNavy100: '#8091AD',
  // White
  white: '#FFFFFF',
  // Black
  black: '#000000',
  // Transparent
  transparent: 'rgba(0,0,0,0)',
  // Dark Gray
  darkGray600: '#333333',
  darkGray500: '#586067',
  darkGray400: '#909DA9',
  darkGray300: '#DEE5EB',
  darkGray200: '#F2F4F5',
  darkGray100: '#F8FBFC',
  // Red
  red500: '#C34242',
  red400: '#F45252',
  red300: '#F9A8A8',
  red200: '#FDDCDC',
  red100: '#FEEEEE',
  // Pink
  pink400: '#FF539F',
  pink300: '#FFA9CF',
  pink200: '#FFDDEC',
  pink100: '#FFEEF5',
  // Orange
  orange400: '#FF9C45',
  orange300: '#FFCDA2',
  orange200: '#FFEBDA',
  orange100: '#FFF5ED',
  // Yellow
  yellow400: '#FFD146',
  yellow300: '#FFE8A2',
  yellow200: '#FFF6DA',
  yellow100: '#FFFBED',
  // Light Green
  lightGreen400: '#86E200',
  lightGreen300: '#C3F180',
  lightGreen200: '#E7F9CC',
  lightGreen100: '#F3FCE5',
  // Green
  green400: '#00C781',
  green300: '#80E3C0',
  green200: '#CCF4E6',
  green100: '#E5F9F3',
  // Dark Green
  darkGreen400: '#006B73',
  darkGreen300: '#80B5B9',
  darkGreen200: '#CCE1E3',
  darkGreen100: '#E5F0F1',
  // Turquoise
  turquoise400: '#00B4DC',
  turquoise300: '#80DAED',
  turquoise200: '#CCF0F8',
  turquoise100: '#E5F8FB',
  // Purple
  purple400: '#9B51E0',
  purple300: '#CDA8EF',
  purple200: '#EBDCF9',
  purple100: '#F5EDFC',
  // Dark Purple
  darkPurple400: '#840073',
  darkPurple300: '#C180B9',
  darkPurple200: '#E6CCE3',
  darkPurple100: '#F3E5F1',
};

export const DROP_SHADOW = '0px 1px 8px rgba(213, 213, 213, 0.5)';
