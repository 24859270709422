// @ts-strict-ignore
import { useState } from 'react';
import { Box, Grid, Layer, Text } from 'grommet';

import { isAutocompleteSelection } from 'utils/autocompleteUtils';
import { COLORS } from '../../../styles/colors';
import StandardButton from '../../buttons/StandardButton';
import NavContent, { NavContentItem } from '../../navs/NavContent';
import { NavItem } from '../../navs/useNavState';
import VerticalNav from '../../navs/VerticalNav';

interface ModalWithSideNavProps {
  show: boolean;
  title: string;
  // Because modal use px or vh/vw
  contentWidth: string;
  // Because modal use px or vh/vw
  contentHeight: string;
  navItems: NavItem[];
  navContentItems: NavContentItem[];
  onClose: () => void;
  'data-testid'?: string;
  footer?: React.ReactNode;
  sideNavGridSize?: string;
  sideNavItemsBoxHeight?: string;
  contentBackgroundColor?: string;
  contentPadding?: string;
  additionalSidebarContent?: React.ReactNode;
  activeNavItemOverride?: NavItem;
}

export const ModalWithSideNav = (props: ModalWithSideNavProps) => {
  const {
    footer,
    sideNavGridSize = 'min-content',
    sideNavItemsBoxHeight = '100%',
    additionalSidebarContent,
    contentBackgroundColor = COLORS.darkGray200,
    contentPadding = '20px',
    activeNavItemOverride,
  } = props;

  const [activeLink, setActiveLink] = useState<string>();

  const onClickOutside = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    const target = e.target as HTMLDivElement | HTMLSpanElement;
    if (!isAutocompleteSelection(target)) {
      props.onClose();
    }
  };

  if (!props.show) {
    return <></>;
  }

  return (
    <Layer data-testid={props['data-testid']} onClickOutside={onClickOutside}>
      <Grid
        columns={[sideNavGridSize, '1fr']}
        rows={['min-content', '1fr', 'min-content']}
        areas={[
          ['title', 'title'],
          ['side-nav', 'content'],
          ['footer', 'footer'],
        ]}
        width={props.contentWidth}
        height={props.contentHeight}
        pad="20px"
        gap={{ row: '20px', column: '10px' }}
      >
        <Text gridArea="title" size="20px" weight="bold">
          {props.title}
        </Text>

        <Box gridArea="side-nav">
          <Box height={sideNavItemsBoxHeight}>
            <VerticalNav
              width="fit-content"
              navItems={props.navItems}
              onActiveLinkClicked={setActiveLink}
              activeNavItemOverride={activeNavItemOverride}
            />
          </Box>
          {additionalSidebarContent}
        </Box>

        <Box gridArea="content" overflow="auto" pad={contentPadding}>
          {/* We only want the background color to cover the content of the roobi, so we need this div */}
          <div
            style={{
              background: contentBackgroundColor,
              padding: contentPadding,
              borderRadius: '4px',
            }}
          >
            <NavContent navContentItems={props.navContentItems} activeItemLabel={activeLink} />
          </div>
        </Box>

        <Box gridArea="footer" direction="row" justify="end">
          {footer || <StandardButton label="Close" onClick={props.onClose} />}
        </Box>
      </Grid>
    </Layer>
  );
};

export default ModalWithSideNav;
