// Generated by ts-to-zod
import { z } from 'zod';

import { billingModifierSchema } from './billing.zod';
import {
  encountersEncounterSchema,
  encountersEncounterTemplateSchema,
  encountersGroupEncounterSchema,
} from './encounters.zod';
import { entitySuggestedUserSchema } from './entity.zod';
import { usersUserSchema } from './users.zod';

export const calendarCalendarReminderIntervalSchema = z.union([
  z.literal('0m'),
  z.literal('15m'),
  z.literal('30m'),
  z.literal('1h'),
  z.literal('24h'),
  z.literal('2d'),
  z.literal('3d'),
]);

export const calendarZoomMeetingResponseSchema = z.object({
  primaryHost: usersUserSchema,
  alternativeHosts: z.array(usersUserSchema).optional(),
  joinUrl: z.string().optional(),
  startUrl: z.string().optional(),
  id: z.string(),
});

export const calendarZoomMeetingPostBodySchema = z.object({
  isTelehealth: z.boolean().optional(),
  zoomMeetingId: z.string().optional(),
  primaryHostId: z.string().optional(),
  alternativeHostIds: z.array(z.string()).optional(),
});

export const calendarEventQueryBodySchema = z.object({
  entityIds: z.array(z.string()),
  roleIds: z.array(z.string()).optional(),
  encounterTemplateIds: z.array(z.string()),
  hasNoEncounterTemplate: z.boolean().optional(),
  startTime: z.date(),
  endTime: z.date(),
});

export const calendarCalendarEventEditTypePatchBodySchema = z.union([
  z.literal('This and future events'),
  z.literal('All events'),
  z.literal('This event only'),
]);

export const calendarDeletionTypeSchema = z.union([
  z.literal('this and future events'),
  z.literal('all events'),
  z.literal('this event'),
]);

export const calendarCalendarEventGuestUserSchema = z.object({
  id: z.string().optional(),
  entityId: z.string(),
  first: z.string(),
  middle: z.string(),
  last: z.string(),
  createdAt: z.string(),
});

export const calendarCalendarEventGuestPatientSchema = z.object({
  id: z.string().optional(),
  entityId: z.string(),
  first: z.string(),
  middle: z.string(),
  last: z.string(),
  createdAt: z.string(),
});

export const calendarCalendarEventGuestGroupSchema = z.object({
  id: z.string().optional(),
  groupName: z.string(),
  users: z.array(calendarCalendarEventGuestUserSchema),
  patients: z.array(calendarCalendarEventGuestPatientSchema),
});

export const calendarCalendarEventGuestProgramSchema = z.object({
  id: z.string().optional(),
  programName: z.string(),
  patients: z.array(calendarCalendarEventGuestPatientSchema),
  programLOC: z.string().optional(),
});

export const calendarCalendarEventGuestCareTeamSchema = z.object({
  id: z.string().optional(),
  patient: calendarCalendarEventGuestPatientSchema,
  primaryClinician: entitySuggestedUserSchema,
  team: z.array(entitySuggestedUserSchema),
});

export const calendarCalendarEventGuestClinicTeamSchema = z.object({
  id: z.string().optional(),
  name: z.string(),
  patients: z.array(calendarCalendarEventGuestPatientSchema),
  team: z.array(entitySuggestedUserSchema),
});

export const calendarSuggestedGuestEntitySchema = z.object({
  entityType: z.string(),
  entityId: z.string().optional(),
});

export const calendarEventStartSchema = z.object({
  patientIds: z.array(z.string()).optional(),
  encounterId: z.string().optional(),
  groupEncounterId: z.string().optional(),
});

export const calendarNylasAccountSchema = z.object({
  id: z.string(),
  userId: z.string(),
  email: z.string(),
  providerType: z.string(),
  createdAt: z.string(),
  deletedAt: z.string().optional(),
});

export const calendarCalendarEventGuestsSchema = z.object({
  users: z.array(calendarCalendarEventGuestUserSchema),
  patients: z.array(calendarCalendarEventGuestPatientSchema),
  groups: z.array(calendarCalendarEventGuestGroupSchema),
  programs: z.array(calendarCalendarEventGuestProgramSchema),
  careTeams: z.array(calendarCalendarEventGuestCareTeamSchema),
  clinicTeams: z.array(calendarCalendarEventGuestClinicTeamSchema),
  canViewAllGuests: z.boolean(),
});

export const calendarGuestListPostBodySchema = z.object({
  editType: calendarCalendarEventEditTypePatchBodySchema.optional(),
  guests: z.array(calendarSuggestedGuestEntitySchema),
  eventLimit: z.number().optional(),
  notify: z.boolean().optional(),
});

export const calendarCalendarEventSchema = z.lazy(() =>
  z.object({
    id: z.string().optional(),
    title: z.string(),
    description: z.string().optional(),
    location: z.string().optional(),
    startTime: z.date(),
    endTime: z.date(),
    encounterTemplate: encountersEncounterTemplateSchema.optional(),
    reminderIntervals: z.array(calendarCalendarReminderIntervalSchema).optional(),
    frequency: z.string().optional(),
    interval: z.number().optional(),
    daysOfWeek: z.array(z.string()).optional(),
    until: z.date().optional(),
    guests: calendarCalendarEventGuestsSchema,
    encounter: encountersEncounterSchema.optional(),
    groupEncounter: encountersGroupEncounterSchema.optional(),
    createdAt: z.date(),
    modifiers: z.array(billingModifierSchema).optional(),
    meeting: calendarZoomMeetingResponseSchema.optional(),
  }),
);

export const calendarEventPostBodySchema = z.object({
  title: z.string(),
  description: z.string().optional(),
  location: z.string().optional(),
  startTime: z.date(),
  endTime: z.date(),
  timezone: z.string(),
  encounterTemplateId: z.string().optional(),
  reminderIntervals: z.array(calendarCalendarReminderIntervalSchema).optional(),
  isRecurring: z.boolean(),
  frequency: z.string().optional(),
  interval: z.number().optional(),
  daysOfWeek: z.array(z.string()).optional(),
  until: z.date().optional(),
  guestList: calendarGuestListPostBodySchema,
  modifierIds: z.array(z.string()).optional(),
  meeting: calendarZoomMeetingPostBodySchema.optional(),
});

export const calendarEventConflictSchema = z.object({
  entityIds: z.array(z.string()),
  eventStart: z.date(),
  eventEnd: z.date(),
  eventId: z.string(),
  eventTitle: z.string(),
  eventRecurring: z.boolean(),
});
