import { parseArrayDates, parseObjectDates, StringifyKeys } from 'utils/dateParsers';
import RittenClient from './RittenClient';

export default class SecurityClient extends RittenClient {
  listSecurityGroups = async (): Promise<Security.SecurityGroup[]> => {
    const res = await this.get<Security.SecurityGroup[]>('/api/security/groups');
    return res.data;
  };

  editSecurityGroup = async (
    body: Security.SecurityGroupPostBody,
  ): Promise<Security.SecurityGroup> => {
    const res = await this.post<Security.SecurityGroup>('/api/security/groups', body);
    return res.data;
  };

  getSecurityGroup = async (id: string): Promise<Security.SecurityGroup> => {
    const res = await this.get<Security.SecurityGroup>(`/api/security/groups/${id}`);
    return parseObjectDates(res.data, ['createdAt']);
  };

  deleteSecurityGroup = async (id: string): Promise<void> => {
    await this.delete(`/api/security/groups/${id}`);
  };

  setSecurityGroupUsers = async (
    id: string,
    body: Security.SecurityGroupUsersPostBody,
  ): Promise<void> => {
    await this.post(`/api/security/groups/${id}/users`, body);
  };

  assignGroupPolicy = async (groupId: string, policyId: string): Promise<void> => {
    await this.post<void>(`/api/security/groups/${groupId}/policies/${policyId}`);
  };

  updateGroupPolicy = async (
    groupId: string,
    policyId: string,
    data: { isDeny: boolean },
  ): Promise<void> => {
    await this.patch<void>(`/api/security/groups/${groupId}/policies/${policyId}`, data);
  };

  removeGroupPolicy = async (groupId: string, policyId: string): Promise<void> => {
    await this.delete<void>(`/api/security/groups/${groupId}/policies/${policyId}`);
  };

  listPolicies = async (): Promise<Security.Policy[]> => {
    const res = await this.get<StringifyKeys<Security.Policy, 'createdAt'>[]>(
      '/api/security/policies',
    );
    return parseArrayDates(res.data, ['createdAt']);
  };

  createPolicy = async (body: Security.PolicyPostBody): Promise<Security.Policy> => {
    const res = await this.post<Security.Policy>('/api/security/policies', body);
    return res.data;
  };

  deletePolicy = async (id: string): Promise<void> => {
    await this.delete(`/api/security/policies/${id}`);
  };
}
