// @ts-strict-ignore
import { Box, CheckBoxGroup, CheckBoxGroupProps, ThemeContext } from 'grommet';
import { grommet } from 'grommet/themes';
import { deepMerge, DirectionType } from 'grommet/utils';

import { COLORS } from '../../styles/colors';
import ErrorLabel from '../labels/ErrorLabel';
import InputLabel from './InputLabel';

interface CheckboxGroupComponentProps {
  label: string;
  checkboxGroupProps: CheckBoxGroupProps;
  width?: string;
  fontSize?: string;
  direction?: DirectionType;
  padding?: {
    paddingTop?: string;
    paddingBottom?: string;
    paddingLeft?: string;
    paddingRight?: string;
  };
  'data-cy'?: string;
  dataTestId?: string;
  requirementMarker?: string;
  nowrap?: boolean;
  inputLabel?: React.ReactNode;
  error?: string;
  hideErrorMessage?: boolean;
}

const CheckboxGroupComponent = (props: CheckboxGroupComponentProps) => {
  const checkboxGroupTheme = deepMerge(grommet, {
    checkBox: {
      size: '16px',
      extend: {
        ...(props.padding
          ? props.padding
          : {
              paddingBottom: '6px',
              paddingTop: '6px',
              paddingRight: '40px',
            }),
        fontSize: props.fontSize ?? '16px',
      },
      icon: {
        size: '16px',
        extend: {
          background: COLORS.rittenBlue400,
          borderRadius: '2px',
          overflow: 'visible',
        },
      },
      border: {
        color: COLORS.darkGray400,
        width: '1px',
      },
      color: COLORS.white,
      check: {
        radius: '2px',
        extend: {
          background: COLORS.white,
        },
      },
      hover: {
        border: {
          color: COLORS.darkGray600,
        },
      },
    },
  });

  return (
    <ThemeContext.Extend value={checkboxGroupTheme}>
      <Box data-cy={props['data-cy']} data-testid={props.dataTestId}>
        {props.inputLabel ? (
          props.inputLabel
        ) : (
          <InputLabel label={props.label} requirementMarker={props.requirementMarker} />
        )}
        <CheckBoxGroup
          {...props.checkboxGroupProps}
          aria-label={props.label || ''}
          direction={props.direction ?? 'row'}
          gap="none"
          wrap={!props.nowrap}
        />
        {props.error && !props.hideErrorMessage && (
          <Box pad={{ top: 'xxsmall' }}>
            <ErrorLabel>{props.error}</ErrorLabel>
          </Box>
        )}
      </Box>
    </ThemeContext.Extend>
  );
};

export default CheckboxGroupComponent;
