import { KeyOf } from '../ui-library/types/keyof.type';

/**
 * Used to type check a string when you need to pass a property from a Class/Interface as a string.
 *
 * @example
 * // The compiler will throw an error if 'id' is not a property on MyObject.
 * <DropdownComponent valueKey={Prop<MyObject>('id')} />
 *
 * @param {keyof T} propName
 * @returns {string}
 */
export function Prop<T>(propName: KeyOf<T>) {
  return propName as string;
}
