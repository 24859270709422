import { Action, configureStore, ThunkAction } from '@reduxjs/toolkit';

import encounterPatchReducer from '../features/clients/chart/encounters/components/EncounterPatchSlice';
import documentReducer from '../features/clients/chart/new-encounter-element-modal/document-upload/DocumentSlice';
import orderReducer from '../features/clients/chart/orders/OrderSlice';
import formReducer from '../features/forms/FormSlice';
import groupEncounterFormReducer from '../features/forms/GroupEncounterFormSlice';
import signatureReducer from '../features/signatures/SignatureSlice';

export const store = configureStore({
  reducer: {
    signature: signatureReducer,
    encounterPatch: encounterPatchReducer,
    document: documentReducer,
    order: orderReducer,
    form: formReducer,
    groupEncounterForm: groupEncounterFormReducer,
  },
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
