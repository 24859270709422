import * as Sentry from '@sentry/react';

import ErrorPage from './ErrorPage';

/**
 * Wraps content in a Sentry.ErrorBoundary - which reports errors to Sentry.
 * If you need to intercept errors look at the beforeSend function in sentry.ts
 * @param {React.PropsWithChildren<{}>} props
 * @returns {JSX.Element}
 */
const ErrorBoundary: React.FC = (props: React.PropsWithChildren<{}>): JSX.Element => {
  // const onBeforeCapture = (scope: Sentry.Scope, error: any) => {
  const onBeforeCapture = () => {
    // Can be used to message global state.  Currently we don't need this.
  };

  return (
    <Sentry.ErrorBoundary
      fallback={(props) => <ErrorPage {...props} />}
      beforeCapture={onBeforeCapture}
    >
      {props.children}
    </Sentry.ErrorBoundary>
  );
};

export default ErrorBoundary;
