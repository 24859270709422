import * as Sentry from '@sentry/react';

export function openURLInNewTab(url: string) {
  // This is work around to open a new tab instead of using window.open which causes issues in safari.
  const a = document.createElement('a');
  a.setAttribute('href', url);
  a.setAttribute('target', '_blank');
  a.click();
}

export const isIOS = () => {
  return /iPad|iPhone|iPod/.test(navigator.userAgent);
};

export const setLocalStorageItem = (key: string, value: string) => {
  try {
    window.localStorage.setItem(key, value);
  } catch (e) {
    Sentry.captureException(e);
  }
};
