// @ts-strict-ignore
/**
 * Uses the beforeunload event on Window to call caches.delete().
 * *beforeunload* function will be called on refresh or tab/browser close
 * @param {string[]} cacheNames
 * @returns void
 */
export const clearCachesOnAppUnload = (cacheNames: string[]) => {
  window.addEventListener('beforeunload', () => {
    for (const cache of cacheNames) {
      caches.delete(cache);
    }
  });
};

/**
 * Gets value in the API cache which is called - CacheStorage.
 * MORE INFO: https://developer.mozilla.org/en-US/docs/Web/API/CacheStorage
 * @param {string} cacheName
 * @param {string} url
 * @returns {Response | null}
 */
export const getFromApiCache = async (cacheName: string, url: string): Promise<Response | null> => {
  if (caches) {
    const cache = await caches.open(cacheName);
    const result = await cache.match(url);
    return result;
  }

  return null;
};

/**
 * The value is wrapped in a Response() which is required to store in the CacheStorage.
 * The "API Cache" is called - CacheStorage.
 * MORE INFO: https://developer.mozilla.org/en-US/docs/Web/API/CacheStorage
 * @param {string} cacheName
 * @param {string} url
 * @param {any} value
 * @returns {any}
 */
export const addToApiCache = async (cacheName: string, url: string, value: any) => {
  if (caches && value) {
    const cache = await caches.open(cacheName);
    cache.put(url, new Response(value));
  }
};
