import { COLORS } from '../../styles/colors';
import { ButtonColor } from './constants';

export const getColor = (color: ButtonColor) => {
  switch (color) {
    case 'alert':
      return COLORS.red400;
    case 'plain':
      return COLORS.black;
    case 'white':
      return COLORS.white;
    case 'gray-mid':
      return COLORS.darkGray400;
    case 'text':
      return COLORS.darkGray600;
    case 'yellow':
      return COLORS.yellow400;
    case 'purple':
      return COLORS.purple400;
    default:
      return COLORS.rittenBlue400;
  }
};

export const getTextColor = (color: ButtonColor) => {
  switch (color) {
    case 'yellow':
    case 'white':
      return COLORS.darkGray600;
    case 'alert':
    case 'plain':
    case 'gray-mid':
    case 'text':
    default:
      return COLORS.white;
  }
};

export const getHoverStateColor = (color: ButtonColor, isPrimary: boolean) => {
  switch (color) {
    case 'alert':
      return isPrimary ? COLORS.red500 : COLORS.red100;
    case 'plain':
    case 'white':
    case 'gray-mid':
    case 'text':
      return COLORS.darkGray200;
    case 'yellow':
      return COLORS.yellow200;
    case 'purple':
      return COLORS.purple200;
    default:
      return isPrimary ? COLORS.rittenDarkBlue : COLORS.rittenBlue200;
  }
};

export const useButtonColor = () => {
  return { getColor, getTextColor, getHoverStateColor };
};
