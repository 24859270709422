import { useEffect, useMemo, useState } from 'react';

import { setLocalStorageItem } from 'utils';
import { reportError } from '../third-party/sentry';

export type Serializer<T> = (object: T | undefined) => string;
export type Parser<T> = (val: string) => T | undefined;
export type Setter<T> = React.Dispatch<React.SetStateAction<T | undefined>>;

type Options<T> = Partial<{
  serializer: Serializer<T>;
  parser: Parser<T>;
  logger: (error: any) => void;
  syncData: boolean;
}>;

function useLocalStorage<T>(key: string, defaultValue: T, options?: Options<T>): [T, Setter<T>];
function useLocalStorage<T>(key: string, defaultValue?: T, options?: Options<T>) {
  const opts = useMemo(() => {
    return {
      serializer: JSON.stringify,
      parser: JSON.parse,
      reporter: reportError,
      syncData: false,
      ...options,
    };
  }, [options]);

  const { serializer, parser, reporter, syncData = false } = opts;

  const [storedValue, setValue] = useState(() => {
    if (typeof window === 'undefined') {
      return defaultValue;
    }

    try {
      const item = window.localStorage.getItem(key);
      const res: T = item ? parser(item) : defaultValue;
      return res;
    } catch (e) {
      reporter(e);
      return defaultValue;
    }
  });

  useEffect(() => {
    if (typeof window === 'undefined') {
      return;
    }

    const updateLocalStorage = () => {
      if (storedValue !== undefined) {
        setLocalStorageItem(key, serializer(storedValue));
      } else {
        window.localStorage.removeItem(key);
      }
    };

    updateLocalStorage();
  }, [storedValue]);

  useEffect(() => {
    if (!syncData) {
      return () => {};
    }
    if (typeof window === 'undefined') {
      return () => {};
    }

    const handleStorageChange = (e: StorageEvent) => {
      if (e.key !== key || e.storageArea !== window.localStorage) {
        return;
      }

      try {
        setValue(e.newValue ? parser(e.newValue) : defaultValue);
      } catch (e) {
        reporter(e);
      }
    };

    window.addEventListener('storage', handleStorageChange);
    return () => {
      window.removeEventListener('storage', handleStorageChange);
    };
  }, [key, syncData]);

  return [storedValue, setValue];
}

interface UsePersistedStateArgs<S> {
  localStorageKey: string;
  initialData: S;
  options?: Options<S>;
}

/**
 * usePersistedState is a hook that behaves just like useState, but persists the state to browser storage.
 * Right now it uses a custom copy of use-local-storage on npm because the package itself was mysteriously
 * setting initial state to undefined despite being passed default defined values.
 *
 * @example
 * const [data, setData] = usePersistedState<S>("data", { foo: "bar" });
 * }
 * @returns {S}
 */
const usePersistedState = <S>(args: UsePersistedStateArgs<S>) => {
  const { localStorageKey, initialData, options } = args;
  return useLocalStorage<S>(localStorageKey, initialData, options);
};

export default usePersistedState;
