import { AxiosRequestConfig } from 'axios';

import RittenClient from './RittenClient';

class EntityClient extends RittenClient {
  getEntities = async (
    name?: string,
    params?: {
      searchUsers?: boolean;
      searchPatients?: boolean;
      searchPrograms?: boolean;
      searchCareTeams?: boolean;
      searchClinicTeams?: boolean;
      primaryCliniciansOnly?: boolean;
      careTeamCliniciansOnly?: boolean;
      patientProgramStatuses?: string[];
      limit?: number;
      offset?: number;
    },
  ) => {
    const {
      searchUsers = true,
      searchPatients = true,
      searchPrograms = true,
      searchCareTeams = true,
      searchClinicTeams = true,
      primaryCliniciansOnly = false,
      careTeamCliniciansOnly = false,
      patientProgramStatuses = [],
      limit = 20,
      offset = 0,
    } = params || {};

    const entityParams: AxiosRequestConfig = {
      params: {
        name,
        limit,
        offset,
        searchUsers,
        searchPatients,
        searchPrograms,
        searchCareTeams,
        searchClinicTeams,
        primaryCliniciansOnly,
        careTeamCliniciansOnly,
        patientProgramStatuses,
      },
    };
    const res = await this.get<Entity.EntityResponse>(`/api/entities`, entityParams);
    return res.data;
  };
}

export default EntityClient;
