// Generated by ts-to-zod
import { z } from 'zod';

import { patientPatientSchema } from './patient.zod';
import { usersUserSchema } from './users.zod';

export const labOrderStatusSchema = z.union([
  z.literal('pending'),
  z.literal('canceled'),
  z.literal('error'),
  z.literal('submitted'),
  z.literal('acknowledgement_required'),
  z.literal('complete'),
]);

export const labOrderSchema = z.lazy(() =>
  z.object({
    id: z.string(),
    orderId: z.string(),
    patientId: z.string(),
    encounterId: z.string(),
    resultId: z.string().nullable(),
    fileId: z.string().nullable(),
    documentId: z.string().nullable(),
    status: labOrderStatusSchema,
    createdAt: z.date(),
    deletedAt: z.date().nullable(),
    acknowledgedAt: z.date().nullable(),
    acknowledgedBy: usersUserSchema.nullable(),
    orderedBy: usersUserSchema.nullable(),
    name: z.string(),
    patient: patientPatientSchema.nullable(),
  }),
);

export const labOrderCallbackResponseCodeSchema = z.union([
  z.literal('success'),
  z.literal('canceled'),
  z.literal('error'),
]);

export const resultClassificationSchema = z.union([z.literal('Positive'), z.literal('Negative')]);

export const labListOrdersParamsSchema = z.object({
  searchText: z.string(),
  ids: z.array(z.string()),
  orderIds: z.array(z.string()),
  encounterIds: z.array(z.string()),
  patientIds: z.array(z.string()),
  programIds: z.array(z.string()),
  orderedByUserIds: z.array(z.string()),
  primaryClinicianIds: z.array(z.string()),
  careTeamUserIds: z.array(z.string()),
  startDate: z.date(),
  endDate: z.date(),
  status: z.array(z.string()),
  limit: z.number(),
  offset: z.number(),
  sortBy: z.string(),
  withArchived: z.boolean(),
});

export const labOrderCallbackPostBodySchema = z.object({
  responseCode: labOrderCallbackResponseCodeSchema,
  responseMessage: z.string().optional(),
  orderId: z.string(),
  state: z.string(),
});
