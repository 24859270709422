import { AxiosRequestConfig, AxiosResponseHeaders } from 'axios';
import _ from 'lodash';

import {
  getInsightsReportTypeURL,
  InsightsReportTypeName,
} from '../features/insights/common/insightsReportName';
import { getFileNameFromHeaders } from '../utils/fileUtils';
import RittenClient from './RittenClient';

export default class InsightsClient extends RittenClient {
  #queryCensus = async <T>(
    query: Insights.PostBody.InsightsCensusQuery,
    config: AxiosRequestConfig = {},
  ) => {
    // Remove any items with no params.
    let cleanParams = _.filter(query.dataSetParams, (q) => q.params?.length > 0);

    // The backend can't parse an int so need to convert the age params to a string
    cleanParams = cleanParams?.map((q) => {
      if (q.type === 'Age') {
        q.params[0] = `${q.params[0]}`;
        q.params[1] = `${q.params[1]}`;
      }
      return q;
    });

    query.dataSetParams = cleanParams;
    return this.post<T>('api/insights/census', query, {
      ...config,
    });
  };

  queryCensus = async (query: Insights.PostBody.InsightsCensusQuery) => {
    const res = await this.#queryCensus<Insights.InsightCensusQueryResult[]>(query);
    return res.data;
  };

  queryCensusCsv = async (query: Insights.PostBody.InsightsCensusQuery) => {
    const res = await this.#queryCensus<Blob>(
      { ...query, csv: true },
      {
        responseType: 'blob',
      },
    );
    return res.data;
  };

  getSavedGraphs = async () => {
    const res = await this.get<Insights.InsightsGraphSettings[]>('api/insights/graph');
    return res.data;
  };

  deleteSavedGraph = async (graphId: string) => {
    await this.delete(`api/insights/graph/${graphId}`);
  };

  saveCensusGraphSettings = async (graphSettings: Insights.InsightsGraphSettings) => {
    // convert param numbers to strings
    const cleanGraphSettings = _.cloneDeep(graphSettings);

    cleanGraphSettings.dataSets?.forEach((dataSet) => {
      // filters any out with no params.
      // The backend will delete any params which were previously saved and
      // no longer exist in the param array.
      dataSet.dataSetParams = _.filter(dataSet.dataSetParams, (q) => q.params.length > 0);

      dataSet.dataSetParams?.forEach((dataSetParam) => {
        dataSetParam.params = dataSetParam.params?.map((param) => {
          if (typeof param === 'number') {
            return `${param}`;
          }
          return param;
        });
      });
    });

    const res = await this.post<Insights.InsightsGraphSettings>(
      'api/insights/graph',
      cleanGraphSettings,
    );
    return res.data;
  };

  // Gets a report by name (accepts startDate and endDate as Date objects)
  getReportCsvByNameAndDates = async (
    reportName: InsightsReportTypeName,
    startDate: Date,
    endDate: Date,
    isBrief: boolean,
    combineTransfers: boolean,
    activeOnly: boolean,
    clinical: boolean,
    formId?: string,
    patientIds?: string[],
    userIds?: string[],
  ) => {
    const params: any = {
      csv: true,
      formDefinitionId: formId,
      isBrief,
      combineTransfers,
      activeOnly,
      clinical,
      patientIds,
      userIds,
      startDate,
      endDate,
    };
    const res = await this.get<Blob>(`api/insights/${getInsightsReportTypeURL(reportName)}`, {
      params,
      responseType: 'blob',
    });

    const headers = res.headers as AxiosResponseHeaders;
    const fileName = getFileNameFromHeaders(headers['content-disposition']);
    const result = { fileName, file: res.data };
    return result;
  };

  // Gets a reports by name (accepts startDate and endDate as date strings 'yyyy-mm-dd')
  getReportCsvByNameAndDateStrings = async (
    reportName: InsightsReportTypeName,
    startDate: string,
    endDate: string,
    isBrief: boolean,
    combineTransfers: boolean,
    activeOnly: boolean,
    clinical: boolean,
    formId?: string,
    patientIds?: string[],
    userIds?: string[],
  ) => {
    const params: any = {
      csv: true,
      formDefinitionId: formId,
      isBrief,
      combineTransfers,
      activeOnly,
      clinical,
      patientIds,
      userIds,
      startDate,
      endDate,
    };

    const res = await this.get<Blob>(`api/insights/${getInsightsReportTypeURL(reportName)}`, {
      params,
      responseType: 'blob',
    });

    const headers = res.headers as AxiosResponseHeaders;
    const fileName = getFileNameFromHeaders(headers['content-disposition']);
    const result = { fileName, file: res.data };
    return result;
  };

  exportCensusAsync = async (
    chart: Blob,
    queryInfo: Insights.PostBody.InsightsCensusPDFQuery,
    chartData: Insights.InsightCensusGraphData[],
  ) => {
    const formData = new FormData();
    formData.append('file', chart, 'Census-Report.png');
    formData.append('query', JSON.stringify(queryInfo));
    formData.append('data', JSON.stringify(chartData));
    const res = await this.post<Blob>(`api/exports/async/insights/census`, formData, {
      responseType: 'blob',
    });
    return res;
  };

  getMetabaseDashboardUrl = async (
    dashboardName: Insights.MetabaseDashboardName,
  ): Promise<Insights.MetabaseIFrameURLResponse> => {
    const res = await this.get<Insights.MetabaseIFrameURLResponse>(
      `/api/metabase/embed-url/${dashboardName}`,
    );
    return res.data;
  };
}
