import { Box, Text } from 'grommet';
import { FormClose } from 'grommet-icons';

import { COLORS } from 'styles/colors';
import { DropdownChoice, getChoiceLabel, Variant } from '../Dropdown';
import AnchorContainer from './AnchorContainer';

interface MultiChoiceAnchorProps {
  onDropClick: () => void;
  anchorRef: any;
  disabled: boolean;
  selections: DropdownChoice[];
  onRemoveSelection: (choice: DropdownChoice) => void;
  showSelectionsInline?: boolean;
  placeholderText?: string;
  choiceTextDataCy?: string;
  choiceLabelKey?: string;
  variant: Variant;
  inlineChipColors?: { borderColor: string; backgroundColor: string; iconColor: string };
  hasError?: boolean;
  iconSize?: string;
}

const MultiChoiceAnchor = (props: MultiChoiceAnchorProps) => {
  const {
    disabled,
    selections,
    onRemoveSelection,
    placeholderText,
    showSelectionsInline,
    choiceTextDataCy,
    choiceLabelKey,
    inlineChipColors,
  } = props;

  const renderSummaryLabel = () => {
    const textColor =
      !selections || selections?.length === 0 ? COLORS.darkGray400 : COLORS.darkGray600;

    const label = getChoiceLabel(selections?.[0], choiceLabelKey);
    return (
      <Box>
        {/* we can truncate this text because we are rendering the selected values elsewhere */}
        <Text size="16px" color={textColor} truncate>
          {(!selections || selections?.length === 0) && (placeholderText || 'Select...')}
          {selections?.length === 1 && label}
          {selections?.length > 1 && `${label} + ${selections.length - 1}`}
        </Text>
      </Box>
    );
  };

  const renderSelectionsInline = () => {
    const {
      borderColor = COLORS.rittenBlue400,
      backgroundColor = COLORS.rittenBlue200,
      iconColor = COLORS.rittenBlue400,
    } = inlineChipColors || {};
    return (
      <Box direction="row" wrap gap="8px" height="min-content" style={{ rowGap: '8px' }}>
        {selections?.length > 0 ? (
          selections.map((choice) => {
            const label = getChoiceLabel(choice, choiceLabelKey);
            return (
              <Box
                key={label}
                onClick={
                  !disabled
                    ? (event: React.MouseEvent<HTMLElement>) => {
                        event.preventDefault();
                        event.stopPropagation();
                        onRemoveSelection(choice);
                      }
                    : undefined
                }
                data-testid={`mc-dropdown-inline-selection-${label.replace(/\s/g, '')}`}
                onFocus={(event) => event.stopPropagation()}
                border={{ size: '1px', color: borderColor }}
                direction="row"
                align="center"
                height="24px"
                background={backgroundColor}
                style={{ opacity: disabled ? 0.5 : 1, borderRadius: '4px' }}
                pad="4px"
              >
                <Box direction="row" align="center" pad={{ right: '6px' }}>
                  <Text
                    data-cy={choiceTextDataCy}
                    color={COLORS.darkGray600}
                    weight="bold"
                    size="11px"
                    truncate
                  >
                    {label}
                  </Text>
                </Box>
                <Box data-cy="form-close-button">
                  <FormClose size="16px" color={iconColor} />
                </Box>
              </Box>
            );
          })
        ) : (
          <Text color={COLORS.darkGray400} size="16px">
            {placeholderText || 'Select...'}
          </Text>
        )}
      </Box>
    );
  };

  return (
    <AnchorContainer {...props}>
      {showSelectionsInline ? renderSelectionsInline() : renderSummaryLabel()}
    </AnchorContainer>
  );
};

export default MultiChoiceAnchor;
