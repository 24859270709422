import { useContext } from 'react';

import LogRocketContext from './LogRocketContext';

const useLogRocket = (): LogRocketContext => {
  const context = useContext(LogRocketContext);
  if (context === null) {
    throw new Error('useLogRocket must be used inside a LogRocketContext.Provider');
  }
  return context;
};

export default useLogRocket;
