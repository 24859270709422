import { useState } from 'react';

import { getErrorMessage } from '../utils/errorUtils';

/**
 * useErrorState can be used in conjunction with Error panel and provides an errors
 * state variable and a function to clear the errors.
 *
 * Consider using `useErrorStateMap` instead if you need a Map data structure.
 */
export const useErrorState = () => {
  const [errors, setErrors] = useState<string[]>([]);

  const addError = (err: unknown) => {
    const errorMessage = getErrorMessage(err);
    setErrors((prevValue) => prevValue.concat(errorMessage));
  };

  const clearErrors = () => {
    setErrors([]);
  };

  const errorsExist = errors && errors.length > 0;

  return {
    errors,
    setErrors,
    addError,
    clearErrors,
    errorsExist,
  };
};

/**
 * `useErrorStateMap` is like the base `useErrorState` hook, but uses an alternate
 * data structure (hash map), which maps a key of type {T} to a set of error values
 * of type {Set}. The set ensures unique error values.
 *
 * This is useful, for example, to enable row-level validation, by binding error
 * values to a specific section ID (string) or index (number).
 *
 * Generic function that accepts the type {T} of the Map's key.
 *
 * @example
 * const { errors, addError, clearErrors } = useErrorStateMap<number>();
 * addError(0, "Zero has an error");
 * addError(0, "Zero has another error");
 * errors.size;        // 1
 * errors.has(0);      // true
 * errors.get(0).size; // 2
 *
 * addError(1, "One now also has an error");
 * errors.size;        // 2
 * errors.has(1);      // true
 * errors.get(1).size; // 1
 * clearErrors(1);
 * errors.has(1);      // false
 *
 * resetErrors();
 * errors.size;   // 0
 * errors.has(0); // false
 * errors.has(1); // false
 */
export const useErrorStateMap = <T>(initialState: Map<T, Set<string>> = new Map()) => {
  const [errors, setErrors] = useState<Map<T, Set<string>>>(initialState);

  /**
   * Appends a new error to the set of errors for the given key in the map.
   */
  const addError = (key: T, e: Error | string) =>
    setErrors((prevState) => {
      const errorText = e instanceof Error ? e.message : e;
      const nextState = new Map(prevState);
      if (nextState.has(key)) {
        const set = nextState.get(key);
        set?.add(errorText);
      } else {
        nextState.set(key, new Set([errorText]));
      }
      return nextState;
    });

  /**
   * Clears all the errors for the given key in the map.
   */
  const clearErrors = (key: T) =>
    setErrors((prevState) => {
      const nextState = new Map(prevState);
      if (nextState.has(key)) {
        nextState.delete(key);
      }
      return nextState;
    });

  /**
   * Resets the state by clearing all errors for every key in the map.
   */
  const resetErrors = () =>
    setErrors((prevState) => {
      const nextState = new Map(prevState);
      nextState.clear();
      return nextState;
    });

  return {
    errors,
    addError,
    clearErrors,
    resetErrors,
  };
};
