import { Box, Grommet } from 'grommet';

import { COLORS } from '../../styles/colors';

interface IconLabelProps {
  icon?: React.ReactNode;
  containerSize?: string;
}

const IconLabel = (props: IconLabelProps) => {
  const iconTheme = {
    global: {
      colors: {
        icon: COLORS.darkGray600,
      },
    },
    icon: {
      size: {
        small: '18px',
        medium: '24px',
        large: '28px',
        xlarge: '32px',
      },
      extend: `
      background-color: ${COLORS.transparent},
      `,
    },
  };
  const { containerSize = '26px' } = props;

  return (
    <Grommet theme={iconTheme}>
      <Box
        background={COLORS.white}
        round="full"
        height={containerSize}
        width={containerSize}
        justify="center"
        align="center"
      >
        {props.icon}
      </Box>
    </Grommet>
  );
};

export default IconLabel;
