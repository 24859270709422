import { ReactNode } from 'react';
import { Box } from 'grommet';
import _ from 'lodash';

export interface NavContentItem {
  label: string;
  component: ReactNode;
}

interface NavContentProps {
  activeItemLabel: string;
  navContentItems: NavContentItem[];
  width?: string;
  height?: string;
  overflow?: boolean;
}

const NavContent: React.FC<NavContentProps> = (props: NavContentProps) => {
  const activeContent = _.find(props.navContentItems, { label: props.activeItemLabel });
  const currentNavItem = activeContent?.component;

  return (
    <Box width={props.width} height={props.height} overflow={props.overflow ? 'auto' : ''}>
      {currentNavItem ?? null}
    </Box>
  );
};

export default NavContent;
