// @ts-strict-ignore
import { useEffect, useState } from 'react';
import { Box, Layer } from 'grommet';

import useAPI from '../../external/useAPI';
import { useErrorState } from '../../hooks/useErrorState';
import { StandardButton } from '../buttons';
import ErrorPanel from '../errors/ErrorPanel';
import LoadingPanel from '../panels/LoadingPanel';

interface DosespotEncounterIFrameModalProps {
  encounterId: string | null;
  onClose: () => void;
  onSync: (syncResult: Orders.Order[]) => void;
}

export const DosespotEncounterIFrameModal: React.FC<DosespotEncounterIFrameModalProps> = (
  props: DosespotEncounterIFrameModalProps,
) => {
  const { dosespotAPI } = useAPI();

  const [iframeURL, setIFrameURL] = useState<string>('');
  const [loading, setLoading] = useState<boolean>(true);
  const { errors, addError, clearErrors } = useErrorState();

  async function fetchSSOURL() {
    clearErrors();
    setLoading(true);
    try {
      const ssoURL = await dosespotAPI.fetchEncounterSSOURL(props.encounterId);
      setIFrameURL(ssoURL);
    } catch (err) {
      addError(err);
    } finally {
      setLoading(false);
    }
  }

  async function performDosespotSync() {
    clearErrors();
    setLoading(true);
    try {
      const syncResult = await dosespotAPI.postEncounterSync(props.encounterId);
      props.onClose();
      props.onSync(syncResult);
    } catch (err) {
      addError(err);
    } finally {
      setLoading(false);
    }
  }

  const onDone = () => {
    setIFrameURL('');
    performDosespotSync();
  };

  useEffect(() => {
    fetchSSOURL();
  }, []);

  return (
    <Layer modal>
      <Box width="xxlarge" height="xxlarge" pad="small" gap="small">
        <Box direction="row" justify="end" flex={false}>
          {errors.length > 0 ? (
            <StandardButton label="Close" onClick={props.onClose} />
          ) : (
            <StandardButton label="Done" onClick={onDone} isLoading={loading} />
          )}
        </Box>
        <ErrorPanel errors={errors} />
        <Box fill>
          <LoadingPanel showLoader={loading} />
          {iframeURL !== '' && (
            <iframe
              key="dosespot"
              src={iframeURL}
              title="dosespot"
              frameBorder="0"
              style={{ height: '100%', width: '100%' }}
            />
          )}
        </Box>
      </Box>
    </Layer>
  );
};

export default DosespotEncounterIFrameModal;
