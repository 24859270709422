import { Box, Text } from 'grommet';

import { COLORS } from 'styles/colors';

export const renderDownloadsCount = (downloadsCount: number, style: 'badge' | 'inline') => {
  const DOCUMENTS_REQUEST_LIMIT = 50;
  const text =
    downloadsCount >= DOCUMENTS_REQUEST_LIMIT ? `${DOCUMENTS_REQUEST_LIMIT}+` : downloadsCount;
  const showDownloadsCount = downloadsCount > 0;

  if (style === 'inline') {
    return (
      showDownloadsCount && (
        <Box
          background={COLORS.rittenBlue400}
          width={{ min: '20px' }}
          height={{ min: '20px' }}
          pad="0px 4px"
          style={{
            cursor: 'pointer',
          }}
          round="full"
          justify="center"
          align="center"
        >
          <Text size="11px" color={COLORS.white}>
            {text}
          </Text>
        </Box>
      )
    );
  }
  return (
    showDownloadsCount && (
      <div
        style={{
          textAlign: 'center',
          cursor: 'pointer',
          padding: '0px 4px',
          minWidth: '20px',
          backgroundColor: COLORS.rittenBlue400,
          borderRadius: '14px',
          position: 'absolute',
          top: -10,
          right: 3,
          fontSize: '11px',
          color: COLORS.white,
        }}
      >
        {text}
      </div>
    )
  );
};

export const getNotificationCount = (user: Users.FullUser): number => {
  const {
    changeRequestCount = 0,
    pendingPrescriptionCount = 0,
    refillRequestCount = 0,
    transmissionErrorsCount = 0,
  } = user.dosespotNotificationCounts || {};

  const aggregatedCount =
    changeRequestCount + pendingPrescriptionCount + refillRequestCount + transmissionErrorsCount;

  return Number.isNaN(aggregatedCount) ? 0 : aggregatedCount;
};
