import { useEffect, useState } from 'react';

import { ErrorPanel } from '@ritten/ui-library/errors';
import LoadingPanel from '@ritten/ui-library/panels/LoadingPanel';

import { useErrorState, useLoadingState } from 'hooks';
import useAPI from '../../external/useAPI';
import FeatureFlagContext from './FeatureFlagContext';

const FeatureFlagProvider: React.FC<{}> = (props: React.PropsWithChildren<{}>): JSX.Element => {
  const { featureFlagsAPI } = useAPI();
  const { errors, addError } = useErrorState();
  const { loading, needsLoadingState } = useLoadingState(false);

  const [allFlags, setAllFlags] = useState<FeatureFlags.FeatureFlag[]>([]);
  const [featureFlagMap, setFeatureFlagMap] = useState<Map<
    string,
    FeatureFlags.FeatureFlag
  > | null>(null);

  useEffect(() => {
    fetchInitialFeatureFlags();
  }, []);

  const fetchInitialFeatureFlags = needsLoadingState(async () => {
    await fetchFeatureFlags();
  });

  const fetchFeatureFlags = async () => {
    try {
      const allFeatureFlags = await featureFlagsAPI.getAllFlags();
      const newMap = new Map<string, FeatureFlags.FeatureFlag>();
      allFeatureFlags.forEach((ff) => {
        if (!newMap.get(ff.label)) {
          newMap.set(ff.label, ff);
        }
      });
      setAllFlags(allFeatureFlags);
      setFeatureFlagMap(newMap);
    } catch (error) {
      addError(error);
    }
  };
  const isFlagOn = (label: string): boolean => {
    const flag = featureFlagMap?.get(label);
    if (!flag) {
      return false;
    }
    return flag.isOn;
  };
  const refresh = () => {
    fetchFeatureFlags();
  };
  return (
    <>
      <LoadingPanel showLoader={loading} />
      {errors && <ErrorPanel errors={errors} />}
      {featureFlagMap && (
        <FeatureFlagContext.Provider
          value={{
            flags: allFlags,
            isFlagOn,
            refresh,
          }}
        >
          {props.children}
        </FeatureFlagContext.Provider>
      )}
    </>
  );
};

export default FeatureFlagProvider;
