import * as ReactDOM from 'react-dom';
import FadeIn from 'react-fade-in/lib/FadeIn';
import { Helmet } from 'react-helmet';
import { Route, Switch } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';
import { Box, Grommet } from 'grommet';

import UserInactiveModal from 'common/modals/UserInactiveModal';
import useUIConfig from 'context/config/useUIConfig';
import LogRocketProvider from 'context/logrocket/LogRocketProvider';
import MobileDeviceProvider from 'context/mobileDevice/MobileDeviceProvider';
import SetPasswordPage from 'features/auth/SetPasswordPage';
import PublicLeadApplication from 'features/lead-application/PublicLeadApplication';
import RedirectPage from 'features/redirect/RedirectPage';
import ClinicApp from './applications/clinic/app';
import PortalApp from './applications/portal/app';
import { ReactComponent as RittenLogo } from './assets/ritten_full_color_banner.svg';
import { Auth0App } from './context/auth0/Auth0App';
import Auth0UserProvider from './context/auth0/Auth0UserProvider';
import useAuth0User from './context/auth0/useAuth0User';
import UIConfigProvider from './context/config/UIConfigProvider';
import { APP_THEME } from './styles/apptheme';
import { COLORS } from './styles/colors';
import reportWebVitals from './third-party/report-web-vitals';
import { useSentry } from './third-party/sentry';
import LoadingPanel from './ui-library/panels/LoadingPanel';

import './styles/font.css';
import './styles/scrollbar.css';
import './styles/reactHotToastStyles.css';
import './third-party/cache';
import './third-party/chartjs';
import 'polyfills';

const AuthenticatedApp = () => {
  const { isPatient, userInactive, getTokenAndSetExpirationDate } = useAuth0User();

  const AppType = isPatient ? PortalApp : ClinicApp;

  return (
    <>
      <AppType />
      {userInactive && <UserInactiveModal onContinue={getTokenAndSetExpirationDate} />}
    </>
  );
};

const SecureApp = () => {
  const { config } = useUIConfig();

  const { isLoading, error, logout, isAuthenticated, loginWithRedirect } = useAuth0();

  useSentry();

  if (isLoading) {
    return <LoadingPanel showLoader />;
  }
  if (error) {
    logout({
      returnTo: window.location.origin,
    });
    return null;
  }
  if (!isAuthenticated) {
    return (
      <Box fill justify="center" align="center" background={COLORS.darkGray200}>
        <FadeIn
          delay={0}
          transitionDuration={800}
          onComplete={() => {
            loginWithRedirect();
          }}
        >
          <RittenLogo />
        </FadeIn>
      </Box>
    );
  }
  return (
    <Auth0UserProvider>
      <Helmet>
        {config.googlePlacesApiKey && (
          <script
            src={`https://maps.googleapis.com/maps/api/js?key=${config.googlePlacesApiKey}&libraries=places&loading=async&callback=initAutocomplete`}
            async
          />
        )}
      </Helmet>
      <AuthenticatedApp />
    </Auth0UserProvider>
  );
};

const App = () => {
  return (
    // Grommet full doesn't work in safari.  100vw and 100vh doesn't account for the url bar and makes the app too large.
    <Grommet theme={APP_THEME} style={{ width: '100%', height: '100%' }}>
      <UIConfigProvider>
        <LogRocketProvider>
          <Auth0App>
            <MobileDeviceProvider>
              <Switch>
                <Route path="/set-password" exact component={SetPasswordPage} />
                <Route path="/r/:shortId" exact component={RedirectPage} />
                <Route path="/lead-application" exact component={PublicLeadApplication} />
                <Route
                  path="/lead-application/:leadAppInstanceId"
                  exact
                  component={PublicLeadApplication}
                />
                <Route component={SecureApp} />
              </Switch>
            </MobileDeviceProvider>
          </Auth0App>
        </LogRocketProvider>
      </UIConfigProvider>
    </Grommet>
  );
};
const root = document.getElementById('app');
ReactDOM.render(<App />, root);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
