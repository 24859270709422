import { Text } from 'grommet';

import { COLORS } from 'styles/colors';
import { DropdownChoice, getChoiceLabel, Variant } from '../Dropdown';
import AnchorContainer from './AnchorContainer';

interface SingleChoiceAnchorProps {
  disabled: boolean;
  onDropClick: () => void;
  anchorRef: any;
  selection: DropdownChoice;
  placeholderText?: string;
  width?: string | { min?: string; max?: string };
  gap?: string;
  choiceLabelKey?: string;
  onSelectChoice: (choice: DropdownChoice) => void;
  showClearSelection: boolean;
  variant: Variant;
  renderSelection?: (choice: DropdownChoice) => React.ReactNode;
  hasError?: boolean;
  iconSize?: string;
  iconColor?: string;
  truncateSelectionText?: boolean;
  borderColor?: string;
}

const SingleChoiceAnchor = (props: SingleChoiceAnchorProps) => {
  const {
    selection,
    placeholderText,
    choiceLabelKey,
    renderSelection,
    truncateSelectionText = false,
  } = props;

  const selectionText = getChoiceLabel(selection, choiceLabelKey);

  return (
    <AnchorContainer {...props}>
      {renderSelection && selection ? (
        renderSelection(selection)
      ) : (
        <Text
          size="16px"
          color={selection ? COLORS.darkGray600 : 'rgba(0, 0, 0, .33)'}
          wordBreak="break-word"
          truncate={truncateSelectionText}
        >
          {selectionText || placeholderText || 'Select...'}
        </Text>
      )}
    </AnchorContainer>
  );
};

export default SingleChoiceAnchor;
