import { createContext, useContext, useState } from 'react';

import usePersistedState from 'hooks/usePersistedState';

const PatientEventContext = createContext<
  | {
      patientHeaderUpdateEvent: boolean;
      triggerPatientHeaderUpdate: () => void;
      bpiUpdateEvent: boolean;
      triggerBpiUpdate: () => void;
      triggerExport: () => void;
      exportEvent: number;
      downloadEvent: boolean;
      triggerDownloadEvent: () => void;
      updatedDoseEvent: boolean;
      triggerUpdatedDoseEvent: () => void;
      insuranceUpdate: boolean;
      triggerInsuranceUpdate: () => void;
      referralUpdate: boolean;
      triggerReferralUpdate: () => void;
      copayUpdate: boolean;
      triggerCopayUpdate: () => void;
      roundsUpdate: boolean;
      triggerRoundsUpdate: () => void;
      isGuardianRequiredUpdate: boolean;
      triggerIsGuardianRequiredUpdate: () => void;
    }
  | undefined
>(undefined);

type PatientProviderProps = React.PropsWithChildren<{}>;

const PatientEventProvider: React.FC<PatientProviderProps> = ({
  children,
}: PatientProviderProps) => {
  const [patientHeaderUpdateEvent, setPatientHeaderUpdateEvent] = useState<boolean>(false);
  const [bpiUpdateEvent, setBpiUpdateEvent] = useState<boolean>(false);
  const [exportEvent, setExportEvent] = useState<number>(0);
  const [downloadEvent, setDownloadEvent] = useState<boolean>(false);
  const [insuranceUpdate, setInsuranceUpdate] = useState<boolean>(false);
  const [referralUpdate, setReferralUpdate] = useState<boolean>(false);
  const [copayUpdate, setCopayUpdate] = useState<boolean>(false);
  const [roundsUpdate, setRoundsUpdate] = useState<boolean>(false);
  const [isGuardianRequiredUpdate, setIsGuardianRequiredUpdate] = useState<boolean>(false);

  // Using usePersistedState for this allows other open windows on pages listening for this event
  // to update as well because internally the hook listens for storage changes - this helps keep med pass updated
  const [updatedDoseEvent, setUpdatedDoseEvent] = usePersistedState<boolean>({
    localStorageKey: 'RITTEN_LOGGED_DOSE_EVENT_TRACKER', // note: storage key name does not accurately reflect tracking of editing and signing doses
    initialData: false,
    options: {
      syncData: true,
    },
  });

  const triggerPatientHeaderUpdate = () => {
    setPatientHeaderUpdateEvent((prevState) => {
      return !prevState;
    });
  };
  const triggerBpiUpdate = () => setBpiUpdateEvent(!bpiUpdateEvent);

  const triggerRoundsUpdate = () => setRoundsUpdate(!roundsUpdate);

  const triggerIsGuardianRequiredUpdate = () =>
    setIsGuardianRequiredUpdate(!isGuardianRequiredUpdate);

  const value = {
    patientHeaderUpdateEvent,
    triggerPatientHeaderUpdate,
    bpiUpdateEvent,
    triggerBpiUpdate,
    triggerExport: () => setExportEvent(exportEvent + 1),
    exportEvent,
    downloadEvent,
    triggerDownloadEvent: () => setDownloadEvent(!downloadEvent),
    updatedDoseEvent,
    triggerUpdatedDoseEvent: () => setUpdatedDoseEvent(!updatedDoseEvent),
    insuranceUpdate,
    triggerInsuranceUpdate: () => {
      setInsuranceUpdate((prev) => !prev);
    },
    referralUpdate,
    triggerReferralUpdate: () => {
      setReferralUpdate((prev) => !prev);
    },
    copayUpdate,
    triggerCopayUpdate: () => {
      setCopayUpdate((prev) => !prev);
    },
    roundsUpdate,
    triggerRoundsUpdate,
    isGuardianRequiredUpdate,
    triggerIsGuardianRequiredUpdate,
  };

  return <PatientEventContext.Provider value={value}>{children}</PatientEventContext.Provider>;
};

const usePatientEventContext = () => {
  const context = useContext(PatientEventContext);
  if (context === undefined) {
    throw new Error('usePatientEventContext must be used within a PatientProvider');
  }
  return context;
};

export { PatientEventProvider, usePatientEventContext };
