import { Suspense } from 'react';
import { Redirect, Switch } from 'react-router-dom';
import { Box } from 'grommet';

import LoadingPanel from '@ritten/ui-library/panels/LoadingPanel';

import ProtectedRoute from 'features/nav/ProtectedRoute';
import { lazyWithRetry } from 'utils/lazyLoadUtils';

const AdminPage = lazyWithRetry(() => import('../../features/admin/AdminPage'));
const CalendarRouterPage = lazyWithRetry(
  () => import('../../features/calendar/CalendarRouterPage'),
);
const ClientsPage = lazyWithRetry(() => import('../../features/clients/ClientsPage'));
const InsightsPage = lazyWithRetry(() => import('../../features/insights/InsightsPage'));
const UserPage = lazyWithRetry(() => import('../../features/user-profile/UserPage'));
const BillingPage = lazyWithRetry(() => import('../../features/billing/BillingPage'));
const CompliancePage = lazyWithRetry(() => import('../../features/compliance/CompliancePage'));
const CRMPage = lazyWithRetry(() => import('../../features/crm/CRMPage'));
const HomePageLanding = lazyWithRetry(() => import('../../features/home/HomePageLanding'));
const MultiTenantNavPage = lazyWithRetry(() => import('../../features/nav/MultiTenantNavPage'));
const CallbackPage = lazyWithRetry(() => import('../../features/callback/CallbackPage'));

/* eslint-disable */
const Routes = () => {
  return (
    <Suspense
      fallback={
        <Box fill align="center" justify="center">
          <LoadingPanel showLoader fill />
        </Box>
      }
    >
      <Switch>
        <ProtectedRoute path="/crm" component={CRMPage} />
        <ProtectedRoute path="/records" component={ClientsPage} />
        <ProtectedRoute path="/compliance" component={CompliancePage} />
        <ProtectedRoute path="/calendar" component={CalendarRouterPage} />

        <ProtectedRoute path="/insights" component={InsightsPage} />
        <ProtectedRoute path="/admin" component={AdminPage} />
        <ProtectedRoute path="/billing" component={BillingPage} />
        <ProtectedRoute path="/user" component={UserPage} />
        <ProtectedRoute path="/home" component={HomePageLanding} />
        <ProtectedRoute path="/navigation" component={MultiTenantNavPage} skipPolicyCheck />
        <ProtectedRoute path="/callback" component={CallbackPage} />
        <Redirect to="/home" />
      </Switch>
    </Suspense>
  );
};
/* eslint-enable */
export default Routes;
