import { ReactNode } from 'react';
import { Box, DropProps, grommet, ThemeContext, Tip } from 'grommet';
import { deepMerge, DirectionType, PadType } from 'grommet/utils';

import { COLORS } from 'styles/colors';

export const TipContentWithCaret = ({ backgroundColor, tooltipContent, tooltipAlign }) => {
  if (tooltipAlign === 'top' || tooltipAlign === 'bottom') {
    const styleConfig =
      tooltipAlign === 'top'
        ? { transformRotation: 'rotate(90deg) translateX(-8px)', boxDirection: 'column' }
        : { transformRotation: 'rotate(-90deg) translateX(-8px)', boxDirection: 'column-reverse' };
    return (
      <Box direction={styleConfig.boxDirection as DirectionType} align="center">
        <Box pad="small" round="xsmall" background={backgroundColor}>
          {tooltipContent}
        </Box>
        <Box style={{ transform: styleConfig.transformRotation }}>
          <svg viewBox="0 0 22 22" version="1.1" width="22px" height="22px">
            <polygon fill={backgroundColor} points="6 2 18 12 6 22" />
          </svg>
        </Box>
      </Box>
    );
  }
  const styleConfig =
    tooltipAlign === 'left'
      ? { transformRotation: 'rotate(0deg) translateX(-8px)', boxDirection: 'row-reverse' }
      : { transformRotation: 'rotate(180deg) translateX(-8px)', boxDirection: 'row' };
  return (
    <Box direction={styleConfig.boxDirection as DirectionType} align="center">
      <Box style={{ transform: styleConfig.transformRotation }}>
        <svg viewBox="0 0 22 22" version="1.1" width="22px" height="22px">
          <polygon fill={backgroundColor} points="6 2 18 12 6 22" />
        </svg>
      </Box>
      <Box direction="row" pad="small" round="xsmall" background={backgroundColor}>
        {tooltipContent}
      </Box>
    </Box>
  );
};

export const getDropProps = (alignment: string): DropProps => {
  switch (alignment) {
    case 'top':
      return { align: { bottom: 'top' } };
    case 'bottom':
      return { align: { top: 'bottom' } };

    case 'left':
      return { align: { right: 'left' } };

    case 'right':
      return { align: { left: 'right' } };

    default:
      return { align: { bottom: 'top' } };
  }
};

type CustomTooltipProps = React.PropsWithChildren<{
  showCaret?: boolean;
  tooltipAlign: 'top' | 'bottom' | 'left' | 'right';
  tooltipBackgroundColor: string;
  tooltipContent: ReactNode;
  hideTooltip?: boolean; // used when there is a conditional reason for preventing the tooltip from showing
  isButton?: boolean;
  tooltipContentPadding?: PadType;
}>;

const CustomTooltip: React.FC<CustomTooltipProps> = (props: CustomTooltipProps) => {
  const {
    tooltipAlign,
    tooltipBackgroundColor,
    tooltipContent,
    showCaret,
    hideTooltip = false,
    isButton = false,
    tooltipContentPadding,
  } = props;

  const themeTooltip = deepMerge(grommet, {
    tip: {
      content: {
        background: tooltipBackgroundColor,
      },
    },
  });

  if (hideTooltip) {
    return <>{props.children}</>;
  }

  return (
    <ThemeContext.Extend value={themeTooltip}>
      <Tip
        dropProps={getDropProps(tooltipAlign)}
        content={
          showCaret ? (
            <TipContentWithCaret
              backgroundColor={tooltipBackgroundColor ?? COLORS.white}
              tooltipAlign={tooltipAlign}
              tooltipContent={tooltipContent}
            />
          ) : (
            <Box
              pad={tooltipContentPadding ?? 'small'}
              round="xsmall"
              background={tooltipBackgroundColor}
            >
              {tooltipContent}
            </Box>
          )
        }
        plain={showCaret}
      >
        {/* Using the isButton prop will allow the tooltip to show on disabled  */}
        {/* buttons by hooking into the Box's mouse events and not the button's.  */}
        {isButton ? <Box flex={false}>{props.children}</Box> : props.children}
      </Tip>
    </ThemeContext.Extend>
  );
};

export default CustomTooltip;
