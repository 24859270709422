import { head, upperCase } from 'lodash';
import minimatch from 'minimatch';

import useUIConfig from 'context/config/useUIConfig';

const SUPER_USER_ONLY_PAGES = ['/admin/config', '/admin/config/feature-flags'];

// use this glob match tool
// https://www.digitalocean.com/community/tools/glob?comments=true&glob=%2F%7Brecords%7B%2F%2A%2C%2F%2A%2Fchart%7D%2Cadmin%7B%2A%2C%2F%21%28permissions%29%7D%7D&matches=false&tests=%2Frecords&tests=%2Frecords%2F%3ApatientId&tests=%2Frecords%2F%3ApatientId%2Fchart&tests=%2Frecords%2F%3ApatientId%2Ftrends&tests=%2Fadmin&tests=%2Fadmin%2Fencounters&tests=%2Fadmin%2Fpermissions
export function canUserAccessPath(user: Users.FullUser, pagePath: string): boolean {
  const { config } = useUIConfig();

  if (['beta', 'prod'].includes(config.env) && SUPER_USER_ONLY_PAGES.includes(pagePath)) {
    return user.isSuper;
  }

  let can = false;
  for (const policy of user.pagePolicies) {
    if (policy.resource === '*') {
      // user has access to all pages with super policy
      can = true;
      break;
    }
    if (policy.resource === 'page') {
      // page policy
      if (policy.filter === 'n/a') {
        // there is no path filter on the page policy
        // so the user has access to all page paths
        can = true;
        break;
      }
      // else evaluate the filter glob against the pagePath
      can = minimatch(pagePath, policy.filterGlob);
      if (can) {
        break;
      } // short circuit on first policy to allow
    }
  }
  return can;
}

export const hasAccessPolicy = (user: Users.FullUser, policyResource: string): boolean => {
  if (!user.accessPolicies || user.accessPolicies.length < 1) {
    return false;
  }
  for (const policy of user.accessPolicies) {
    if (policy.resource === policyResource && !policy.isDeny) {
      return true;
    }
  }
  return false;
};

export const getUserInitials = (user: Users.FullUser | Patient.PatientName): string => {
  const hasFirstName = user.first !== '';
  const hasLastName = user.last !== '';
  if (hasFirstName || hasLastName) {
    let initials = '';
    if (hasFirstName) {
      initials += upperCase(head(user.first));
    }
    if (hasLastName) {
      initials += upperCase(head(user.last));
    }
    return initials;
  }

  if ('email' in user) {
    return upperCase(head(user.email));
  }

  // Unlikely (impossible?) case in which no properties are available to generate initials
  return '';
};
