export const parsePlaceResult = (place: any) => {
  let address1 = '';
  let postcode = '';
  let state = '';
  let city = '';
  let country = '';

  place.address_components.forEach((component) => {
    const componentType = component.types[0];

    switch (componentType) {
      case 'street_number': {
        address1 = `${component.long_name} ${address1}`;
        break;
      }

      case 'route': {
        address1 += component.short_name;
        break;
      }

      case 'postal_code': {
        postcode = `${component.long_name}${postcode}`;
        break;
      }

      case 'postal_code_suffix': {
        postcode = `${postcode}-${component.long_name}`;
        break;
      }

      case 'locality':
        city = component.long_name;
        break;

      case 'administrative_area_level_1': {
        state = component.short_name;
        break;
      }

      case 'country':
        country = component.short_name;
        break;

      default:
        break;
    }
  });

  return { address1, postcode, state, city, country };
};

export const isAutocompleteSelection = (target: HTMLDivElement | HTMLSpanElement): boolean => {
  return (
    target.classList.contains('pac-container') ||
    target.classList.contains('pac-matched') ||
    target.classList.contains('pac-item') ||
    target.classList.contains('pac-item-query') ||
    target.classList.length === 0
  );
};
