// @ts-strict-ignore
import { Box } from 'grommet';
import { Down, Up } from 'grommet-icons';

import { COLORS } from 'styles/colors';

interface UpDownButtonsProps {
  fieldIdx: number;
  moveField: (idx: number, direction: 'up' | 'down') => void;
  numFields: number;
}

const UpDownButtons = (props: UpDownButtonsProps) => {
  const isUpDisabled = props.fieldIdx === 0;
  const isDownDisabled = props.fieldIdx === props.numFields - 1;
  return (
    <Box direction="row" align="center" gap="18px">
      <Box
        align="center"
        direction="row"
        justify="between"
        onClick={isUpDisabled ? null : () => props.moveField(props.fieldIdx, 'up')}
      >
        <Up size="12px" color={isUpDisabled ? COLORS.darkGray400 : COLORS.darkGray600} />
      </Box>
      <Box
        align="center"
        direction="row"
        justify="between"
        onClick={isDownDisabled ? null : () => props.moveField(props.fieldIdx, 'down')}
      >
        <Down size="12px" color={isDownDisabled ? COLORS.darkGray400 : COLORS.darkGray600} />
      </Box>
    </Box>
  );
};

export default UpDownButtons;
