import { useState } from 'react';
import * as Sentry from '@sentry/react';

import useUIConfig from 'context/config/useUIConfig';
import LogRocketContext from './LogRocketContext';

const LogRocket = require('logrocket/setup')({
  sdkServer: 'https://logrocket.ritten.io',
  ingestServer: 'https://logrocket.ritten.io',
});

const LogRocketProvider: React.FC<{}> = (props: React.PropsWithChildren<{}>): JSX.Element => {
  const { config } = useUIConfig();
  const { env } = config;
  const [sessionUrl, setSessionUrl] = useState<string | null>(null);

  // All usage of initializeLogRocket should be behind LR feature flag
  const initializeLogRocket = (id, traits) => {
    if (env === 'prod') {
      LogRocket.init('ritten/valinor-prod');
      LogRocket.getSessionURL((url) => {
        setSessionUrl(url);
        Sentry.getCurrentScope().setExtra('sessionURL', url);
      });
      identify(id, traits);
    }
  };

  const identify = (id, traits) => {
    LogRocket.identify(id, traits);
  };

  // No need to put usage behind LR feature flag since it will just be handled by returning the initial null value
  const getSessionUrl = () => {
    return sessionUrl;
  };

  // All usage of track should be behind LR feature flag
  const track = (eventName: string, options?: object) => {
    LogRocket.track(eventName, options ?? {});
  };

  return (
    <LogRocketContext.Provider value={{ initializeLogRocket, getSessionUrl, track }}>
      {props.children}
    </LogRocketContext.Provider>
  );
};

export default LogRocketProvider;
