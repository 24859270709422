import { Suspense } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import { Box } from 'grommet';

import LoadingPanel from '@ritten/ui-library/panels/LoadingPanel';

import PortalClientIdPage from 'features/portal-dashboard/PortalClientIdPage';
import PortalClientsPage from 'features/portal-dashboard/PortalClientsPage';
import { getPortalPageRootUrl } from 'features/portal-dashboard/utils';
import { lazyWithRetry } from 'utils/lazyLoadUtils';

const FormViewerPage = lazyWithRetry(() => import('../../features/portal-dashboard/FormViewer'));

interface AppRoutesProps {
  accessibleContacts: Users.PortalAuthorization[];
}

export const AppRoutes = (props: AppRoutesProps) => {
  const { accessibleContacts } = props;

  const singleClientId = accessibleContacts[0]?.patient.patientId;

  const redirectUrl =
    accessibleContacts.length === 1
      ? `${getPortalPageRootUrl('forms', singleClientId)}/forms`
      : `${getPortalPageRootUrl('clients')}/clients`;

  return (
    <Suspense
      fallback={
        <Box fill align="center" justify="center">
          <LoadingPanel showLoader />
        </Box>
      }
    >
      <Switch>
        <Route exact path="/portal/clients">
          <PortalClientsPage accessibleContacts={accessibleContacts} />
        </Route>
        <Route exact path="/portal/:clientId/forms/:formId" component={FormViewerPage} />
        <Route path="/portal/:clientId">
          <PortalClientIdPage accessibleContacts={accessibleContacts} />
        </Route>
        <Redirect to={redirectUrl} />
      </Switch>
    </Suspense>
  );
};
