import heic2any from 'heic2any';

/**
 *
 * @param file the file to be checked for heic and converted as needed
 * @returns jpg file
 */
export const maybeTransformHEICToJPGFile = async (file: File): Promise<File> => {
  if (/image\/hei(c|f)/.test(file.type)) {
    const blob: Blob = file;
    const jpgBlob = await heic2any({ blob, toType: 'image/jpeg', quality: 1 });
    // Change the name of the file according to the new format
    const newName = file.name.replace(/\.[^/.]+$/, '.jpg');
    const newFile: any = jpgBlob;
    newFile.lastModified = file.lastModified;

    // Convert blob back to file
    return new File([newFile], newName);
  }
  return file;
};

/**
 *
 * @param blob the blob to be checked for heic and converted as needed
 * @returns jpg blob
 */
export const maybeTransformHEICToJPGBlob = async (blob: Blob): Promise<Blob> => {
  if (/image\/hei(c|f)/.test(blob.type)) {
    const jpgBlob = await heic2any({ blob, toType: 'image/jpeg', quality: 1 });
    return jpgBlob as Blob;
  }
  return blob;
};

/**
 * Takes a file name and attempts to find and remove the file extension before returning it
 * @param fileName
 * @returns file name without extension
 */
export const maybeRemoveFileExtension = (fileName: string) => {
  const lastIndex = fileName.lastIndexOf('.');
  if (lastIndex) {
    return fileName.slice(0, lastIndex);
  }
  return fileName;
};
