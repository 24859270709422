import { parseISODateStrings } from 'utils';
import RittenClient from './RittenClient';

class ClaimsClient extends RittenClient {
  postClaims = async (dailyBillableServiceIds: string[] = [], type?: Claims.ClaimType) => {
    const res = await this.post<API.IDResponse>('/api/claims/instances', {
      type,
      dailyBillableServiceIds,
    });
    return res.data;
  };

  postClaimsV2 = async (dailyBillableServiceIds: string[], type?: Claims.ClaimType) => {
    const res = await this.post<API.IDResponse>('/api/claims/v2/instances', {
      type,
      dailyBillableServiceIds,
    });
    return res.data;
  };

  listClaims = async (params: Claims.ListClaimsQuery) => {
    const res = await this.get<Claims.ListClaimsResponse>('/api/claims/instances', {
      params,
    });

    const { claims, totalCount } = res.data;

    return { claims: parseISODateStrings(claims), totalCount };
  };

  archiveClaim = async (claimId: string) => {
    await this.delete(`/api/claims/instances/${claimId}`);
  };

  getInstitutionalClaim = async (id: string) => {
    const res = await this.get<Claims.InstitutionalClaim.InstitutionalClaimForm>(
      `/api/claims/instances/${id}/institutional`,
    );

    return parseISODateStrings(res.data);
  };

  getProfessionalClaim = async (id: string) => {
    const res = await this.get<Claims.InstitutionalClaim.InstitutionalClaimForm>( // todo: create separate type for professional
      `/api/claims/instances/${id}/professional`,
    );

    return parseISODateStrings(res.data);
  };

  sendClaimToCollaborateMD = async (claimId: string) => {
    await this.post<API.IDResponse>('/api/claims/integrations/collabmd', { claimId });
  };

  getCMS1500 = async (id: string) => {
    const res = await this.get<Claims.CMS1500>(`/api/claims/v2/instances/${id}/cms1500`);
    return parseISODateStrings(res.data);
  };

  putCMS1500 = async (putBody: Claims.PutCMS1500) => {
    const res = await this.put<Claims.PutCMS1500>(
      `/api/claims/v2/instances/${putBody.id}/cms1500`,
      putBody,
    );
    return parseISODateStrings(res.data);
  };

  getUB04 = async (id: string) => {
    const res = await this.get<Claims.UB04>(`/api/claims/v2/instances/${id}/ub04`);
    return parseISODateStrings(res.data);
  };

  putUB04 = async (putBody: Claims.PutUB04) => {
    const res = await this.put<Claims.PutUB04>(
      `/api/claims/v2/instances/${putBody.id}/ub04`,
      putBody,
    );
    return parseISODateStrings(res.data);
  };

  getEDI = async (id: string) => {
    const res = await this.get<Claims.X12Response>(`/api/claims/v2/instances/${id}/x12`);
    return parseISODateStrings(res.data);
  };

  validateClaimBasic = async (claimId: string) => {
    await this.post<API.IDResponse>(`/api/claims/v2/instances/${claimId}/validate-basic`);
  };

  validateClaimAdvanced = async (claimId: string) => {
    await this.post<API.IDResponse>(`/api/claims/v2/instances/${claimId}/validate-advanced`);
  };

  getClaimStatusChange = async (claimId: string) => {
    await this.post<API.IDResponse>(`/api/claims/v2/instances/${claimId}/status`);
  };

  getClaimStatusStedi = async (claimId: string) => {
    await this.post<API.IDResponse>(`/api/claims/v2/instances/${claimId}/status-stedi`);
  };

  submitClaimToClearinghouse = async (claimId: string) => {
    const res = await this.post<any>(`/api/claims/v2/instances/${claimId}/submit`);
    return res.data;
  };

  submitClaimToStedi = async (claimId: string) => {
    const res = await this.post<any>(`/api/claims/v2/instances/${claimId}/submit-stedi`);
    return res.data;
  };

  getReportsFromClearinghouse = async () => {
    await this.post(`/api/claims/v2/reports/fetch`);
  };

  getClaimPayments = async (claimId: string): Promise<Claims.ClaimPayment> => {
    const { data } = await this.get<Claims.ClaimPayment>(
      `/api/claims/v2/instances/${claimId}/payments`,
    );
    return data;
  };

  patchClaimServiceItemStatus = async (
    claimId: string,
    serviceItemId: string,
    status: Remittances.ServiceStatus,
  ) => {
    await this.patch(`api/claims/v2/instances/${claimId}/items/${serviceItemId}/status`, {
      status,
    });
  };

  deleteClaimServiceItem = async (claimId: string, serviceItemId: string) => {
    await this.delete(`api/claims/v2/instances/${claimId}/items/${serviceItemId}`);
  };

  getClaimHeader = async (claimId: string): Promise<Claims.ClaimHeader> => {
    const { data } = await this.get<Claims.ClaimHeader>(`api/claims/v2/instances/${claimId}`);
    return data;
  };

  runCollabmdReports = async () => {
    await this.post(`/api/claims/collabmd-reports/run`);
  };

  getClaimStatusDetails = async (claimId: string) => {
    const res = await this.get<Claims.StatusDetailsResponse>(
      `/api/claims/v2/instances/${claimId}/status-details`,
    );
    return res.data;
  };

  getClaimHistory = async (
    claimId: string,
    params: { limit: number; offset: number },
  ): Promise<Claims.ClaimEventsResponse> => {
    const { data } = await this.get<Claims.ClaimEventsResponse>(
      `/api/claims/v2/instances/${claimId}/events`,
      { params },
    );
    return parseISODateStrings(data);
  };

  mockFetchClaimReports = async () => {
    const res = await this.get<any>(`api/claims/v2/reports/mock`);
    return res.data;
  };

  listClaimsV2 = async (
    params: Claims.ListClaimsV2QueryParams,
  ): Promise<Claims.ListClaimsV2Response> => {
    const res = await this.get<Claims.ListClaimsV2Response>(`/api/claims/v2/instances`, {
      params,
    });
    return res.data;
  };

  generateMockReportUrl = async (body: {
    fileName: string;
    contentType: string;
  }): Promise<{ url: string }> => {
    const res = await this.post<{ url: string }>(`/api/claims/v2/reports/mock/url`, body);
    return res.data;
  };
}

export default ClaimsClient;
