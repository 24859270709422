// @ts-strict-ignore
import { MutableRefObject, useEffect, useRef, useState } from 'react';
import { format, set } from 'date-fns';
import {
  Box,
  Button,
  DropButton,
  grommet,
  HeaderProps,
  Text,
  TextProps,
  ThemeContext,
} from 'grommet';
import { deepMerge } from 'grommet/utils';
import { FormNext, FormPrevious } from 'grommet-icons';
import { range } from 'lodash';
import useOnClickOutside from 'use-onclickoutside';

import { COLORS, DROP_SHADOW } from 'styles/colors';
import { LinkButton } from '../buttons';
import type { ButtonColor } from '../buttons/constants';

interface YearsDropdownProps {
  options: number[];
  onClickYear: (year: number) => void;
  onClose: () => void;
  currentYear: number;
  yearRef: MutableRefObject<HTMLDivElement>;
}

const YearsDropdown = (props: YearsDropdownProps) => {
  const { currentYear, onClose, onClickYear, yearRef } = props;

  return (
    <Box
      direction="row"
      wrap
      pad="medium"
      style={{
        position: 'absolute',
        left: 0,
        zIndex: 100,
        borderRadius: '8px',
        boxShadow: DROP_SHADOW,
      }}
      margin={{ top: '50px' }}
      height={{ max: '300px' }}
      width="300px"
      overflow="scroll"
      background={COLORS.white}
    >
      {props.options?.map((option) => (
        <Box
          key={option}
          hoverIndicator
          onClick={() => {
            onClickYear(option);
            onClose();
          }}
          margin="12px"
          ref={currentYear === option ? yearRef : null}
        >
          <Text
            color={currentYear === option ? COLORS.rittenBlue400 : COLORS.darkGray600}
            size="15px"
          >
            {option}
          </Text>
        </Box>
      ))}
    </Box>
  );
};

interface CalendarHeaderProps {
  calendarRenderHeaderProps: HeaderProps;
  arrowColor?: ButtonColor;
  headingTextStyle?: TextProps;
  daysOfWeekTextStyle?: TextProps;
  calendarRef?: MutableRefObject<any>;
  showHeaderDateDrop?: boolean;
  onDateChange?: (dateString: string) => void;
  showDaysOfWeek?: boolean;
}

const dropButtonTheme = deepMerge(grommet, {
  button: {
    extend: `
        padding: 0;
        `,
  },
});

const CalendarHeader = (props: CalendarHeaderProps) => {
  const {
    calendarRenderHeaderProps,
    arrowColor = 'text',
    headingTextStyle = {},
    daysOfWeekTextStyle = {},
    showHeaderDateDrop = false,
    onDateChange,
    showDaysOfWeek = false,
  } = props;
  const { onPreviousMonth, onNextMonth, date } = calendarRenderHeaderProps;

  const month = format(date, 'MMMM');
  const year = format(date, 'y');
  const currentYear = new Date().getFullYear();
  const yearsList = range(currentYear - 100, currentYear + 50);
  const dropRef = useRef();
  const yearRef = useRef<null | HTMLDivElement>(null);
  const [monthDropOpen, setMonthDropOpen] = useState<boolean>(false);
  const [yearDropOpen, setYearDropOpen] = useState<boolean>(false);

  useEffect(() => {
    if (yearDropOpen && yearRef && yearRef.current) {
      try {
        yearRef.current.scrollIntoView({ behavior: 'smooth', block: 'center' });
      } catch (error) {
        yearRef.current.scrollIntoView(false);
      }
    }
  }, [yearDropOpen]);

  const yearDropRef = useRef();
  const onCloseYearDrop = () => setYearDropOpen(false);
  useOnClickOutside(yearDropRef, onCloseYearDrop);

  return (
    <Box gap="20px">
      <Box direction="row" justify="between" align="center">
        <LinkButton
          padding={{ top: '2px' }}
          color={arrowColor}
          icon={<FormPrevious color={arrowColor} />}
          onClick={onPreviousMonth}
          data-testid="calendar-date-picker-previous-month"
        />
        {showHeaderDateDrop ? (
          <ThemeContext.Extend value={dropButtonTheme}>
            <Box direction="row">
              <DropButton
                dropTarget={dropRef.current}
                margin={{ right: '10px' }}
                label={<Text {...headingTextStyle}>{month}</Text>}
                open={monthDropOpen}
                onOpen={() => setMonthDropOpen(true)}
                onClose={() => setMonthDropOpen(false)}
                dropAlign={{ top: 'top' }}
                dropContent={
                  <Box
                    background={COLORS.white}
                    width="300px"
                    direction="row"
                    wrap
                    pad="medium"
                    data-testid="month-selector"
                  >
                    {[
                      'January',
                      'February',
                      'March',
                      'April',
                      'May',
                      'June',
                      'July',
                      'August',
                      'September',
                      'October',
                      'November',
                      'December',
                    ].map((monthName) => (
                      <Button
                        key={monthName}
                        onClick={() => {
                          const newMonthDate = new Date(`${monthName} 1, ${year}`);
                          onDateChange(newMonthDate.toISOString());
                          setMonthDropOpen(false);
                        }}
                        margin="12px"
                        hoverIndicator
                      >
                        <Text
                          color={monthName === month ? COLORS.rittenBlue400 : COLORS.darkGray600}
                          size="15px"
                        >
                          {monthName}
                        </Text>
                      </Button>
                    ))}
                  </Box>
                }
                color="transparent"
              ></DropButton>
              <Box ref={yearDropRef}>
                <Button
                  label={<Text {...headingTextStyle}>{year}</Text>}
                  onClick={() => setYearDropOpen(!yearDropOpen)}
                  color="transparent"
                />
                {yearDropOpen && (
                  <YearsDropdown
                    options={yearsList}
                    onClose={onCloseYearDrop}
                    onClickYear={(yearNumber) => {
                      const newYearDate = set(date, { year: yearNumber });
                      onDateChange(newYearDate.toISOString());
                    }}
                    currentYear={Number(year)}
                    yearRef={yearRef}
                  />
                )}
              </Box>
            </Box>
          </ThemeContext.Extend>
        ) : (
          <Text {...headingTextStyle}>{format(date, 'MMMM y')}</Text>
        )}
        <LinkButton
          padding={{ top: '2px' }}
          color={arrowColor}
          icon={<FormNext color={arrowColor} />}
          onClick={onNextMonth}
          data-testid="calendar-date-picker-next-month"
        />
      </Box>
      <Box direction="row" justify="between" align="center" ref={dropRef}>
        {showDaysOfWeek &&
          ['S', 'M', 'T', 'W', 'T', 'F', 'S'].map((day) => (
            <Box key={day} width="36px" height="36px" justify="center" align="center">
              <Text {...daysOfWeekTextStyle}>{day}</Text>
            </Box>
          ))}
      </Box>
    </Box>
  );
};

export default CalendarHeader;
