// Generated by ts-to-zod
import { z } from 'zod';

import { encountersGroupEncounterSchema } from './encounters.zod';
import { formsFormInstanceSchema, formSignatureRequirementTypeSchema } from './forms.zod';
import { ordersOrderSchema } from './orders.zod';
import { patientPatientNameSchema } from './patient.zod';
import { usersUserSchema } from './users.zod';

export const signaturesSignatureStatusSchema = z.union([
  z.literal('pending'),
  z.literal('complete'),
]);

export const signaturesUserSignatureSchema = z.object({
  id: z.string(),
  userId: z.string(),
  signatureImageFileId: z.string(),
  signatureDisplayText: z.string(),
  createdAt: z.string(),
});

export const signaturesFormDefinitionSignatureRequirementPostBodySchema = z.object({
  roleIds: z.array(z.string()),
  type: formSignatureRequirementTypeSchema,
});

export const signaturesPostSignBodySchema = z.object({
  formIds: z.array(z.string()).optional(),
  groupEncounterId: z.string().optional(),
  orderId: z.string().optional(),
  signatureActionIds: z.array(z.string()).optional(),
  isPatientSignature: z.boolean(),
  pin: z.string().optional(),
  patientId: z.string().optional(),
  signatureImageFileId: z.string().optional(),
});

export const postSignResponseSchema = z.object({
  failed: z.boolean(),
  failedForms: z
    .array(
      z.object({
        id: z.string(),
        error: z.string(),
      }),
    )
    .optional(),
});

export const signaturesSignatureActionContentTypeSchema = z.union([
  z.literal('Forms'),
  z.literal('Groups'),
  z.literal('Orders'),
]);

export const signaturesSignatureSortTypeSchema = z.union([
  z.literal('createdAt_asc'),
  z.literal('createdAt_desc'),
]);

export const signaturesSignatureActionSchema = z.lazy(() =>
  z.object({
    id: z.string(),
    requiredRoleIds: z.array(z.string()).optional(),
    requestedUserIds: z.array(z.string()).optional(),
    createdAt: z.date(),
    patient: patientPatientNameSchema.and(
      z.object({
        patientId: z.string(),
      }),
    ),
    formInstance: formsFormInstanceSchema.optional(),
    groupEncounter: encountersGroupEncounterSchema.optional(),
    order: ordersOrderSchema.optional(),
  }),
);

export const signaturesListActionsQueryParamsSchema = z.object({
  requiredRoleIds: z.array(z.string()).optional(),
  objectCreatedByIds: z.array(z.string()).optional(),
  patientIds: z.array(z.string()).optional(),
  formDefinitionIds: z.array(z.string()).optional(),
  orderingProviderIds: z.array(z.string()).optional(),
  clientStatuses: z.array(z.string()).optional(),
  externalSignatureStatuses: z.array(z.string()).optional(),
  contentTypes: z.array(signaturesSignatureActionContentTypeSchema).optional(),
  hasSignature: z.boolean().optional(),
  limit: z.number().optional(),
  offset: z.number().optional(),
  sortBy: signaturesSignatureSortTypeSchema.optional(),
});

export const signaturesSignatureSchema = z.lazy(() =>
  z.object({
    id: z.string(),
    fileId: z.string(),
    displayText: z.string(),
    status: signaturesSignatureStatusSchema,
    createdAt: z.date(),
    deletedAt: z.date().nullable(),
    userId: z.string().nullable(),
    user: usersUserSchema.nullable(),
    patientId: z.string().nullable(),
    patient: patientPatientNameSchema.nullable(),
    firstName: z.string(),
    middleName: z.string(),
    lastName: z.string(),
  }),
);

export const signaturesSignatureRequirementsDeleteBody = z.object({
  signatureRequirementIds: z.array(z.string()),
});
