// @ts-strict-ignore
import { addHours, isAfter, isValid, isWithinInterval, parse, subHours } from 'date-fns';
import _ from 'lodash';
import * as uuid from 'uuid';

import { LabelValueDropdownChoice } from '@ritten/ui-library/dropdowns/Dropdown';

import { COLORS } from 'styles/colors';
import { fillArrayWithNumbers, formatNumberToHourlyTime } from 'utils';
import { capitalizeOnlyFirstLetter } from 'utils/stringUtils';
import {
  DOSE_STATUS_LABELS,
  FREQUENCY_OPTION_LABELS,
  FREQUENCY_OPTIONS,
  INJECTABLE_ROUTES,
  MONTHLY_DOSE_FREQUENCIES,
  NEGATIVE_DOSE_STATUSES,
  ORDER_TYPE_LABELS,
  ORDER_TYPES,
  SINGLE_DOSE_FREQUENCIES,
} from './constants';

export const SINGLE_DOSE_FREQUENCY_CHIP_LABELS = ['Now', 'STAT'];

export const getFrequencyOptions = () => FREQUENCY_OPTION_LABELS;

export const getFrequencyLabel = (val?: MAR.ScheduleBlockFrequency): string => {
  const freqIdx = FREQUENCY_OPTIONS.findIndex((o) => o === val);
  return FREQUENCY_OPTION_LABELS[freqIdx];
};

export const getFrequencyValue = (label: string): MAR.ScheduleBlockFrequency => {
  const freqIdx = FREQUENCY_OPTION_LABELS.findIndex((o) => o === label);
  return FREQUENCY_OPTIONS[freqIdx];
};

export const prefillBlockWithMedData = (
  block: MAR.CreatableScheduleBlock,
  med: Catalog.DoseSpotCodedMedicationDetails | null,
  hours: number[],
  prepopulateData?: MAR.Schedule | MAR.CreatableSchedule,
): MAR.CreatableScheduleBlock => {
  const { frequency } = block;
  const strengths: string[] = [];
  const doseForms: string[] = [];
  const routes: string[] = [];
  const quantities: number[] = [];
  if (med) {
    _.times(getFrequencyNumber(frequency), () => {
      strengths.push(capitalizeOnlyFirstLetter(med.Strength));
      doseForms.push(capitalizeOnlyFirstLetter(med.DoseForm));
      routes.push(capitalizeOnlyFirstLetter(med.Route));
      quantities.push(1);
    });
  }
  if (prepopulateData) {
    const blockToCopy = prepopulateData.blocks[0];
    _.times(getFrequencyNumber(frequency), () => {
      strengths.push(capitalizeOnlyFirstLetter(blockToCopy.strengths[0]));
      doseForms.push(capitalizeOnlyFirstLetter(blockToCopy.doseForms[0]));
      routes.push(capitalizeOnlyFirstLetter(blockToCopy.routes[0]));
      quantities.push(1);
    });
  }
  if (!med && !prepopulateData) {
    // If no other prefill data, at least default quantities
    _.times(getFrequencyNumber(frequency), () => {
      quantities.push(1);
    });
  }
  return { ...block, strengths, doseForms, routes, quantities, hours };
};

export const prefillBlockWithFreeTextMedData = (
  block: MAR.CreatableScheduleBlock,
  doseForm: string,
  hours: number[],
): MAR.CreatableScheduleBlock => {
  const { frequency } = block;
  const strengths: string[] = [];
  const doseForms: string[] = [];
  const routes: string[] = [];
  const quantities: number[] = [];
  _.times(getFrequencyNumber(frequency), () => {
    doseForms.push(capitalizeOnlyFirstLetter(doseForm));
    quantities.push(1);
  });
  return { ...block, strengths, doseForms, routes, quantities, hours };
};

export const prefillBlockWithDosespotOrderDetails = (
  block: MAR.CreatableScheduleBlock,
  details: Orders.OrderDetailsDosespot,
  hours: number[],
): MAR.CreatableScheduleBlock => {
  const { frequency } = block;
  const strengths: string[] = [];
  const doseForms: string[] = [];
  const routes: string[] = [];
  const quantities: number[] = [];
  _.times(getFrequencyNumber(frequency), () => {
    strengths.push(capitalizeOnlyFirstLetter(details.strength));
    doseForms.push(capitalizeOnlyFirstLetter(details.doseForm));
    routes.push(capitalizeOnlyFirstLetter(details.route));
    quantities.push(undefined); // Quantity defaults to empty but we need placeholders for the PRN blocks
  });
  return {
    ...block,
    strengths,
    doseForms,
    routes,
    quantities,
    count: parseInt(details.daysSupply),
    hours,
  };
};

export const getEmptyScheduleBlock = (
  medication: Catalog.DoseSpotCodedMedicationDetails | null,
  hours: number[],
  currentBlocks?: MAR.ScheduleBlock[] | MAR.CreatableScheduleBlock[],
  isIntervalDose: boolean = false,
): MAR.CreatableScheduleBlock => {
  const emptyBlock: MAR.CreatableScheduleBlock = {
    id: uuid.v4(),
    frequency: 'QD',
    count: 30,
    hours,
    quantities: [],
    strengths: [],
    doseForms: [],
    routes: [],
    durationType: isIntervalDose ? 'Doses' : 'Days',
    isIntervalDose,
  };
  if (!medication && currentBlocks?.length > 0) {
    return {
      ...emptyBlock,
      ...currentBlocks[0],
      id: emptyBlock.id,
    };
  }
  return {
    ...emptyBlock,
    ...prefillBlockWithMedData(emptyBlock, medication, hours),
  };
};

export const getTimeDropdownOptions = (freq: MAR.ScheduleBlockFrequency): string[] => {
  switch (freq) {
    case 'Q30M':
      return fillArrayWithNumbers(24, 0.5).map((n) => formatNumberToHourlyTime(n));
    default:
      return fillArrayWithNumbers(24, 1).map((n) => formatNumberToHourlyTime(n));
  }
};

export const getTimesForFrequency = (freq?: MAR.ScheduleBlockFrequency): number[] => {
  return (
    {
      Q30M: [
        0, 0.5, 1, 1.5, 2, 2.5, 3, 3.5, 4, 4.5, 5, 5.5, 6, 6.5, 7, 7.5, 8, 8.5, 9, 9.5, 10, 10.5,
        11, 11.5, 12, 12.5, 13, 13.5, 14, 14.5, 15, 15.5, 16, 16.5, 17, 17.5, 18, 18.5, 19, 19.5,
        20, 20.5, 21, 21.5, 22, 22.5, 23, 23.5,
      ],
      QH: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23],
      Q2H: [1, 3, 5, 7, 9, 11, 13, 15, 17, 19, 21, 23],
      Q3H: [0, 3, 6, 9, 12, 15, 18, 21],
      Q4H: [1, 5, 9, 13, 17, 21],
      Q6H: [6, 12, 18, 23],
      Q8H: [8, 16, 23],
      Q12H: [8, 20],
      QD: [8],
      HS: [20],
      BID: [8, 20],
      TID: [8, 13, 20],
      QID: [8, 12, 16, 20],
      Now: [new Date().getHours()],
      Stat: [new Date().getHours()],
    }[freq] || [8]
  );
};

export const getFrequencyNumber = (freq?: MAR.ScheduleBlockFrequency): number => {
  return getTimesForFrequency(freq).length;
};

export const getFrequencyNumberByScheduleType = (
  type: MAR.ScheduleType,
  isDosesInterval: boolean = false,
  freq?: MAR.ScheduleBlockFrequency,
): number => {
  if (type === 'Unscheduled Action') {
    return 0;
  }
  if (type === 'PRN' || isDosesInterval) {
    return 1;
  }
  return getFrequencyNumber(freq);
};

/**
 * Gets the default duration type for the frequency.
 */
export const getBlockDurationType = (freq: MAR.ScheduleBlockFrequency): MAR.BlockDurationType => {
  if (freq === 'QW' || freq === 'Select Week Days') {
    return 'Weeks';
  }
  if (MONTHLY_DOSE_FREQUENCIES.includes(freq)) {
    return 'Months';
  }
  if (SINGLE_DOSE_FREQUENCIES.includes(freq)) {
    return 'Doses';
  }
  return 'Days';
};

/**
 * Gets the duration types that are valid for a frequency.
 */
export const getBlockDurationTypeOptions = (
  freq?: MAR.ScheduleBlockFrequency,
  includeDoses: boolean = false,
): MAR.BlockDurationType[] => {
  // Doses not an option for PRN orders
  switch (freq) {
    case 'QW':
      return includeDoses ? ['Weeks', 'Doses'] : ['Weeks'];
    case 'Q4W':
      return includeDoses ? ['Months', 'Doses'] : ['Months'];
    case 'QM':
      return includeDoses ? ['Months', 'Doses'] : ['Months'];
    case 'Select Week Days':
      return includeDoses ? ['Weeks', 'Doses'] : ['Weeks'];
    default:
      return includeDoses ? ['Days', 'Weeks', 'Months', 'Doses'] : ['Days', 'Weeks', 'Months'];
  }
};

export const getNewFrequencyBlock = (
  block: MAR.CreatableScheduleBlock,
  frequency: MAR.ScheduleBlockFrequency,
): MAR.CreatableScheduleBlock => {
  const { count, startsAt, durationType } = block;
  const isSingleDose = SINGLE_DOSE_FREQUENCIES.includes(frequency);
  return {
    ...block,
    count: isSingleDose ? 1 : count,
    startsAt: isSingleDose ? getTodaysDateWithPrepopulatedHours(new Date()) : startsAt,
    durationType:
      isSingleDose || durationType === 'Doses' ? 'Doses' : getBlockDurationType(frequency),
    frequency,
    isIntervalDose: isSingleDose || durationType === 'Doses',
  };
};

/**
 * Formats the duration type as a string.
 */
export const formatBlockDurationType = (type: MAR.BlockDurationType, count?: number): string => {
  const str = `${type}`;
  if (count && count < 2) {
    return str.substring(0, str.length - 1);
  }
  return str;
};

/**
 * Parses an hour string and converts it to a number.
 * e.g. "10 AM" => 10
 */
export const parseHourlyTime = (time: string): number => {
  const d = parse(time, 'h a', new Date());
  return isValid(d) ? d.getHours() : 0;
};

/**
 * Calculates the schedule block "count" value for the frequency and duration type.
 */
export const blockCountCalculator = (
  block: MAR.CreatableScheduleBlock,
): MAR.CreatableScheduleBlock => {
  let count = block.count;
  switch (block.frequency) {
    case 'Q4W':
      break;
    case 'QW': {
      if (block.durationType === 'Months') {
        count *= 4;
      }
      break;
    }
    case 'QM': {
      break;
    }
    case 'Select Week Days': {
      // select week days should only ever have week intervals
      if (block.durationType === 'Weeks') {
        count *= block.daysOfWeek?.length ?? 0;
      }
      break;
    }
    default: {
      if (block.durationType === 'Months') {
        count *= 7 * 4;
      } else if (block.durationType === 'Weeks') {
        count *= 7;
      }
      break;
    }
  }
  return { ...block, count };
};

/**
 * Parses the API schedule block into a local MAR.CreatableScheduleBlock type.
 * It performs the logic to calculate the block duration type,
 * so that the UI can show "Weeks" or "Months" instead of "Days".
 */
export const parseScheduleBlock = (apiBlock: MAR.ScheduleBlock): MAR.CreatableScheduleBlock => {
  const block: MAR.CreatableScheduleBlock = { ...apiBlock, durationType: 'Days' };
  if (apiBlock.isIntervalDose) {
    block.durationType = 'Doses';
    return block;
  }
  const numDaysPerWeek = apiBlock.daysOfWeek?.length ?? 0;
  if (numDaysPerWeek > 0) {
    const numWeeks = Math.ceil(apiBlock.count / numDaysPerWeek);
    block.count = numWeeks;
    block.durationType = 'Weeks';
    return block;
  }
  switch (block.frequency) {
    case 'Q4W':
      block.durationType = 'Months';
      break;
    case 'QW': {
      block.durationType = 'Weeks';
      if (block.count % 4 === 0 && block.count > 4) {
        block.count /= 4;
        block.durationType = 'Months';
      }
      break;
    }
    case 'QM': {
      block.durationType = 'Months';
      break;
    }
    default: {
      if (block.count % 28 === 0 && block.count > 28) {
        block.count /= 28;
        block.durationType = 'Months';
      }
      if (block.count % 7 === 0 && block.count > 7) {
        block.count /= 7;
        block.durationType = 'Weeks';
      }
      break;
    }
  }
  return block;
};

/**
 * Converts a MAR dose status to a UI display string.
 */
export const formatDoseStatus = (status: MAR.DoseStatus): string => {
  return DOSE_STATUS_LABELS[status] ?? '';
};

/**
 * Checks if the dose status needs a corresponding `canceledAt` property.
 */
export const statusIsCanceled = (status?: MAR.DoseStatus) => {
  return NEGATIVE_DOSE_STATUSES.includes(status);
};

/**
 * Converts a MAR order status to a UI label.
 */
export const getOrderStatusText = (status: MAR.OrderStatus) => {
  switch (status) {
    case 'active':
      return 'Active';
    case 'inactive':
      return 'Discontinued';
    case 'pending':
      return 'Signature required';
    case 'complete':
      return 'Complete';
    default:
      return '';
  }
};

export const getOrderStatusColor = (status: MAR.OrderStatus) => {
  switch (status) {
    case 'inactive':
      return COLORS.red400;
    case 'complete':
      return COLORS.darkGray400;
    default:
      return COLORS.rittenBlue300;
  }
};

/**
 * Gets a list of MAR order types options that can be used in the Select component.
 */
export const getOrderTypeOptions = (): { label: string; value: string }[] =>
  ORDER_TYPES.map((ot) => ({
    label: ORDER_TYPE_LABELS[ot],
    value: ot,
  }));

export const countDayOfTheWeekOccurrences = (
  daysOfWeek: string[],
  durationInDays: number,
  startsAt: Date,
) => {
  // Get the day of the week for the given date (0 = Sunday, 1 = Monday, ..., 6 = Saturday)
  const currentDay = startsAt.getDay();

  // Create a map to convert day names to their corresponding index (0 = Sunday, 1 = Monday, ..., 6 = Saturday)
  const dayIndexMap = {
    Sunday: 0,
    Monday: 1,
    Tuesday: 2,
    Wednesday: 3,
    Thursday: 4,
    Friday: 5,
    Saturday: 6,
  };

  // Calculate the occurrences for each day in the array
  const occurrences = daysOfWeek.reduce((total, day) => {
    const dayIndex = dayIndexMap[day];
    let dayDifference = dayIndex - currentDay;

    if (dayDifference < 0) {
      dayDifference += 7;
    }

    const dayOccurrences = Math.floor(durationInDays / 7);
    const remainingDays = durationInDays % 7;
    const remainingOccurrences = dayDifference < remainingDays ? 1 : 0;

    return total + dayOccurrences + remainingOccurrences;
  }, 0);

  return occurrences;
};

/**
 * Gets the total number of pills needed for a schedule block,
 * by creating a sum of all the quantities multiplied by the count and duration.
 */
export const getBlockPillCount = (
  { frequency, quantities, count, durationType, daysOfWeek }: MAR.CreatableScheduleBlock,
  startsAt?: Date,
): number => {
  if (SINGLE_DOSE_FREQUENCIES.includes(frequency)) {
    return parseFloat(quantities[0]);
  }
  if (frequency === 'Select Week Days') {
    const dosesPerWeek = daysOfWeek?.length;
    if (!dosesPerWeek) {
      return 0;
    }
    if (durationType === 'Days') {
      return countDayOfTheWeekOccurrences(daysOfWeek, count, startsAt);
    }
    if (durationType === 'Weeks') {
      return count * quantities[0] * dosesPerWeek;
    }
    if (durationType === 'Months') {
      return count * 4 * quantities[0] * dosesPerWeek;
    }
  }
  if (durationType === 'Doses') {
    // Doses blocks can only have one row/quantity so it will always be
    // count * first quantities value
    return count * parseFloat(quantities[0]);
  }
  const dailySum = _.sum(quantities.map((q) => parseFloat(q || 0)));
  switch (durationType) {
    case 'Months': {
      const multiplier = MONTHLY_DOSE_FREQUENCIES.includes(frequency) ? 1 : 28;
      return Math.max(0, dailySum * count * multiplier);
    }
    case 'Weeks': {
      const multiplier = frequency === 'QW' ? 1 : 7;
      return Math.max(0, dailySum * count * multiplier);
    }
    default:
      return Math.max(0, dailySum * count);
  }
};

/**
 * Normalizes the route options, adding more choices where needed.
 */
export const normalizeRouteOptions = (routes: string[] = []) => {
  // Adds the full list of injectable routes if one injectable route is found.
  const injectableRoutesMatch = routes.some((r) => INJECTABLE_ROUTES.includes(r));
  if (injectableRoutesMatch) {
    for (const injectableRoute of INJECTABLE_ROUTES) {
      if (!routes.includes(injectableRoute)) {
        routes.push(injectableRoute);
      }
    }
  }
  return routes;
};

export const hasValidRole = (
  sigRequirements: Orders.OrderSignatureRequirement[] = [],
  roles: Users.Role[] = [],
) => {
  return (roles || []).reduce((hasValid, role) => {
    const isRoleRequired = !!(sigRequirements || []).find((r) => r.roleId === role.id);
    if (isRoleRequired) {
      hasValid = true;
    }
    return hasValid;
  }, false);
};

/**
 * Validates the schedule blocks for a schedule and returns a set of errors.
 */
export const validateScheduleBlocks = (
  scheduleType: MAR.ScheduleType,
  blocks: MAR.CreatableScheduleBlock[],
  startBehavior: MAR.ScheduleStartBehavior,
): Set<string> => {
  const errors = new Set<string>();
  if (scheduleType === 'Unscheduled Action') {
    if (blocks.length !== 1) {
      errors.add('Unscheduled Actions should contain exactly one schedule block');
    }
    if (!blocks[0].durationType) {
      errors.add('Missing duration info');
    }
    if (blocks[0]?.count < 1) {
      errors.add('Duration must be greater than 0');
    }
    if (blocks[0]?.durationType === 'Doses') {
      errors.add('Unscheduled Actions may not use doses interval');
    }
    return errors;
  }
  blocks.forEach((block) => {
    const { count, frequency, hours, quantities, strengths, doseForms, routes } = block;
    if (!count || count < 1 || !block.durationType) {
      errors.add('Missing duration info');
    }
    if (!frequency) {
      errors.add('Missing frequency');
    }
    if (frequency === 'Select Week Days' && block.daysOfWeek?.length === 0) {
      errors.add('Missing day(s) of the week selection');
    }
    const slotsInScheduleBlock = getFrequencyNumber(frequency);
    const isMissingValue = (prop) => {
      // 'given time' is a scheduled startBehavior that currently behaves similar to PRN
      if (scheduleType === 'PRN' || startBehavior === 'given time') {
        return prop.length === 0;
      }

      return prop.length !== slotsInScheduleBlock;
    };

    if (block.durationType !== 'Doses') {
      if (isMissingValue(hours)) {
        errors.add('Missing at least one scheduled time');
      }
      const hasRepeatTime: boolean = _.uniq(hours).length !== hours.length;
      if (hasRepeatTime) {
        errors.add('Cannot repeat any times');
      }
    }

    if (scheduleType !== 'Action') {
      if (isMissingValue(quantities) || quantities.includes(undefined)) {
        errors.add('Missing at least one quantity');
      }
      if (quantities.some((qty) => qty <= 0)) {
        errors.add('All quantities must be greater than 0');
      }
      if (isMissingValue(strengths)) {
        errors.add('Missing at least one strength');
      }
      if (isMissingValue(doseForms)) {
        errors.add('Missing at least one dose form');
      }
      if (isMissingValue(routes)) {
        errors.add('Missing at least one route');
      }
    }
  });
  return errors;
};

export const getQuantityTextColor = (quantity: number) => {
  if (quantity < 1) {
    return COLORS.rittenBlue400;
  }
  if (quantity > 1) {
    return COLORS.orange400;
  }
  return COLORS.text;
};

export const getDoseDueChipInfo = (
  scheduledAt: Date,
  isBeforeSchedule: boolean,
  frequency?: MAR.ScheduleBlockFrequency,
): { color: string; label: string } => {
  if (frequency) {
    if (frequency === 'Stat') {
      return {
        color: COLORS.red400,
        label: 'STAT',
      };
    }
    if (frequency === 'Now') {
      return {
        color: COLORS.orange300,
        label: 'Now',
      };
    }
  }

  if (isBeforeSchedule) {
    return {
      color: COLORS.darkGray200,
      label: 'Before Schedule',
    };
  }

  const now = new Date();
  const oneHourAheadOfScheduledTime = subHours(scheduledAt, 1);
  const oneHourPastScheduledTime = addHours(scheduledAt, 1);

  const isWithinOneHour = isWithinInterval(now, {
    start: oneHourAheadOfScheduledTime,
    end: oneHourPastScheduledTime,
  });

  const isPastDue = isAfter(now, oneHourPastScheduledTime);

  if (isWithinOneHour) {
    return {
      color: COLORS.red200,
      label: 'Dose Due',
    };
  }
  if (isPastDue) {
    return {
      color: COLORS.darkGray200,
      label: 'Past Due',
    };
  }

  return null;
};

// Dosespot API details provide medication names like "Benadryl FastMelt Allergy 12 mg oral" so
// we need to dissect the actual med name from the long string that includes strength and dose form
export const trimDosespotMedicationName = (longMedName: string): string => {
  if (!longMedName) {
    return longMedName;
  }

  const stringPieces = longMedName.split(' ');
  // Find the "last" full number defined in the long med name string because the actual med name could have numbers in it
  // (for example, Allegra-D 12 Hour Allergy or B-12 Vitamin) but we only want the actual strength (aka 1200 mg)
  // Note: Some meds can have strengths defined as "0.2%-10%" which is why we do this replace
  const inverseIndexOfNumberInName = [...stringPieces]
    .reverse()
    .findIndex((p) => /^\d+$/.test(p.replace(/[.%-]/g, '')));

  if (inverseIndexOfNumberInName < 0 || !inverseIndexOfNumberInName) {
    return longMedName;
  }

  const actualIndexOfNumberInName = stringPieces.length - inverseIndexOfNumberInName - 1;
  return stringPieces.slice(0, actualIndexOfNumberInName).join(' ');
};

// Returns the number of hours per interval for a particular frequency
export const getFrequencyIntervalInHours = (freq: MAR.ScheduleBlockFrequency): number => {
  switch (freq) {
    case 'QH':
      return 1;
    case 'Q2H':
      return 2;
    case 'Q4H':
      return 4;
    case 'Q6H':
    case 'QID':
      return 6;
    case 'Q8H':
    case 'TID':
      return 8;
    case 'BID':
      return 12;
    case 'QD':
    case 'HS':
      return 24;
    case 'QW':
      return 24 * 7;
    case 'Q4W':
      return 24 * 7 * 4;
    default:
      return 0;
  }
};

export const shouldShowPRNDoseWarning = (dose: MAR.Dose, now?: Date): boolean => {
  if (!dose || dose?.scheduleType !== 'PRN' || !dose.nextTakenAt || !dose.prevTakenAt) {
    return false;
  }
  const rightNow = now || new Date();
  return rightNow < dose.nextTakenAt;
};

/**
 * This is a shim for blocks belonging to PRN-type schedules.
 * In contrast to "Scheduled" types, these blocks only require
 * a single value in all the array fields, and no value for `hours`.
 */
export const normalizePRNBlock = (
  block: MAR.CreatableScheduleBlock,
): MAR.CreatableScheduleBlock => {
  return {
    ...block,
    hours: [],
    strengths: block.strengths.slice(0, 1),
    quantities: block.quantities.slice(0, 1),
    routes: block.routes.slice(0, 1),
    doseForms: block.doseForms.slice(0, 1),
    count: SINGLE_DOSE_FREQUENCIES.includes(block.frequency) ? 1 : block.count,
    startsAt: SINGLE_DOSE_FREQUENCIES.includes(block.frequency) ? new Date() : block.startsAt,
    isIntervalDose: block.durationType === 'Doses',
  };
};

/** Normalize schedule blocks for API creation */
export const normalizeScheduleBlocks = (
  scheduleType: MAR.ScheduleType,
  blocks: MAR.CreatableScheduleBlock[],
): MAR.CreatableScheduleBlock[] => {
  return (
    blocks
      .map((block) => {
        if (scheduleType === 'Unscheduled Action') {
          return {
            ...block,
            frequency: 'QD',
            isIntervalDose: false,
          };
        }
        if (scheduleType === 'PRN' || block.durationType === 'Doses') {
          return normalizePRNBlock(block);
        }
        return block;
      })
      // Perform the block count calculation for the duration type.
      .map(blockCountCalculator)
      // Convert quantity strings to floats
      .map((b) => ({ ...b, quantities: b.quantities.map((q) => parseFloat(q)) }))
  );
};

export const getEmptyMedSchedule = (
  orderingProviderId?: string,
  isTemplate: boolean = false,
): MAR.CreatableSchedule => ({
  id: uuid.v4(),
  type: 'Scheduled',
  medication: null,
  blocks: [getEmptyScheduleBlock(null, [8])],
  justification: '',
  pillCount: 0,
  warning: '',
  instructions: '',
  startsAt: isTemplate ? getDummyDate() : getTodaysDateWithPrepopulatedHours(new Date()),
  marOrderType: 'Electronic',
  orderingProviderId,
  prescriptionNumber: '',
  startBehavior: 'after current time',
  startTime: '',
});

export const getEmptyActionSchedule = (orderingProviderId?: string): MAR.CreatableSchedule => ({
  id: uuid.v4(),
  type: 'Action',
  medication: null,
  blocks: [getEmptyScheduleBlock(null, [8])],
  justification: '',
  warning: '',
  pillCount: 0,
  instructions: '',
  startsAt: new Date(),
  marOrderType: 'Other',
  orderingProviderId,
  prescriptionNumber: '',
  startBehavior: 'after current time',
  startTime: '',
});

export const getPrepopulatedDataWithUpdatedStartBehavior = (prepopulatedData?: MAR.Schedule) => {
  if (prepopulatedData && prepopulatedData.startBehavior !== 'given time') {
    const numScheduleBlocks = prepopulatedData.blocks?.length;
    if (numScheduleBlocks > 1) {
      return {
        ...prepopulatedData,
        startBehavior: 'after current time' as MAR.ScheduleStartBehavior,
      };
    }
    return {
      ...prepopulatedData,
      startBehavior: 'start of day' as MAR.ScheduleStartBehavior,
    };
  }
  return prepopulatedData;
};

export const getTodaysDateWithPrepopulatedHours = (originalDate: Date) => {
  // Extract the time from the original Date
  const hours = originalDate.getHours();

  // Create a new Date object
  const newDate = new Date();

  // Set the time of the new Date object
  newDate.setHours(hours);
  newDate.setMinutes(0);
  newDate.setSeconds(0);

  return newDate;
};

export const getDummyDate = () => {
  const newDate = new Date(Date.UTC(0, 0, 0, 0, 0, 0));
  return newDate;
};

export const isDummyOrNullDate = (date: Date): boolean => {
  if (!date) {
    return true;
  }
  return date.getFullYear() === 1899 && date.getMonth() === 11;
};

export const isDoseLogged = (doseStatus: MAR.DoseStatus) =>
  doseStatus !== 'pending' && doseStatus !== 'before schedule';

export const areUnscheduledOrdersFiltersValid = (
  orderingProviders: Users.User[],
  careTeamOptions: Entity.SuggestedUser[],
  programOptions: Catalog.ClinicProgram[],
  selectedOrderingProviders: LabelValueDropdownChoice[],
  selectedCareTeam: LabelValueDropdownChoice[],
  selectedPrograms: string[],
): {
  careTeamValid: boolean;
  orderingProvidersValid: boolean;
  programsValid: boolean;
} => {
  const careTeamValid = selectedCareTeam.reduce((isValid, careTeamMember) => {
    const user = careTeamOptions.find((u) => u.id === careTeamMember.value);
    return isValid && !!user;
  }, true);
  const orderingProvidersValid = selectedOrderingProviders.reduce((isValid, selectedOP) => {
    const user = orderingProviders.find((u) => u.id === selectedOP.value);
    return isValid && !!user;
  }, true);
  const programsValid = selectedPrograms.reduce((isValid, selectedCP) => {
    const program = programOptions.find((cp) => cp.id === selectedCP);
    return isValid && !!program;
  }, true);
  return {
    careTeamValid,
    orderingProvidersValid,
    programsValid,
  };
};
