import { isPossiblePhoneNumber } from 'react-phone-number-input';

import { isInt } from './regexUtils';

const validPhoneNumberRegex = /^(\+\d{1,2}\s)?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/;
/**
 * usePhoneUtils will contain util functions for working with phone numbers
 *
 * @returns {any}
 */
export const getPhoneValue = (phoneNumber: string) => {
  let phone = phoneNumber.replace(/\D/g, '');
  const match = phone.match(/^(\d{1,3})(\d{0,3})(\d{0,4})$/);
  if (match) {
    phone = `(${match[1]}${match[2] ? ') ' : ''}${match[2]}${match[3] ? '-' : ''}${match[3]}`;
  }
  return phone;
};

export const getPhoneLength = (newPhoneNumber: string) => {
  const digits: string[] = [];
  for (let i = 0; i < newPhoneNumber.length; i += 1) {
    if (isInt(newPhoneNumber[i])) {
      digits.push(newPhoneNumber[i]);
    }
  }
  return digits.length;
};

export const phoneValidation = async (phoneNumber: string) => {
  return phoneNumber?.length > 0 && validPhoneNumberRegex.test(phoneNumber);
};

export const intlPhoneValidation = async (value: string) => {
  /**
   * Note from Author
   * https://www.npmjs.com/package/react-phone-number-input
   *
   * I personally don't use isValidPhoneNumber() for phone number validation in
   * my projects. The rationale is that telephone numbering plans can and
   * sometimes do change, meaning that isValidPhoneNumber()function may one day
   * become outdated on a website that isn't actively maintained anymore.
   * Imagine a "promo-site" or a "personal website" being deployed once and
   * then running for years without any maintenance, where a client may be
   * unable to submit a simple "Contact Us" form just because this newly
   * allocated pool of mobile phone numbers wasn't present in that old version
   * of libphonenumber-js bundled in it.
   *
   * Whenever there's a "business requirement" to validate a phone number that's
   * being input by a user, I prefer using isPossiblePhoneNumber() instead of
   * isValidPhoneNumber(), so that it just validates the phone number length,
   * and doesn't validate the actual phone number digits. But it doesn't mean
   * that you shouldn't use isValidPhoneNumber() — maybe in your case it would
   * make sense.
   */
  return value?.length > 0 && isPossiblePhoneNumber(value);
};
