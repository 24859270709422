// @ts-strict-ignore
import { CSSProperties, useState } from 'react';

import { COLORS } from '../../styles/colors';
import { isNotEmpty } from '../../utils';

export const useInputTheme = () => {
  const unfocusedStyle: CSSProperties = {
    // For now we will continue to lean on grommet to specify the onfocused border color
    border: null,
  };
  const focusedStyle: CSSProperties = {
    border: `solid 1px ${COLORS.rittenBlue400}`,
    boxShadow: `inset 0px 0px 0px 1px ${COLORS.rittenBlue400}`,
  };

  const [focusStyle, setFocusStyle] = useState<CSSProperties>(unfocusedStyle);

  const onFocus = (isFocused: boolean) => {
    setFocusStyle(isFocused ? focusedStyle : unfocusedStyle);
  };

  const errorStyle: CSSProperties = {
    border: 'solid 1px red',
  };

  const getErrorStyle = (error?: string): CSSProperties => {
    if (isNotEmpty(error)) {
      return errorStyle;
    }
    return {};
  };

  const extendInputContainerProperties = (width?: string, disabled?: boolean) => {
    return {
      backgroundColor: disabled ? COLORS.darkGray200 : COLORS.white,
      fontSize: '16px',
      fontWeight: 400,
      height: '40px',
      width: width ?? '100%;',
      borderRadius: '4px',
    };
  };

  const inputLabelCss = () => {
    const css: React.CSSProperties = {
      fontWeight: 'bold',
      fontSize: '10px',
    };

    return css;
  };

  return {
    extendInputContainerProperties,
    inputLabelCss,
    onFocus,
    focusStyle,
    getErrorStyle,
  };
};
