/**
 * This file is meant specifically for helpers related to time strings of any kind.
 */

import { format } from 'date-fns';

export const WEEK_DAY_LABELS = [
  'Sunday',
  'Monday',
  'Tuesday',
  'Wednesday',
  'Thursday',
  'Friday',
  'Saturday',
];

/**
 *
 * @param day
 * @returns 2-char day string: '01' for the first day of the month, '24' for the 24th
 */
export const getDayForISOString = (day: number) => {
  if (day < 10) {
    return `0${day}`;
  }
  return day.toString();
};

export const getHoursMinutesObject = (time: string) => {
  if (!time || time === '') {
    return null;
  }

  const amOrPm = time.substring(time.length - 2);

  const splitTime = time.substring(0, time.length - 3).split(':');
  let hour = parseInt(splitTime[0]);
  if (amOrPm.toLowerCase() === 'pm' && hour < 12) {
    hour = parseInt(splitTime[0]) + 12;
  } else if (amOrPm.toLowerCase() === 'am' && hour === 12) {
    hour = 0;
  }

  const minute = parseInt(splitTime[1]);

  const hoursMinutesObject = {
    hour,
    minute,
  };

  // if hour or minute are NaN, return null ?
  if (Number.isNaN(hour) || Number.isNaN(minute)) {
    return null;
  }

  return hoursMinutesObject;
};

export const getTimeStringHHMMAA = (startDate: Date, lowercase?: boolean) => {
  return format(startDate, `hh:mm aa${lowercase ? 'a' : ''}`);
};

/**
 *
 * @param monthIndex
 * @returns 2-char month string: '01' for january, '12' for december
 */
export const getMonthForISOString = (monthIndex: number) => {
  const monthNumber = monthIndex + 1;

  if (monthNumber < 10) {
    return `0${monthNumber}`;
  }
  return monthNumber.toString();
};

const partialTimeRegExp = /(^\d{0,2})(:)?(\d{0,2})?( )?((a|p))?m?$/gim;

/**
 * Approximates the partial time value from the TimeInputComponent to the nearest
 * full time that matches the date-fns format: "hh:mm aaa".
 * e.g. "9:"=>"9:00 am" or "1:3"=>"1:30 am" or "11:59 p"=>"11:59 pm"
 *
 * @param value - the partial string returned by the TimeInputComponent.
 */
export const formatTimeApproximate = (value: string): string => {
  const groupMatches = Array.from(value.matchAll(partialTimeRegExp))[0] ?? [];
  const hour = groupMatches[1] || '00';
  const minute = groupMatches[3] || '00';
  const meridiem = groupMatches[5] || 'am';
  return `${hour}:${minute.padEnd(2, '0')} ${meridiem.padEnd(2, 'm')}`;
};

/**
 *
 * @param number float representing the hour of the day (e.g. 9 or 9.5).
 * @returns string representing the time like a clock suffixed by `AM` or `PM`
 */
export const formatNumberToHourlyTime = (
  hourNumber: number,
  showDoubleZeroOnHourTimes?: boolean,
): string => {
  if (hourNumber == null) {
    return '';
  }

  const hours = Math.floor(hourNumber) % 24;
  const minutes = Math.round((hourNumber - Math.floor(hourNumber)) * 60);

  if (Intl?.DateTimeFormat) {
    // The actual date doesn't matter, since we're just using it to parse a time.
    const d = new Date();
    d.setHours(hours, minutes);

    return new Intl.DateTimeFormat('default', {
      hour12: true,
      hour: 'numeric',
      minute: minutes === 0 && !showDoubleZeroOnHourTimes ? undefined : '2-digit',
    }).format(d);
  }

  if (hourNumber === 0 || hourNumber === 24) {
    return '12 AM';
  }
  const realHourNumber = hours > 12 ? hours - 12 : hours;
  return `${realHourNumber}${minutes === 0 ? '' : `:${minutes.toString().padStart(2, '0')}`} ${
    hourNumber >= 12 ? 'PM' : 'AM'
  }`;
};

/**
 * takes a time string HH:MM:SS => HH:MM AA. If the string is already formatted HH:MM AA it will be returned as is
 * @param timeString formatted HH:MM:SS or HH:MM AA
 * @returns HH:MM AA formatted time
 */
export const maybeConvertHHMMSSToHHMMAA = (timeString: string): string => {
  // check if already formatted to HH:MM AA
  if (/\b\d{1,2}:\d{2} (AM|PM)\b/.test(timeString)) {
    return timeString;
  }

  const [hours, minutes] = timeString.split(':').map(Number);
  let period = 'AM';

  let formattedHours = hours;
  if (hours >= 12) {
    period = 'PM';
    formattedHours = hours === 12 ? hours : hours - 12;
  }
  if (formattedHours === 0) {
    formattedHours = 12;
  }

  return `${formattedHours}:${minutes.toString().padStart(2, '0')} ${period}`;
};

export const caseInsensitiveHHMMAATimeStringRegex = /\b\d{1,2}:\d{2} (AM|PM)\b/i;

export const maybeConvert12HourHHMMAATo24HourHHMM = (timeString: string): string | null => {
  // ensure current format is HH:MM AA
  if (caseInsensitiveHHMMAATimeStringRegex.test(timeString)) {
    const [hour, minute, period] = timeString.split(/:| /);
    let newHour = parseInt(hour);
    if (/PM/i.test(period) && newHour !== 12) {
      newHour += 12;
    } else if (/AM/i.test(period) && newHour === 12) {
      newHour = 0;
    }
    return `${newHour}:${minute}`;
  }

  return null;
};
