import { useEffect, useState } from 'react';
import { matchPath, useLocation } from 'react-router-dom';
import { Box, Text } from 'grommet';
import { FormClose, Link } from 'grommet-icons';
import { isEmpty } from 'lodash';

import ContentBox from '@ritten/ui-library/boxes/ContentBox';
import InteriorBox from '@ritten/ui-library/boxes/InteriorBox';
import { StandardButton } from '@ritten/ui-library/buttons';
import Dropdown, { LabelValueDropdownChoice } from '@ritten/ui-library/dropdowns/Dropdown';
import { ErrorPanel } from '@ritten/ui-library/errors';
import { TextInputComponent } from '@ritten/ui-library/form-inputs';
import DateInputComponent from '@ritten/ui-library/form-inputs/DateInputComponent';
import { TextAreaComponent } from '@ritten/ui-library/form-inputs/TextAreaComponent';
import { BaseModal } from '@ritten/ui-library/modals';
import LoadingPanel from '@ritten/ui-library/panels/LoadingPanel';

import useMobileDevice from 'context/mobileDevice/useMobileDevice';
import useUser from 'context/user/useUser';
import useAPI from 'external/useAPI';
import { useErrorState, useLoadingState, useResponsiveDeviceSize } from 'hooks';
import { COLORS } from 'styles/colors';
import { convertISOStringToDate, formatDateYearMonthDayDashes, getFullName } from 'utils';
import { CLINIC_APP_ROUTE_URL_MAPPING } from 'utils/routeUtils';
import { taskPriorities } from './constants';
import { validatePostTask } from './utils';

const ALLOW_WEBLINK_TASK_OBJECT_TYPES: Tasks.TaskObjectType[] = ['No Object', 'Weblink'];

interface CreateEditTaskProps {
  onClose: () => void;
  onSuccess: (newTask: Tasks.Task) => void;
  task?: Tasks.PostTask;
  isFormMode?: boolean;
  isNavMode?: boolean;
  objectType?: Tasks.TaskObjectType;
  objectId?: string;
  objectSubtype?: { id: string; type: Tasks.TaskObjectSubtype };
  formCreatorId?: string;
}

const CreateEditTask = (props: CreateEditTaskProps) => {
  const {
    onClose,
    task,
    isFormMode,
    isNavMode,
    objectType,
    objectId,
    formCreatorId,
    objectSubtype,
    onSuccess,
  } = props;

  const { entityAPI, tasksAPI } = useAPI();
  const { loading, needsLoadingState } = useLoadingState(false);
  const { isMobile } = useMobileDevice();
  const { deviceSize } = useResponsiveDeviceSize();
  const { errors, setErrors, addError, clearErrors } = useErrorState();
  const { user } = useUser();
  const location = useLocation();

  const getDefaultAssignee = () => {
    if (task?.assigneeId) {
      return task.assigneeId;
    }
    if (isFormMode && formCreatorId) {
      return formCreatorId;
    }
    return user.id;
  };

  const [title, setTitle] = useState<string>(task?.title ?? '');
  const [userResults, setUserResults] = useState<LabelValueDropdownChoice[]>([]);
  // default assignee to current user when creating a task
  const [assigneeId, setAssigneeId] = useState<string>(getDefaultAssignee());
  const [watcherIds, setWatcherIds] = useState<string[]>(task?.watcherIds ?? []);
  const [description, setDescription] = useState<string>(task?.description ?? '');
  const [dueDate, setDueDate] = useState<Date>(
    task?.dueDate ? convertISOStringToDate(task.dueDate) : new Date(),
  );
  const [webLink, setWebLink] = useState<string>(task?.weblink ?? '');
  const [weblinkAlias, setWeblinkAlias] = useState<string>(task?.weblinkAlias ?? '');
  const [priority, setPriority] = useState<Tasks.TaskPriority>(task?.priority ?? 'Medium');
  const [isTaskLinkedToPage, setIsTaskLinkedToPage] = useState<boolean>(false);

  useEffect(() => {
    fetchUsers();
  }, []);

  const fetchUsers = needsLoadingState(async (query?: string) => {
    const users = await entityAPI.getEntities(query, {
      searchUsers: true,
      searchPatients: false,
      searchPrograms: false,
      searchCareTeams: false,
      searchClinicTeams: false,
      primaryCliniciansOnly: false,
      limit: 1000,
    });
    setUserResults(users.users.map((u) => ({ label: getFullName(u), value: u.id })));
  });

  const getTaskObjectForPost = (): { type: Tasks.TaskObjectType; id: string } => {
    if (objectType && objectId) {
      return { type: objectType, id: objectId };
    }
    if (task?.objectType && task?.objectId) {
      return { type: task.objectType, id: task.objectId };
    }
    return isEmpty(webLink) ? { type: 'No Object', id: '' } : { type: 'Weblink', id: '' };
  };

  const getLinkNameFromLink = (url: string): string => {
    const appRoute = Array.from(CLINIC_APP_ROUTE_URL_MAPPING.keys()).find((pattern) => {
      const match = matchPath(url, {
        path: pattern,
        exact: true,
      });
      return match !== null;
    });
    if (appRoute) {
      return CLINIC_APP_ROUTE_URL_MAPPING.get(appRoute) as string;
    }
    return 'Ritten';
  };

  const onSave = needsLoadingState(async () => {
    clearErrors();
    const taskObj = getTaskObjectForPost();
    const taskPostObj: Tasks.PostTask = {
      id: task?.id,
      title,
      description,
      assigneeId,
      watcherIds,
      priority,
      dueDate: formatDateYearMonthDayDashes(dueDate),
      status: 'New',
      weblink: taskObj.type === 'Weblink' ? webLink : '',
      weblinkAlias: taskObj.type === 'Weblink' ? weblinkAlias : '',
      objectType: taskObj.type,
      objectId: taskObj.id,
      objectSubtype: objectSubtype?.type,
      objectSubtypeId:
        objectSubtype?.type === 'Treatment Plan Non-Plan Field' ? '' : objectSubtype?.id,
    };
    const validationErrors = validatePostTask(taskPostObj);
    if (validationErrors.length > 0) {
      setErrors(validationErrors);
      return;
    }
    try {
      const newTask = await tasksAPI.postTask(taskPostObj);
      onSuccess(newTask);
    } catch (err) {
      addError(err);
    }
  });

  const renderPageBody = () => {
    const showLinkRelatedInputs =
      !isFormMode && ALLOW_WEBLINK_TASK_OBJECT_TYPES.includes(task?.objectType ?? 'No Object');
    return (
      <Box
        width={!isMobile && !isFormMode ? { min: '350px' } : '100%'}
        height="100%"
        data-testid="task-create-edit-body"
      >
        <LoadingPanel showLoader={loading} fill>
          <ErrorPanel errors={errors} />
          <Box pad={{ bottom: '12px' }} gap="12px" flex>
            <TextInputComponent
              label="Task Name"
              requirementMarker="Required"
              textInputProps={{
                value: title,
                onChange: (e) => setTitle(e.target.value),
                maxLength: 80,
              }}
            />
            <Dropdown
              dropdownLabel="Assignee"
              variant="single-choice"
              choices={userResults}
              onSelect={(choice) => {
                const assignee = choice as LabelValueDropdownChoice;
                setAssigneeId(assignee.value);
              }}
              singleChoiceSelection={userResults.find((u) => u.value === assigneeId)}
              customizationProps={{
                anchorWidth: '100%',
                tooltipText: isFormMode ? 'Defaults to form creator' : 'Defaults to you',
              }}
            />
            <Dropdown
              dropdownLabel="Watchers"
              variant="multiple-choice"
              choices={userResults}
              onSelect={(choice) => {
                const watchers = choice as LabelValueDropdownChoice[];
                setWatcherIds(watchers.map((c) => c.value));
              }}
              multipleChoiceSelections={watcherIds.map((watcherId) => {
                const userOption = userResults.find((u) => u.value === watcherId);
                return { label: userOption?.label, value: userOption?.value };
              })}
              customizationProps={{
                anchorWidth: '100%',
                tooltipText: 'Users that will see this task in their list',
              }}
              multipleChoiceProps={{ hideSelectionTags: true }}
            />
            <TextAreaComponent
              label="Description"
              textAreaProps={{
                value: description,
                onChange: (e) => setDescription(e.target.value),
              }}
              initialHeight="60px"
              flex={false}
            />
            {!isNavMode && showLinkRelatedInputs && (
              <TextInputComponent
                label="Web Link"
                textInputProps={{ value: webLink, onChange: (e) => setWebLink(e.target.value) }}
              />
            )}
            {showLinkRelatedInputs && !isNavMode && (
              <TextInputComponent
                label="Link Name"
                tooltipText="Text presented describing this link"
                textInputProps={{
                  value: weblinkAlias,
                  onChange: (e) => setWeblinkAlias(e.target.value),
                }}
              />
            )}
            {showLinkRelatedInputs && isNavMode && (
              <InteriorBox round="4px" pad="8px" gap="8px">
                <StandardButton
                  styleVariant="outline"
                  sizeVariant="small"
                  label={isTaskLinkedToPage ? 'Clear Link' : 'Link Task To This Page'}
                  color={isTaskLinkedToPage ? 'alert' : 'default'}
                  fullWidth
                  onClick={() => {
                    if (isTaskLinkedToPage) {
                      setWeblinkAlias('');
                      setWebLink('');
                      setIsTaskLinkedToPage(false);
                    } else {
                      const linkName = getLinkNameFromLink(location.pathname);
                      setWeblinkAlias(linkName);
                      setWebLink(window.location.href);
                      setIsTaskLinkedToPage(true);
                    }
                  }}
                  icon={
                    isTaskLinkedToPage ? undefined : (
                      <Link color={COLORS.rittenBlue400} size="12px" />
                    )
                  }
                  reverse
                  gap="6px"
                />
                <TextInputComponent
                  label="Web Link"
                  textInputProps={{
                    value: webLink,
                    onChange: (e) => setWebLink(e.target.value),
                    disabled: isTaskLinkedToPage,
                  }}
                />
                <TextInputComponent
                  label="Link Name"
                  textInputProps={{
                    value: weblinkAlias,
                    onChange: (e) => setWeblinkAlias(e.target.value),
                  }}
                />
              </InteriorBox>
            )}
            <DateInputComponent
              label="Due Date"
              dateValue={dueDate}
              onDateChange={(newDate) => setDueDate(newDate)}
              dateInputProps={{
                format: 'mm/dd/yyyy',
              }}
              width="100%"
            />
            <Dropdown
              dropdownLabel="Priority"
              variant="single-choice"
              singleChoiceSelection={priority}
              choices={taskPriorities}
              onSelect={(choice) => {
                const newPriority = choice as Tasks.TaskPriority;
                setPriority(newPriority);
              }}
              customizationProps={{
                anchorWidth: '500px',
              }}
              searchProps={{
                hideSearch: true,
              }}
            />
          </Box>
        </LoadingPanel>
        <Box
          style={!isMobile ? { position: 'sticky', bottom: 0 } : undefined}
          direction="row"
          pad={{ vertical: '8px' }}
          gap="8px"
          background={COLORS.white}
        >
          <Box basis="50%">
            <StandardButton
              fullWidth
              onClick={() => onClose()}
              label="Cancel"
              styleVariant="outline"
            />
          </Box>
          <Box basis="50%">
            <StandardButton
              data-testid="create-edit-task-save-btn"
              fullWidth
              onClick={onSave}
              label={task ? 'Save' : 'Create'}
            />
          </Box>
        </Box>
      </Box>
    );
  };

  if (isFormMode && deviceSize.isLarge) {
    return (
      <ContentBox fill pad="8px" overflow={{ vertical: 'scroll' }}>
        <Box pad={{ top: '4px', bottom: '8px' }} direction="row" align="center" justify="between">
          <Text>{!task ? 'Create New ' : 'Edit '} Task</Text>
          <FormClose cursor="pointer" onClick={() => onClose()} color={COLORS.darkGray600} />
        </Box>
        {renderPageBody()}
      </ContentBox>
    );
  }

  return (
    <BaseModal
      isOpen
      onClose={onClose}
      header={`${!task ? 'Create New ' : 'Edit '}Task`}
      height={isMobile ? { max: '100%', height: '100%' } : undefined}
      layerProps={{ responsive: false, full: isMobile }}
    >
      {renderPageBody()}
    </BaseModal>
  );
};

export default CreateEditTask;
