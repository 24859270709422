import { from } from 'rxjs';

import { parseISODateStrings } from 'utils';
import RittenClient from './RittenClient';

export default class CatalogClient extends RittenClient {
  getMedications = async (name: string): Promise<Catalog.CodedMedicationResponse[]> => {
    const res = await this.get<Catalog.CodedMedicationResponse[]>(`/api/catalog/medications`, {
      params: {
        name,
      },
    });
    return res.data;
  };

  getMedications$ = (name: string) => from(this.getMedications(name));

  getMedicationCoded = async (
    name: string,
    strength: string,
  ): Promise<Catalog.DoseSpotCodedMedicationDetails> => {
    const res = await this.get<Catalog.DoseSpotCodedMedicationDetails>(
      `/api/catalog/medications/coded`,
      {
        params: {
          name,
          strength,
        },
      },
    );
    return res.data;
  };

  getMedicationCodedWithOptions = async (
    name: string,
    strength: string,
  ): Promise<Catalog.CodedMedicationDetailsWithOptions> => {
    const res = await this.get<Catalog.CodedMedicationDetailsWithOptions>(
      `/api/catalog/medications/coded-details`,
      {
        params: {
          name,
          strength,
        },
      },
    );
    return res.data;
  };

  getMedicationCoded$ = (name: string, strength: string) =>
    from(this.getMedicationCoded(name, strength));

  getFreeTextMedUnitTypes = async (): Promise<Catalog.DoseSpotDispenseUnitType[]> => {
    const res = await this.get<Catalog.DoseSpotDispenseUnitType[]>(`/api/catalog/medications/free`);
    return res.data;
  };

  getFreeTextMedUnitTypes$ = () => from(this.getFreeTextMedUnitTypes());

  getAllergies = async (searchName: string): Promise<Catalog.AllergiesResult> => {
    const params: Catalog.AllergiesQueryParams = {
      name: searchName,
    };
    const res = await this.get<Catalog.AllergiesResult>(`api/catalog/allergies`, { params });
    return res.data;
  };

  getDiagnoses = async (searchName: string): Promise<Catalog.ICDDiagnosis[]> => {
    const res = await this.get<Catalog.ICDDiagnosis[]>(`/api/catalog/diagnosis`, {
      params: {
        diagnosis: searchName,
      },
    });
    // sort the response
    const sortedResult = res.data.sort((a, b) => {
      const order = { 'ICD-10': 1, 'ICD-9-CM': 2, 'DSM-5': 3 };
      // Compare the order values of a.type and b.type
      return order[a.type] - order[b.type];
    });
    return sortedResult;
  };

  getDiagnoses$ = (searchName: string) => from(this.getDiagnoses(searchName));

  getICD10Diagnoses = async (searchName: string): Promise<Catalog.ICD10Diagnosis[]> => {
    const res = await this.get<Catalog.ICD10Diagnosis[]>(`/api/catalog/icd10-diagnosis`, {
      params: {
        diagnosis: searchName,
      },
    });

    return res.data;
  };

  getLevelsOfCare = async (clinicOnly: boolean) => {
    const { data = [] } = await this.get<Catalog.LevelOfCare[]>(`api/catalog/levels-of-care`, {
      params: {
        clinicOnly,
      },
    });
    return data ?? [];
  };

  getLevelsOfCare$ = (clinicOnly: boolean) => from(this.getLevelsOfCare(clinicOnly));

  getSubstances = async (): Promise<Catalog.Substance[]> => {
    const res = await this.get<Catalog.Substance[]>(`/api/catalog/substances`);
    return res.data;
  };

  getDocumentTypes = async (): Promise<string[]> => {
    const res = await this.get<string[]>(`api/catalog/documentTypes`);
    return res.data;
  };

  getRaces = async (): Promise<Catalog.Races[]> => {
    const res = await this.get<Catalog.Races[]>(`/api/catalog/races`);
    return res.data;
  };

  getEthnicities = async (): Promise<Catalog.Ethnicities[]> => {
    const res = await this.get<Catalog.Ethnicities[]>(`/api/catalog/ethnicities`);
    return res.data;
  };

  getLanguages = async (): Promise<Catalog.Languages[]> => {
    const res = await this.get<Catalog.Languages[]>(`/api/catalog/languages`);
    return res.data;
  };

  /**
   *
   * @param searchName search parameter (code string)
   * @returns a list of cpt codes, AND a list of hcpcs codes
   */
  getBillingCodes = async (searchName: string): Promise<Catalog.BillingCodesResult> => {
    const params: Catalog.BillingCodesQueryParams = {
      code: searchName,
    };
    const res = await this.get<Catalog.BillingCodesResult>(`api/catalog/billing`, { params });

    return res.data;
  };

  /**
   *
   * @param searchName search parameter (code string)
   * @returns a single list of billing code objects, with 'type' key specifying
   * whether the code is a cpt code or an hcpcs code
   */
  getProcedureCodesAsList = async (searchName: string): Promise<Catalog.ParsedBillingCode[]> => {
    const params: Catalog.BillingCodesQueryParams = {
      code: searchName,
    };
    const res = await this.get<Catalog.BillingCodesResult>(`api/catalog/billing`, { params });

    const cptCodes = res.data.cptCodes?.map((code) => {
      const codeForCombinedList: Catalog.ParsedBillingCode = {
        type: 'cpt',
        code: code.code,
        description: code.description,
      };
      return codeForCombinedList;
    });

    const hcpcsCodes = res.data.hcpcsCodes?.map((code) => {
      const codeForCombinedList: Catalog.ParsedBillingCode = {
        type: 'hcpcs',
        code: code.code,
        description: code.description,
      };
      return codeForCombinedList;
    });

    const completeList = (cptCodes || []).concat(hcpcsCodes || []);

    return completeList;
  };

  getProcedureCodesAsList$ = (searchName: string) => from(this.getProcedureCodesAsList(searchName));

  /**
   *
   * @param searchName search parameter (code string)
   * @returns a single list of billing code objects, with 'type' key specifying
   * whether the code is a cpt code or an hcpcs code
   */
  getRevenueCodesAsList = async (searchName: string): Promise<Catalog.ParsedBillingCode[]> => {
    const params: Catalog.BillingCodesQueryParams = {
      code: searchName,
    };
    const res = await this.get<Catalog.BillingCodesResult>(`api/catalog/billing`, { params });

    const revenueCodes = res.data.revenueCodes?.map((code) => {
      const parsedCode: Catalog.ParsedBillingCode = {
        type: 'revenue',
        code: code.code,
        description: code.description,
      };
      return parsedCode;
    });

    return revenueCodes ?? [];
  };

  getRevenueCodesAsList$ = (searchName: string) => from(this.getRevenueCodesAsList(searchName));

  listPlaceOfServiceCodes = async (): Promise<Catalog.PlaceOfServiceCode[]> => {
    const { data = [] } = await this.get<Catalog.PlaceOfServiceCode[]>(
      '/api/catalog/billing/places-of-service',
    );
    return data ?? [];
  };

  getTxPlanProblemsAsList = async (
    params: Partial<Catalog.TxPlanProblemsQueryParams> = {},
  ): Promise<Catalog.TxPlanProblem[]> => {
    const { data = [] } = await this.get<Catalog.TxPlanProblem[]>(`api/catalog/tx-plan/problems`, {
      params,
    });
    return parseISODateStrings(data);
  };

  getTxPlanProblemById = async (id: string): Promise<Catalog.TxPlanProblem> => {
    const { data } = await this.get<Catalog.TxPlanProblem>(`api/catalog/tx-plan/problems/${id}`);
    return parseISODateStrings(data);
  };

  /**
   * Adds a new problem to the catalog tx plan problem library.
   */
  postTxPlanProblem = async (problem: Catalog.TxPlanProblemPostBody): Promise<void> => {
    await this.post<Catalog.TxPlanProblem>(`api/catalog/tx-plan/problems`, problem);
  };

  updateTxPlanProblem = async (problem: Catalog.TxPlanProblemPutBody): Promise<void> => {
    await this.put<Catalog.TxPlanProblem>(`api/catalog/tx-plan/problems/${problem.id}`, problem);
  };

  deleteTxPlanProblem = async (problemId: string): Promise<void> => {
    await this.delete<Catalog.TxPlanProblem>(`api/catalog/tx-plan/problems/${problemId}`);
  };
}
