// Generated by ts-to-zod
import { z } from 'zod';

import { catalogClinicProgramSchema } from './catalog.zod';
import { clinicDisqualificationReasonSchema, clinicReferralTypeSchema } from './clinic.zod';
import {
  patientAddressSchema,
  patientContactPointSchema,
  patientPatientInsuranceSchema,
  patientPatientProgramSchema,
  patientProgramStatusSchema,
  patientReleaseOfInformationV2Schema,
} from './patient.zod';
import { usersActorSchema, usersUserSchema } from './users.zod';

export const crmPostOrganizationBodySchema = z.lazy(() =>
  z.object({
    id: z.string(),
    name: z.string(),
    typeId: z.string(),
    assignedUserId: z.string(),
    url: z.string().optional(),
    address: patientAddressSchema.optional(),
    organizationContactPoints: z.array(patientContactPointSchema).optional(),
    primaryContactId: z.string().optional(),
  }),
);

export const crmPostOrganizationContactSchema = z.object({
  id: z.string().optional(),
  organizationId: z.string(),
  personId: z.string().optional(),
  isPrimaryContact: z.boolean().optional(),
  isPrimaryOrganization: z.boolean().optional(),
  relationshipType: z.string(),
});

export const crmPostPersonDetailsSchema = z.object({
  id: z.string().optional(),
  jobTitle: z.string().optional(),
  assignedUserId: z.string(),
});

export const crmPostMergePersonsSchema = z.object({
  mergePersonIds: z.array(z.string()),
});

export const crmPersonDetailsSchema = z.object({
  id: z.string(),
  personId: z.string(),
  jobTitle: z.string(),
  assignedUser: usersUserSchema,
  createdAt: z.date(),
});

export const crmPostPersonRelationshipSchema = z.object({
  personId: z.string(),
  type: z.string(),
});

export const crmActionSchema = z.object({
  id: z.string().optional(),
  content: z.string(),
  completedBy: usersUserSchema.optional(),
  completedAt: z.date().optional().nullable(),
  createdBy: usersUserSchema.optional(),
  createdAt: z.date().optional(),
  deletedAt: z.date().optional(),
});

export const crmDealDisqualificationReasonSchema = z.lazy(() =>
  z.object({
    id: z.string(),
    disqualificationReason: clinicDisqualificationReasonSchema,
    createdAt: z.date(),
    deletedAt: z.date(),
  }),
);

export const crmActivityTypeSchema = z.union([
  z.literal('Call'),
  z.literal('Email'),
  z.literal('Meeting'),
  z.literal('In-Service'),
  z.literal('Tour'),
  z.literal('Event'),
  z.literal('Conference'),
  z.literal('Drop-In'),
]);

export const crmNoteTypeSchema = z.union([
  z.literal('Deal Note'),
  z.literal('Organization Note'),
  z.literal('Contact Note'),
]);

export const crmPostCaseSchema = z.object({
  id: z.string().optional(),
  personSeekingTreatmentId: z.string().optional(),
  caseName: z.string(),
  caseOwnerId: z.string().optional(),
  referringOrganizationId: z.string().optional(),
  referringPersonId: z.string().optional(),
  potentialAdmitDate: z.date().optional().nullable(),
  potentialProgramIds: z.array(z.string()).optional(),
  caseSizeCents: z.number().optional().nullable(),
  followUpDate: z.date().optional().nullable(),
  primaryContactId: z.string().optional(),
  caseSource: z.string().optional(),
});

export const crmPatchCaseSchema = crmPostCaseSchema.extend({
  id: z.string(),
});

export const crmPatchCaseStatusSchema = z.object({
  status: z.string(),
});

export const crmPostNoteSchema = z.object({
  id: z.string().optional(),
  entityId: z.string(),
  type: crmNoteTypeSchema,
  name: z.string(),
  content: z.string(),
  isPinned: z.boolean(),
  noteOwnerId: z.string().optional(),
  activityType: crmActivityTypeSchema,
  takenAt: z.date().nullable(),
});

export const crmPostActionSchema = z.object({
  id: z.string().optional(),
  content: z.string(),
  completedAt: z.date().optional().nullable(),
});

export const crmPostCaseDisqualificationSchema = z.object({
  reasonId: z.string(),
});

export const crmPostOutboundReferralSchema = z.object({
  id: z.string().optional(),
  ownerId: z.string(),
  note: crmPostNoteSchema.optional(),
});

export const crmPostOutboundReferralContactSchema = z.object({
  id: z.string().optional(),
  caseOutboundReferralId: z.string(),
  contactId: z.string().optional(),
  organizationId: z.string().optional(),
});

export const crmSortOptionSchema = z.union([
  z.literal('firstName_asc'),
  z.literal('firstName_desc'),
  z.literal('createdAt_desc'),
  z.literal('createdAt_asc'),
]);

export const crmListOrganizationsQuerySchema = z.object({
  searchQuery: z.string().optional(),
  organizationTypeIDs: z.array(z.string()).optional(),
  assignedUserIds: z.array(z.string()).optional(),
  startDate: z.date().optional(),
  endDate: z.date().optional(),
  limit: z.number().optional(),
  offset: z.number().optional(),
  sortBy: crmSortOptionSchema.optional(),
});

export const crmListPeopleQuerySchema = z.lazy(() =>
  z.object({
    searchText: z.string().optional(),
    excludeClients: z.boolean().optional(),
    limit: z.number().optional(),
    offset: z.number().optional(),
    sortBy: crmSortOptionSchema.optional(),
    assignedUserIds: z.array(z.string()).optional(),
    excludeStatuses: z.array(patientProgramStatusSchema).optional(),
  }),
);

export const crmListCasesQuerySchema = z.object({
  searchQuery: z.string().optional(),
  statuses: z.array(z.string()).optional(),
  potentialProgramIds: z.array(z.string()).optional(),
  admitStartDate: z.date().optional(),
  admitEndDate: z.date().optional(),
  followUpStartDate: z.date().optional(),
  followUpEndDate: z.date().optional(),
  caseOwnerIds: z.array(z.string()).optional(),
  referredOutOrganizationIds: z.array(z.string()).optional(),
  referringOrganizationIds: z.array(z.string()).optional(),
  referringContactIds: z.array(z.string()).optional(),
  caseSources: z.array(z.string()).optional(),
  sortBy: z.string().optional(),
  limit: z.number().optional(),
  offset: z.number().optional(),
});

export const crmListNotesQuerySchema = z.object({
  type: crmNoteTypeSchema,
  entityId: z.string(),
  limit: z.number().optional(),
  offset: z.number().optional(),
  sortBy: z.string().optional(),
  activityTypes: z.array(crmActivityTypeSchema).optional(),
});

export const crmListOrganizationPersonQuerySchema = z.object({
  relationshipTypes: z.array(z.string()).optional(),
  sortBy: crmSortOptionSchema.optional(),
  limit: z.number().optional(),
  offset: z.number().optional(),
});

export const crmListOrganizationROIsQuerySchema = z.object({
  sortBy: crmSortOptionSchema.optional(),
});

export const crmPostPersonSchema = z.lazy(() =>
  z.object({
    id: z.string(),
    nameId: z.string().optional(),
    first: z.string(),
    middle: z.string(),
    last: z.string(),
    dob: z.string().optional(),
    organizations: z.array(crmPostOrganizationContactSchema),
    details: crmPostPersonDetailsSchema.optional(),
    address: patientAddressSchema,
    contactPoints: z.array(patientContactPointSchema),
  }),
);

export const crmNoteSchema = z.object({
  id: z.string().optional(),
  entityId: z.string().optional(),
  type: crmNoteTypeSchema,
  noteName: z.string(),
  content: z.string(),
  isPinned: z.boolean(),
  activityType: crmActivityTypeSchema,
  noteOwner: usersUserSchema,
  takenAt: z.date(),
  createdBy: usersUserSchema,
  createdAt: z.date(),
  updatedAt: z.date(),
  updatedBy: usersActorSchema,
  deletedAt: z.date(),
});

export const crmFullNoteSchema = z.object({
  pinnedNote: crmNoteSchema.optional(),
  notes: z.array(crmNoteSchema),
});

export const crmOrganizationContactSchema = z.lazy(() =>
  z.object({
    id: z.string().optional(),
    organization: crmOrganizationSchema,
    person: crmPersonSchema,
    isPrimaryContact: z.boolean(),
    isPrimaryOrganization: z.boolean(),
    relationshipType: z.string(),
    releaseOfInformations: z.array(patientReleaseOfInformationV2Schema),
    createdAt: z.date(),
  }),
);

export const crmCaseSchema = z.lazy(() =>
  z.object({
    id: z.string(),
    caseName: z.string(),
    personSeekingTreatment: crmPersonSchema.optional(),
    primaryContact: crmPersonSchema.optional(),
    status: z.string(),
    caseOwner: usersUserSchema.optional(),
    caseSizeCents: z.string().optional(),
    referringPerson: crmPersonSchema.optional(),
    referringOrganization: crmOrganizationSchema.optional(),
    potentialPrograms: z.array(catalogClinicProgramSchema).optional(),
    potentialAdmitDate: z.date().optional(),
    admittingProgram: catalogClinicProgramSchema.optional(),
    admitDate: z.date().optional(),
    followUpDate: z.date().optional(),
    actions: z.array(crmActionSchema),
    disqualificationReasons: z.array(crmDealDisqualificationReasonSchema),
    outboundReferral: crmOutboundReferralSchema,
    leadAppInstanceId: z.string().optional(),
    leadAppInstanceStatus: crmLeadAppStatusSchema.optional(),
    caseSource: z.string().optional(),
    createdBy: usersUserSchema,
    updatedBy: usersUserSchema,
    updatedAt: z.date(),
    createdAt: z.date(),
    deletedAt: z.date(),
  }),
);

export const crmPersonSchema = z.lazy(() =>
  z.object({
    patientId: z.string(),
    first: z.string(),
    middle: z.string(),
    last: z.string(),
    dob: z.string(),
    mrn: z.string().optional(),
    status: patientProgramStatusSchema.optional(),
    organizationContacts: z.array(crmOrganizationContactSchema),
    permittedContacts: z.array(crmPersonRelationshipSchema),
    personDetails: crmPersonDetailsSchema,
    insurances: z.array(patientPatientInsuranceSchema),
    primaryClinician: usersUserSchema,
    programs: z.array(patientPatientProgramSchema),
    addresses: z.array(patientAddressSchema),
    contactPoints: z.array(patientContactPointSchema),
    deletedAt: z.string(),
    cases: z.array(crmCaseSchema),
    userId: z.string().optional(),
    isPortalDisabled: z.boolean(),
  }),
);

export const crmPrimaryContactSchema = z.object({
  id: z.string().optional(),
  person: crmPersonSchema,
  isPrimaryOrganization: z.boolean(),
  relationshipType: z.string(),
  createdAt: z.date(),
});

export const crmOrganizationSchema = z.lazy(() =>
  z.object({
    id: z.string(),
    name: z.string(),
    assignedUser: usersUserSchema.optional(),
    url: z.string().optional(),
    primaryContact: crmPrimaryContactSchema.optional(),
    type: clinicReferralTypeSchema,
    contactPoints: z.array(patientContactPointSchema).optional(),
    address: patientAddressSchema.optional(),
    createdAt: z.date(),
  }),
);

export const crmOrganizationROISchema = z.lazy(() =>
  z.object({
    patient: crmPersonSchema,
    rois: z.array(patientReleaseOfInformationV2Schema),
  }),
);

export const crmPersonRelationshipSchema = z.lazy(() =>
  z.object({
    id: z.string(),
    destinationPerson: crmPersonSchema,
    sourcePerson: crmPersonSchema,
    sourceIsOfDestination: z.string(),
    destinationIsOfSource: z.string(),
    releaseOfInformations: z.array(patientReleaseOfInformationV2Schema),
    createdAt: z.date(),
    deletedAt: z.date(),
  }),
);

export const crmPersonRelationshipsSchema = z.object({
  mainPersonId: z.string(),
  sourceRelationships: z.array(crmPersonRelationshipSchema),
  destinationRelationships: z.array(crmPersonRelationshipSchema),
});

export const crmOutboundReferralContactSchema = z.object({
  id: z.string(),
  referralOrganization: crmOrganizationSchema,
  referralContact: crmPersonSchema,
  createdAt: z.date(),
});

export const crmOutboundReferralSchema = z.object({
  id: z.string(),
  owner: usersUserSchema,
  note: crmNoteSchema,
  referrals: z.array(crmOutboundReferralContactSchema),
  createdAt: z.date(),
});

export const crmCaseSourceSchema = z.object({
  id: z.string(),
  title: z.string(),
  createdAt: z.string(),
  deletedAt: z.string().optional(),
});

export const crmCaseSourcePostBodySchema = z.object({
  title: z.string(),
  id: z.string().optional(),
});

export const crmLeadAppSectionLabelSchema = z.union([
  z.literal('lead_app_name'),
  z.literal('lead_app_dob'),
  z.literal('lead_app_address'),
  z.literal('lead_app_email'),
  z.literal('lead_app_phone'),
  z.literal('lead_app_insurance_card'),
  z.literal('lead_app_payer_info'),
  z.literal('lead_app_custom'),
]);

export const crmLeadAppFieldLabelSchema = z.union([
  z.literal('lead_app_first_name'),
  z.literal('lead_app_last_name'),
  z.literal('lead_app_dob'),
  z.literal('lead_app_address1'),
  z.literal('lead_app_address2'),
  z.literal('lead_app_city'),
  z.literal('lead_app_state'),
  z.literal('lead_app_zip'),
  z.literal('lead_app_email'),
  z.literal('lead_app_phone'),
  z.literal('lead_app_payer_insurance'),
  z.literal('lead_app_payer_group_number'),
  z.literal('lead_app_payer_member_id'),
  z.literal('lead_app_payer_rx_bin'),
  z.literal('lead_app_payer_start_date'),
  z.literal('lead_app_payer_expiration_date'),
  z.literal('lead_app_insured_first_name'),
  z.literal('lead_app_insured_last_name'),
  z.literal('lead_app_insured_address_1'),
  z.literal('lead_app_insured_city'),
  z.literal('lead_app_insured_state'),
  z.literal('lead_app_insured_zip'),
  z.literal('lead_app_insured_dob'),
  z.literal('lead_app_insured_relationship'),
  z.literal('lead_app_payer_insurance_card_front'),
  z.literal('lead_app_payer_insurance_card_back'),
  z.literal('lead_app_insured_different_than_client'),
  z.literal('lead_app_custom'),
]);

export const crmLeadAppFieldMetaSchema = z.object({
  promptText: z.string(),
  choices: z.array(z.string()).optional(),
});

export const crmLeadAppDefinitionMetaSchema = z.object({
  logoId: z.string().nullable(),
  hexColor: z.string().nullable(),
});

// Extracted from formsFieldTypeSchema
export const crmLeadAppFieldTypeSchema = z.union([
  z.literal('text'),
  z.literal('text_paragraph'),
  z.literal('date'),
  z.literal('single_choice_radio'),
  z.literal('single_choice_dropdown'),
  z.literal('multi_choice_checkbox'),
]);

export const crmLeadAppFieldSchema = z.object({
  label: crmLeadAppFieldLabelSchema,
  type: crmLeadAppFieldTypeSchema,
  meta: crmLeadAppFieldMetaSchema,
  value: z.any().optional(),
});

export const crmLeadAppSectionSchema = z.object({
  label: crmLeadAppSectionLabelSchema,
  title: z.string(),
  isRequired: z.boolean(),
  fields: z.array(crmLeadAppFieldSchema),
});

export const crmLeadAppDefinitionSchema = z.object({
  id: z.string(),
  version: z.number(),
  sections: z.array(crmLeadAppSectionSchema),
  createdAt: z.string(),
  deletedAt: z.string().optional(),
  meta: crmLeadAppDefinitionMetaSchema,
});

export const crmLeadAppDefinitionUpdateRequestSchema = z.object({
  meta: crmLeadAppDefinitionMetaSchema,
  sections: z.array(crmLeadAppSectionSchema),
});

export const crmLeadAppStatusSchema = z.union([
  z.literal('draft'),
  z.literal('submitted'),
  z.literal('shared'),
  z.literal('approval_required'),
  z.literal('approved'),
]);

export const crmLeadAppInstanceSchema = z.object({
  id: z.string(),
  definitionId: z.string(),
  version: z.number(),
  sections: z.array(crmLeadAppSectionSchema),
  status: crmLeadAppStatusSchema,
  meta: crmLeadAppDefinitionMetaSchema,
  dealId: z.string().optional(),
  createdAt: z.string(),
  deletedAt: z.string().optional(),
  updatedAt: z.string().optional(),
});
