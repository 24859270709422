// @ts-strict-ignore
import { useEffect, useState } from 'react';
import axios from 'axios';
import { debounce } from 'lodash';

import { useErrorState } from '../../hooks/useErrorState';
import { history } from '../../third-party/history';
import ErrorPanel from '../../ui-library/errors/ErrorPanel';
import LoadingPanel from '../../ui-library/panels/LoadingPanel';
import UIConfigContext from './UIConfigContext';

const UIConfigProvider: React.FC<{}> = (props: React.PropsWithChildren<{}>): JSX.Element => {
  const [config, setConfig] = useState<UIConfig | null>(null);
  const [loading, setLoading] = useState<boolean>(true);
  const { errors, clearErrors, addError } = useErrorState();

  useEffect(() => {
    loadConfig();
  }, []);

  useEffect(() => {
    const unlisten = history.listen(() => {
      // This is being done async with page load which means that the page may load in error
      // while this is still running. To the user it probably won't be a big deal as the
      // delay from an error and this refreshing the page should be short in most cases.
      // We need to debounce the checkVersion function because redirects cause this to fire
      // in quick succession.
      debouncedCheckVersion();
    });

    return unlisten;
  }, [config]);

  async function fetchUIConfig(): Promise<UIConfig> {
    const res = await axios.get<UIConfig>('/api/config/ui');
    return res.data;
  }

  async function loadConfig() {
    clearErrors();
    try {
      const resConfig = await fetchUIConfig();
      setConfig(resConfig);
    } catch (err) {
      addError(err);
    } finally {
      setLoading(false);
    }
  }

  const debouncedCheckVersion = debounce(async () => {
    try {
      const resConfig = await fetchUIConfig();
      if (config.commitSHA !== resConfig.commitSHA) {
        // reload the page
        window.location.reload();
      }
    } catch (err) {
      addError(err);
    }
  }, 100);

  if (loading) {
    return <LoadingPanel showLoader />;
  }

  if (errors.length > 0) {
    return <ErrorPanel errors={errors} />;
  }

  return <UIConfigContext.Provider value={{ config }}>{props.children}</UIConfigContext.Provider>;
};

export default UIConfigProvider;
