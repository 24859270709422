export const ACCEPTED_FILE_TYPES = {
  'image/png': [],
  'image/jpeg': [],
  'image/heic': [],
  'application/msword': [],
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document': [],
  'application/pdf': [],
  'text/plain': [],
  'application/zip': [],
};

export const ACCEPTED_FILE_TYPES_STRING =
  'image/png, image/jpeg, image/heic, application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document, application/pdf, text/plain, application/zip';

export const ACCEPTED_IMAGE_FILE_TYPES = { 'image/png': [], 'image/jpeg': [], 'image/heic': [] };
