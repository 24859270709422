interface FormSubmitWrapperProps {
  submit: () => any;
}

const FormSubmitWrapper = (props: React.PropsWithChildren<FormSubmitWrapperProps>) => {
  const handleEnterKey = (event) => {
    if (event.key === 'Enter' && !event.shiftKey) {
      event.preventDefault();
      props.submit();
    }
  };
  return <form onKeyDown={handleEnterKey}>{props.children}</form>;
};

export default FormSubmitWrapper;
