import { useRef } from 'react';
import chmln from '@chamaeleonidae/chmln';

import useUIConfig from 'context/config/useUIConfig';

interface ChameleonIdentityTraits {
  email: string;
  name: string;
  role: string;
}

export const useChameleon = () => {
  const { config } = useUIConfig();
  const { chameleonClientKey } = config;

  const isInitialized = useRef(false);

  const isValidEnv = config.env !== 'dev' && config.env !== 'test' && config.env !== 'qa';

  const identify = (id: string, traits: ChameleonIdentityTraits) => {
    if (isValidEnv) {
      if (!isInitialized.current) {
        chmln.init(chameleonClientKey, {
          fastUrl: 'https://fast.chameleon.io/',
        });
        isInitialized.current = true;
      }
      chmln.identify(id, traits);
    }
  };

  return {
    identify,
  };
};
