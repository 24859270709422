export const getSubdomain = (hostname: string) => {
  if (hostname.includes('://')) {
    hostname = hostname.split('://')[1];
  }
  return hostname.split('.')[0];
};

export const getNonRelativeUrl = (url?: string) => {
  if (url) {
    const hasProtocol = /https?:\/\//.test(url);
    if (!hasProtocol) {
      return `https://${url}`;
    }
  }
  return url;
};

export const isValidURL = (url: string) => {
  return /^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/|\/|\/\/)?[A-Za-z0-9_-]*:?([A-Za-z0-9_-]*@)?[A-Za-z0-9]+([-.]{1}[a-z0-9]+)*\.[a-z]{2,}(:[0-9]{1,5})?(\/.*)?$/.test(
    url,
  );
};
