import { Box, grommet, Text, ThemeContext } from 'grommet';
import { AlignType, BorderType, deepMerge, JustifyContentType, PadType } from 'grommet/utils';

import { COLORS } from '../../styles/colors';
import AsyncButton from './AsyncButton';
import { ButtonColor, ButtonSize } from './constants';
import { useButtonColor } from './useButtonColor';
import { useButtonSize } from './useButtonSize';

interface LinkButtonProps {
  label?: string;
  textSize?: string;
  children?: string;
  icon?: React.ReactNode;
  color?: ButtonColor;
  onClick: (e?: any) => void;
  noGap?: boolean;
  iconGap?: string;
  padding?: PadType;
  justify?: JustifyContentType;
  size?: ButtonSize;
  align?: AlignType;
  className?: string;
  border?: BorderType;
  borderRadius?: string;
  ref?: React.LegacyRef<HTMLButtonElement>;
  showHyperLinkStyle?: boolean;
  iconPosition?: 'before' | 'after';
  disabled?: boolean;
  textWeight?: 'bold' | 'normal' | number;
  'data-testid'?: string;
  isLoading?: boolean;
  truncate?: boolean;
}

export const LinkButton: React.FC<LinkButtonProps> = (props: LinkButtonProps) => {
  const {
    color = 'default',
    onClick,
    className,
    padding,
    align = 'center',
    border,
    borderRadius,
    icon,
    label,
    children,
    textSize,
    size = 'medium',
    justify = 'start',
    ref,
    showHyperLinkStyle = false,
    iconPosition = 'before',
    noGap,
    disabled,
    textWeight,
    isLoading,
    iconGap = '10px',
    truncate = false,
  } = props;

  const theme = deepMerge(grommet, {
    button: {
      disabled: {
        opacity: '1',
      },
    },
  });

  const { getColor } = useButtonColor();
  const { getTextSize } = useButtonSize();
  const linkColor = props.disabled ? COLORS.darkGray400 : getColor(color);
  const showIconBeforeText = icon && iconPosition === 'before';
  const showIconAfterText = icon && iconPosition === 'after';

  return (
    <ThemeContext.Extend value={theme}>
      <AsyncButton
        onClick={onClick}
        className={className}
        ref={ref}
        disabled={disabled}
        isLoading={isLoading}
        color={linkColor}
      >
        <Box
          pad={padding || 'small'}
          direction="row"
          align={align}
          justify={justify}
          gap={noGap ? '' : iconGap}
          border={border || undefined}
          style={{ borderRadius }}
          data-testid={props['data-testid']}
        >
          {showIconBeforeText && <Box color={linkColor}>{icon}</Box>}
          {(label || children) && (
            <Text
              weight={textWeight}
              size={textSize || getTextSize(size)}
              color={linkColor}
              style={{
                borderBottom: showHyperLinkStyle
                  ? `1px solid ${color ? linkColor : COLORS.rittenBlue400}`
                  : 'none',
                lineHeight: textSize || 'normal',
              }}
              truncate={truncate}
            >
              {label} {children}
            </Text>
          )}
          {showIconAfterText && <Box color={linkColor}>{icon}</Box>}
        </Box>
      </AsyncButton>
    </ThemeContext.Extend>
  );
};

export default LinkButton;
