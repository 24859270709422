import { useState } from 'react';
import { Box, Text } from 'grommet';
import { Document } from 'grommet-icons';

import { StandardButton } from '@ritten/ui-library/buttons';
import ErrorLabel from '@ritten/ui-library/labels/ErrorLabel';
import { BaseModal } from '@ritten/ui-library/modals';
import LoadingPanel from '@ritten/ui-library/panels/LoadingPanel';
import { DashedBoxSkeleton } from '@ritten/ui-library/skeletons';

import usePublicAPI from 'external/usePublicAPI';
import FilePicker from 'features/clients/chart/new-encounter-element-modal/document-upload/FilePicker';
import { LeadAppMode } from 'features/lead-application/constants';
import { useLoadingState } from 'hooks';
import { COLORS } from 'styles/colors';

interface LeadApplicationInsuranceCardProps {
  field: CRM.LeadAppField;
  mode: LeadAppMode;
  isRequired: boolean;
  updateField: (value: string) => void;
  error?: string;
  leadAppInstanceId?: string;
  token?: string;
}

const LeadApplicationInsuranceCard = (props: LeadApplicationInsuranceCardProps) => {
  const { field, mode, isRequired, updateField, error, leadAppInstanceId, token } = props;

  const { leadApplicationAPI } = usePublicAPI();
  const { loading, needsLoadingState } = useLoadingState(false);

  const [showDocumentUploadModal, setShowDocumentUploadModal] = useState<boolean>(false);

  const uploadInsuranceCard = needsLoadingState(async (file: File) => {
    try {
      if (leadAppInstanceId) {
        const { documentId } = await leadApplicationAPI.postLeadAppFile(
          leadAppInstanceId,
          file,
          field.label.replace(/lead_app_payer_/g, ''),
          token,
        );

        updateField(documentId);
        setShowDocumentUploadModal(false);
      }
    } catch (error) {
      setShowDocumentUploadModal(false);
    }
  });

  const isDisabled = mode === 'builder' || mode === 'case';

  return (
    <Box gap="8px">
      {isRequired && (
        <Text size="13px" color={COLORS.red400}>
          Required
        </Text>
      )}
      <DashedBoxSkeleton
        borderColor={COLORS.darkGray300}
        background={COLORS.darkGray100}
        pad="16px 50px"
        align="center"
        justify="center"
        round="4px"
        height="auto"
      >
        {field.value ? (
          <Box direction="row" align="center" gap="4px">
            <Document size="14px" color={COLORS.darkGray600} />
            <Text size="13px">File uploaded</Text>
          </Box>
        ) : (
          <StandardButton
            label={`Upload Insurance Card ${field.meta.promptText}`}
            disabled={isDisabled}
            onClick={() => setShowDocumentUploadModal(true)}
          />
        )}
      </DashedBoxSkeleton>
      {error && (
        <Box pad={{ top: 'xxsmall' }}>
          <ErrorLabel>{error}</ErrorLabel>
        </Box>
      )}
      {showDocumentUploadModal && (
        <BaseModal
          isOpen={showDocumentUploadModal}
          onClose={() => setShowDocumentUploadModal(false)}
          header={`Upload Insurance Card ${field.meta.promptText}`}
        >
          <Box pad={{ bottom: '18px' }}>
            {loading && <LoadingPanel showLoader />}
            {!loading && (
              <FilePicker
                uploadFile={(files) => {
                  uploadInsuranceCard(files[0]);
                }}
                imagesOnly
                maxFiles={1}
              />
            )}
          </Box>
        </BaseModal>
      )}
    </Box>
  );
};

export default LeadApplicationInsuranceCard;
