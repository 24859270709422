import { useContext } from 'react';

import FeatureFlagContext from './FeatureFlagContext';

/**
 * Exposes methods to conditionally render based on feature flags.
 *
 * NOTE: consider using the `<FeatureFlag />` component instead in JSX contexts.
 */
const useFeatureFlags = (): FeatureFlags.FeatureFlagContext => {
  const context = useContext(FeatureFlagContext);
  if (context === null) {
    throw new Error('useFeatureFlags must be used inside a FeatureFlagContext.Provider');
  }
  return context;
};

export default useFeatureFlags;
