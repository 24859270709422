// @ts-strict-ignore
import { AxiosRequestConfig, AxiosResponse } from 'axios';
import { formatISO } from 'date-fns';
import { from } from 'rxjs';
import { z } from 'zod';

import { Auth0TokenGetter } from 'context/auth0/Auth0UserProvider';
import { documentsDocumentSchema } from 'types/documents.zod';
import { isEmpty, isNotEmpty } from 'utils';
import { parseISODateStrings, parseObjectDates, StringifyKeys } from '../utils/dateParsers';
import RittenClient from './RittenClient';
import UserClient from './UserClient';

class PatientClient extends RittenClient {
  private userAPI: UserClient;

  constructor(t: Auth0TokenGetter, lr: () => string | null) {
    super(t, lr);
    this.userAPI = new UserClient(t, lr);
  }

  listPatients = async (props: {
    /**
     * Search for a patient by either first name, last name, chosen name or MRN.
     */
    searchText?: string;
    programIds?: string[];
    programStatuses?: string[];
    primaryClinicianIds?: string[];
    careTeamMemberIds?: string[];
    levelsOfCare?: string[];
    clinicTeamIds?: string[];
    admitStartTime?: string;
    admitEndTime?: string;
    dischargeStartTime?: string;
    dischargeEndTime?: string;
    limit?: number;
    offset?: number;
    sortBy?: string;
    // determines whether to load in patients chart flag
    loadFlag?: boolean;
  }): Promise<Patient.ListPatientsResponse> => {
    const {
      searchText,
      programIds = [],
      programStatuses: patientProgramStatuses = [],
      primaryClinicianIds = [],
      careTeamMemberIds = [],
      levelsOfCare = [],
      clinicTeamIds = [],
      admitStartTime,
      admitEndTime,
      dischargeStartTime,
      dischargeEndTime,
      limit,
      offset = 0,
      sortBy,
      loadFlag = false,
    } = props;
    const { data } = await this.get<Patient.ListPatientsResponse>('/api/patients', {
      params: {
        searchText,
        programIds,
        patientProgramStatuses,
        primaryClinicianIds,
        careTeamMemberIds,
        levelsOfCare,
        clinicTeamIds,
        admitStartTime,
        admitEndTime,
        dischargeStartTime,
        dischargeEndTime,
        limit,
        offset,
        sortBy,
        loadFlag,
      },
    });
    return parseISODateStrings(data);
  };

  postPatient = async (patient: Patient.PatientPostBody): Promise<Patient.Patient> => {
    const res = await this.post<Patient.Patient>('/api/patients', patient);
    return res.data;
  };

  patchPatient = async (
    patientId: string,
    patient: Patient.PatientPostBody,
  ): Promise<Patient.Patient> => {
    const res = await this.patch<Patient.Patient>(`/api/patients/${patientId}`, patient);
    return res.data;
  };

  deletePatient = async (patientId: string): Promise<void> => {
    await this.delete<void>(`/api/patients/${patientId}`);
  };

  unarchivePatient = async (patientId: string): Promise<void> => {
    await this.patch<void>(`/api/patients/${patientId}/unarchive`);
  };

  getPatientByID = async (id: string): Promise<Patient.Patient> => {
    const res = await this.get<Patient.Patient>(`/api/patients/${id}`);
    return res.data;
  };

  getPatientName = async (patientId: string): Promise<Patient.PatientName> => {
    const res = await this.get<Patient.PatientName>(`/api/patients/${patientId}/name`);
    return res.data;
  };

  patchPatientName = async (
    patientId: string,
    name: Patient.PatientName,
    fieldInstanceId?: string,
  ): Promise<void> => {
    await this.patch<void>(`/api/patients/${patientId}/name`, { ...name, fieldInstanceId });
  };

  patchPatientDob = async (
    patientId: string,
    dob: string,
    fieldInstanceId?: string,
  ): Promise<void> => {
    await this.patch<void>(`/api/patients/${patientId}/dob`, { dob, fieldInstanceId });
  };

  getPatientIsGuardianRequired = async (patientId: string): Promise<Patient.GuardianStatus> => {
    const res = await this.get<Patient.GuardianStatus>(`/api/patients/${patientId}/guardian`);
    return res.data;
  };

  patchPatientIsGuardianRequired = async (
    patientId: string,
    isGuardianRequired: boolean,
    fieldInstanceId?: string,
  ): Promise<void> => {
    await this.patch<void>(`/api/patients/${patientId}/guardian`, {
      isGuardianRequired,
      fieldInstanceId,
    });
  };

  patchPatientStatus = async (patientId: string, status: Patient.ProgramStatus): Promise<void> => {
    await this.patch<void>(`/api/patients/${patientId}/status`, { status });
  };

  patchPatientSSN = async (
    patientId: string,
    ssn: string,
    fieldInstanceId?: string,
  ): Promise<void> => {
    await this.patch<void>(`/api/patients/${patientId}/ssn`, { ssn, fieldInstanceId });
  };

  getPatientSSN = async (id: string, signaturePIN: string): Promise<Patient.PatientSSN> => {
    const res = await this.post<Patient.Patient>(`/api/patients/${id}/ssn`, {
      signaturePIN,
    });
    return res.data;
  };

  listPatientTxPlanForms = async (patientId: string): Promise<Forms.FormInstance[]> => {
    const res = await this.get<Forms.FormInstance[]>(`/api/patients/${patientId}/tx-plan-forms`);
    return res.data;
  };

  listPatientPortalForms = async (
    patientId: string,
    limit: number,
    offset: number,
  ): Promise<Forms.FormInstance[]> => {
    const res = await this.get<Forms.FormInstance[]>(`/api/patients/${patientId}/portal-forms`, {
      params: {
        limit,
        offset,
      },
    });
    return parseISODateStrings(res.data);
  };

  exportPatientAsync = async (
    id: string,
    patientExportObject: Patient.PatientExportPostBody,
  ): Promise<void> => {
    await this.post<void>(`/api/exports/async/patient/full/${id}`, patientExportObject);
  };

  exportBriefPatientAsync = async (id: string): Promise<void> => {
    await this.post<void>(`/api/exports/async/patient/brief/${id}`);
  };

  exportPatientWeeklyAgendaAsync = async (
    id: string,
    exportObject: Patient.PatientWeeklyAgendaExportPostBody,
  ): Promise<AxiosResponse<Blob>> => {
    // Create a post body with stringified dates in the user's local time zone.
    // This is needed to render the PDF with local dates for the user.
    const postBody = {
      ...exportObject,
      startTime: formatISO(exportObject.startTime),
      endTime: formatISO(exportObject.endTime),
      events: exportObject.events.map((event) => ({
        ...event,
        interval: `${event.interval}`,
        startTime: formatISO(event.startTime),
        endTime: formatISO(event.endTime),
      })),
    };
    const res = await this.post<Blob>(`/api/exports/async/patient/weekly-agenda/${id}`, postBody, {
      responseType: 'blob',
    });
    return res;
  };

  exportPatientActiveMARSchedulesAsync = async (id: string): Promise<void> => {
    await this.post<void>(`/api/exports/async/patient/mar-active-schedules/${id}`);
  };

  getPatientContactPoints = async (patientID: string): Promise<Patient.ContactPoint[]> => {
    const res = await this.get<Patient.ContactPoint[]>(`/api/patients/${patientID}/contact-points`);
    return res.data;
  };

  upsertPatientContactPoints = async (
    patientID: string,
    contactPoints: Patient.ContactPoint[],
    fieldInstanceID?: string,
  ): Promise<Patient.ContactPoint[]> => {
    const res = await this.post<Patient.ContactPoint[]>(
      `/api/patients/${patientID}/contact-points`,
      {
        contactPoints,
        ...(fieldInstanceID ? { fieldInstanceId: fieldInstanceID } : {}),
      },
    );
    return res.data;
  };

  deletePatientContactPoint = async (
    patientID: string,
    contactPointID: string,
    fieldInstanceID?: string,
  ): Promise<void> => {
    await this.delete(`/api/patients/${patientID}/contact-points/${contactPointID}`, {
      params: {
        fieldInstanceID,
      },
    });
  };

  getPatientPrimaryPhone = async (patientId: string) => {
    const contactPoints = await this.getPatientContactPoints(patientId);
    const primaryPhoneContactPoint: Patient.ContactPoint | undefined = contactPoints.find(
      (contactPoint) => contactPoint.system === 'PHONE' && contactPoint.isPrimary,
    );
    const primaryPhoneNumber: string =
      (primaryPhoneContactPoint && primaryPhoneContactPoint.value) || '';
    return primaryPhoneNumber;
  };

  getPatientAddresses = async (patientID: string): Promise<Patient.Address[]> => {
    const res = await this.get<Patient.Address[]>(`/api/patients/${patientID}/addresses`);
    return res.data;
  };

  upsertPatientAddresses = async (
    patientID: string,
    addresses: Patient.Address[],
    fieldInstanceID?: string,
  ): Promise<Patient.Address[]> => {
    const res = await this.post<Patient.Address[]>(`/api/patients/${patientID}/addresses`, {
      addresses,
      ...(fieldInstanceID ? { fieldInstanceId: fieldInstanceID } : {}),
    });
    return res.data;
  };

  deletePatientAddress = async (
    patientID: string,
    addressID: string,
    fieldInstanceID?: string,
  ): Promise<void> => {
    await this.delete(`/api/patients/${patientID}/addresses/${addressID}`, {
      params: {
        fieldInstanceID,
      },
    });
  };

  getPatientDemographics = async (patientID: string): Promise<Patient.Demographics> => {
    const res = await this.get<Patient.Demographics>(`/api/patients/${patientID}/demographics`);
    return res.data;
  };

  upsertPatientDemographics = async (
    patientID: string,
    demographics: Patient.Demographics,
    fieldInstanceID?: string,
  ): Promise<Patient.Demographics> => {
    const res = await this.post<Patient.Demographics>(`/api/patients/${patientID}/demographics`, {
      patientDemographics: demographics,
      ...(fieldInstanceID ? { fieldInstanceId: fieldInstanceID } : {}),
    });
    return res.data;
  };

  getPatientPrograms = async (patientID: string, programType?: Catalog.ClinicProgramType) => {
    const res = await this.get<Patient.PatientProgram[]>(`/api/patients/${patientID}/programs`);

    let patientPrograms = res.data;
    if (programType) {
      patientPrograms = res.data?.filter((program) => program.program.programType === programType);
    }

    return parseISODateStrings(patientPrograms);
  };

  getPatientPrograms$ = (patientID: string) => from(this.getPatientPrograms(patientID));

  patientProgramAdmit = async (
    patientId: string,
    programId: string,
    program: Patient.ProgramAdmissionPostBody,
  ) => {
    const res = await this.post<Patient.PatientProgram>(
      `/api/patients/${patientId}/programs/${programId}/admit`,
      program,
    );
    return res.data;
  };

  patientProgramTransfer = async (
    patientId: string,
    programId: string,
    program: Patient.ProgramTransferPostBody,
  ) => {
    const res = await this.post<Patient.PatientProgram>(
      `/api/patients/${patientId}/programs/${programId}/transfer`,
      program,
    );
    return res.data;
  };

  patientProgramDischarge = async (
    patientId: string,
    programId: string,
    program: Patient.ProgramDischargePostBody,
  ) => {
    const res = await this.post<Patient.PatientProgram>(
      `/api/patients/${patientId}/programs/${programId}/discharge`,
      program,
    );
    return res.data;
  };

  deletePatientProgram = async (patientId: string, programId: string) => {
    const res = await this.delete<Patient.PatientProgram>(
      `/api/patients/${patientId}/programs/${programId}`,
    );
    return res.data;
  };

  editPatientProgramDischargeDate = async (patientId: string, programId: string, newDate: Date) => {
    const res = await this.patch(`/api/patients/${patientId}/active-program/${programId}`, {
      estimatedDischargeDate: newDate,
    });
    return res.data;
  };

  getPatientSUH = async (patientID: string): Promise<Patient.SubstanceUseHistory[]> => {
    const res = await this.get<Patient.SubstanceUseHistory[]>(
      `/api/patients/${patientID}/history/substance-use`,
    );
    return res.data;
  };

  upsertPatientSUH = async (
    patientID: string,
    substanceUseHistory: Patient.SubstanceUseHistory[],
    fieldInstanceID?: string,
  ): Promise<Patient.SubstanceUseHistory[]> => {
    const res = await this.post<Patient.SubstanceUseHistory[]>(
      `/api/patients/${patientID}/history/substance-use`,
      {
        substanceUseHistory,
        ...(fieldInstanceID ? { fieldInstanceId: fieldInstanceID } : {}),
      },
    );
    return res.data;
  };

  deletePatientSUH = async (
    patientID: string,
    SUHID: string,
    fieldInstanceID?: string,
  ): Promise<void> => {
    await this.delete(`/api/patients/${patientID}/history/substance-use/${SUHID}`, {
      params: {
        fieldInstanceID,
      },
    });
  };

  getPatientAllergies = async (patientId: string): Promise<Patient.Allergy[]> => {
    const res = await this.get<Patient.Allergy[]>(`/api/patients/${patientId}/allergies`);
    return res.data;
  };

  getPatientAllergies$ = (patientId: string) => from(this.getPatientAllergies(patientId));

  upsertPatientAllergies = async (
    patientId: string,
    allergies: Patient.AllergiesPostBody,
  ): Promise<Patient.Allergy[]> => {
    const result = await this.post<Patient.Allergy[]>(
      `/api/patients/${patientId}/allergies`,
      allergies,
    );
    return result.data;
  };

  upsertPatientAllergies$ = (patientId: string, allergies: Patient.AllergiesPostBody) =>
    from(this.upsertPatientAllergies(patientId, allergies));

  deletePatientAllergy = async (
    patientId: string,
    allergyId: string,
    fieldInstanceID?: string,
  ): Promise<void> => {
    await this.delete<Patient.Allergy[]>(`/api/patients/${patientId}/allergies/${allergyId}`, {
      params: {
        fieldInstanceID,
      },
    });
  };

  deletePatientAllergy$ = (patientId: string, allergyId: string, fieldInstanceID?: string) =>
    from(this.deletePatientAllergy(patientId, allergyId, fieldInstanceID));

  getPatientInsurance = async (patientId: string): Promise<Patient.PatientInsurance[]> => {
    const res = await this.get<Patient.PatientInsurance[]>(`/api/patients/${patientId}/insurance`);
    return res.data;
  };

  getPatientPayerGroups = async (patientId: string): Promise<Clinic.PayerGroup[]> => {
    const res = await this.get<Patient.PatientInsurance[]>(`/api/patients/${patientId}/insurance`);
    const payerGroups = res.data.map((ins) => ins.payerGroup);
    return payerGroups;
  };

  upsertPatientInsurance = async (
    patientID: string,
    patientInsurance: Patient.PatientInsurance[],
    skipBilling: boolean,
    fieldInstanceID?: string,
  ): Promise<Patient.PatientInsurance[]> => {
    const res = await this.post<Patient.PatientInsurance[]>(
      `/api/patients/${patientID}/insurance`,
      {
        patientInsurance,
        ...(fieldInstanceID ? { fieldInstanceId: fieldInstanceID } : {}),
        skipBilling,
      },
    );
    return res.data;
  };

  deletePatientInsurance = async (
    patientID: string,
    patientInsuranceId: string,
    fieldInstanceID?: string,
  ): Promise<void> => {
    await this.delete(`/api/patients/${patientID}/insurance/${patientInsuranceId}`, {
      params: {
        fieldInstanceID,
      },
    });
  };

  getPatientDiagnoses = async (
    patientID: string,
    showInactive?: boolean,
  ): Promise<Patient.Diagnosis[]> => {
    const result = await this.get<Patient.Diagnosis[]>(`/api/patients/${patientID}/diagnosis`, {
      params: {
        ...(showInactive && { showInactive }),
      },
    });
    return result.data;
  };

  upsertPatientDiagnoses = async (
    patientId: string,
    diagnoses: Patient.DiagnosesPostBody,
  ): Promise<Patient.Diagnosis[]> => {
    const result = await this.post<Patient.Diagnosis[]>(
      `/api/patients/${patientId}/diagnosis`,
      diagnoses,
    );
    return result.data;
  };

  deletePatientDiagnosis = async (
    patientID: string,
    diagnosisId: string,
    fieldInstanceID?: string,
  ): Promise<void> => {
    await this.delete<Patient.Diagnosis[]>(`/api/patients/${patientID}/diagnosis/${diagnosisId}`, {
      params: {
        fieldInstanceID,
      },
    });
  };

  getPatientTreatmentHistory = async (patientID: string): Promise<Patient.TreatmentHistory[]> => {
    const res = await this.get<Patient.TreatmentHistory[]>(
      `/api/patients/${patientID}/history/treatment`,
    );

    return res.data;
  };

  upsertPatientTreatmentHistory = async (
    patientID: string,
    treatmentHistory: Patient.TreatmentHistory[],
    fieldInstanceID?: string,
  ): Promise<Patient.TreatmentHistory[]> => {
    const res = await this.post<Patient.TreatmentHistory[]>(
      `/api/patients/${patientID}/history/treatment`,
      {
        treatmentHistory,
        ...(fieldInstanceID && { fieldInstanceId: fieldInstanceID }),
      },
    );
    return res.data;
  };

  deletePatientTreatmentHistory = async (
    patientID: string,
    treatmentHistoryId: string,
    fieldInstanceID?: string,
  ): Promise<void> => {
    await this.delete(`/api/patients/${patientID}/history/treatment/${treatmentHistoryId}`, {
      params: {
        fieldInstanceID,
      },
    });
  };

  getPatientMedications = async (
    patientId: string,
    showInactive?: boolean,
  ): Promise<Patient.Medication[]> => {
    const res = await this.getWithDatesArray<Patient.Medication>(
      `/api/patients/${patientId}/medications`,
      ['dateOrdered', 'dateInactivated'],
      {
        params: {
          ...(showInactive && { showInactive }),
        },
      },
    );

    return res;
  };

  upsertPatientMedications = async (
    patientID: string,
    medications: Patient.Medication[],
    fieldInstanceID?: string,
  ): Promise<Patient.Medication[]> => {
    const res = await this.post<Patient.Medication[]>(`/api/patients/${patientID}/medications`, {
      medications,
      ...(fieldInstanceID && { fieldInstanceId: fieldInstanceID }),
    });
    return res.data;
  };

  deletePatientMedication = async (
    patientID: string,
    medicationId: string,
    fieldInstanceID?: string,
  ): Promise<void> => {
    await this.delete(`/api/patients/${patientID}/medications/${medicationId}`, {
      params: {
        fieldInstanceID,
      },
    });
  };

  updatePatientMedicationStatus = async (
    patientId: string,
    medicationId: string,
    status: Patient.MedicationStatus,
  ): Promise<void> => {
    await this.patch(`/api/patients/${patientId}/medications/${medicationId}/status`, {
      status,
    });
  };

  getPatientCareTeam = async (patientId: string): Promise<Patient.CareTeam> => {
    const res = await this.get<Patient.CareTeam>(`/api/patients/${patientId}/care-team`);
    return res.data;
  };

  upsertPatientCareTeam = async (
    patientId: string,
    patientCareTeam: Patient.CareTeam,
    fieldInstanceId?: string,
  ): Promise<Patient.CareTeam> => {
    const res = await this.post<Patient.CareTeam>(`/api/patients/${patientId}/care-team`, {
      patientCareTeam,
      ...(fieldInstanceId ? { fieldInstanceId } : {}),
    });
    return res.data;
  };

  deletePatientCareTeam = async (
    patientId: string,
    careTeamEntryId: string,
    fieldInstanceId?: string,
  ): Promise<Patient.CareTeam> => {
    const res = await this.delete<Patient.CareTeam>(
      `/api/patients/${patientId}/care-team/${careTeamEntryId}`,
      {
        params: {
          fieldInstanceID: fieldInstanceId,
        },
      },
    );
    return res.data;
  };

  /**
   * Retrieves the Primary Clinician user from a patientId or a primaryClinicianId.
   * WARNING: Makes an extra API if primaryClinicianId is NOT specified.
   * @returns Users.User
   */
  async getPatientPrimaryClinician(options: {
    patientId?: string;
    primaryClinicianId?: string;
  }): Promise<Users.User | null>;
  /**
   * Retrieves the Primary Clinician user for a patient.
   * @returns Users.User
   */
  async getPatientPrimaryClinician(patientId: string): Promise<Users.User | null>;
  async getPatientPrimaryClinician(
    args: string | { patientId?: string; primaryClinicianId?: string },
  ): Promise<Users.User | null> {
    let primaryClinicianId: string;
    let patientId: string;

    if (typeof args === 'string') {
      patientId = args;
    } else if (typeof args === 'object') {
      primaryClinicianId = args.primaryClinicianId;
      patientId = args.patientId;
    }

    if (isNotEmpty(patientId) && isEmpty(primaryClinicianId)) {
      const careTeam = await this.getPatientCareTeam(patientId);
      primaryClinicianId = careTeam.primaryClinicianId;
    }

    if (isNotEmpty(primaryClinicianId)) {
      return this.userAPI.getUserById(primaryClinicianId);
    }
    return null;
  }

  getClientContacts = async (patientId?: string): Promise<Patient.ClientContact[]> => {
    const res = await this.get<Patient.ClientContact[]>(
      `/api/patients/${patientId}/client-contacts`,
    );
    return parseISODateStrings(res.data);
  };

  upsertClientContacts = async (
    patientId: string,
    relationships: Patient.ClientContactPostBody[],
    fieldInstanceId?: string,
  ): Promise<Patient.ClientContact[]> => {
    const res = await this.post<Patient.ClientContact[]>(
      `/api/patients/${patientId}/client-contacts`,
      {
        relationships,
        ...(fieldInstanceId ? { fieldInstanceId } : {}),
      },
    );
    return res.data;
  };

  deleteClientContact = async (
    patientId: string,
    contactId: string,
    fieldInstanceId?: string,
  ): Promise<void> => {
    await this.delete(`/api/patients/${patientId}/client-contacts/${contactId}`, {
      params: {
        fieldInstanceID: fieldInstanceId,
      },
    });
  };

  getAllDocumentsByPatient = async (
    patientId: string,
    query?: Documents.DocumentQuery,
  ): Promise<Documents.Document[]> => {
    const res = await this.get<Documents.Document[]>(`api/patients/${patientId}/documents`, {
      params: query ?? {},
    });
    // We can remove the `as Documents.Document[]` once we have converted our app to support and enable Typescript strictNullChecks
    return z.array(documentsDocumentSchema).parse(res.data) as Documents.Document[];
  };

  exportPatientDocuments = async (patientId: string, documentTypes: string[]): Promise<void> => {
    await this.post<void>(`/api/exports/async/documents/patient/${patientId}`, {
      documentTypes,
    });
  };

  getFamilyRelationships = async (patientID?: string): Promise<Patient.ClientRelationship[]> => {
    const res = await this.get<Patient.ClientRelationship[]>(
      `/api/patients/${patientID}/relationships`,
      {
        params: {
          showRelationships: true,
        },
      },
    );
    return res.data;
  };

  upsertFamilyRelationships = async (
    patientID: string,
    patientRelationships: Patient.ClientRelationship[],
    fieldInstanceID?: string,
  ): Promise<Patient.ClientRelationship[]> => {
    const res = await this.post<Patient.ClientRelationship[]>(
      `/api/patients/${patientID}/relationships`,
      {
        patientRelationships,
        ...(fieldInstanceID ? { fieldInstanceId: fieldInstanceID } : {}),
      },
    );
    return res.data;
  };

  deleteFamilyRelationship = async (
    patientID: string,
    relationshipId: string,
    fieldInstanceID?: string,
  ): Promise<void> => {
    await this.delete(`/api/patients/${patientID}/relationships/${relationshipId}`, {
      params: {
        fieldInstanceID,
      },
    });
  };

  getReferrals = async (patientID?: string) => {
    const res = await this.get<StringifyKeys<Patient.PatientReferral, 'createdAt'>>(
      `/api/patients/${patientID}/referrals`,
    );
    return parseObjectDates(res.data, ['createdAt']);
  };

  postReferral = async (
    patientID: string,
    patientReferral: Patient.PatientReferralPostBody,
    fieldInstanceID?: string,
  ) => {
    const res = await this.post<Patient.PatientReferral>(`/api/patients/${patientID}/referrals`, {
      patientReferral,
      ...(fieldInstanceID ? { fieldInstanceId: fieldInstanceID } : {}),
    });
    return res.data;
  };

  getAuthorizations = async (patientID: string): Promise<Patient.Authorization[]> => {
    const res = await this.get<Patient.AuthorizationResponse[]>(
      `/api/patients/${patientID}/authorizations`,
    );

    return res.data;
  };

  upsertAuthorizations = async (
    patientID: string,
    patientAuthorizations: Patient.Authorization[],
    skipBilling: boolean,
    fieldInstanceID?: string,
  ): Promise<Patient.Authorization[]> => {
    const res = await this.post<Patient.Authorization[]>(
      `/api/patients/${patientID}/authorizations`,
      {
        patientAuthorizations,
        ...(fieldInstanceID ? { fieldInstanceId: fieldInstanceID } : {}),
        skipBilling,
      },
    );
    return res.data;
  };

  deleteAuthorization = async (
    patientID: string,
    authorizationId: string,
    fieldInstanceID?: string,
  ): Promise<void> => {
    await this.delete(`/api/patients/${patientID}/authorizations/${authorizationId}`, {
      params: {
        fieldInstanceID,
      },
    });
  };

  deleteAuthorizationEntry = async (
    patientID: string,
    authorizationId: string,
    authorizationEntryId: string,
    fieldInstanceID?: string,
  ): Promise<void> => {
    await this.delete(
      `/api/patients/${patientID}/authorizations/${authorizationId}/entries/${authorizationEntryId}`,
      {
        params: {
          fieldInstanceID,
        },
      },
    );
  };

  getPatientTreatmentPlan = async (patientID: string): Promise<Patient.Problem[]> => {
    const { data } = await this.get<Patient.Problem[]>(`/api/patients/${patientID}/problems`);
    return parseISODateStrings(data);
  };

  upsertPatientTreatmentPlan = async (
    patientID: string,
    treatmentPlan: Patient.ProblemPostBody[],
    fieldInstanceID?: string,
  ): Promise<Patient.Problem[]> => {
    const addFieldInstanceIfPresent = fieldInstanceID ? { fieldInstanceId: fieldInstanceID } : {};

    const res = await this.post<Patient.Problem[]>(`/api/patients/${patientID}/problems`, {
      problems: treatmentPlan,
      ...addFieldInstanceIfPresent,
    });
    return res.data;
  };

  archiveTreatmentPlanProblem = async (
    patientId: string,
    problemId: string,
    fieldInstanceID?: string,
  ): Promise<void> => {
    await this.delete(`/api/patients/${patientId}/problems/${problemId}`, {
      params: {
        fieldInstanceID,
      },
    });
  };

  archiveTreatmentPlanGoal = async (
    patientId: string,
    problemId: string,
    goalId: string,
    fieldInstanceID?: string,
  ): Promise<void> => {
    await this.delete(`/api/patients/${patientId}/problems/${problemId}/goals/${goalId}`, {
      params: {
        fieldInstanceID,
      },
    });
  };

  archiveTreatmentPlanObjective = async (
    patientId: string,
    problemId: string,
    goalId: string,
    objectiveId: string,
    fieldInstanceID?: string,
  ): Promise<void> => {
    await this.delete(
      `/api/patients/${patientId}/problems/${problemId}/goals/${goalId}/objectives/${objectiveId}`,
      {
        params: {
          fieldInstanceID,
        },
      },
    );
  };

  archiveTreatmentPlanIntervention = async (
    patientId: string,
    problemId: string,
    goalId: string,
    objectiveId: string,
    interventionId: string,
    fieldInstanceID?: string,
  ): Promise<void> => {
    await this.delete(
      `/api/patients/${patientId}/problems/${problemId}/goals/${goalId}/objectives/${objectiveId}/interventions/${interventionId}`,
      {
        params: {
          fieldInstanceID,
        },
      },
    );
  };

  getPatientsByProgramId = async (programId: string) => {
    const params: AxiosRequestConfig = {
      params: {
        programIds: [programId],
        searchUsers: false,
        searchPatients: true,
        searchPrograms: true,
        searchCareTeams: false,
        searchClinicTeams: false,
        patientProgramStatuses: ['active'],
        limit: 200,
      },
    };
    const res = await this.get<Entity.GetEntitiesResponse>(`/api/entities`, params);
    return res.data.patients;
  };

  getPatientsByClinicTeamId = async (clinicTeamId: string) => {
    const params: AxiosRequestConfig = {
      params: {
        clinicTeamIds: [clinicTeamId],
        searchUsers: false,
        searchPatients: true,
        searchPrograms: true,
        patientProgramStatuses: ['active'],
      },
    };
    const res = await this.get<Entity.GetEntitiesResponse>(`/api/entities`, params);

    return res.data.patients;
  };

  getTreatmentPlanReferences = async (
    patientId: string,
    formInstanceId: string,
  ): Promise<Patient.TreatmentPlanReference[]> => {
    const params: AxiosRequestConfig = {
      params: {
        formInstanceId,
      },
    };
    const res = await this.get<Patient.TreatmentPlanReference[]>(
      `/api/patients/${patientId}/treatment-plan-reference`,
      params,
    );
    return res.data;
  };

  upsertTreatmentPlanReferences = async (
    patientId: string,
    treatmentPlanReferences: Patient.TreatmentPlanReferencePostBody[],
    formInstanceId: string,
    fieldInstanceId?: string,
  ): Promise<Patient.TreatmentPlanReference[]> => {
    const res = await this.post<Patient.TreatmentPlanReference[]>(
      `/api/patients/${patientId}/treatment-plan-reference`,
      {
        treatmentPlanReferences,
        formInstanceId,
        fieldInstanceId,
      },
    );
    return res.data;
  };

  deleteTreatmentPlanReference = async (patientId: string, referenceId: string): Promise<void> => {
    await this.delete(`/api/patients/${patientId}/treatment-plan-reference/${referenceId}`);
  };

  revokeReleaseOfInformation = async (
    patientId: string,
    releaseOfInformationId: string,
  ): Promise<Patient.ReleaseOfInformation> => {
    const res = await this.post<Patient.ReleaseOfInformation>(
      `/api/patients/${patientId}/release-of-information/${releaseOfInformationId}/revoke`,
    );
    return parseISODateStrings(res.data);
  };

  upsertReleaseOfInformationV2 = async (
    patientId: string,
    releaseOfInformation: Patient.ReleaseOfInformationV2PostBody,
    fieldInstanceId?: string,
  ): Promise<Patient.ReleaseOfInformationV2> => {
    const res = await this.post<Patient.ReleaseOfInformationV2>(
      `/api/patients/${patientId}/release-of-informationV2`,
      {
        releaseOfInformation,
        ...(fieldInstanceId ? { fieldInstanceId } : {}),
      },
    );
    return parseISODateStrings(res.data);
  };

  getReleaseOfInformations = async (patientId: string): Promise<Patient.ReleaseOfInformation[]> => {
    const res = await this.get<Patient.ReleaseOfInformation[]>(
      `/api/patients/${patientId}/release-of-information`,
    );
    return parseISODateStrings(res.data);
  };

  getRoisByReleaseToId = async (patientId: string, releaseToId: string) => {
    const res = await this.get<Patient.ReleaseOfInformationV2[]>(
      `/api/patients/${patientId}/release-of-informationV2/${releaseToId}`,
    );
    return parseISODateStrings(res.data);
  };

  getReleasesOfInformationV2 = async (
    patientId: string,
  ): Promise<Patient.ReleaseOfInformationV2[]> => {
    const res = await this.get<Patient.ReleaseOfInformationV2[]>(
      `/api/patients/${patientId}/release-of-informationV2`,
    );
    return parseISODateStrings(res.data);
  };

  getClinicTeams = async (patientId: string): Promise<Patient.ClinicTeam[]> => {
    const res = await this.get<Patient.ClinicTeam[]>(`/api/patients/${patientId}/clinic-teams`);
    return res.data;
  };

  addPatientToClinicTeam = async (
    patientId: string,
    teamId: string,
    fieldInstanceId?: string,
  ): Promise<void> => {
    await this.post(`/api/patients/${patientId}/clinic-teams/${teamId}`, {
      ...(fieldInstanceId ? { fieldInstanceId } : {}),
    });
  };

  removePatientFromClinicTeam = async (
    patientId: string,
    teamId: string,
    fieldInstanceId?: string,
  ): Promise<void> => {
    await this.delete(
      `/api/patients/${patientId}/clinic-teams/${teamId}`,
      {},
      {
        ...(fieldInstanceId ? { fieldInstanceId } : {}),
      },
    );
  };

  resendPatientInvite = async (patientId: string): Promise<void> => {
    await this.post(`/api/patients/${patientId}/user/resend-invite`);
  };

  getPatientFundingSource = async (patientId: string): Promise<Patient.FundingSource> => {
    const res = await this.get<Patient.FundingSource>(`/api/patients/${patientId}/funding-source`);
    return res.data;
  };

  upsertPatientFundingSource = async ({
    patientId,
    fundingSource,
    fieldInstanceId,
  }: {
    patientId: string;
    fundingSource: Patient.FundingSource;
    fieldInstanceId?: string;
  }): Promise<Patient.FundingSource> => {
    const res = await this.post<Patient.FundingSource>(
      `/api/patients/${patientId}/funding-source`,
      {
        ...fundingSource,
        ...(fieldInstanceId && { fieldInstanceId }),
      },
    );
    return res.data;
  };

  getBiometrics = async (patientId: string): Promise<Patient.ObservationResponse> => {
    const res = await this.get<Patient.ObservationResponse>(
      `/api/patients/${patientId}/biometrics`,
    );
    return parseISODateStrings(res.data);
  };

  getPatientBiometricsByType = async (
    patientId: string,
    biometricsQuery: Patient.BiometricsQuery,
  ): Promise<Patient.ObservationResponse> => {
    const res = await this.get<Patient.ObservationResponse>(
      `/api/patients/${patientId}/biometrics`,
      {
        params: biometricsQuery,
      },
    );
    return parseISODateStrings(res.data);
  };

  upsertBiometrics = async (
    patientId: string,
    observations: Patient.ObservationPostBody[],
  ): Promise<Patient.Observation[]> => {
    const res = await this.post<Patient.Observation[]>(
      `/api/patients/${patientId}/biometrics`,
      observations,
    );
    return res.data;
  };

  archiveBiometricObservation = async (patientId: string, observationId: string): Promise<void> => {
    await this.delete(`/api/patients/${patientId}/biometrics/${observationId}`);
  };

  archiveBiometricMeasurement = async (patientId: string, measurementId: string): Promise<void> => {
    await this.delete(`/api/patients/${patientId}/biometrics/measurements/${measurementId}`);
  };

  getPatientRelationshipById = async (patientId: string, relationshipId: string) => {
    const res = await this.get<Patient.ClientRelationship>(
      `/api/patients/${patientId}/relationships/${relationshipId}`,
    );
    return parseISODateStrings(res.data);
  };

  exportBiometrics = async ({
    patientId,
    types,
    startDate,
    endDate,
  }: Patient.ExportBiometricsQuery): Promise<void> => {
    await this.post<void>(`/api/exports/async/patient/${patientId}/biometrics`, {
      types,
      startDate: startDate ? formatISO(startDate, { representation: 'complete' }) : undefined,
      endDate: endDate ? formatISO(endDate, { representation: 'complete' }) : undefined,
    });
  };

  getPatientAgenda = async (patientId: string, startTime: Date, endTime: Date) => {
    const res = await this.get<Calendar.CalendarEvent[]>(`/api/patients/${patientId}/agenda`, {
      params: {
        startTime,
        endTime,
      },
    });
    return parseISODateStrings(res.data);
  };

  // warnings
  getPatientWarnings = async (patientId: string): Promise<Patient.Warning[]> => {
    const res = await this.get<Patient.Warning[]>(`/api/patients/${patientId}/warnings`);
    return res.data;
  };

  upsertPatientWarnings = async (
    patientID: string,
    warnings: Patient.Warning[],
    fieldInstanceID?: string,
  ): Promise<Patient.Warning[]> => {
    const res = await this.post<Patient.Warning[]>(`/api/patients/${patientID}/warnings`, {
      warnings,
      ...(fieldInstanceID && { fieldInstanceId: fieldInstanceID }),
    });
    return res.data;
  };

  deletePatientWarning = async (
    patientId: string,
    warningId: string,
    fieldInstanceId?: string,
  ): Promise<Patient.Warning> => {
    const res = await this.delete<Patient.Warning>(
      `/api/patients/${patientId}/warnings/${warningId}`,
      {
        params: {
          fieldInstanceID: fieldInstanceId,
        },
      },
    );
    return res.data;
  };

  // dietary restrictions
  getPatientDietaryRestrictions = async (
    patientId: string,
  ): Promise<Patient.DietaryRestriction[]> => {
    const res = await this.get<Patient.DietaryRestriction[]>(
      `/api/patients/${patientId}/dietary-restrictions`,
    );
    return res.data;
  };

  upsertPatientDietaryRestrictions = async (
    patientID: string,
    dietaryRestrictions: Patient.DietaryRestriction[],
    fieldInstanceID?: string,
  ): Promise<Patient.DietaryRestriction[]> => {
    const res = await this.post<Patient.DietaryRestriction[]>(
      `/api/patients/${patientID}/dietary-restrictions`,
      {
        dietaryRestrictions,
        ...(fieldInstanceID && { fieldInstanceId: fieldInstanceID }),
      },
    );
    return parseISODateStrings(res.data);
  };

  deletePatientDietaryRestriction = async (
    patientId: string,
    dietaryRestrictionId: string,
    fieldInstanceId?: string,
  ): Promise<Patient.DietaryRestriction> => {
    const res = await this.delete<Patient.DietaryRestriction>(
      `/api/patients/${patientId}/dietary-restrictions/${dietaryRestrictionId}`,
      {
        params: {
          fieldInstanceID: fieldInstanceId,
        },
      },
    );
    return res.data;
  };

  getPatientVOBs = async (patientId: string): Promise<Patient.VOB[]> => {
    const res = await this.get<Patient.VOB[]>(`/api/patients/${patientId}/billing/vob`);
    return parseISODateStrings(res.data);
  };

  postPatientVOB = async (patientId: string, vob: Patient.VOB): Promise<Patient.VOB> => {
    const res = await this.post<Patient.VOB>(`/api/patients/${patientId}/billing/vob`, {
      ...vob,
    });
    return parseISODateStrings(res.data);
  };

  deletePatientVOB = async (patientId: string, vobId: string) => {
    await this.delete(`/api/patients/${patientId}/billing/vob/${vobId}`);
  };

  listPatientPortalDocsShared = async (patientId: string): Promise<Documents.Document[]> => {
    const res = await this.get<Documents.Document[]>(
      `/api/patients/${patientId}/portal-docs/shared`,
    );
    return parseISODateStrings(res.data);
  };

  listPatientPortalDocsUploaded = async (patientId: string): Promise<Documents.Document[]> => {
    const res = await this.get<Documents.Document[]>(
      `/api/patients/${patientId}/portal-docs/uploaded`,
    );
    return parseISODateStrings(res.data);
  };

  requestPaymentMethods = async (patientId: string, body: Patient.RequestPaymentMethod) => {
    await this.post(`/api/patients/${patientId}/stripe/payment-method-request`, body);
  };

  getPaymentMethods = async (patientId: string): Promise<Patient.PaymentMethodCreditCard[]> => {
    const res = await this.get<Patient.PaymentMethodCreditCard[]>(
      `/api/patients/${patientId}/stripe/payment-methods`,
    );
    return res.data;
  };

  postPaymentMethods = async (
    patientId: string,
    body: Patient.StripePaymentMethodPostBody,
  ): Promise<Patient.StripePaymentMethodSession> => {
    const res = await this.post<Patient.StripePaymentMethodSession>(
      `/api/patients/${patientId}/stripe/payment-methods`,
      body,
    );
    return res.data;
  };

  deletePaymentMethod = async (patientId: string, pmId: string): Promise<void> => {
    await this.delete(`/api/patients/${patientId}/stripe/payment-methods/${pmId}`);
  };

  getCharges = async (
    patientId: string,
    params: Patient.StripeGetChargesParams,
  ): Promise<Patient.Charge[]> => {
    const res = await this.get<Patient.Charge[]>(`/api/patients/${patientId}/stripe/charges`, {
      params,
    });
    return parseISODateStrings(res.data);
  };

  postCharge = async (patientId: string, body: Patient.StripeChargePostBody): Promise<void> => {
    await this.post(`/api/patients/${patientId}/stripe/charges`, body);
  };

  postRefund = async (patientId: string, body: Patient.StripeRefundPostBody): Promise<void> => {
    await this.post(`/api/patients/${patientId}/stripe/refunds`, body);
  };

  refreshPatientBalance = async (patientId: string): Promise<Patient.CMDBalance | null> => {
    const res = await this.post<Patient.CMDBalance | null>(
      `/api/patients/${patientId}/billing/balance`,
    );
    return parseISODateStrings(res.data);
  };

  getExclusiveGrants = async (patientId: string): Promise<Patient.ExclusiveGrant[]> => {
    const res = await this.get<Patient.ExclusiveGrant[]>(
      `/api/patients/${patientId}/exclusive-grants`,
    );
    return res.data;
  };

  postExclusiveGrant = async (
    patientId: string,
    body: Patient.ExclusiveGrantPostBody,
  ): Promise<void> => {
    await this.post(`/api/patients/${patientId}/exclusive-grants`, body);
  };

  deleteExclusiveGrant = async (patientId: string, exclusiveGrantId: string): Promise<void> => {
    await this.delete(`/api/patients/${patientId}/exclusive-grants/${exclusiveGrantId}`);
  };

  // ciwa premium roobi
  listPatientCIWA = async (
    patientId: string,
    limit?: number,
    offset?: number,
  ): Promise<Patient.CIWA[]> => {
    const res = await this.get<Patient.CIWA[]>(`/api/patients/${patientId}/ciwa`, {
      params: {
        limit: limit ? limit.toString() : '20',
        offset: offset ? offset.toString() : '0',
      },
    });
    return parseISODateStrings(res.data);
  };

  upsertPatientCIWA = async (
    patientID: string,
    ciwa: Patient.CIWAPostBody,
    fieldInstanceId?: string | null,
  ): Promise<Patient.CIWA[]> => {
    const res = await this.post<Patient.CIWA[]>(`/api/patients/${patientID}/ciwa`, {
      ciwa,
      ...(fieldInstanceId && { fieldInstanceId }),
    });
    return parseISODateStrings(res.data);
  };

  archivePatientCIWA = async (patientId: string, ciwaId: string): Promise<void> => {
    await this.delete<void>(`/api/patients/${patientId}/ciwa/${ciwaId}`);
  };

  getCIWAById = async (patientId: string, ciwaId: string): Promise<Patient.CIWA> => {
    const res = await this.get<Patient.CIWA>(`/api/patients/${patientId}/ciwa/${ciwaId}`);
    return parseISODateStrings(res.data);
  };

  // cows premium roobi
  listPatientCOWS = async (
    patientId: string,
    limit: number,
    offset: number,
  ): Promise<Patient.COWS[]> => {
    const res = await this.get<Patient.COWS[]>(`/api/patients/${patientId}/cows`, {
      params: {
        limit,
        offset,
      },
    });
    return parseISODateStrings(res.data);
  };

  upsertPatientCOWS = async (
    patientID: string,
    cows: Patient.COWSPostBody,
    fieldInstanceId?: string | null,
  ): Promise<Patient.COWS[]> => {
    const res = await this.post<Patient.COWS[]>(`/api/patients/${patientID}/cows`, {
      cows,
      ...(fieldInstanceId && { fieldInstanceId }),
    });
    return parseISODateStrings(res.data);
  };

  archivePatientCOWS = async (patientId: string, cowsId: string): Promise<void> => {
    await this.delete<void>(`/api/patients/${patientId}/cows/${cowsId}`);
  };

  getCOWSById = async (patientId: string, cowsId: string): Promise<Patient.COWS> => {
    const res = await this.get<Patient.COWS>(`/api/patients/${patientId}/cows/${cowsId}`);
    return parseISODateStrings(res.data);
  };

  // pain scale premium roobi
  listPatientPainScale = async (
    patientId: string,
    limit: number,
    offset: number,
  ): Promise<Patient.PainScale[]> => {
    const res = await this.get<Patient.PainScale[]>(`/api/patients/${patientId}/pain-scale`, {
      params: {
        limit,
        offset,
      },
    });
    return parseISODateStrings(res.data);
  };

  upsertPatientPainScale = async (
    patientId: string,
    painScale: Patient.PainScalePostBody,
    fieldInstanceId?: string | null,
  ): Promise<Patient.PainScale[]> => {
    const res = await this.post<Patient.PainScale[]>(`/api/patients/${patientId}/pain-scale`, {
      painScale,
      ...(fieldInstanceId && { fieldInstanceId }),
    });
    return parseISODateStrings(res.data);
  };

  archivePatientPainScale = async (patientId: string, painScaleId: string): Promise<void> => {
    await this.delete<void>(`/api/patients/${patientId}/pain-scale/${painScaleId}`);
  };

  getPainScaleById = async (patientId: string, painScaleId: string): Promise<Patient.PainScale> => {
    const res = await this.get<Patient.PainScale>(
      `/api/patients/${patientId}/pain-scale/${painScaleId}`,
    );
    return parseISODateStrings(res.data);
  };

  postChartFlag = async (patientId: string, body: Patient.PatientChartFlag): Promise<void> => {
    await this.post(`/api/patients/${patientId}/flag`, body);
  };

  getChartFlag = async (patientId: string): Promise<Patient.PatientChartFlag | undefined> => {
    const { data } = await this.get<Patient.PatientChartFlag>(`/api/patients/${patientId}/flag`);
    return parseISODateStrings(data);
  };

  deleteChartFlag = async (patientId: string): Promise<void> => {
    await this.delete(`/api/patients/${patientId}/flag`);
  };
}

export default PatientClient;
