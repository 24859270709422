import {
  CategoryScale,
  Chart as ChartJS,
  LinearScale,
  LineController,
  LineElement,
  PointElement,
  TimeScale,
  Title,
  Tooltip,
} from 'chart.js';

import 'chartjs-adapter-date-fns';

// Register required chartjs modules here.  Register only what you need to keep bundle size lower.
ChartJS.register(
  LineController,
  LineElement,
  PointElement,
  Title,
  LinearScale,
  CategoryScale,
  TimeScale,
  Tooltip,
);
