import { Box, Text } from 'grommet';

import { COLORS } from '../../styles/colors';

interface AlertCountProps {
  backgroundColor?: string;
  color?: string;
  size?: string;
  fontSize?: string;
  count: number;
  hideIfZero?: boolean;
  ['data-testid']?: string;
}

const AlertCount: React.FC<AlertCountProps> = (props: AlertCountProps) => {
  const { backgroundColor, color, size, fontSize, count, hideIfZero } = props;
  const defaultBackgroundColor = COLORS.red400;
  const defaultColor = COLORS.white;
  const defaultSize = '26px';
  const defaultFontSize = 'small';
  if (hideIfZero && count === 0) {
    return <></>;
  }
  return (
    <Box
      background={backgroundColor || defaultBackgroundColor}
      round="full"
      height={{ min: size || defaultSize }}
      width={{ min: size || defaultSize }}
      align="center"
      justify="center"
      pad="0px 4px"
      data-testid={props['data-testid']}
    >
      <Text color={color || defaultColor} size={fontSize || defaultFontSize}>
        {count}
      </Text>
    </Box>
  );
};

export default AlertCount;
