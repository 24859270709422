import { useEffect, useRef, useState } from 'react';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { Box, BoxProps, Text } from 'grommet';
import { Like } from 'grommet-icons';

import ContentBox from '@ritten/ui-library/boxes/ContentBox';
import { StandardButton } from '@ritten/ui-library/buttons';
import { ErrorPanel } from '@ritten/ui-library/errors';
import LoadingPanel from '@ritten/ui-library/panels/LoadingPanel';

import ViewportMetaTagHandler from 'common/ViewportMetaTagHandler';
import usePublicAPI from 'external/usePublicAPI';
import { redirectToHomePage } from 'features/auth/SetPasswordPage';
import { isFieldValueEmpty } from 'features/forms/formInstanceUtils';
import { useErrorState, useLoadingState } from 'hooks';
import { COLORS } from 'styles/colors';
import { blobToBase64DataURL, convertLocalDateToUTCDateOnly } from 'utils';
import {
  LABEL_TO_CATEGORY_MAP,
  LEAD_APP_CATEGORY_TO_PUBLIC_DISPLAY_TEXT,
  LeadAppCategory,
  ORDERED_CATEGORIES,
  SKIP_REQUIRED_VALIDATION_FIELDS,
} from './constants';
import LeadApplicationRenderer from './LeadApplicationRenderer';
import { getErrorKey, parseLeadApp, validateLeadAppFieldValues } from './utils';

const PublicLeadApplication = () => {
  const { leadAppInstanceId: leadAppInstanceIdParam } =
    useParams<UIRoutes.PublicLeadApplicationParams>();
  const { search } = useLocation();
  const history = useHistory();
  const { leadApplicationAPI, fileDownloadsAPI } = usePublicAPI();
  const { errors, addError, clearErrors } = useErrorState();
  const { loading, needsLoadingState } = useLoadingState(true);

  const [token, setToken] = useState<string | undefined>();
  const [leadApp, setLeadApp] = useState<CRM.LeadAppDefinition | CRM.LeadAppInstance | null>(null);
  const [leadAppInstanceId, setLeadAppInstanceId] = useState<string | undefined>();
  const [leadAppLogoUrl, setLeadAppLogoUrl] = useState<string | undefined>();
  const [sectionsByCategory, setSectionsByCategory] = useState<
    Map<LeadAppCategory, CRM.LeadAppSection[]>
  >(new Map());
  const [validationErrors, setValidationErrors] = useState<
    Record<CRM.LeadAppFieldLabel, string[]> | undefined
  >();
  const [isSubmitSuccess, setIsSubmitSuccess] = useState<boolean>(false);
  const errorPanelRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (leadAppInstanceIdParam) {
      fetchLeadApplicationInstance(leadAppInstanceIdParam);
    } else {
      fetchLeadApplicationDefinition();
    }
  }, []);

  const getParsedLeadApp = (leadAppRes: CRM.LeadAppDefinition | CRM.LeadAppInstance) => {
    const newSelectedElements = parseLeadApp(leadAppRes);
    setSectionsByCategory(newSelectedElements);
    if (leadAppRes?.meta.logoId) {
      fetchLeadAppLogoUrl(leadAppRes.meta.logoId);
    }
  };

  const fetchLeadApplicationInstance = needsLoadingState(async (id: string) => {
    try {
      setLeadAppInstanceId(id);
      const query = new URLSearchParams(search);
      const jwtToken = query.get('token');
      history.replace({
        search: '',
      });
      if (jwtToken) {
        setToken(jwtToken);
        const leadAppInstance = await leadApplicationAPI.getLeadAppInstance(id, jwtToken);
        setLeadApp(leadAppInstance);
        getParsedLeadApp(leadAppInstance);
      } else {
        throw new Error('Link invalid or expired');
      }
    } catch (error) {
      addError(error);
    }
  });

  const fetchLeadApplicationDefinition = needsLoadingState(async () => {
    try {
      const leadAppDefRes = await leadApplicationAPI.getLeadAppDefinition();
      setLeadApp(leadAppDefRes);
      getParsedLeadApp(leadAppDefRes);
      // generate new lead app instance
      const { id } = await leadApplicationAPI.createDraftLeadAppInstance(
        leadAppDefRes.id,
        leadAppDefRes.version,
      );
      setLeadAppInstanceId(id);
    } catch (error) {
      // Redirect to the home page if the backend lets us know via an error that the lead application page should not be accessible
      redirectToHomePage();
    }
  });

  const fetchLeadAppLogoUrl = async (logoId: string) => {
    try {
      const res = await fileDownloadsAPI.downloadFileById(logoId);
      const url = await blobToBase64DataURL(res);
      setLeadAppLogoUrl(url);
    } catch (error) {
      // swallow error
      // couldn't fetch logo url so we won't render the image
    }
  };

  const updateLeadAppFieldValue = async (
    category: LeadAppCategory,
    section: CRM.LeadAppSection,
    field: CRM.LeadAppField,
    value?: any,
  ) => {
    const sections = sectionsByCategory.get(category) || [];
    const updatedSections = sections.map((s) => {
      if (s.title === section.title && s.label === section.label) {
        const updatedFields = s.fields.map((f) => {
          if (f.label === field.label) {
            return {
              ...f,
              value,
            };
          }
          return f;
        });
        return {
          ...s,
          fields: updatedFields,
        };
      }
      return s;
    });
    const newSectionsByCategory = new Map(sectionsByCategory);
    newSectionsByCategory.set(category, updatedSections);
    setSectionsByCategory(newSectionsByCategory);
  };

  const onSubmit = needsLoadingState(async () => {
    const isValid = validateLeadApp();
    if (!isValid) {
      return;
    }
    try {
      if (leadAppInstanceId && leadApp?.sections) {
        await leadApplicationAPI.putLeadAppInstance(
          leadAppInstanceId,
          leadApp.sections.map((section) => {
            const updatedSection = sectionsByCategory
              .get(LABEL_TO_CATEGORY_MAP[section.label])
              ?.find((s) => s.title === section.title && s.label === section.label);
            // Convert local date to UTC date for DOB field
            if (updatedSection) {
              updatedSection.fields = updatedSection.fields.map((field) => {
                if (field.label === 'lead_app_dob' || field.label === 'lead_app_insured_dob') {
                  return {
                    ...field,
                    value: field.value ? convertLocalDateToUTCDateOnly(field.value) : field.value,
                  };
                }
                if (field.label === 'lead_app_insured_different_than_client') {
                  return {
                    ...field,
                    value: field.value ?? [],
                  };
                }
                return field;
              });
            }
            return updatedSection || section;
          }),
          token,
        );
        setIsSubmitSuccess(true);
      }
    } catch (error) {
      addError(error);
    }
  });

  const validateLeadApp = () => {
    // Validate that all required fields are filled out
    const errors = {} as Record<CRM.LeadAppFieldLabel, string[]>;
    Array.from(sectionsByCategory.values()).forEach((section) => {
      section.forEach((s) =>
        s.fields.forEach((field) => {
          if (
            s.isRequired &&
            !SKIP_REQUIRED_VALIDATION_FIELDS.includes(field.label) &&
            isFieldValueEmpty(field.value)
          ) {
            errors[getErrorKey(s, field)] = ['Field is required'];
          }
          const specialValidationError = validateLeadAppFieldValues(field, s);
          if (specialValidationError) {
            errors[getErrorKey(s, field)] = (errors[getErrorKey(s, field)] || []).concat(
              specialValidationError,
            );
          }
        }),
      );
    });
    setValidationErrors(errors);
    if (Object.keys(errors).length > 0) {
      addError('Please ensure all fields are valid and required fields are filled out');
      errorPanelRef.current?.scrollIntoView({ behavior: 'smooth' });
      return false;
    }
    clearErrors();
    return true;
  };

  const nonCustomSectionContainerProps: BoxProps = {
    direction: 'row',
    wrap: true,
  };

  // TODO ensure only loader shows when loading and lead app definition/instance are not yet fetched

  return (
    <>
      <ViewportMetaTagHandler />
      <Box background={COLORS.darkGray200} fill>
        <LoadingPanel showLoader={loading}>
          <Box
            background={COLORS.white}
            pad="16px"
            border={
              leadApp?.meta.hexColor
                ? { size: '5px', side: 'bottom', color: leadApp.meta.hexColor }
                : undefined
            }
            direction="row"
            align="center"
            justify="center"
            gap="10px"
            wrap
          >
            {leadAppLogoUrl && <img src={leadAppLogoUrl} alt="" height="30px" width="auto" />}
            <Text size="24px" weight={700} wordBreak="break-all" textAlign="center">
              {leadApp?.meta.title}
            </Text>
          </Box>
          {isSubmitSuccess ? (
            <Box pad="24px" align="center">
              <ContentBox pad="32px" align="center" width={{ width: '600px', max: '100%' }}>
                <Like color={COLORS.green400} size="24px" />
                <Text size="18px" weight={700} margin="8px 0 16px">
                  Success!
                </Text>
                <Text size="14px">Your information has been submitted.</Text>
              </ContentBox>
            </Box>
          ) : (
            <>
              <Box ref={errorPanelRef}>
                <ErrorPanel errors={errors} />
              </Box>
              <Box pad="24px" align="center">
                <Box width={{ max: '675px', width: '100%' }} gap="16px">
                  {ORDERED_CATEGORIES.map((category) => {
                    if (!sectionsByCategory.get(category)?.length) {
                      return null;
                    }
                    return (
                      <ContentBox noBoxShadow pad="16px 32px" gap="16px" key={category}>
                        <Text size="18px" weight={700}>
                          {LEAD_APP_CATEGORY_TO_PUBLIC_DISPLAY_TEXT[category]}
                        </Text>
                        <Box
                          {...(category === 'Custom Question'
                            ? { gap: '16px' }
                            : nonCustomSectionContainerProps)}
                          style={{ rowGap: '10px', columnGap: '8px' }}
                        >
                          {sectionsByCategory.get(category)?.map((section, index) => (
                            <LeadApplicationRenderer
                              key={index}
                              section={section}
                              mode="application"
                              updateLeadAppFieldValue={(section, field, value) =>
                                updateLeadAppFieldValue(category, section, field, value)
                              }
                              validationErrors={validationErrors}
                              leadAppInstanceId={leadAppInstanceId}
                              token={token}
                            />
                          ))}
                        </Box>
                      </ContentBox>
                    );
                  })}
                  {leadApp && (
                    <Box width="100%" align="end">
                      <StandardButton label="Submit" onClick={onSubmit} disabled={loading} />
                    </Box>
                  )}
                </Box>
              </Box>
            </>
          )}
        </LoadingPanel>
      </Box>
    </>
  );
};

export default PublicLeadApplication;
